import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import {
    addTimesheet_action, updateTimesheet_action,
    TimesheetDetails_action, approvalTimesheet_action, LastTimesheetFetch_action, lastTimesheetFill_action, singleLastTimesheetDate_action
} from '../../../actions/time_sheet_expense_action/time_sheet_action'
import { fill_action } from '../../../actions/master_action/checklist_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import {
    TimeSheetClientActionId, financialYearId, engagementPartnerId,
    engagementTypeId, transcationForActionId, employeeActionId,
    taskActionId, transcationTypeActionId,
    finYearOnbasicClientActionId, engagementTimeSheetActionId,
    Timesheet
} from '../../../utils/constants'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from 'react-icons/fa';
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faClock } from '@fortawesome/free-solid-svg-icons';
// import 'react-datepicker/dist/react-datepicker.css';
// import { TimePicker } from '@hilla/react-components/TimePicker.js';
import { getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';
import { event } from 'jquery';

/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));

class TimesheetAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: '',
            isOpen: false,
            is_add: false,
            is_delete: false,
            is_update: false,
            isLoading: false,
            transactionForId: '',
            transactionForList: [],
            finYearId: '',
            financialYearList: [],
            clientNameList: [],
            clientNameId: '',
            engagementId: '',
            engagementList: [],
            transactionTypeId: '',
            transactionTypeList: [],
            employeeId: (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            employeeList: [],
            taskId: '',
            taskList: [],
            description: '',
            date: '',
            fromTime: moment('10:30', 'HH:mm'),
            // fromTime: '10:00',
            // toTime: '19:00',
            toTime: moment('18:30', 'HH:mm'),
            currentTimesheetTime: '21:00',
            remark: '',
            rejectionReason: '',
            isVerified: '',
            TimeSheetStatus: '',
            timeSheetExpenseID: '',
            ExpenseAmmount: '',
            ExpenseId: '',
            isApproved: false,
            startDate: '',
            endDate: '',
            lastDateTimesheetFill: '',
            is_DateFlag: false,
            isClientDisable: false,
            timesheetDisable: false,
            isTimesheetSuccsess: false,
            timesheetId: '',
            singleDisableLastDateTimesheet: false,
            isDatePresent: false,
            currentDate: new Date,
        }
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.onSave = this.onSave.bind(this)
        this.toCamelCase = this.toCamelCase.bind(this)
        this.onSelectClient = this.onSelectClient.bind(this)
        this.onSelectFinYear = this.onSelectFinYear.bind(this)
        this.onSelectEngagementId = this.onSelectEngagementId.bind(this)
        this.getDetailsTimesheet = this.getDetailsTimesheet.bind(this)
        this.onSelectTransactionForId = this.onSelectTransactionForId.bind(this)
        this.onSelectDate = this.onSelectDate.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.handleHolidaySave = this.handleHolidaySave.bind(this)
        this.approvalTimesheet = this.approvalTimesheet.bind(this)
        this.addingTimeSheet = this.addingTimeSheet.bind(this)
        this.getStartEndDateList = this.getStartEndDateList.bind(this)
        this.inputRef = React.createRef();
    }

    componentDidMount() {

        console.log('componentDidMount', (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID']);
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
            engagementAllocationId: this.props.location.state != null && this.props.location.state.engagementAllocationId != null ? this.props.location.state.engagementAllocationId : '',
            isAccessToView: localStorage.getItem('UserId') == '1' ? true : false,

        })
        this.getFillList(transcationForActionId, '')
        this.getFillList(employeeActionId, '')
        this.getFillList(TimeSheetClientActionId, '')

        this.getFillList(taskActionId, '')
        this.getFillList(transcationTypeActionId, '')
        this.props.location.state != null && this.props.location.state.is_update && this.getDetailsTimesheet()

        setTimeout(() => {
            // this.lastTimesheetFill()
            this.singleLastTimesheet()
        }, 300);

        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);

    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    // get Quality Reviewer Name List dropdown list 
    getFillList(value, ref, ref2) {
        const { clientNameId, is_add } = this.state
        this.setState({ isLoading: true })

        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == engagementTimeSheetActionId) {

                        console.log("****")
                        this.setState({ engagementList: data.data, isLoading: false })

                        if (data.data.length == 1) {
                            this.setState({ is_DateFlag: false })

                        }

                        if (data.data.length == 0) {
                            toast.error('No engagement created  against this financial year ', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }
                        if (data.data.length == 1) {
                            this.setState({ engagementId: data.data[0]['EngagementID'], isLoading: false })
                            sessionStorage.setItem('engagementIdTimeSS', data.data[0]['EngagementID'])
                            console.log("this.state.engagementId11****", this.state.engagementId)
                            this.getStartEndDateList(data.data[0]['EngagementID'])
                            this.lastTimesheetFill(data.data[0]['EngagementID'])

                        }
                        console.log("engagementTimeSheetActionId", data.data)
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);



                    }
                    if (value == TimeSheetClientActionId) {
                        console.log('clientsNameList')
                        console.log('clientsNameList', data.data)

                        this.setState({ clientNameList: data.data, isLoading: false })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);
                    }
                    if (value == finYearOnbasicClientActionId) {
                        console.log('financialYearList', data.data[0]['FinYearID'])
                        console.log('clientNameId', clientNameId)
                        this.setState({ financialYearList: data.data })
                        console.log("data*81314", data)
                        if (data.data.length == 0) {
                            toast.error('No financial year available against this client', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })

                            // is_add && setTimeout(() => {
                            //     this.getFillList(engagementTimeSheetActionId, clientNameId, data.data[0]['FinYearID'])
                            // }, 500);
                        }
                        if (data.data.length == 1) {
                            this.setState({ finYearId: data.data[0]['FinYearID'] })
                            console.log('clientNameId **************************', clientNameId)
                            console.log('FinYearID ***************************', data.data[0]['FinYearID'])
                            sessionStorage.setItem('finYearIdTimeSS', data.data[0]['FinYearID'])

                            console.log('')
                            setTimeout(() => {
                                this.getFillList(engagementTimeSheetActionId, data.data[0]['ClientID'], data.data[0]['FinYearID'])
                            }, 500);
                        }


                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);
                    }
                    if (value == taskActionId) {
                        console.log('employeeActionId')

                        this.setState({ taskList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);
                    }
                    if (value == transcationForActionId) {
                        console.log('employeeActionId')

                        this.setState({ transactionForList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);
                    }
                    if (value == transcationTypeActionId) {
                        console.log('transcationTypeActionId', data.data)

                        this.setState({ transactionTypeList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);
                    }
                    if (value == employeeActionId) {
                        console.log('employeeActionId ????????', localStorage.getItem('employeeIdSS'))
                        data.data.map(item => {
                            if (item.emmnID == localStorage.getItem('employeeIdSS')) {
                                console.log('employeeActionId ????????', localStorage.getItem('employeeIdSS'))

                                this.setState({ employeeId: item.emmnID })
                            }
                        })
                        this.setState({ employeeList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);
                    }
                    this.setState({ isLoading: false })


                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    approvalTimesheet() {
        const { transactionForId, employeeId, clientNameId, finYearId,
            engagementId, } = this.state
        this.setState({ isLoading: true })
        this.props
            .approvalTimesheet_action(transactionForId, employeeId, clientNameId, finYearId, engagementId)
            .then((data) => {
                if (data.error != 1) {
                    // console.log("getTimesheetId_action ", data.data[0]['FinYearID'])

                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    //toggle model for ................................
    toggleModal() {
        const { isOpen, } = this.state
        this.setState({ isOpen: !isOpen })
    }

    // get timesheet details
    getDetailsTimesheet() {
        this.setState({ isLoading: true })
        var id = this.props.location.state != ' ' && this.props.location.state.timesheetListId != ' ' ? this.props.location.state.timesheetListId : ''

        this.props
            .TimesheetDetails_action(id)
            .then((data) => {
                if (data.error != 1) {
                    if (data.data[0]['ClientID'] != 0 && data.data[0]['EngagementID'] != 0 && data.data[0]['FinYearID'] != 0) {
                        this.getFillList(finYearOnbasicClientActionId, data.data[0]['ClientID'], data.data[0]['EngagementID'])
                        this.getFillList(engagementTimeSheetActionId, data.data[0]['ClientID'], data.data[0]['FinYearID'])
                    }
                    // employee id
                    data.data.map(item => {
                        if (item.empsFirstName == localStorage.getItem('employeeIdSS')) {
                            console.log("getDetailsTimesheet2", item.emmnID)
                            this.setState({ employeeId: item.emmnID })
                        }
                    })
                    this.setState({
                        clientNameId: data.data[0]['ClientID'],
                        transactionForId: data.data[0]['TransactionForID'],
                        isClientDisable: data.data[0]['TransactionForID'] == 2 ? true : false,
                        finYearId: data.data[0]['FinYearID'],
                        engagementId: data.data[0]['EngagementID'],
                        TimeSheetStatus: data.data[0]['TimeSheetStatus'],
                        transactionTypeId: data.data[0]['TransactionTypeID'],
                        employeeId: data.data[0]['EmployeeID'],
                        taskId: data.data[0]['TaskTypeID'],
                        description: data.data[0]['Description'],
                        date: moment(data.data[0]['TimeSheetDate'], 'YYYY-MM-DD').toDate(),
                        fromTime: data.data[0]['StartTime'],
                        toTime: data.data[0]['Endtime'],
                        // fromTime:  moment(data.data[0]['StartTime'], 'HH:mm'),
                        // toTime: moment(data.data[0]['Endtime'], 'HH:mm'),
                        remark: data.data[0]['Remark'],
                        rejectionReason: data.data[0]['RejectReason'],
                        timeSheetExpenseID: data.data[0]['TimeSheetID'],
                        ExpenseAmmount: data.data[0]['ExpenseAmount'],
                        ExpenseId: data.data[0]['ExpenseID'],
                        isLoading: false
                    },()=>this.getStartEndDateList(data.data[0]['EngagementID']))
                    // sessionStorage.setItem('clientNameIdTimeSS', data.data[0]['ClientID'])
                    // sessionStorage.setItem('transactionForIdTimeSS', data.data[0]['TransactionForID'])
                    // sessionStorage.setItem('finYearIdTimeSS', data.data[0]['FinYearID'])
                    // sessionStorage.setItem('engagementIdTimeSS', data.data[0]['EngagementID'])
                    // sessionStorage.setItem('dateTimeSS', moment(data.data[0]['TimeSheetDate'], 'YYYY-MM-DD').toDate())

                    console.log('fromTime 1', moment(data.data[0]['StartTime'], 'HH:mm')['_i'], data.data[0]['StartTime'])
                    console.log('toTime 1', moment(data.data[0]['Endtime'], 'HH:mm')['_i'], data.data[0]['Endtime'])
                    if (data.data[0]['TimeSheetStatus'] == "Approved") {
                        this.setState({ timesheetDisable: true })
                    }

                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })

            .catch((e) => {
                console.log(e);
            });
    }

    onSelectClient(e) {
        const { financialYearList, is_add, date } = this.state
        // date: '',
        this.setState({ date: is_add ? '' : date, finYearId: '', financialYearList: [], engagementId: '', engagementList: [], is_DateFlag: true, lastDateTimesheetFill: '' })
        console.log("e", e.target.value)
        e.target.value == '' ? this.setState({ date: is_add ? '' : date }) : <></>
        if (e.target.value != '') {
            this.setState({ clientNameId: e.target.value }, () => { })
            setTimeout(() => {
                this.getFillList(finYearOnbasicClientActionId, e.target.value)
                // this.getFillList(engagementTimeSheetActionId, e.target.value)

            }, 200);
        }
        else {
            this.setState({ finYearId: '', financialYearList: [], engagementId: '', engagementList: [], clientNameId: '' })
        }
        // this.setState({ clientNameId: e.target.value }, () => { })
        // setTimeout(() => {
        //     this.getFillList(finYearOnbasicClientActionId, e.target.value)
        //     sessionStorage.setItem('clientNameIdTimeSS', e.target.value)
        // }, 500);
    }

    onSelectFinYear(e) {
        const { clientNameId, engagementList } = this.state
        console.log("onSelectFinYear", `${clientNameId}-${e.target.value}`)
        this.setState({ finYearId: e.target.value })

        if (e.target.value != "") {
            // this.setState({ isLoading: true })
            this.getFillList(engagementTimeSheetActionId, clientNameId, e.target.value)
        } else {
            this.setState({ engagementId: '', engagementList: [] })
        }
        console.log("***********", e.target.value)

    }

    toCamelCase(str) {
        return str.replace(/[-_]+/g, ' ')
            .replace(/(?:^\w|[A-Z]|\b\w)/g, function (match, index) {
                return index === 0 ? match.toUpperCase() : match.toLowerCase();
            })
            .replace(/\s+/g, '');
    }

    handleHolidaySave() {
        this.onSave('', 1)
    }

    navigateToExpense() {
        const { timesheetId, clientNameId, finYearId, engagementId, transactionForId, date } = this.state
        console.log("****************timesheet to expense", timesheetId)
        this.props.navigate('/expenseU', {
            state: {
                is_add: true,
                is_navigateTimesheetToExpense: true,
                timeSheetExpenseID: timesheetId,
                clientNameId: clientNameId,
                transactionForId: transactionForId,
                finYearId: finYearId,
                engagementId: engagementId,
                date: date,
            }
        })
    }

    onSave(e, isVerified) {

        const { transactionForId, employeeId, clientNameId, finYearId,
            engagementId, taskId, transactionTypeId, description, date, fromTime, toTime, currentTimesheetTime,
            remark, rejectionReason, lastDateTimesheetFill, startDate, disableLastDateTimesheetFill, isClientDisable } = this.state

        this.setState({ isLoading: true })
        console.log('fromTime 34324', fromTime, moment(fromTime, 'HH:mm')['_i'])
        console.log('toTime2131', toTime, moment(toTime, 'HH:mm')['_i'])
        this.props
            .addTimesheet_action(transactionForId, employeeId, clientNameId, finYearId,
                engagementId, taskId, transactionTypeId, description, date,
                moment(fromTime, 'HH:mm')['_i'],
                moment(toTime, 'HH:mm')['_i'],
                remark, rejectionReason, isVerified)
            .then((data) => {
                this.setState({ isLoading: false, isOpen: false })
                if (data.error == 0) {
                    this.setState({ isApproved: false, timesheetId: data.data[0]['TimeSheetID'] })
                    console.log("data.data", data.data)
                    console.log("data.data[0]", data.data[0])
                    console.log("data.data[0]['TimeSheetID']", data.data[0]['TimeSheetID'])
                    this.setState({ isLoading: true })
                    toast.success("timesheet added successfully", {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    setTimeout(() => {
                        this.setState({ isTimesheetSuccsess: true, isLoading: false, isOpen: false })
                    }, 1000);
                }
                else {
                    if (data.msg == 'Adding TimeSheet on Holiday or Weekly Off: Are you sure?') {
                        this.setState({ isOpen: true, isApproved: false })
                    }
                    else {
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    onExpenseUpdate() {
        const { ExpenseId } = this.state
        console.log("ExpenseId!!", ExpenseId)
        if (ExpenseId != null) {
            this.props.navigate('/expenseU', { state: { is_update: true, is_Expenseupdate: true, UpdateTimeSheetExpenseID: ExpenseId } })
        }
    }

    onExpenseSave() {
        const { ExpenseId, timeSheetExpenseID } = this.state
        this.props.navigate('/expenseU', { state: { is_add: true, timeSheetExpenseID: timeSheetExpenseID } })

    }

    // update template
    handleUpdate(e) {
        e.preventDefault()
        const { transactionForId, employeeId, clientNameId, finYearId, startDate, endDate, isClientDisable,
            engagementId, taskId, transactionTypeId, description, date, fromTime, toTime,
            remark, rejectionReason } = this.state
        var id = this.props.location.state != ' ' && this.props.location.state.timesheetListId != '' ? this.props.location.state.timesheetListId : ''

        if ((date != "" && date != "Invalid Date" && date != null) && fromTime != "" && toTime != "" && transactionTypeId != 'Select' && transactionTypeId != "" && taskId != 'Select' && taskId != "" && description != "" && (transactionForId != '2' ? (clientNameId != '' && clientNameId != 'Select' && finYearId != '' && finYearId != 'Select' && engagementId != '' && engagementId != 'Select') : true) && transactionForId != '' && transactionForId != 'Select') {

            var isFollow = false;
            console.log("isClientDisable :", isClientDisable)
            console.log("isFollow 1:", isFollow)
            if (!isClientDisable) {
                // Compare if myDate is between strDate and endDate
                if (date >= startDate && date <= endDate) {
                    console.log('Date is between strDate and endDate');
                    isFollow = true
                } else {
                    isFollow = false
                    console.log('Date is not between strDate and endDate');
                    if (!isClientDisable) {
                        toast.error('Timesheet Date not in range of engagement date.', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                }
            } else {
                isFollow = true
            }
            console.log("isFollow 2:", isFollow)
            if (isFollow) {

                this.setState({ isLoading: true })
                this.props
                    .updateTimesheet_action(id, transactionForId, employeeId, clientNameId, finYearId,
                        engagementId, taskId, transactionTypeId, description, date, fromTime, toTime,
                        remark, rejectionReason)
                    .then((data) => {
                        if (data.error == 0) {
                            this.setState({ isLoading: false })
                            toast.success('Record updated successfully', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                            setTimeout(() => {
                                this.props.navigate('/timeSheet')
                            }, 1000);
                        }
                        else {
                            this.setState({ isLoading: false })
                            toast.error(data.msg, {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }

        } else {
            this.setState({ isLoading: false })
            toast.error('Please enter mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }


    }

    getStartEndDateList(engId) {
        const { engagementId, singleDisableLastDateTimesheet, currentDate, date, is_update } = this.state
        console.log("123", engagementId)
        this.setState({ isLoading: true })
        this.props
            .LastTimesheetFetch_action(engId != null ? engId : engagementId)
            .then((data) => {
                if (data.error != 1) {

                    const strDate = data.data[0]['StartDate'] != '' ? moment(data.data[0]['StartDate'], 'YYYY-MM-DD').toDate() : null
                    const endDate = data.data[0]['EndDate'] != '' ? moment(data.data[0]['EndDate'], 'YYYY-MM-DD').toDate() : null

                    if (is_update) {
                        console.log('date', date);
                        console.log('strDate', strDate);
                        console.log('endDate', endDate);
                        // Compare if myDate is between strDate and endDate
                        if (date >= strDate && date <= endDate) {
                            console.log('Date is between strDate and endDate');
                            // toast.error('Timesheet Date not in range of engagement date.', {
                            //     theme: 'colored',
                            //     autoClose: 3000,
                            //     hideProgressBar: true,
                            // })
                        } else {
                            console.log('Date is not between strDate and endDate');
                            toast.error('Timesheet Date not in range of engagement date.', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }
                    }


                    if (singleDisableLastDateTimesheet >= strDate && singleDisableLastDateTimesheet >= endDate) {
                        this.setState({ isDatePresent: true })
                    } else {
                        this.setState({ isDatePresent: false })
                    }

                    if (currentDate < endDate) {
                        this.setState({
                            startDate: strDate,
                            endDate: currentDate
                        })
                    } else {
                        this.setState({
                            startDate: strDate,
                            endDate: endDate
                        })
                    }

                    setTimeout(() => {
                        this.setState({ isLoading: false })
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })

                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    lastTimesheetFill(engId) {
        const { engagementId } = this.state
        console.log("engId", engId)
        console.log("engagementId", engagementId)
        this.setState({ isLoading: true })
        this.props
            .lastTimesheetFill_action(engId != null ? engId : engagementId)
            .then((data) => {
                if (data.error != 1) {
                    console.log("last timesheet fill", data.data)

                    this.setState({
                        disableLastDateTimesheetFill: data.data != '' ? moment(data.data[0]['Nextdate'], 'YYYY-MM-DD').toDate() : '', isLoading: false,
                        lastDateTimesheetFill: data.data != '' ? moment(data.data[0]['timesheetdate']).format('DD-MM-YYYY') : '',
                    })

                }
                else {
                    this.setState({ isLoading: false })

                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    singleLastTimesheet(engId) {
        const { engagementId, startDate, endDate, singleDisableLastDateTimesheet } = this.state
        console.log("engId", engId)
        console.log("engagementId", engagementId)
        this.setState({ isLoading: true })
        this.props
            .singleLastTimesheetDate_action(engId != null ? engId : engagementId)
            .then((data) => {
                if (data.error != 1) {
                    console.log("2", data.data[0][0]['TimeSheetDate'])

                    console.log("1", data.data[0]['TimeSheetDate'])
                    const date = data.data != '' ? moment(data.data[0]['TimeSheetDate'], 'YYYY-MM-DD').toDate() : ''
                    //  moment(data.data[0][0]['TimeSheetDate']).format('MM-DD-YYYY')

                    console.log("date", date)

                    const nextDate = new Date(date)
                    nextDate.setDate(nextDate.getDate() + 1)

                    console.log('*******************', nextDate)
                    const checkType = data.data[0]['TransactionTypeID']
                    console.log(" 343", checkType != 1 ? date : nextDate)
                    this.setState({
                        isLoading: false,
                        singleDisableLastDateTimesheet: checkType != 1 ? date : nextDate

                    })
                    console.log("singleDisableLastDateTimesheet", singleDisableLastDateTimesheet)
                    // singleDisableLastDateTimesheet: checkType != 1 ? date : nextDate

                }
                else {
                    this.setState({ isLoading: false })

                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // clear field ,list
    handleCancel() {
        this.props.navigate('/timeSheet')
    }

    handleReset() {
        this.setState({
            transactionForId: '',
            finYearId: '',
            clientNameId: '',
            engagementId: '',
            transactionTypeId: '',
            // employeeId: '',
            remark: '',
            taskId: '',
            description: '',
            date: '',
            fromTime: '',
            toTime: '',
            rejectionReason: '',

            financialYearList: [],
            engagementList: [],
        })
        // this.getClientsList()

    }


    onSelectTransactionForId(e) {
        const { date, is_add, currentDate } = this.state
        this.setState({ transactionForId: e.target.value })
        console.log("onSelectTransactionForId", e.target.value)
        if (e.target.value === '2') {
            this.setState({
                isClientDisable: true,
                clientNameId: '',
                finYearId: '',
                engagementId: '',
                // endDate: '',
                // startDate: '',
                // lastDateTimesheetFill: '',
                date: is_add ? '' : date,
                endDate: currentDate
            })
        } else {
            this.setState({ isClientDisable: false, date: is_add ? '' : date, })
        }
    }

    onSelectEngagementId(e) {
        const { is_add, date, strDate, endDate } = this.state
        this.setState({ engagementId: e.target.value, is_DateFlag: false })
        e.target.value != '' ? this.setState({ date: is_add ? '' : date }) : <></>
        setTimeout(() => {
            // && is_add ? 
            e.target.value != '' ? this.getStartEndDateList() : <></>
            e.target.value != '' && is_add ? this.lastTimesheetFill() : <></>
        }, 100);



    }

    onSelectDate(date) {
        const { } = this.state
        this.setState({ date: date })

    }

    //toggle model for delete.................................
    toggleModal() {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
    }

    addingTimeSheet() {
        const { date, fromTime, currentTimesheetTime, toTime, description, clientNameId, finYearId, engagementId, transactionForId, transactionTypeId, taskId } = this.state

        function getSystemTime() {
            var date = new Date();
            var hours = date.getHours();
            var minutes = date.getMinutes();

            hours = (hours < 10 ? '0' : '') + hours;
            minutes = (minutes < 10 ? '0' : '') + minutes;

            return hours + ':' + minutes;
        }

        const systemTime = getSystemTime();
        // console.log('System time:', systemTime);

        const findCurrentDateTime = new Date

        const currentDate = moment(findCurrentDateTime).format('DD-MM-YYYY')
        const checkedDate = moment(date).format('DD-MM-YYYY')
        console.log('date ******* time:', date);
        if ((date != "" && date != "Invalid Date" && date != null) && fromTime != "" && toTime != "" && transactionTypeId != 'Select' && transactionTypeId != "" && taskId != 'Select' && taskId != "" && description != "" && (transactionForId != '2' ? (clientNameId != '' && clientNameId != 'Select' && finYearId != '' && finYearId != 'Select' && engagementId != '' && engagementId != 'Select') : true) && transactionForId != '' && transactionForId != 'Select') {
            const { isApproved } = this.state

            if (currentDate == checkedDate) {
                if (systemTime >= currentTimesheetTime) {
                    this.setState({ isApproved: !isApproved })
                } else {
                    toast.error("You can not fill current date timesheet before 9 PM", {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            } else {
                this.setState({ isApproved: !isApproved })
            }
        } else {
            this.setState({ isLoading: false })
            toast.error('Please enter mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    expenseCancle() {
        this.setState({ isTimesheetSuccsess: false })
        this.props.navigate('/timeSheet')
    }

    onChange = (value) => {
        this.setState({ fromTime: value })
    };

    handleEnterKey = (dete, event) => {
        if (event.key === 'Enter') {
            this.setState({ date: dete });
        }
    }
    render() {
        const { isAccessToView, is_add, details_clientId, is_update, isOpen, is_allSelected, is_allDeselected, searchClientsName, qualityReviewerName, qualityReviewerNameList, isLoading,
            TimeSheetStatus, isTimePickerOpen,
            transactionForId, transactionForList, finYearId, engagementId, clientNameId, clientNameList,
            engagementList, transactionTypeId, transactionTypeList, employeeId, taskId,
            taskList, description, fromTime, toTime, rejectionReason, employeeList, financialYearList, isApproved, startDate, endDate, is_DateFlag, isClientDisable, timesheetDisable, isTimesheetSuccsess, singleDisableLastDateTimesheet, isDatePresent, currentDate } = this.state
        const minTime = new Date();
        minTime.setHours(9);
        minTime.setMinutes(0);
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    {/* partial:partials/_navbar.html */}
                    {/* partial */}
                    <div className="container-fluid page-body-wrapper">
                        {/* partial:partials/_settings-panel.html */}
                        <Drawer />
                        {/* start page */}
                        {/* {isAccessToView ? ( */}
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul className="breadcrumb">

                                        <li>
                                            <a onClick={this.handleCancel}>Timesheet </a>
                                        </li>
                                        <li>
                                            <a>{this.state.is_add ? 'New' : 'Update'}</a>
                                        </li>
                                        <li />
                                    </ul>
                                </div>
                                <div className=" user-status">
                                    {
                                        is_update && TimeSheetStatus != '' ? <p>
                                            <strong className={TimeSheetStatus
                                                === 'Entered' || 'Sent for Approval' || 'Approved' || 'Rejected' || 'Deleted' ? 'greena' : 'redi'}> {TimeSheetStatus
                                                }</strong>
                                        </p> : <></>
                                    }
                                </div>
                                <div className='page-space-from-bottom'>
                                    <div
                                        className="tab-content"
                                        id="myTabContent">
                                        <div className="row">
                                            <style
                                                type="text/css"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                                }}
                                            />
                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body">

                                                        <div className="d-flex flex-wrap row ">
                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    Transaction For
                                                                    <span className="required1">*</span>
                                                                </label>
                                                                <select
                                                                    //  className={is_update ? 'bg-secondary' : "js-example-basic-single"} style={{ width: "100%" }}

                                                                    disabled={timesheetDisable}
                                                                    className={"js-example-basic-single"} style={{ width: "100%" }}

                                                                    value={transactionForId}
                                                                    onChange={e => this.onSelectTransactionForId(e)}>
                                                                    <option value={''}>Select</option>
                                                                    {transactionForList.length > 0 ? transactionForList.map(item => (
                                                                        <option
                                                                            key={item.TransactionForID}
                                                                            value={item.TransactionForID}>
                                                                            {item.TransactionFor}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>

                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    Employee
                                                                    {/* <span className="required1">*</span> */}
                                                                </label>
                                                                <select
                                                                    className={"js-example-basic-single bg-secondary"}
                                                                    style={{ width: "100%" }}
                                                                    disabled={true}
                                                                    value={employeeId}
                                                                    onChange={e => this.setState({ employeeId: e.target.value })}
                                                                >
                                                                    <option value={''}>Select</option>
                                                                    {employeeList.length > 0 ? employeeList.map(item => (
                                                                        <option
                                                                            key={item.emmnID}
                                                                            value={item.emmnID}>
                                                                            {item.empsFirstName}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>

                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    Client
                                                                    <span className="required1">{!isClientDisable ? '*' : ''}</span>
                                                                </label>
                                                                <select
                                                                    // className={is_update || isClientDisable ? 'bg-secondary' : "js-example-basic-single"}
                                                                    className={isClientDisable ? 'bg-secondary' : "js-example-basic-single"}

                                                                    style={{ width: "100%" }}
                                                                    value={clientNameId}
                                                                    disabled={isClientDisable || timesheetDisable}
                                                                    onChange={e => this.onSelectClient(e)}
                                                                >
                                                                    <option value=''>Select</option>
                                                                    {clientNameList.length > 0 ? clientNameList.map(item => (
                                                                        <option
                                                                            key={item.clnnID}
                                                                            value={item.clnnID}>
                                                                            {item.clnsClientFName}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>

                                                        </div>

                                                        <div className="d-flex flex-wrap row mt-2">
                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    Financial Year
                                                                    <span className="required1">{!isClientDisable ? '*' : ''}</span>
                                                                </label>
                                                                <select
                                                                    // className={is_update || isClientDisable ? 'bg-secondary' : "js-example-basic-single"}
                                                                    className={isClientDisable ? 'bg-secondary' : "js-example-basic-single"}

                                                                    style={{ width: "100%" }}
                                                                    disabled={isClientDisable || timesheetDisable}
                                                                    value={finYearId} onChange={e => this.onSelectFinYear(e)}
                                                                >
                                                                    <option value={''}>Select</option>
                                                                    {financialYearList.length > 0 ? financialYearList.map(item => (
                                                                        <option
                                                                            key={item.FinYearID}
                                                                            value={item.FinYearID}>
                                                                            {item.FinYear}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>

                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    Engagement <span className="required1">{!isClientDisable ? '*' : ''}</span>
                                                                </label>
                                                                <select
                                                                    // className={is_update || isClientDisable ? 'bg-secondary' : "js-example-basic-single"}
                                                                    className={isClientDisable ? 'bg-secondary' : "js-example-basic-single"}

                                                                    style={{ width: "100%" }}
                                                                    disabled={isClientDisable || timesheetDisable}
                                                                    value={engagementId}
                                                                    onChange={e => this.onSelectEngagementId(e)}
                                                                >
                                                                    <option value={''}>Select</option>
                                                                    {engagementList.length > 0 ? engagementList.map(item => (
                                                                        <option
                                                                            key={item.EngagementID}
                                                                            value={item.EngagementID}>
                                                                            {item.MainEngagement}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    Task Type
                                                                    <span className="required1">*</span>
                                                                </label>
                                                                <select
                                                                    className={timesheetDisable ? 'bg-secondary' : "js-example-basic-single"}
                                                                    style={{ width: "100%" }}
                                                                    disabled={timesheetDisable}
                                                                    value={taskId}
                                                                    onChange={e => this.setState({ taskId: e.target.value })}
                                                                >
                                                                    <option value={''}>Select</option>
                                                                    {taskList.length > 0 ? taskList.map(item => (
                                                                        <option
                                                                            key={item.tsknID}
                                                                            value={item.tsknID}>
                                                                            {item.tsksTaskType}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>

                                                                {/* <select
                                                                className={timesheetDisable ? "js-example-basic-single bg-secondary w-100" : ""}
                                                                disabled={timesheetDisable}
                                                                style={{ width: "100%" }}
                                                                value={taskId} onChange={e => this.setState({ taskId: e.target.value })}

                                                            >
                                                                <option value={''}>Select</option>
                                                                {taskList.length > 0 ? taskList.map(item => (
                                                                    <option
                                                                        key={item.tsknID}
                                                                        value={item.tsknID}>
                                                                        {item.tsksTaskType}
                                                                    </option>
                                                                )) : <></>}
                                                            </select> */}
                                                            </div>
                                                        </div>

                                                        <div className="d-flex flex-wrap row mt-2">
                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    Transaction Type
                                                                    <span className="required1">*</span>
                                                                </label>
                                                                {/* <select
                                                                className={timesheetDisable ? "js-example-basic-single bg-secondary w-100" : ""}
                                                                disabled={timesheetDisable}
                                                                style={{ width: "100%" }}
                                                                value={transactionTypeId} onChange={e => this.setState({ transactionTypeId: e.target.value })}

                                                            >
                                                                <option value={''}>Select</option>
                                                                {transactionTypeList.length > 0 ? transactionTypeList.map(item => (
                                                                    <option
                                                                        key={item.TransactionTypeID}
                                                                        value={item.TransactionTypeID}>
                                                                        {item.Name}
                                                                    </option>
                                                                )) : <></>}
                                                            </select> */}
                                                                <select
                                                                    className={timesheetDisable ? 'bg-secondary' : "js-example-basic-single"}
                                                                    style={{ width: "100%" }}
                                                                    disabled={timesheetDisable}
                                                                    value={transactionTypeId}
                                                                    onChange={e => this.setState({ transactionTypeId: e.target.value })}
                                                                >
                                                                    <option value={''}>Select</option>
                                                                    {transactionTypeList.length > 0 ? transactionTypeList.map(item => (
                                                                        <option
                                                                            key={item.TransactionTypeID}
                                                                            value={item.TransactionTypeID}>
                                                                            {item.Name}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>

                                                            </div>

                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    Date<span className="required1">*</span>
                                                                </label>
                                                                <div className="form-group d-flex">
                                                                    <DatePicker className={timesheetDisable || is_update || (transactionForId != '2' ? engagementId == ''
                                                                        || is_DateFlag : '') ?
                                                                        "form-control js-example-basic-single bg-secondary w-100" :
                                                                        "form-control js-example-basic-single w-100"}
                                                                        dateFormat="dd-MM-yyyy"
                                                                        selected={this.state.date}
                                                                        disabled={timesheetDisable || is_update || (transactionForId != '2' ? engagementId == '' || is_DateFlag : '')}
                                                                        maxDate={endDate}
                                                                        onChange={(date) => {
                                                                            this.setState({ date: date });

                                                                        }}
                                                                        onKeyDown={(event) => {
                                                                            console.log("event.key------>",event.key)
                                                                            if (event.key === 'Enter') {
                                                                                const inputValue = event.target.value;
                                                                                const parsedDate = new Date(inputValue.split('-').reverse().join('-'))
                                                                                if (!isNaN(parsedDate)) {
                                                                                    this.setState({ date: parsedDate });  
                                                                                } 
                                                                            }
                                                                        }}
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        placeholderText=""
                                                                        customInput={<ExampleCustomInput />}
                                                                        dayClassName={() => "example-datepicker-day-class"}
                                                                        popperClassName="example-datepicker-class"
                                                                    />
                                                                    {/* <DatePicker
                                                                   
                                                                    className={timesheetDisable || is_update || (transactionForId != '2' ? engagementId == ''
                                                                        || is_DateFlag : '') ?
                                                                        "form-control js-example-basic-single bg-secondary w-100" :
                                                                        "form-control js-example-basic-single w-100"}
                                                                    dateFormat="dd-MM-yyyy"
                                                                    selected={this.state.date}
                                                                    disabled={timesheetDisable || is_update || (transactionForId != '2' ? engagementId == '' || is_DateFlag : '')}
                                                                    maxDate={endDate}
                                                                
                                                                    minDate={
                                                                        singleDisableLastDateTimesheet == '' ? startDate : singleDisableLastDateTimesheet
                                                                    }
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    placeholderText=""
                                                                    customInput={<ExampleCustomInput />}
                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                    popperClassName="example-datepicker-class"
                                                                /> */}
                                                                    {/* {console.log("lastDateTimesheetFill", disableLastDateTimesheetFill)} */}
                                                                    {/* <FaCalendarAlt style={{
                                                                    flex: '0 0 19%',
                                                                    maxWidth: '20%',
                                                                    color: 'grey',
                                                                    marginTop: '7px',
                                                                    marginLeft: '-5rem',
                                                                    zIndex: '0'
                                                                }} /> */}
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="d-flex flex-wrap row mt-2">
                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    From Time<span className="required1">*</span>
                                                                </label>
                                                                <div className="form-group d-flex">
                                                                    {/* <TimePicker
                                                                placeholder='hh:mm'
                                                                className='time-picker'
                                                                value={fromTime}
                                                                // value={fromTime != undefined ? fromTime.replace(/\s+(?:AM|PM)/, "") : ''}
                                                                // onChange={(e) => this.setState({ fromTime: fromTime.replace(/\s+(?:AM|PM)/, "") })}
                                                                step={60}
                                                            /> */}

                                                                    <TimePicker
                                                                        className={timesheetDisable ? "js-example-basic-single bg-secondary w-100 time-picker"
                                                                            : "js-example-basic-single w-100 time-picker"}
                                                                        disabled={timesheetDisable}
                                                                        onChange={(e, time) => {
                                                                            const selectedTime = moment(e['_d'], "HH:mm").format("HH:mm");
                                                                            console.log("time 3", selectedTime)
                                                                            this.setState({
                                                                                fromTime: selectedTime,
                                                                            });
                                                                        }}
                                                                        showSecond={false}
                                                                        value={moment(fromTime, 'HH:mm')}
                                                                    // defaultValue={moment('10:00', 'HH:mm')}
                                                                    />


                                                                    {/* <input type='time'
                                                                    className={timesheetDisable ? "js-example-basic-single bg-secondary w-100" : "js-example-basic-single form-control"}
                                                                    disabled={timesheetDisable}
                                                                    onChange={(e, time) => this.setState({ fromTime: e.target.value })}
                                                                    value={fromTime}
                                                                    format="HH:mm"
                                                                /> */}
                                                                </div>
                                                            </div>

                                                            <div className="form-group col-md-3">
                                                                <label>
                                                                    To  Time<span className="required1">*</span>
                                                                </label>
                                                                <div className="form-group d-flex">
                                                                    <TimePicker
                                                                        className={timesheetDisable ? "js-example-basic-single bg-secondary w-100 time-picker"
                                                                            : "js-example-basic-single w-100 time-picker"}
                                                                        disabled={timesheetDisable}
                                                                        onChange={(e, time) => {
                                                                            const selectedTime = moment(e['_d'], "HH:mm").format("HH:mm");
                                                                            console.log("time 3", selectedTime)
                                                                            this.setState({
                                                                                toTime: selectedTime,
                                                                            });
                                                                        }}
                                                                        value={moment(toTime, 'HH:mm')}
                                                                        showSecond={false}
                                                                    // defaultValue={moment('19:00', 'HH:mm')}
                                                                    />
                                                                    {/* <input type='time'
                                                                    className={timesheetDisable ? "js-example-basic-single bg-secondary w-100" : "js-example-basic-single form-control"}
                                                                    disabled={timesheetDisable}
                                                                    min={fromTime}
                                                                    onChange={(e, time) => this.setState({ toTime: e.target.value })} value={toTime} />
                                     */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <div className="d-flex flex-wrap row mt-2"> */}
                                                        <div className="d-flex flex-wrap row mt-2">
                                                            <div className="form-group col-md-6">
                                                                <label>
                                                                    Description <span className="required1">*</span>
                                                                </label>
                                                                <textarea
                                                                    type="text"
                                                                    className={timesheetDisable ? "js-example-basic-single bg-secondary w-100 h-auto border-0 rounded-1" : "js-example-basic-single form-control h-auto"}
                                                                    disabled={timesheetDisable}
                                                                    value={description}
                                                                    // rows={3}
                                                                    // cols={50}
                                                                    maxLength={500}
                                                                    onChange={(e) => this.setState({ description: e.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="d-flex flex-wrap row mt-2">
                                                            <div className="form-group col-md-6">
                                                                <label>Remark</label>
                                                                <textarea
                                                                    className={timesheetDisable ? "js-example-basic-single bg-secondary w-100 h-auto border-0 rounded-1" : "js-example-basic-single h-auto form-control"}
                                                                    disabled={timesheetDisable}
                                                                    name="remark"

                                                                    maxLength={500}
                                                                    // defaultValue={this.state.remark}
                                                                    value={this.state.remark}
                                                                    onChange={e => this.setState({ remark: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* </div> */}
                                                        {TimeSheetStatus === 'Rejected' ?
                                                            <div className="d-flex flex-wrap row mt-2">
                                                                <div className="form-group col-md-6">
                                                                    <label>Rejection Reason  </label>
                                                                    <div className="form-group mb-3">
                                                                        <textarea
                                                                            maxLength={500}
                                                                            className="form-group rounded-1"
                                                                            name="remark"
                                                                            style={{ border: "1px solid #dee2e6" }}
                                                                            disabled={true}
                                                                            value={rejectionReason}
                                                                            onChange={e => this.setState({ rejectionReason: e.target.value })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : <></>}
                                                        <div style={{ height: 50 }} />
                                                        <div className="row">
                                                            <div className="footer-btn">
                                                                <div className="col-md-3">
                                                                    <div
                                                                        className="btn-group"
                                                                        role="group"
                                                                        aria-label="Basic example"
                                                                    >

                                                                        {
                                                                            is_add && getModuleWriteAccess(Timesheet) ?
                                                                                <>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-save footer-btn-inner mr-1"
                                                                                        onClick={e => this.addingTimeSheet(e)}
                                                                                        disabled={isLoading}
                                                                                    > Save </button>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-update footer-btn-inner mr-1"
                                                                                        onClick={this.handleReset}
                                                                                    >
                                                                                        Reset
                                                                                    </button>

                                                                                </>
                                                                                :

                                                                                <>
                                                                                    {!timesheetDisable && getModuleUpdateAccess(Timesheet) ?
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-update footer-btn-inner mr-1"
                                                                                            onClick={e => this.handleUpdate(e)}
                                                                                            disabled={isLoading}
                                                                                        > Update </button> : <></>
                                                                                    }
                                                                                </>


                                                                        }






                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-cancel footer-btn-inner"
                                                                            onClick={this.handleCancel}
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end page */}
                            </div>
                            {/* page-body-wrapper ends */}
                        </div>
                        {/* ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)} */}
                    </div>
                </div >
                <ToastContainer />

                <Modal
                    isOpen={isOpen}
                    onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Add Timesheet</h4>
                                {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Adding TimeSheet on Holiday or Weekly Off: Are you sure?</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot" >
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleHolidaySave()}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn  del-pop-cancel" data-dismiss="modal"
                                            style={{ marginLeft: '5px' }} onClick={(e) => this.toggleModal()}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={isApproved}
                    onRequestClose={this.addingTimeSheet}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Add Timesheet</h4>
                                <button type="button " className="close pop-close" data-dismiss="modal" onClick={() => this.addingTimeSheet()} ><span aria-hidden="true" className="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Are you sure to Add TimeSheet ?</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot" >
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.onSave(e, '')}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn  del-pop-cancel" data-dismiss="modal"
                                            style={{ marginLeft: '5px' }} onClick={(e) => this.addingTimeSheet()}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={isTimesheetSuccsess}
                    onRequestClose={!isTimesheetSuccsess}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Add Timesheet</h4>
                                <button type="button " className="close pop-close" data-dismiss="modal" onClick={() => this.handleCancel()} ><span aria-hidden="true" className="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you want to add Expense also ?</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot" >
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.navigateToExpense()}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn  del-pop-cancel" data-dismiss="modal"
                                            style={{ marginLeft: '5px' }} onClick={() => this.handleCancel()}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>

            </>
        );
    }
}

export default withRouter(connect(null, {
    addTimesheet_action, updateTimesheet_action,
    TimesheetDetails_action, approvalTimesheet_action, LastTimesheetFetch_action,
    fill_action, lastTimesheetFill_action, singleLastTimesheetDate_action
})(TimesheetAU));