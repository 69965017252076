import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    platfarm, headers,
    TRAINING_REPORT_ACTION_TYPE,reportTrainingViewUrl,TRAINING_REPORT_VIEW_ACTION_TYPE,
    UserProfileStatus,fetchAuthReport
} from "../../utils/constants"
import moment from 'moment';


export const getReportUserProfile_action = (FinacialYearID,Training_EmployeeID,StatusID,ReportType) => async (dispatch) => {
    try {
       
        var list = [];
        const mapdata1 = {
            "ParameterName":"@UserID",
            "ParameterValue": parseInt(localStorage.getItem('UserId')),
            "ParameterDataType":"int"
        }
        list.push(mapdata1);
        // const mapdata2 = {
        //     "ParameterName":"@EmployeeID",
        //     "ParameterValue": parseInt( (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID']),
        //     "ParameterDataType":"int"
        // }
        // list.push(mapdata2);
        const mapdata3 = {
            "ParameterName":"@Platform",
            "ParameterValue":platfarm,
            "ParameterDataType":"varchar"
        }
        list.push(mapdata3);
        const mapdata4 = {
            "ParameterName":"@FinyearID",
            "ParameterValue":parseInt(FinacialYearID),
            "ParameterDataType":"int"
        }
        list.push(mapdata4);
        const mapdata5 = {
            "ParameterName":"@EmployeeID",
            "ParameterValue":Training_EmployeeID,
            "ParameterDataType":"varchar"
        }
        list.push(mapdata5 );
        // const mapdata5 = {
        //     "ParameterName":"@Training_EmployeeID",
        //     "ParameterValue": parseInt(Training_EmployeeID),
        //     "ParameterDataType":"int"
        // }
        // list.push(mapdata5);
        // const mapdata6 = {
        //     "ParameterName":"@StatusID",
        //     "ParameterValue": parseInt(StatusID),
        //     "ParameterDataType":"int"
        // }
        // list.push(mapdata6);
        // const mapdata7 = {
        //     "ParameterName":"@ReportType",
        //     "ParameterValue":ReportType,
        //     "ParameterDataType":"int"
        // }
        // list.push(mapdata7);

        const mapData = {
            "user_id": localStorage.getItem('UserId'),
            "report_name": UserProfileStatus,
            "parameter_details": list
        }
     

        const res = await axiosPost(fetchAuthReport, mapData);
        dispatch({
            type: TRAINING_REPORT_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const getReportUserProfileView_action = (FinacialYearID,Training_EmployeeID,StatusID,ReportType) => async (dispatch) => {
        try {
            const param = {
                UserID: localStorage.getItem('UserId'),
                EmployeeID: parseInt( (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID']),
                Platform: platfarm,
                FinacialYearID : FinacialYearID,

                Training_EmployeeID: Training_EmployeeID,
                StatusID: StatusID,
                ReportType: ReportType,
              
            }
            console.log(param)
            const res = await axiosGet(reportTrainingViewUrl, param)
            console.log(res)
            dispatch({
                type: TRAINING_REPORT_VIEW_ACTION_TYPE,
                payload: res
            })

            return Promise.resolve(res)
        }
        catch (err) {
            return Promise.reject(err)
        }
    }