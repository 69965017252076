import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { fill_action } from '../../../actions/master_action/checklist_action'
import {
    reviewChecklistDetails_action, updateReviewChecklist_action,
    closeReviewChecklist_action, deleteFile_action, uploadFile_action, GetFile_action
} from '../../../actions/engagement_action/review_checklist_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Modal from "react-modal";
import { updateChecklistReviewedByActionId, clearLocalStorage, Review_Checklist } from '../../../utils/constants'
import Pagination from '../../../utils/pagination'
import Loader from '../../../utils/Loader'
import moment from 'moment';
import Resizer from "react-image-file-resizer";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import _default from 'react-redux/es/components/connect';
import { getModuleUpdateAccess } from '../../../utils/RoleAccess';

/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
    // loginAction: () => dispatch(loginAction())
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})


class ReviewChecklistU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reviewChecklistData: [],
            isLoading: false,
            isOpen: false,
            isOpenDeleteFile: false,
            OpenDeleteFile : [],
            engagementAllocationlistDeleteId: '',
            reviewChecklistId: '',
            is_update: false,
            isLoading: false,
            isOpen: false,
            reviewedByList: [],
            preparedById: '',
            checklistList: [],
            remark: '',
            dissableList: [],
            isImageModalOpen: false,
            imageName: '',
            isFilePdf: false,
            isFileExcel: false,
            isFileImg: false,
            isShowMore: false,
            isDetails: false,
            allowedFileTypes: []
        }
        this.handleCancel = this.handleCancel.bind(this)
        this.getReviewChecklistDetails = this.getReviewChecklistDetails.bind(this)
        this.handleRemark = this.handleRemark.bind(this)
        this.onSelectReviewed = this.onSelectReviewed.bind(this)
        this.onSelectReviewedBy = this.onSelectReviewedBy.bind(this)

        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.onPdfSelect = this.onPdfSelect.bind(this)
        this.onImageCancelClick = this.onImageCancelClick.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.handleDeleteFile = this.handleDeleteFile.bind(this)
        this.handleUploadFile = this.handleUploadFile.bind(this)
        this.handleGetFile = this.handleGetFile.bind(this)
        this.toggleModalDisplayImage = this.toggleModalDisplayImage.bind(this)
        this.toggleModalDisplayImageClose = this.toggleModalDisplayImageClose.bind(this)
        this.onDownloadClick = this.onDownloadClick.bind(this)
        this.fileInputRef = React.createRef();
        this.toggleModalDeleteFile = this.toggleModalDeleteFile.bind(this)

    }



    componentDidMount = () => {
        this.setState({
            reviewChecklistId: this.props.location.state.reviewChecklistId != '' ? this.props.location.state.reviewChecklistId : '',
        })
        this.getReviewChecklistDetails()
        this.getFillList(updateChecklistReviewedByActionId, this.props.location.state.reviewChecklistId)
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);
        this.setState({
            allowedFileTypes: ['.pdf', '.jpeg', '.png', '.jpg', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx']
        })
    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);

    }



    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };
    // get fill List dropdown  
    getFillList(id, reference) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(id, reference)
            .then((data) => {
                if (data.error != 1) {
                    if (id === updateChecklistReviewedByActionId) {
                        this.setState({
                            reviewedByList: data.data,
                        })
                    }
                    this.setState({
                        isLoading: false
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    // delete file
    toggleModalDeleteFile = (item ) => {
        const { isOpenDeleteFile } = this.state;
        this.setState({ isOpenDeleteFile: !isOpenDeleteFile, OpenDeleteFile : item }); // Reset if the clicked element is not a target

            // this.handleDeleteFile(item)
    }

    // get review checklist   details
    getReviewChecklistDetails() {
        const { reviewChecklistData, dissableList, isDetails } = this.state
        var id = this.props.location.state.reviewChecklistId
        this.setState({ isLoading: true })
        // await this.getClientsList()
        this.props
            .reviewChecklistDetails_action(id)
            .then((data) => {
                if (data.error != 1) {

                    var checklist = data.data['checkList']
                    for (var i = 0; i < checklist.length; i++) {
                        checklist[i].file = '';
                        checklist[i].fileName = '';
                        checklist[i].ActionID = 0;
                        checklist[i].isDisabled = checklist[i].IsReviewed;
                        dissableList.push(checklist[i].IsReviewed)
                    }

                    this.setState({
                        reviewChecklistData: data.data != null && data.data,
                        checklistList: checklist != null && checklist,
                        isLoading: false,
                        status: data.data['Status'] != null && data.data['Status'],
                        isDetails: true
                    })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })

    }


    handleKeyDown(e, index) {
        if (e.code === 'Enter') {
            this.handleRemark(e, index)
        }
    }

    handleRemark(e, index) {
        const { checklistList } = this.state

        checklistList[index]['ReviewedByRemark'] = e.target.value
        checklistList[index]['ActionID'] = 2 // 
        this.setState({ checklistList: checklistList })
    }

    // clear field ,list
    handleCancel() {
        this.props.navigate('/reviewChecklist')
    }

    //toggle model for delete.................................
    toggleModal() {
        const { isOpen, reviewChecklistId } = this.state
        this.setState({ isOpen: !isOpen })

    }

    onSelectReviewed(e, data, index) {
        const { checklistList, dissableList } = this.state
        if (e.target.checked) {
            // checklistList[index]['IsReviewed'] = !checklistList[index]['IsReviewed']
            checklistList[index]['IsReviewed'] = true

            checklistList[index]['ReviewedBy'] = localStorage.getItem('UserId')
        }
        else {
            if (!checklistList[index]['Isok']) {
                // checklistList[index]['IsReviewed'] = !checklistList[index]['IsReviewed']
                checklistList[index]['IsReviewed'] = false

                checklistList[index]['ReviewedByRemark'] = ''
                checklistList[index]['ReviewedBy'] = ''
            }

        }
        if (!checklistList[index]['isDisabled']) {
            checklistList[index]['ActionID'] = 2
        }
        else {
            checklistList[index]['ActionID'] = 0
        }
        checklistList[index]['ReviewedDate'] = null
        checklistList[index]['CompletedBy'] = localStorage.getItem('UserId')
        this.setState({ checklistList: checklistList })
    }

    onSelectIsOk(e, data, index) {
        const { checklistList, dissableList } = this.state
        if (e.target.checked) {
            // checklistList[index]['Isok'] = !checklistList[index]['Isok']
            checklistList[index]['Isok'] = true

            // checklistList[index]['IsReviewed'] = !checklistList[index]['IsReviewed']
            checklistList[index]['IsReviewed'] = true

            checklistList[index]['ReviewedBy'] = localStorage.getItem('UserId')
        }
        else {
            // checklistList[index]['Isok'] = !checklistList[index]['Isok']
            checklistList[index]['Isok'] = false
        }

        checklistList[index]['ActionID'] = 2

        this.setState({ checklistList: checklistList })
    }

    onSelectReviewedBy(e, index) {
        const { checklistList } = this.state
        checklistList[index]['ReviewedBy'] = parseInt(e.target.value)
        checklistList[index]['ReviewerName'] = e.target.label

        this.setState({ checklistList: checklistList })
    }

    // update financial year 
    handleUpdate(e) {
        e.preventDefault()
        const { checklistList } = this.state
        const id = this.props.location.state.reviewChecklistId;
        if (id != "" && checklistList != "") {
            this.setState({ isLoading: true })
            checklistList.map((item, index) => {
                // item.ActionID = 2
                item.EngagementChecklistID = item.EngagementChecklistID
                item.IsCompleted = null
                item.CompletedDate = null
                item.file = null
                item.fileName = null

                // item.File = item.File != null ? item.File : null
            })
            var list = checklistList.filter((item) => (item.ActionID === 2))

            this.props
                .updateReviewChecklist_action(id, checklistList)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data reviewd successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false,  })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }

    // on close
    handleClose(e) {
        e.preventDefault()
        const { remark } = this.state
        this.setState({ isLoading: true })
        const id = this.props.location.state.reviewChecklistId;
        if (remark != '' && id != '') {
            this.props
                .closeReviewChecklist_action(id, remark)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Checklist closed successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isOpen: !this.state.isOpen, isLoading: false, remark: " " })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  Remark field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    handleDeleteFile(checklist) {
        const { file, fileName, isOpenDeleteFile } = this.state
        this.setState({ isLoading: true })
        var id = checklist.EngagementChecklistID
        if (id != '') {
            this.props
                .deleteFile_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('File deleted successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        // this.handleGetFile(id)
                        // this.getReviewChecklistDetails()
                        checklist['file'] = '';
                        checklist['fileName'] = '';
                        checklist['IsFileAvailable'] = 0;
                        this.setState({ isLoading: false , isOpenDeleteFile : !isOpenDeleteFile})
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Something went wrong', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    onDownloadClick() {
        const { imageName } = this.state
        window.location.href = imageName;
        this.setState({ imageName: '' })
    }

    handleGetFile(id) {
        const { file, fileName, imageName } = this.state
        this.setState({ isLoading: true, imageName: '' })
        if (id != '') {
            this.props
                .GetFile_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ imageName: data.data[0]['File'], isLoading: false })
                        if (data.data[0]['File'].includes('spreadsheetml.sheet')
                            || data.data[0]['File'].includes('.ms-powerpoint')
                            || data.data[0]['File'].includes('.presentation')
                            || data.data[0]['File'].includes('.doc')
                            || data.data[0]['File'].includes('msword')
                            || data.data[0]['File'].includes('.document')) {
                            this.setState({ isFileExcel: true, isFilePdf: false, isFileImg: false })

                        }
                        else if (data.data[0]['File'].includes('pdf')) {
                            this.setState({ isFilePdf: true, isFileExcel: false, isFileImg: false })
                            this.toggleModalDisplayImage()
                        }

                        else if (data.data[0]['File'].includes('jpeg') || data.data[0]['File'].includes('jpg') || data.data[0]['File'].includes('png')) {
                            this.setState({ isFileImg: true, isFilePdf: false, isFileExcel: false })

                        }
                        else {
                            // this.setState({ isFilePdf: false})
                        }
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Something went wrong', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    handleUploadFile(id, file) {
        const { fileName } = this.state
        this.setState({ isLoading: true })
        if (file != '' && id != '') {
            this.props
                .uploadFile_action(id, file)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('File uploaded successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Something went wrong', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    onFilePick = async (e, file, data) => {
        e.preventDefault()
        const { checklistList, allowedFileTypes } = this.state
        if (file[0]['size'] > 5242880) {
            toast.error('File size more than 5mb are not allow.', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
            // Clear the file input field
            if (this.fileInputRef.current) {
                this.fileInputRef.current.value = ''; // Clear the input value
            }

            return;

        } else {
            var image = '';
            if (file[0]['name'].toString().toLowerCase().includes('.pdf') ||
                file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                file[0]['name'].toString().toLowerCase().includes('.png') ||
                file[0]['name'].toString().toLowerCase().includes('.jpg') ||
                file[0]['name'].toString().toLowerCase().includes('.doc') ||
                file[0]['name'].toString().toLowerCase().includes('.docx') ||
                file[0]['name'].toString().toLowerCase().includes('.xls') ||
                file[0]['name'].toString().toLowerCase().includes('.xlsx') ||
                file[0]['name'].toString().toLowerCase().includes('.ppt') ||
                file[0]['name'].toString().toLowerCase().includes('.pptx')
            ) {
                if (file[0]['name'].toString().toLowerCase().includes('.jpg') ||
                    file[0]['name'].toString().toLowerCase().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpeg')) {
                    image = await this.resizeFile(file[0]);
                } else {
                    image = await this.onPdfSelect(file[0]);
                }
                // image = window.URL.createObjectURL(file[0])
                var checklist = checklistList;
                for (var i = 0; i < checklist.length; i++) {
                    if (checklist[i]['EngagementChecklistID'] == data.EngagementChecklistID) {
                        if (checklist[i]['IsReviewed']) {
                            checklist[i]['file'] = image;
                            checklist[i]['fileName'] = file[0]['name'];
                            checklist[i]['IsFileAvailable'] = 1;
                            this.handleUploadFile(checklist[i]['EngagementChecklistID'], checklist[i]['file'])
                        }
                        else {
                            toast.error('Please select reviewed', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }


                    }
                }
                this.setState({ checklistList: [...checklist] });

            } else {
                toast.error(`Allowed File types: ${allowedFileTypes.map((type) => type.toUpperCase().replace('.', '')).join(', ')}. Maximum size: 5 MB`, {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                // Clear the file input field
                if (this.fileInputRef.current) {
                    this.fileInputRef.current.value = ''; // Clear the input value
                }

                return;
            }

        }

    }

    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);

        });

    onImageCancelClick = (e, data) => {
        e.preventDefault()
        const { checklistList } = this.state
        var checklist = checklistList;
        for (var i = 0; i < checklist.length; i++) {
            if (checklist[i]['EngagementChecklistID'] == data.EngagementChecklistID) {
                checklist[i]['file'] = '';
                checklist[i]['fileName'] = '';
            }
        }
        this.setState({ checklistList: [...checklist] });
    }

    //toggle model for img file.................................
    toggleModalDisplayImage() {
        const { isImageModalOpen } = this.state
        // this.handleGetFile(data.EngagementChecklistID)
        this.setState({ isImageModalOpen: !isImageModalOpen, isLoading: false })
    }

    toggleModalDisplayImageClose() {
        const { isImageModalOpen } = this.state
        // this.handleGetFile(data.EngagementChecklistID)
        this.setState({ isImageModalOpen: !isImageModalOpen, isLoading: false, imageName: '' })
    }

    render() {
        const { isLoading, reviewChecklistData, remark, OpenDeleteFile, isShowMore, is_update, isDetails, allowedFileTypes,
            checklistList, isOpen, isOpenDeleteFile, dissableList, isImageModalOpen, imageName, isFilePdf,
            clientName, engPartnerName, qualityReviewerName, engagementTypeId, status, reviewChecklistId, isFileExcel, isFileImg
        } = this.state


        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <Header />

                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        <>
                            {/* start page */}
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li>
                                                <a href="#">Review Checklist</a>
                                            </li>
                                            <li />
                                        </ul>
                                    </div>

                                    <div className="user-status">
                                        <div className="">
                                            {isDetails && reviewChecklistData['Status'] != '' ?
                                                <p>
                                                    <strong className={reviewChecklistData['Status'] === 'In-Process' ? 'greena' :
                                                        reviewChecklistData['Status'] == '' ? null : 'redi'}>
                                                        {reviewChecklistData['Status'] != null ? reviewChecklistData['Status'] : ''}</strong>
                                                </p>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                    <div className='page-space-from-bottom'>
                                        <div className="row">

                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body">
                                                        {/* <div className="row user-status">
                                                        <p>
                                                            <strong className={reviewChecklistData['Status'] === 'In-Process' ? 'greena' : 'redi'}>
                                                                {reviewChecklistData['Status'] != null ? reviewChecklistData['Status'] : ''}</strong>
                                                        </p>
                                                    </div> */}
                                                        <div className="col-md-12 mb-3  mt-2">
                                                            <div>
                                                                <div>
                                                                    <div className="d-flex flex-wrap row">
                                                                        {/* <div class="form-group col-md-3">
                                                      <label>Company</label>
                                                       <p>Natvarlal Vepari & Co</p>
                                              </div> */}
                                                                        <div className="form-group col-md-3 ">
                                                                            <label>Client</label>
                                                                            <p>{reviewChecklistData['ClientName'] != null ? reviewChecklistData['ClientName'] : ''}</p>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Engagement Type</label>
                                                                            <p>{reviewChecklistData['EngagementType'] != null ? reviewChecklistData['EngagementType'] : ''}</p>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Financial Year</label>
                                                                            <p>{reviewChecklistData['FinancialYear'] != null ? reviewChecklistData['FinancialYear'] : ''}</p>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Location</label>
                                                                            <p>{reviewChecklistData['LocationName'] != null ? reviewChecklistData['LocationName'] : ''}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-wrap row mt-2">
                                                                        <div className="form-group col-md-3">
                                                                            <label>Audit Period</label>
                                                                            <p>{reviewChecklistData.AuditFromDate != null ? moment(reviewChecklistData.AuditFromDate).format('DD-MM-YYYY') : ''} -

                                                                                {reviewChecklistData.AuditToDate != null ? moment(reviewChecklistData.AuditToDate).format('DD-MM-YYYY') : ""}</p>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Start Date - End Date</label>
                                                                            <p>{reviewChecklistData.StartDate != null ? moment(reviewChecklistData.StartDate).format('DD-MM-YYYY') : ''} -
                                                                                {reviewChecklistData.StartDate != null ? moment(reviewChecklistData.StartDate).format('DD-MM-YYYY') : ''}</p>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Expected Closure Date</label>
                                                                            <p>{reviewChecklistData.ExpectedClosureDate != null ? moment(reviewChecklistData.ExpectedClosureDate).format('DD-MM-YYYY') : ''}</p>
                                                                        </div>
                                                                    </div>
                                                                    {/* Show More Start Here */}

                                                                    {!isShowMore ?
                                                                        <></>
                                                                        :
                                                                        // <div className="content">
                                                                        <div className="d-flex flex-wrap row mt-1">
                                                                            <div className="form-group col-md-3">
                                                                                <label>Engagement Head</label>
                                                                                <p>{reviewChecklistData['EngagementHead'] != null ? reviewChecklistData['EngagementHead'] : ''}</p>
                                                                            </div>
                                                                            <div className="form-group col-md-3">
                                                                                <label>Engagement Partner</label>
                                                                                <p>{reviewChecklistData['EngPartnerName'] != null ? reviewChecklistData['EngPartnerName'] : ''}</p>
                                                                            </div>
                                                                            <div className="form-group col-md-3">
                                                                                <label>Quality Reviewer</label>
                                                                                <p>{reviewChecklistData['QualityReviewerName'] != null ? reviewChecklistData['QualityReviewerName'] : ''}</p>
                                                                            </div>
                                                                            <div class="form-group col-md-3">
                                                                                <label>Team Member</label>
                                                                                <p>{reviewChecklistData['TeamMemberName'] != null ? reviewChecklistData['TeamMemberName'] : ''}</p>


                                                                            </div>
                                                                        </div>
                                                                        // </div>
                                                                    }
                                                                    {isShowMore ?
                                                                        <p style={{ color: "#1683ff" }}>
                                                                            {" "}
                                                                            <button className='btn btn-outline-info p-1 py-1 pt-2 pb-2 px-2 rounded-1' onClick={() => this.setState({ isShowMore: !isShowMore })}>
                                                                                <i className="fa fa-arrow-up" /> Less
                                                                            </button>
                                                                        </p>
                                                                        :
                                                                        <p style={{ color: "#1683ff" }}>
                                                                            {" "}
                                                                            <button className='btn btn-outline-info p-1 py-1 pb-2 px-2 rounded-1' onClick={() => this.setState({ isShowMore: !isShowMore })}>
                                                                                <i className="fa fa-plus" /> Show More
                                                                            </button>

                                                                        </p>}

                                                                    {/* Show More End Here */}
                                                                    <div className="row mt-2">
                                                                        <div className="table-responsive tbs4 common-table trail-tb">
                                                                            <table className="table table-bordered tb-action du-table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="text-center">Sr. No</th>
                                                                                        <th>Checklist</th>
                                                                                        <th style={{ width: 100 }}>E/Q</th>
                                                                                        <th
                                                                                            style={{ width: 100 }}
                                                                                            className="text-center"
                                                                                        >
                                                                                            Critical
                                                                                        </th>
                                                                                        <th>Reviewed</th>
                                                                                        <th>IS OK</th>
                                                                                        {/* <th>Reviewed By</th> */}
                                                                                        <th>Reviewed By Remark</th>
                                                                                        <th>Prepared By Remark</th>
                                                                                        <th style={{width:'115px'}}>Upload File</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        checklistList.length > 0 ? checklistList.map((item, index) => (
                                                                                            <tr>
                                                                                                <td className="text-center">{index + 1}</td>
                                                                                                <td style={{ textAlign: 'left' }}>{item.ChecklistName}</td>
                                                                                                <td>{item.Name}</td>
                                                                                                <td className="text-center">{item.IsCritical ? 'Critical' : 'Non-Critical'}</td>
                                                                                                <td>{" "}<input type="checkbox"
                                                                                                    className={item.isDisabled && 'bg-secondary'}
                                                                                                    checked={item.IsReviewed}
                                                                                                    value={item.IsReviewed}
                                                                                                    disabled={item.IsFileAvailable}
                                                                                                    onClick={e => this.onSelectReviewed(e, item, index)}
                                                                                                /></td>
                                                                                                <td>{" "}<input type="checkbox"
                                                                                                    className={item.isDisabled && 'bg-secondary'}
                                                                                                    checked={item.Isok}
                                                                                                    value={item.Isok}
                                                                                                    onClick={e => this.onSelectIsOk(e, item, index)}
                                                                                                /></td>
                                                                                                {/* <td style={{ padding: 0 }}>
                                                                                                <select
                                                                                                    className={item.isDisabled ? 'bg-secondary js-example-basic-single' : 'js-example-basic-single'}
                                                                                                    style={{
                                                                                                        width: "100%",
                                                                                                        height: 38,
                                                                                                        border: "none",
                                                                                                        background: "#eef7f9"
                                                                                                    }}
                                                                                                    value={item.ReviewedBy}
                                                                                                    disabled={item.isDisabled || !item.IsReviewed ? true : false}
                                                                                                    onChange={e => this.onSelectReviewedBy(e, index)}>
                                                                                                     <option value={''}>Select</option>
                                                                                                    {reviewedByList.map(item => (
                                                                                                        <option
                                                                                                            key={item.EngagementPartnerID}
                                                                                                            value={item.EngagementPartnerID}>
                                                                                                            {item.EngagementPartner}
                                                                                                        </option>
                                                                                                    ))}

                                                                                                </select>
                                                                                            </td> */}
                                                                                                <td style={{ padding: 0 }}>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className={item.isDisabled && 'bg-secondary'}
                                                                                                        style={{ padding: 7, background: "#eef7f9", width:'100%' }}
                                                                                                        value={item.ReviewedByRemark}
                                                                                                        disabled={item.IsFileAvailable || !item.IsReviewed ? true : false}
                                                                                                        onChange={e => this.handleRemark(e, index)}
                                                                                                        onKeyDown={e => this.handleKeyDown(e, index)}
                                                                                                    />
                                                                                                </td>
                                                                                                <td><p>{item.CompletedByRemark}</p></td>
                                                                                                {/* <td style={{ padding: 0 }}>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    style={{ padding: 7, background: "#eef7f9" }}
                                                                                                    value={item.CompletedByRemark}
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </td> */}
                                                                                                <td style={{ padding: '0px 4px !important' }}>

                                                                                                    {item.IsFileAvailable ? (<>
                                                                                                        <div className="col grid-upload-file d-flex justify-content-beetween">
                                                                                                            <div className='col'>
                                                                                                                <button className='btn btn-info btn-sm rounded-0 p-1 full-btn'
                                                                                                                    onClick={(e) => this.handleGetFile(item.EngagementChecklistID)}>


                                                                                                                    {isFileExcel ? <a className='text-light' onClick={this.onDownloadClick} download> Download</a> : 'View'} </button>
                                                                                                                {/* href={imageName} target={'_blank'}  <i class= {imageName != '' && !isFilePdf ? "fa fa-picture-o" : "fa fa-file-pdf-o"} aria-hidden="true" onClick={(e) => this.handleGetFile(item.EngagementChecklistID)}></i> */}
                                                                                                                {
                                                                                                                    imageName != '' && isFileImg && !isFilePdf &&
                                                                                                                    (<Lightbox image={imageName}
                                                                                                                        onClose={() =>
                                                                                                                            this.setState({ imageName: '' })
                                                                                                                        }
                                                                                                                        style={{ width: '200px', height: '500px' }} />
                                                                                                                    )
                                                                                                                }
                                                                                                                {/* {
                                                                                                                imageName != '' && isFileExcel &&
                                                                                                                (<a href={imageName} download> Download</a>   )
                                                                                                            } */}
                                                                                                            </div>

                                                                                                            <div classname="col">
                                                                                                                <i class="fa fa-times img_cancel" onClick={(e) => this.toggleModalDeleteFile(item ,)}></i></div>
                                                                                                        </div>
                                                                                                    </>)
                                                                                                        : (<>
                                                                                                            <form encType="multipart/form-data" style={{position:'relative'}}>
                                                                                                                <div className="grid-upload-file d-flex">
                                                                                                                    <input type="file" ref={this.fileInputRef} // Attach ref here
                                                                                                                        onChange={(e) => this.onFilePick(e, e.target.files, item)} accept={".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"} />
                                                                                                                    <a
                                                                                                                        className="btn btn-info btn-sm remove upload-btn rounded-0 p-1 text-white"
                                                                                                                        style={{ lineHeight: "21px!important" }}
                                                                                                                    >
                                                                                                                        <span className="glyphicon glyphicon-upload" />
                                                                                                                        Upload File

                                                                                                                    </a>
                                                                                                                </div>
                                                                                                                <i class="fa fa-info-circle file_info"
                                                                                                                    title={`Allowed File types ${allowedFileTypes.map((type) => type.toUpperCase().replace('.', '')).join(', ')} with maximum size 5 MB`} ></i>


                                                                                                            </form>
                                                                                                        </>)}

                                                                                                </td>
                                                                                            </tr>
                                                                                        )) : (<tr ><td colSpan={18} style={{ textAlign: 'center' }}>Check list is not available</td></tr>)
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="footer-btn">
                                                                        <div className="col-md-3">
                                                                            <div
                                                                                className="btn-group"
                                                                                role="group"
                                                                                aria-label="Basic example"
                                                                            >
                                                                                {isDetails ?
                                                                                    reviewChecklistData['Status'] === 'Closed' || reviewChecklistData['Status'] === 'Cancelled' ? null :
                                                                                    <>
                                                                                    {getModuleUpdateAccess(Review_Checklist) && <button
                                                                                            type="button"
                                                                                            className="btn btn-update footer-btn-inner mr-1"
                                                                                            onClick={this.handleUpdate}
                                                                                        >
                                                                                            Update
                                                                                        </button>
                                                                                    }
                                                                                    </>
                                                                                    : <></>

                                                                                }
                                                                                {/* <button
                                                                                type="button"
                                                                                className="btn btn-delete footer-btn-inner mr-1"
                                                                                data-toggle="modal"
                                                                                data-target="#close"
                                                                                onClick={this.toggleModal}
                                                                            >
                                                                                Close
                                                                            </button> */}
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-cancel footer-btn-inner"
                                                                                    onClick={this.handleCancel}
                                                                                >
                                                                                    Cancel
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                            {/* //====  Modal for file image*/}
                            <Modal
                                isOpen={isImageModalOpen} style={{ width: '100% !important' }}
                                onRequestClose={this.toggleModalDisplayImage}>

                                <div className="modal-dialog" style={{ borderRadius: '0px', maxWidth: '100% !important' }}>
                                    <div className="modal-content sheet-model" style={{ borderRadius: '0px', maxWidth: '90vw !important' }}>
                                        <div class="modal-header">
                                            <h4 class="modal-title">File</h4>
                                            <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModalDisplayImageClose} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                                        </div>
                                        <div class="modal-body" style={{ padding: "10px 10px", width: '90% !important' }}>
                                            <div className="d-flex flex-wrap row">
                                                {/* <img src={`${imageName}`} style={{ width: '100%' }} /> */}
                                                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js`}>
                                                    <div>
                                                        <Viewer fileUrl={imageName} />
                                                    </div>
                                                </Worker>
                                            </div>
                                        </div>
                                        <div className="modal-footer" style={{ padding: '0px' }}>
                                            {/* <button
                                                type="button"
                                                className="btn btn-delete footer-btn-inner mr-1"
                                            >
                                                Change
                                            </button> */}
                                            <button type="button" className="btn btn-cancel  footer-btn-inner"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={this.toggleModalDisplayImageClose}
                                            >
                                                Cancel
                                            </button>
                                        </div>

                                    </div>
                                </div>

                            </Modal>


                            {/* 
                            <Modal
                                isOpen={isOpen}
                                onRequestClose={this.toggleModal}>
                             
                                    <div className="modal-dialog custom_modal_dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header " style={{ padding: "10px 10px" }}>
                                                <h5 className="modal-title modal_title_text" id="close">
                                                    Forcefully Closure of an Engagement
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="close modal-close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={this.toggleModal}
                                                >
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body" style={{ padding: "10px 10px" }}>
                                                <div className="d-flex flex-wrap row">
                                                    <div className="form-group col-12">
                                                        <label style={{ fontSize: 14 }}>
                                                            There are {checklistList.length} critical checklist, which has not yet been updated.
                                                            In case you want to forcefully close this engagement, enter your
                                                            remark and click on "Close" else click on "Cancel" to fill the
                                                            missing checklists.
                                                        </label>
                                                        <textarea
                                                            className="mt-2"
                                                            name="remark"
                                                            rows={4}
                                                            cols={50}
                                                            style={{ border: "1px solid #ddd" }}
                                                            value={remark}
                                                            onChange={e => this.setState({ remark: e.target.value })}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-delete footer-btn-inner mr-1"
                                                    onClick={e => this.handleClose(e)}
                                                >
                                                    Close
                                                </button>
                                                <button type="button" className="btn btn-cancel  footer-btn-inner"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={this.toggleModal}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                              
                            </Modal> */}
                            <Modal
                                isOpen={isOpen}
                                onRequestClose={this.toggleModal}>
                                <div className="modal-dialog custom_modal_dialog">
                                    <div className="modal-content" style={{ borderRadius: '0px', left: '0%', width: '400px' }}>
                                        <div class="modal-header">
                                            <h4 class="modal-title">Forcefully Closure of an Engagement</h4>
                                            <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                                        </div>
                                        <div class="modal-body" style={{ padding: "10px 10px" }}>
                                            <div className="d-flex flex-wrap row">
                                                <div className="form-group col-12">
                                                    <label style={{ fontSize: 14 }}>
                                                        There are {checklistList.length} critical checklist, which has not yet been reviewd.
                                                        In case you want to forcefully close this engagement, enter your
                                                        remark and click on "Close" else click on "Cancel" to fill the
                                                        missing checklists.
                                                    </label>
                                                    <textarea
                                                        className="mt-2"
                                                        name="remark"
                                                        rows={4}
                                                        cols={50}
                                                        style={{ border: "1px solid #ddd" }}
                                                        value={remark}
                                                        onChange={e => this.setState({ remark: e.target.value })}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-delete footer-btn-inner mr-1"
                                                onClick={e => this.handleClose(e)}
                                            >
                                                Close
                                            </button>
                                            <button type="button" className="btn btn-cancel  footer-btn-inner"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={this.toggleModal}
                                            >
                                                Cancel
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </Modal>
                            <Modal isOpen={isOpenDeleteFile} onRequestClose={ () => this.toggleModalDeleteFile('')}>
                                <div className="modal-dialog custom_modal_dialog">
                                    <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                                        <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm File Delete</h4>
                                            <button type="button " class="close pop-close" data-dismiss="modal" onClick={ () =>  this.toggleModalDeleteFile('')} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row">
                                                <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this file</h3></div>
                                            </div>
                                        </div>
                                        <div class="modal-footer p-1">
                                            <div class="form-group text-right">
                                                <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDeleteFile(OpenDeleteFile)}> Yes</a>
                                                <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={ () =>  this.toggleModalDeleteFile('')}>No</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </>

                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>


            </>
        );
    }
}

export default withRouter(connect(null, {
    reviewChecklistDetails_action, updateReviewChecklist_action,
    closeReviewChecklist_action,
    fill_action, deleteFile_action, uploadFile_action, GetFile_action
})(ReviewChecklistU));