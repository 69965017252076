import React, { Component } from 'react';
import { connect, forwardRef } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Loader from '../../../utils/Loader'
import { engagementRoleAdd_action, engagementRoleUpdate_action, engagementRoleDetails_action } from '../../../actions/master_action/engagementRole_action'
import { fill_action } from '../../../actions/master_action/checklist_action'

import { moduleAccessListActionId, Master, rolePlayedId } from '../../../utils/constants'
import { getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

const mapStateToProps = state => ({
    ...state
})


class EngagementRoleAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            isLoading: false,
            is_add: false,
            is_delete: false,
            is_update: false,
            IsActive: false,
            parentId: '',
            parentList: [],
            engagementRoleId: '',
            engagementRoleName: '',
            maxAllowed: '',
            displayOrder: '',
            is_Disable: false,
            moduleList: [],
            reportList: [],
        }
        this.handleAdd = this.handleAdd.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.getEngagementRoleDetails = this.getEngagementRoleDetails.bind(this)
    }

    componentDidMount = () => {
        this.getFillList(rolePlayedId, '')
        this.getFillList(moduleAccessListActionId, '')

        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_Disable: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            engagementRoleId: this.props.location.state != null && this.props.location.state.engagementRoleId != null ? this.props.location.state.engagementRoleId : '',
            isAccessToView: true,
        })
        setTimeout(() => {
            this.props.location.state != null && this.props.location.state.is_update && this.getEngagementRoleDetails()           
            }, 200);
        // this.props.location.state != null && this.props.location.state.is_update && this.getEngagementRoleDetails()
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }

    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    getFillList(value, ref, ref2) {

        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != '' ? ref : '',
                ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == rolePlayedId) {
                        this.setState({ parentList: data.data, isLoading: false })
                    }
                    if (value == moduleAccessListActionId) {
                        data.moduleList.map((item) => {
                            item.ReadAccess = false
                            item.WriteAccess = false
                            item.UpdateAccess = false
                            item.DeleteAccess = false
                            item.FullAccess = false
                            item.Is_SpecialAccess = false
                        })
                        data.reportList.map((item) => {
                            item.FullAccess = false
                            item.ViewAccess = false
                            item.DownloadAccess = false
                        })
                        this.setState({
                            moduleList: data.moduleList,
                            reportList: data.reportList,
                            isLoading: false,
                        })
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    handleCancel() {
        this.props.navigate('/EngagementRoleList')
    }

    clearValue() {
        this.setState({
            engagementRoleName: '',
            parentId: '',
            maxAllowed: '',
            displayOrder: '',
        })
    }

    getEngagementRoleDetails() {
        var id = this.props.location.state.engagementRoleId
        this.setState({ isLoading: true })
        this.props
            .engagementRoleDetails_action(id)
            .then((data) => {
                if (data.error != 1) {

                    this.setState({
                        engagementRoleName: data.data[0]['Role'],
                        parentId: data.data[0]['ParentDesID'],
                        maxAllowed: data.data[0]['MaxCount'],
                        displayOrder: data.data[0]['DisplayOrder'],
                        IsActive: data.data[0]['IsActive'],
                        isLoading: false,
                        moduleList: data.moduleList,
                        reportList: data.reportList
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }


    // update financial year 
    handleUpdate(e) {
        e.preventDefault()
        const { engagementRoleName, parentId, engagementRoleId, moduleList, reportList } = this.state
        const id = engagementRoleId;

        if (id != "" && engagementRoleName != "" && engagementRoleName != null) {
            this.setState({ isLoading: true })
            this.props
                .engagementRoleUpdate_action(id, engagementRoleName.trim(), parentId, moduleList, reportList)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.handleCancel()

                        }, 1000);
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }

    handleAdd(e) {
        e.preventDefault()
        const { engagementRoleName, parentId, maxAllowed, displayOrder, moduleList, reportList } = this.state
        if (engagementRoleName != "" && engagementRoleName != null) {
            this.setState({ isLoading: true })


            this.props
                .engagementRoleAdd_action(engagementRoleName.trim(), parentId, maxAllowed, displayOrder, moduleList, reportList)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.clearValue()

                            this.handleCancel()
                        }, 1000);

                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }


    onModuleChange(e, index, value) {
        const { moduleList } = this.state
        const event = e.target.checked
        moduleList.map((data, i) => {
            if (i === index) {
                if (event) {
                    if (value == 'FullAccess') {
                        data.FullAccess = true
                        data.ReadAccess = true
                        data.WriteAccess = true
                        data.UpdateAccess = true
                        data.DeleteAccess = true
                        data.Is_SpecialAccess = true
                    } else if (value == 'ReadAccess') {
                        data.ReadAccess = true
                    } else if (value == 'WriteAccess') {
                        data.WriteAccess = true
                    } else if (value == 'UpdateAccess') {
                        data.UpdateAccess = true
                    } else if (value == 'DeleteAccess') {
                        data.DeleteAccess = true
                    } else {
                        data.Is_SpecialAccess = true
                    }
                } else {
                    if (value == 'FullAccess') {
                        data.FullAccess = false
                        data.ReadAccess = false
                        data.WriteAccess = false
                        data.UpdateAccess = false
                        data.DeleteAccess = false
                        data.Is_SpecialAccess = false
                    } else if (value == 'ReadAccess') {
                        data.ReadAccess = false
                    } else if (value == 'WriteAccess') {
                        data.WriteAccess = false
                    } else if (value == 'UpdateAccess') {
                        data.UpdateAccess = false
                    } else if (value == 'DeleteAccess') {
                        data.DeleteAccess = false
                    } else {
                        data.Is_SpecialAccess = false
                    }
                }
            }
        })
        console.log("moduleList--", moduleList)
        this.setState({ moduleList: moduleList })
    }
    onReportChange(e, index, value) {
        const { reportList } = this.state
        const event = e.target.checked
        reportList.map((data, i) => {
            if (i === index) {
                if (event) {
                    if (value == 'FullAccess') {
                        data.FullAccess = true
                        data.ViewAccess = true
                        data.DownloadAccess = true
                    } else if (value == 'ViewAccess') {
                        data.ViewAccess = true
                    } else {
                        data.DownloadAccess = true
                    }
                } else {
                    if (value == 'FullAccess') {
                        data.FullAccess = false
                        data.ViewAccess = false
                        data.DownloadAccess = false
                    } else if (value == 'ViewAccess') {
                        data.ViewAccess = false
                    } else {
                        data.DownloadAccess = false
                    }
                }
            }
        })
        this.setState({ reportList: reportList })
    }



    render() {
        const { isLoading, IsActive, is_update,
            is_add, engagementRoleName, parentId,
            parentList, isAccessToView, maxAllowed,
            displayOrder, is_Disable, moduleList, reportList } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li>
                                                <a onClick={this.handleCancel} >Engagement Role</a>
                                            </li>
                                            <li>
                                                <a >{this.state.is_add ? 'New' : 'Update'}</a>
                                            </li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className="user-status">
                                        {
                                            is_update && engagementRoleName != '' ? <p>
                                                <strong className={IsActive == true ? 'greena' : 'redi'}> {IsActive == true ? 'Active' : 'Inactive'}</strong>
                                            </p> : <></>
                                        }

                                    </div>
                                    <div className='page-space-from-bottom'>
                                    <div className="row">
                                        <style
                                            type="text/css"
                                            dangerouslySetInnerHTML={{
                                                __html: "\n.accordion-button::after {\n background-size: 13px;\n display: none; \n} \n"
                                            }}
                                        />
                                        <div className="col-md-12 pg-body grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div class="form-group col-md-3 ">
                                                            <label>Engagement Role<span class="required1">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={engagementRoleName}
                                                                onChange={(e) => this.setState({ engagementRoleName: e.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-3 mt-1">
                                                            <label>
                                                                Parent
                                                            </label>
                                                            <select
                                                                className="js-example-basic-single"
                                                                style={{ width: "100%" }}

                                                                value={parentId}
                                                                onChange={e => this.setState({ parentId: e.target.value != "" ? e.target.value : "" })}

                                                            >
                                                                <option value={''}>Select</option>
                                                                {parentList.length > 0 ? parentList.map(item => (
                                                                    <option
                                                                        key={item.egrnID}
                                                                        value={item.egrnID}>
                                                                        {item.egrsRole}
                                                                    </option>
                                                                )) : <></>}
                                                            </select>
                                                        </div>
                                                        {/* <div class="form-group col-md-3 mt-1">
                                                        <label>Max Allowed</label>
                                                        <input
                                                            disabled={is_Disable}
                                                            type="text"
                                                            className="form-control"
                                                            value={maxAllowed}
                                                            onChange={(e) => this.setState({ maxAllowed: e.target.value.replace(/\D/,'') })}
                                                            required
                                                        />
                                                    </div>
                                                    <div class="form-group col-md-3 mt-1">
                                                        <label>	Display Order</label>
                                                        <input
                                                            disabled={is_Disable}
                                                            type="text"
                                                            className="form-control"
                                                            value={displayOrder}
                                                            onChange={(e) => this.setState({ displayOrder: e.target.value.replace(/\D/,'') })}
                                                            required
                                                        />
                                                    </div> */}
                                                    </div>
                                                    {/* <div className="row mt-5">
                                                        <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                            <table className="table table-bordered tb-action du-table -50">
                                                                <thead>
                                                                    <tr>
                                                                        <th>MODULE NAME</th>
                                                                        <th>FULL ACCESS</th>
                                                                        <th>READ</th>
                                                                        <th>WRITE</th>
                                                                        <th>UPDATE</th>
                                                                        <th>DELETE</th>
                                                                        <th>SPECIAL PERMISSIONS</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {moduleList.length > 0 ? (
                                                                        moduleList.map((data, i) => (
                                                                            <>
                                                                                <tr >
                                                                                    <td>{data.ModuleName}</td>
                                                                                    <td>
                                                                                        <input type='checkbox'
                                                                                            checked={data.FullAccess}
                                                                                            onChange={(e) => this.onModuleChange(e, i, 'FullAccess')}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type='checkbox'
                                                                                            checked={data.ReadAccess}
                                                                                            onChange={(e) => this.onModuleChange(e, i, 'ReadAccess')}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type='checkbox'
                                                                                            checked={data.WriteAccess}
                                                                                            onChange={(e) => this.onModuleChange(e, i, 'WriteAccess')}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type='checkbox'
                                                                                            checked={data.UpdateAccess}
                                                                                            onChange={(e) => this.onModuleChange(e, i, 'UpdateAccess')}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type='checkbox'
                                                                                            checked={data.DeleteAccess}
                                                                                            onChange={(e) => this.onModuleChange(e, i, 'DeleteAccess')}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type='checkbox'
                                                                                            checked={data.Is_SpecialAccess}
                                                                                            onChange={(e) => this.onModuleChange(e, i, 'Is_SpecialAccess')}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        ))

                                                                    ) : (<tr><td colSpan={8} className='text-center'>Record not found</td></tr>)}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                            <table className="table table-bordered tb-action du-table -50">
                                                                <thead>
                                                                    <tr>
                                                                        <th>REPORT NAME</th>
                                                                        <th>FULL ACCESS</th>
                                                                        <th>VIEW</th>
                                                                        <th>DOWNLOAD</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {reportList.length > 0 ? (
                                                                        reportList.map((data, i) => (
                                                                            <tr >
                                                                                <td>{data.ReportName}</td>
                                                                                <td>
                                                                                    <input type='checkbox'
                                                                                        checked={data.FullAccess}
                                                                                        onChange={(e) => this.onReportChange(e, i, 'FullAccess')}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input type='checkbox'
                                                                                        checked={data.ViewAccess}
                                                                                        onChange={(e) => this.onReportChange(e, i, 'ViewAccess')}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input type='checkbox'
                                                                                        checked={data.DownloadAccess}
                                                                                        onChange={(e) => this.onReportChange(e, i, 'DownloadAccess')}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        ))

                                                                    ) : (<tr><td colSpan={8} className='text-center'>Record not found</td></tr>)}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div> */}
                                                    <div className="row">
                                                        <div className="footer-btn">
                                                            <div className="col-md-3">
                                                                <div
                                                                    className="btn-group"
                                                                    role="group"
                                                                    aria-label="Basic example"
                                                                >
                                                                    {
                                                                        this.state.is_add && getModuleWriteAccess(Master) && <button
                                                                            type="button"
                                                                            className="btn btn-save footer-btn-inner"
                                                                            onClick={this.handleAdd}
                                                                        >
                                                                            Save
                                                                        </button>
                                                                    }

                                                                    {
                                                                        getModuleUpdateAccess(Master) && this.state.is_update ?
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-update footer-btn-inner mx-1"
                                                                                onClick={this.handleUpdate}
                                                                            >
                                                                                Update
                                                                            </button> : <></>
                                                                    }
                                                                    {
                                                                        getModuleWriteAccess(Master) && this.state.is_add ?
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-cancel footer-btn-inner mx-1"
                                                                                onClick={this.clearValue}
                                                                            >
                                                                                Reset
                                                                            </button> : <></>
                                                                    }

                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-cancel footer-btn-inner"
                                                                        onClick={this.handleCancel}
                                                                    >
                                                                        Cancel
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                {/* end
   */}
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>
                <ToastContainer />
            </>
        );
    }
}


export default withRouter(connect(null, { fill_action, mapStateToProps, engagementRoleAdd_action, engagementRoleUpdate_action, engagementRoleDetails_action })(EngagementRoleAU))