import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { fill_action } from '../../../actions/master_action/checklist_action'
import {
    approval_action,
    approvalReject_action,
} from '../../../actions/time_sheet_expense_action/approval_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Modal from "react-modal";
import { engagementTypeId, employeeActionId, clearLocalStorage, transcationForActionId, clientsId, financialYearId, timesheetStatusActionId, Timesheet_and_Expense_Approval } from '../../../utils/constants'
import Pagination from '../../../utils/pagination'
import Loader from '../../../utils/Loader'
import moment from 'moment';
import Workbook from 'react-excel-workbook'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getModuleUpdateAccess } from '../../../utils/RoleAccess';
/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
    // loginAction: () => dispatch(loginAction())
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));


class ApprovalList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage: 10,
            pageSizeInput: "10",
            isAccessToView: false,
            clientsNameList: [],
            clientsNameId: '',
            financialYearId: '',
            financialYearList: [],
            engagementId: '',
            engagementList: [],
            employeeId: '',
            employeeList: [],
            approvalList: [],
            statusId: '',
            statusList: [],
            expenseListId: '',
            timeSheetId: '',
            _isReject: false,
            statusId: '',
            _isApprove: '',
            isOpen: false,
            flag: 1,
            rejectId: '',
            rejectReason: '',
            transactionForId: '',
            transactionForList: [],
            timesheetCheckBoxFlag: false,

            TimesheetApprovedId: '',
            ExpenseApprovedId: '',
            TimesheetRejectId: '',
            ExpenseRejectdId: '',

            allSelected: '',
            epmtyList: false,
            isSearchTab: false,

            clientsNameIdTS: '',
            financialYearIdTS: '',
            employeeIdTS: '',
            engagementIdTS: '',
            transactionForIdTS: '',
            statusIdTS: '',
            checkedCurrentRecords: []


        }
        this.toggleModal = this.toggleModal.bind(this)
        this.getApprovalList = this.getApprovalList.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);
        this.record = this.record.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.approvalStatus = this.approvalStatus.bind(this)
        this.approvalStatusModal = this.approvalStatusModal.bind(this)
    }

    componentDidMount = () => {

        this.getFillList(engagementTypeId, '')
        this.getFillList(clientsId, '')
        this.getFillList(financialYearId, '')
        this.getFillList(timesheetStatusActionId, '')
        this.getFillList(transcationForActionId, '')
        this.getFillList(employeeActionId, '')

        localStorage.setItem("prevSelectedPage", this.currentPage)
        this.setState({

            isAccessToView: localStorage.getItem('UserId') == '1' ? true : false,


        })

        // console.log("1234567890", localStorage.getItem('statusIdTS'));
        // localStorage.clear();
        this.setState({
            clientsNameIdTS: localStorage.getItem('clientsNameIdTS') != '' ? localStorage.getItem('clientsNameIdTS') : '',
            financialYearIdTS: localStorage.getItem('financialYearIdTS') != '' ? localStorage.getItem('financialYearIdTS') : '',
            employeeIdTS: localStorage.getItem('employeeIdTS') != '' ? localStorage.getItem('employeeIdTSLS') : '',
            engagementIdTS: localStorage.getItem('engagementIdTS') != '' ? localStorage.getItem('engagementIdTS') : '',
            transactionForIdTS: localStorage.getItem('transactionForIdTS') != '' ? localStorage.getItem('transactionForIdTS') : '',
            statusIdTS: localStorage.getItem('statusIdTS') != '' ? localStorage.getItem('statusIdTS') : '',


            clientsNameId: localStorage.getItem('clientsNameIdTS') != '' ? localStorage.getItem('clientsNameIdTS') : '',
            financialYearId: localStorage.getItem('financialYearIdTS') != '' ? localStorage.getItem('financialYearIdTS') : '',
            employeeId: localStorage.getItem('employeeIdTS') != '' ? localStorage.getItem('employeeIdTS') : '',
            engagementId: localStorage.getItem('engagementIdTS') != '' ? localStorage.getItem('engagementIdTS') : '',
            transactionForId: localStorage.getItem('transactionForIdTS') != '' ? localStorage.getItem('transactionForIdTS') : '',
            statusId: localStorage.getItem('statusIdTS') != '' ? localStorage.getItem('statusIdTS') : '',
        })
        clearLocalStorage("approval")
        setTimeout(() => {
            this.getApprovalList()

        }, 100);

        // document.addEventListener('click', this.handleInputClick);

        // document.addEventListener('keydown', this.handleTabKeyPress);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    onStatusIdSet(e) {
        this.setState({ statusId: e.target.value })
        console.log("statusId", e.target.value)
        setTimeout(() => {
            this.getApprovalList()
        }, 100);
    }

    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.getApprovalList()
        }
    }

    clearValue() {
        this.setState({
            clientsNameId: '',
            financialYearId: '',
            engagementId: '',
            statusId: '',
            employeeId: '',
            approvalList: [],
            rejectReason: '',
            currentPage: 1,
            transactionForId: ''

        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getApprovalList()
        }, 300);

    }

    //toggle model for Reject.................................

    // get fill list 
    getFillList(value, ref) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value, ref)
            .then((data) => {
                if (data.error != 1) {
                    if (value == engagementTypeId) {
                        this.setState({ engagementList: data.data, isLoading: false })
                    }
                    if (value == employeeActionId) {
                        this.setState({ employeeList: data.data, isLoading: false })

                    }
                    if (value == clientsId) {
                        this.setState({ clientsNameList: data.data, isLoading: false })
                    }
                    if (value == financialYearId) {
                        this.setState({ financialYearList: data.data, isLoading: false })
                    }
                    if (value == timesheetStatusActionId) {
                        console.log("timesheetStatusActionId", data.data)
                        const array = []
                        data.data != '' ?
                            data.data.map((item) => {
                                if (item.TimeSheetStatusID != 1 && item.TimeSheetStatusID != 5) {
                                    array.push(item)
                                }
                            }) : <></>
                        this.setState({ statusList: array, isLoading: false })
                    }
                    if (value == transcationForActionId) {
                        this.setState({ transactionForList: data.data, isLoading: false })
                    }
                }
                else {
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    getApprovalDetails(timeSheetId, expenseId, Previous_reject) {
        this.props.navigate('/approvalU', { state: { is_Details: true, timeSheetID: timeSheetId, expenseID: expenseId, Previous_reject: Previous_reject } })
    }


    // get engagement allocation list
    getApprovalList() {
        const { clientsNameId, financialYearId, engagementId, employeeId, transactionForId, statusId, currentPage, checkedCurrentRecords } = this.state
        this.setState({ isLoading: true })
        clearLocalStorage("approval")

        console.log('employeeId', employeeId);
        //clientName, finYearId, engId, employeeId,  transactionForId, status
        this.props
            .approval_action(clientsNameId, financialYearId, engagementId, employeeId, transactionForId, statusId == '' ? 2 : statusId,)
            .then((data) => {
                if (data.error != 1) {
                    data.data.map((item) => {
                        item.is_cheked = 0
                    })
                    if (data.data == '') {

                        this.setState({
                            timesheetCheckBoxFlag: false, epmtyList: true,
                        })
                    }
                    console.log("", data.data)
                    this.setState({ approvalList: data.data })
                    setTimeout(() => {
                        this.setState({ isLoading: false })
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })

                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

        console.log("++++", employeeId);

        localStorage.setItem("clientsNameIdTS", clientsNameId)
        localStorage.setItem("financialYearIdTS", financialYearId)
        localStorage.setItem("employeeIdTS", employeeId)
        localStorage.setItem("engagementIdTS", engagementId != null ? engagementId : '')
        localStorage.setItem("transactionForIdTS", transactionForId != null ? transactionForId : '')
        localStorage.setItem("statusIdTS", statusId)

    }

    handleCancel() {
        this.state.isOpen = false
        this.clearValue()
    }

    approvalStatusModal(timesheetApprovedId, expenseApprovedId, approvalList) {
        const { isOpen } = this.state
        this.state._isApprove = true
        this.state._isReject = false
        this.setState({ isOpen: !isOpen })

        this.setState({ TimesheetApprovedId: timesheetApprovedId, ExpenseApprovedId: expenseApprovedId, checkedCurrentRecords: approvalList })
    }

    approvalStatusModalCancle() {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen, _isApprove: false, _isReject: false })
        this.setState({ TimesheetApprovedId: '', ExpenseApprovedId: '' })
    }

    approvalStatus(e) {
        e.preventDefault()
        const { flag, rejectReason, approvalList, TimesheetApprovedId, ExpenseApprovedId, currentPage, checkedCurrentRecords } = this.state
        const timesheetListId = []
        const commonListId = []
        approvalList.map((data) => {
            if (data.is_cheked == 1) {
                if (data.TIMESHEETID != 0) {
                    timesheetListId.push(data.TIMESHEETID)
                    commonListId.push(data.TIMESHEETID)
                }
            }
        })
        timesheetListId != '' && timesheetListId != null ? timesheetListId.concat().toString() : <></>

        const expenseListId = []
        approvalList.map((data) => {
            if (data.is_cheked == 1) {
                if (data.ExpenseID != null) {
                    expenseListId.push(data.ExpenseID)
                    commonListId.push(data.ExpenseID)
                }
            }
        })
        expenseListId != '' && expenseListId != null ? expenseListId.concat().toString() : <></>

        const newRejectReason = null
        this.setState({ isLoading: true })
        this.props
            .approvalReject_action(
                TimesheetApprovedId != null && TimesheetApprovedId != '' ? TimesheetApprovedId.toString() : timesheetListId,
                ExpenseApprovedId != null && ExpenseApprovedId != '' ? ExpenseApprovedId.toString() : expenseListId,
                flag, newRejectReason)
            .then((data) => {
                if (data.error != 1) {
                    if (currentPage != 1) {
                        if ((TimesheetApprovedId == '' || TimesheetApprovedId == null) && (ExpenseApprovedId == '' || ExpenseApprovedId == null)) {
                            if (checkedCurrentRecords.length == commonListId.length) {
                                this.setState({ currentPage: currentPage - 1 })
                                console.log("test---------->1",)
                            } else {
                                console.log("test---------->2",)
                                this.setState({ currentPage: currentPage })
                            }
                        } else {
                            if (checkedCurrentRecords.length == 1) {
                                console.log("test---------->3",)
                                this.setState({ currentPage: currentPage - 1 })
                            } else {
                                if (currentPage != 1) {
                                    console.log("test---------->4",)
                                    this.setState({ currentPage: currentPage })
                                } else {
                                    console.log("test---------->5",)
                                    this.setState({ currentPage: 1 })
                                }
                            }
                        }
                    }
                    toast.success('Timesheet & Expense Approve Successfully', {
                        theme: 'colored',
                        autoClose: 1000,
                        hideProgressBar: true,
                    })
                    this.setState({ isLoading: false, isOpen: false, _isApprove: false, allSelected: false, TimesheetApprovedId: null, ExpenseApprovedId: null })
                    this.getApprovalList()
                    // this.handleCancel()
                    // this.clearValue()
                }
                else {
                    this.setState({ isLoading: false })

                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 1000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }


    updateItem(item, currentRecords) {

        var prevPage = localStorage.getItem("prevSelectedPage")
        this.setState({ currentPage: item });
        if (prevPage != null && prevPage != item) {
            const { approvalList } = this.state;
            const pList = [...approvalList];

            pList.forEach((data, i) => {
                data.is_cheked = 0
            });
            this.setState({
                approvalList: pList,
                allSelected: false
            });
        }

    }

    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
            this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
    };

    handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
            this.setState({ pageSizeInput: input });
        }
    };


    //toggle model for Reject.................................
    toggleModal(timesheetRejectId, expenseRejectId, currentRecords) {
        const { isOpen } = this.state
        this.state._isApprove = false
        this.state._isReject = true
        this.setState({ isOpen: !isOpen })
        this.setState({ TimesheetRejectId: timesheetRejectId, ExpenseRejectdId: expenseRejectId, checkedCurrentRecords: currentRecords });
    }

    toggleModalCancle() {
        const { isOpen } = this.state
        this.state._isApprove = false
        this.state._isReject = false
        this.setState({ isOpen: !isOpen })
        this.setState({ TimesheetRejectId: '', ExpenseRejectdId: '',rejectReason:'' });
    }


    approvalReject(e) {
        e.preventDefault()
        const { rejectReason, flag, approvalList, TimesheetRejectId, ExpenseRejectdId, currentPage, checkedCurrentRecords } = this.state
        const rejectFlag = 0
        const id = this.state.rejectId;
        console.log("TimesheetRejectId", TimesheetRejectId)
        console.log("ExpenseRejectdId", ExpenseRejectdId)
        const timesheetListId = []
        const commonListId = []
        approvalList.map((data) => {
            if (data.is_cheked == 1) {
                if (data.TIMESHEETID != 0) {
                    timesheetListId.push(data.TIMESHEETID)
                    commonListId.push(data.TIMESHEETID)
                }
            }
        })
        timesheetListId != '' ? timesheetListId.concat().toString() : <></>

        const expenseListId = []
        approvalList.map((data) => {
            if (data.is_cheked == 1) {
                if (data.ExpenseID != null) {
                    expenseListId.push(data.ExpenseID)
                    commonListId.push(data.ExpenseID)
                }
            }
        })
        expenseListId != '' ? expenseListId.concat().toString() : <></>

        if (rejectReason != "") {
            this.setState({ isLoading: true })
            this.props
                .approvalReject_action(
                    TimesheetRejectId != null && TimesheetRejectId != '' ? TimesheetRejectId.toString() : timesheetListId,
                    ExpenseRejectdId != null && ExpenseRejectdId != '' ? ExpenseRejectdId.toString() : expenseListId,
                    rejectFlag, rejectReason)
                .then((data) => {
                    if (data.error != 1) {
                        console.log("checkedCurrentRecords---------->", checkedCurrentRecords)
                        console.log("commonListId---------->", commonListId)
                        console.log("TimesheetRejectId---------->", TimesheetRejectId)
                        console.log("ExpenseRejectdId---------->", ExpenseRejectdId)

                        if (currentPage != 1) {
                            if ((TimesheetRejectId == '' || TimesheetRejectId == null) && (ExpenseRejectdId == '' || ExpenseRejectdId == null)) {
                                if (checkedCurrentRecords.length == commonListId.length) {
                                    this.setState({ currentPage: currentPage - 1 })
                                    console.log("test---------->1",)
                                } else {
                                    console.log("test---------->2",)
                                    this.setState({ currentPage: currentPage })
                                }
                            } else {
                                if (checkedCurrentRecords.length == 1) {
                                    console.log("test---------->3",)
                                    this.setState({ currentPage: currentPage - 1 })
                                } else {
                                    if (currentPage != 1) {
                                        console.log("test---------->4",)
                                        this.setState({ currentPage: currentPage })
                                    } else {
                                        console.log("test---------->5",)
                                        this.setState({ currentPage: 1 })
                                    }
                                }
                            }
                        }

                        toast.success('Timesheet & Expense Reject Successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false, isOpen: false, _isReject: false, allSelected: false,rejectReason:'' })
                        this.getApprovalList()
                        // this.handleCancel()
                        // this.clearValue()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter Resion', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }

    timesheetExpenseCheckbox(data2, is_cheked, e) {

        const { approvalList } = this.state;
        const pList = [...approvalList];
        const event = e.target.checked
        if (event == true) {
            pList.forEach((data, i) => {
                if (data.ExpenseID == data2.ExpenseID && data.TIMESHEETID == data2.TIMESHEETID) {
                    data.is_cheked = 1
                }
            });
            this.setState({
                approvalList: pList,
            })

        } else {

            pList.forEach((data, i) => {
                if (data.ExpenseID == data2.ExpenseID && data.TIMESHEETID == data2.TIMESHEETID) {

                    data.is_cheked = 0
                }
            });
            this.setState({
                approvalList: pList,
            });
        }

        const checkedAllSelected = pList.every(item => item.is_cheked == 1)

        if (checkedAllSelected) {
            this.setState({ allSelected: true })
        } else {
            this.setState({ allSelected: false })
        }

        const checkboxSelected = pList.some(item => item.is_cheked == 1)

        // debugger
        if (checkboxSelected) {
            // console.log("****** true******")
            this.state.timesheetCheckBoxFlag = true
        }
        else {
            // console.log("****** false******")
            this.state.timesheetCheckBoxFlag = false
        }
    }

    timeSheetExpenseApproved(currentRecords) {
        const { isOpen, currentPage } = this.state
        this.setState({ isOpen: !isOpen, _isApprove: true, _isReject: false, TimesheetRejectId: '', ExpenseRejectdId: "", timesheetApprovedId: '', expenseApprovedId: '', checkedCurrentRecords: currentRecords })
    }

    timeSheetExpenseReject(currentRecords) {
        const { isOpen, currentPage } = this.state
        this.setState({ isOpen: !isOpen, _isReject: true, _isApprove: false, TimesheetRejectId: '', ExpenseRejectdId: '', timesheetApprovedId: '', expenseApprovedId: '', checkedCurrentRecords: currentRecords })
    }

    allSelectedCheckbox(e, indexOfFirstRecord, indexOfLastRecord) {
        const { statusId, allSelected, approvalList } = this.state

        this.setState({ allSelected: e.target.checked })
        if (e.target.checked == true) {
            // approvalList.map((data) => {
            //     data.is_cheked = 1
            // })

            approvalList.map((data, index) => {
                if (index >= indexOfFirstRecord && index < indexOfLastRecord) {
                    data.is_cheked = 1;
                }
                return data;
            });
            this.setState({ approvalList: approvalList, timesheetCheckBoxFlag: true })
        } else {
            // approvalList.map((data) => {
            //     data.is_cheked = 0
            // })
            approvalList.map((data, index) => {
                data.is_cheked = 0;
            });
            this.setState({ approvalList: approvalList, timesheetCheckBoxFlag: false })
        }

    }



    record(approvalList, indexOfFirstRecord, indexOfLastRecord, currentPage) {
        const { statusId, allSelected } = this.state
        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        {approvalList.length > 0 ?


                            (statusId == '2' || statusId == '') && getModuleUpdateAccess(Timesheet_and_Expense_Approval) ?
                                <th style={{ width: '60px' }} >
                                    <input
                                        type='checkbox'
                                        onClick={(e) => this.allSelectedCheckbox(e, indexOfFirstRecord, indexOfLastRecord)}
                                        checked={allSelected}
                                    />

                                </th>
                                :
                                <></>


                            : <></>}
                        <th style={{ width: '60px' }}  ></th>
                        {(statusId == '2' || statusId == '') && getModuleUpdateAccess(Timesheet_and_Expense_Approval) ?
                            <>
                                <th style={{ width: '60px' }}  ></th>
                                {/* <th style={{ width: '60px' }} /> */}
                            </>
                            : <></>
                        }
                        <th style={{ textAlign: 'left' }} >Type</th>
                        <th style={{ textAlign: 'left' }} >Employee</th>
                        <th style={{ width: '150px' }} >Trans. For </th>
                        <th style={{ width: '250px' }} >Client Name</th>
                        <th style={{ width: '150px' }}>Financial Year</th>
                        <th style={{ width: '250px' }} >Engagement</th>
                        <th style={{ width: '250px' }}>Expense Type</th>

                        {/* //// */}
                        <th style={{ width: '250px' }}>Desc.</th>
                        <th style={{ width: '250px' }}>Date.</th>
                        <th style={{ width: '100px' }} >Amount</th>
                        <th style={{ width: '100px' }} >Previous Reject</th>
                        <th style={{ width: '250px' }}>Status</th>
                        <th style={{ width: '100px' }}>Supporting</th>

                    </tr>
                </thead>
                <tbody>

                    {
                        approvalList.length > 0 ?
                            approvalList.map((data, i) => (
                                <>
                                    <tr >
                                        {(statusId == '2' || statusId == '') && getModuleUpdateAccess(Timesheet_and_Expense_Approval) ?
                                            <td>
                                                <input
                                                    onClick={(e) => this.timesheetExpenseCheckbox(data, data.is_cheked, e)}
                                                    type="checkbox"
                                                    checked={data.is_cheked}
                                                />
                                            </td>
                                            : <></>
                                        }
                                        <td className="text-center" style={{ padding: '5px!important' }}>
                                            <div className="table__button-group">
                                                <a> <i className="fa fa-eye" style={{ color: '#3498db' }}
                                                    onClick={() => this.getApprovalDetails(data.TIMESHEETID, data.ExpenseID, data.Previous_reject)} /></a>

                                            </div>
                                        </td>
                                        {(statusId == '2' || statusId == '') && getModuleUpdateAccess(Timesheet_and_Expense_Approval) ?
                                            <>
                                                <td>
                                                    <a onClick={(e) => this.approvalStatusModal(data.TIMESHEETID, data.ExpenseID, approvalList)}><button className='btn btn-outline-info  mr-1 py-1 mx-1 px-3 m-1 h-auto'>Approval</button></a>
                                                    <a onClick={(e) => this.toggleModal(data.TIMESHEETID, data.ExpenseID, approvalList)}><button className='btn btn-outline-danger mr-1 py-1 mx-1 px-3 m-1 h-auto'>Rejection</button></a></td>
                                            </>
                                            : <></>

                                        }
                                        <td >{data.type}</td>
                                        <td style={{ textAlign: 'left' }}>{data.EmployeeName}</td>
                                        <td>{data.TransactionFor != '' ? data.TransactionFor : '-'}</td>
                                        <td>{data.ClientName != '' ? data.ClientName : '-'}</td>
                                        <td>{data.FinancialYear != '' ? data.FinancialYear : '-'}</td>
                                        <td>{data.Engagement}</td>
                                        <td>{data.ExpenseType != null ? data.ExpenseType : '-'}</td>
                                        <td>{data.Description}</td>
                                        <td>{data.EnteredDate != null && moment(data.EnteredDate).format('DD-MM-YYYY')}</td>
                                        <td>{data.ExpAmount}</td>
                                        <td>{data.Previous_reject}</td>
                                        <td>{data.TimeSheetStatus}</td>
                                        <td>{data.NeedSupporting == 0 ? 'No' : 'Yes'}</td>
                                    </tr></>
                            )) : <tr><td colSpan={15} className='text-center'>Record not found</td></tr>
                    }

                </tbody>
            </table>
        )

    }

    render() {
        const { isSearchTab, isLoading,
            expenseListDeleteId, isOpen,
            statusList, approvalList, engagementList, clientsNameList, clientsNameId,
            financialYearId, financialYearList, engagementId, employeeId, employeeList, _isReject, rejectReason, _isApprove, transactionForId, transactionForList, statusId, timesheetCheckBoxFlag
        } = this.state
        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = approvalList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(approvalList.length / recordsPerPage)
        //    localStorage.setItem("last_currentPage", currentPage.toString())
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {/* {isAccessToView ? ( */}
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul className="breadcrumb">

                                        <li><a href="#">Approval List</a></li>
                                        <li />
                                    </ul>
                                </div>
                                <div className='page-space-from-bottom'>
                                    <div className="row">
                                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                        <div className="col-md-12 pg-body grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body pr-2">
                                                    <div className="accordion accordion-flush " id="accordionFlushExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="flush-headingOne">
                                                                <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                                                    <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                </button>
                                                            </h2>
                                                            {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                            <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                <div className="d-flex flex-wrap row">

                                                                    <div className="form-group col-md-3">
                                                                        <label>Employee</label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }}
                                                                            value={employeeId}
                                                                            onChange={(e) => this.setState({ employeeId: e.target.value })}>
                                                                            <option value={''}>Select</option>
                                                                            {employeeList.length > 0 && employeeList.map(item => (
                                                                                <option
                                                                                    key={item.emmnID}
                                                                                    value={item.emmnID}>
                                                                                    {item.empsFirstName}
                                                                                    {/* {item.empsFirstName}&nbsp;{item.empsLastName} */}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group col-md-3">
                                                                        <label>Transaction for</label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }}
                                                                            value={transactionForId}
                                                                            onChange={(e) => this.setState({ transactionForId: e.target.value })}>
                                                                            <option value={''}>Select</option>
                                                                            {transactionForList.length > 0 && transactionForList.map(item => (
                                                                                <option
                                                                                    key={item.TransactionForID}
                                                                                    value={item.TransactionForID}>
                                                                                    {item.TransactionFor}
                                                                                </option>
                                                                            ))}

                                                                        </select>

                                                                    </div>

                                                                    <div className="form-group col-md-3">
                                                                        <label>Client Name </label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }} value={clientsNameId} onChange={e => this.setState({ clientsNameId: e.target.value })}>
                                                                            <option value={''}> Select</option>
                                                                            {clientsNameList.length > 0 && clientsNameList.map(item => (
                                                                                <option
                                                                                    key={item.ClientID}
                                                                                    value={item.ClientID}>
                                                                                    {item.ClientFName}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label>Financial Year</label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }} value={financialYearId} onChange={e => this.setState({ financialYearId: e.target.value })}>
                                                                            <option value={''}>Select</option>
                                                                            {financialYearList.length > 0 && financialYearList.map(item => (
                                                                                <option
                                                                                    key={item.FinYearID}
                                                                                    value={item.FinYearID}>
                                                                                    {item.FinancialYear}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label>Engagement </label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }}
                                                                            value={engagementId}
                                                                            onChange={e => this.setState({ engagementId: e.target.value })}>
                                                                            <option value={''}>Select</option>
                                                                            {engagementList.length > 0 && engagementList.map(item => (
                                                                                <option
                                                                                    key={item.EngagementMasterID}
                                                                                    value={item.EngagementMasterID}>
                                                                                    {item.EngagementType}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group col-md-3">
                                                                        <label>Status</label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }}
                                                                            value={statusId}
                                                                            onChange={(e) => this.onStatusIdSet(e)}>
                                                                            <option value={''}>Select</option>
                                                                            {statusList.length > 0 && statusList.map(item => (
                                                                                <option
                                                                                    key={item.TimeSheetStatusID}
                                                                                    value={item.TimeSheetStatusID}>
                                                                                    {item.Name}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group col-md-4 mt-4" style={{ textAlign: 'left' }}>
                                                                        <button type="button" className="btn btn-Add rounded-1" onClick={this.getApprovalList}> Search</button>
                                                                        <button type="button" className="btn btn-clear rounded-1 mx-1" onClick={this.clearValue}> Clear</button>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div> : <></>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 ">
                                                            <div className='mt-1 mb-1'>
                                                                {
                                                                    approvalList != '' && timesheetCheckBoxFlag && getModuleUpdateAccess(Timesheet_and_Expense_Approval) ?
                                                                        <>

                                                                            <button type="button" className="btn btn-outline-danger rounded-1 mx-2 py-2 px-3 p-0 mb-1" style={{ float: 'right' }} onClick={() => this.timeSheetExpenseReject(currentRecords)}> Rejection</button>

                                                                            <button type="button" className="btn btn-Add rounded-1 mx-1 pt-2" style={{ float: 'right' }} onClick={() => this.timeSheetExpenseApproved(currentRecords)}> Approval</button>
                                                                        </>
                                                                        : <></>
                                                                }
                                                            </div>

                                                            <div className="table-responsive tbs4 common-table trail-tb mt-1 mb-4" style={{ width: '100%' }}>
                                                                {this.record(currentRecords, indexOfFirstRecord, indexOfLastRecord, currentPage)}
                                                                {approvalList.length > 0 ? <Pagination
                                                                    nPages={nPages}
                                                                    currentPage={currentPage}
                                                                    setCurrentPage={this.updateItem}
                                                                    recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                    setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                    totalRecords={approvalList.length}
                                                                /> : <></>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)} */}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>
                <ToastContainer />

                {/* // modal for Approve */}
                <Modal isOpen={isOpen && _isApprove} onRequestClose={(e) => { this.approvalStatusModal(e) }}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Approved</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.approvalStatusModalCancle()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label pt-3">Do you really want to Approved this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <button href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.approvalStatus(e)}><i class="fa fa-check"></i>&nbsp; Yes</button>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.approvalStatusModalCancle()}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>

                {/* // modal for reject */}
                <Modal isOpen={isOpen && _isReject} onRequestClose={() => this.setState({ isOpen: !isOpen })}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div className="modal-header">
                                <h4 className="modal-title modal_title_text">Reject</h4>
                                <button type="button " className="close pop-close" data-dismiss="modal" onClick={() => this.toggleModalCancle()}><span aria-hidden="true" className="pop-cancel">&times;</span></button>

                            </div>
                            <div className="d-flex flex-wrap row m-0">
                                <div className="form-group col-12">
                                    <label>Reason<span class="required1">*</span></label>
                                    <textarea
                                        className="form-group"
                                        name="rejectReason"
                                        rows={5}
                                        cols={50}
                                        style={{ border: "1px solid #dee2e6" }}
                                        // defaultValue={this.state.remark}
                                        value={rejectReason}
                                        onChange={e => this.setState({ rejectReason: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-left mod-fot" style={{ marginLeft: '7px' }}>
                                        <a href="javascript:void(0);" class="btn btn-outline-danger rounded-1 mx-2 py-2 px-3 p-0 mb-1" onClick={(e) => this.approvalReject(e)}>Reject</a>
                                        <a href="javascript:void(0);" class="btn btn-outline-secondary text-dark rounded-1  py-2 px-3 p-0 mb-1" data-dismiss="modal" onClick={() => this.toggleModalCancle()}>Cancel</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(connect(null, {
    approval_action,
    approvalReject_action,
    fill_action
})(ApprovalList));