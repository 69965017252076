
import {
  TIMESHEET_ACTION_TYPE,
  TIMESHEET_DETAILS_ACTION_TYPE,
  TIMESHEET_ADD_ACTION_TYPE,
  TIMESHEET_UPDATE_ACTION_TYPE,
  TIMESHEET_DELETE_ACTION_TYPE,
  TIMESHEET_APPROVAL_ACTION_TYPE,
  LAST_TIMESHEET_FILL_ACTION_TYPE,
  SINGLE_LAST_TIMESHEET_DATE_ACTION_TYPE
} from "../../utils/constants"


const initialState = [];

function timesheetReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TIMESHEET_ACTION_TYPE:
      return [...state, payload];
    case TIMESHEET_DETAILS_ACTION_TYPE:
      return [...state, payload];
    case TIMESHEET_ADD_ACTION_TYPE:
      return [...state, payload];
    case TIMESHEET_UPDATE_ACTION_TYPE:
      return [...state, payload];
    case TIMESHEET_DELETE_ACTION_TYPE:
      return [...state, payload];
    case TIMESHEET_APPROVAL_ACTION_TYPE:
      return [...state, payload];
    case LAST_TIMESHEET_FILL_ACTION_TYPE:
      return [...state, payload];
    case SINGLE_LAST_TIMESHEET_DATE_ACTION_TYPE:
      return [...state, payload];

    default:
      return state;
  }
};

export default timesheetReducer;


// export default (state = {}, action) => {
//     switch (action.type) {
//      case 'SIMPLE_ACTION':
//       return {
//        result: action.payload
//       }
//      default:
//       return state
//     }
//    }