import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import { designationList_action, designationAdd_action, designationDetails_action, designationUpdate_action, designationDelete_action, rolePlayedDelete_action } from '../../../actions/master_action/designation_action'
import { fill_action } from '../../../actions/master_action/checklist_action'

import Pagination from "../../../utils/pagination";

import { clearLocalStorage, Master, parentId, rolePlayedId } from '../../../utils/constants'
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

class Designation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            isOpen: false,
            isLoading: false,
            IsActive: 0,
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage: 10,
            pageSizeInput: "10",
            status: '',
            designationList: [],
            designation: '',
            designationDLS: '',
            reportingDLS: '',
            statusDLS: '',
            designationId: '',
            _isDelete: false,
            _isAdd: false,
            _isUpdate: false,
            parentId: '',
            parentList: [],
            rolePlayedId: '',
            rolePlayedList: [],
            addedRolePlayedList: [],
            egrnID: '',
            search_designation: '',
            isSearchTab: false,
            isOpenUpdateAddModal: false,
        }
        this.getFillList = this.getFillList.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.deleteRoleplayed = this.deleteRoleplayed.bind(this)
        this.getDesignationList = this.getDesignationList.bind(this)
        this.getDesignationDetails = this.getDesignationDetails.bind(this)
        this.onSelectRolePlayed = this.onSelectRolePlayed.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.toggleModalAdd = this.toggleModalAdd.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);

        this.CancelOpenUpdateAddModal = this.CancelOpenUpdateAddModal.bind(this);
    }

    componentDidMount = () => {
        this.getFillList(parentId, '')
        this.getFillList(rolePlayedId, '')

        this.setState({
            isAccessToView: true,

            designationDLS: localStorage.getItem('designationDLS'),
            reportingDLS: localStorage.getItem('reportingDLS'),
            statusDLS: localStorage.getItem('statusDLS'),

            search_designation: localStorage.getItem('designationDLS') != '' ? localStorage.getItem('designationDLS') : '',
            parentId: localStorage.getItem('reportingDLS') != '' ? localStorage.getItem('reportingDLS') : '',
            status: localStorage.getItem('statusDLS') != '' ? localStorage.getItem('statusDLS') : '',
        })
        // this.clearValue()
        clearLocalStorage("designation")
        setTimeout(() => {
            this.getDesignationList()
        }, 300);

        document.addEventListener('keydown', this.handleTabKeyPress);
        document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleTabKeyPress);
        document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };


    getFillList(value, ref, ref2) {

        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == parentId) {
                        this.setState({ parentList: data.data, isLoading: false })
                    }
                    if (value == rolePlayedId) {
                        this.setState({ rolePlayedList: data.data, isLoading: false })
                        console.log("getFillRolePlayed", data.data)
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    // designation List
    getDesignationList() {
        const { search_designation, parentId, status } = this.state
        this.setState({ isLoading: true })
        clearLocalStorage("designation")
        this.props
            .designationList_action(search_designation.trim(), parentId.trim(), status == '' ? 1 : status)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ designationList: data.data, isLoading: false, currentPage: 1 })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

        localStorage.setItem("designationDLS", search_designation)
        localStorage.setItem("reportingDLS", parentId)
        localStorage.setItem("statusDLS", status)
    }

    clearValue() {
        this.setState({
            search_designation: '',
            designation: '',
            parentId: '',
            rolePlayedId: '',
            status: '',
            addedRolePlayedList: []
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getDesignationList()
        }, 300);

    }

    CancelOpenUpdateAddModal() {
        const { isOpenUpdateAddModal } = this.state

        this.setState({
            isOpenUpdateAddModal: !isOpenUpdateAddModal,
            designation: "",
            parentId: '',
            addedRolePlayedList: []
        })
    }

    // Designation Details
    getDesignationDetails(designationId) {
        const { rolePlayedList } = this.state
        console.log(' rolePlayedList ++++ ', rolePlayedList)
        var id = designationId
        this.setState({ isLoading: false })
        this.props
            .designationDetails_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log('addedRolePlayedList 55', data.data['roleList'])

                    var list = []
                    data.data['roleList'].length > 0 && data.data['roleList'].map(data => {
                        rolePlayedList.length > 0 && rolePlayedList.map(item => {
                            if (item.egrsRole == data.Designation) {
                                item.id = data.ID
                                item.actionId = 0
                                list.push(item)
                            }
                        })
                    })
                    console.log('data1 list', list)

                    this.setState({
                        designation: data.data['detailsList'][0]['Designation'],
                        parentId: data.data['detailsList'][0]['ParentDesID'],
                        addedRolePlayedList: list,
                        isActive: data.data['detailsList'][0]['IsActive'],
                    })
                    console.log('getDesignationDetails', data.data)
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }
    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }


    // Role Played
    onSelectRolePlayed(e) {
        const { designation, rolePlayedList, addedRolePlayedList } = this.state
        if (designation != '' && designation != null) {
            this.setState({ egrnID: e.target.value, rolePlayedId: e.target.value != "" ? e.target.value : "" })
            var isDataAvailable = false
            rolePlayedList.map((item) => {

                if (item.egrnID == e.target.value) {
                    console.log("addedRolePlayedList item", item)

                    addedRolePlayedList.map(data => {

                        if (data.egrnID == e.target.value) {
                            isDataAvailable = true
                        }
                        // else {
                        //     item.actionId = 1
                        // }
                    })

                    if (isDataAvailable) {
                        toast.error('Already added role', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                    else {
                        item.actionId = 1
                        addedRolePlayedList.push(item)
                        this.setState({ addedRolePlayedList: addedRolePlayedList })
                    }
                    console.log(" addedRolePlayedList", addedRolePlayedList)

                }
            })

        } else {
            toast.error('Please select mendatory fields', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true
            })
        }
    }

    // Role Played Delete
    deleteRoleplayed(e, roleId, egrnID) {
        e.preventDefault()
        const { addedRolePlayedList, } = this.state
        var id = roleId
        this.setState({ isLoading: true })
        console.log("323232", addedRolePlayedList)
        const filterList = addedRolePlayedList.filter(item => item.egrnID != egrnID);
        console.log("filterList", filterList)
        this.setState({ addedRolePlayedList: filterList })
        this.props
            .rolePlayedDelete_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    console.log()
                    this.setState({ isLoading: false })

                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }
    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
            this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
    };

    handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
            this.setState({ pageSizeInput: input });
        }
    };

    // Designation Delete
    toggleModal(designationId) {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
        this.state._isDelete = true
        this.state._isAdd = false
        this.state._isUpdate = false
        if (designationId == '') {
            return;
        } else {
            this.setState({ designationId: designationId })
        }

    }
    handleDelete(e) {
        e.preventDefault()
        var id = this.state.designationId
        this.setState({ isLoading: true })
        this.props
            .designationDelete_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isLoading: false })
                    this.handleCancel()
                    this.getDesignationList()
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    handleUpdate(designationId) {
        this.props.navigate('/designationAU', { state: { is_update: true, DesignationId: designationId } })
    }
    handleAdd() {
        this.props.navigate('/designationAU', { state: { is_add: true } })
    }

    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.getDesignationList()
        }
    }

    // cancel Designation
    handleCancel() {
        this.state.isOpen = false
        // this.getDesignationList()
        this.clearValue()
    }

    // Designation add
    toggleModalAdd(departmentId) {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen, _isAdd: true })
        this.clearValue()
        this.state._isAdd = true
        this.state._isUpdate = false
        this.state._isDelete = false
    }

    record(designationList) {
        return (
            <table className="table table-bordered tb-action du-table -50">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ textAlign: 'left' }}>Designation</th>
                        <th style={{ textAlign: 'left' }}>Reporting</th>
                        <th style={{ width: '100px' }}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {designationList.length > 0 ? (
                        designationList.map((data, i) => (
                            <tr key={data.DesignationID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        {getModuleUpdateAccess(Master) ?
                                            <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleUpdate(data.DesignationID)} /></a> :
                                            <a><i className="fa fa-eye" style={{ color: '#3498db' }} onClick={() => this.handleUpdate(data.DesignationID)} /></a>
                                        }
                                        {data.IsActive == 1 && getModuleDeleteAccess(Master) ? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.DesignationID)} /></span></a>
                                            : <></>}
                                    </div>
                                </td>
                                <td style={{ textAlign: 'left' }}>{data.Designation}</td>
                                <td style={{ textAlign: 'left' }}>{data.reporting}</td>
                                <td style={{ textAlign: 'center' }}>{data.IsActive ? "Active" : "Inactive"}</td>
                            </tr>
                        ))

                    ) : (<tr><td colSpan={5} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }

    render() {
        const { isSearchTab, isOpenUpdateAddModal, designation,
            status, designationList, isAccessToView,
            isLoading, _isDelete, isOpen, designationId, _isAdd,
            _isUpdate, parentId, parentList, rolePlayedId,
            rolePlayedList, addedRolePlayedList, search_designation } = this.state
        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = designationList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(designationList.length / recordsPerPage)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />


                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>Designation</a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className='page-space-from-bottom'>
                                        <div className="row">
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n.accordion-button::after {\nbackground-size: 13px;\ndisplay: none;\n} \n" }} />
                                            <div className="col-md-12 pg-body grid-margin stretch-card" style={{ height: '75vh', overflow: 'scroll' }}>
                                                <div className="card">
                                                    <div className="card-body pr-2">
                                                        <div className="accordion accordion-flush " id="accordionFlushExample">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingOne">
                                                                    <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                                                        <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                    </button>
                                                                </h2>
                                                            </div>
                                                            {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                            <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                <div className="d-flex flex-wrap row">
                                                                    <div className="form-group col-md-3">
                                                                        <label>Designation Role</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            value={search_designation}
                                                                            onKeyDown={e => this.handleKeyDown(e)}
                                                                            onChange={(e) => {
                                                                                const inputValue = e.target.value;
                                                                                if (inputValue.length <= 50) {
                                                                                    this.setState({ search_designation: inputValue });
                                                                                }
                                                                            }} required />
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label>Reporting</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            value={parentId}
                                                                            onKeyDown={e => this.handleKeyDown(e)}
                                                                            onChange={(e) => this.setState({ parentId: e.target.value })}
                                                                            required />
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label>Status</label>
                                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                                            value={status}
                                                                            onChange={(e) => this.setState({ status: e.target.value })}>
                                                                            <option value=''>Select</option>
                                                                            <option value={1}>Active</option>
                                                                            <option value={0}>Inactive</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-md-3 filter-btn-col p-0">
                                                                        <label></label>
                                                                        <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={e => this.getDesignationList()}> Search</button>
                                                                        <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3" onClick={e => this.clearValue()}> Clear</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div> : <></>}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 mt-1">
                                                                {getModuleWriteAccess(Master) && <button type="button" className="btn btn-Add rounded-1 mb-1 px-3"
                                                                    style={{ lineHeight: '14px', padding: '7px', float: 'right' }} onClick={(e) => this.handleAdd()}> Add</button>}
                                                            </div>
                                                            <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                                {this.record(currentRecords)}
                                                                {designationList.length > 0 ? <Pagination
                                                                    nPages={nPages}
                                                                    currentPage={currentPage}
                                                                    setCurrentPage={this.updateItem}
                                                                    recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                    setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                    totalRecords={designationList.length}
                                                                /> : <></>}                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>


                <ToastContainer />
                {/* // modal for delete */}
                {/* <Modal isOpen={isOpen && _isDelete} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '400px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, designationId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}


                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right">
                                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, designationId)}> Yes</a>
                                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* Modal for add and update */}
                {/* <Modal isOpen={isOpenUpdateAddModal} onRequestClose={() => this.CancelOpenUpdateAddModal()}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '560px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Designation</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.CancelOpenUpdateAddModal()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div className='model-body p-2'>
                                <div className="d-flex flex-wrap row ">
                                    <div class="form-group col-md-6">
                                        <label>Designation<span class="required1">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={designation}
                                            onChange={(e) => this.setState({ designation: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-md-6 ">
                                        <label>
                                            Reporting
                                        </label>
                                        <select
                                            className="js-example-basic-single"
                                            style={{ width: "100%" }}

                                            value={parentId}
                                            onChange={e => this.setState({ parentId: e.target.value != "Select" ? e.target.value : "" })}
                                        >
                                            <option value=''>Select</option>
                                            {parentList.length > 0 ? parentList.map(item => (
                                                <option
                                                    key={item.DesignationID}
                                                    value={item.DesignationID}>
                                                    {item.Reporting}
                                                </option>
                                            )) : <></>}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6 mt-2">
                                        <label>
                                            Role Played
                                        </label>
                                        <select
                                            className="js-example-basic-single"
                                            style={{ width: "100%" }}

                                            value={rolePlayedId}
                                            onChange={(e) => this.onSelectRolePlayed(e)}
                                        >
                                            <option value={''}>Select</option>
                                            {rolePlayedList.length > 0 ? rolePlayedList.map(item => (
                                                <option
                                                    key={item.egrnID}
                                                    value={item.egrnID}>
                                                    {item.egrsRole}
                                                </option>
                                            )) : <></>}
                                        </select>

                                    </div>
                                    <div className="table-responsive tbs4 common-table trail-tb mt-2 mb-2" >
                                        <table className="table table-bordered tb-action du-table ">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th style={{ textAlign: 'left' }} >Engagement Role</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {addedRolePlayedList.length > 0 ? (
                                                    addedRolePlayedList.map((data, i) => (
                                                        <tr key={data.egrnID}>
                                                            <td className="text-center" style={{ padding: '5px!important' }}>
                                                                <div>{getModuleDeleteAccess(Master) &&
                                                                    <a><span className="text-danger"><i className="fa fa-trash-o" onClick={(e) => this.deleteRoleplayed(e, data.id, data.egrnID)} /></span></a>}
                                                                </div>
                                                            </td>
                                                            <td style={{ textAlign: 'left' }}>{data.egrsRole}</td>
                                                        </tr>
                                                    ))

                                                ) : (<tr><td colSpan={5} className='text-center'>No records to display.</td></tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer p-0'>

                                <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic example"
                                >
                                    {
                                        this.state._isAdd && getModuleWriteAccess(Master) && <button
                                            type="button"
                                            className="btn btn-save footer-btn-inner mr-1"
                                            onClick={(e) => this.handleAdd(e)}
                                        >
                                            Save
                                        </button>
                                    }

                                    {
                                        this.state._isUpdate && getModuleUpdateAccess(Master) &&
                                        <button
                                            type="button"
                                            className="btn btn-update footer-btn-inner mr-1"
                                            onClick={(e) => this.handleUpdate(e)}
                                        >
                                            Update
                                        </button>
                                    }
                                    {
                                        this.state._isAdd && getModuleWriteAccess(Master) &&
                                        <button
                                            type="button"
                                            className="btn btn-update footer-btn-inner mr-1"
                                            onClick={() => this.clearValue()}
                                        >
                                            Reset
                                        </button>
                                    }
                                    <button
                                        type="button"
                                        className="btn btn-cancel footer-btn-inner mr-1"
                                        onClick={() => this.CancelOpenUpdateAddModal()}
                                    >
                                        Cancel
                                    </button>

                                </div>

                            </div>

                        </div>
                    </div>
                </Modal> */}


            </>
        );
    }

}

export default withRouter(connect(null, { designationList_action, designationDelete_action, fill_action, designationAdd_action, rolePlayedDelete_action, designationUpdate_action, designationDetails_action })(Designation))