import { axiosPost, axiosGet } from "../react_axios/Axios"

// export const BASE_URL = "http://localhost:9001";
// export const BASE_URL = "http://localhost:9000";
// export const BASE_URL = "http://192.168.3.11:8081/api";
// export const BASE_URL = "http://192.168.3.11:9010/api";
// export const BASE_URL = "http://202.189.254.89:8084/api";
// export const BASE_URL = "http://uat.nvc.co.in/api";
// live url ................
export const BASE_URL = "https://nvcaasan.in/api";
//dev url.......................................................
// export var BASE_URL = `http://${window.location.host}:9000`;
// prod url with HTTPS.....................................................
// export var BASE_URL = `https://${window.location.host}/api`;
// prod url with HTTP only.....................................................
// export var BASE_URL = `http://${window.location.host}/api`;
//dev.......................................................
// export var REPORT_BASE_URL = `http://192.168.3.11:9010/doc`;
// product
// export var REPORT_BASE_URL = `http://192.168.3.11:8081/doc`;

//UAT.........................................................
// export var REPORT_BASE_URL = `http://uat.nvc.co.in/doc`;

//prod.........................................................
export var REPORT_BASE_URL = `https://nvcaasan.in/doc`;


export const fetchAuthReport = `${REPORT_BASE_URL}/report/SetParameterData`;
export const downloadReport = `${REPORT_BASE_URL}/WebForm/AasanReportViewer.aspx`;
export const downloadReportTrainingFirmWise = `${REPORT_BASE_URL}/WebForm/TrainingStatus.aspx`;

export const checklistReportName = 'ChecklistTemplate.rpt'
export const engAllocationReportName = 'AllocatedAuditList.rpt'
export const auditPlaneReportName = 'AuditPlan.rpt'
export const EngStatusReportName = 'EngagementStatus.rpt'
export const EngClosureReportName = 'Closure.rpt'
export const TrainingStatus = 'TrainingStatus.rpt'
export const FirmwiseTrainngStatus = 'FirmwiseTrainngStatus.rpt'
export const EmployeewiseTrainingStatus = 'EmployeewiseTrainingStatus.rpt'
export const UserProfileStatus = 'Userprofile.rpt'
// reports page
export const TimesheetEmpSummaryReport = 'TimesheetEmployeewiseSummaryReport.rpt'
export const TimesheetClientSummaryReport = 'TimesheetClientwiseSummaryReport.rpt'
export const ExpenseEmpSummaryReport = 'ExpenseReportEmployeewiseSummaryreport.rpt'
export const ExpenseClientSummaryReport = 'ExpenseReportClientwiseSummaryreport.rpt'
// c:\Users\Admin\Downloads\timesheetDetailReport.rpt
export const EmpClientSummaryReport = 'EmployeeClientWiseReport.rpt'
export const TimesheetDetailsReport = 'timesheetDetailReport.rpt'
export const ClientDetailsReport = 'ClientDetailsReport.rpt'
export const ClientEngDetailsReport = 'ClientEngDetailsReport.rpt'

// export const TimesheetDetailsReport = 'timesheetDetailReport.rpt'
// export const ClientDetailsReport = 'timesheetDetailReport.rpt'
// export const ClientEngDetailsReport = 'timesheetDetailReport.rpt'

export const LeaveReport = 'LeaveReport.rpt'
export const EmpLeaveReport = 'EmpLeaveReport.rpt'
export const EmployeeHasEnteredTimesheetReport = 'EmployeeLastTimesheetDate.rpt'


export const authUrl = `${BASE_URL}/timesheet/validateUser`;
export const changePasswordUrl = `${BASE_URL}/user/changePassword`;
export const forgetPasswordUrl = `${BASE_URL}/user/forgetPassword`;

// dashboard
export const dashboardUrl = `${BASE_URL}/dashboard/fetchDashboardList`;

// dropdown list
export const fillListUrl = `${BASE_URL}/checklist/getDropdownList`;

// financial list
export const financialYearListUrl = `${BASE_URL}/financialYear/fetchFinancialYearList`;
export const financialYearDetailsUrl = `${BASE_URL}/financialYear/fetchFinancialYearDetails`;
export const addFinancialYearListUrl = `${BASE_URL}/financialYear/addFinancialYearList`;
export const deleteFinancialYearUrl = `${BASE_URL}/financialYear/deleteFinancialYearList`;
export const updateFinancialYearUrl = `${BASE_URL}/financialYear/updateFinancialYearList`;

// employee 
export const employeeListUrl = `${BASE_URL}/employee/getEmployeeList`;
export const employeeDetailsUrl = `${BASE_URL}/employee/getEmployeeDetails`;
export const addEmployeeListUrl = `${BASE_URL}/employee/addEmployee`;
export const deleteEmployeeUrl = `${BASE_URL}/employee/deleteEmployee`;
export const updateEmployeeUrl = `${BASE_URL}/employee/updateEmployee`;

export const deleteEmployeeEngRoleUrl = `${BASE_URL}/employee/deleteEmployeeRolePlayed`;

// employee Other Contact Details
export const employeeContactListUrl = `${BASE_URL}/employee/getContactEmployeeList`;
export const employeeContactDetailsUrl = `${BASE_URL}/employee/getContactEmployeeDetails`;
export const addEmployeeContactUrl = `${BASE_URL}/employee/addContactEmployee`;
export const updateEmployeeContactUrl = `${BASE_URL}/employee/updateContactEmployee`;
export const deleteEmployeeContactUrl = `${BASE_URL}/employee/deleteContactEmployee`;

// Department
export const departmentListUrl = `${BASE_URL}/department/getDepartmentList`
export const departmentAddUrl = `${BASE_URL}/department/addDepartment`
export const departmentDetailsUrl = `${BASE_URL}/department/getDepartmentDetails`
export const departmentUpdateUrl = `${BASE_URL}/department/updateDepartment`
export const departmentDeleteUrl = `${BASE_URL}/department/deleteDepartment`

// DESIGNATION
export const designationListUrl = `${BASE_URL}/designation/getDesignationList`
export const designationAddUrl = `${BASE_URL}/designation/addDesignation`
export const designationDetailsUrl = `${BASE_URL}/designation/getDesignationDetails`
export const designationUpdateUrl = `${BASE_URL}/designation/updateDesignation`
export const designationDeleteUrl = `${BASE_URL}/designation/deleteDesignation`
export const rolePlayedDeleteUrl = `${BASE_URL}/designation/deleteRolePlayed`

// check list
export const checkListUrl = `${BASE_URL}/checklist/fetchCheckList`;
export const checkListDetailsUrl = `${BASE_URL}/checklist/fetchCheckListDetails`;
export const addcheckListUrl = `${BASE_URL}/checklist/addCheckList`;
export const deletecheckListUrl = `${BASE_URL}/checklist/deleteCheckList`;
export const updateCheckListUrl = `${BASE_URL}/checklist/updateCheckList`;

// check list template
export const templateUrl = `${BASE_URL}/checklistTemplate/fetchTemplate`;
export const checkListTemplateDetailsUrl = `${BASE_URL}/checklistTemplate/fetchCheckListTemplateDetails`;
export const addcheckListTemplateUrl = `${BASE_URL}/checklistTemplate/addCheckListTemplate`;
export const deletecheckTemplateUrl = `${BASE_URL}/checklistTemplate/deleteCheckListTemplate`;
export const updateCheckTemplateUrl = `${BASE_URL}/checklistTemplate/updateCheckListTemplate`;

// engagement allocation  
export const engagementAllocationUrl = `${BASE_URL}/engagementAllocation/fetchEngagementAllocation`;
export const engagementAllocationDetailsUrl = `${BASE_URL}/engagementAllocation/fetchEngagementAllocationDetails`;
export const addEngagementAllocationUrl = `${BASE_URL}/engagementAllocation/addEngagementAllocation`;
export const deleteEngagementAllocationUrl = `${BASE_URL}/engagementAllocation/deleteEngagementAllocation`;
export const updateEngagementAllocationUrl = `${BASE_URL}/engagementAllocation/updateEngagementAllocation`;

//  client engagement  
export const clientEngagementUrl = `${BASE_URL}/clientEngagement/fetchClientEngagement`;
export const clientEngagementDetailsUrl = `${BASE_URL}/clientEngagement/fetchClientEngagementDetails`;
export const addClientEngagementUrl = `${BASE_URL}/clientEngagement/addClientEngagement`;
export const deleteClientEngagementUrl = `${BASE_URL}/clientEngagement/deleteClientEngagement`;
export const updateClientEngagementUrl = `${BASE_URL}/clientEngagement/updateClientEngagement`;
export const clientCheckListUrl = `${BASE_URL}/clientEngagement/fetchCheckList`;
export const getDesignationDropdownlistClientEngagementUrl = `${BASE_URL}/clientEngagement/getDesignationDropdownlistClientEngagement`;

//  update checklist 
export const updateChecklistUrl = `${BASE_URL}/updateChecklist/fetchUpdateChecklist`;
export const updateChecklistDetailsUrl = `${BASE_URL}/updateChecklist/fetchUpdateChecklistDetails`;
export const updateUpdateChecklistUrl = `${BASE_URL}/updateChecklist/updateUpdateChecklist`;
export const closeUpdateChecklistUrl = `${BASE_URL}/updateChecklist/closeUpdateChecklist`;

//  review checklist 
export const reviewChecklistUrl = `${BASE_URL}/reviewChecklist/fetchReviewChecklist`;
export const reviewChecklistDetailsUrl = `${BASE_URL}/reviewChecklist/fetchReviewChecklistDetails`;
export const updateReviewChecklistUrl = `${BASE_URL}/reviewChecklist/updateReviewChecklist`;
export const closeReviewChecklistUrl = `${BASE_URL}/reviewChecklist/closeReviewChecklist`;
export const uploadFileUrl = `${BASE_URL}/reviewChecklist/uploadFile`;
export const deleteFileUrl = `${BASE_URL}/reviewChecklist/deleteFile`;
export const getFileUrl = `${BASE_URL}/reviewChecklist/getFile`;

// time sheet 
export const timesheetUrl = `${BASE_URL}/timesheet/fetchTimesheet_web`;
export const timesheetDetailsUrl = `${BASE_URL}/timesheet/getTimesheetDetails_web`;
export const addTimesheetUrl = `${BASE_URL}/timesheet/addTimesheet_web`;
export const deleteTimesheetUrl = `${BASE_URL}/timesheet/deleteTimesheetData_web`;
export const updateTimesheetUrl = `${BASE_URL}/timesheet/updateTimesheet_web`;
export const approvalTimesheetUrl = `${BASE_URL}/timesheet/approvalTimesheet`;
export const lastTimesheetFillUrl = `${BASE_URL}/timesheet/lastTimesheetFill`;
export const singleLastTimesheetDateUrl = `${BASE_URL}/timesheet/singleLastTimesheetDate`;

// expense 
export const expenseUrl = `${BASE_URL}/expense/fetchExpenseList`;
export const getTimesheetIdUrl = `${BASE_URL}/expense/getTimesheetId`;
export const expenseDetailsUrl = `${BASE_URL}/expense/getExpenseDetails`;
export const addExpenseUrl = `${BASE_URL}/expense/addExpense`;
export const deleteExpenseUrl = `${BASE_URL}/expense/deleteExpenseData`;
export const updateExpenseUrl = `${BASE_URL}/expense/updateExpense`;
export const approvalExpenseUrl = `${BASE_URL}/timesheet/approvalExpense`;

export const deleteExpenseDocumentUrl = `${BASE_URL}/expense/deleteExpenseDocument`;
export const fetchLastTimeSheetExpenseUrl = `${BASE_URL}/expense/LastTimesheet`;

// approval
export const approvalUrl = `${BASE_URL}/approval/fetchApprovalList`;
export const approvalDetailsUrl = `${BASE_URL}/approval/getApprovalDetails`;
export const approvalRejectUrl = `${BASE_URL}/approval/approvalReject`;

// document
export const documentUrl = `${BASE_URL}/document/fetchDocument`;
export const documentDetailsUrl = `${BASE_URL}/document/getDocumentDetails`;
export const documentAddUrl = `${BASE_URL}/document/addDocument`;
export const documentUpdateUrl = `${BASE_URL}/document/updateDocument`;
export const deleteDocumentUrl = `${BASE_URL}/document/deleteDocumentData`;

//Client
export const clientListUrl = `${BASE_URL}/clientMaster/getClientMasterList`;
export const clientDetailsUrl = `${BASE_URL}/clientMaster/getClientMasterDetails`;
export const addClientUrl = `${BASE_URL}/clientMaster/addClientMaster`;
export const deleteClientUrl = `${BASE_URL}/clientMaster/deleteClientMaster`;
export const updateClientUrl = `${BASE_URL}/clientMaster/updateClientMaster`;

export const clientConpanyListUrl = `${BASE_URL}/clientMaster/getClientCompanyList`;
export const addClientConpanyUrl = `${BASE_URL}/clientMaster/addClientCompany`;
export const updateClientCompanyUrl = `${BASE_URL}/clientMaster/updateClientCompany`;
export const clientCompanyDetailsUrl = `${BASE_URL}/clientMaster/getClientCompanyDetails`;

export const clientlocationListUrl = `${BASE_URL}/clientMaster/getClientlocationList`;
export const addClientLocationUrl = `${BASE_URL}/clientMaster/addClientLocation`;
export const updateClientLocationUrl = `${BASE_URL}/clientMaster/updateClientLocation`;
export const clientLocationDetailsUrl = `${BASE_URL}/clientMaster/getClientLocationDetails`;

export const clientAddressListUrl = `${BASE_URL}/clientMaster/getClientAddressList`;
export const addClientAddressUrl = `${BASE_URL}/clientMaster/addClientAddress`;
export const updateClientAddressUrl = `${BASE_URL}/clientMaster/updateClientAddress`;
export const clientAddressDetailsUrl = `${BASE_URL}/clientMaster/getClientAddressDetails`;

//Engagement master
export const engagementMasterListURL = `${BASE_URL}/EngagementMaster/getEngagementMasterList`;
export const engagementMasterDetailsUrl = `${BASE_URL}/EngagementMaster/getEngagementMasterDetails`;
export const addEngagementMasterUrl = `${BASE_URL}/EngagementMaster/addEngagementMaster`;
export const deleteEngagementMasterUrl = `${BASE_URL}/EngagementMaster/deleteEngagementMaster`;
export const updateEngagementMasterUrl = `${BASE_URL}/EngagementMaster/updateEngagementMaster`;

// Blood Group
export const bloodGroupListUrl = `${BASE_URL}/bloodGroup/getBloodGroupList`
export const bloodGroupDetailsUrl = `${BASE_URL}/bloodGroup/getBloodGroupDetails`
export const bloodGroupAddUrl = `${BASE_URL}/bloodGroup/addBloodGroup`
export const bloodGroupUpdateUrl = `${BASE_URL}/bloodGroup/updateBloodGroup`
export const bloodGroupDeleteUrl = `${BASE_URL}/bloodGroup/deleteBloodGroup`

// ClientGroup
export const clientGroupListUrl = `${BASE_URL}/clientGroup/getClientGroupList`
export const clientGroupDetailsUrl = `${BASE_URL}/clientGroup/getClientGroupDetails`
export const clientGroupAddUrl = `${BASE_URL}/clientGroup/addClientGroup`
export const clientGroupUpdateUrl = `${BASE_URL}/clientGroup/updateClientGroupName`
export const clientGroupDeleteUrl = `${BASE_URL}/clientGroup/deleteClientGroup`

// leaveApproval
export const leaveApprovalListUrl = `${BASE_URL}/leaveApproval/leaveApprovalList`
export const leaveApprovalDetailsUrl = `${BASE_URL}/leaveApproval/leaveApprovalDetails`
export const leaveApprovalStatusUrl = `${BASE_URL}/leaveApproval/leaveApprovalStatus`
export const leaveApprovalRejectUrl = `${BASE_URL}/leaveApproval/leaveApprovalReject`

// TaskType
export const taskTypeListUrl = `${BASE_URL}/taskType/getTaskTypeList`
export const taskTypeDetailsUrl = `${BASE_URL}/taskType/getTaskTypeDetails`
export const taskTypeAddUrl = `${BASE_URL}/taskType/addTaskType`
export const taskTypeUpdateUrl = `${BASE_URL}/taskType/updateTaskType`
export const taskTypeDeleteUrl = `${BASE_URL}/taskType/deleteTaskType`

//Holiday
export const holidayListUrl = `${BASE_URL}/HolidayMaster/getHolidayMasterList`;
export const addHolidayUrl = `${BASE_URL}/HolidayMaster/addHolidayMaster`;
export const updateHolidayUrl = `${BASE_URL}/HolidayMaster/updateHolidayMaster`;
export const holidayDetailsUrl = `${BASE_URL}/HolidayMaster/getHolidayMasterDetails`;
export const deleteHolidayUrl = `${BASE_URL}/HolidayMaster/deleteHolidayMaster`;

// User Company
export const locationTypeListUrl = `${BASE_URL}/locationType/getLocationTypeList`
export const locationTypeDetailsUrl = `${BASE_URL}/locationType/getLocationTypeDetails`
export const locationTypeAddUrl = `${BASE_URL}/locationType/addLocationType`
export const locationTypeUpdateUrl = `${BASE_URL}/locationType/updateLocationType`
export const locationTypeDeleteUrl = `${BASE_URL}/locationType/deleteLocationType`

//Address type
export const addressTypeListUrl = `${BASE_URL}/AddressType/getAddressTypeList`;
export const addAddressTypeUrl = `${BASE_URL}/AddressType/addAddressType`;
export const updateAddressTypeUrl = `${BASE_URL}/AddressType/updateAddressType`;
export const addressTypeDetailsUrl = `${BASE_URL}/AddressType/getAddressTypeDetails`;
export const deleteAddressTypeUrl = `${BASE_URL}/AddressType/deleteAddressType`;

//working days
export const workingDaysListUrl = `${BASE_URL}/workingDays/getWorkingDaysList`;
export const updateWorkingDaysUrl = `${BASE_URL}/workingDays/updateWorkingDays`;
export const addWorkingDaysUrl = `${BASE_URL}/workingDays/addWorkingDays`;
export const workingDaysDetailsUrl = `${BASE_URL}/workingDays/getWorkingDaysDetails`;

//client category
export const ClientCategoryListUrl = `${BASE_URL}/clientCategory/getClientCategoryList`;
export const addClientCategoryUrl = `${BASE_URL}/clientCategory/addClientCategory`;
export const updateClientCategoryUrl = `${BASE_URL}/clientCategory/updateClientCategory`;
export const ClientCategoryDetailsUrl = `${BASE_URL}/clientCategory/getClientCategoryDetails`;
export const deleteClientCategoryUrl = `${BASE_URL}/clientCategory/deleteClientCategory`;

//expense head
export const expenseHeadListUrl = `${BASE_URL}/expenseHead/getExpenseHeadList`;
export const addExpenseHeadUrl = `${BASE_URL}/expenseHead/addExpenseHead`;
export const updateExpenseHeadUrl = `${BASE_URL}/expenseHead/updateExpenseHead`;
export const expenseHeadDetailsUrl = `${BASE_URL}/expenseHead/getExpenseHeadDetails`;
export const deleteExpenseHeadUrl = `${BASE_URL}/expenseHead/deleteExpenseHead`;

// management type
export const managementTypeListUrl = `${BASE_URL}/managementType/getManagementTypeList`;
export const managementTypeDetailsUrl = `${BASE_URL}/managementType/getManagementTypeDetails`;
export const managementTypeAddUrl = `${BASE_URL}/managementType/addManagementType`;
export const managementTypeUpdateUrl = `${BASE_URL}/managementType/updateManagementType`;
export const managementTypeDeleteUrl = `${BASE_URL}/managementType/deleteManagementType`;

// engagementRole 
export const engagementRoleListUrl = `${BASE_URL}/engagementRole/getEngagementRoleList`
export const engagementRoleDetailsUrl = `${BASE_URL}/engagementRole/getEngagementRoleDetails`
export const engagementRoleAddUrl = `${BASE_URL}/engagementRole/engagementRoleAdd`
export const engagementRoleUpdateUrl = `${BASE_URL}/engagementRole/engagementRoleUpdate`
export const deleteEngagementRoleUrl = `${BASE_URL}/engagementRole/deleteEngagementRole`

//leave
export const leaveListUrl = `${BASE_URL}/leave/getLeaveList`;
export const addLeaveUrl = `${BASE_URL}/leave/addLeave  `;
export const updateLeaveUrl = `${BASE_URL}/leave/updateLeave`;
export const leaveDetailsUrl = `${BASE_URL}/leave/getLeaveDetails`;
export const deleteLeaveUrl = `${BASE_URL}/leave/deleteLeave`;
export const minLeaveDateUrl = `${BASE_URL}/leave/getMinLeaveDate`;

//martial status
export const martialStatusListUrl = `${BASE_URL}/martialStatus/getMartialStatusList`;
export const addMartialStatusUrl = `${BASE_URL}/martialStatus/addMartialStatus`;
export const updateMartialStatusUrl = `${BASE_URL}/martialStatus/updateMartialStatus`;
export const martialStatusDetailsUrl = `${BASE_URL}/martialStatus/getMartialStatusDetails`;
export const deleteMartialStatusUrl = `${BASE_URL}/martialStatus/deleteMartialStatus`;

//gender
export const getGenderListUrl = `${BASE_URL}/gender/getGenderList`;
export const addGenderUrl = `${BASE_URL}/gender/addGender`;
export const updateGenderUrl = `${BASE_URL}/gender/updateGender`;
export const getGenderDetailsUrl = `${BASE_URL}/gender/getGenderDetails`;
export const deleteGenderUrl = `${BASE_URL}/gender/deleteGender`;

//prefix
export const getPrefixListUrl = `${BASE_URL}/prefix/getPrefixList`;
export const addPrefixUrl = `${BASE_URL}/prefix/addPrefix`;
export const updatePrefixUrl = `${BASE_URL}/prefix/updatePrefix`;
export const getPrefixDetailsUrl = `${BASE_URL}/prefix/getPrefixDetails`;
export const deletePrefixUrl = `${BASE_URL}/prefix/deletePrefix`;

//legal entity
export const getLegalEntityListUrl = `${BASE_URL}/legalEntity/getLegalEntityList`;
export const addLegalEntityUrl = `${BASE_URL}/legalEntity/addLegalEntity`;
export const updateLegalEntityUrl = `${BASE_URL}/legalEntity/updateLegalEntity`;
export const getLegalEntityDetailsUrl = `${BASE_URL}/legalEntity/getLegalEntityDetails`;
export const deleteLegalEntityUrl = `${BASE_URL}/legalEntity/deleteLegalEntity`;


//training
export const trainingUrl = `${BASE_URL}/training/getTrainingList`;
export const addtrainingUrl = `${BASE_URL}/training/addTraining`;
export const deletetrainingUrl = `${BASE_URL}/training/deleteTraining`;
export const approveRejectTrainingUrl = `${BASE_URL}/training/approveRejectTraining`;
export const getTrainingApprovalRejectListUrl = `${BASE_URL}/training/getTrainingApprovalRejectList`;
export const reportTrainingUrl = `${BASE_URL}/training/reportTraining`;
export const reportTrainingViewUrl = `${BASE_URL}/training/reportTrainingView`;

//mcq chapter
export const mcqListUrl = `${BASE_URL}/mcqChapter/fetchMCQChapterList`;
export const addMCQUrl = `${BASE_URL}/mcqChapter/addMCQChapter`;
export const deleteMCQUrl = `${BASE_URL}/mcqChapter/deleteMCQChapter`;
export const getMCQDetailsUrl = `${BASE_URL}/mcqChapter/getMCQChapterDetails`;
export const updateMCQUrl = `${BASE_URL}/mcqChapter/updateMCQChapter`;

//mcq setup
export const mcqSetupListUrl = `${BASE_URL}/mcqSetup/fetchMCQSetupList`;
export const addMCQSetupUrl = `${BASE_URL}/mcqSetup/addMCQSetup`;
export const deleteMCQSetupUrl = `${BASE_URL}/mcqSetup/deleteMCQSetup`;
export const getMCQSetupDetailsUrl = `${BASE_URL}/mcqSetup/getMCQSetupDetails`;
export const updateMCQSetupUrl = `${BASE_URL}/mcqSetup/updateMCQSetup`;

//setting
export const mcqSettingListUrl = `${BASE_URL}/setting/fetchMCQSetting`;
export const getSettingDetailsUrl = `${BASE_URL}/setting/getMCQSettingDetails`;
export const updateSettingUrl = `${BASE_URL}/setting/addMCQSetting`;
export const addSettingUrl = `${BASE_URL}/setting/UpdateMCQSetting`;
export const deleteMCQSetting = `${BASE_URL}/setting/deleteMCQSetting`;

//Test
export const TestListUrl = `${BASE_URL}/test/fetchMCQTest`;
export const getTestDetailsUrl = `${BASE_URL}/test/getMCQTestDetails`;
export const submitTestUrl = `${BASE_URL}/test/submitTest`;
export const addTestUrl = `${BASE_URL}/test/UpdateMCQTest`;
export const deleteTestUrl = `${BASE_URL}/test/deleteMCQTest`;

// reports
export const reportsUrl = `${BASE_URL}/reports/reports`;
export const LeaveReportViewUrl = `${BASE_URL}/reports/LeaveReportView`;
export const EmpLeaveReportViewUrl = `${BASE_URL}/reports/EmpLeaveReportView`;
export const timesheetEmpSummaryReportViewUrl = `${BASE_URL}/reports/TimesheetEmpSummaryReportView`;
export const timesheetClientSummaryReportViewUrl = `${BASE_URL}/reports/TimesheetClientSummaryReportView`;
export const EmployeeHasEnteredTimesheetReportViewUrl = `${BASE_URL}/reports/EmployeeHasEnteredTimesheetReportView`;
export const EmpClientSummaryReportViewUrl = `${BASE_URL}/reports/EmpClientSummaryReportView`;
export const ExpenseEmpSummaryReportViewUrl = `${BASE_URL}/reports/ExpenseEmpSummaryReportView`;
export const ExpenseClientSummaryReportViewUrl = `${BASE_URL}/reports/ExpenseClientSummaryReportView`;
export const TimesheetDetailsReportViewUrl = `${BASE_URL}/reports/TimesheetDetailsReportView`;
export const ClientDetailsReportViewUrl = `${BASE_URL}/reports/ClientDetailsReportView`;
export const ClientEngDetailsReportViewUrl = `${BASE_URL}/reports/ClientEngDetailsReportView`;




//  platfarm  name  const platform = window.navigator.platform
export const platfarm = window.navigator.platform


//get user manual pdf download...............
export const userManualDownloadUrl = `${BASE_URL}/userManualDownload`;

export const headers = {
  'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
  'Content-Type': 'application/json',
}



//action type start.....................
// dashboard
export const DASHBOARD_ACTION_TYPE = "DASHBOARD_ACTION_TYPE";

// login
export const LOGIN_ACTION_TYPE = "LOGIN";
export const CHANGE_PASSWORD_ACTION_TYPE = "CHANGE_PASSWORD_ACTION_TYPE";
export const FORGET_PASSWORD_ACTION_TYPE = "FORGET_PASSWORD_ACTION_TYPE";
export const FILL_LIST_ACTION_TYPE = "FILL_LIST_ACTION_TYPE";

// financial year
export const FINANCIAL_YEAR_LIST_ACTION_TYPE = "FINANCIAL_YEAR_LIST_ACTION_TYPE";
export const FINANCIAL_YEAR_LIST_DETAILS_ACTION_TYPE = "FINANCIAL_YEAR_LIST_DETAILS_ACTION_TYPE";
export const FINANCIAL_YEAR_LIST_ADD_ACTION_TYPE = "FINANCIAL_YEAR_LIST_ADD_ACTION_TYPE";
export const FINANCIAL_YEAR_LIST_UPDATE_ACTION_TYPE = "FINANCIAL_YEAR_LIST_UPDATE_ACTION_TYPE";
export const FINANCIAL_YEAR_LIST_DELETE_ACTION_TYPE = "FINANCIAL_YEAR_LIST_DELETE_ACTION_TYPE";

// Employee
export const EMPLOYEE_LIST_ACTION_TYPE = "EMPLOYEE_LIST_ACTION_TYPE";
export const EMPLOYEE_LIST_DETAILS_ACTION_TYPE = "EMPLOYEE_LIST_DETAILS_ACTION_TYPE";
export const EMPLOYEE_LIST_ADD_ACTION_TYPE = "EMPLOYEE_LIST_ADD_ACTION_TYPE";
export const EMPLOYEE_LIST_UPDATE_ACTION_TYPE = "EMPLOYEE_LIST_UPDATE_ACTION_TYPE";
export const EMPLOYEE_LIST_DELETE_ACTION_TYPE = "EMPLOYEE_LIST_DELETE_ACTION_TYPE";

export const ROLEPLAYED_EMPLOYEE_DELETE_ACTION_TYPE = "ROLEPLAYED_EMPLOYEE_DELETE_ACTION_TYPE"

// Other Contact Details
export const EMPLOYEE_CONTACT_LIST_ACTION_TYPE = "EMPLOYEE_CONTACT_LIST_ACTION_TYPE";
export const EMPLOYEE_CONTACT_DETAILS_ACTION_TYPE = "EMPLOYEE_CONTACT_DETAILS_ACTION_TYPE";
export const EMPLOYEE_CONTACT_ADD_ACTION_TYPE = "EMPLOYEE_CONTACT_ADD_ACTION_TYPE";
export const EMPLOYEE_CONTACT_UPDATE_ACTION_TYPE = "EMPLOYEE_CONTACT_UPDATE_ACTION_TYPE";
export const EMPLOYEE_CONTACT_DELETE_ACTION_TYPE = "EMPLOYEE_CONTACT_DELETE_ACTION_TYPE";

// Department
export const DEPARTMENT_LIST_ACTION_TYPE = "DEPARTMENT_LIST_ACTION_TYPE"
export const DEPARTMENT_ADD_ACTION_TYPE = "DEPARTMENT_ADD_ACTION_TYPE"
export const DEPARTMENT_DETAILS_ACTION_TYPE = "DEPARTMENT_DETAILS_ACTION_TYPE"
export const DEPARTMENT_UPDATE_ACTION_TYPE = "DEPARTMENT_UPDATE_ACTION_TYPE"
export const DEPARTMENT_DELETE_ACTION_TYPE = "DEPARTMENT_DELETE_ACTION_TYPE"

// Designation
export const DESIGNATION_LIST_ACTION_TYPE = "DESIGNATION_LIST_ACTION_TYPE"
export const DESIGNATION_ADD_ACTION_TYPE = "DESIGNATION_ADD_ACTION_TYPE"
export const DESIGNATION_DETAILS_ACTION_TYPE = "DESIGNATION_DETAILS_ACTION_TYPE"
export const DESIGNATION_UPDATE_ACTION_TYPE = "DESIGNATION_UPDATE_ACTION_TYPE"
export const DESIGNATION_DELETE_ACTION_TYPE = "DESIGNATION_DELETE_ACTION_TYPE"
export const ROLEPLAYED_DELETE_ACTION_TYPE = "ROLEPLAYED_DELETE_ACTION_TYPE"

// check list
export const CHECK_LIST_ACTION_TYPE = "CHECK_LIST_ACTION_TYPE";
export const CHECK_LIST_DETAILS_ACTION_TYPE = "CHECK_LIST_DETAILS_ACTION_TYPE";
export const CHECK_LIST_ADD_ACTION_TYPE = "CHECK_LIST_ADD_ACTION_TYPE";
export const CHECK_LIST_UPDATE_ACTION_TYPE = "CHECK_LIST_UPDATE_ACTION_TYPE";
export const CHECK_LIST_DELETE_ACTION_TYPE = "CHECK_LIST_DELETE_ACTION_TYPE";

// check list template
export const TEMPLATE_ACTION_TYPE = "TEMPLATE_ACTION_TYPE";
export const CHECK_LIST_TEMPLATE_ACTION_TYPE = "CHECK_LIST_TEMPLATE_ACTION_TYPE";
export const CHECK_LIST_TEMPLATE_DETAILS_ACTION_TYPE = "CHECK_LIST_TEMPLATE_DETAILS_ACTION_TYPE";
export const CHECK_LIST_TEMPLATE_ADD_ACTION_TYPE = "CHECK_LIST_TEMPLATE_ADD_ACTION_TYPE";
export const CHECK_LIST_TEMPLATE_UPDATE_ACTION_TYPE = "CHECK_LIST_TEMPLATE_UPDATE_ACTION_TYPE";
export const CHECK_LIST_TEMPLATE_DOWNLOAD_ACTION_TYPE = "CHECK_LIST_TEMPLATE_DOWNLOAD_ACTION_TYPE";
export const CHECK_LIST_TEMPLATE_DELETE_ACTION_TYPE = "CHECK_LIST_TEMPLATE_DELETE_ACTION_TYPE";

//  engagement allocation  
export const ENGAGEMENT_ALLOCATION_ACTION_TYPE = "ENGAGEMENT_ALLOCATION_ACTION_TYPE";
export const ENGAGEMENT_ALLOCATION_DETAILS_ACTION_TYPE = "ENGAGEMENT_ALLOCATION_DETAILS_ACTION_TYPE";
export const ENGAGEMENT_ALLOCATION_ADD_ACTION_TYPE = "ENGAGEMENT_ALLOCATION_ADD_ACTION_TYPE";
export const ENGAGEMENT_ALLOCATION_UPDATE_ACTION_TYPE = "ENGAGEMENT_ALLOCATION_UPDATE_ACTION_TYPE";
export const ENGAGEMENT_ALLOCATION_DELETE_ACTION_TYPE = "ENGAGEMENT_ALLOCATION_DELETE_ACTION_TYPE";

//  client engagement  
export const CLIENT_ENGAGEMENT_ACTION_TYPE = "CLIENT_ENGAGEMENT_ACTION_TYPE";
export const CLIENT_ENGAGEMENT_DETAILS_ACTION_TYPE = "CLIENT_ENGAGEMENT_DETAILS_ACTION_TYPE";
export const CLIENT_ENGAGEMENT_CHECKLIST_ACTION_TYPE = "CLIENT_ENGAGEMENT_CHECKLIST_ACTION_TYPE";
export const CLIENT_ENGAGEMENT_ADD_ACTION_TYPE = "CLIENT_ENGAGEMENT_ADD_ACTION_TYPE";
export const CLIENT_ENGAGEMENT_UPDATE_ACTION_TYPE = "CLIENT_ENGAGEMENT_UPDATE_ACTION_TYPE";
export const CLIENT_ENGAGEMENT_DELETE_ACTION_TYPE = "CLIENT_ENGAGEMENT_DELETE_ACTION_TYPE";
export const CLIENT_ENGAGEMENT_DESIGNATION_LIST_ACTION_TYPE = "CLIENT_ENGAGEMENT_DESIGNATION_LIST_ACTION_TYPE";

//  update checklist 
export const UPDATE_CHECKLIST_ACTION_TYPE = "UPDATE_CHECKLIST_ACTION_TYPE";
export const UPDATE_CHECKLIST_DETAILS_ACTION_TYPE = "UPDATE_CHECKLIST_DETAILS_ACTION_TYPE";
export const UPDATE_CHECKLIST_UPDATE_ACTION_TYPE = "UPDATE_CHECKLIST_UPDATE_ACTION_TYPE";
export const UPDATE_CHECKLIST_CLOSE_ACTION_TYPE = "UPDATE_CHECKLIST_CLOSE_ACTION_TYPE";

//  review checklist 
export const REVIEW_CHECKLIST_ACTION_TYPE = "REVIEW_CHECKLIST_ACTION_TYPE";
export const REVIEW_CHECKLIST_DETAILS_ACTION_TYPE = "REVIEW_CHECKLIST_DETAILS_ACTION_TYPE";
export const REVIEW_CHECKLIST_UPDATE_ACTION_TYPE = "REVIEW_CHECKLIST_UPDATE_ACTION_TYPE";
export const REVIEW_CHECKLIST_CLOSE_ACTION_TYPE = "REVIEW_CHECKLIST_CLOSE_ACTION_TYPE";
export const REVIEW_CHECKLIST_FILE_UPLOAD_ACTION_TYPE = "REVIEW_CHECKLIST_FILE_UPLOAD_ACTION_TYPE";
export const REVIEW_CHECKLIST_FILE_DELETE_ACTION_TYPE = "REVIEW_CHECKLIST_FILE_DELETE_ACTION_TYPE";
export const REVIEW_CHECKLIST_FILE_GET_ACTION_TYPE = "REVIEW_CHECKLIST_FILE_GET_ACTION_TYPE";

// BloodGroup Master
export const BLOODGROUP_LIST_ACTION_TYPE = "BLOODGROUP_LIST_ACTION_TYPE"
export const BLOODGROUP_DETAILS_ACTION_TYPE = "BLOODGROUP_DETAILS_ACTION_TYPE"
export const BLOODGROUP_ADD_ACTION_TYPE = "BLOODGROUP_ADD_ACTION_TYPE"
export const BLOODGROUP_UPDATE_ACTION_TYPE = "BLOODGROUP_UPDATE_ACTION_TYPE"
export const BLOODGROUP_DELETE_ACTION_TYPE = "BLOODGROUP_DELETE_ACTION_TYPE"

// Client Group
export const CLIENTGROUP_LIST_ACTION_TYPE = "CLIENTGROUP_LIST_ACTION_TYPE"
export const CLIENTGROUP_DETAILS_ACTION_TYPE = "CLIENTGROUP_DETAILS_ACTION_TYPE"
export const CLIENTGROUP_ADD_ACTION_TYPE = "CLIENTGROUP_ADD_ACTION_TYPE"
export const CLIENTGROUP_UPDATE_ACTION_TYPE = "CLIENTGROUP_UPDATE_ACTION_TYPE"
export const CLIENTGROUP_DELETE_ACTION_TYPE = "CLIENTGROUP_DELETE_ACTION_TYPE"

// Task Type
export const TASKTYPE_LIST_ACTION_TYPE = "TASKTYPELS_LIST_ACTION_TYPE"
export const TASKTYPE_DETAILS_ACTION_TYPE = "TASKTYPELS_DETAILS_ACTION_TYPE"
export const TASKTYPE_ADD_ACTION_TYPE = "TASKTYPELS_ADD_ACTION_TYPE"
export const TASKTYPE_UPDATE_ACTION_TYPE = "TASKTYPELS_UPDATE_ACTION_TYPE"
export const TASKTYPE_DELETE_ACTION_TYPE = "TASKTYPELS_DELETE_ACTION_TYPE"



// timesheet and expense
//  timesheet
export const TIMESHEET_ACTION_TYPE = "TIMESHEET_ACTION_TYPE";
export const TIMESHEET_DETAILS_ACTION_TYPE = "TIMESHEET_DETAILS_ACTION_TYPE";
export const TIMESHEET_ADD_ACTION_TYPE = "TIMESHEET_ADD_ACTION_TYPE";
export const TIMESHEET_UPDATE_ACTION_TYPE = "TIMESHEET_UPDATE_ACTION_TYPE";
export const TIMESHEET_DELETE_ACTION_TYPE = "TIMESHEET_DELETE_ACTION_TYPE";
export const TIMESHEET_APPROVAL_ACTION_TYPE = "TIMESHEET_APPROVAL_ACTION_TYPE";
export const LAST_TIMESHEET_FILL_ACTION_TYPE = "LAST_TIMESHEET_FILL_ACTION_TYPE";
export const SINGLE_LAST_TIMESHEET_DATE_ACTION_TYPE = "SINGLE_LAST_TIMESHEET_DATE_ACTION_TYPE";


//  expense
export const EXPENSE_ACTION_TYPE = "EXPENSE_ACTION_TYPE";
export const EXPENSE_GET_TIMESHEETID_ACTION_TYPE = "EXPENSE_GET_TIMESHEETID_ACTION_TYPE";
export const EXPENSE_DETAILS_ACTION_TYPE = "EXPENSE_DETAILS_ACTION_TYPE";
export const EXPENSE_ADD_ACTION_TYPE = "EXPENSE_ADD_ACTION_TYPE";
export const EXPENSE_UPDATE_ACTION_TYPE = "EXPENSE_UPDATE_ACTION_TYPE";
export const EXPENSE_DELETE_ACTION_TYPE = "EXPENSE_DELETE_ACTION_TYPE";
export const EXPENSE_APPROVAL_ACTION_TYPE = "EXPENSE_APPROVAL_ACTION_TYPE";

export const EXPENSE_DELETE_DOCUMENT_ACTION_TYPE = "EXPENSE_DELETE_DOCUMENT_ACTION_TYPE";

export const EXPENSE_LASTTIMESHEET_FILL_ACTION_TYPE = "EXPENSE_LASTTIMESHEET_FILL_ACTION_TYPE";

//approval
export const APPROVAL_LIST_ACTION_TYPE = "APPROVAL_LIST_ACTION_TYPE";
export const APPROVAL_DETAILS_ACTION_TYPE = "APPROVAL_DETAILS_ACTION_TYPE";
export const APPROVAL_REJECT_ACTION_TYPE = "APPROVAL_REJECT_ACTION_TYPE";

// document
export const DOCUMENT_ACTION_TYPE = "DOCUMENT_ACTION_TYPE";
export const DOCUMENT_DETAILS_ACTION_TYPE = "DOCUMENT_DETAILS_ACTION_TYPE";
export const DOCUMENT_ADD_ACTION_TYPE = "DOCUMENT_ADD_ACTION_TYPE";
export const DOCUMENT_UPDATE_ACTION_TYPE = "DOCUMENT_UPDATE_ACTION_TYPE";
export const DOCUMENT_DELETE_ACTION_TYPE = "DOCUMENT_DELETE_ACTION_TYPEDOCUMENT_UPDATE_ACTION_TYPE";

//client
export const CLIENT_LIST_ACTION_TYPE = "CLIENT_LIST_ACTION_TYPE";
export const CLIENT_LIST_DETAILS_ACTION_TYPE = "CLIENT_LIST_DETAILS_ACTION_TYPE";
export const CLIENT_LIST_ADD_ACTION_TYPE = "CLIENT_LIST_ADD_ACTION_TYPE";
export const CLIENT_LIST_UPDATE_ACTION_TYPE = "CLIENT_LIST_UPDATE_ACTION_TYPE";
export const CLIENT_LIST_DELETE_ACTION_TYPE = "CLIENT_LIST_DELETE_ACTION_TYPE";

export const CLIENT_COMPANY_LIST_ACTION_TYPE = "CLIENT_COMPANY_LIST_ACTION_TYPE";
export const CLIENT_COMPANY_LIST_ADD_ACTION_TYPE = "CLIENT_COMPANY_LIST_ADD_ACTION_TYPE";
export const CLIENT_COMPANY_LIST_UPDATE_ACTION_TYPE = "CLIENT_COMPANY_LIST_UPDATE_ACTION_TYPE";
export const CLIENT_COMPANY_LIST_DETAILS_ACTION_TYPE = "CLIENT_COMPANY_LIST_DETAILS_ACTION_TYPE";

export const CLIENT_LOCATION_LIST_ACTION_TYPE = "CLIENT_LOCATION_LIST_ACTION_TYPE";
export const CLIENT_LOCATION_LIST_ADD_ACTION_TYPE = "CLIENT_LOCATION_LIST_ADD_ACTION_TYPE";
export const CLIENT_LOCATION_LIST_UPDATE_ACTION_TYPE = "CLIENT_LOCATION_LIST_UPDATE_ACTION_TYPE";
export const CLIENT_LOCATION_LIST_DETAILS_ACTION_TYPE = "CLIENT_LOCATION_LIST_DETAILS_ACTION_TYPE";

export const CLIENT_ADDRESS_LIST_ACTION_TYPE = "CLIENT_ADDRESS_LIST_ACTION_TYPE";
export const CLIENT_ADDRESS_LIST_ADD_ACTION_TYPE = "CLIENT_ADDRESS_LIST_ADD_ACTION_TYPE";
export const CLIENT_ADDRESS_LIST_UPDATE_ACTION_TYPE = "CLIENT_ADDRESS_LIST_UPDATE_ACTION_TYPE";
export const CLIENT_ADDRESS_LIST_DETAILS_ACTION_TYPE = "CLIENT_ADDRESS_LIST_DETAILS_ACTION_TYPE";

//Holiday
export const HOLIDAY_LIST_ACTION_TYPE = "HOLIDAY_LIST_ACTION_TYPE";
export const HOLIDAY_ADD_ACTION_TYPE = "HOLIDAY_ADD_ACTION_TYPE";
export const HOLIDAY_UPDATE_ACTION_TYPE = "HOLIDAY_UPDATE_ACTION_TYPE";
export const HOLIDAY_DETAILS_ACTION_TYPE = "HOLIDAY_DETAILS_ACTION_TYPE";
export const HOLIDAY_DETETE_ACTION_TYPE = "HOLIDAY_DETETE_ACTION_TYPE";

//Address type
export const ADDRESS_TYPE_LIST_ACTION_TYPE = "ADDRESS_TYPE_LIST_ACTION_TYPE";
export const ADDRESS_TYPE_ADD_ACTION_TYPE = "ADDRESS_TYPE_ADD_ACTION_TYPE";
export const ADDRESS_TYPE_UPDATE_ACTION_TYPE = "ADDRESS_TYPE_UPDATE_ACTION_TYPE";
export const ADDRESS_TYPE_DETAILS_ACTION_TYPE = "ADDRESS_TYPE_DETAILS_ACTION_TYPE";
export const ADDRESS_TYPE_DETETE_ACTION_TYPE = "ADDRESS_TYPE_DETETE_ACTION_TYPE";

//engagement master
export const ENGAGEMENT_MASTER_LIST_ACTION_TYPE = "ENGAGEMENT_MASTER_LIST_ACTION_TYPE";
export const ENGAGEMENT_MASTER_ADD_ACTION_TYPE = "ENGAGEMENT_MASTER_ADD_ACTION_TYPE";
export const ENGAGEMENT_MASTER_DETAILS_ACTION_TYPE = "ENGAGEMENT_MASTER_DETAILS_ACTION_TYPE";
export const ENGAGEMENT_MASTER_UPDATE_ACTION_TYPE = "ENGAGEMENT_MASTER_UPDATE_ACTION_TYPE";
export const ENGAGEMENT_MASTER_DELETE_ACTION_TYPE = "ENGAGEMENT_MASTER_DELETE_ACTION_TYPE";

//working days
export const WORKING_DAYS_LIST_ACTION_TYPE = "WORKING_DAYS_LIST_ACTION_TYPE";
export const WORKING_DAYS_UPDATE_ACTION_TYPE = "WORKING_DAYS_UPDATE_ACTION_TYPE";
export const WORKING_DAYS_DETAILS_ACTION_TYPE = "WORKING_DAYS_DETAILS_ACTION_TYPE";
export const WORKING_DAYS_ADD_ACTION_TYPE = "WORKING_DAYS_ADD_ACTION_TYPE";


//client category
export const CLIENT_CATEGORY_LIST_ACTION_TYPE = "CLIENT_CATEGORY_LIST_ACTION_TYPE";
export const CLIENT_CATEGORY_ADD_ACTION_TYPE = "CLIENT_CATEGORY_ADD_ACTION_TYPE";
export const CLIENT_CATEGORY_UPDATE_ACTION_TYPE = "CLIENT_CATEGORY_UPDATE_ACTION_TYPE";
export const CLIENT_CATEGORY_DETAILS_ACTION_TYPE = "CLIENT_CATEGORY_DETAILS_ACTION_TYPE";
export const CLIENT_CATEGORY_DETETE_ACTION_TYPE = "CLIENT_CATEGORY_DETETE_ACTION_TYPE";

//expense head
export const EXPENSE_HEAD_LIST_ACTION_TYPE = "EXPENSE_HEAD_LIST_ACTION_TYPE";
export const EXPENSE_HEAD_ADD_ACTION_TYPE = "EXPENSE_HEAD_ADD_ACTION_TYPE";
export const EXPENSE_HEAD_UPDATE_ACTION_TYPE = "EXPENSE_HEAD_UPDATE_ACTION_TYPE";
export const EXPENSE_HEAD_DETAILS_ACTION_TYPE = "EXPENSE_HEAD_DETAILS_ACTION_TYPE";
export const EXPENSE_HEAD_DETETE_ACTION_TYPE = "EXPENSE_HEAD_DETETE_ACTION_TYPE";


//leave
export const LEAVE_LIST_ACTION_TYPE = "LEAVE_LIST_ACTION_TYPE";
export const LEAVE_ADD_ACTION_TYPE = "LEAVE_ADD_ACTION_TYPE";
export const LEAVE_UPDATE_ACTION_TYPE = "LEAVE_UPDATE_ACTION_TYPE";
export const LEAVE_DETAILS_ACTION_TYPE = "LEAVE_DETAILS_ACTION_TYPE";
export const LEAVE_DETETE_ACTION_TYPE = "LEAVE_DETETE_ACTION_TYPE";
export const LEAVE_MIN_DATE_ACTION_TYPE = "LEAVE_MIN_DATE_ACTION_TYPE";

//martial statusMARTIAL_STATUS
export const MARTIAL_STATUS_LIST_ACTION_TYPE = "MARTIAL_STATUS_LIST_ACTION_TYPE";
export const MARTIAL_STATUS_ADD_ACTION_TYPE = "MARTIAL_STATUS_ADD_ACTION_TYPE";
export const MARTIAL_STATUS_UPDATE_ACTION_TYPE = "MARTIAL_STATUS_UPDATE_ACTION_TYPE";
export const MARTIAL_STATUS_DETAILS_ACTION_TYPE = "MARTIAL_STATUS_DETAILS_ACTION_TYPE";
export const MARTIAL_STATUS_DETETE_ACTION_TYPE = "MARTIAL_STATUS_DETETE_ACTION_TYPE";

// leaveApproval
export const LEAVEAPPROVAL_LIST_ACTION_TYPE = "LEAVEAPPROVAL_LIST_ACTION_TYPE";
export const LEAVEAPPROVAL_DETAILS_ACTION_TYPE = "LEAVEAPPROVAL_DETAILS_ACTION_TYPE";

export const LEAVEAPPROVAL_STATUS_ACTION_TYPE = "LEAVEAPPROVAL_STATUS_ACTION_TYPE"
export const LEAVEAPPROVAL_REJECT_ACTION_TYPE = "LEAVEAPPROVAL_REJECT_ACTION_TYPE"

// User Company
export const LOCATIONTYPE_LIST_ACTION_TYPE = "LOCATIONTYPE_LIST_ACTION_TYPE"
export const LOCATIONTYPE_DETAILS_ACTION_TYPE = "LOCATIONTYPE_DETAILS_ACTION_TYPE"
export const LOCATIONTYPE_ADD_ACTION_TYPE = "LOCATIONTYPE_ADD_ACTION_TYPE"
export const LOCATIONTYPE_UPDATE_ACTION_TYPE = "LOCATIONTYPE_UPDATE_ACTION_TYPE"
export const LOCATIONTYPE_DELETE_ACTION_TYPE = "LOCATIONTYPE_DELETE_ACTION_TYPE"

// Management Type
export const MANAGEMENTTYPE_LIST_ACTION_TYPE = "MANAGEMENTTYPE_LIST_ACTION_TYPE"
export const MANAGEMENTTYPE_DETAILS_ACTION_TYPE = "MANAGEMENTTYPE_DETAILS_ACTION_TYPE"
export const MANAGEMENTTYPE_ADD_ACTION_TYPE = "MANAGEMENTTYPE_ADD_ACTION_TYPE"
export const MANAGEMENTTYPE_UPDATE_ACTION_TYPE = "MANAGEMENTTYPE_UPDATE_ACTION_TYPE"
export const MANAGEMENTTYPE_DELETE_ACTION_TYPE = "MANAGEMENTTYPE_DELETE_ACTION_TYPE"

// Engagementole 
export const ENGAGEMENTROLE_LIST_ACTION_TYPE = "ENGAGEMENTROLE_LIST_ACTION_TYPE"
export const ENGAGEMENTROLE_DETAILS_ACTION_TYPE = "ENGAGEMENTROLE_DETAILS_ACTION_TYPE"
export const ENGAGEMENTROLE_ADD_ACTION_TYPE = "ENGAGEMENTROLE_ADD_ACTION_TYPE"
export const ENGAGEMENTROLE_UPDATE_ACTION_TYPE = "ENGAGEMENTROLE_UPDATE_ACTION_TYPE"
export const ENGAGEMENTROLE_DELETE_ACTION_TYPE = "ENGAGEMENTROLE_DELETE_ACTION_TYPE"

// prefix 
export const PREFIX_LIST_ACTION_TYPE = "PREFIX_LIST_ACTION_TYPE"
export const PREFIX_DETAILS_ACTION_TYPE = "PREFIX_DETAILS_ACTION_TYPE"
export const PREFIX_ADD_ACTION_TYPE = "PREFIX_ADD_ACTION_TYPE"
export const PREFIX_UPDATE_ACTION_TYPE = "PREFIX_UPDATE_ACTION_TYPE"
export const PREFIX_DELETE_ACTION_TYPE = "PREFIX_DELETE_ACTION_TYPE"

// training 
export const TRAINING_LIST_ACTION_TYPE = "TRAINING_LIST_ACTION_TYPE"
export const TRAINING_APPROVE_Reject_ACTION_TYPE = "TRAINING_APPROVE_Reject_ACTION_TYPE"
export const TRAINING_APPROVE_Reject_LIST_ACTION_TYPE = "TRAINING_APPROVE_Reject_LIST_ACTION_TYPE"
export const TRAINING_ADD_ACTION_TYPE = "TRAINING_ADD_ACTION_TYPE"
export const TRAINING_REPORT_ACTION_TYPE = "TRAINING_REPORT_ACTION_TYPE"
export const TRAINING_DELETE_ACTION_TYPE = "TRAINING_DELETE_ACTION_TYPE"
export const TRAINING_REPORT_VIEW_ACTION_TYPE = "TRAINING_REPORT_VIEW_ACTION_TYPE"

// gender 
export const GENDER_LIST_ACTION_TYPE = "GENDER_LIST_ACTION_TYPE"
export const GENDER_DETAILS_ACTION_TYPE = "GENDER_DETAILS_ACTION_TYPE"
export const GENDER_ADD_ACTION_TYPE = "GENDER_ADD_ACTION_TYPE"
export const GENDER_UPDATE_ACTION_TYPE = "GENDER_UPDATE_ACTION_TYPE"
export const GENDER_DELETE_ACTION_TYPE = "GENDER_DELETE_ACTION_TYPE"

// lagal entity 
export const LEGALENTITY_LIST_ACTION_TYPE = "LEGALENTITY_LIST_ACTION_TYPE"
export const LEGALENTITY_DETAILS_ACTION_TYPE = "LEGALENTITY_DETAILS_ACTION_TYPE"
export const LEGALENTITY_ADD_ACTION_TYPE = "LEGALENTITY_ADD_ACTION_TYPE"
export const LEGALENTITY_UPDATE_ACTION_TYPE = "LEGALENTITY_UPDATE_ACTION_TYPE"
export const LEGALENTITY_DELETE_ACTION_TYPE = "LEGALENTITY_DELETE_ACTION_TYPE"

// mcq chapter
export const MCQ_LIST_ACTION_TYPE = "MCQ_LIST_ACTION_TYPE"
export const MCQ_DETAILS_ACTION_TYPE = "MCQ_DETAILS_ACTION_TYPE"
export const MCQ_ADD_ACTION_TYPE = "MCQ_ADD_ACTION_TYPE"
export const MCQ_UPDATE_ACTION_TYPE = "MCQ_UPDATE_ACTION_TYPE"
export const MCQ_DELETE_ACTION_TYPE = "MCQ_DELETE_ACTION_TYPE"

// mcq setup
export const MCQ_SETUP_LIST_ACTION_TYPE = "MCQ_SETUP_LIST_ACTION_TYPE"
export const MCQ_SETUP_DETAILS_ACTION_TYPE = "MCQ_SETUP_DETAILS_ACTION_TYPE"
export const MCQ_SETUP_ADD_ACTION_TYPE = "MCQ_SETUP_ADD_ACTION_TYPE"
export const MCQ_SETUP_UPDATE_ACTION_TYPE = "MCQ_SETUP_UPDATE_ACTION_TYPE"
export const MCQ_SETUP_DELETE_ACTION_TYPE = "MCQ_SETUP_DELETE_ACTION_TYPE"


// setting
export const SETTING_LIST_ACTION_TYPE = "MCQ_SETUP_LIST_ACTION_TYPE"
export const TEST_DETAILS_ACTION_TYPE = "TEST_DETAILS_ACTION_TYPE"
export const TEST_ADD_ACTION_TYPE = "TEST_ADD_ACTION_TYPE"
export const TEST_UPDATE_ACTION_TYPE = "TEST_UPDATE_ACTION_TYPE"
export const TEST_DELETE_ACTION_TYPE = "TEST_DELETE_ACTION_TYPE"

// test
export const TEST_LIST_ACTION_TYPE = "TEST_LIST_ACTION_TYPE"
export const SETTING_DETAILS_ACTION_TYPE = "MCQ_SETUP_DETAILS_ACTION_TYPE"
export const SETTING_ADD_ACTION_TYPE = "MCQ_SETUP_ADD_ACTION_TYPE"
export const SETTING_UPDATE_ACTION_TYPE = "MCQ_SETUP_UPDATE_ACTION_TYPE"
export const SETTING_DELETE_ACTION_TYPE = "MCQ_SETUP_DELETE_ACTION_TYPE"

// report
export const LEAVE_VIEW_ACTION_TYPE = "LEAVE_VIEW_ACTION_TYPE"
export const EMP_LEAVE_VIEW_ACTION_TYPE = "EMP_LEAVE_VIEW_ACTION_TYPE"
export const TIME_EMP_VIEW_ACTION_TYPE = "TIME_EMP_VIEW_ACTION_TYPE"
export const TIME_CLIENT_VIEW_ACTION_TYPE = "TIME_CLIENT_VIEW_ACTION_TYPE"
export const EMP_ENTERED_TIMESHEET_VIEW_ACTION_TYPE = "EMP_ENTERED_TIMESHEET_VIEW_ACTION_TYPE"
export const EMP_CLIENT_VIEW_ACTION_TYPE = "EMP_CLIENT_VIEW_ACTION_TYPE"
export const EXPENSE_EMP_VIEW_ACTION_TYPE = "EXPENSE_EMP_VIEW_ACTION_TYPE"
export const EXPENSE_CLIENT_VIEW_ACTION_TYPE = "EXPENSE_CLIENT_VIEW_ACTION_TYPE"
export const TIMESHEET_DETAIL_VIEW_ACTION_TYPE = "TIMESHEET_DETAIL_VIEW_ACTION_TYPE"
export const CLIENT_DETAIL_VIEW_ACTION_TYPE = "CLIENT_DETAIL_VIEW_ACTION_TYPE"
export const CLIENT_ENG_DETAIL_VIEW_ACTION_TYPE = "CLIENT_ENG_DETAIL_VIEW_ACTION_TYPE"


//action type end..........................

// action ID name
export const eqListId = "1";
export const financialYearId = "2";
export const clientsId = "3";
export const engagementPartnerId = "4";
export const qualityReviewerId = "5";
export const engagementTypeId = "6";
export const engagementAllocationStatus = "7";
export const engagementStatus = "8"; // eng status
export const checklisttemplateActionId = "9";
export const finToDateFromLastRecordId = "10";
export const engagementHeadActionId = "11";
export const teamMemberOptionId = "12";
export const checklistnameActionId = "13";
export const locationActionId = "14";
export const clientEngagementChecklistActionId = "15";
export const clientEngagementNewChecklistActionId = "16";
export const clientEngagementActionId = "17";
export const clientEngagementEngagementActionId = "18";
export const clientEngagementFinActionId = "19";
export const clientEngagementTeamMemEngPatQRActionId = "20";
export const updateChecklistPreparedByActionId = "21";
export const updateChecklistReviewedByActionId = "22";
export const clientEngagementNewChecklistForEditActionId = "23";
export const typeOfEngagementActionId = "24";
export const allFinancialYearId = "25";

export const transcationForActionId = "26";
export const transcationTypeActionId = "27";
export const timesheetStatusActionId = "28";
export const employeeActionId = "29";
export const taskActionId = "30";
export const finYearOnbasicClientActionId = "31";
export const engagementTimeSheetActionId = "32";
export const teamDesignationDropdownActionId = "65";

export const documentCategoryActionId = "33";
export const documentTypeActionId = "34";
export const subCategoryActionId = "";
export const subSubCategoryAcctionId = "";
export const expenseTypeActionId = "38";

export const clientGroupActionId = "36"
export const clientTypeActionId = "37"
export const clientNonIndividualActionId = "39"
export const clientIndividualActionId = "40"

export const mainEngagementActionId = "41"
export const companyNameActionId = "53"
export const locationCityActionId = "56"
export const locationStateActionId = "55"
export const locationTypeActionId = "54"

export const subEngagementActionId = "42"
// employee
export const designationActionId = "43"
export const managerId = "44"
export const departmentId = "45"
export const partnerId = "46"
export const genderId = "47"
export const bloodGroupId = "48"
export const maritalStatusId = "49"
export const prefixId = "51"
export const rolePlayedId = "52"

export const parentId = "57"
export const holidayTypeActionId = "58"
export const workingDayTypeActionId = "59"

export const clientCategoryActionId = "35"
export const leaveTypeActionId = '60'

export const TimeSheetClientActionId = '62'
export const reportingListActionId = '64'
export const prefixForActionId = '66'
export const courseTypeActionId = '67'
export const trainingStatusActionId = '68'
export const trainingApprovedByActionId = '69'
export const trainingEmployeeActionId = '70'
export const monthActionId = '71'
export const yearsActionId = '72'
// mcq
export const chapterListActionId = '73'
export const levelActionId = '74'
export const answerkeyActionId = '75'
export const PrefixActionId = '76'
export const moduleAccessListActionId = '77'

export const MstMcqInstructionActionId = '78'
export const typeOfTestActionId = '79'
export const ClientEngengagementTimeSheetActionId = '80'
export const ClientEngfinYearOnbasicClientActionId = '81'

export const MonthActionId = '82'
export const LocationTypeActionId = '83'
export const engagementTimeForApproveSheetActionId = '84'
export const userEngagementRoleActionId = '85'
//  @ActionID = 26 ---TranactionFor
// @ActionID = 27 ----TranactionType
// @ActionID = 29 ------Employee
// @ActionID = 30  --------TaskTypeLS
// : @ActionID = 31  ---------FinYear on basic of Client
// @Reference = E.ClientID
//  @ActionID = 32  ---------------Engagement in TimeSheet
// @Reference = E.ClientID AND @Reference = E.EngagementID

export const appVersion = '1.0.0';
export const buildNumber = '20220511';
export const NA = 'NA';

// RoleAccess ModuleID
export const Dashboard = 1
export const Engagement = 2
export const Engagement_Allocation = 3
export const Client_Engagement = 4
export const Update_Checklist = 5
export const Review_Checklist = 6
export const Close_Engagement = 7
export const Timesheet_and_Expense = 8
export const Timesheet = 9
export const Expense = 10
export const Leave = 11
export const Timesheet_and_Expense_Approval = 12
export const Leave_Approval = 13
export const Training = 14
export const Traning_Entry = 15
export const Training_Approval = 16
export const Training_report = 17
export const Reports = 18
export const MCQ = 19
export const MCQ_Chapter = 20
export const MCQ_Setup = 21
export const MCQ_Setting = 22
export const MCQ_Take_Test = 23
export const Master = 24
export const Change_Password = 25

// RoleAccess ReportID
export const Time_Sheet_Employee_wise_report = 1
export const Time_Sheet_Client_wise_report = 2
export const Employee_and_Client_wise_Summary_Report = 3
export const Employee_wise_expense_report = 4
export const Client_wise_expense_report = 5
export const Time_Sheet_Detailed_Report = 6
export const Client_wise_detailed_Report = 7
export const Client_and_Engagement_wise_detailed_Report = 8
export const Leave_Report = 9
export const Employee_wise_Leave_Report = 10
export const Firm_wise_training_report = 11
export const Employee_wise_training_report = 12
export const UserProfile = 13

export const clearLocalStorage = (pageName) => {
  if (pageName === "clientGroup") {

    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    financialYearLS()
  }
  if (pageName === "bloodGroup") {

    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    financialYearLS()
    clientGroupLS()
  }
  if (pageName === "financialYear") {

    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  if (pageName === "approval") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  if (pageName === "employee") {
    financialYearLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "checklist") {
    financialYearLS()
    employeeLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "checklistTemplate") {
    financialYearLS()
    employeeLS()
    checklistLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "engagementAllocation") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "clientEngagement") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "updateChecklist") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "reviewChecklist") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "closerChecklist") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "timesheet") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "client") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "engagementMaster") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "department") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "designation") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "holiday") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "addressType") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "clientCategory") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "expenseHead") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "engagementRole") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "leave") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "locationType") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "managementType") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "martialStatus") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "approval") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "leaveApproval") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "expense") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "gender") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "legalEntity") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "training") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "chapter") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === "setup") {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === 'engagementRoleName') {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else if (pageName === 'taskType') {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    bloodGroupLS()
    clientGroupLS()
  }
  else {
    financialYearLS()
    employeeLS()
    checklistLS()
    checklistTemplateLS()
    enagagementAllocationLS()
    clientEngagementLS()
    updateChecklistLS()
    reviewChecklistLS()
    closerChecklistLS()
    timesheetLS()
    clientLS()
    engagementMasterLS()
    departmentLS()
    designationLS()
    holidayLS()
    addressTypeLS()
    clientCategoryLS()
    expenseHeadLS()
    engagementRoleLS()
    locationTypeLS()
    managementTypeLS()
    leaveLS()
    martialStatusLS()
    approvalLS()
    leaveApprovalLS()
    expenseLS()
    genderLS()
    legalEntityLS()
    trainingLS()
    setupLS()
    chapterLS()
    taskTypeLS()
    bloodGroupLS()
    clientGroupLS()

  }


}

const setupLS = () => {
  localStorage.setItem("chapterSLS", '')
  localStorage.setItem("levelSLS", '')
  localStorage.setItem("questionSLS", '')
  localStorage.setItem("statusIdSLS", '')
}

const chapterLS = () => {
  localStorage.setItem("chapterMLS", '')
  localStorage.setItem("statusIdMLS", '')
}
const trainingLS = () => {
  localStorage.setItem("trainingStartDateTLS", '')
  localStorage.setItem("trainingEndDateTLS", '')
  localStorage.setItem("courseTypeTLS", '')
  localStorage.setItem("courseDetailsTLS", '')
  localStorage.setItem("employeeTLS", '')
  localStorage.setItem("statusIdTLS", '')
  localStorage.setItem("approvedByTLS", '')
  localStorage.setItem("approvedDateTLS", '')
}

const genderLS = () => {
  localStorage.setItem("genderGLS", '')
  localStorage.setItem("statusIdGLS", '')
}

const legalEntityLS = () => {
  localStorage.setItem("legalEntityLELS", '')
  localStorage.setItem("formatLELS", '')
  localStorage.setItem("statusIdLELS", '')
}
// const leaveApprovalLS = () => {
//   localStorage.setItem("finYearNameFLS", '')
//   localStorage.setItem("shortNameFLS", '')
//   localStorage.setItem("statusFLS", 'Select')
// }

const leaveApprovalLS = () => {
  localStorage.setItem("employeeIdLALS", '')
  localStorage.setItem("fromDateLALS", '')
  localStorage.setItem("toDateLALS", '')
  localStorage.setItem("statusIdLALS", '')
}

const financialYearLS = () => {
  localStorage.setItem("finYearNameFLS", '')
  localStorage.setItem("shortNameFLS", '')
  localStorage.setItem("statusFLS", '')
}

const employeeLS = () => {
  localStorage.setItem("codeELS", '')
  localStorage.setItem("employeeNameELS", '')
  localStorage.setItem("genderELS", '')
  localStorage.setItem("designationELS", '')
  localStorage.setItem("departmentELS", '')
  localStorage.setItem("managerELS", '')
  localStorage.setItem("partnerELS", '')
  localStorage.setItem("fromELS", '')
  localStorage.setItem("toELS", '')
  localStorage.setItem("emailELS", '')
  localStorage.setItem("statusELS", '')
}

const approvalLS = () => {
  localStorage.setItem('clientsNameIdTS', '')
  localStorage.setItem('financialYearIdTS', '')
  localStorage.setItem('employeeIdTS', '')
  localStorage.setItem('engagementIdTS', '')
  localStorage.setItem('transactionForIdTS', '')
  localStorage.setItem('statusIdTS', '')
}

const checklistLS = () => {
  localStorage.setItem("checklistNameCLS", '')
  localStorage.setItem("shortNameCLS", '')
  localStorage.setItem("statusCLS", '')
  localStorage.setItem("eqCLS", '')
}

const checklistTemplateLS = () => {
  localStorage.setItem("templateNameCTLS", '')
  localStorage.setItem("statusCTLS", '')
}

const enagagementAllocationLS = () => {
  localStorage.setItem("clientNameEALS", '')
  localStorage.setItem("engPartnerNameEALS", '')
  localStorage.setItem("qualityReviewerNameEALS", '')
  localStorage.setItem("statusEALS", '')
  localStorage.setItem("engagementTypeNameEALS", '')
}

const clientEngagementLS = () => {
  localStorage.setItem("clientNameCALS", '')
  localStorage.setItem("finYearNameCALS", '')
  localStorage.setItem("statusCALS", '')
}

const updateChecklistLS = () => {
  localStorage.setItem("clientNameUCLS", '')
  localStorage.setItem("finYearNameUCLS", '')
  localStorage.setItem("statusUCLS", '')
}

const reviewChecklistLS = () => {
  localStorage.setItem("clientNameRCLS", '')
  localStorage.setItem("finYearNameRCLS", '')
  localStorage.setItem("statusRCLS", '')
}

const closerChecklistLS = () => {
  localStorage.setItem("clientNameCCLS", '')
  localStorage.setItem("finYearNameCCLS", '')
  localStorage.setItem("statusCCLS", '')
}


const timesheetLS = () => {
  localStorage.setItem("clientsNameIdTSLS", '')
  localStorage.setItem("financialYearIdTSLS", '')
  localStorage.setItem("engagementIdTSLS", '')
  localStorage.setItem("toDateTSLS", '')
  localStorage.setItem("fromDateTSLS", '')
  localStorage.setItem("statusIdTSLS", '')
}

const expenseLS = () => {
  localStorage.setItem("clientsNameIdESLS", '')
  localStorage.setItem("financialYearIdESLS", '')
  localStorage.setItem("engagementIdESLS", '')
  localStorage.setItem("toDateESLS", '')
  localStorage.setItem("fromDateESLS", '')
  localStorage.setItem("statusIdESLS", '')
}

const clientLS = () => {
  localStorage.setItem("clientNameCLS", '')
  localStorage.setItem("fileNoCLS", '')
  localStorage.setItem("clientGroupCLS", '')
  localStorage.setItem("clientCategoryCLS", '')
  localStorage.setItem("statusCLLS", '')
}

const engagementMasterLS = () => {
  localStorage.setItem("engagementMasterNameEMLS", '')
  localStorage.setItem("statusEMLS", '')
}

const departmentLS = () => {
  localStorage.setItem('departmentNameDLS', "")
  localStorage.setItem('statusDLS', "")
}

const designationLS = () => {
  localStorage.setItem('designationDLS', '')
  localStorage.setItem('reportingDLS', '')
  localStorage.setItem('statusDLS', "")
}

const holidayLS = () => {
  localStorage.setItem("holidayDateHLS", '')
  localStorage.setItem("holidayNameHLS", '')
  localStorage.setItem("holidayTypeHLS", '')
  localStorage.setItem('statusHLS', "")
}

const addressTypeLS = () => {
  localStorage.setItem("addressTypeNameATLS", '')
  localStorage.setItem('statusATLS', "")
}

const clientCategoryLS = () => {
  localStorage.setItem("clientCategoryNameCGLS", '')
  localStorage.setItem("managementDescCGLS", '')
  localStorage.setItem('statusCGLS', "")
}

const leaveLS = () => {
  localStorage.setItem("fromDateLLS", '')
  localStorage.setItem("toDateLLS", '')
  localStorage.setItem('statusIdLLS', "")
}

const expenseHeadLS = () => {
  localStorage.setItem("expenseHeadNameEHLS", '')
  localStorage.setItem('statusEHLS', "")
}
const engagementRoleLS = () => {
  localStorage.setItem("engagementRoleNameERLS", '')
  localStorage.setItem('reportingIdERLS', "")
  localStorage.setItem("MaxCountERLS", '')
  localStorage.setItem("orderERLS", '')
  localStorage.setItem('statusERLS', "")
}

const bloodGroupLS = () => {
  localStorage.setItem('bloodGroupBLS', '')
  localStorage.setItem('statusBLS', "")
}

const clientGroupLS = () => {
  localStorage.setItem('clientGroupCGLS', '')
  localStorage.setItem('statusCGLS', '')
}

const taskTypeLS = () => {
  localStorage.setItem('taskTypeNameTLS', '')
  localStorage.setItem('statusTLS', '')
}



const martialStatusLS = () => {
  localStorage.setItem('statusMSLS', '')
}
const locationTypeLS = () => {
  localStorage.setItem('locationTypeLLS', '')
  localStorage.setItem('statusLLS', '')
}

const managementTypeLS = () => {
  localStorage.setItem("managementTypeMLS", '')
  localStorage.setItem("clientCategoryIdMLS", '')
  localStorage.setItem('statusMLS', "")
}

export function timeout(delay) {
  return new Promise(res => setTimeout(res, delay));
}