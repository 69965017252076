import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { fill_action } from '../../../actions/master_action/checklist_action'
import {
    timesheet_action,
    deleteTimesheet_action,
    singleLastTimesheetDate_action
} from '../../../actions/time_sheet_expense_action/time_sheet_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Modal from "react-modal";
import { engagementTypeId, clearLocalStorage, downloadReport, clientsId, financialYearId, timesheetStatusActionId, Timesheet } from '../../../utils/constants'
import Pagination from '../../../utils/pagination'
import Loader from '../../../utils/Loader'
import moment from 'moment';
import Workbook from 'react-excel-workbook'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';
/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
    // loginAction: () => dispatch(loginAction())
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));


class TimeSheetList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            engagementAllocationName: '',
            clientName: '',
            engPartnerName: '',
            qualityReviewerName: '',
            engagementTypeName: '',
            timesheetList: [],
            engagementTypeNameList: [],
            eq: '',
            status: '',
            isOpen: false,
            timesheetListDeleteId: '',
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage: 10,
            pageSizeInput: "10",
            timeSheetExpenseID: '',

            isClientNameSort: false,
            isEngPartnerNameSort: false,
            isEngagementTypeNameSort: false,
            isQualityReviewerNameSort: false,
            isFromDateSort: false,
            isToDateSort: false,
            isStatusSort: false,

            toDate: '',
            fromDate: '',
            clientsNameList: [],
            clientsNameId: '',
            financialYearId: '',
            financialYearList: [],
            engagementId: '',
            statusId: '',
            statusList: [],

            // local storage
            clientsNameIdTSLS: '',
            financialYearIdTSLS: '',
            engagementIdTSLS: '',
            toDateTSLS: '',
            fromDateTSLS: '',
            statusIdTSLS: '',

            singleLastTimesheetDate: '',
            isSearchTab: false,
        }
        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleDelete = this.handleDelete.bind(this)

        this.renderTemplate = this.renderTemplate.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.getTimesheetList = this.getTimesheetList.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);

        this.record = this.record.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.clientNameSort = this.clientNameSort.bind(this)
        this.engPartnerNameSort = this.engPartnerNameSort.bind(this)
        this.engagementTypeNameSort = this.engagementTypeNameSort.bind(this)
        this.qualityReviewerNameSort = this.qualityReviewerNameSort.bind(this)
        this.fromDateSort = this.fromDateSort.bind(this)
        this.toDateSort = this.toDateSort.bind(this)
        this.statusSort = this.statusSort.bind(this)
        this.expenseAdd = this.expenseAdd.bind(this)
    }

    componentDidMount = () => {
        this.getFillList(engagementTypeId, '')
        this.getFillList(clientsId, '')
        this.getFillList(financialYearId, '')
        this.getFillList(timesheetStatusActionId, '')

        this.setState({
            clientsNameIdTSLS: localStorage.getItem('clientsNameIdTSLS'),
            financialYearIdTSLS: localStorage.getItem('financialYearIdTSLS'),
            engagementIdTSLS: localStorage.getItem('engagementIdTSLS'),
            toDateTSLS: localStorage.getItem('toDateTSLS'),
            fromDateTSLS: localStorage.getItem('fromDateTSLS'),
            statusIdTSLS: localStorage.getItem('statusIdTSLS'),


            clientsNameId: localStorage.getItem('clientsNameIdTSLS') != '' ? localStorage.getItem('clientsNameIdTSLS') : '',
            financialYearId: localStorage.getItem('financialYearIdTSLS') != '' ? localStorage.getItem('financialYearIdTSLS') : '',
            engagementId: localStorage.getItem('engagementIdTSLS') != '' ? localStorage.getItem('engagementIdTSLS') : '',
            toDate: localStorage.getItem('toDateTSLS') != '' ? moment(localStorage.getItem('toDateTSLS')).toDate() : '',
            fromDate: localStorage.getItem('fromDateTSLS') != '' ? moment(localStorage.getItem('fromDateTSLS')).toDate() : '',
            statusId: localStorage.getItem('statusIdTSLS') != '' ? localStorage.getItem('statusIdTSLS') : '',

        })

        console.log("localStorage.getItem('fromDateTSLS')", localStorage.getItem('fromDateTSLS'))

        setTimeout(() => {
            clearLocalStorage("timesheet")
            this.getTimesheetList()
            this.singleLastTimesheetDate()

        }, 300);
        // document.addEventListener('click', this.handleInputClick);

        // document.addEventListener('keydown', this.handleTabKeyPress);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }



    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };


    expenseAdd(data) {

        localStorage.setItem('clientNameIdTimeSS', data['ClientID'])
        localStorage.setItem('transactionForIdTimeSS', data['TransactionForID'])
        localStorage.setItem('finYearIdTimeSS', data['FinYearID'])
        localStorage.setItem('engagementIdTimeSS', data['EngagementID'])
        localStorage.setItem('dateTimeSS', moment(data['TimeSheetDate'], 'YYYY-MM-DD').toDate())

        // console.log("******", data['TimeSheetID'])

        this.props.navigate('/expenseU', { state: { is_add: true, timeSheetExpenseID: data['TimeSheetID'] } })
    }

    statusSort() {
        const { checkList, isStatusSort } = this.state
        this.setState({ isStatusSort: !isStatusSort })
        if (!isStatusSort) {
            // sorting
            var statusSort = checkList.sort((a, b) => {
                const nameA = a.isActive; // ignore upper and lowercase
                const nameB = b.isActive; // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            this.setState({ checkList: statusSort })
        }
        else {
            // reverse
            var statusSort = checkList.sort((a, b) => {
                const nameA = a.isActive; // ignore upper and lowercase
                const nameB = b.isActive; // ignore upper and lowercase
                if (nameA < nameB) {
                    return 1;
                }
                if (nameA > nameB) {
                    return -1;
                }
                return 0;
            });
            this.setState({ checkList: statusSort })
        }
        this.setState({ isStatusSort: !isStatusSort })
    }

    toDateSort() {
        const { timesheetList, isToDateSort } = this.state
        this.setState({ isToDateSort: !isToDateSort })
        if (!isToDateSort) {
            // sorting
            var toDateSort = timesheetList.sort((a, b) => {
                const nameA = a.EndDate.toUpperCase(); // ignore upper and lowercase
                const nameB = b.EndDate.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            this.setState({ timesheetList: toDateSort })
        }
        else {
            // reverse
            var toDateSort = timesheetList.sort((a, b) => {
                const nameA = a.EndDate.toUpperCase(); // ignore upper and lowercase
                const nameB = b.EndDate.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return 1;
                }
                if (nameA > nameB) {
                    return -1;
                }
                return 0;
            });
            this.setState({ timesheetList: toDateSort })
        }
        this.setState({ isToDateSort: !isToDateSort })
    }

    fromDateSort() {
        const { timesheetList, isFromDateSort } = this.state
        this.setState({ isFromDateSort: !isFromDateSort })
        if (!isFromDateSort) {
            // sorting
            var totalCheckSort = timesheetList.sort((a, b) => {
                const nameA = a.StartDate.toUpperCase(); // ignore upper and lowercase
                const nameB = b.StartDate.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            this.setState({ timesheetList: totalCheckSort })
        }
        else {
            // reverse
            var totalCheckSort = timesheetList.sort((a, b) => {
                const nameA = a.StartDate.toUpperCase(); // ignore upper and lowercase
                const nameB = b.StartDate.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return 1;
                }
                if (nameA > nameB) {
                    return -1;
                }
                return 0;
            });
            this.setState({ timesheetList: totalCheckSort })
        }
        this.setState({ isFromDateSort: !isFromDateSort })
    }

    qualityReviewerNameSort() {
        const { timesheetList, isQualityReviewerNameSort } = this.state
        this.setState({ isQualityReviewerNameSort: !isQualityReviewerNameSort })
        if (!isQualityReviewerNameSort) {
            // sorting
            var timesheetListNameSort = timesheetList.sort((a, b) => {
                const nameA = a.QualityReviewerName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.QualityReviewerName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            this.setState({ timesheetList: timesheetListNameSort })
        }
        else {
            // reverse
            var timesheetListNameSort = timesheetList.sort((a, b) => {
                const nameA = a.QualityReviewerName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.QualityReviewerName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return 1;
                }
                if (nameA > nameB) {
                    return -1;
                }
                return 0;
            });
            this.setState({ timesheetList: timesheetListNameSort })
        }
        this.setState({ isQualityReviewerNameSort: !isQualityReviewerNameSort })
    }

    engagementTypeNameSort() {
        const { timesheetList, isEngagementTypeNameSort } = this.state
        this.setState({ isEngagementTypeNameSort: !isEngagementTypeNameSort })
        if (!isEngagementTypeNameSort) {
            // sorting
            var engagementTypeNameSort = timesheetList.sort((a, b) => {
                const nameA = a.EngagementTypeName.toUpperCase();  // ignore upper and lowercase
                const nameB = b.EngagementTypeName.toUpperCase();  // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            this.setState({ timesheetList: engagementTypeNameSort })
        }
        else {
            // reverse
            var engagementTypeNameSort = timesheetList.sort((a, b) => {
                const nameA = a.EngagementTypeName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.EngagementTypeName.toUpperCase();  // ignore upper and lowercase
                if (nameA < nameB) {
                    return 1;
                }
                if (nameA > nameB) {
                    return -1;
                }
                return 0;
            });
            this.setState({ timesheetList: engagementTypeNameSort })
        }
        this.setState({ isEngagementTypeNameSort: !isEngagementTypeNameSort })
    }

    engPartnerNameSort() {
        const { timesheetList, isEngPartnerNameSort } = this.state
        this.setState({ isEngPartnerNameSort: !isEngPartnerNameSort })
        if (!isEngPartnerNameSort) {
            // sorting
            var engPartnerNameSort = timesheetList.sort((a, b) => {
                const nameA = a.EngPartnerName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.EngPartnerName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            this.setState({ timesheetList: engPartnerNameSort })
        }
        else {
            // reverse
            var engPartnerNameSort = timesheetList.sort((a, b) => {
                const nameA = a.EngPartnerName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.EngPartnerName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return 1;
                }
                if (nameA > nameB) {
                    return -1;
                }
                return 0;
            });
            this.setState({ timesheetList: engPartnerNameSort })
        }
        this.setState({ isEngPartnerNameSort: !isEngPartnerNameSort })
    }

    clientNameSort() {
        const { timesheetList, isClientNameSort } = this.state
        this.setState({ isClientNameSort: !isClientNameSort })
        if (!isClientNameSort) {
            // sorting
            var clientNameSort = timesheetList.sort((a, b) => {
                const nameA = a.ClientName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.ClientName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            this.setState({ timesheetList: clientNameSort })
        }
        else {
            // reverse
            var clientNameSort = timesheetList.sort((a, b) => {
                const nameA = a.ClientName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.ClientName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return 1;
                }
                if (nameA > nameB) {
                    return -1;
                }
                return 0;
            });
            this.setState({ timesheetList: clientNameSort })
        }
        this.setState({ isClientNameSort: !isClientNameSort })
    }

    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.getTimesheetList()
        }
    }

    clearValue() {
        this.setState({
            clientsNameId: '',
            financialYearId: '',
            engagementId: '',
            statusId: '',
            fromDate: '',
            toDate: '',
            timesheetList: [],

            currentPage: 1,
            // local storage
            clientsNameIdTSLS: '',
            financialYearIdTSLS: '',
            engagementIdTSLS: '',
            toDateTSLS: '',
            fromDateTSLS: '',
            statusIdTSLS: '',

        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getTimesheetList()
        }, 300);

    }


    // get fill list 
    getFillList(value, ref) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value, ref)
            .then((data) => {
                if (data.error != 1) {
                    if (value == engagementTypeId) {
                        this.setState({ engagementTypeNameList: data.data, isLoading: false })
                        console.log('engagementTypeNameList ', data.data)
                    }
                    if (value == clientsId) {
                        this.setState({ clientsNameList: data.data, isLoading: false })
                    }
                    if (value == financialYearId) {
                        this.setState({ financialYearList: data.data, isLoading: false })
                    }
                    if (value == timesheetStatusActionId) {
                        console.log('timesheetStatusActionId ', data.data)
                        this.setState({ statusList: data.data, isLoading: false })
                    }
                }
                else {
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    // get engagement allocation list
    getTimesheetList() {
        const { clientsNameId, financialYearId, engagementId, fromDate, toDate, statusId, } = this.state
        this.setState({ isLoading: true })
        clearLocalStorage("timesheet")
        console.log(" **********----------------------sagar", engagementId)

        this.props
            .timesheet_action(
                clientsNameId != '' && clientsNameId != '' ? clientsNameId : null,
                financialYearId != '' && financialYearId != '' ? financialYearId : null,
                engagementId != '' && engagementId != '' ? engagementId : null,
                fromDate, toDate,
                statusId != '' && statusId != '' ? statusId : null,
            )
            .then((data) => {
                if (data.error != 1) {

                    this.setState({ timesheetList: data.data, currentPage: 1 })

                    if (data.data == '') {

                        this.setState({ checkBoxFlag: false })
                    }
                    setTimeout(() => {
                        this.setState({ isLoading: false })
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })

                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

        localStorage.setItem("clientsNameIdTSLS", clientsNameId)
        localStorage.setItem("financialYearIdTSLS", financialYearId)
        localStorage.setItem("engagementIdTSLS", engagementId)
        localStorage.setItem("toDateTSLS", toDate)
        localStorage.setItem("fromDateTSLS", fromDate)
        localStorage.setItem("statusIdTSLS", statusId)
    }


    // add list 
    handleAdd() {
        this.props.navigate('/timeSheetU', { state: { is_add: true } })
    }

    // handleDownload(e) {
    //     e.preventDefault()
    //     const { engagementTypeNameList, status, clientName, engPartnerName, qualityReviewerName, engagementTypeName, clientNameEALS, engPartnerNameEALS, qualityReviewerNameEALS } = this.state
    //     this.setState({ isLoading: true })
    //     this.props
    //         .engagementAllocationDownload_action(clientName, engPartnerName, qualityReviewerName, engagementTypeName === 'Select' ? '' : engagementTypeName, status === 'Select' ? 1 : status)
    //         .then((data) => {
    //             if (data != null) {
    //                 this.setState({ isLoading: false })
    //                 console.log('Report data : ', data);
    //                 var url = `${downloadReport}?SessionIdentifier=${data}&Export=Yes`;
    //                 console.log('Report download url : ', url);
    //                 const win = window.location.replace(url, '_blank');
    //                 if (win != null) {
    //                     win.focus();
    //                 }
    //             } else {
    //                 this.setState({ isLoading: false })
    //                 toast.error('There is some problem to download report. Please try again later!', {
    //                     theme: 'colored',
    //                     autoClose: 3000,
    //                     hideProgressBar: true,
    //                 })
    //             }
    //         })
    //         .catch((e) => {
    //             console.log(e);
    //             this.setState({ isLoading: false })
    //         });
    // }

    // update list
    handleUpdate(timesheetListId) {
        this.props.navigate('/timeSheetU', { state: { is_update: true, timesheetListId: timesheetListId } })
    }

    // delete list
    handleDelete(e, timesheetListId) {
        e.preventDefault()
        // this.props.navigate('/timesheetListAU', { state: { is_delete: true, timesheetListId: timesheetListId } })
        var id = timesheetListId
        this.setState({ isLoading: true })
        this.props
            .deleteTimesheet_action(id)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isOpen: !this.state.isOpen })
                    this.getTimesheetList()
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.singleLastTimesheetDate();
                    this.setState({ isLoading: false })

                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    //toggle model for delete.................................
    toggleModal(timesheetListId) {
        const { isOpen, timesheetListDeleteId } = this.state
        this.setState({ isOpen: !isOpen })
        if (timesheetListId == '') {
            return;
        } else {
            this.setState({ timesheetListDeleteId: timesheetListId })
        }

    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }

    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
            this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
    };

    handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
            this.setState({ pageSizeInput: input });
        }
    };

    singleLastTimesheetDate() {
        this.setState({ isLoading: true })
        this.props
            .singleLastTimesheetDate_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("last singleLastTimesheetDate_action fill89754", data.data[0][0])
                    console.log("last singleLastTimesheetDate_action fill 979797", moment(data.data[0][0]['TimeSheetDate'], 'YYYY-MM-DD'))
                    // moment(data.data[0][0]['TimeSheetDate']).format('DD-MM-YYYY')
                    this.setState({
                        // singleLastTimesheetDate: data.data != '' ? data.data[0]['TimeSheetDate'] : ''

                        singleLastTimesheetDate: data.data != '' ? moment(data.data[0][0]['TimeSheetDate']).format('DD-MM-YYYY') : ''
                        // moment(data.data[0]['TimeSheetDate']).format('DD-MM-YYYY') : '',
                    })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    record(timesheetList) {
        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        <th style={{  }} />
                        <th style={{ textAlign: 'left' }} onClick={this.clientNameSort}>Employee</th>
                        <th style={{ }} onClick={this.engPartnerNameSort}>Trans. For </th>
                        <th style={{ }} onClick={this.qualityReviewerNameSort}>Client Name</th>
                        <th style={{ }} onClick={this.engagementTypeNameSort}>Financial Year</th>
                        <th style={{ }} onClick={this.fromDateSort}>Engagement</th>
                        <th style={{ }} onClick={this.toDateSort}>Exp. Amount</th>
                        <th style={{ }} onClick={this.statusSort}>Task Type</th>

                        {/* //// */}
                        <th style={{ width:'120%'}} onClick={this.fromDateSort}>Desc.</th>
                        <th style={{ }} onClick={this.toDateSort}>Date.</th>
                        <th style={{ }} onClick={this.statusSort}>Trans.Type</th>
                        <th style={{ }} onClick={this.fromDateSort}>Time</th>
                        <th style={{ }} onClick={this.toDateSort}>Status</th>
                        {/* <th style={{ width: '250px' }} onClick={this.toDateSort}>Expense Add</th> */}
                    </tr>
                </thead>
                <tbody>


                    {
                        timesheetList.length > 0 ?
                            timesheetList.map((data, index) => (
                                <>
                                    <tr key={Math.random()}>

                                        <td className="text-center" style={{ padding: '5px!important' }}>
                                            <div className="table__button-group">

                                                {/* <a> <i className="fa fa-pencil" style={{ color: '#aac722' }}
                                                    onClick={() => this.handleUpdate(data.TimeSheetID)} /></a> */}

                                                {
                                                    data.TimeSheetStatus == 'Approved' ?
                                                        <a> <span ><i class="fa fa-eye" style={{ color: '#3498db' }}
                                                            onClick={() => this.handleUpdate(data.TimeSheetID)} /></span></a>
                                                        :
                                                        <>
                                                            {getModuleUpdateAccess(Timesheet) ? <a> <i className="fa fa-pencil" style={{ color: '#aac722' }}
                                                                onClick={() => this.handleUpdate(data.TimeSheetID)} /></a> :
                                                                <a> <i className="fa fa-eye" style={{ color: '#3498db' }}
                                                                    onClick={() => this.handleUpdate(data.TimeSheetID)} /></a>
                                                            }
                                                            {data.TimeSheetStatus != 'Deleted' && getModuleDeleteAccess(Timesheet) ? <a> <span className="text-danger"><i className="fa fa-trash-o"
                                                                onClick={() => this.toggleModal(data.TimeSheetID)} /></span></a>
                                                                : <></>}
                                                        </>
                                                }



                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'left' }}>{data.EmployeeName != '' ? data.EmployeeName : '-'}</td>
                                        <td>{data.TransactionFor != '' ? data.TransactionFor : '-'}</td>
                                        <td>{data.ClientName != '' ? data.ClientName : '-'}</td>
                                        <td>{data.FinancialYear != '' ? data.FinancialYear : '-'}</td>
                                        <td>{data.Engagement != '' ? data.Engagement : '-'}</td>
                                        <td>{data.ExpenseAmount != '' ? data.ExpenseAmount : '-'}</td>
                                        <td>{data.TaskType != '' && data.TaskType != null ? data.TaskType : '-'}</td>
                                        <td style={{width: '450px', whiteSpace: 'normal' }}>{data.Description != '' ? data.Description : '-'}</td>
                                        <td>{data.TimeSheetDate != null && moment(data.TimeSheetDate).format('DD-MM-YYYY')}</td>
                                        <td>{data.TransactionType != '' ? data.TransactionType : '-'}</td>
                                        <td>{data.StartTime != '' ? data.StartTime : '-'} to {data.Endtime != '' ? data.Endtime : '-'}</td>
                                        <td>{data.TimeSheetStatus != '' ? data.TimeSheetStatus : '-'}</td>
                                        {/* {data.Flag != 1 ?
                                            <td onClick={() => this.expenseAdd(data)}> Add</td>
                                            : <td > -</td>
                                        } */}
                                    </tr></>
                            )) : <tr><td colSpan={13} className='text-center'>Record not found</td></tr>
                    }

                </tbody>
            </table>
        )
    }

    renderTemplate() {
        const { timesheetList } = this.state
        console.log('list is : ', timesheetList);
        var list = [...timesheetList]
        var columnarray = ["SR No", "Client Name", "Engagement Partner", "Quality Reviewer", "Engagement Type", "From Date",
            "To Date", "Status"];

        var array = list.map((data, index) => {
            return {
                'SR No': index + 1,
                'Client Name': data.ClientName,
                'Engagement Partner': data.EngPartnerName ?? 0,
                'Quality Reviewer': data.QualityReviewerName ?? 0,
                'Engagement Type': data.EngagementTypeName ?? 0,
                'From Date': data.StartDate ?? 0,
                'To Date': data.EndDate ?? 0,
                'Status': data.EngagementAllocationStatus ?? 0,
            };

        })
        return (
            <div className="row text-center">
                <Workbook filename="Engagement_Allocation.xlsx" style={{ marginleft: '-45px' }} element={
                    <button type="button" className="btn btn-noti ddd" style={{ padding: '8px 20px 5px', marginleft: '-45px' }}>
                        Export to Excel</button>

                }>
                    <Workbook.Sheet data={array} name="Sheet A">
                        {columnarray && columnarray.map((value, index) =>
                            <Workbook.Column label={value} value={value} />
                        )}
                    </Workbook.Sheet>
                </Workbook>
            </div>

        );
    }


    render() {
        const { isSearchTab, isAccessToView, isLoading, timesheetList, engagementTypeName, engagementTypeNameList,
            timesheetListDeleteId, isOpen,
            statusList, engPartnerName, qualityReviewerName, engagementTypeId, status, toDate, fromDate, clientsNameList, clientsNameId,
            financialYearId, financialYearList, engagementId, singleLastTimesheetDate
        } = this.state

        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = timesheetList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(timesheetList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {/* {isAccessToView ? ( */}
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul className="breadcrumb">

                                        <li><a href="#">Time Sheet List</a></li>
                                        <li />
                                    </ul>
                                </div>
                                <div className='page-space-from-bottom'>
                                    <div className="row m-0">
                                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                        <div className="col-md-12 pg-body grid-margin stretch-card p-0">
                                            <div className="card">
                                                <div className="card-body pr-2">
                                                    <div className="accordion accordion-flush " id="accordionFlushExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="flush-headingOne">
                                                                <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">                                                                <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                </button>
                                                            </h2>
                                                            {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                            <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                <div className="d-flex flex-wrap row">
                                                                    <div className="form-group col-md-3">
                                                                        <label>Client Name </label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }} value={clientsNameId} onChange={e => this.setState({ clientsNameId: e.target.value })}>
                                                                            <option value={''}>Select</option>
                                                                            {clientsNameList.length > 0 && clientsNameList.map(item => (
                                                                                <option
                                                                                    key={item.ClientID}
                                                                                    value={item.ClientID}>
                                                                                    {item.ClientFName}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-md-2">
                                                                        <label>Financial Year</label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }} value={financialYearId} onChange={e => this.setState({ financialYearId: e.target.value })}>
                                                                            <option value={''}>Select</option>
                                                                            {financialYearList.length > 0 && financialYearList.map(item => (
                                                                                <option
                                                                                    key={item.FinYearID}
                                                                                    value={item.FinYearID}>
                                                                                    {item.FinancialYear}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label>Engagement </label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }} value={engagementId} onChange={e => this.setState({ engagementId: e.target.value })}>
                                                                            <option value={''}>Select</option>
                                                                            {engagementTypeNameList.length > 0 && engagementTypeNameList.map(item => (
                                                                                <option
                                                                                    key={item.EngagementMasterID}
                                                                                    value={item.EngagementMasterID}>
                                                                                    {item.EngagementType}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                    </div>
                                                                    <div className='form-group col-md-4'>
                                                                        <div className='row'>
                                                                            <div className="col-6">
                                                                                <label>
                                                                                    From Date<span className="required1">*</span>
                                                                                </label>
                                                                                <div className="form-group d-flex">
                                                                                    <DatePicker className="form-control"
                                                                                        dateFormat="dd-MM-yyyy"
                                                                                        selected={this.state.fromDate}
                                                                                        // minDate={this.state.finTodateLstRcd != '' ? this.state.finTodateLstRcd : ''}
                                                                                        // disabled={is_add ? (this.state.finTodateLstRcd != '' ? true : false) : is_update}
                                                                                        onChange={(date) => {
                                                                                            this.setState({ fromDate: date });

                                                                                        }}
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        placeholderText="From Date"
                                                                                        customInput={<ExampleCustomInput />}
                                                                                        dayClassName={() => "example-datepicker-day-class"}
                                                                                        popperClassName="example-datepicker-class"
                                                                                    />
                                                                                </div>
                                                                                <div></div>
                                                                            </div>

                                                                            <div className="col-6">
                                                                                <label>
                                                                                    To Date<span className="required1">*</span>
                                                                                </label>
                                                                                <div className="input-group">
                                                                                    <div className='daterangecss'>
                                                                                        <div className="form-group d-flex">
                                                                                            <DatePicker className="form-control"
                                                                                                dateFormat="dd-MM-yyyy"
                                                                                                selected={this.state.toDate}
                                                                                                minDate={this.state.fromDate}
                                                                                                peekNextMonth
                                                                                                showMonthDropdown
                                                                                                showYearDropdown
                                                                                                dropdownMode="select"
                                                                                                // disabled={is_add ? false : finYearId == lastRecord ? false : true}
                                                                                                onChange={(date) => this.setState({ toDate: date })}
                                                                                                placeholderText="To Date"
                                                                                                customInput={<ExampleCustomInput />}
                                                                                                dayClassName={() => "example-datepicker-day-class"}
                                                                                                popperClassName="example-datepicker-class"
                                                                                            />


                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label>Status</label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }}
                                                                            value={this.state.statusId}
                                                                            onChange={(e) => this.setState({ statusId: e.target.value })}>
                                                                            <option value={''}>Select</option>
                                                                            {statusList.length > 0 && statusList.map(item => (
                                                                                <option
                                                                                    key={item.TimeSheetStatusID}
                                                                                    value={item.TimeSheetStatusID}>
                                                                                    {item.Name}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-md-3 mt-4 text-left" style={{ textAlign: 'left' }}>
                                                                        <button type="button" className="btn btn-Add rounded-1" onClick={() => this.getTimesheetList()}> Search</button>
                                                                        <button type="button" className="btn btn-clear rounded-1 mx-1" onClick={this.clearValue}> Clear</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div> : <></>}
                                                        </div>
                                                    </div>
                                                    <div className="row m-0">
                                                        <div className="col-12 p-2">
                                                            {/* {
                                                                localStorage.getItem('UserId') == '1' ? <> */}
                                                            {/* <button type="button" class="btn btn-print-audit rounded-0 m-1 mb-2"
                                    style={{ lineHeight: '14px', padding: '7px', float: 'right' }}>Print</button> */}
                                                            {/* <div className="col-md-12 form-group ">
                                                                        <div className="mt-1">
                                                                            <button type="button" className="btn btn-email rounded-0 m-1"
                                                                                style={{ lineHeight: '14px', padding: '7px', float: 'right' }} onClick={e => this.handleDownload(e)}>PDF</button>
                                                                            <a className="btn btn-excel rounded-0 m-1" style={{ lineHeight: '10px', float: 'right' }}>{this.renderTemplate()}</a>
                                                                        </div>
                                                                    </div> */}

                                                            <div className='d-flex w-100' style={{ justifyContent: 'space-between' }}>
                                                                <div className="form-group col-md-3  mb-2">
                                                                    <label className='date-text'>Last timesheet fill date: <span className='date-output'>{singleLastTimesheetDate}</span></label>
                                                                </div>
                                                                {getModuleWriteAccess(Timesheet) && <div>
                                                                    <button type="button" className="btn btn-Add rounded-1 m-1"
                                                                        onClick={this.handleAdd}> Add</button>
                                                                </div>}
                                                            </div>

                                                            {/* </>
                                                                    : <></>} */}

                                                            <div className="table-responsive tbs4 common-table trail-tb " style={{ width: '100%' }}>
                                                                {this.record(currentRecords)}
                                                                {timesheetList.length > 0 ? <Pagination
                                                                    nPages={nPages}
                                                                    currentPage={currentPage}
                                                                    setCurrentPage={this.updateItem}
                                                                    recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                    setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                    totalRecords={timesheetList.length}
                                                                /> : <></>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)} */}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>
                <ToastContainer />
                {/* // modal for delete */}
                {/* <Modal
                    isOpen={isOpen}
                    onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot" >
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, timesheetListDeleteId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn  del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}

                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right">
                                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, timesheetListDeleteId)}> Yes</a>
                                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(connect(null, {
    timesheet_action,
    deleteTimesheet_action,
    fill_action,
    singleLastTimesheetDate_action
})(TimeSheetList));