
import { EMPLOYEE_LIST_ACTION_TYPE, EMPLOYEE_LIST_ADD_ACTION_TYPE, EMPLOYEE_LIST_UPDATE_ACTION_TYPE, EMPLOYEE_LIST_DELETE_ACTION_TYPE, EMPLOYEE_LIST_DETAILS_ACTION_TYPE, EMPLOYEE_CONTACT_ADD_ACTION_TYPE, EMPLOYEE_CONTACT_UPDATE_ACTION_TYPE, EMPLOYEE_CONTACT_DETAILS_ACTION_TYPE, EMPLOYEE_CONTACT_LIST_ACTION_TYPE, ROLEPLAYED_EMPLOYEE_DELETE_ACTION_TYPE, EMPLOYEE_CONTACT_DELETE_ACTION_TYPE} from '../../utils/constants'

const initialState = []

function employeeReducer(state = initialState, action){
    const{ type, payload} = action

    switch (type) {
        case EMPLOYEE_LIST_ACTION_TYPE:
          return [...state, payload];
        case EMPLOYEE_LIST_ADD_ACTION_TYPE:
          return [...state, payload];
        case EMPLOYEE_LIST_UPDATE_ACTION_TYPE:
          return [...state, payload];
        case EMPLOYEE_LIST_DELETE_ACTION_TYPE:
          return [...state, payload];
        case EMPLOYEE_LIST_DETAILS_ACTION_TYPE:
          return [...state, payload];
          
        case ROLEPLAYED_EMPLOYEE_DELETE_ACTION_TYPE:
          return [...state, payload];

        case EMPLOYEE_CONTACT_LIST_ACTION_TYPE:
          return [...state, payload];
        case EMPLOYEE_CONTACT_ADD_ACTION_TYPE:
          return [...state, payload];
        case EMPLOYEE_CONTACT_DETAILS_ACTION_TYPE:
          return [...state, payload];
        case EMPLOYEE_CONTACT_UPDATE_ACTION_TYPE:
          return [...state, payload];
        case EMPLOYEE_CONTACT_DELETE_ACTION_TYPE:
          return [...state, payload];
        
        default:
          return state;
      }
}

export default employeeReducer