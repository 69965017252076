import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { fill_action } from '../../../actions/master_action/checklist_action'
import {
    updateChecklistDetails_action, updateUpdateChecklist_action,
    closeUpdateChecklist_action
} from '../../../actions/engagement_action/update_checklist_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Modal from "react-modal";
import { updateChecklistPreparedByActionId, clearLocalStorage, Update_Checklist } from '../../../utils/constants'
import Pagination from '../../../utils/pagination'
import Loader from '../../../utils/Loader'
import moment from 'moment';
import { getModuleUpdateAccess } from '../../../utils/RoleAccess';
/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
    // loginAction: () => dispatch(loginAction())
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

class UpdateChecklistU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            updateChecklistData: [],
            isLoading: false,
            isOpen: false,
            engagementAllocationlistDeleteId: '',
            updateChecklistId: '',
            is_update: false,
            isLoading: false,
            isOpen: false,
            preparedByList: [],
            preparedById: '',
            checklistList: [],
            remark: '',
            isShowMore: false,
            isDetails: false,
        }
        this.handleCancel = this.handleCancel.bind(this)
        this.getUpdateChecklistDetails = this.getUpdateChecklistDetails.bind(this)
        this.handleRemark = this.handleRemark.bind(this)
        this.onSelectCompleted = this.onSelectCompleted.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.onSelectPreparedBy = this.onSelectPreparedBy.bind(this)

    }



    componentDidMount = () => {
        this.setState({
            updateChecklistId: this.props.location.state.updateChecklistId != '' ? this.props.location.state.updateChecklistId : '',

        })

        this.getUpdateChecklistDetails()
        this.getFillList(updateChecklistPreparedByActionId, this.props.location.state.updateChecklistId)
        document.addEventListener('keydown', this.handleTabKeyPress);
        document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleTabKeyPress);
        document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };
    // get fill List dropdown  
    getFillList(id, reference) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(id, reference)
            .then((data) => {
                if (data.error != 1) {
                    if (id === updateChecklistPreparedByActionId) {
                        this.setState({
                            preparedByList: data.data,
                        })
                    }
                    this.setState({
                        isLoading: false
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    // get update checklist   details
    getUpdateChecklistDetails() {
        const { updateChecklistData } = this.state
        var id = this.props.location.state.updateChecklistId
        this.setState({ isLoading: true })
        // await this.getClientsList()
        this.props
            .updateChecklistDetails_action(id)
            .then((data) => {
                if (data.error != 1) {
                    for (let i = 0; i < data.data['checkList'].length; i++) {
                        var isDisable = false;
                        if (data.data['checkList'][i].Isok) {
                            isDisable = true;
                        } else if (data.data['checkList'][i].Name == 'Quality' && data.data['Role'] == 'E') {
                            isDisable = true;
                        } else if (data.data['checkList'][i].Name == 'Engagement' && data.data['Role'] == 'Q') {
                            isDisable = true;
                        }
                        data.data['checkList'][i].ActionID = 0;
                        data.data['checkList'][i].isDisabled = isDisable;
                        data.data['checkList'][i].roleId = localStorage.getItem('roleIdLS');
                        data.data['checkList'][i].Role = data.data['Role'];

                    }
                    this.setState({
                        updateChecklistData: data.data,
                        checklistList: data.data['checkList'],
                        isLoading: false,
                        status: data.data['Status'],
                        isDetails: true
                    })

                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })

    }


    handleKeyDown(e, index) {
        if (e.code === 'Enter') {
            this.handleRemark(e, index)
        }
    }

    handleRemark(e, index) {
        const { checklistList } = this.state
        checklistList[index]['CompletedByRemark'] = e.target.value
        checklistList[index]['ActionID'] = 2
        this.setState({ checklistList: checklistList })
    }

    // clear field ,list
    handleCancel() {
        this.props.navigate('/updateChecklist')
    }

    //toggle model for delete.................................
    toggleModal() {
        const { isOpen, updateChecklistId } = this.state
        this.setState({ isOpen: !isOpen })

    }

    onSelectCompleted(e, data, index) {
        const { checklistList } = this.state
        if (e.target.checked) {
            checklistList[index]['IsCompleted'] = !checklistList[index]['IsCompleted']
        }
        else {
            checklistList[index]['IsCompleted'] = !checklistList[index]['IsCompleted']
            checklistList[index]['PreparedBy'] = ''
            checklistList[index]['CompletedByRemark'] = ''
        }
        checklistList[index]['ActionID'] = 2
        checklistList[index]['CompletedDate'] = null
        checklistList[index]['CompletedBy'] = localStorage.getItem('UserId')
        this.setState({ checklistList: checklistList })

    }

    onSelectPreparedBy(e, index) {
        const { checklistList } = this.state
        checklistList[index]['PreparedBy'] = e.target.value != '' ? parseInt(e.target.value) : ''
        checklistList[index]['ActionID'] = 2
        this.setState({ checklistList: checklistList })
    }

    // update  checklist 
    handleUpdate(e) {
        e.preventDefault()
        const { checklistList, updateChecklistData } = this.state
        const id = this.props.location.state.updateChecklistId;
        console.log("checklistList------->", checklistList)
        if (id != "" && checklistList != "") {
            this.setState({ isLoading: true })
            const list = []
            checklistList.map((item, index) => {
                // item.ActionID = 2 
                // item.EngagementChecklistID = checklistList['EngagementChecklistID']
                // item.EngMemberID = updateChecklistData['EngMemberID']
                item.IsReviewed = null
                item.ReviewedDate = null
                item.File = null
            })
            // var list = checklistList.filter((item) => (item.ActionID === 2))

            var isPreparedByIdSelected = false
            // var isPreparedByIdSelected2 = false
            // // var sr_no = ''

            console.log("list 0000", list);
            // list.map((item, index) => {

            //     if (item.IsCompleted && item.PreparedBy != '') {

            //         isPreparedByIdSelected = true
            //     } else {
            //         isPreparedByIdSelected = false
            //     }
            // })

            checklistList.map((item, index) => {
                if (item.IsCompleted) {
                    console.log('item.PreparedBy ', item.PreparedBy)
                    if (item.PreparedBy == '' || item.PreparedBy == 0) {
                        isPreparedByIdSelected = true
                    }
                }
            })



            if (!isPreparedByIdSelected) {
                this.props
                    .updateUpdateChecklist_action(id, checklistList)
                    .then((data) => {
                        if (data.error != 1) {
                            toast.success('Data updated successfully', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })

                            this.setState({ isLoading: false })
                            setTimeout(() => {
                                this.handleCancel()
                            }, 1000);
                        }
                        else {
                            this.setState({ isLoading: false })

                            toast.error(data.msg, {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
            else {
                // if (isPreparedByIdSelected2) {
                //     console.log('isPreparedByIdSelected2 else', isPreparedByIdSelected2)
                //     this.setState({ isLoading: false })
                // }
                // else {
                //     console.log('isPreparedByIdSelected2 else', isPreparedByIdSelected2)
                this.setState({ isLoading: false })
                toast.error(`Select Completed and Prepared by field `, {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                return;
                // }

            }


        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }

    // on close
    handleClose(e) {
        e.preventDefault()
        const { remark } = this.state
        this.setState({ isLoading: true })
        const id = this.props.location.state.updateChecklistId;
        if (remark != '' && id != '') {
            this.props
                .closeUpdateChecklist_action(id, remark)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Checklist closed successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isOpen: !this.state.isOpen, isLoading: false, remark: " " })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  Remark field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    render() {
        const { isLoading, updateChecklistData, remark, preparedByList,
            checklistList, isOpen, currentPage, isShowMore,
            clientName, engPartnerName, qualityReviewerName, engagementTypeId, status, updateChecklistId
        } = this.state


        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        <>
                            {/* start page */}
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li>
                                                <a href="#">Update Checklist</a>
                                            </li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className="user-status">
                                        <div className="">
                                            {
                                                <p>
                                                    <strong className={updateChecklistData['Status'] != null ? (updateChecklistData['Status'] === 'In-Process' ? 'greena' :
                                                        updateChecklistData['Status'] == '' ? null : 'redi') : ''}>
                                                        {updateChecklistData['Status'] != null ? updateChecklistData['Status'] : ''}</strong>
                                                </p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="row user-status">
                                            <div className="status-top mr-2">
                                                <p>
                                                    <strong className={updateChecklistData['Status'] != null ? (updateChecklistData['Status'] === 'In-Process' ? 'greena' : 'redi') : ''}>
                                                        {updateChecklistData['Status'] != null ? updateChecklistData['Status'] : ''}</strong>
                                                </p>
                                            </div>
                                        </div> */}
                                        <div className='page-space-from-bottom'>
                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body">

                                                        <div className="col-md-12 mb-3">

                                                            <div className="d-flex flex-wrap row">
                                                                {/* <div class="form-group col-md-3">
                                                      <label>Company</label>
                                                       <p>Natvarlal Vepari & Co</p>
                                              </div> */}
                                                                <div className="form-group col-md-3 ">
                                                                    <label>Client</label>
                                                                    <p>{updateChecklistData['ClientName'] != null ? updateChecklistData['ClientName'] : ''}</p>
                                                                </div>
                                                                <div className="form-group col-md-3">
                                                                    <label>Engagement Type</label>
                                                                    <p>{updateChecklistData['EngagementType'] != null ? updateChecklistData['EngagementType'] : ''}</p>
                                                                </div>
                                                                <div className="form-group col-md-3">
                                                                    <label>Financial Year</label>
                                                                    <p>{updateChecklistData['FinancialYear'] != null ? updateChecklistData['FinancialYear'] : ''}</p>
                                                                </div>
                                                                <div className="form-group col-md-3">
                                                                    <label>Location</label>
                                                                    <p>{updateChecklistData['LocationName'] != null ? updateChecklistData['LocationName'] : ''}</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-wrap row mt-2">
                                                                <div className="form-group col-md-3">
                                                                    <label>Audit Period</label>
                                                                    <p>{updateChecklistData.AuditFromDate != null ? moment(updateChecklistData.AuditFromDate).format('DD-MM-YYYY') : ''}
                                                                        - {updateChecklistData.AuditToDate != null ? moment(updateChecklistData.AuditToDate).format('DD-MM-YYYY') : ''}</p>
                                                                </div>
                                                                <div className="form-group col-md-3">
                                                                    <label>Start Date - End Date</label>
                                                                    <p>{updateChecklistData.StartDate != null ? moment(updateChecklistData.StartDate).format('DD-MM-YYYY') : ''}
                                                                        - {updateChecklistData.EndDate != null ? moment(updateChecklistData.EndDate).format('DD-MM-YYYY') : ''}</p>
                                                                </div>
                                                                <div className="form-group col-md-3">
                                                                    <label>Expected Closure Date</label>
                                                                    <p>{updateChecklistData.ExpectedClosureDate != null ? moment(updateChecklistData.ExpectedClosureDate).format('DD-MM-YYYY') : ''}</p>
                                                                </div>
                                                            </div>

                                                            {!isShowMore ?
                                                                <></>
                                                                :
                                                                // <div className="content">
                                                                <div className="d-flex flex-wrap row mt-1">
                                                                    <div className="form-group col-md-3">
                                                                        <label>Engagement Head</label>
                                                                        <p>{updateChecklistData['EngagementHead'] != null ? updateChecklistData['EngagementHead'] : ''}</p>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label>Engagement Partner</label>
                                                                        <p>{updateChecklistData['EngPartnerName'] != null ? updateChecklistData['EngPartnerName'] : ''}</p>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label>Quality Reviewer</label>
                                                                        <p>{updateChecklistData['QualityReviewerName'] != null ? updateChecklistData['QualityReviewerName'] : ''}</p>
                                                                    </div>
                                                                    <div class="form-group col-md-3">
                                                                        <label>Team Member</label>
                                                                        <p>{updateChecklistData['TeamMemberName'] != null ? updateChecklistData['TeamMemberName'] : ''}</p>


                                                                    </div>
                                                                </div>
                                                                // </div>
                                                            }

                                                            {isShowMore ?
                                                                <p style={{ color: "#1683ff" }}>
                                                                    {" "}
                                                                    <button className='btn btn-outline-info p-1 py-1 pt-2 pb-2 px-2 rounded-1' onClick={() => this.setState({ isShowMore: !isShowMore })}>
                                                                        <i className="fa fa-arrow-up" /> Less
                                                                    </button>
                                                                </p>
                                                                :
                                                                <p style={{ color: "#1683ff" }}>
                                                                    {" "}
                                                                    <button className='btn btn-outline-info p-1 py-1 pb-2 px-2 rounded-1' onClick={() => this.setState({ isShowMore: !isShowMore })}>
                                                                        <i className="fa fa-plus" /> Show More
                                                                    </button>

                                                                </p>}
                                                            {/* Show More End Here */}
                                                            <div className="row mt-2">
                                                                <div className="table-responsive tbs4 common-table trail-tb" style={{ height: '360px' }}>
                                                                    <table className="table table-bordered tb-action du-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="text-center">Sr. No</th>
                                                                                <th>Checklist</th>
                                                                                <th style={{ width: 100 }}>E/Q</th>
                                                                                <th
                                                                                    style={{ width: 100 }}
                                                                                    className="text-center"
                                                                                >
                                                                                    Critical
                                                                                </th>
                                                                                <th className="text-center">Completed</th>
                                                                                <th>Prepared By</th>
                                                                                {/* <th>Reviewed By</th> */}
                                                                                <th>Prepared By Remark</th>
                                                                                <th>Reviewed By Remark</th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                checklistList.length > 0 ? checklistList.map((item, index) => (
                                                                                    <tr>
                                                                                        <td className="text-center">{index + 1}</td>
                                                                                        <td style={{ textAlign: 'left' }}>{item.ChecklistName}</td>
                                                                                        <td>{item.Name}</td>
                                                                                        <td className="text-center">{item.IsCritical ? 'Critical' : 'Non-Critical'}</td>
                                                                                        <td>{" "}<input type="checkbox" checked={item.IsCompleted}
                                                                                            value={item.IsCompleted}
                                                                                            // disabled={item.Name == 'Quality' && item.roleId == 2  ? true : item.Name == 'Engagement' && item.roleId != 2 ? true : item.IsFileAvailable}
                                                                                            disabled={item.isDisabled}
                                                                                            className={item.isDisabled && 'bg-secondary'}
                                                                                            onClick={e => this.onSelectCompleted(e, item, index)}
                                                                                        />
                                                                                        </td>

                                                                                        <td style={{ padding: 0 ,width:'150px'}}>
                                                                                            <select className="js-example-basic-single"
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                    height: 38,
                                                                                                    border: "none",
                                                                                                    background: "#eef7f9"
                                                                                                }}
                                                                                                value={item.PreparedBy}
                                                                                                disabled={item.isDisabled || !item.IsCompleted}
                                                                                                onChange={e => this.onSelectPreparedBy(e, index)}>
                                                                                                <option value={''}>Select</option>
                                                                                                {preparedByList.map(item => (
                                                                                                    <option
                                                                                                        key={item.TeamMemberID}
                                                                                                        value={item.TeamMemberID}>
                                                                                                        {item.TeamMember}
                                                                                                    </option>
                                                                                                ))}

                                                                                            </select>
                                                                                        </td>
                                                                                        {/* <td>{item.ReviewerName}</td> */}
                                                                                        <td style={{ padding: 0 }}>
                                                                                            <input
                                                                                                type="text"
                                                                                                style={{ padding: '5px', width: '100%', background: "#eef7f9" }}
                                                                                                value={item.CompletedByRemark}
                                                                                                disabled={item.isDisabled || !item.IsCompleted}
                                                                                                onChange={e => this.handleRemark(e, index)}
                                                                                                onKeyDown={e => this.handleKeyDown(e, index)}
                                                                                                maxLength={250}
                                                                                            />
                                                                                        </td>
                                                                                        <td style={{ padding: '5px', width: '200px', background: "#eef7f9" }}>{item.ReviewedByRemark}</td>
                                                                                        {/* <td style={{ padding: 0 }}>
                                                                                            <div class="demo" contenteditable>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    // style={{ padding: 7, background: "#eef7f9"}}
                                                                                                    value={item.ReviewedByRemark}
                                                                                                    disabled={true}/></div>
                                                                                            </td> */}
                                                                                    </tr>
                                                                                )) : (<tr ><td colSpan={8} style={{ textAlign: 'center' }}>Check list is not available</td></tr>)
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="footer-btn">
                                                                    <div className="col-md-3">
                                                                        <div
                                                                            className="btn-group"
                                                                            role="group"
                                                                            aria-label="Basic example"
                                                                        >
                                                                            {this.state.isDetails ?
                                                                                updateChecklistData['Status'] === 'Closed' || updateChecklistData['Status'] === 'Cancelled' ? null :
                                                                                    <>{getModuleUpdateAccess(Update_Checklist) && <button
                                                                                        type="button"
                                                                                        className="btn btn-update footer-btn-inner mr-1"
                                                                                        onClick={this.handleUpdate}
                                                                                    >
                                                                                        Update
                                                                                    </button>}</>
                                                                                : <></>}
                                                                            {/* <button
                                                                                type="button"
                                                                                className="btn btn-delete footer-btn-inner mr-1"
                                                                                data-toggle="modal"
                                                                                data-target="#close"
                                                                                onClick={this.toggleModal}
                                                                            >
                                                                                Close
                                                                            </button> */}
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-cancel footer-btn-inner"
                                                                                onClick={this.handleCancel}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />

                            {/* Modal */}
                            {/* 
                            <Modal
                                isOpen={isOpen}
                                onRequestClose={this.toggleModal}>
                             
                                    <div className="modal-dialog custom_modal_dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header " style={{ padding: "10px 10px" }}>
                                                <h5 className="modal-title modal_title_text" id="close">
                                                    Forcefully Closure of an Engagement
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="close modal-close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={this.toggleModal}
                                                >
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body" style={{ padding: "10px 10px" }}>
                                                <div className="d-flex flex-wrap row">
                                                    <div className="form-group col-12">
                                                        <label style={{ fontSize: 14 }}>
                                                            There are {checklistList.length} critical checklist, which has not yet been updated.
                                                            In case you want to forcefully close this engagement, enter your
                                                            remark and click on "Close" else click on "Cancel" to fill the
                                                            missing checklists.
                                                        </label>
                                                        <textarea
                                                            className="mt-2"
                                                            name="remark"
                                                            rows={4}
                                                            cols={50}
                                                            style={{ border: "1px solid #ddd" }}
                                                            value={remark}
                                                            onChange={e => this.setState({ remark: e.target.value })}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-delete footer-btn-inner mr-1"
                                                    onClick={e => this.handleClose(e)}
                                                >
                                                    Close
                                                </button>
                                                <button type="button" className="btn btn-cancel  footer-btn-inner"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={this.toggleModal}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                              
                            </Modal> */}
                            <Modal
                                isOpen={isOpen}
                                onRequestClose={this.toggleModal}>
                                <div className="modal-dialog custom_modal_dialog">
                                    <div className="modal-content" style={{ borderRadius: '0px', left: '0%', width: '400px' }}>
                                        <div class="modal-header">
                                            <h4 class="modal-title">Forcefully Closure of an Engagement</h4>
                                            {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
                                            <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                                        </div>
                                        <div class="modal-body">
                                            <div className="d-flex flex-wrap row">
                                                <div className="form-group col-12">
                                                    <label style={{ fontSize: 14 }}>
                                                        There are {checklistList.length} critical checklist, which has not yet been updated.
                                                        In case you want to forcefully close this engagement, enter your
                                                        remark and click on "Close" else click on "Cancel" to fill the
                                                        missing checklists.
                                                    </label>
                                                    <textarea
                                                        className="mt-2"
                                                        name="remark"
                                                        rows={4}
                                                        cols={50}
                                                        style={{ border: "1px solid #ddd" }}
                                                        value={remark}
                                                        onChange={e => this.setState({ remark: e.target.value })}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-delete footer-btn-inner mr-1"
                                                onClick={e => this.handleClose(e)}
                                            >
                                                Close
                                            </button>
                                            <button type="button" className="btn btn-cancel  footer-btn-inner "
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={this.toggleModal}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </Modal>
                        </>

                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>


            </>
        );
    }
}

export default withRouter(connect(null, {
    updateChecklistDetails_action, updateUpdateChecklist_action,
    closeUpdateChecklist_action,
    fill_action
})(UpdateChecklistU));