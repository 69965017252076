import React, { Component } from "react";
import Loader from "../../../utils/Loader";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-modal";
import Pagination from '../../../utils/pagination'
import { clearLocalStorage, Master } from '../../../utils/constants'
import { engagementMasterList_action, deleteEngagementMaster_action } from '../../../actions/master_action/engagement_master_action'
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from "../../../utils/RoleAccess";


class EngagementMaster extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAccessToView: true,
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage: 10,
            pageSizeInput: "10",
            engagementMasterList: [],

            engagementMasterNameEMLS: '',
            statusEMLS: '',
            engagementMasterName: '',
            status: ' ',
            engagementMasterDeleteId: '',
            isOpen: false,
            isEngagementMasterNameSort: false,
            isSearchTab: false,

        }

        this.getEngagementMasterList = this.getEngagementMasterList.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);

        this.toggleModal = this.toggleModal.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        // this.engagementMasterNameSort = this.engagementMasterNameSort.bind(this)
        this.handleAdd = this.handleAdd.bind(this)

    }

    // engagementMasterNameSort() 

    //get list
    getEngagementMasterList() {
        const { engagementMasterName, status } = this.state
        this.setState({ isLoading: true })
        clearLocalStorage("engagementMaster")
        this.props
            .engagementMasterList_action(engagementMasterName.trim(), status === '' ? 1 : status)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        engagementMasterList: data.data,
                        isLoading: false,
                        currentPage: 1
                    })
                    console.log(data)
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    //engagementmater list default
    componentDidMount = () => {
        this.setState({
            isAccessToView: true,
            engagementMasterNameEMLS: localStorage.getItem('engagementMasterNameEMLS'),
            statusEMLS: localStorage.getItem('statusEMLS'),

            engagementMasterName: localStorage.getItem('engagementMasterNameEMLS') != '' ? localStorage.getItem('engagementMasterNameEMLS') : '',
            status: localStorage.getItem('statusEMLS') != '' ? localStorage.getItem('statusEMLS') : '',
        })
        clearLocalStorage("engagementMaster")

        setTimeout(() => {
            this.getEngagementMasterList()

        }, 300);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    // update engagement master
    handleUpdate(EngagementMasterID) {
        console.log(EngagementMasterID)
        this.props.navigate('/engagementMasterAU', { state: { is_update: true, engagementMasterId: EngagementMasterID } })
    }

    handleAdd() {
        this.props.navigate('/engagementMasterAU', { state: { is_add: true } })
    }

    //search handle
    handleSearch() {
        const { engagementMasterName, status } = this.state
        clearLocalStorage("engagementMaster")
        this.setState({ isLoading: true })
        this.props
            .engagementMasterList_action(engagementMasterName.trim(), status === '' ? 1 : status)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ engagementMasterList: data.data, isLoading: false, currentPage: 1 })
                    console.log(data)
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
        localStorage.setItem("engagementMasterNameEMLS", engagementMasterName)
        localStorage.setItem("statusEMLS", status)
    }

    //clear value
    clearValue() {
        this.setState({
            engagementMasterList: [],
            engagementMasterName: '',
            engagementMasterNameEMLS: '',
            statusEMLS: '',
            status: ' ',
            currentPage: 1,
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getEngagementMasterList()
        }, 300);
    }

    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.handleSearch()
        }
    }

    toggleModal(EngagementMasterID) {
        const { isOpen, engagementMasterDeleteId } = this.state
        this.setState({ isOpen: !isOpen })
        if (EngagementMasterID == '') {
            return
        }
        else {
            this.setState({ engagementMasterDeleteId: EngagementMasterID })
        }
    }

    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    //delete handle
    handleDelete(e, EngagementMasterID) {
        e.preventDefault()
        var id = EngagementMasterID
        this.setState({ isLoading: true })
        this.props
            .deleteEngagementMaster_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isOpen: !this.state.isOpen, isLoading: false })
                    this.getEngagementMasterList()
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }
    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
            this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
    };

    handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
            this.setState({ pageSizeInput: input });
        }
    };

    record(engagementMasterList) {
        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ textAlign: 'left' }} >Engagement Master Name</th>
                        <th style={{ width: '100px' }}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {engagementMasterList.length > 0 ? (
                        engagementMasterList.map((data, i) => (
                            <tr key={Math.random()}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        {getModuleUpdateAccess(Master) ?
                                            <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleUpdate(data.EngagementMasterID)} /></a> :
                                            <a><i className="fa fa-eye" onClick={() => this.handleUpdate(data.EngagementMasterID)} /></a>
                                        }
                                        {data.IsActive === true && getModuleDeleteAccess(Master) ? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.EngagementMasterID)} /></span></a>
                                            : <></>}

                                    </div>
                                </td>
                                <td style={{ textAlign: 'left' }}> {data.EngagementDesc}</td>
                                <td>{data.IsActive == true ? "Active" : "Inactive"}</td>
                            </tr>
                        ))
                    ) : (<tr><td colSpan={5} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }

    render() {
        const { isSearchTab, isLoading, isAccessToView, engagementMasterList, isOpen, engagementMasterDeleteId, engagementMasterName, status } = this.state
        // const recordsPerPage = 10
        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = engagementMasterList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(engagementMasterList.length / recordsPerPage)
        return (

            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />

                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (<div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul className="breadcrumb">
                                        <li><a>Engagement Master </a></li>
                                        <li />
                                    </ul>
                                </div>
                                <div className='page-space-from-bottom'>
                                    <div className="row">
                                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                        <div className="col-md-12 pg-body grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body pr-2">

                                                    <div className="accordion accordion-flush " id="accordionFlushExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="flush-headingOne">
                                                                <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                                                    <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                </button>
                                                            </h2>
                                                            {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                            <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                <div className="d-flex flex-wrap row">
                                                                    <div className="form-group col-md-4">
                                                                        <label>Engagement Master Name</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Engagement Master Name"
                                                                            value={engagementMasterName}
                                                                            onChange={(e) => this.setState({ engagementMasterName: e.target.value })}
                                                                            onKeyDown={e => this.handleKeyDown(e)}
                                                                            required />
                                                                    </div>

                                                                    <div className="form-group col-md-3">
                                                                        <label>Status</label>
                                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                                            value={status}
                                                                            onChange={(e) => this.setState({ status: e.target.value })}>
                                                                            <option value={''}>Select</option>
                                                                            <option value={1}>Active</option>
                                                                            <option value={0}>Inactive</option>
                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group col-md-3 filter-btn-col p-0">
                                                                        <label></label>
                                                                        <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={e => this.handleSearch()}> Search</button>
                                                                        <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3" onClick={e => this.clearValue()}> Clear</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div> : <></>}
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-12 mt-1">
                                                            {getModuleWriteAccess(Master) && < button type="button" className="btn btn-Add rounded-1 mb-1 float-right px-3" onClick={this.handleAdd}>Add</button>}
                                                            {/* button */}
                                                            <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                                {this.record(currentRecords)}
                                                                {engagementMasterList.length > 0 ? <Pagination
                                                                    nPages={nPages}
                                                                    currentPage={currentPage}
                                                                    setCurrentPage={this.updateItem}
                                                                    recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                    setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                    totalRecords={engagementMasterList.length}
                                                                /> : <></>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)
                        }
                        {/* end page */}
                    </div >
                    {/* page-body-wrapper ends */}
                </div >
                <ToastContainer />
                {/* // modal for delete */}
                {/* <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, engagementMasterDeleteId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}

                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right">
                                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, engagementMasterDeleteId)}> Yes</a>
                                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withRouter(connect(null, { engagementMasterList_action, deleteEngagementMaster_action })(EngagementMaster))