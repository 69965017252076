import { LEAVE_LIST_ACTION_TYPE, LEAVE_MIN_DATE_ACTION_TYPE, LEAVE_ADD_ACTION_TYPE, LEAVE_UPDATE_ACTION_TYPE, LEAVE_DETAILS_ACTION_TYPE, LEAVE_DETETE_ACTION_TYPE } from "../../utils/constants"


const initialState = [];

function leaveReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LEAVE_LIST_ACTION_TYPE:
            return [...state, payload];
        case LEAVE_ADD_ACTION_TYPE:
            return [...state, payload];
        case LEAVE_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case LEAVE_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case LEAVE_DETETE_ACTION_TYPE:
            return [...state, payload];
        case LEAVE_MIN_DATE_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
};

export default leaveReducer;
