
import {
    DOCUMENT_ACTION_TYPE,
    DOCUMENT_DETAILS_ACTION_TYPE,
    DOCUMENT_ADD_ACTION_TYPE,
    DOCUMENT_UPDATE_ACTION_TYPE,
    DOCUMENT_DELETE_ACTION_TYPE,
 } from "../../utils/constants"


const initialState = [];

function documentReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DOCUMENT_ACTION_TYPE:
      return [...state, payload];
      case DOCUMENT_DETAILS_ACTION_TYPE:
      return [...state, payload];
    case DOCUMENT_ADD_ACTION_TYPE:
      return [...state, payload];
      case DOCUMENT_UPDATE_ACTION_TYPE:
        return [...state, payload];
        case DOCUMENT_DELETE_ACTION_TYPE:
      return [...state, payload];
    default:
      return state;
  }
};

export default documentReducer;


// export default (state = {}, action) => {
//     switch (action.type) {
//      case 'SIMPLE_ACTION':
//       return {
//        result: action.payload
//       }
//      default:
//       return state
//     }
//    }