import { axiosPost, axiosGet } from "../../react_axios/Axios"
import { LOCATIONTYPE_ADD_ACTION_TYPE, LOCATIONTYPE_DELETE_ACTION_TYPE, LOCATIONTYPE_DETAILS_ACTION_TYPE, LOCATIONTYPE_LIST_ACTION_TYPE, LOCATIONTYPE_UPDATE_ACTION_TYPE, locationTypeAddUrl, locationTypeListUrl, locationTypeDeleteUrl, locationTypeUpdateUrl, locationTypeDetailsUrl, platfarm, headers } from '../../utils/constants'

const user_id = localStorage.getItem('UserId')

export const locationTypeList_action = (locationTypeName, status)=> async(dispatch) => {
    try{
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: locationTypeName,
            Status:status
        }
        // console.log("locationTypeList_action",param)
        const res = await axiosGet(locationTypeListUrl, param);
        dispatch({
            type: LOCATIONTYPE_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    }
    catch(err){
        return Promise.reject(err);
    }
}

export const locationTypeAdd_action = (LocationTypeName) => async(dispatch) => {
    try {
        const param = {
            UserID : localStorage.getItem('UserId'),
            Platform : platfarm,
            LocationType : LocationTypeName
        }
        const res = await axiosPost(locationTypeAddUrl, param, headers);
        dispatch({
            type: LOCATIONTYPE_ADD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);    
    } 
    catch(err){
        return Promise.reject(err);
    }
}

export const locationTypeDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }        
        const res = await axiosGet(locationTypeDetailsUrl, param);
        dispatch({
            type: LOCATIONTYPE_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const locationTypeUpdate_action = (locationTypeName, id) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            LocationType: locationTypeName,
            ID : id,
        }
        const res = await axiosPost(locationTypeUpdateUrl, mapdata, headers);
        dispatch({
            type: LOCATIONTYPE_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const locationTypeDelete_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }
        const res = await axiosGet(locationTypeDeleteUrl, param);
        dispatch({
            type: LOCATIONTYPE_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch (err) {
        return Promise.reject(err);
    }
};