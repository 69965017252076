
import { CLIENTGROUP_ADD_ACTION_TYPE,CLIENTGROUP_DELETE_ACTION_TYPE,CLIENTGROUP_DETAILS_ACTION_TYPE,CLIENTGROUP_LIST_ACTION_TYPE,CLIENTGROUP_UPDATE_ACTION_TYPE } from "../../utils/constants";

const initialState = []

function clientGroupReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case CLIENTGROUP_LIST_ACTION_TYPE:
            return [...state, payload];
        case CLIENTGROUP_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case CLIENTGROUP_ADD_ACTION_TYPE:
            return [...state, payload];
        case CLIENTGROUP_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case CLIENTGROUP_DELETE_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}

export default clientGroupReducer