

import { CHECK_LIST_ACTION_TYPE, CHECK_LIST_DETAILS_ACTION_TYPE, CHECK_LIST_ADD_ACTION_TYPE,
   CHECK_LIST_UPDATE_ACTION_TYPE, CHECK_LIST_DELETE_ACTION_TYPE,FILL_LIST_ACTION_TYPE } from "../../utils/constants"


const initialState = [];

function checkListReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHECK_LIST_ACTION_TYPE:
      return [...state, payload];
      case FILL_LIST_ACTION_TYPE:
      return [...state, payload];
    case CHECK_LIST_DETAILS_ACTION_TYPE:
      return [...state, payload];
    case CHECK_LIST_ADD_ACTION_TYPE:
      return [...state, payload];
    case CHECK_LIST_UPDATE_ACTION_TYPE:
      return [...state, payload];
    case CHECK_LIST_DELETE_ACTION_TYPE:
      return [...state, payload];
    default:
      return state;
  }
};

export default checkListReducer;


