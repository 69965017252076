import { axiosPost, axiosGet } from "../../react_axios/Axios"
import { departmentAddUrl, departmentListUrl, departmentDetailsUrl, departmentUpdateUrl, departmentDeleteUrl, DEPARTMENT_ADD_ACTION_TYPE, DEPARTMENT_LIST_ACTION_TYPE, DEPARTMENT_DETAILS_ACTION_TYPE, DEPARTMENT_UPDATE_ACTION_TYPE, DEPARTMENT_DELETE_ACTION_TYPE, platfarm, headers } from '../../utils/constants'

const user_id = localStorage.getItem('UserId')

export const departmentList_action = (departmentName, status)=> async(dispatch) => {
    try{
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: departmentName,
            Status:status
        }
        const res = await axiosGet(departmentListUrl, param);
        dispatch({
            type: DEPARTMENT_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    }
    catch(err){
        return Promise.reject(err);
    }
}

export const departmentAdd_action = (departmentName) => async(dispatch) => {
    try {
        const param = {
            UserID : localStorage.getItem('UserId'),
            Platform : platfarm,
            Name : departmentName
        }
        const res = await axiosPost(departmentAddUrl, param, headers);
        dispatch({
            type: DEPARTMENT_ADD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);    
    } 
    catch(err){
        return Promise.reject(err);
    }
}

export const departmentDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }        
        const res = await axiosGet(departmentDetailsUrl, param);
        dispatch({
            type: DEPARTMENT_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const departmentUpdate_action = (departmentName, id) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: departmentName,
            ID : id,
        }
        const res = await axiosPost(departmentUpdateUrl, mapdata, headers);
        console.log("departmentUpdate_action", mapdata)
        dispatch({
            type: DEPARTMENT_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const departmentDelete_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }
        const res = await axiosGet(departmentDeleteUrl, param);
        dispatch({
            type: DEPARTMENT_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch (err) {
        return Promise.reject(err);
    }
};