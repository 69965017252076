import { axiosGet } from "../../react_axios/Axios"
import {platfarm,headers,dashboardUrl, DASHBOARD_ACTION_TYPE } from "../../utils/constants"

export const dashboard_action = (From,status, finYearId) => async (dispatch) => {
  try {
    const mapdata = {
        UserID: localStorage.getItem('UserId'),
        platform: platfarm,
        From: From,
        Status : status,
        FinYearId : finYearId,
    }
    const res = await axiosGet(dashboardUrl, mapdata);

    dispatch({
      type: DASHBOARD_ACTION_TYPE,
      payload: res,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};


