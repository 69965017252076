import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    getGenderListUrl,
    addGenderUrl,
    updateGenderUrl,
    getGenderDetailsUrl,
    deleteGenderUrl,
    platfarm,
    GENDER_LIST_ACTION_TYPE,
    GENDER_DETAILS_ACTION_TYPE,
    GENDER_ADD_ACTION_TYPE,
    GENDER_UPDATE_ACTION_TYPE,
    GENDER_DELETE_ACTION_TYPE,
    headers
} from "../../utils/constants"
import moment from 'moment';



export const genderList_action = (genderName,status) => async (dispatch) => {
    try {

        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            GenderName : genderName,
            status : status != '' ? status : '1'
        }
        const res = await axiosGet(getGenderListUrl, param);
        dispatch({
            type: GENDER_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const genderDetail_action = (id, lastRecord) => async (dispatch) => {
    try {
        const param = {
            ID: id,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            LastRecord: lastRecord
        }

        const res = await axiosGet(getGenderDetailsUrl, param);
        dispatch({
            type: GENDER_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const addgenderList_action = (genderName) => async (dispatch) => {
    try {
        const mapdata = {
            Gender: genderName,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }
        const res = await axiosPost(addGenderUrl, mapdata, headers);

        dispatch({
            type: GENDER_ADD_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updategenderList_action = (id, genderName) => async (dispatch) => {
    try {
        const mapdata = {
            Id: id,
            Gender : genderName,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }
        const res = await axiosPost(updateGenderUrl, mapdata, headers);

        dispatch({
            type: GENDER_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const deletegenderList_action = (id) => async (dispatch) => {
    try {
        const param = {
            Id: id,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,

        }
        const res = await axiosGet(deleteGenderUrl, param);
        dispatch({
            type: GENDER_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export default (state = {}, action) => {
    switch (action.type) {
        case 'SIMPLE_ACTION':
            return {
                result: action.payload
            }
        default:
            return state
    }
}