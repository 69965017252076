import { ADDRESS_TYPE_DETAILS_ACTION_TYPE, ADDRESS_TYPE_ADD_ACTION_TYPE, ADDRESS_TYPE_UPDATE_ACTION_TYPE,ADDRESS_TYPE_LIST_ACTION_TYPE, ADDRESS_TYPE_DETETE_ACTION_TYPE } from "../../utils/constants";

const initialState = []

function addressTypeReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case ADDRESS_TYPE_LIST_ACTION_TYPE:
            return [...state, payload];
        case ADDRESS_TYPE_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case ADDRESS_TYPE_ADD_ACTION_TYPE:
            return [...state, payload];
        case ADDRESS_TYPE_DETETE_ACTION_TYPE:
            return [...state, payload];
        case ADDRESS_TYPE_UPDATE_ACTION_TYPE:
            return [...state, payload]
        default:
            return state;
    }
}

export default addressTypeReducer