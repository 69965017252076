import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {platfarm, headers,
    UPDATE_CHECKLIST_ACTION_TYPE,
    UPDATE_CHECKLIST_DETAILS_ACTION_TYPE,
    UPDATE_CHECKLIST_UPDATE_ACTION_TYPE,
    UPDATE_CHECKLIST_CLOSE_ACTION_TYPE,
    updateChecklistUrl ,updateChecklistDetailsUrl ,closeUpdateChecklistUrl,
    updateUpdateChecklistUrl, fillListUrl, FILL_LIST_ACTION_TYPE,
    uploadFileUrl,deleteFileUrl,
    REVIEW_CHECKLIST_FILE_UPLOAD_ACTION_TYPE,
    REVIEW_CHECKLIST_FILE_DELETE_ACTION_TYPE, 
    REVIEW_CHECKLIST_FILE_GET_ACTION_TYPE, getFileUrl, EngClosureReportName, fetchAuthReport

} from "../../utils/constants"
import moment from 'moment';


export const closeEngagementChecklist_action = (financialYear,clientName,status) => async (dispatch) => {
    try {   
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientName : clientName,
            FinancialYear : financialYear,
            status : status,
            from : 'close'
        }
        const res = await axiosGet(updateChecklistUrl, param);
        dispatch({
            type: UPDATE_CHECKLIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const closeChecklistDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Id: id
        }        

        const res = await axiosGet(updateChecklistDetailsUrl, param);
        dispatch({
            type: UPDATE_CHECKLIST_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const closeCloseEngagementChecklist_action = (id,remark,ActualClosureDate) => async (dispatch) => {
    try {
        const param = {
            EngagementID : id,
            Remark : remark,
            userId: localStorage.getItem('UserId'),
            Platform: platfarm,
            ActualClosureDate : new Date(moment(ActualClosureDate ).format('MM-DD-YYYY')+'Z').toISOString(), 
        }
        const res = await axiosGet(closeUpdateChecklistUrl, param);
        dispatch({
            type: UPDATE_CHECKLIST_CLOSE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const uploadFile_action = (id,file) => async (dispatch) => {
    try {   
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            EngChecklistID: id,
            File : file
        }
        const res = await axiosPost(uploadFileUrl, param);
        dispatch({
            type: REVIEW_CHECKLIST_FILE_UPLOAD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const downloadStatus_action = (id) => async (dispatch) => {
    try {
        var list = [];
        const mapdata1 = {
            "ParameterName":"@id",
            "ParameterValue":id,
            "ParameterDataType":"int"
        }
        
        list.push(mapdata1);

        const mapData = {
            "user_id": localStorage.getItem('UserId'),
            "report_name": EngClosureReportName,
            "parameter_details": list
        }

        const res = await axiosPost(fetchAuthReport, mapData);

        dispatch({
            type: UPDATE_CHECKLIST_CLOSE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const deleteFile_action = (id) => async (dispatch) => {
    try {   
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            EngChecklistID: id
        }
        const res = await axiosGet(deleteFileUrl, param);
        dispatch({
            type: REVIEW_CHECKLIST_FILE_DELETE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const GetFile_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Id: id
        }        
        const res = await axiosGet(getFileUrl, param);
        dispatch({
            type: REVIEW_CHECKLIST_FILE_GET_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};



export const simpleAction = () => dispatch => {
    dispatch({
     type: 'SIMPLE_ACTION',
     payload: 'result_of_simple_action'
    })
   }