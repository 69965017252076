import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { getReportTraining_action, getReportTrainingView_action } from '../../../actions/training_action/training_action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import Pagination from '../../../utils/pagination'
import { fill_action } from '../../../actions/master_action/checklist_action'
import Select from 'react-select';

import {
    clearLocalStorage, financialYearId,

    trainingStatusActionId, trainingEmployeeActionId, downloadReport,
    Firm_wise_training_report,
    Employee_wise_training_report,
    Training_report
} from '../../../utils/constants'
import * as XLSX from 'xlsx';
import { getModuleDownloadAccess, getModuleReadAccess, getReportDownloadAccess, getReportViewAccess } from '../../../utils/RoleAccess';

function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
}

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//   FinancialYearAction: () => dispatch(FinancialYearAction())
// })

/* 
 * mapStateToProps
*/
// const mapStateToProps = state => {
//   return {
//     loginData: state,
//   };
// }

const customStyles = {
    // control: (provided) => ({
    //     ...provided,
    //     height: '10px'
    // }),
    control: (provided) => ({
        ...provided,
        // height: '10px',
        borderColor: "#c9c9c9", // Blue border color
        boxShadow: "none",
        "&:hover": {
            borderColor: "#80bdff", // Blue border color on hover
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        borderRadius: "14px",
        padding: "4px",
        backgroundColor: "#00BCD4", // Blue background color for selected value
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: "white", // Text color for selected value
        fontSize: "12px",
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: "white",
        "&:hover": {
            backgroundColor: "#00BCD4",
            color: "#fff", // Blue border color on hover
        }, // Remove icon color for selected value
    }),
};
class EmployeeTrainingReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            finYear: '',
            finYearList: [],
            employeeId: '',
            employeeList: [],
            statusId: '',
            statusList: [],
            selectedEmployeeIdList: [],
            isOpen: false,
            isLoading: false,
            isSearchTab: false,
            listData: [],
            columnArray: [],
            rowData: [],
            empTrainingList: [],
            currentPage: 1,
            tablePerPage: 10,
        }
        this.handleClick = this.handleClick.bind(this)
        this.getReport = this.getReport.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.getReportView = this.getReportView.bind(this)
        this.getReportViewList = this.getReportViewList.bind(this)
        this.updateItem = this.updateItem.bind(this)

    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }


    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.handleSearch()
        }
    }

    handleClick(event) {
        let listid = Number(event.target.id);
        this.setState({
            currentPage: listid
        });
    }
    getReport() {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .getReportTraining_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false, currentPage: 1 })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }
    // financial list default
    componentDidMount = () => {
        console.log(" localStorage.getItem('RoleId') ", localStorage.getItem('RoleId'))
        this.setState({
            isAccessToView: getReportViewAccess(Employee_wise_training_report),
        })
        // clearLocalStorage("financialYear")
        // document.addEventListener('click', this.handleInputClick);

        // document.addEventListener('keydown', this.handleTabKeyPress);
        this.getFillList(financialYearId)
        this.getFillList(trainingEmployeeActionId)
        this.getFillList(trainingStatusActionId)

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


   
  handleTabKeyPress = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
      const focusedElement = document.activeElement;
      const { previouslyFocusedElement } = this.state;

      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }

      if (focusedElement.classList.contains('form-control') ||
        focusedElement.classList.contains('js-example-basic-single') ||
        focusedElement.classList.contains('example-custom-input')) {
        focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
        this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
      }
    }
  };


  handleInputClick = (event) => {
    const { previouslyFocusedElement } = this.state;
    const clickedElement = event.target;

    if (previouslyFocusedElement) {
      previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    }

    if (clickedElement.classList.contains('form-control') ||
      clickedElement.classList.contains('js-example-basic-single') ||
      clickedElement.classList.contains('example-custom-input')) {
      clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
      this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
    } else {
      this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
    }
  };

    // get fill List dropdown  
    async getFillList(value, reference) {
        const { is_add } = this.state
        this.setState({ isLoading: true })
        await this.props
            .fill_action(value, reference)
            .then((data) => {
                if (data.error != 1) {
                    if (value === financialYearId) {
                        this.setState({ finYearList: data.data, isLoading: false })
                    }
                    if (value == trainingStatusActionId) {
                        this.setState({ statusList: data.data, isLoading: false })
                    }
                    if (value == trainingEmployeeActionId) {
                        console.log("trainingEmployeeActionId", data.data)
                        var list = []
                        data.data.map((obj) => {
                            list.push({
                                'value': obj.EmployeeID,
                                'label': obj.Employee
                            });
                        })
                        console.log(list)
                        var list1 = []
                        list.map((obj) => {
                            var name = localStorage.getItem("UserName");
                            if (name.trim() === obj.label.trim()) {
                                console.log("Condition met");
                                list1.push({
                                    'value': obj.value,
                                    'label': obj.label
                                });
                            }
                        })
                        console.log("90099", list1);
                        this.setState({ employeeList: list, isLoading: false, selectedEmployeeIdList: list1 })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }


    formatData = (data) => {
        return data.map(item => ({
            ...item,
            // Date: item.Date ? moment(item.Date).format('DD/MM/YYYY HH:mm:ss') : '',
            Training_Date: item.Training_Date ? moment(item.Training_Date,'DD-MMM-YYYY').format('DD/MM/YYYY') : '',
            // LeaveToDate: item.LeaveToDate ? moment(item.LeaveToDate).format('DD/MM/YYYY HH:mm:ss') : '',
            // ApprovedDate: item.ApprovedDate ? moment(item.ApprovedDate).format('DD/MM/YYYY HH:mm:ss') : ''
        }));
    }

    generateExcel = () => {
        const { listData } = this.state;
        console.log('data generateExcel: ', listData);

        // Prepare data for Excel (xlsx) file
        const worksheet = XLSX.utils.json_to_sheet(listData);

        // Calculate the maximum width for each column
        const columnWidths = listData.reduce((acc, row) => {
            Object.keys(row).forEach((key, idx) => {
                const value = row[key] ? row[key].toString() : '';
                acc[idx] = Math.max(acc[idx] || 10, value.length);
            });
            return acc;
        }, []).map(width => ({ wch: width }));

        worksheet['!cols'] = columnWidths;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate a download link
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'EmployeeTrainingReport.xlsx';
        a.click();
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 100);
    };

    updateItem(item) {
        this.setState({ currentPage: item });
      }

    getReportViewList() {
        const { selectedEmployeeIdList, statusId, finYear, rowData, listData, columnArray, empTrainingList } = this.state
        const employeedata1 = selectedEmployeeIdList.map(employee => employee.value).join(',');
        var isFollow = false
        if (finYear == '') {
            toast.error('Please select Financial year', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
            isFollow = false
            return;

        }
        else{
            isFollow = true
        }
        if(employeedata1 == '') {
            toast.error('Please select employee', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
            isFollow = false
            return;
        }
        else{
            isFollow = true
        }
       
        if(isFollow) {
            this.setState({ isLoading: true })
            const employeedata = selectedEmployeeIdList.map(employee => employee.value).join(',');
            console.log(employeedata);
            console.log(finYear, statusId)
            this.props
                .getReportTrainingView_action(finYear, employeedata, statusId, 2)
                .then((data) => {
                    if (data != null) {
                        this.setState({ isLoading: false, empTrainingList: data.data })
                        console.log('Report getReportViewList empTrainingList: ', data.data);
                        if (data.data.length == 0) {
                            toast.error('Record not found', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }

                    } else {
                        this.setState({ isLoading: false })
                        toast.error('Something went wrong...!', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ isLoading: false })
                });
        }
    }

    getReportView() {
        const { selectedEmployeeIdList, statusId, finYear, rowData, listData, columnArray } = this.state
        const employeedata1 = selectedEmployeeIdList.map(employee => employee.value).join(',');
        var isFollow = false
        if (finYear == '') {
            toast.error('Please select Financial year', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
            isFollow = false
            return;

        }
        else{
            isFollow = true
        }
        if(employeedata1 == '') {
            toast.error('Please select employee', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
            isFollow = false
            return;
        }
        else{
            isFollow = true
        }
       
        if(isFollow) {
            this.setState({ isLoading: true })
            const employeedata = selectedEmployeeIdList.map(employee => employee.value).join(',');
            console.log(employeedata);
            console.log(finYear, statusId)
            this.props
                .getReportTrainingView_action(finYear, employeedata, statusId, 2)
                .then((data) => {
                    if (data != null) {
                        this.setState({ isLoading: false })
                        console.log('Report data : ', data.data);
                        if (data.data.length == 0) {
                            toast.error('Record not found', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        } else {
                            data.data.map((data, index) => {
                                if (index == 0) {
                                    Object.keys(data).map((key, index) => {
                                        columnArray.push(key)
                                    })
                                }
                            })
                            // for row data
                            data.data.map((data, index) => {
                                rowData.push(data)
                            })
                            console.log('rowData data : ', rowData);
                            console.log('columnArray data : ', columnArray);
                            const formattedData = this.formatData(data.data);
                            this.setState({ listData: formattedData, rowData: rowData, columnArray: columnArray, dataFetched: true })

                            setTimeout(() => {
                                this.generateExcel();
                            }, 200);
                        }

                        //
                    } else {
                        this.setState({ isLoading: false })
                        toast.error('There is some problem to download excel. Please try again later!', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ isLoading: false })
                });
        }

    }





    // download audit plane...............
    handleDownload(e) {
        e.preventDefault()
        const { selectedEmployeeIdList, statusId, finYear } = this.state

        const employeedata1 = selectedEmployeeIdList.map(employee => employee.value).join(',');
        var isFollow = false
        if (finYear == '') {
            toast.error('Please select Financial year', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
            isFollow = false
            return;

        }
        else{
            isFollow = true
        }
        if(employeedata1 == '') {
            toast.error('Please select employee', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
            isFollow = false
            return;
        }
        else{
            isFollow = true
        }
        
        if(isFollow) {
            this.setState({ isLoading: true })
            const employeedata = selectedEmployeeIdList.map(employee => employee.value).join(',');
            console.log(employeedata);
            console.log(finYear, statusId)
            this.props
                .getReportTraining_action(finYear, employeedata, statusId, 2)
                .then((data) => {
                    if (data != null) {
                        this.setState({ isLoading: false })
                        console.log('Report data : ', data);
                        var url = `${downloadReport}?SessionIdentifier=${data}&Export=Yes`;
                        console.log('Report download url : ', url);
                        const win = window.location.replace(url, '_blank');
                        if (win != null) {
                            win.focus();
                        }
                    } else {
                        this.setState({ isLoading: false })
                        toast.error('There is some problem to download report. Please try again later!', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ isLoading: false })
                });
        }

    }


    //on select fin year
    onSelectFinYearDropdown(e) {
        this.setState({ finYear: e.target.value })
    }

    record(empTrainingList) {
        console.log('empTrainingList: ', empTrainingList);
        return (
            <div class="table-responsive tbs4 common-table mt-2">
                <table className="table table-bordered tb-action du-table">
                    <thead>
                        <tr>
                            <th style={{ width: '30px'}}>SR No </th>
                            <th style={{ width: '100px' }} >Employee Name </th>
                            <th style={{ width: '150px' }}>Training Date</th>
                            <th style={{ width: '150px' }}>Hours</th>
                            <th style={{ width: '100px' }} >Course Type</th>
                            <th style={{ width: '250px' }} >Course Details</th>
                            <th style={{ width: '100px' }} >Approved By</th>
                            <th style={{ width: '100px' }} >Approved Date</th>
                            <th style={{ width: '100px' }} >Status</th>

                        </tr>
                    </thead>
                    <tbody>
                        {empTrainingList.length > 0 ?
                            empTrainingList.map((data, index) => (

                                <tr>
                                    <td style={{ width: '30px', textAlign: 'left' }}>{data.SR_No}</td>
                                    <td style={{ width: '100px', textAlign: 'left' }}>{data.Employee_Name}</td>
                                    <td style={{ textAlign: 'left' }}>{data.Training_Date != null && moment(data.Training_Date, 'DD-MMM-YYYY').format('DD-MMM-YYYY')}</td>
                                    <td style={{ textAlign: 'left' }}>{data.Hours}</td>
                                    <td style={{ textAlign: 'left' }}>{data.Course_Type}</td>
                                    <td style={{width: '250px' , textAlign: 'left' }}>{data.Course_Details}</td>
                                    <td style={{ textAlign: 'left' }}>{data.Approved_By}</td>
                                    <td style={{ textAlign: 'left' }}>{data.Approved_Date != null && moment(data.Approved_Date, 'DD-MMM-YYYY').format('DD-MMM-YYYY')}</td>
                                    <td style={{ textAlign: 'left' }}>{data.Status}</td>

                                </tr>
                            )) : <> <tr><td colSpan={9} className='text-center'>Record not found</td></tr></>
                        }
                    </tbody>
                </table>
            </div>
        )
    }



    render() {

        const { isSearchTab, finYearList, finYear, isAccessToView, empTrainingList,
            tablePerPage, currentPage, employeeList, employeeId, selectedEmployeeIdList,
            statusId, statusList,
            isLoading, } = this.state
        const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = empTrainingList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(empTrainingList.length / recordsPerPage)



        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />


                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a> Employee Training</a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className='page-scroll-from-bot' style={{ height: 'calc(100vh - 130px)' }}>
                                        <div className="row">
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                            <div className="row pg-body grid-margin stretch-card d-flex m-0 mt-2">
                                                <div className="form-group col-md-6">
                                                    <label>
                                                        Financial Year<span className="required1">*</span>
                                                    </label>
                                                    <select
                                                        className="js-example-basic-single"
                                                        style={{ width: "100%" }}
                                                        value={finYear}
                                                        onChange={(e) => this.onSelectFinYearDropdown(e)}
                                                    >
                                                        <option value=''>Select</option>
                                                        {finYearList.map(item => (
                                                            <option
                                                                key={item.FinYearID}
                                                                value={item.FinYearID}>
                                                                {item.FinancialYear}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {/* <div className="form-group col-md-3">
                                                <label>Employee </label>
                                                <select className="js-example-basic-single"
                                                    style={{ width: '100%' }} value={employeeId}
                                                    onChange={e => this.setState({ employeeId: e.target.value })}>
                                                     <option value={''}>Select</option>
                                                    {employeeList.length > 0 && employeeList.map(item => (
                                                        <option
                                                            key={item.EmployeeID}
                                                            value={item.EmployeeID}>
                                                            {item.Employee}
                                                        </option>
                                                    ))}

                                                </select>
                                            </div> */}
                                                <div class="row mt-2">
                                                    <div className="col-6 pr-0 form-group">
                                                        <label>Employee
                                                            {/* <span class="mendatory_class">*</span> */}
                                                        </label>
                                                        <Select style={{ Height: '10px !important' }}
                                                            isMulti
                                                            isDisabled={localStorage.getItem('RoleId') != '1'}
                                                            // isClearable={statusId !== '0'}
                                                            // styles={statusId == '0' ? deletedStyles : customStyles}
                                                            styles={customStyles}
                                                            value={selectedEmployeeIdList}
                                                            onChange={e => this.setState({ selectedEmployeeIdList: e })}
                                                            options={employeeList} />
                                                    </div>

                                                </div>
                                                <div className="form-group col-md-6 mt-2">
                                                    <label>Status</label>
                                                    <select className="js-example-basic-single"
                                                        style={{ width: '100%' }}
                                                        value={this.state.statusId}
                                                        onChange={(e) => this.setState({ statusId: e.target.value })}>
                                                        <option value={''}>Select</option>
                                                        {statusList.length > 0 && statusList.map(item => (
                                                            <option
                                                                key={item.Code}
                                                                value={item.Code}>
                                                                {item.Description}
                                                            </option>
                                                        ))}

                                                    </select>
                                                </div>
                                                <div className="form-group col-12 mt-2">
                                                    {getReportDownloadAccess(Employee_wise_training_report) && <button
                                                        type="button"
                                                        className="btn btn-print-audit footer-btn-inner mr-1 mt-1" style={{ float: 'left' }}
                                                        onClick={e => this.handleDownload(e)}
                                                    >
                                                        Download
                                                    </button>}
                                                    <button
                                                        type="button"
                                                        className="btn btn-print-audit footer-btn-inner mr-1 mt-1" style={{ float: 'left' }}
                                                        onClick={e => this.getReportViewList(e)}
                                                    >
                                                        View
                                                    </button>
                                                    {/* <div className="text-center mx-3"> */}
                                                    {getReportDownloadAccess(Employee_wise_training_report) && <button type="button"
                                                        className="btn btn-outline-primary fil-btn p-2 w-auto mr-1 mt-1" style={{ float: 'left' }}
                                                        onClick={this.getReportView}
                                                    >
                                                        Export To Excel
                                                    </button>}
                                                    {/* </div> */}
                                                </div>

                                                {empTrainingList.length > 0 ? this.record(currentRecords) : <></>}
                                                {empTrainingList.length > 0 ? <Pagination
                                                    nPages={nPages}
                                                    currentPage={currentPage}
                                                    setCurrentPage={this.updateItem} /> : <></>}

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>


                <ToastContainer />

            </>
        );
    }
}

export default withRouter(connect(null, {
    getReportTraining_action, fill_action, getReportTrainingView_action
})(EmployeeTrainingReport));