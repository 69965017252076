import React, { Component, forwardRef } from "react";
import Loader from "../../../utils/Loader";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../../../utils/pagination'
import { addressTypeList_action, deleteAddressType_action, addAddressType_action, updateAddressType_action, addressTypeDetail_action } from '../../../actions/master_action/address_type_action'
import { clearLocalStorage } from '../../../utils/constants'
import Modal from "react-modal";

class AddressType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            isAccessToView: true,
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage : 10,
            pageSizeInput: "10", 
            status: ' ',
            statusATLS: '',
            addressTypeName: '',
            addressTypeNameSearch :"",
            is_add: false,
            is_update: false,
            addressTypeNameATLS: '',
            AddressTypeID: '',
            isOpenAU: true,
            addressTypeList: [],
            addressTypeDeleteId: '',
            isSearchTab: false,
        }
        this.record = this.record.bind(this)
        this.getAddressTypeList = this.getAddressTypeList.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);  
		
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        // this.handleAddAddressType = this.handleAddAddressType.bind(this)
        // this.handleUpdateAddressType = this.handleUpdateAddressType.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.toggleUpdate = this.toggleUpdate.bind(this)
        this.toggleAdd = this.toggleAdd.bind(this)
        this.toggleModalAU = this.toggleModalAU.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.getAddressTypeDetails = this.getAddressTypeDetails.bind(this)
    }

    //address type list default
    componentDidMount = () => {
        this.setState({
            isAccessToView: true,
            addressTypeNameATLS: localStorage.getItem('addressTypeNameATLS'),
            statusATLS: localStorage.getItem('statusHLS'),

            addressTypeName: localStorage.getItem('addressTypeNameATLS') != '' ? localStorage.getItem('holidayDateHLS') : '',
            status: localStorage.getItem('statusATLS') != 'Select' ? localStorage.getItem('statusATLS') : 'Select',
        })
        clearLocalStorage("addressType")
        setTimeout(() => {
            localStorage.getItem('statusATLS') == 'Select' ? this.getAddressTypeList() : this.handleSearch()
        }, 100);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
          const focusedElement = document.activeElement;
          const { previouslyFocusedElement } = this.state;
    
          if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
          }
    
          if (focusedElement.classList.contains('form-control') ||
            focusedElement.classList.contains('js-example-basic-single') ||
            focusedElement.classList.contains('example-custom-input')) {
            focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
            this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
          }
        }
      };
    
    
      handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;
    
        if (previouslyFocusedElement) {
          previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }
    
        if (clickedElement.classList.contains('form-control') ||
          clickedElement.classList.contains('js-example-basic-single') ||
          clickedElement.classList.contains('example-custom-input')) {
          clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
          this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
          this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
      };
      
    //get list
    getAddressTypeList() {
        const { addressTypeName, addressTypeNameSearch, status } = this.state
        this.setState({ isLoading: true })
        this.props
            .addressTypeList_action(addressTypeNameSearch, 1)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        addressTypeList: data.data,
                        isLoading: false,
                        currentPage: 1
                    })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    //  record
    record(addressTypeList) {

        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ textAlign: 'center' }} >Address Type</th>
                        <th style={{ width: '180px' }} >Active</th>
                    </tr>
                </thead>
                <tbody>
                    {addressTypeList.length > 0 ? (
                        addressTypeList.map((data, i) => (
                            <tr key={data.AddressTypeID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.toggleUpdate(data.AddressTypeID)} /></a>

                                        {data.IsActive === true ? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.AddressTypeID)} /></span></a>
                                            : <></>}

                                    </div>
                                </td>
                                <td style={{ textAlign: 'center' }}>{data.AddressType}</td>
                                <td>{data.IsActive == true ? "Active" : "Inactive"}</td>
                            </tr>
                        ))
                    ) : (<tr><td colSpan={5} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }

    //search
    handleSearch() {
        const { addressTypeName, addressTypeNameSearch,status } = this.state
        clearLocalStorage("addressType")
        this.setState({ isLoading: true })
        this.props
            .addressTypeList_action(addressTypeNameSearch.trim(), status === 'Select' ? 1 : parseInt(status))
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ addressTypeList: data.data, isLoading: false, currentPage: 1 })
                    console.log(data)
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
        localStorage.setItem("addressTypeNameATLS", addressTypeName)
        localStorage.setItem("statusATLS", status === 'Select' ? 1 : status)
    }
    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }


    //toggal for delete
    toggleModal(AddressTypeID) {
        const { isOpen, clientDeleteId } = this.state
        this.setState({ isOpen: !isOpen })
        if (AddressTypeID == '') {
            return
        }
        else {
            this.setState({ addressTypeDeleteId: AddressTypeID })
        }
    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }
    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
          this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
      };
      
      handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
          this.setState({ pageSizeInput: input });
        }
      };
    // //add address type
    // handleAddAddressType() {
    //     this.props.navigate('/addressTypeAU', { state: { is_add: true } })
    // }

    // //update addres type
    // handleUpdateAddressType(AddressTypeID) {
    //     this.props.navigate('/addressTypeAU', { state: { is_update: true, AddressTypeID: AddressTypeID } })
    // }

    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.handleSearch()
        }
    }

    handleReset() {
        this.setState({
            AddressTypeID: '',
            addressTypeName: '',
            addressTypeNameSearch :"",
        })
    }

    //clear value
    clearValue() {
        this.setState({
            currentPage: 1,
            status: ' ',
            statusATLS: 'Select',
            addressTypeName: '',
            addressTypeNameSearch: "",
            addressTypeNameATLS: '',
            addressTypeList: [],
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getAddressTypeList()
        }, 300);
    }

    //delete handle
    handleDelete(e, AddressTypeID) {
        e.preventDefault()
        var id = AddressTypeID
        this.setState({ isLoading: true })
        this.props
            .deleteAddressType_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isOpen: !this.state.isOpen, isLoading: false })
                    this.getAddressTypeList()
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    toggleAdd() {
        const { is_add } = this.state
        this.setState({ is_add: true, is_update: false, })
        this.handleReset()
        this.toggleModalAU()
    }

    toggleUpdate(addressType) {
        const { is_update } = this.state
        this.handleReset()
        this.setState({ is_update: true, is_add : false, AddressTypeID: addressType })
        this.toggleModalAU()
    }

    //toggle/reset
    toggleModalAU(addressType) {
        const { isOpenAU } = this.state
        this.getAddressTypeDetails()
        this.setState({ isOpenAU: !isOpenAU })
    }

    //add Address
    handleAdd(e) {
        e.preventDefault()
        const { addressTypeName } = this.state
        if (addressTypeName != "") {
            this.setState({ isLoading: true })
            this.props
                .addAddressType_action(addressTypeName.trim())
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false })
                        this.toggleModalAU()
                        this.clearValue()
                        // setTimeout(() => {
                        //     this.getAddressTypeList()
                        // }, 500);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //update Address
    handleUpdate(e) {
        e.preventDefault()
        const { AddressTypeID, addressTypeName ,isOpenAU} = this.state
        const id = AddressTypeID

        if (id != "" && addressTypeName != "") {
            this.setState({ isLoading: true })
            this.props
                .updateAddressType_action(id, addressTypeName.trim())
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false , isOpenAU: !isOpenAU, addressTypeName : "" })
                        this.clearValue()
                        // this.toggleModalAU()
                        this.getAddressTypeDetails()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //get details
    getAddressTypeDetails() {
        console.log(this.state.AddressTypeID)

        setTimeout(() => {
            var id = this.state.AddressTypeID
            this.props
                .addressTypeDetail_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            addressTypeName: data.data[0]['AddressType'],
                            status: data.data[0]['IsActive']
                        })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 200);
    }


    render() {
        const {isSearchTab, isLoading, isAccessToView, addressTypeDeleteId, addressTypeList,addressTypeNameSearch,  addressTypeName, isOpen, isOpenAU, is_add, is_update, status } = this.state
        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = addressTypeList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(addressTypeList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />


                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>Address Type</a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className='page-space-from-bottom'>
                                    <div className="row">
                                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                        <div className="col-md-12 pg-body grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body pr-2">


                                                    <div className="accordion accordion-flush " id="accordionFlushExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="flush-headingOne">
                                                            <button className="accordion-button collapsed list-search" 
                                                            onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse"
                                                             data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} 
                                                             aria-controls="flush-collapseOne">
                                                                    <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                </button>
                                                            </h2>
                                                            { isSearchTab ? <div id="flush-collapseOne" 
                                                            className={isSearchTab ? "collapse show" : "collapsing hide"}
                                                             aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                                <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                    <div className="d-flex flex-wrap row">

                                                                        <div className="form-group col-md-3">
                                                                            <label>Address Type</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder=""
                                                                                value={addressTypeNameSearch}
                                                                                onChange={(e) => this.setState({ addressTypeNameSearch: e.target.value })}
                                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                                required />
                                                                        </div>

                                                                        <div className="form-group col-md-3">
                                                                            <label>Status</label>
                                                                            <select className="js-example-basic-single" style={{ width: '100%' }}
                                                                                value={status}
                                                                                onChange={(e) => this.setState({ status: e.target.value })}>
                                                                                 <option value={''}>Select</option>
                                                                                <option value={1}>Active</option>
                                                                                <option value={0}>Inactive</option>
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group col-md-3 filter-btn-col p-0">
                                      <label></label>
                                      <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={e => this.handleSearch()}> Search</button>
                                      <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3" onClick={e => this.clearValue()}> Clear</button>
                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div> : <></>} 
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-12 mt-1">
                                                            <button type="button" className="btn btn-Add rounded-1 mb-1 px-3 float-right" onClick={this.toggleAdd}>Add</button>
                                                            {/* button */}
                                                            <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                                {this.record(currentRecords)}
                                                                {addressTypeList.length > 0 ? <Pagination
                                                                    nPages={nPages}
                                                                    currentPage={currentPage}
                                                                    setCurrentPage={this.updateItem}
                                                                    recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                     setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                       totalRecords={addressTypeList.length}
                                                                     /> : <></>}
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        ) : (<h5 style={{ color: 'red', alignSelf: 'center', margin: 'auto' }}>You do not have access to view this form!</h5>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>
                <ToastContainer />
                {/* // modal for delete */}
                {/* <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, addressTypeDeleteId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal> */}


                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
          <div className="modal-dialog custom_modal_dialog">
            <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
              <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                </div>
              </div>
              <div class="modal-footer p-1">
                  <div class="form-group text-right">
                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1"  onClick={(e) => this.handleDelete(e, addressTypeDeleteId)}> Yes</a>
                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                </div>
              </div>
            </div>
          </div>
        </Modal>

                {/* modal for AU */}
                <Modal isOpen={!isOpenAU} onRequestClose={this.toggleModalAU}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                {is_add == true ? <h4 class="modal-title modal_title_text">Add Address Type</h4> : <h4 class="modal-title modal_title_text">Update Address Type</h4>}
                                {is_add == true ? <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleAdd} ><span aria-hidden="true" class="pop-cancel">&times;</span></button> : <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.toggleUpdate()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>}
                            </div>
                            <div class="modal-body p-3">
                                <div class="d-flex flex-wrap row mt-2">
                                    <div class="d-flex flex-wrap row">
                                        <div className="form-group col-md-3">
                                            <label>Address Type<span class="required1">*</span></label>
                                            </div>
                                            <div className="form-group col-9 pr-0">
                                            <input
                                                disabled={!status}
                                                type="text"
                                                className="form-control"
                                                placeholder='Name'
                                                value={this.state.addressTypeName}
                                                onChange={(e) => this.setState({ addressTypeName: e.target.value })}
                                                required
                                            />
                                        </div>
                                        </div>
                                </div>
                            </div>

                            <div class="modal-footer p-1">
                                    <div class="form-group text-right mod-fot">
                                        {is_add == true ? <a class="btn save_btn del-pop-save px-3" onClick={this.handleAdd}> Save</a> : status  && <a class="btn save_btn del-pop-save" onClick={this.handleUpdate}> Update</a>}
                                        {is_add == true ? <a class="btn btn-outline-danger px-3" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.handleReset}>Reset</a> : <></>}
                                        {is_add == true ? <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleAdd}>Cancel</a> : <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.toggleUpdate()}>Cancel</a>}
                                    </div>
                            </div>
                        </div>
                        </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(connect(null, { addressTypeList_action, deleteAddressType_action, addAddressType_action, addressTypeDetail_action, updateAddressType_action })(AddressType))