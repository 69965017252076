import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import $ from 'jquery';
import Modal from "react-modal";
import { withRouter } from '../utils/withRouter';
import { getModuleReadAccess, getReportViewAccess } from '../utils/RoleAccess';
import { Change_Password, Client_and_Engagement_wise_detailed_Report, Client_Engagement, Client_wise_detailed_Report, Client_wise_expense_report, Close_Engagement, Dashboard, Employee_and_Client_wise_Summary_Report, Employee_wise_expense_report, Employee_wise_Leave_Report, Employee_wise_training_report, Engagement, Engagement_Allocation, Expense, Firm_wise_training_report, Leave, Leave_Approval, Leave_Report, Master, MCQ, MCQ_Chapter, MCQ_Setting, MCQ_Setup, MCQ_Take_Test, Reports, Review_Checklist, Time_Sheet_Client_wise_report, Time_Sheet_Detailed_Report, Time_Sheet_Employee_wise_report, Timesheet, Timesheet_and_Expense, Timesheet_and_Expense_Approval, Training, Training_Approval, Training_report, Traning_Entry, Update_Checklist, UserProfile } from '../utils/constants';


const Drawer = ({ head, userId }) => {
  const history = useNavigate();
  const navigate = useNavigate();
  const location = useLocation();
  const drawerRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false)
  const [isMaster, setIsMaster] = useState(false)
  const [isEngagement, setIsEngagement] = useState(false)
  const [isTimesheet, setIsTimesheet] = useState(false)
  const [isTraining, setIsTraining] = useState(false)
  const [isTrainingReport, setIsTrainingReport] = useState(false)
  const [isReports, setIsReports] = useState(false)
  const [isMCQ, setIsMCQ] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isToggle, setToggle] = useState(false);


  //toggle model for logout.................................
  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const loadScripts = () => {
    // This array contains all the files/CDNs 
    const dynamicScripts = [
      'js/jquery.sticky.js',
      'js/metisMenu.min.js',
      'js/metisMenu-active.js',
      'js/main.js'
    ];

    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  const logout = () => {
    localStorage.clear()
    navigate('/')
  }

  // Click event handler
  const handleClick = (event, path) => {

    // if (drawerRef.current && drawerRef.current.contains(event.target)) {
    const targetPath = location.pathname; // Get path from data attribute
    // console.log(`Clicked item path: ${targetPath}`);

    //
    if (targetPath == '/startTest') {
      console.log(`Clicked item path startTest: ${targetPath}`);
      const confirmLeave = window.confirm("Do you really want to quit this test?");

      if (confirmLeave) {
        console.log("You pressed OK!");
        // Optionally, navigate away or perform other actions
        // document.getElementById('confirmDialog').style.display = 'none';
        // navigate('/finYearList')
        // <Link to='/finYearList'></Link>
        navigate(path)

      } else {
        console.log("You canceled!");
      }
    }
    else {
      navigate(path)

    }
  };

  useEffect(() => {
    // loadScripts();
    const targetPath = location.pathname; // Get path from data attribute
    console.log(`Clicked item path: ${targetPath}`);
    if (targetPath == '/changePassword' || targetPath == '/dashboard') {
      allSetFalse()
    }
    setHeadingClickedData()
    // <div>Current URL: {location.pathname}</div>
    // Add event listener to the drawer container
    const drawerElement = drawerRef.current;
    if (drawerElement) {
      //  drawerElement.addEventListener('click', handleClick);
    }

    // Clean up event listener
    return () => {
      if (drawerElement) {
        //  drawerElement.removeEventListener('click', handleClick);
      }
    };
  }, []);

  const setHeadingClickedData = () => {
    setIsEngagement(localStorage.getItem('isEngagement') === 'true')
    setIsTimesheet(localStorage.getItem('isTimesheet') === 'true')
    setIsTraining(localStorage.getItem('isTraining') === 'true')
    setIsReports(localStorage.getItem('isReports') === 'true')
    setIsMCQ(localStorage.getItem('isMCQ') === 'true')
    setIsMaster(localStorage.getItem('isMaster') === 'true')

  }


  const onClickEng = () => {
    setIsEngagement(!isEngagement)
    localStorage.setItem('isEngagement', !isEngagement)
    localStorage.setItem('isTimesheet', false)
    localStorage.setItem('isTraining', false)
    localStorage.setItem('isReports', false)
    localStorage.setItem('isMCQ', false)
    localStorage.setItem('isMaster', false)
  }

  const onClickTimesheet = () => {
    setIsTimesheet(!isTimesheet)
    localStorage.setItem('isTimesheet', !isTimesheet)
    localStorage.setItem('isEngagement', false)
    localStorage.setItem('isTraining', false)
    localStorage.setItem('isReports', false)
    localStorage.setItem('isMCQ', false)
    localStorage.setItem('isMaster', false)

  }

  const onClickTraining = () => {
    setIsTraining(!isTraining)
    localStorage.setItem('isTraining', !isTraining)
    localStorage.setItem('isEngagement', false)
    localStorage.setItem('isTimesheet', false)
    localStorage.setItem('isReports', false)
    localStorage.setItem('isMCQ', false)
    localStorage.setItem('isMaster', false)
  }

  const onClickReports = () => {
    setIsReports(!isReports)
    localStorage.setItem('isReports', !isReports)
    localStorage.setItem('isEngagement', false)
    localStorage.setItem('isTimesheet', false)
    localStorage.setItem('isTraining', false)
    localStorage.setItem('isMCQ', false)
    localStorage.setItem('isMaster', false)
  }

  const onClickMCQ = () => {
    setIsMCQ(!isMCQ)
    localStorage.setItem('isMCQ', !isMCQ)
    localStorage.setItem('isEngagement', false)
    localStorage.setItem('isTimesheet', false)
    localStorage.setItem('isTraining', false)
    localStorage.setItem('isReports', false)
    localStorage.setItem('isMaster', false)
  }

  const onClickMaster = () => {
    setIsMaster(!isMaster)
    localStorage.setItem('isMaster', !isMaster)
    localStorage.setItem('isEngagement', false)
    localStorage.setItem('isTimesheet', false)
    localStorage.setItem('isTraining', false)
    localStorage.setItem('isReports', false)
    localStorage.setItem('isMCQ', false)
  }

  const allSetFalse = () => {
    localStorage.setItem('isMaster', false)
    localStorage.setItem('isEngagement', false)
    localStorage.setItem('isTimesheet', false)
    localStorage.setItem('isTraining', false)
    localStorage.setItem('isReports', false)
    localStorage.setItem('isMCQ', false)
  }

  const onRedirect = (path) => {
    history.push({ pathname: path, state: { 'isSearchClear': true } });
  }


  const togglebtn = {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  };

  const lftscroll = {
    display: "block",
  };

  const lftscrollhide = {
    display: "none",
  };

  const toggleBtnStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  };



  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={e => toggleModal()}>
        <div className="modal-dialog custom_modal_dialog" >
          <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
            <div class="modal-header pt-1 bg-danger">
              <h4 class="modal-title modal_title_text">Confirm Logout</h4>
              {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
              <button type="button " class="close pop-close" data-dismiss="modal" onClick={e => toggleModal()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <h3 class="pop_label pt-3 pb-2">Are you sure you want to log out?</h3>
                </div>
              </div>
            </div>

            <div class="modal-footer p-1">
              <div class="form-group text-right">
                <a class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={logout}> Yes</a>
                <a class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={toggleModal}> No</a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Left side menu */}
      <nav className="sidebar lft-sidebar sidebar-offcanvas" id="sidebar3">
        <div ref={drawerRef} className="lft-scroll">
          <ul className="nav navs-top">
            {getModuleReadAccess(Dashboard) ? (
              <li className="nav-item" onClick={(e) => handleClick(e, '/dashboard')} >
                {/* <Link to='/dashboard'> */}
                <a className="nav-link ">
                  <i className="fa fa-tachometer" style={{ marginRight: 8, fontSize: 17 }} />
                  <span className="menu-title">Dashboard</span>
                </a>
                {/* </Link> */}
              </li>
            ) : (null)}
            {getModuleReadAccess(Master) ? (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="collapse"
                    href="#masters"
                    aria-expanded={isMaster ? 'true' : 'false'}
                    aria-controls="masters"
                    onClick={onClickMaster}
                  >
                    <i
                      className="fa fa-database"
                      style={{ marginRight: 8, fontSize: 17 }}
                    />
                    <span className="menu-title">Master</span>
                    <i className="menu-arrow" />
                  </a>
                  {isMaster ? <div className={isMaster ? "collapse show" : "collapsing hide"} id="masters">
                    <ul className="nav flex-column sub-menu ">
                      <li className="nav-item" onClick={(e) => handleClick(e, '/bloodGroup')}>
                        {/* <Link to='/bloodGroup'> */}
                        <a className="nav-link">
                          <i className="fa fa-angle-right" /> &nbsp;                   BloodGroup {" "}</a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/checkList')}>
                        {/* <Link to='/checkList'> */}
                        <a className="nav-link">
                          <i className="fa fa-angle-right" /> &nbsp;                       Checklist
                        </a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/checkListTemplate')}>
                        {/* <Link to='/checkListTemplate'> */}
                        <a className="nav-link">
                          <i className="fa fa-angle-right" /> &nbsp;                   Checklist Template {" "}
                        </a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/clientList')}>
                        {/* <Link to='/clientList'> */}
                        <a className="nav-link" >
                          <i className="fa fa-angle-right" /> &nbsp;                   Client{" "}
                        </a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/clientCategoryListAU')}>
                        {/* <Link to='/clientCategoryListAU'> */}
                        <a className="nav-link" >
                          <i className="fa fa-angle-right" /> &nbsp;                   Client Category{" "}
                        </a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/clientGroup')}>
                        {/* <Link to='/clientGroup'> */}
                        <a className="nav-link">
                          <i className="fa fa-angle-right" /> &nbsp;                   Client Group {" "}</a>{" "}
                        {/* </Link> */}
                      </li>


                      <li className="nav-item" onClick={(e) => handleClick(e, '/department')}>
                        {/* <Link to='/department'> */}
                        <a className="nav-link">
                          <i className="fa fa-angle-right" /> &nbsp;                   Department {" "}</a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/designation')}>
                        {/* <Link to='/designation'> */}
                        <a className="nav-link">
                          <i className="fa fa-angle-right" /> &nbsp;                   Designation {" "}</a>{" "}
                        {/* </Link> */}
                      </li>

                      <li className="nav-item" onClick={(e) => handleClick(e, '/employeeList')}>
                        {/* <Link to='/employeeList'> */}
                        <a className="nav-link">
                          <i className="fa fa-angle-right" /> &nbsp;                   Employee {" "}</a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/engagementMaster')}>
                        {/* <Link to='/engagementMaster'> */}
                        <a className="nav-link" >
                          <i className="fa fa-angle-right" /> &nbsp;                   Engagement Master{" "}
                        </a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/EngagementRoleList')}>
                        {/* <Link to='/EngagementRoleList'> */}
                        <a className="nav-link" >
                          <i className="fa fa-angle-right" /> &nbsp;                   Engagement Role{" "}
                        </a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/expenseHeadListAU')}>
                        {/* <Link to='/expenseHeadListAU'> */}
                        <a className="nav-link" >
                          <i className="fa fa-angle-right" /> &nbsp;                   Expense Head{" "}
                        </a>{" "}
                        {/* </Link> */}
                      </li>

                      {/* // onClick={(e) => handleClick(e)} */}
                      <li className="nav-item" onClick={(e) => handleClick(e, '/finYearList')}  >
                        {/* <Link to='/finYearList'> */}
                        <a className="nav-link" >
                          <i className="fa fa-angle-right" /> &nbsp;                   Financial Year{" "}
                        </a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/genderListAU')}>
                        {/* <Link to='/genderListAU'> */}
                        <a className="nav-link" >
                          <i className="fa fa-angle-right" /> &nbsp;                   Gender{" "}
                        </a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/holidayListAU')}>
                        {/* <Link to='/holidayListAU'> */}
                        <a className="nav-link" >
                          <i className="fa fa-angle-right" /> &nbsp;                   Holiday{" "}
                        </a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/locationType')}>
                        {/* <Link to='/locationType'> */}
                        <a className="nav-link" >
                          <i className="fa fa-angle-right" /> &nbsp;                   Location Type{" "}
                        </a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/martialStatusListAU')}>
                        {/* <Link to='/martialStatusListAU'> */}
                        <a className="nav-link" >
                          <i className="fa fa-angle-right" /> &nbsp;                   Martial Status{" "}
                        </a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/prefixListAU')}>
                        {/* <Link to='/prefixListAU'> */}
                        <a className="nav-link" >
                          <i className="fa fa-angle-right" /> &nbsp;                   Prefix{" "}
                        </a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/taskType')}>
                        {/* <Link to='/taskType'> */}
                        <a className="nav-link">
                          <i className="fa fa-angle-right" /> &nbsp;                   TaskType {" "}</a>{" "}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item" onClick={(e) => handleClick(e, '/workingDaysList')}>
                        {/* <Link to='/workingDaysList'> */}
                        <a className="nav-link" >
                          <i className="fa fa-angle-right" /> &nbsp;                   Working Days{" "}
                        </a>{" "}
                        {/* </Link> */}
                      </li>
                      {/* <li className="nav-item">  <Link to='/addressTypeListAU'>
                      <a className="nav-link" >
                        <i className="fa fa-angle-right" /> &nbsp;                   Address Type{" "}
                      </a>{" "}</Link>
                    </li> */}
                      {/* <li className="nav-item">  <Link to='/managementType'>
                      <a className="nav-link" >
                        <i className="fa fa-angle-right" /> &nbsp;                   Management Type{" "}
                      </a>{" "}</Link>
                    </li> */}

                      {/* <li className="nav-item">  <Link to='/legalEntityListAU'>
                      <a className="nav-link" >
                        <i className="fa fa-angle-right" /> &nbsp;                   Legal Entity{" "}
                      </a>{" "}</Link>
                    </li> */}
                    </ul>
                  </div> : <></>}
                </li>
            ) : (null)}
            {getModuleReadAccess(Engagement) ? (
              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-toggle="collapse"
                  href="#Engagement"
                  aria-expanded={isEngagement ? 'true' : 'false'}
                  aria-controls="Engagement"
                  onClick={onClickEng}
                >
                  <i
                    className="fa fa-briefcase"
                    style={{ marginRight: 8, fontSize: 17 }}
                  />
                  <span className="menu-title">Engagement </span>
                  <i className="menu-arrow" />
                </a>
                {isEngagement ? <div className={isEngagement ? "collapse show" : "collapsing hide"} id="Engagement">
                  <ul className="nav flex-column sub-menu ">
                    {/* {localStorage.getItem('UserId') == '1' ? ( */}
                    {getModuleReadAccess(Engagement_Allocation) && <li className="nav-item" onClick={(e) => handleClick(e, '/engagementAllocation')}>
                      {/* <Link to='/engagementAllocation'> */}
                        <a className="nav-link">
                          <i className="fa fa-angle-right" /> &nbsp;                 Engagement allocation
                        </a>
                      {/* </Link> */}
                    </li>}
                    {/* ) : (null)} */}

                    {/*<li class="nav-item"><a class="nav-link" href="client_financial_year_approval.html"><i class="fa fa-angle-right"></ &nbsp;nt FY Approval</a></li>*/}
                    {getModuleReadAccess(Client_Engagement) && <li className="nav-item" onClick={(e) => handleClick(e, '/clientEngagement')}>
                      {/* <Link to='/clientEngagement'> */}
                        <a className="nav-link">
                          <i className="fa fa-angle-right" /> &nbsp;                 Client Engagement

                        </a>
                      {/* </Link> */}
                    </li>}
                    {getModuleReadAccess(Update_Checklist) && <li className="nav-item" onClick={(e) => handleClick(e, '/updateChecklist')}>
                      {/* <Link to='/updateChecklist'> */}
                        <a className="nav-link">
                          <i className="fa fa-angle-right" /> &nbsp;                 Update Checklist
                        </a>
                      {/* </Link> */}
                    </li>}
                    {getModuleReadAccess(Review_Checklist) && <li className="nav-item" onClick={(e) => handleClick(e, '/reviewChecklist')} >
                      {/* <Link to='/reviewChecklist'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Review Checklist
                      </a>
                      {/* </Link> */}
                    </li>}
                    {getModuleReadAccess(Close_Engagement) && <li className="nav-item" onClick={(e) => handleClick(e, '/closerChecklist')}>
                      {/* <Link to='/closerChecklist'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Close Engagement
                      </a>
                      {/* </Link> */}
                    </li>}
                  </ul>
                </div> : <></>}
              </li>
            ) : (null)}
            {/* // timesheet */}
            {getModuleReadAccess(Timesheet_and_Expense) ? (
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#timesheetMaster"
                  aria-expanded={isTimesheet ? 'true' : 'false'}
                  aria-controls="timesheetMaster"
                  onClick={onClickTimesheet}
                >
                  <i
                    className="fa fa-calendar"
                    style={{ marginRight: 8, fontSize: 17 }}
                  />
                  <span className="menu-title">Timesheet & Expense</span>
                  <i className="menu-arrow" />
                </a>
                {isTimesheet ? <div className={isTimesheet ? "collapse show" : "collapsing hide"} id="timesheetMaster">
                  <ul className="nav flex-column sub-menu ">
                    {getModuleReadAccess(Timesheet) && <li className="nav-item" onClick={(e) => handleClick(e, '/timeSheet')}>
                      {/* <Link to='/timeSheet'> */}
                      <a className="nav-link" >
                        <i className="fa fa-angle-right" /> &nbsp;                 Time sheet{" "}
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {getModuleReadAccess(Expense) && <li className="nav-item" onClick={(e) => handleClick(e, '/expense')}>
                      {/* <Link to='/expense'> */}
                      <a className="nav-link" >
                        <i className="fa fa-angle-right" /> &nbsp;                 Expense
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {/* {
                    localStorage.getItem('RoleId') == 4 ? */}
                    {getModuleReadAccess(Timesheet_and_Expense_Approval) && <li className="nav-item" onClick={(e) => handleClick(e, '/approval')} style={{ textAlign: 'left' }}>
                      {/* <Link to='/approval '> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                     Timesheet & Expense <br />&nbsp;   Approval
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {/* : <></>
                  } */}
                    {getModuleReadAccess(Leave) && <li className="nav-item" onClick={(e) => handleClick(e, '/leaveList')}>
                      {/* <Link to='/leaveList'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Leave
                      </a>
                      {/* </Link> */}
                    </li>}
                    {getModuleReadAccess(Leave_Approval) && <li className="nav-item" onClick={(e) => handleClick(e, '/leaveApproval')}>
                      {/* <Link to='/leaveApproval'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                     Leave Approval
                      </a>{" "}
                      {/* </Link> */}
                    </li>} 
                  </ul>
                </div> : <></>}
              </li>
            ) : (null)}
            {/* // training */}
            {getModuleReadAccess(Training) ? (
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#trainingMaster"
                  aria-expanded={isTraining ? 'true' : 'false'}
                  aria-controls="trainingMaster"
                  onClick={onClickTraining}
                >
                  <i
                    className="fa fa-address-book"
                    style={{ marginRight: 8, fontSize: 17 }}
                  />
                  <span className="menu-title">Training</span>
                  <i className="menu-arrow" />
                </a>
                {isTraining ? <div className={isTraining ? "collapse show" : "collapsing hide"} id="trainingMaster">
                  <ul className="nav flex-column sub-menu ">
                    {getModuleReadAccess(Traning_Entry) && <li className="nav-item" onClick={(e) => handleClick(e, '/trainingList')}>
                      {/* <Link to='/trainingList '> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Training
                      </a>
                      {/* </Link> */}
                    </li>}

                    {/* {localStorage.getItem('RoleId') == '4'  || localStorage.getItem('RoleId') == '1' ? */}
                    {getModuleReadAccess(Training_Approval) && <li className="nav-item" onClick={(e) => handleClick(e, '/trainingApprovalReject')}>
                      {/* <Link to='/trainingApprovalReject'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Training Approval Reject
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {/* : <></>
                  } */}
                    {/* {localStorage.getItem('RoleId') == '4'  || localStorage.getItem('RoleId') == '1' ? */}
                    {getModuleReadAccess(Training_report) && <li className="nav-item">
                      <a style={{ backgroundColor: "#93d1eb" }}
                        className="nav-link rounded-0"
                        data-bs-toggle="collapse"
                        href="#trainingReportMaster"
                        aria-expanded={isTrainingReport ? 'true' : 'false'}
                        aria-controls="trainingReportMaster"
                        onClick={() => setIsTrainingReport(!isTrainingReport)}
                      >

                        <span className="menu-title">Training Reports</span>
                        <i className="menu-arrow" />
                      </a>
                      {isTrainingReport ? <div className={isTrainingReport ? "collapse show" : "collapsing hide"} id="trainingReportMaster">
                        <ul className="nav flex-column sub-menu ">
                          {getReportViewAccess(Firm_wise_training_report) && <li className="nav-item" onClick={(e) => handleClick(e, '/trainingReportFirmWise')}>
                            {/* <Link to='/trainingReportFirmWise'> */}
                            <a className="nav-link">
                              <i className="fa fa-angle-right" /> &nbsp;                          Firm wise Training{" "}
                            </a>{" "}
                            {/* </Link> */}
                          </li>}
                          {getReportViewAccess(Employee_wise_training_report) && <li className="nav-item" onClick={(e) => handleClick(e, '/trainingReportEmployee')}>
                            {/* <Link to='/trainingReportEmployee'> */}
                            <a className="nav-link">
                              <i className="fa fa-angle-right" /> &nbsp;                       Employee Training
                            </a>{" "}
                            {/* </Link> */}
                          </li>}
                        </ul>
                      </div> : <></>}
                    </li>}
                    {/* : <></> } */}
                  </ul>
                </div> : <></>}
              </li>
            ) : (null)}
            {/* reports */}
            {getModuleReadAccess(Reports) ? (
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#reportsMaster"
                  aria-expanded={isReports ? 'true' : 'false'}
                  aria-controls="reportsMaster"
                  onClick={onClickReports}
                >
                  <i
                    className="fa fa-flag-o"
                    style={{ marginRight: 8, fontSize: 17 }}
                  />
                  <span className="menu-title">Reports</span>
                  <i className="menu-arrow" />
                </a>
                {isReports ? <div className={isReports ? "collapse show" : "collapsing hide"} id="reportsMaster">
                  <ul className="nav flex-column sub-menu ">
                    {getReportViewAccess(Time_Sheet_Employee_wise_report) && <li className="nav-item" onClick={(e) => handleClick(e, '/timesheetEmployeeSummaryReport')}>
                      {/* <Link to='/timesheetEmployeeSummaryReport'> */}
                      <a className="nav-link" >
                        <i className="fa fa-angle-right" /> &nbsp;                 Timesheet Employee Summary Report{" "}
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {getReportViewAccess(Time_Sheet_Client_wise_report) && <li className="nav-item" onClick={(e) => handleClick(e, '/timesheetClientSummaryReport')}>
                      {/* <Link to='/timesheetClientSummaryReport'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Timesheet Client Summary Report
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {getReportViewAccess(Employee_and_Client_wise_Summary_Report) && <li className="nav-item" onClick={(e) => handleClick(e, '/empClientSummaryReport')}>
                      {/* <Link to='/empClientSummaryReport'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Employee Client Summary Report
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {getReportViewAccess(Employee_wise_expense_report) && <li className="nav-item" onClick={(e) => handleClick(e, '/expenseEmpSummaryReport')}>
                      {/* <Link to='/expenseEmpSummaryReport'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Expense Employee Summary Report
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {getReportViewAccess(Client_wise_expense_report) && <li className="nav-item" onClick={(e) => handleClick(e, '/expenseClientSummaryReport')}>
                      {/* <Link to='/expenseClientSummaryReport'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Expense Client Summary Report
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {getReportViewAccess(Time_Sheet_Detailed_Report) && <li className="nav-item" onClick={(e) => handleClick(e, '/timesheetDetailsReport')}>
                      {/* <Link to='/timesheetDetailsReport'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Timesheet Details Report
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {getReportViewAccess(Client_wise_detailed_Report) && <li className="nav-item" onClick={(e) => handleClick(e, '/clientDetailsReport')}>
                      {/* <Link to='/clientDetailsReport'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Client Details Report
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {getReportViewAccess(Client_and_Engagement_wise_detailed_Report) && <li className="nav-item" onClick={(e) => handleClick(e, '/clientEngDetailsReport')}>
                      {/* <Link to='/clientEngDetailsReport'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Client Engagement Details Report
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {getReportViewAccess(Leave_Report) && <li className="nav-item" onClick={(e) => handleClick(e, '/leaveReport')}>
                      {/* <Link to='/leaveReport'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Leave Report
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {getReportViewAccess(Employee_wise_Leave_Report) && <li className="nav-item" onClick={(e) => handleClick(e, '/empLeaveReport')}>
                      {/* <Link to='/empLeaveReport'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Employee Leave Report
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {getReportViewAccess(Time_Sheet_Employee_wise_report) && <li className="nav-item" onClick={(e) => handleClick(e, '/employeeHasEnteredTimesheetReport')}>
                      {/* <Link to='/employeeHasEnteredTimesheetReport'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                 Employee Has Entered Timesheet
                      </a>{" "}
                      {/* </Link> */}
                    </li>}

                    {getReportViewAccess(UserProfile) && <li className="nav-item" onClick={(e) => handleClick(e, '/userProfileReport')}>
                      {/* <Link to='/userProfileReport'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                User Profile Report
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                  </ul>
                </div> : <></>}
              </li>
            ) : (null)}
            {/* // mcq */}
            {getModuleReadAccess(MCQ) ? (
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  href="#MCQMaster"
                  aria-expanded={isMCQ ? 'true' : 'false'}
                  aria-controls="MCQMaster"
                  onClick={onClickMCQ}
                >
                  <i
                    className="fa fa-file-text-o"
                    style={{ marginRight: 8, fontSize: 17 }}
                  />
                  <span className="menu-title">MCQ</span>
                  <i className="menu-arrow" />
                </a>
                {isMCQ ? <div className={isMCQ ? "collapse show" : "collapsing hide"} id="MCQMaster">
                  <ul className="nav flex-column sub-menu ">
                    {getModuleReadAccess(MCQ_Chapter) && <li className="nav-item" onClick={(e) => handleClick(e, '/mcqchapterlist')}>
                      {/* <Link to='/mcqchapterlist'> */}
                      <a className="nav-link" >
                        <i className="fa fa-angle-right" /> &nbsp;                  Chapter{" "}
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {getModuleReadAccess(MCQ_Setup) && <li className="nav-item" onClick={(e) => handleClick(e, '/mcqsetupList')}>
                      {/* <Link to='/mcqsetupList'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                  Setup
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {getModuleReadAccess(MCQ_Setting) && <li className="nav-item" onClick={(e) => handleClick(e, '/settingAU')}>
                      {/* <Link to='/settingAU'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                  Settings
                      </a>{" "}
                      {/* </Link> */}
                    </li>}

                    {getModuleReadAccess(MCQ_Take_Test) && <li className="nav-item" onClick={(e) => handleClick(e, '/takeTest')}>
                      {/* <Link to='/takeTest'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                  Take a Test
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                    {getModuleReadAccess(MCQ_Take_Test) && <li className="nav-item" onClick={(e) => handleClick(e, '/homeTest')}>
                      {/* <Link to='/homeTest'> */}
                      <a className="nav-link">
                        <i className="fa fa-angle-right" /> &nbsp;                   Test History
                      </a>{" "}
                      {/* </Link> */}
                    </li>}
                  </ul>
                </div> : <></>}
              </li>
            ) : (null)}
            {/* // change pass */}
            {getModuleReadAccess(Change_Password) ? (
              <li className="nav-item" onClick={(e) => handleClick(e, '/changePassword')}>
                {/* <Link to='/changePassword'> */}
                <a className="nav-link">
                  <i className="fa fa-cog" /> &nbsp; Change Password
                </a>{" "}
                {/* </Link> */}
              </li>
            ) : (null)}
            <li className="nav-item" data-toggle="modal" data-target="#logout" onClick={toggleModal}>
              <a className="nav-link ">
                <i
                  className="fa fa-sign-out"
                  style={{ marginRight: 8, fontSize: 17 }}
                />
                <span className="menu-title"  > Logout</span>

              </a>
            </li>

          </ul>
        </div>
        {/* <div className="toggle-btn" onClick="toggleSidebar(this)">
          <i className="mdi mdi-chevron-left asd1" />
          <i className="mdi mdi-chevron-right asd" />
        </div> */}



        {/* {isToggle ==  false ? 
         <div className="toggle-btn" > <i className="mdi mdi-chevron-left asd1"  onClick={() => setToggle(!isToggle)}/>  </div> :
         <div className="toggle-btn" > <i className="mdi mdi-chevron-right asd" onClick={() => setToggle(!isToggle)} />  </div>}
        */}

      </nav>
      {/* end left menu */}

    </>
  );
}
export default withRouter(Drawer);