import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { fill_action } from '../../../actions/master_action/checklist_action'
import {
    addFinancialYearList_action, updateFinancialYearList_action,
    deleteFinancialYearList_action, financialYearDetail_action
} from '../../../actions/master_action/financial_year_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from "react-router-dom";
import { withRouter } from '../../../utils/withRouter';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Loader from '../../../utils/Loader'
import { FaCalendarAlt } from 'react-icons/fa';
import "react-datepicker/dist/react-datepicker.css";
import { finToDateFromLastRecordId, Master } from '../../../utils/constants'
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     FinancialYearAction: () => dispatch(FinancialYearAction())
// })

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));


class FinancialYearAU extends Component {

    constructor(props) {
        super(props)
        this.state = {

            financialYearName: '',
            shortName: '',
            fromDate: '',
            toDate: '',
            is_add: false,
            is_delete: false,
            is_update: false,
            finYearId: '',
            financialYear: [],
            isLoading: false,
            isActive: 0,
            finTodateLstRcd: '',
            lastRecord: '',
            isAccessToView: true,
        }
        this.handleAdd = this.handleAdd.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.getFinDetails = this.getFinDetails.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.getLatToDate = this.getLatToDate.bind(this)

    }

    // financial year details 
    componentDidMount = () => {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
            finYearId: this.props.location.state != null && this.props.location.state.finYearId != null ? this.props.location.state.finYearId : '',
            isAccessToView: true,
        })
        this.props.location.state != null && this.props.location.state.is_update && this.getFinDetails()
        this.props.location.state != null && this.props.location.state.is_add && this.getLatToDate()
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
          const focusedElement = document.activeElement;
          const { previouslyFocusedElement } = this.state;
    
          if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
          }
    
          if (focusedElement.classList.contains('form-control') ||
            focusedElement.classList.contains('js-example-basic-single') ||
            focusedElement.classList.contains('example-custom-input')) {
            focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
            this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
          }
        }
      };
    
    
      handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;
    
        if (previouslyFocusedElement) {
          previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }
    
        if (clickedElement.classList.contains('form-control') ||
          clickedElement.classList.contains('js-example-basic-single') ||
          clickedElement.classList.contains('example-custom-input')) {
          clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
          this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
          this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
      };
    // get engagement type dropdown list 
    getLatToDate() {
        this.setState({ isLoading: true })
        this.props
            .fill_action(finToDateFromLastRecordId, '')
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ fromDate: data.data[0]['Todate'] != null ? moment(data.data[0]['Todate'], 'YYYY-MM-DD').toDate() : '', finTodateLstRcd: data.data[0]['Todate'] != null ? moment(data.data[0]['Todate'], 'YYYY-MM-DD').toDate() : '', isLoading: false })
                    // this.setState({ isLoading: false })

                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }



    getFinDetails() {
        const { financialYearName, shortName, fromDate, toDate, lastRecord } = this.state
        var id = this.props.location.state.finYearId
        this.setState({ isLoading: true })
        this.props
            .financialYearDetail_action(id)
            .then((data) => {
                if (data.error != 1) {

                    this.setState({
                        financialYearName: data.data[0]['FinancialYear'],
                        shortName: data.data[0]['Code'],
                        fromDate: moment(data.data[0]['FromDate'], 'YYYY-MM-DD').toDate(),
                        toDate: moment(data.data[0]['ToDate'], 'YYYY-MM-DD').toDate(),
                        isLoading: false,
                        isActive: data.data[0]['IsActive'],
                        lastRecord: data.data[0]['Lastrecord']
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // add financial year 
    handleAdd(e) {
        e.preventDefault()
        const { financialYearName, shortName, fromDate, toDate } = this.state
        if (financialYearName != "" && shortName != "" &&
        (fromDate != "" && fromDate != null && fromDate != "Invalid Date") 
        &&   (toDate != "" && toDate != null && toDate != "Invalid Date") ) {
            this.setState({ isLoading: true })

            this.props
                .addFinancialYearList_action(financialYearName.trim(), shortName.trim(), fromDate, toDate)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.clearValue()
                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }



    }

    // update financial year 
    handleUpdate(e) {
        e.preventDefault()
        const { financialYearName, shortName, fromDate, toDate, finYearId } = this.state
        const id = finYearId;

        if (id != "" && financialYearName != "" && shortName != "" &&
        (fromDate != "" && fromDate != null && fromDate != "Invalid Date") 
        &&   (toDate != "" && toDate != null && toDate != "Invalid Date")) {
            this.setState({ isLoading: true })
            this.props
                .updateFinancialYearList_action(id, financialYearName.trim(), shortName.trim(), fromDate, toDate)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }
    // delete financial year 
    handleDelete(e) {
        e.preventDefault()
        var id = this.state.finYearId
        this.setState({ isLoading: true })
        this.props
            .deleteFinancialYearList_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    // clear field  
    handleCancel() {
        this.props.navigate('/finYearList')
    }

    clearValue() {
        this.setState({
            financialYearName: '',
            shortName: '',
            fromDate: '',
            toDate: '',

        })
    }



    render() {
        const { isLoading, isActive, is_update, is_add, lastRecord, finYearId, isAccessToView } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li>
                                                <a onClick={this.handleCancel} >Financial Year</a>
                                            </li>
                                            <li>
                                                <a >{this.state.is_add ? 'New' : 'Update'}</a>
                                            </li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className="user-status">
                                        {
                                            is_update ? <p>
                                                <strong className={isActive === 1 ? 'green' : 'redi'}> {isActive === 1 ? 'Active' : 'Inactive'}</strong>
                                            </p> : <></>
                                        }

                                    </div>
                                    <div className="row">
                                        <style
                                            type="text/css"
                                            dangerouslySetInnerHTML={{
                                                __html: "\n.accordion-button::after {\n background-size: 13px;\n display: none; \n} \n"
                                            }}
                                        />
                                        <div className="col-md-12 pg-body grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body pt-0">

                                                    <div class="d-flex flex-wrap row ">
                                                        <div class="form-group col-md-3">
                                                            <label>Financial Year Name<span class="required1">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder='Financial Year Name'
                                                                value={this.state.financialYearName}
                                                                onChange={(e) => {
                                                                    if (e.target.value.length <= 50) {
                                                                        this.setState({ financialYearName: e.target.value });
                                                                    } else {
                                                                        // Truncate the input value to 50 characters
                                                                        this.setState({ financialYearName: e.target.value.slice(0, 50) });
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                        </div>

                                                        <div className="form-group col-md-3">
                                                            <label>
                                                                Shortname<span className="required1">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder='Shortname'
                                                                value={this.state.shortName}
                                                                onChange={(e) => {
                                                                    if (e.target.value.length <= 50) {
                                                                        this.setState({ shortName: e.target.value });
                                                                    } else {
                                                                        // Truncate the input value to 50 characters
                                                                        this.setState({ shortName: e.target.value.slice(0, 50) });
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                        </div>

                                                        <div className="form-group col-md-2">
                                                            <label>
                                                                From Date<span className="required1">*</span>
                                                            </label>
                                                            <div className="form-group d-flex">
                                                                <DatePicker className="form-control"
                                                                    dateFormat="dd-MM-yyyy"
                                                                    selected={
                                                                        is_add ? (this.state.finTodateLstRcd != '' ? this.state.finTodateLstRcd : this.state.fromDate) : this.state.fromDate
                                                                    }
                                                                    minDate={this.state.finTodateLstRcd != '' ? this.state.finTodateLstRcd : ''}
                                                                    disabled={is_add ? (this.state.finTodateLstRcd != '' ? true : false) : is_update}
                                                                    onChange={(date) => {
                                                                        this.setState({ fromDate: date });

                                                                    }}
                                                                    onClick={(date) => {
                                                                        this.setState({ fromDate: date });

                                                                    }}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    placeholderText="From Date"
                                                                    customInput={<ExampleCustomInput />}
                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                    popperClassName="example-datepicker-class"
                                                                />

                                                                {/* <FaCalendarAlt style={{
                                                                    flex: '0 0 19%',
                                                                    maxWidth: '19%',
                                                                    color: 'grey',
                                                                    marginTop: '7px',
                                                                    marginLeft: '-35px',
                                                                    zIndex: '0'
                                                                }} /> */}

                                                            </div>
                                                            <div></div>
                                                        </div>
                                                        <div className="form-group col-md-2">
                                                            <label>
                                                                To Date<span className="required1">*</span>
                                                            </label>
                                                            <div className="input-group">
                                                                <div className='daterangecss'>
                                                                    <div className="form-group d-flex">
                                                                        <DatePicker className="form-control"
                                                                            dateFormat="dd-MM-yyyy"
                                                                            selected={this.state.toDate}
                                                                            minDate={this.state.fromDate}
                                                                            peekNextMonth
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            disabled={is_add ? false : finYearId == lastRecord ? false : true}
                                                                            onChange={(date) => this.setState({ toDate: date })}
                                                                            placeholderText="To Date"
                                                                            customInput={<ExampleCustomInput />}
                                                                            dayClassName={() => "example-datepicker-day-class"}
                                                                            popperClassName="example-datepicker-class"
                                                                        />

                                                                        {/* <FaCalendarAlt style={{
                                                                    flex: '0 0 19%',
                                                                    maxWidth: '19%',
                                                                    color: 'grey',
                                                                    marginTop: '7px',
                                                                    marginLeft: '-35px',
                                                                    zIndex: '0'
                                                                }} /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div></div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-wrap row mt-1">

                                                    </div>
                                                    <div className="row">
                                                        <div className="footer-btn">
                                                            <div className="col-md-3">
                                                                <div
                                                                    className="btn-group"
                                                                    role="group"
                                                                    aria-label="Basic example"
                                                                >
                                                                    {
                                                                        this.state.is_add && getModuleWriteAccess(Master) && <button
                                                                            type="button"
                                                                            className="btn btn-save footer-btn-inner mr-1"
                                                                            onClick={this.handleAdd}
                                                                        >
                                                                            Save
                                                                        </button>
                                                                    }

                                                                    {
                                                                        this.state.is_update && isActive === 1 && getModuleUpdateAccess(Master) ?
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-update footer-btn-inner mr-1"
                                                                                onClick={this.handleUpdate}
                                                                            >
                                                                                Update
                                                                            </button> : <></>
                                                                    }

                                                                    {
                                                                        this.state.is_delete && getModuleDeleteAccess(Master) &&
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-delete footer-btn-inner mr-1"
                                                                            onClick={this.handleDelete}
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    }

                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-cancel footer-btn-inner"
                                                                        onClick={this.handleCancel}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end
   */}
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>
                <ToastContainer />
            </>
        );
    }
}

export default withRouter(connect(mapStateToProps, {
    financialYearDetail_action, addFinancialYearList_action, updateFinancialYearList_action, deleteFinancialYearList_action
    , fill_action
})(FinancialYearAU));