import { axiosGet, axiosPost } from "../../react_axios/Axios"
import moment from "moment/moment"
import { headers, addressTypeListUrl, ADDRESS_TYPE_LIST_ACTION_TYPE, addAddressTypeUrl, addressTypeDetailsUrl, updateAddressTypeUrl, deleteAddressTypeUrl, ADDRESS_TYPE_DETAILS_ACTION_TYPE, ADDRESS_TYPE_DETETE_ACTION_TYPE, ADDRESS_TYPE_UPDATE_ACTION_TYPE, ADDRESS_TYPE_ADD_ACTION_TYPE, platfarm } from "../../utils/constants"

export const addressTypeList_action = (addressTypeName, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: addressTypeName,
            Status: status
        }
        console.log(param)
        const res = await axiosGet(addressTypeListUrl, param)
        console.log(res)
        dispatch({
            type: ADDRESS_TYPE_LIST_ACTION_TYPE,
            payload: res
        })

        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const addAddressType_action = (addressTypeName) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: addressTypeName
        }
        const res = await axiosPost
            (addAddressTypeUrl, mapdata, headers)
        dispatch({
            type: ADDRESS_TYPE_ADD_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const updateAddressType_action = (id, addressTypeName) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
            Name: addressTypeName,
        }
        const res = await axiosPost(updateAddressTypeUrl, mapdata, headers)
        dispatch({
            type: ADDRESS_TYPE_UPDATE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const addressTypeDetail_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id
        }
        const res = await axiosGet(addressTypeDetailsUrl, param)
        dispatch({
            type: ADDRESS_TYPE_DETAILS_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const deleteAddressType_action = (id) =>
    async (dispatch) => {
        try {
            const param = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                ID: id,
            }
            console.log(param)
            const res = await axiosGet
                (deleteAddressTypeUrl, param)
            dispatch({
                type: ADDRESS_TYPE_DETETE_ACTION_TYPE,
                payload: res
            })
            return Promise.resolve(res)
        } catch (err) {
            return Promise.reject(err)
        }
}

export default (state = {}, action) => {
    switch (action.type) {
        case 'SIMPLE_ACTION':
            return {
                result: action.payload
            }
        default:
            return state
    }
}