import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import Drawer from '../../common_components/Drawer';
import { getEmpLeaveReportView_action, getEmpLeaveReport_action } from '../../actions/reports_action/emp_leave_report_action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../utils/Loader'
import Pagination from '../../utils/pagination'
import { fill_action } from '../../actions/master_action/checklist_action'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    clearLocalStorage, downloadReport, monthActionId, yearsActionId,
    timesheetStatusActionId, trainingEmployeeActionId,
    Employee_wise_Leave_Report,
} from '../../utils/constants'
import * as XLSX from 'xlsx';
import { getReportDownloadAccess, getReportViewAccess } from '../../utils/RoleAccess';
/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//   FinancialYearAction: () => dispatch(FinancialYearAction())
// })

/* 
 * mapStateToProps
*/
// const mapStateToProps = state => {
//   return {
//     loginData: state,
//   };
// }


const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));

const customStyles = {
    // singleValue: (provided, state) => ({
    //     ...provided,
    //     backgroundColor: '#007bff',
    //     color: 'white',
    //     padding: '5px 10px',
    //     borderRadius: '25px',
    //     display: 'flex',
    //     alignItems: 'center',
    //   }),
    singleValue: (provided) => ({
        ...provided,
        borderRadius: "14px",
        padding: "4px",
        width : '12rem',
        backgroundColor: "#00BCD4", // Blue background color for selected value
    }),
    singleValueLabel: (provided) => ({
        ...provided,
        color: "white", // Text color for selected value
        fontSize: "12px",
    }),
    singleValueRemove: (provided) => ({
        ...provided,
        color: "white",
        "&:hover": {
            backgroundColor: "#00BCD4",
            color: "#fff", // Blue border color on hover
        }, // Remove icon color for selected value
    }),
    control: (provided) => ({
        ...provided,
        borderColor: "#c9c9c9", // Blue border color
        boxShadow: "none",
        "&:hover": {
            borderColor: "#80bdff", // Blue border color on hover
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        borderRadius: "14px",
        padding: "4px",
        backgroundColor: "#00BCD4", // Blue background color for selected value
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: "white", // Text color for selected value
        fontSize: "12px",
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: "white",
        "&:hover": {
            backgroundColor: "#00BCD4",
            color: "#fff", // Blue border color on hover
        }, // Remove icon color for selected value
    }),
};

function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
}

class EmpLeaveReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            endDate: '',
            fromDate: '',
            monthId: "",
            monthList: [],
            yearId: '',
            yearList: [],
            selectedEmployeeIdList: [],
            employeeList: [],
            statusList: [],
            statusId: [],
            isOpen: false,
            isLoading: false,
            isSearchTab: false,
            listData: [],
            columnArray: [],
            rowData: [],
        }
        this.handleClick = this.handleClick.bind(this)
        this.getReport = this.getReport.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.getReportView = this.getReportView.bind(this)

    }

    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.handleSearch()
        }
    }

    handleClick(event) {
        let listid = Number(event.target.id);
        this.setState({
            currentPage: listid
        });
    }
    getReport() {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .getReportTraining_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false, currentPage: 1 })

                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }
    // financial list default
    componentDidMount = () => {
        this.setState({
            isAccessToView: getReportViewAccess(Employee_wise_Leave_Report),
        })
        clearLocalStorage("financialYear")
        // document.addEventListener('click', this.handleInputClick);

        // document.addEventListener('keydown', this.handleTabKeyPress);
        this.getFillList(trainingEmployeeActionId)
        this.getFillList(timesheetStatusActionId)
        this.getFillList(monthActionId)
        this.getFillList(yearsActionId)

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
          const focusedElement = document.activeElement;
          const { previouslyFocusedElement } = this.state;
    
          if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
          }
    
          if (focusedElement.classList.contains('form-control') ||
            focusedElement.classList.contains('js-example-basic-single') ||
            focusedElement.classList.contains('example-custom-input')) {
            focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
            this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
          }
        }
      };
    
    
      handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;
    
        if (previouslyFocusedElement) {
          previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }
    
        if (clickedElement.classList.contains('form-control') ||
          clickedElement.classList.contains('js-example-basic-single') ||
          clickedElement.classList.contains('example-custom-input')) {
          clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
          this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
          this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
      };

    // get fill List dropdown  
    async getFillList(id, reference) {
        const { is_add } = this.state
        this.setState({ isLoading: true })
        console.log("989809809")
        await this.props
            .fill_action(id, reference)
            .then((data) => {
                if (data.error != 1) {
                    if (id === trainingEmployeeActionId) {
                        console.log("trainingEmployeeActionId", data.data)

                        var list = []
                        data.data.map((obj) => {
                            list.push({
                                'value': obj.EmployeeID,
                                'label': obj.Employee
                            });
                        })
                        console.log('getFillList')
                        console.log(list)

                        this.setState({
                            employeeList: list, isLoading: false
                        })
                    }
                    if (id === timesheetStatusActionId) {
                        var list = []
                        data.data.map((obj) => {
                            list.push({
                                'value': obj.TimeSheetStatusID,
                                'label': obj.Name
                            });
                        })
                        console.log(list)
                        this.setState({
                            statusList: list, isLoading: false
                        })
                    }
                    if (id === monthActionId) {
                        this.setState({
                            monthList: data.data, isLoading: false
                        })
                    }
                    if (id === yearsActionId) {
                        this.setState({
                            yearList: data.data, isLoading: false
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }



    formatData = (data) => {
        return data.map(item => ({
          ...item,
          Date: item.Date ? moment(item.Date, 'DD-MM-YYYY').format('DD/MM/YYYY HH:mm:ss') : '',
        //   LeaveFromDate: item.LeaveFromDate ? moment(item.LeaveFromDate).format('DD/MM/YYYY HH:mm:ss') : '',
        //   LeaveToDate: item.LeaveToDate ? moment(item.LeaveToDate).format('DD/MM/YYYY HH:mm:ss') : '',
        //   ApprovedDate: item.ApprovedDate ? moment(item.ApprovedDate).format('DD/MM/YYYY HH:mm:ss') : ''
        }));
    }

    generateExcel = () => {
        const { listData } = this.state;
        console.log('data generateExcel: ', listData);

        // Prepare data for Excel (xlsx) file
        const worksheet = XLSX.utils.json_to_sheet(listData);

        // Calculate the maximum width for each column
        const columnWidths = listData.reduce((acc, row) => {
            Object.keys(row).forEach((key, idx) => {
                const value = row[key] ? row[key].toString() : '';
                acc[idx] = Math.max(acc[idx] || 10, value.length);
            });
            return acc;
        }, []).map(width => ({ wch: width }));

        worksheet['!cols'] = columnWidths;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate a download link
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'EmployeeLeaveReport.xlsx';
        a.click();
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 100);
    };

    getReportView() {
        const { rowData, columnArray, listData, fromDate, endDate, monthId, yearId, selectedEmployeeIdList, statusId } = this.state


        
        // Check if at least one of the fields is selected
        if (
            (fromDate !== '' && fromDate !== null && fromDate !== "Invalid Date") ||
            (endDate !== '' && endDate !== null && endDate !== "Invalid Date") ||
            (monthId !== '' && monthId !== null) ||
            (yearId !== '' && yearId !== null) ||
            (selectedEmployeeIdList == 'Select' || selectedEmployeeIdList.length == 0)
        ) {
            // Validate the fields based on monthId and yearId
            if (
                (monthId !== '' && yearId === '') ||
                (monthId === '' && yearId !== '')
            ) {
                toast.error('Month and year must be selected together.', {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                });
                return;
            }

            if (fromDate === '' || fromDate === null || fromDate === "Invalid Date") {
                if (endDate !== '') {
                    toast.error('Please select a start date.', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    });
                    return;
                }
            }

            if ( selectedEmployeeIdList.length == 0) {
                toast.error('Please select employee', {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                return;
            }


            console.log()
            this.setState({ isLoading: true })
            console.log('check issue');
            console.log(selectedEmployeeIdList);
            // console.log( selectedEmployeeIdList.map(employee => employee.value).join(','));

            const employeedata = [selectedEmployeeIdList].map(employee => employee.value).join(',');
            console.log(employeedata);
            const statusIddata = statusId.map(item => item.value).join(',');

            this.props
                .getEmpLeaveReportView_action(
                    fromDate != 'Invalid date' && fromDate != null && fromDate != '' && fromDate != 'Select' ?
                        moment(fromDate).format('YYYY-MM-DD') : '',
                    endDate != 'Invalid date' && endDate != null && endDate != '' && endDate != 'Select' ? moment(endDate).format('YYYY-MM-DD') : '',
                    monthId != 'Select' && monthId != '' ? monthId : null,
                    yearId != 'Select' && yearId != '' ? yearId : null,
                    employeedata != '' ? employeedata : null,
                    statusIddata != '' ? statusIddata : null,
                )
                .then((data) => {
                    if (data != null) {
                        this.setState({ isLoading: false })
                        console.log('Report data : ', data.data);
                        if (data.data.length == 0) {
                            toast.error('Record not found', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        } else {
                            data.data.map((data, index) => {
                                if (index == 0) {
                                    Object.keys(data).map((key, index) => {
                                        columnArray.push(key)
                                    })
                                }
                            })
                            // for row data
                            data.data.map((data, index) => {
                                rowData.push(data)
                            })
                            console.log('rowData data : ', rowData);
                            console.log('columnArray data : ', columnArray);
                            const formattedData = this.formatData(data.data);
                            this.setState({ listData: formattedData, rowData: rowData, columnArray: columnArray, dataFetched: true })

                            setTimeout(() => {
                                this.generateExcel();
                            }, 200);
                        }
                        //
                    } else {
                        this.setState({ isLoading: false })
                        toast.error('There is some problem to download excel. Please try again later!', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ isLoading: false })
                });
            } else {
                toast.error('Please select at least one mandatory field.', {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }

    }




    // download audit plane...............
    handleDownload(e) {
        e.preventDefault()
        const { fromDate, endDate, monthId, yearId, selectedEmployeeIdList, statusId } = this.state
        console.log('selectedEmployeeIdList 32');
        console.log(selectedEmployeeIdList);

        
        // Check if at least one of the fields is selected
        if (
            (fromDate !== '' && fromDate !== null && fromDate !== "Invalid Date") ||
            (endDate !== '' && endDate !== null && endDate !== "Invalid Date") ||
            (monthId !== '' && monthId !== null) ||
            (yearId !== '' && yearId !== null) ||
            (selectedEmployeeIdList == 'Select' || selectedEmployeeIdList.length == 0)
        ) {
            // Validate the fields based on monthId and yearId
            if (
                (monthId !== '' && yearId === '') ||
                (monthId === '' && yearId !== '')
            ) {
                toast.error('Month and year must be selected together.', {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                });
                return;
            }

            if (fromDate === '' || fromDate === null || fromDate === "Invalid Date") {
                if (endDate !== '') {
                    toast.error('Please select a start date.', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    });
                    return;
                }
            }

            if ( selectedEmployeeIdList.length == 0) {
                toast.error('Please select employee', {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                return;
            }

           
           
            console.log()
            this.setState({ isLoading: true })
            // console.log(employeedata);
            console.log(selectedEmployeeIdList);
            // console.log( selectedEmployeeIdList.map(employee => employee.value).join(','));
            const employeedata = [selectedEmployeeIdList].map(employee => employee.value).join(',');

            console.log(employeedata);
            const statusIddata = statusId.map(item => item.value).join(',');

            this.props
                .getEmpLeaveReport_action(
                    fromDate != 'Invalid date' && fromDate != null && fromDate != '' && fromDate != 'Select' ?
                        moment(fromDate).format('YYYY-MM-DD') : '',
                    endDate != 'Invalid date' && endDate != null && endDate != '' && endDate != 'Select' ? moment(endDate).format('YYYY-MM-DD') : '',
                    monthId != 'Select' && monthId != '' ? monthId : null,
                    yearId != 'Select' && yearId != '' ? yearId : null,
                    employeedata != '' ? employeedata : null,
                    statusIddata != '' ? statusIddata : null,
                )
                .then((data) => {
                    if (data != null) {
                        this.setState({ isLoading: false })
                        console.log('Report data : ', data);
                        var url = `${downloadReport}?SessionIdentifier=${data}&Export=Yes`;
                        console.log('Report download url : ', url);
                        const win = window.location.replace(url, '_blank');
                        if (win != null) {
                            win.focus();
                        }
                    } else {
                        this.setState({ isLoading: false })
                        toast.error('There is some problem to download report. Please try again later!', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ isLoading: false })
                });
            } else {
                toast.error('Please select at least one mandatory field.', {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }

    }


    //on select fin year
    onSelectFinYearDropdown(e) {
        this.setState({ finYear: e.target.value })
    }



    render() {

        const { isSearchTab, isAccessToView,
            endDate, fromDate, monthId, monthList,
            yearId, yearList, selectedEmployeeIdList, employeeList,
            statusList, statusId,
            isLoading, } = this.state
        // const recordsPerPage = 10
        // const indexOfLastRecord = currentPage * recordsPerPage;
        // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // // Records to be displayed on the current page
        // const currentRecords = financialYearList.slice(indexOfFirstRecord, indexOfLastRecord);
        // const nPages = Math.ceil(financialYearList.length / recordsPerPage)


        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />


                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>Employee Leave Report </a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                        <div className="row m-0  pg-body grid-margin stretch-card">
                                            <div className='form-group col-md-3'>
                                                <label>
                                                    Start Date<span className="required1">*</span>
                                                </label>
                                                <div className="form-group d-flex">
                                                    <DatePicker className="form-control"
                                                        dateFormat="dd-MM-yyyy"
                                                        selected={this.state.fromDate}
                                                        // minDate={this.state.finTodateLstRcd != '' ? this.state.finTodateLstRcd : ''}
                                                        // disabled={is_add ? (this.state.finTodateLstRcd != '' ? true : false) : is_update}
                                                        onChange={(date) => {
                                                            this.setState({ fromDate: date, endDate: '' });

                                                        }}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText="From Date"
                                                        customInput={<ExampleCustomInput />}
                                                        dayClassName={() => "example-datepicker-day-class"}
                                                        popperClassName="example-datepicker-class"
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-group col-md-3 mt-1'>
                                                <label>
                                                    End Date
                                                </label>
                                                <div className="form-group d-flex">
                                                    <DatePicker className="form-control"
                                                        dateFormat="dd-MM-yyyy"
                                                        selected={this.state.endDate}
                                                        // minDate={this.state.finTodateLstRcd != '' ? this.state.finTodateLstRcd : ''}
                                                        // disabled={is_add ? (this.state.finTodateLstRcd != '' ? true : false) : is_update}
                                                        onChange={(date) => {
                                                            this.setState({ endDate: date });

                                                        }}
                                                        disabled={this.state.fromDate == ''  || this.state.fromDate == null || this.state.fromDate == "Invalid Date" ? true : false}
                                                        minDate={this.state.fromDate}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText="From Date"
                                                        customInput={<ExampleCustomInput />}
                                                        dayClassName={() => "example-datepicker-day-class"}
                                                        popperClassName="example-datepicker-class"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label>
                                                    Month<span className="required1">*</span>
                                                </label>
                                                <select
                                                    className="js-example-basic-single"
                                                    style={{ width: "100%" }}
                                                    value={monthId}
                                                    onChange={(e) => this.setState({ monthId: e.target.value })}
                                                >
                                                    <option value={''}>Select</option>
                                                    {monthList.length > 0 && monthList.map(item => (
                                                        <option
                                                            key={item.Code}
                                                            value={item.Code}>
                                                            {item.Description}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label>
                                                    Year<span className="required1">*</span>
                                                </label>
                                                <select
                                                    className="js-example-basic-single"
                                                    style={{ width: "100%" }}
                                                    value={yearId}
                                                    onChange={(e) => this.setState({ yearId: e.target.value })}
                                                >
                                                    <option value={''} >Select</option>
                                                    {yearList.length > 0 && yearList.map(item => (
                                                        <option
                                                            key={item.Code}
                                                            value={item.Code}>
                                                            {item.Description}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-6 pr-0 form-group">
                                                <label>Employee
                                                    <span className="required1">*</span>
                                                </label>
                                                <Select
                                                    isMulti={false}
                                                    // isDisabled={statusId == '0'}
                                                    // isClearable={statusId != '0'}
                                                    // styles={statusId == '0' ? deletedStyles : customStyles}
                                                    styles={customStyles}
                                                    value={selectedEmployeeIdList}
                                                    onChange={e => this.setState({ selectedEmployeeIdList: e })}
                                                    options={employeeList} />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Status</label>
                                                <Select
                                                    isMulti
                                                    // isDisabled={statusId == '0'}
                                                    // isClearable={statusId != '0'}
                                                    // styles={statusId == '0' ? deletedStyles : customStyles}
                                                    styles={customStyles}
                                                    value={statusId}
                                                    onChange={e => this.setState({ statusId: e })}
                                                    options={statusList} />
                                                {/* <select className="js-example-basic-single"
                                                    style={{ width: '100%' }}
                                                    value={statusId}
                                                    onChange={(e) => this.setState({ statusId: e.target.value })}>
                                                    <option  value={''}>Select</option>
                                                    {statusList.length > 0 && statusList.map(item => (
                                                        <option
                                                        key={item.TimeSheetStatusID}
                                                        value={item.TimeSheetStatusID}>
                                                        {item.Name}
                                                    </option>
                                                    ))}

                                                </select> */}
                                            </div>

                                            {/* <div className="form-group col-md-3  filter-btn-col">
                                            <label></label>
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary fil-btn p-2 w-auto"
                                                onClick={e => this.handleDownload(e)}>
                                                Generate
                                            </button>
                                            </div> */}
                                        </div>
                                        <div className="footer-btn mx-2">
                                            {getReportDownloadAccess(Employee_wise_Leave_Report) &&
                                                <div className="col-md-6 d-flex ">
                                                    <div
                                                        className="d-flex"
                                                        role="group"
                                                        aria-label="Basic example">
                                                        <button type="button"
                                                            className="btn btn-outline-primary fil-btn p-2 w-auto"
                                                            onClick={e => this.handleDownload(e)}>   Generate </button>
                                                        <div className="text-center mx-3">
                                                            <button type="button" className="btn btn-outline-primary fil-btn p-2 w-auto"
                                                                onClick={this.getReportView}
                                                            >
                                                                Export To Excel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>


                <ToastContainer />

            </>
        );
    }
}

export default withRouter(connect(null, {
    getEmpLeaveReportView_action, fill_action, getEmpLeaveReport_action
})(EmpLeaveReport));