import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login_action, forgetPassword_action } from '../../actions/user_auth_action/login_action';
import store from '../../store'
import { Route, Redirect, Router, Link, Form } from 'react-router-dom';
import Dashboard from '../dashboard/dashboard';
import { Navigate } from "react-router-dom";
import { withRouter } from '../../utils/withRouter';
import Loader from '../../utils/Loader'
import { clearLocalStorage } from '../../utils/constants'
import Modal from "react-modal";
import { emailValidator } from '../../utils/Validator'

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/


const mapStateToProps = state => {
  return {
    loginData: state,
  };
}

class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      userName: '',
      password: '',
      is_rememberme: false,
      isLoading: false,
      is_showpass: false,
      isOpen: false,
      email: '',
      is_forgotPassword: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleObscure = this.handleObscure.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.forgetPassword = this.forgetPassword.bind(this)
  }

  componentDidMount() {
    clearLocalStorage('')
    localStorage.clear()
  }

  handleObscure() {
    const { is_showpass } = this.state
    this.setState({ is_showpass: !is_showpass })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { userName, password, } = this.state
    if (userName != ' ' && password != ' ') {
      this.setState({ isLoading: true })
      this.props
        .login_action(userName, password)
        .then((data) => {
          if (data.error != 1) {
            localStorage.setItem("userNameLS", userName)
            localStorage.setItem("roleIdLS", data.data['roleId'])

            if (password.includes('ASN')) {
              this.props.navigate("/changePassword", { state: { is_forgotPassword: true } });
            }
            else {
              this.props.navigate("/dashboard");
            }
            this.setState({ isLoading: false })
          }
          else {
            this.setState({ isLoading: false })
            toast.error(data.data, {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    else {
      this.setState({ isLoading: false })

      toast.error('Please check user name and password', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: true,
      })
    }
  }

  //toggle model for forgot password.................................
  toggleModal() {
    const { isOpen, } = this.state
    this.setState({ isOpen: !isOpen })

  }


  handleKeyDown(e) {
    if (e.code === 'Enter') {
      this.forgetPassword(e)
    }
  }

  forgetPassword(e) {
    e.preventDefault()
    const { email, isOpen } = this.state
    var errMsg = emailValidator(email)
    if (errMsg != '') {
      toast.error(errMsg, {
        theme: 'colored',
        autoClose: 1000,
        hideProgressBar: true,
      })
    } else {
      if (email != ' ') {
        this.setState({ isLoading: true })
        this.props
          .forgetPassword_action(email)
          .then((data) => {
            if (data.error != 1) {
              this.setState({ isLoading: false })
              if (data['data'].includes('OK')) {
                toast.success('Your new password has been sent to your email address. Please use that password to proceed.', {
                  theme: 'colored',
                  autoClose: 3000,
                  hideProgressBar: true,
                })
                this.toggleModal();
                this.setState({ is_forgotPassword: true, })

              }
            }
            else {
              this.setState({ isLoading: false, isOpen: !isOpen, email: '' })
              toast.error(data.msg, {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
              })
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
      else {
        this.setState({ isLoading: false })
        toast.error('Please check email id', {
          theme: 'colored',
          autoClose: 3000,
          hideProgressBar: true,
        })
      }


    }

  }



  render() {
    const { is_showpass, email, isOpen, is_forgotPassword } = this.state
    return (
      <div className="container-scroller">
        <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
          <div className="modal-dialog custom_modal_dialog" >
            <div className="modal-content" style={{ borderRadius: '0px', width:'400px' }}>
              <div class="modal-header">
                <h4 class="modal-title modal_title_text">Forget Password</h4>
                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

              </div>
              <form>
                <div class="modal-body">
                  <div class="row">
                    <div className="form-group">
                      <label>Enter Email Id</label>
                      <input type="email"
                        className="form-control"
                        placeholder=""
                        value={email}
                        onChange={(e) => this.setState({ email: e.target.value })}
                        onKeyDown={e => this.handleKeyDown(e)}
                        required />
                    </div>
                  </div>
                </div>
                <div class="modal-footer py-1">
                  <div class="col-md-12">
                    <div class="form-group text-right mod-fot">
                      <button type='submit' class="btn save_btn del-pop-save" onClick={(e) => this.forgetPassword(e)}> Submit</button>
                      <button class="btn btn-cancel footer-btn-inner" data-dismiss="modal"
                        style={{ marginLeft: '5px' }} onClick={this.toggleModal}> No</button>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </Modal>
        {this.state.isLoading ? (
          <Loader></Loader>
        ) : (
          null
        )}
        <div
          className="container-fluid page-body-wrapper full-page-wrapper login-img"
          style={{ background: "#eef7f9" }}
        >
          <div className="content-wrapper d-flex align-items-center auth px-0 ">
            <div className="container" style={{ zIndex: 999 }}>
              <div className="wrapper-login">
                <div className="title">
                  <span>
                    <img src="assets/MainHeaderLogo.png" />
                  </span>
                </div>
                <form action="#" onSubmit={this.handleSubmit}>
                  <div className="row">
                    <i className="fa fa-user-o" />
                    <input type="text" placeholder="User Name"
                      value={this.state.userName}
                      onChange={(e) => this.setState({ userName: e.target.value })}
                      required
                    />
                  </div>
                  <div className="row">
                    <i className="fa fa-lock" />
                    <input type={is_showpass ? "type" : "password"}
                      placeholder={is_forgotPassword ? "Enter password which got in an email" : "Password"}
                      value={this.state.password}
                      onChange={(e) => this.setState({ password: e.target.value })} required />
                    <i
                      className="fa fa-eye"
                      onClick={this.handleObscure}
                      style={{
                        position: "absolute",
                        right: 18,
                        padding: 0,
                        background: "none",
                        border: "none",
                        color: "#666",
                        fontSize: 15,
                        cursor: "pointer",
                        width: 0
                      }}
                    />
                  </div>
                  <div className="pass text-start">
                    <a href="#">
                      <input
                        className="form-check-input coupon_question"
                        type="checkbox"
                        name="coupon_question"
                        value={this.state.is_rememberme}
                      />{" "}
                      Remember me on this computer
                    </a>
                  </div>
                  <div className="row button">
                    {/* <a> */}
                      <button type="submit"  style={{
                      width: "100%",
                      background: "#0093d0",
                      textAlign: "center",
                      padding: "11px 0",
                      borderRadius: 10,
                      color: "#fff",
                      border:"none"
                    }} >Login </button>
                      {/* </a> */}

                  </div>
                  <div className="pass text-center">
                    <a style={{ color: "#5c8dc4" }}
                      onClick={this.toggleModal}>
                      Forgot Password
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* content-wrapper ends */}
        </div>

        {/* page-body-wrapper ends */}
      </div>
    );
  }


}

export default withRouter(connect(null,
  { login_action, forgetPassword_action })(Login));
