import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Loader from '../../../utils/Loader'
import { fill_action } from '../../../actions/master_action/checklist_action';
import { chapterListActionId, mcqStatusActionId, levelActionId, MCQ_Chapter } from '../../../utils/constants'
import { mcqChapterDetails_action, updatemcqChapter_action, addUpdatemcqChapter_action } from '../../../actions/mcq_action/mcq_chapter_action';
import { getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

class MCQChapterAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAccessToView: true,
            chapterID: '',
            workingDayTypeList: [],
            is_add: false,
            is_delete: false,
            is_update: false,
            chapter: '',
            IsActive : '',
        }
        this.getFillList = this.getFillList.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.mcqChapterDetails = this.mcqChapterDetails.bind(this)
    }

    componentDidMount = () => {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
            chapterID: this.props.location.state != null && this.props.location.state.chapterID != null ? this.props.location.state.chapterID : '',
            dayOfTheWeek: this.props.location.state != null && this.props.location.state.dayOfTheWeek != null ? this.props.location.state.dayOfTheWeek : '',
            // isAccessToView: localStorage.getItem('UserId') == '1' ? true : false,
        })
        this.props.location.state != null && this.props.location.state.is_update && this.mcqChapterDetails()

        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
          const focusedElement = document.activeElement;
          const { previouslyFocusedElement } = this.state;
    
          if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
          }
    
          if (focusedElement.classList.contains('form-control') ||
            focusedElement.classList.contains('js-example-basic-single') ||
            focusedElement.classList.contains('example-custom-input')) {
            focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
            this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
          }
        }
      };
    
    
      handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;
    
        if (previouslyFocusedElement) {
          previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }
    
        if (clickedElement.classList.contains('form-control') ||
          clickedElement.classList.contains('js-example-basic-single') ||
          clickedElement.classList.contains('example-custom-input')) {
          clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
          this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
          this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
      };
    

    //get details
    mcqChapterDetails() {
        setTimeout(() => {
            var id = this.state.chapterID
            this.props
                .mcqChapterDetails_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            chapter: data.data[0]['ChapterName'],
                            IsActive : data.data[0]['IsActive']
                        })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 500);
    }

    //Fill list
    getFillList(value, ref, ref2) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {

                }
            })
    }

    //cancel
    handleCancel() {
        this.props.navigate('/mcqchapterlist')
    }

    //add working day
    handleAdd(e) {
        e.preventDefault()
        const { chapter, chapterID} = this.state
        const id = chapterID

        if (chapter != "") {
            this.setState({ isLoading: true })
            this.props
                .addUpdatemcqChapter_action(id, chapter.trim())
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.handleCancel()
                        }, 500);
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //update working day
    handleUpdate(e) {
        e.preventDefault()
        const { chapterID, chapter} = this.state
        const id = chapterID

        if (id != "" && chapter != "" ) {
            this.setState({ isLoading: true })
            this.props
                .addUpdatemcqChapter_action(id, chapter.trim())
                .then((data) => {
                    if (data.error == 0) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.handleCancel()
                        }, 500);

                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    render() {
        const { is_update, isAccessToView, isLoading, chapter,IsActive } = this.state
        return (<>
            {isLoading ? (
                <Loader></Loader>
            ) : (
                null
            )}
            <Header />
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                    {/* Left side menu */}
                    <Drawer />
                    {/* end left menu */}
                    {/* start page */}
                    {isAccessToView ? (
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul class="breadcrumb d-flex">
                                        <li><a onClick={this.handleCancel}>MCQ Chapter Master</a></li>
                                        <li></li>
                                    </ul>
                                    {is_update ? <div class="status-top mr-2 text-dark" style={{position: 'absolute', right: '0px', top:'62'}}>
                                        <p>Status : <strong class="text-success">{IsActive ? 'Active' : 'Inactive' }</strong></p>
                                    </div> : ''}
                                </div>
                                    <style
                                        type="text/css"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                        }}
                                    />
                                            <div class="card-body p-2 pt-0">
                                                <div class="row m-0 user-status">

                                                </div>
                                                {/* <div class="d-flex flex-wrap row "> */}
                                                    <div class="form-group col-md-4">
                                                        <label>Chapter Name <span class="required1">*</span></label>
                                                        <input type="text" class="form-control" 
                                                        value={chapter} 
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (value.length <= 20) {
                                                                this.setState({ chapter: value });
                                                            }
                                                        }} 
                                                        />
                                                    </div>
                                                {/* </div> */}

                                                {/* {is_update ?  <div class="d-flex flex-wrap row mt-2 m-0 border pt-1 pb-2 rounded-1" style={{ background:'#f4f4f4', textAlign:'left'}}>
                                                    <h5 class="mt-1">System Info</h5>
                                                    <div class="form-group col-md-4 ">
                                                        <span>Created By: Admin</span>
                                                    </div>
                                                    <div class="form-group col-md-4 ">
                                                        <span>Modified By: Admin</span>
                                                    </div>
                                                    <div class="form-group col-md-4 ">
                                                        <span>Deleted Date: 10/03/2024</span>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <span>Created Date Time: 13/04/2024</span>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <span>Modified Date Time: 13/04/2024</span>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <span>Deleted Date Time: 13/04/2024</span>
                                                    </div>
                                                </div> : <></> } */}


                                                <div style={{height: '75px'}}></div>

                                                <div className="row">
                                                    <div className="footer-btn">
                                                        <div className="col-md-3">
                                                            <div
                                                                className="btn-group"
                                                                role="group"
                                                                aria-label="Basic example"
                                                            >
                                                                {is_update ? getModuleUpdateAccess(MCQ_Chapter) && <button
                                                                    type="button"
                                                                    className="btn btn-update footer-btn-inner mr-1"
                                                                    onClick={this.handleUpdate}
                                                                >
                                                                    Update
                                                                </button> : getModuleWriteAccess(MCQ_Chapter)&&<button
                                                                    type="button"
                                                                    className="btn btn-save footer-btn-inner mr-1"
                                                                    onClick={this.handleAdd}
                                                                >
                                                                    Add
                                                                </button>}
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-cancel footer-btn-inner"
                                                                    onClick={this.handleCancel}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                            </div>
                        </div>
                    ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)}
                    {/* end page */}
                </div>
                {/* page-body-wrapper ends */}
            </div>
            <ToastContainer />
        </>)
    }
}

export default withRouter(connect(null, { fill_action, mcqChapterDetails_action, updatemcqChapter_action, addUpdatemcqChapter_action })(MCQChapterAU))