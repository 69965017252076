import { DESIGNATION_ADD_ACTION_TYPE, DESIGNATION_DELETE_ACTION_TYPE, DESIGNATION_DETAILS_ACTION_TYPE, DESIGNATION_LIST_ACTION_TYPE, DESIGNATION_UPDATE_ACTION_TYPE , ROLEPLAYED_DELETE_ACTION_TYPE} from "../../utils/constants";

const initialState = []

function DesignationReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case DESIGNATION_LIST_ACTION_TYPE:
            return [...state, payload];
        case DESIGNATION_ADD_ACTION_TYPE:
            return [...state, payload];
        case DESIGNATION_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case DESIGNATION_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case DESIGNATION_DELETE_ACTION_TYPE:
            return [...state, payload];
        case ROLEPLAYED_DELETE_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}

export default DesignationReducer