import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    platfarm, headers,
    MCQ_LIST_ACTION_TYPE,
    MCQ_DETAILS_ACTION_TYPE,
    MCQ_UPDATE_ACTION_TYPE,
    MCQ_ADD_ACTION_TYPE,
    MCQ_DELETE_ACTION_TYPE,
    mcqListUrl,
    addMCQUrl, deleteMCQUrl,
    getMCQDetailsUrl,
    updateMCQUrl,
    TrainingStatus, fetchAuthReport
} from "../../utils/constants"
import moment from 'moment';



export const mcqChapterList_action = (statusId, chapter
) => async (dispatch) => {
    try {

        const param = {
            UserID: parseInt(localStorage.getItem('UserId')),
            EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            Platform: platfarm,
            Isactive: statusId != "" ? parseInt(statusId) : 1,
            Chaptername: chapter
        }
        console.log("mcqChapterList_action", param);
        const res = await axiosGet(mcqListUrl, param);
        dispatch({
            type: MCQ_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const mcqChapterDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: parseInt(localStorage.getItem('UserId')),
            EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            Platform: platfarm,
            Isactive:  1,
            Chapterid: id != "" ? parseInt(id) : null,
        }
        const res = await axiosGet(getMCQDetailsUrl, param);
        console.log("bloodGroupDetails_action", param)
        dispatch({
            type: MCQ_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
};

export const addUpdatemcqChapter_action = (id, chapterName) => async (dispatch) => {
    try {
        // new Date(moment(auditPeriodStartDate ).format('MM-DD-YYYY')+'Z').toISOString(),
        // moment(ToTrainingDate).format('MM-DD-YYYY')
        const mapdata = {
            UserID: parseInt(localStorage.getItem('UserId')),
            EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            Platform: platfarm,
            Chapterid: id != "" ? parseInt(id) : null,
            ChapterName: chapterName,
            Isactive: 1,

        }
        console.log("mcqChapterList_action", mapdata);
        const res = await axiosPost(addMCQUrl, mapdata, headers);
        dispatch({
            type: MCQ_ADD_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updatemcqChapter_action = (trainigAddList) => async (dispatch) => {
    try {
        // new Date(moment(auditPeriodStartDate ).format('MM-DD-YYYY')+'Z').toISOString(),
        // moment(ToTrainingDate).format('MM-DD-YYYY')
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            trainigAddList: trainigAddList,
        }
        const res = await axiosPost(updateMCQUrl, mapdata, headers);

        dispatch({
            type: MCQ_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const deletemcqChapter_action = (Chapterid) => async (dispatch) => {
    try {
        const param = {
            UserID: parseInt(localStorage.getItem('UserId')),
            EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            Platform: platfarm,
            Chapterid: Chapterid != "" ? parseInt(Chapterid) : null,

        }
        const res = await axiosGet(deleteMCQUrl, param);
        dispatch({
            type: MCQ_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

