import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    platfarm, headers,
    SETTING_LIST_ACTION_TYPE,
    SETTING_DETAILS_ACTION_TYPE,
    SETTING_UPDATE_ACTION_TYPE,
    SETTING_ADD_ACTION_TYPE,
    SETTING_DELETE_ACTION_TYPE,
    getSettingDetailsUrl,
    updateSettingUrl,
    mcqSettingListUrl, 
    deleteMCQSetting,
    addSettingUrl,
    TrainingStatus,fetchAuthReport
} from "../../utils/constants"
import moment from 'moment';



export const settingList_action = (text, value, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            Platform: platfarm,
            Text : text,
            Value : value,
            Isactive : status != "" ? status :  1
        }        
        const res = await axiosGet(mcqSettingListUrl, param);
        console.log("settingList_action", param)
        dispatch({
            type: SETTING_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const settingDetails_action = () => async (dispatch) => {
    try {
        const param = {
            Userid: localStorage.getItem('UserId'),
            Employeeid:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            Platform: platfarm,
            // ID : id,
        }        
        const res = await axiosGet(getSettingDetailsUrl, param);
        console.log("bloodGroupDetails_action", param)
        dispatch({
            type: SETTING_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const addSetting_action = (rows) => async (dispatch) => {
        try {
            const mapdata = {
                Userid: localStorage.getItem('UserId'),
                Employeeid:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
                Platform: platfarm,
                Rows : rows,
            }
            const res = await axiosPost(addSettingUrl, mapdata, headers);

            dispatch({
                type: SETTING_ADD_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    export const updateSetting_action = (rows) => async (dispatch) => {
        try {
            const mapdata = {
                Userid: localStorage.getItem('UserId'),
                Employeeid:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
                Platform: platfarm,
                Rows : rows,
            }
            const res = await axiosPost(updateSettingUrl, mapdata, headers);

            dispatch({
                type: SETTING_UPDATE_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };
    export const deleteSetting_action = (id) => async (dispatch) => {
        try {
            const param = {
                Userid: localStorage.getItem('UserId'),
                Employeeid:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
                Platform: platfarm,
                ID : parseInt(id),
    
            }
            const res = await axiosGet(deleteMCQSetting, param);
            dispatch({
                type: SETTING_DELETE_ACTION_TYPE,
                payload: res,
            });
    
            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };
    
    