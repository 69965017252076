import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    getLegalEntityListUrl,
    addLegalEntityUrl,
    updateLegalEntityUrl,
    getLegalEntityDetailsUrl,
    deleteLegalEntityUrl,
    platfarm,
    LEGALENTITY_LIST_ACTION_TYPE,
    LEGALENTITY_DETAILS_ACTION_TYPE,
    LEGALENTITY_ADD_ACTION_TYPE,
    LEGALENTITY_UPDATE_ACTION_TYPE,
    LEGALENTITY_DELETE_ACTION_TYPE,
    headers
} from "../../utils/constants"
import moment from 'moment';



export const legalEntityList_action = (legalEntityName, formatSearch, status) => async (dispatch) => {
    try {

        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Format : formatSearch,
            legalEntityName: legalEntityName,
            status: status != null ? status : 1
        }
        const res = await axiosGet(getLegalEntityListUrl, param);
        dispatch({
            type: LEGALENTITY_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const legalEntityDetail_action = (id ) => async (dispatch) => {
    try {
        const param = {
            ID: id,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }

        const res = await axiosGet(getLegalEntityDetailsUrl, param);
        dispatch({
            type: LEGALENTITY_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const addlegalEntityList_action = (legalEntityName, format) => async (dispatch) => {
    try {
        const mapdata = {
            LegalEntityType: legalEntityName,
            Format: format,
           UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }
        const res = await axiosPost(addLegalEntityUrl, mapdata, headers);

        dispatch({
            type: LEGALENTITY_ADD_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updatelegalEntityList_action = (id, legalEntityName, format) => async (dispatch) => {
    try {
        const mapdata = {
            Id: id,
            legalEntityName: legalEntityName,
            Format: format,
            DefaultValue: 0,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }
        const res = await axiosPost(updateLegalEntityUrl, mapdata, headers);

        dispatch({
            type: LEGALENTITY_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const deletelegalEntityList_action = (id) => async (dispatch) => {
    try {
        const param = {
            Id: id,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,

        }
        const res = await axiosGet(deleteLegalEntityUrl, param);
        dispatch({
            type: LEGALENTITY_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export default (state = {}, action) => {
    switch (action.type) {
        case 'SIMPLE_ACTION':
            return {
                result: action.payload
            }
        default:
            return state
    }
}