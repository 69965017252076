import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { fill_action } from '../../../actions/master_action/checklist_action'
import {
  reviewChecklist_action,
} from '../../../actions/engagement_action/review_checklist_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import { engagementStatus, clientEngagementStatusActionId, clearLocalStorage, Review_Checklist } from '../../../utils/constants'
import Pagination from '../../../utils/pagination'
import { getModuleUpdateAccess } from '../../../utils/RoleAccess';

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
  ...state
})

class ReviewChecklistList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clientEngagementName: '',

      engPartnerName: '',
      qualityReviewerName: '',
      engagementTypeName: '',
      engagementTypeNameList: [],
      eq: '',
      status: '',
      isOpen: false,
      reviewChecklistDeleteId: '',


      finYear: '',
      clientName: '',
      status: '',
      statusList: [],
      reviewChecklist: [],
      currentPage: 1,
      tablePerPage: 10,
      recordsPerPage: 10,
      pageSizeInput: "10",

      finYearNameRCLS: '',
      clientNameRCLS: '',
      statusRCLS: '',

      isClientNameSort: false,
      isFinancialYearSort: false,
      isEngagementTypeNameSort: false,
      isLocationSort: false,
      isFromDateSort: false,
      isToDateSort: false,
      isStatusSort: false,
      isSearchTab: false,
    }
    this.handleSearch = this.handleSearch.bind(this)

    this.handleUpdate = this.handleUpdate.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.getReviewChecklist = this.getReviewChecklist.bind(this)
    this.getFillList = this.getFillList.bind(this)
    this.updateItem = this.updateItem.bind(this);
    this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
    this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);
    this.record = this.record.bind(this)
    this.clearValue = this.clearValue.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)

    this.clientNameSort = this.clientNameSort.bind(this)
    this.financialYearSort = this.financialYearSort.bind(this)
    this.engagementTypeNameSort = this.engagementTypeNameSort.bind(this)
    this.locationSort = this.locationSort.bind(this)
    this.fromDateSort = this.fromDateSort.bind(this)
    this.toDateSort = this.toDateSort.bind(this)
    this.statusSort = this.statusSort.bind(this)

  }


  componentDidMount = () => {
    this.setState({
      clientNameRCLS: localStorage.getItem('clientNameRCLS'),
      finYearNameRCLS: localStorage.getItem('finYearNameRCLS'),
      statusRCLS: localStorage.getItem('statusRCLS'),
      clientName: localStorage.getItem('clientNameRCLS') != '' ? localStorage.getItem('clientNameRCLS') : '',
      finYear: localStorage.getItem('finYearNameRCLS') != '' ? localStorage.getItem('finYearNameRCLS') : '',
      status: localStorage.getItem('statusRCLS') != '' ? localStorage.getItem('statusRCLS') : '',
    })
    clearLocalStorage("reviewChecklist")
    setTimeout(() => {
      this.getReviewChecklist()
    }, 100);
    this.getFillList(engagementStatus, '')
    // document.addEventListener('keydown', this.handleTabKeyPress);
    // document.addEventListener('click', this.handleInputClick);

  }

  componentWillUnmount() {
    // document.removeEventListener('keydown', this.handleTabKeyPress);
    // document.removeEventListener('click', this.handleInputClick);

  }



  handleTabKeyPress = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
      const focusedElement = document.activeElement;
      const { previouslyFocusedElement } = this.state;

      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }

      if (focusedElement.classList.contains('form-control') ||
        focusedElement.classList.contains('js-example-basic-single') ||
        focusedElement.classList.contains('example-custom-input')) {
        focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
        this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
      }
    }
  };


  handleInputClick = (event) => {
    const { previouslyFocusedElement } = this.state;
    const clickedElement = event.target;

    if (previouslyFocusedElement) {
      previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    }

    if (clickedElement.classList.contains('form-control') ||
      clickedElement.classList.contains('js-example-basic-single') ||
      clickedElement.classList.contains('example-custom-input')) {
      clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
      this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
    } else {
      this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
    }
  };

  //sorting 

  statusSort() {
    const { reviewChecklist, isStatusSort } = this.state
    this.setState({ isStatusSort: !isStatusSort })
    if (!isStatusSort) {
      // sorting
      var statusSort = reviewChecklist.sort((a, b) => {
        const nameA = a.Status; // ignore upper and lowercase
        const nameB = b.Status; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ reviewChecklist: statusSort })
    }
    else {
      // reverse
      var statusSort = reviewChecklist.sort((a, b) => {
        const nameA = a.Status; // ignore upper and lowercase
        const nameB = b.Status; // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ reviewChecklist: statusSort })
    }
    this.setState({ isStatusSort: !isStatusSort })
  }

  toDateSort() {
    const { reviewChecklist, isToDateSort } = this.state
    this.setState({ isToDateSort: !isToDateSort })
    if (!isToDateSort) {
      // sorting
      var toDateSort = reviewChecklist.sort((a, b) => {
        const nameA = a.EndDate.toUpperCase(); // ignore upper and lowercase
        const nameB = b.EndDate.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ reviewChecklist: toDateSort })
    }
    else {
      // reverse
      var toDateSort = reviewChecklist.sort((a, b) => {
        const nameA = a.EndDate.toUpperCase(); // ignore upper and lowercase
        const nameB = b.EndDate.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ reviewChecklist: toDateSort })
    }
    this.setState({ isToDateSort: !isToDateSort })
  }

  fromDateSort() {
    const { reviewChecklist, isFromDateSort } = this.state
    this.setState({ isFromDateSort: !isFromDateSort })
    if (!isFromDateSort) {
      // sorting
      var fromDateSort = reviewChecklist.sort((a, b) => {
        const nameA = a.StartDate.toUpperCase(); // ignore upper and lowercase
        const nameB = b.StartDate.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ reviewChecklist: fromDateSort })
    }
    else {
      // reverse
      var fromDateSort = reviewChecklist.sort((a, b) => {
        const nameA = a.StartDate.toUpperCase(); // ignore upper and lowercase
        const nameB = b.StartDate.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ reviewChecklist: fromDateSort })
    }
    this.setState({ isFromDateSort: !isFromDateSort })
  }

  locationSort() {
    const { reviewChecklist, isLocationSort } = this.state
    this.setState({ isLocationSort: !isLocationSort })
    if (!isLocationSort) {
      // sorting
      var locationSort = reviewChecklist.sort((a, b) => {
        const nameA = a.LocationName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.LocationName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ reviewChecklist: locationSort })
    }
    else {
      // reverse
      var locationSort = reviewChecklist.sort((a, b) => {
        const nameA = a.LocationName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.LocationName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ reviewChecklist: locationSort })
    }
    this.setState({ isLocationSort: !isLocationSort })
  }

  engagementTypeNameSort() {
    const { reviewChecklist, isEngagementTypeNameSort } = this.state
    this.setState({ isEngagementTypeNameSort: !isEngagementTypeNameSort })
    if (!isEngagementTypeNameSort) {
      // sorting
      var engagementTypeNameSort = reviewChecklist.sort((a, b) => {
        const nameA = a.EngagementType.toUpperCase();  // ignore upper and lowercase
        const nameB = b.EngagementType.toUpperCase();  // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ reviewChecklist: engagementTypeNameSort })
    }
    else {
      // reverse
      var engagementTypeNameSort = reviewChecklist.sort((a, b) => {
        const nameA = a.EngagementType.toUpperCase(); // ignore upper and lowercase
        const nameB = b.EngagementType.toUpperCase();  // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ reviewChecklist: engagementTypeNameSort })
    }
    this.setState({ isEngagementTypeNameSort: !isEngagementTypeNameSort })
  }

  financialYearSort() {
    const { reviewChecklist, isFinancialYearSort } = this.state
    this.setState({ isFinancialYearSort: !isFinancialYearSort })
    if (!isFinancialYearSort) {
      // sorting
      var financialYearSort = reviewChecklist.sort((a, b) => {
        const nameA = a.FinancialYear.toUpperCase(); // ignore upper and lowercase
        const nameB = b.FinancialYear.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ reviewChecklist: financialYearSort })
    }
    else {
      // reverse
      var financialYearSort = reviewChecklist.sort((a, b) => {
        const nameA = a.FinancialYear.toUpperCase(); // ignore upper and lowercase
        const nameB = b.FinancialYear.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ reviewChecklist: financialYearSort })
    }
    this.setState({ isFinancialYearSort: !isFinancialYearSort })
  }

  clientNameSort() {
    const { reviewChecklist, isClientNameSort } = this.state
    this.setState({ isClientNameSort: !isClientNameSort })
    if (!isClientNameSort) {
      // sorting
      var clientNameSort = reviewChecklist.sort((a, b) => {
        const nameA = a.ClientName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.ClientName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ reviewChecklist: clientNameSort })
    }
    else {
      // reverse
      var clientNameSort = reviewChecklist.sort((a, b) => {
        const nameA = a.ClientName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.ClientName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ reviewChecklist: clientNameSort })
    }
    this.setState({ isClientNameSort: !isClientNameSort })
  }

  // get fill List dropdown  
  getFillList(id, reference) {
    this.setState({ isLoading: true })
    this.props
      .fill_action(id, reference)
      .then((data) => {
        if (data.error != 1) {
          if (id === engagementStatus) {
            this.setState({
              statusList: data.data
            })
          }
          this.setState({
            isLoading: false
          })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }



  // get engagement allocation list
  getReviewChecklist() {
    const { finYear, clientName, status } = this.state
    // get clientEngagement list default
    this.setState({ isLoading: true })
    this.props
      .reviewChecklist_action(finYear, clientName, status === '' ? 1 : status)
      .then((data) => {
        if (data.error != 1) {
          this.setState({ reviewChecklist: data.data, isLoading: false })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // search list
  handleSearch() {
    // e.preventDefault()
    const { clientName, finYear, status } = this.state
    clearLocalStorage("reviewChecklist")

    this.setState({ isLoading: true })
    this.props
      .reviewChecklist_action(finYear, clientName, status === '' ? 1 : status)
      .then((data) => {
        if (data.error != 1) {
          this.setState({ reviewChecklist: data.data, isLoading: false, currentPage: 1, })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
    localStorage.setItem("clientNameRCLS", clientName)
    localStorage.setItem("finYearNameRCLS", finYear)
    localStorage.setItem("statusRCLS", status)
  }

  clearValue() {
    this.setState({
      clientName: '',
      finYear: '',
      status: ' ',
      reviewChecklist: [],
      currentPage: 1,
      clientNameRCLS: '',
      finYearNameRCLS: '',
      statusRCLS: '',
    })
    clearLocalStorage('')
    setTimeout(() => {
      this.getReviewChecklist()
    }, 300);

  }


  // update list
  handleUpdate(reviewChecklistId) {
    this.props.navigate('/reviewChecklistU', { state: { is_update: true, reviewChecklistId: reviewChecklistId } })
  }


  //toggle model for delete.................................
  toggleModal(reviewChecklistId) {
    const { isOpen, reviewChecklistDeleteId } = this.state
    this.setState({ isOpen: !isOpen })
    if (reviewChecklistId == '') {
      return;
    } else {
      this.setState({ reviewChecklistDeleteId: reviewChecklistId })
    }

  }
  handleKeyDown(e) {
    if (e.code === 'Enter') {
      this.handleSearch()
    }
  }
  updateItem(item) {
    this.setState({ currentPage: item });
  }

  updateRecordsPerPage = (newSize) => {
    const parsedSize = parseInt(newSize, 10);
    if (!isNaN(parsedSize) && parsedSize > 0) {
      this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
    }
    this.setState({ pageSizeInput: newSize }); // Keep the input value updated
  };

  handlePageSizeInputChange = (event) => {
    const input = event.target.value;
    if (/^\d*$/.test(input)) { // Only allow digits
      this.setState({ pageSizeInput: input });
    }
  };


  onSearchTabClick() {
    const { isSearchTab, } = this.state;
    console.log("onSearchTabClick", isSearchTab)
    this.setState({ isSearchTab: !isSearchTab })
  }

  record(reviewChecklist) {
    return (

      <table className="table table-bordered tb-action du-table">
        {/* <button onClick={this.handleUpdate(1)}>update</button> */}
        <thead>
          <tr>
            <th style={{ width: '60px' }} />
            <th style={{ textAlign: 'left' }} onClick={this.clientNameSort}>Client</th>
            <th onClick={this.financialYearSort}>Financial Year</th>
            <th onClick={this.engagementTypeNameSort}>Engagement Type</th>
            <th onClick={this.locationSort}>Location</th>
            <th onClick={this.fromDateSort}>From Date</th>
            <th onClick={this.toDateSort}>To Date</th>
            <th style={{ width: '70px' }} onClick={this.statusSort}>Status</th>
            {/* <th></th> */}
          </tr>
        </thead>
        <tbody>

          {
            reviewChecklist.length > 0 ?
              reviewChecklist.map((data, index) => (
                <>
                  <tr>
                    <td className="text-center" style={{ padding: '5px!important' }}>
                      <div className="table__button-group">
                        {getModuleUpdateAccess(Review_Checklist) ?
                          <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleUpdate(data.EngagementID)} /></a> 
                          : <a><i className="fa fa-eye" style={{ color: '#3498db' }} onClick={() => this.handleUpdate(data.EngagementID)} /></a>
                        }
                      </div>
                    </td>
                    <td style={{ textAlign: 'left' }}>{data.ClientName}</td>
                    <td>{data.FinancialYear}</td>
                    <td>{data.EngagementType}</td>
                    <td style={{ textAlign: 'left' }}>{data.LocationName}</td>
                    <td>{moment(data.StartDate).format('DD-MM-YYYY')}</td>
                    <td>{moment(data.EndDate).format('DD-MM-YYYY')}</td>
                    <td>{data.Status}</td>
                    {/* <td class="text-center" style={{ padding: '5px!important' }}>
                      <a href="" class="sac"><i class="fa fa-spinner" title="status of audit report" style={{ color: '#242424' }}></i></a>
                      <a href=""><i class="fa fa-check-circle-o" title="summary report of audit closure" ></i></a>
                    </td> */}
                  </tr></>
              )) : <tr><td colSpan={19} style={{ textAlign: 'center' }}>Record not found</td></tr>
          }

        </tbody>
      </table>
    )
  }

  render() {
    const { isSearchTab, reviewChecklist, tablePerPage, isLoading, reviewChecklistDeleteId,
      isOpen, status, statusList, clientName, finYear } = this.state
    const { currentPage, recordsPerPage, pageSizeInput } = this.state;

    // const recordsPerPage = 10
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    // Records to be displayed on the current page
    const currentRecords = reviewChecklist.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(reviewChecklist.length / recordsPerPage)


    return (
      <>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          null
        )}
        <Header />
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper">
            {/* Left side menu */}
            <Drawer />
            {/* end left menu */}
            {/* start page */}
            <div className="main-panel main-box">
              <div className="content-wrapper">
                <div className="main-breadcum">
                  <ul className="breadcrumb">
                    <li><a href="#">Review Checklist</a></li>
                    <li />
                  </ul>
                </div>
                <div className='page-space-from-bottom'>
                  <div className="row">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                    <div className="col-md-12 pg-body grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <div className="accordion accordion-flush " id="accordionFlushExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                  <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                </button>
                              </h2>
                              {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                              <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                <div className="d-flex flex-wrap row">
                                  <div className="form-group col-md-3">
                                    <label>Client </label>
                                    <input type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={clientName}
                                      onChange={(e) => this.setState({ clientName: e.target.value })}
                                      onKeyDown={e => this.handleKeyDown(e)}

                                      required />
                                  </div>
                                  <div className="form-group col-md-3">
                                    <label>Financial Year</label>
                                    <input type="text"
                                      className="form-control"
                                      placeholder=''
                                      value={finYear}
                                      onChange={(e) => this.setState({ finYear: e.target.value })}
                                      onKeyDown={e => this.handleKeyDown(e)}
                                      required />
                                  </div>

                                  <div className="form-group col-md-3">
                                    <label>Status</label>
                                    <select className="js-example-basic-single" style={{ width: '100%' }}
                                      value={status} onChange={e => this.setState({ status: e.target.value })}>
                                      <option value={''}>Select</option>
                                      {statusList.map(item => (
                                        <option
                                          key={item.EngagementStatusID}
                                          value={item.EngagementStatusID}>
                                          {item.Name}
                                        </option>
                                      ))}

                                    </select>
                                  </div>
                                  <div className="form-group col-md-3 mt-4 text-left">
                                    <button type="button" className="btn btn-Add rounded-1"onClick={this.handleSearch}> Search</button>
                                    <button type="button" className="btn btn-clear rounded-1 mx-1 px-3" onClick={this.clearValue}> Clear</button>

                                  </div>
                                </div>
                              </div>
                              </div> : <></>}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 mt-2">

                              <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                {this.record(currentRecords)}
                                {reviewChecklist.length > 0 ? <Pagination
                                  nPages={nPages}
                                  currentPage={currentPage}
                                  setCurrentPage={this.updateItem}
                                  recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                  setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                  totalRecords={reviewChecklist.length}
                                /> : <></>}

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end page */}
          </div>
          {/* page-body-wrapper ends */}
        </div>
        <ToastContainer />

      </>
    );
  }
}

export default withRouter(connect(null, {
  reviewChecklist_action,
  fill_action
})(ReviewChecklistList));