
import { BLOODGROUP_LIST_ACTION_TYPE, BLOODGROUP_DETAILS_ACTION_TYPE, BLOODGROUP_ADD_ACTION_TYPE, BLOODGROUP_UPDATE_ACTION_TYPE, BLOODGROUP_DELETE_ACTION_TYPE } from "../../utils/constants";

const initialState = []

function bloodGroupReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case BLOODGROUP_LIST_ACTION_TYPE:
            return [...state, payload];
        case BLOODGROUP_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case BLOODGROUP_ADD_ACTION_TYPE:
            return [...state, payload];
        case BLOODGROUP_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case BLOODGROUP_DELETE_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}

export default bloodGroupReducer