import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import { engagementRoleList_action, deleteEngagementRole_action } from '../../../actions/master_action/engagementRole_action'
import { fill_action } from '../../../actions/master_action/checklist_action'
import { clearLocalStorage, Master, reportingListActionId } from '../../../utils/constants'
import Pagination from "../../../utils/pagination";
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';
// import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

class EngagementRoleList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            isOpen: false,
            isLoading: false,
            IsActive: 0,
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage: 10,
            pageSizeInput: "10",
            engagementRoleList: [],
            engagementRoleDeleteId: '',
            engagementRoleId: "",
            engagementRoleName: "",
            reportingId: "",
            MaxCount: "",
            order: "",
            status: "",
            engagementRoleNameERLS: '',
            reportingIdERLS: '',
            statusERLS: '',
            MaxCountERLS: '',
            orderERLS: '',
            reportingList: [],
            isSearchTab: false,

        }
        this.getEngageMentRoleList = this.getEngageMentRoleList.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);

        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
    }

    componentDidMount = () => {
        this.getFillList(reportingListActionId, '')
        this.setState({
            isAccessToView: true,
            engagementRoleNameERLS: localStorage.getItem("engagementRoleNameERLS"),
            reportingIdERLS: localStorage.getItem("reportingIdERLS"),
            statusERLS: localStorage.getItem("statusERLS"),
            MaxCountERLS: localStorage.getItem("MaxCountERLS"),
            orderERLS: localStorage.getItem("orderERLS"),

            engagementRoleName: localStorage.getItem("engagementRoleNameERLS"),
            reportingId: localStorage.getItem("reportingIdERLS"),
            status: localStorage.getItem("statusERLS"),
            MaxCount: localStorage.getItem("MaxCountERLS"),
            order: localStorage.getItem("orderERLS"),

        })
        // localStorage.setItem("engagementRoleNameERLS", engagementRoleName)
        // localStorage.setItem("reportingIdERLS", reportingId)
        // localStorage.setItem("MaxCountERLS", MaxCount)
        // localStorage.setItem("orderERLS", order)
        // localStorage.setItem("statusERLS", status)
        clearLocalStorage('engagementRoleName')
        setTimeout(() => {
            this.getEngageMentRoleList()
        }, 100);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }



    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };


    updateItem(item) {
        this.setState({ currentPage: item });
    }
    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
            this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
    };

    handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
            this.setState({ pageSizeInput: input });
        }
    }
    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    getFillList(value, ref, ref2) {

        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != ''  ? ref : '',
                ref2 != ''  ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == reportingListActionId) {
                        this.setState({ reportingList: data.data, isLoading: false })
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }



    // designation List
    getEngageMentRoleList() {
        const { engagementRoleName, status, reportingId, MaxCount, order } = this.state
        this.setState({ isLoading: true })
        this.props
            .engagementRoleList_action(engagementRoleName.trim(), reportingId, MaxCount, order, 1)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ engagementRoleList: data.data, isLoading: false, currentPage: 1 })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
        localStorage.setItem("engagementRoleNameERLS", engagementRoleName)
        localStorage.setItem("reportingIdERLS", reportingId)
        localStorage.setItem("MaxCountERLS", MaxCount)
        localStorage.setItem("orderERLS", order)
        localStorage.setItem("statusERLS", status)
    }

    //clear value
    clearValue() {
        this.setState({
            engagementRoleList: [],
            engagementRoleNameERLS: '',
            reportingIdERLS: '',
            statusERLS: '1',
            MaxCountERLS: '',
            orderERLS: '',
            order: '',
            engagementRoleName: '',
            reportingId: '',
            MaxCount: '',
            status: '1',
            currentPage: 1,
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getEngageMentRoleList()
        }, 300);
    }

    //search
    handleSearch() {
        const { engagementRoleName, status, reportingId, MaxCount, order } = this.state
        clearLocalStorage("engagementRoleName")
        this.setState({ isLoading: true })
        this.props
            .engagementRoleList_action(engagementRoleName.trim(), reportingId, MaxCount, order, status == '' ? 1 : status)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ engagementRoleList: data.data, isLoading: false, currentPage: 1 })
                    console.log(data)
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
        localStorage.setItem("engagementRoleNameERLS", engagementRoleName)
        localStorage.setItem("reportingIdERLS", reportingId)
        localStorage.setItem("MaxCountERLS", MaxCount)
        localStorage.setItem("orderERLS", order)
        localStorage.setItem("statusERLS", status)

    }



    // add list 
    handleAdd() {
        this.props.navigate('/EngagementRoleAU', { state: { is_add: true } })
    }

    // update list 
    handleUpdate(engagementRoleId) {
        this.props.navigate('/EngagementRoleAU', { state: { is_update: true, is_Disable: true, engagementRoleId: engagementRoleId } })
    }

    //toggal for delete
    toggleModal(engagementRoleId) {
        console.log("toggleModal", engagementRoleId)
        const { isOpen, engagementRoleDeleteId } = this.state
        this.setState({ isOpen: !isOpen })
        if (engagementRoleId == '') {
            return
        }
        else {
            this.setState({ engagementRoleDeleteId: engagementRoleId })
        }
    }

    //delete handle
    handleDelete(e, engagementRoleId) {
        console.log('handleDelete 89');
        e.preventDefault()
        var id = engagementRoleId
        this.setState({ isLoading: true })
        this.props
            .deleteEngagementRole_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log("msg delete ")
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    setTimeout(() => {
                        this.setState({ isOpen: !this.state.isOpen, isLoading: false })
                        this.getEngageMentRoleList()
                    }, 1000);
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }


    record(engagementRoleList) {
        return (
            <table className="table table-bordered tb-action du-table -50">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ textAlign: 'left' }}>Engagement Role</th>
                        <th style={{ textAlign: 'left' }}>Reporting</th>
                        <th style={{ textAlign: 'left' }}>Max Count</th>
                        <th style={{ textAlign: 'left' }}>Order</th>
                        <th style={{ width: '100px' }}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {engagementRoleList.length > 0 ? (
                        engagementRoleList.map((data, i) => (
                            <tr key={data.ID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        {getModuleUpdateAccess(Master) ?
                                            <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleUpdate(data.ID)} /></a> :
                                            <a><i className="fa fa-eye" onClick={() => this.handleUpdate(data.ID)} /></a>
                                        }
                                        {data.IsActive == 1 && getModuleDeleteAccess(Master) ? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.ID)} /></span></a>
                                            : <></>}
                                    </div>
                                </td>
                                <td style={{ textAlign: 'left' }}>{data.Role}</td>
                                <td style={{ textAlign: 'left' }}>{data.Parent}</td>
                                <td style={{ textAlign: 'left' }}>{data.MaxCount}</td>
                                <td style={{ textAlign: 'left' }}>{data.DisplayOrder}</td>
                                <td style={{ textAlign: 'center' }}>{data.IsActive ? "Active" : "Inactive"}</td>
                            </tr>
                        ))

                    ) : (<tr><td colSpan={5} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }

    render() {
        const { isSearchTab, reportingList, engagementRoleList, engagementRoleId, isAccessToView, isLoading, engagementRoleDeleteId, isOpen, MaxCount, order, reportingId, engagementRoleName, status } = this.state
        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = engagementRoleList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(engagementRoleList.length / recordsPerPage)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />

                <ToastContainer />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>Engagement Role</a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className='page-space-from-bottom'>
                                        <div className="row">
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n.accordion-button::after {\nbackground-size: 13px;\ndisplay: none;\n} \n" }} />
                                            <div className="col-md-12 pg-body grid-margin stretch-card" >
                                                <div className="card">
                                                    <div className="card-body pr-2">
                                                        <div className="accordion accordion-flush " id="accordionFlushExample">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingOne">
                                                                    <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                                                        <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                    </button>
                                                                </h2>
                                                                {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                                <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                    <div className="d-flex flex-wrap row">

                                                                        <div className="form-group col-md-2">
                                                                            <label>Engagement Role</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="Engagement Role"
                                                                                value={engagementRoleName}
                                                                                onChange={(e) => this.setState({ engagementRoleName: e.target.value })}
                                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                                required />
                                                                        </div>

                                                                        <div className="form-group col-md-2">
                                                                            <label>Reporting</label>
                                                                            <select className="js-example-basic-single" style={{ width: '100%' }}
                                                                                value={reportingId}
                                                                                onChange={(e) => this.setState({ reportingId: e.target.value })}>
                                                                                <option value={''}>Select</option>
                                                                                {reportingList.length > 0 && reportingList.map(data => (
                                                                                    <option value={data.Code} key={data.Code}>
                                                                                        {data.Description}</option>))
                                                                                }
                                                                            </select>
                                                                        </div>

                                                                        {/* <div className="form-group col-md-2">
                                                                            <label>Max Count</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="Max Count"
                                                                                value={MaxCount}
                                                                                onChange={(e) => this.setState({ MaxCount: e.target.value })}
                                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                                required />
                                                                        </div>

                                                                        <div className="form-group col-md-2">
                                                                            <label>Order</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="Order"
                                                                                value={order}
                                                                                onChange={(e) => this.setState({ order: e.target.value })}
                                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                                required />
                                                                        </div> */}

                                                                        <div className="form-group col-md-2">
                                                                            <label>Status</label>
                                                                            <select className="js-example-basic-single" style={{ width: '100%' }}
                                                                                value={status}
                                                                                onChange={(e) => this.setState({ status: e.target.value })}>
                                                                                <option value={''}>Select</option>
                                                                                <option value={1}>Active</option>
                                                                                <option value={0}>Inactive</option>
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group col-md-2 filter-btn-col p-0">
                                                                            <label></label>
                                                                            <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={e => this.handleSearch()}> Search</button>
                                                                            <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3" onClick={e => this.clearValue()}> Clear</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div> : <></>}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 mt-1">
                                                                {getModuleWriteAccess(Master) && <button type="button" className="btn btn-Add rounded-1 float-right mb-1 px-3" onClick={(e) => this.handleAdd(e)}> Add</button>
                                                                }
                                                            </div>
                                                            <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                                {this.record(currentRecords)}
                                                                {engagementRoleList.length > 0 ? <Pagination
                                                                    nPages={nPages}
                                                                    currentPage={currentPage}
                                                                    setCurrentPage={this.updateItem}
                                                                    recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                    setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                    totalRecords={engagementRoleList.length}
                                                                /> : <></>}
                                                            </div>
                                                        </div>
                                                    </div >
                                                </div >
                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </div >
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)
                        }
                        {/* end page */}
                    </div >
                    {/* page-body-wrapper ends */}
                </div >
                {/* // modal for delete */}
                < Modal isOpen={isOpen} onRequestClose={this.toggleModal} >
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, engagementRoleDeleteId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal >

            </>
        );
    }

}

export default withRouter(connect(null, { fill_action, engagementRoleList_action, deleteEngagementRole_action })(EngagementRoleList))