import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    platfarm, headers,
    TIMESHEET_ACTION_TYPE,
    TIMESHEET_DETAILS_ACTION_TYPE,
    TIMESHEET_ADD_ACTION_TYPE,
    TIMESHEET_UPDATE_ACTION_TYPE,
    TIMESHEET_DELETE_ACTION_TYPE,
    TIMESHEET_APPROVAL_ACTION_TYPE,
    timesheetUrl, timesheetDetailsUrl,
    addTimesheetUrl, deleteTimesheetUrl,
    updateTimesheetUrl, fillListUrl, FILL_LIST_ACTION_TYPE, fetchAuthReport, engAllocationReportName,
    approvalTimesheetUrl,
    fetchLastTimeSheetExpenseUrl, EXPENSE_LASTTIMESHEET_FILL_ACTION_TYPE,
    LAST_TIMESHEET_FILL_ACTION_TYPE, lastTimesheetFillUrl,
    SINGLE_LAST_TIMESHEET_DATE_ACTION_TYPE, singleLastTimesheetDateUrl
} from "../../utils/constants"
import moment from "moment";



export const timesheet_action = (clientName, finYearId, engId, fromDate, toDate, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientID: clientName !='' ? clientName : null,
            FinyearID: finYearId !='' ? finYearId : null,
            EngagementID: engId !='' ? engId : null,
            FromDate: fromDate != null && fromDate != '' ? moment(fromDate).format('YYYY-MM-DD') : null,
            ToDate: toDate != null && toDate != '' ? moment(toDate).format('YYYY-MM-DD') : null,
            status: status
        }
        const res = await axiosGet(timesheetUrl, param);
        dispatch({
            type: TIMESHEET_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const TimesheetDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            ID: id,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }

        const res = await axiosGet(timesheetDetailsUrl, param);
        dispatch({
            type: TIMESHEET_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const addTimesheet_action = (transactionForId, employeeId, clientNameId, finYearId,
    engagementId, taskId, transactionTypeId, description, date, fromTime, toTime,
    remark, rejectionReason, isVerified) => async (dispatch) => {
        try {
            const mapdata = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,

                TransactionForID: transactionForId,
                EmployeeID: employeeId,
                ClientID: clientNameId,
                FinancialYearID: finYearId,
                EngagementID: engagementId,

                TaskID: taskId,
                TransactionTypeID: transactionTypeId,
                Description: description,
                Remark: remark,
                TimeSheetDate: moment(date).format('YYYY-MM-DD'),
                // fromDate: new Date(moment(fromDate).format('MM-DD-YYYY')+'Z').toISOString(),
                // toDate: new Date(moment(toDate).format('MM-DD-YYYY')+'Z').toISOString(),
                FromTime: fromTime,
                ToTime: toTime,
                IsVerified: isVerified
            }
            console.log("addTimesheet_action******", mapdata)
            const res = await axiosPost(addTimesheetUrl, mapdata, headers);

            dispatch({
                type: TIMESHEET_ADD_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const updateTimesheet_action = (id, transactionForId, employeeId, clientNameId, finYearId,
    engagementId, taskId, transactionTypeId, description, date, fromTime, toTime,
    remark, rejectionReason) => async (dispatch) => {
        try {
            const mapdata = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                ID: id,

                TransactionForID: transactionForId,
                EmployeeID: employeeId,
                ClientID: clientNameId,
                FinancialYearID: finYearId,
                EngagementID: engagementId,

                TaskID: taskId,
                Description: description,
                Remark: remark,
                TransactionTypeID: transactionTypeId,
                Date: moment(date).format('YYYY-MM-DD'),
                // fromDate: new Date(moment(fromDate).format('MM-DD-YYYY')+'Z').toISOString(),
                // toDate: new Date(moment(toDate).format('MM-DD-YYYY')+'Z').toISOString(),
                FromTime: fromTime,
                ToTime: toTime,
                // RejectReason : rejectionReason,
                // Status : status
            }
            const res = await axiosPost(updateTimesheetUrl, mapdata, headers);

            dispatch({
                type: TIMESHEET_UPDATE_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const deleteTimesheet_action = (id) => async (dispatch) => {
    try {
        const param = {
            Id: id,
            userId: localStorage.getItem('UserId'),
            platform: platfarm,

        }
        const res = await axiosGet(deleteTimesheetUrl, param);
        dispatch({
            type: TIMESHEET_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};



export const fill_action = () => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
        }
        const res = await axiosGet(fillListUrl, param);
        dispatch({
            type: FILL_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const approvalTimesheet_action = (clientName, finYearId, EmployeeID, engId, TransactionForID, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientID: clientName,
            FinYearID: finYearId,
            EmployeeID: EmployeeID,
            EngagementID: engId,
            TransactionForID: TransactionForID,
            Status: status
        }

        const res = await axiosGet(approvalTimesheetUrl, param);
        dispatch({
            type: TIMESHEET_APPROVAL_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};



export const simpleAction = () => dispatch => {
    dispatch({
        type: 'SIMPLE_ACTION',
        payload: 'result_of_simple_action'
    })
}


export const LastTimesheetFetch_action = (engagementID) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            EngagementID: engagementID,
        }

        const res = await axiosGet(fetchLastTimeSheetExpenseUrl, param);
        dispatch({
            type: EXPENSE_LASTTIMESHEET_FILL_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const lastTimesheetFill_action = (EngagementID) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            EngagementID: EngagementID,
            Platform: platfarm,
        }

        const res = await axiosGet(lastTimesheetFillUrl, param);
        dispatch({
            type: LAST_TIMESHEET_FILL_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const singleLastTimesheetDate_action = () => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }

        const res = await axiosGet(singleLastTimesheetDateUrl, param);
        dispatch({
            type: SINGLE_LAST_TIMESHEET_DATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};