import { axiosGet, axiosPost } from "../../react_axios/Axios"
import { clientListUrl, clientDetailsUrl, addClientUrl, deleteClientUrl, updateClientUrl, platfarm, CLIENT_LIST_ACTION_TYPE, CLIENT_LIST_DETAILS_ACTION_TYPE, CLIENT_LIST_ADD_ACTION_TYPE, CLIENT_LIST_UPDATE_ACTION_TYPE, CLIENT_LIST_DELETE_ACTION_TYPE, CLIENT_COMPANY_LIST_ACTION_TYPE, clientConpanyListUrl, CLIENT_COMPANY_LIST_ADD_ACTION_TYPE, addClientConpanyUrl, updateClientCompanyUrl, CLIENT_COMPANY_LIST_UPDATE_ACTION_TYPE, CLIENT_COMPANY_LIST_DETAILS_ACTION_TYPE, clientCompanyDetailsUrl, CLIENT_LOCATION_LIST_ACTION_TYPE, clientlocationListUrl, addClientLocationUrl, CLIENT_LOCATION_LIST_ADD_ACTION_TYPE, CLIENT_LOCATION_LIST_UPDATE_ACTION_TYPE, updateClientLocationUrl, clientLocationDetailsUrl, CLIENT_LOCATION_LIST_DETAILS_ACTION_TYPE, clientAddressListUrl, CLIENT_ADDRESS_LIST_ACTION_TYPE, addClientAddressUrl, CLIENT_ADDRESS_LIST_ADD_ACTION_TYPE,updateClientAddressUrl,CLIENT_ADDRESS_LIST_UPDATE_ACTION_TYPE,clientAddressDetailsUrl,CLIENT_ADDRESS_LIST_DETAILS_ACTION_TYPE, headers } from "../../utils/constants"


export const clientList_action = (clientName, fileNo, clientGroup, clientCategory, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: clientName,
            FileNo: fileNo,
            ClientGroup: clientGroup,
            ClientCategory: clientCategory,
            status: status
        }
        const res = await axiosGet(clientListUrl, param)

        dispatch({
            type: CLIENT_LIST_ACTION_TYPE,
            payload: res
        })

        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const clientDetail_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id
        }
        const res = await axiosGet(clientDetailsUrl, param)

        dispatch({
            type: CLIENT_LIST_DETAILS_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const addClient_action = (prefixId, clientTypeId, clientName, fileNo, clientGroupId, clientCategoryId) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            PrefixID: prefixId,
            ClientTypeID: clientTypeId,
            Name: clientName,
            FileNo: fileNo,
            ClientGroupID: clientGroupId,
            ClientCategoryID: clientCategoryId,
        }
        const res = await axiosPost
            (addClientUrl, mapdata, headers)

        dispatch({
            type: CLIENT_LIST_ADD_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const updateClient_action = (id, prefixId, clientTypeId, clientName, fileNo, clientGroupId, clientCategoryId) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
            ClientTypeID: clientTypeId,
            PrefixID: prefixId,
            Name: clientName,
            FileNo: fileNo,
            ClientGroupID: clientGroupId,
            ClientCategoryID: clientCategoryId,
        }
        const res = await axiosPost(updateClientUrl, mapdata, headers)
        dispatch({
            type: CLIENT_LIST_UPDATE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const deleteClient_action = (id) =>
    async (dispatch) => {
        try {
            const param = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                ID: id,
            }
            const res = await axiosGet
                (deleteClientUrl, param)
            dispatch({
                type: CLIENT_LIST_DELETE_ACTION_TYPE,
                payload: res
            })
            return Promise.resolve(res)
        } catch (err) {
            return Promise.reject(err)
        }
    }

// COMAPNY ACTIONS
export const clientCompanyList_action = (ClientID) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: ClientID,
        }
        const res = await axiosGet(clientConpanyListUrl, param)
        dispatch({
            type: CLIENT_COMPANY_LIST_ACTION_TYPE,
            payload: res
        })

        return Promise.resolve(res)
    }
    catch (err) {
        // return Promise.reject(err)
    }
}

export const addClientCompany_action = (companyNameId, auditTypeId, ClientID) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientID: ClientID,
            CompanyID: companyNameId,
            AuditType: auditTypeId
        }
        const res = await axiosPost
            (addClientConpanyUrl, mapdata, headers)

        dispatch({
            type: CLIENT_COMPANY_LIST_ADD_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const updateClientCompany_action = (id, ClientID, companyNameId, auditTypeId) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
            ClientID: ClientID,
            CompanyID: companyNameId,
            AuditType: auditTypeId,
        }
        console.log(mapdata)
        const res = await axiosPost(updateClientCompanyUrl, mapdata, headers)
        dispatch({
            type: CLIENT_COMPANY_LIST_UPDATE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const clientCompanyDetail_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id
        }
        const res = await axiosGet(clientCompanyDetailsUrl, param)
        dispatch({
            type: CLIENT_COMPANY_LIST_DETAILS_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

//LOCTION ACTION
export const clientlocationList_action = (ClientID, locationName, loactionType, contactPerson) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: ClientID,
            LocationType: loactionType,
            LocationName: locationName,
            ContactPerson: contactPerson,
        }
        const res = await axiosGet(clientlocationListUrl, param)

        dispatch({
            type: CLIENT_LOCATION_LIST_ACTION_TYPE,
            payload: res
        })
        console.log(res)
        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const addClientLocation_action = (ClientID, locationTypeId, locationName, toBeAuditedManadatory, contactPerson, address1, address2, address3, stateId, cityId, pinCode) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientID: ClientID,
            LocationTypeID: locationTypeId,
            LocationName: locationName,
            ToBeAuditedManadatory: toBeAuditedManadatory,
            LocationContactPerson: contactPerson,
            Address1: address1,
            Address2: address2,
            Address3: address3,
            StateID: stateId,
            CityID: cityId,
            PinCode: pinCode,
        }
        console.log(mapdata)
        const res = await axiosPost
            (addClientLocationUrl, mapdata, headers)
        console.log(res)

        dispatch({
            type: CLIENT_LOCATION_LIST_ADD_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const updateClientLocation_action = (ClientID,idlocation, locationTypeId, locationName, toBeAuditedManadatory, contactPerson, address1, address2, address3, stateId, cityId, pinCode) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: idlocation,
            LocationTypeID: locationTypeId,
            LocationName: locationName,
            ToBeAuditedManadatory: toBeAuditedManadatory,
            LocationContactPerson: contactPerson,
            Address1: address1,
            Address2: address2,
            Address3: address3,
            StateID: stateId,
            CityID: cityId,
            PinCode: pinCode,
            ClientID : ClientID
        }
        const res = await axiosPost(updateClientLocationUrl, mapdata, headers)
        dispatch({
            type: CLIENT_LOCATION_LIST_UPDATE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);

    } catch (err) {
        return Promise.reject(err);
    }

}

export const clientLocationDetail_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id
        }
        const res = await axiosGet(clientLocationDetailsUrl, param)
        console.log(res)
        dispatch({
            type: CLIENT_LOCATION_LIST_DETAILS_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

//ADDRESS ACTION
export const clientAddressList_action = (ClientID) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: ClientID,
        }

        const res = await axiosGet(clientAddressListUrl, param)
        dispatch({
            type: CLIENT_ADDRESS_LIST_ACTION_TYPE,
            payload: res
        })
        console.log(res)
        return Promise.resolve(res)
    }
    catch (err) {
        // return Promise.reject(err)
    }
}

export const addClientAddress_action = (ClientID, addressTypeId, address1, address2, address3, stateId, cityId, pinCode) => async (dispatch) => {
    console.log("weqe", ClientID,addressTypeId, stateId, cityId)

    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientID: ClientID,
            AddressTypeID: addressTypeId,
            Address1: address1,
            Address2: address2,
            Address3: address3,
            StateID: stateId,
            CityID: cityId,
            PinCode: pinCode,
        }
        console.log(mapdata)
        const res = await axiosPost
        (addClientAddressUrl, mapdata, headers)

        dispatch({
            type: CLIENT_ADDRESS_LIST_ADD_ACTION_TYPE,
            payload: res
        })
        console.log(res)

        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const updateClientAddress_action = (id, ClientID, addressTypeId, address1, address2, address3, stateId, cityId, pinCode) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
            ClientID: ClientID,
            AddressTypeID: addressTypeId,
            Address1: address1,
            Address2: address2,
            Address3: address3,
            StateID: stateId,
            CityID: cityId,
            PinCode: pinCode,
        }
        console.log(mapdata)
        const res = await axiosPost(updateClientAddressUrl, mapdata, headers)
        dispatch({
            type: CLIENT_ADDRESS_LIST_UPDATE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const clientAddressDetail_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id
        }
        const res = await axiosGet(clientAddressDetailsUrl, param)
        console.log(res)
        dispatch({
            type: CLIENT_ADDRESS_LIST_DETAILS_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export default (state = {}, action) => {
    switch (action.type) {
        case 'SIMPLE_ACTION':
            return {
                result: action.payload
            }
        default:
            return state
    }
}