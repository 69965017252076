import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../../common_components/Header';
import Drawer from '../../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../../utils/withRouter';
import Loader from '../../../../utils/Loader'
import { clientTypeActionId, clientIndividualActionId,PrefixActionId, clientNonIndividualActionId, clientGroupActionId, mainEngagementActionId, companyNameActionId, locationCityActionId, locationStateActionId, LocationTypeActionId, prefixId, Master } from '../../../../utils/constants'
import { fill_action } from '../../../../actions/master_action/checklist_action';
import { addClient_action, updateClient_action, clientDetail_action, clientCompanyList_action, addClientCompany_action, updateClientCompany_action, clientCompanyDetail_action, clientlocationList_action, addClientLocation_action, updateClientLocation_action, clientLocationDetail_action } from '../../../../actions/master_action/client_action'
import Modal from "react-modal";
import { getModuleWriteAccess } from '../../../../utils/RoleAccess';




const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));

class ClientMain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clientName: '',
            fileNo: '',
            prefixId: '',
            isActive: 0,
            isOpenCompany: true,
            isOpenLocation: true,

            is_add: false,
            is_update: false,
            is_updateCompany: false,
            is_addCompany: false,
            is_addLocation: false,
            is_updateLocation: false,
            isAccessToView: true,
            isLoading: false,
            clientIndividualList: [],
            clientCategoryId: '',
            clientNonIndividualList: [],
            clientGroupId: '',
            clientGroupList: [],
            clientTypeId: '',
            clientTypeList: [],
            clientType: '',
            ClientID: '',

            companyName: '',
            companyNameId: '',
            companyNameList: [],
            auditType: '',
            auditTypeId: '',
            companyList: [],
            auditList: [],
            ClientCompanyID: '',
            isCompanyAuditSort: false,
            isCompanyNameSort: false,

            locationList: [],
            loactionType: '',
            locationName: '',
            locationNameSearch : '',
            contactPerson: '',
            locationTypeId: '',
            toBeAuditedManadatory: false,
            address1: '',
            address2: '',
            address3: '',
            stateId: '',
            cityId: '',
            pinCode: '',
            locationId: '',
            locationTypeList: [],
            locationStateList: [],
            locationCityList: [],
            isSearchTab: false,
            prefixList : []
        }
        this.handleCancel = this.handleCancel.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.getClientDetails = this.getClientDetails.bind(this)

        this.companyAuditRecord = this.companyAuditRecord.bind(this)
        this.locationRecord = this.locationRecord.bind(this)
        this.toggleModalCompany = this.toggleModalCompany.bind(this)
        this.getClientCompanyList = this.getClientCompanyList.bind(this)
        this.handleAddCompany = this.handleAddCompany.bind(this)
        this.toggleAddCompany = this.toggleAddCompany.bind(this)
        this.getClientCompanyDetails = this.getClientCompanyDetails.bind(this)
        this.handleUpdateCompany = this.handleUpdateCompany.bind(this)
        this.handleCompanyReset = this.handleCompanyReset.bind(this)

        this.getClientLocationList = this.getClientLocationList.bind(this)
        this.toggleModalLocation = this.toggleModalLocation.bind(this)
        this.toggleAddLocation = this.toggleAddLocation.bind(this)
        this.handleAddLocation = this.handleAddLocation.bind(this)
        this.handleUpdateLocation = this.handleUpdateLocation.bind(this)
        this.handleLocationReset = this.handleLocationReset.bind(this)
        this.toggleUpdateLocation = this.toggleUpdateLocation.bind(this)
        this.getClientLocationDetails = this.getClientLocationDetails.bind(this)
        this.handleSearchLocation = this.handleSearchLocation.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)

    }

    componentDidMount = () => {
        sessionStorage.setItem("ClientMaster", '')
        toast.dismiss();
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            ClientID: this.props.location.state != null && this.props.location.state.ClientID != null ? this.props.location.state.ClientID : '',
            clientTypeId: this.props.location.state != null && this.props.location.state.clientTypeId != null ? this.props.location.state.clientTypeId : '',
            isAccessToView: true,
        })
        
        this.getFillList(PrefixActionId, '')
        this.getFillList(clientTypeActionId, '')
        this.getFillList(clientIndividualActionId, '')
        this.getFillList(clientNonIndividualActionId, '')
        this.getFillList(clientGroupActionId, '')
        this.getFillList(mainEngagementActionId, '')
        this.getFillList(companyNameActionId, '')
        this.getFillList(LocationTypeActionId, '')
        this.getFillList(locationStateActionId, '')
        this.getFillList(locationCityActionId, '')


        this.props.location.state != null && this.props.location.state.is_update && this.getClientDetails()
        setTimeout(() => {
            // this.getClientCompanyList()
            // this.getClientLocationList()

            // this.props.location.state.is_add && sessionStorage.setItem("ClientMaster", '')
            // this.props.location.state.is_update && sessionStorage.setItem("ClientMaster", this.state.ClientID)
        }, 500);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
          const focusedElement = document.activeElement;
          const { previouslyFocusedElement } = this.state;
    
          if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
          }
    
          if (focusedElement.classList.contains('form-control') ||
            focusedElement.classList.contains('js-example-basic-single') ||
            focusedElement.classList.contains('example-custom-input')) {
            focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
            this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
          }
        }
      };
    
    
      handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;
    
        if (previouslyFocusedElement) {
          previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }
    
        if (clickedElement.classList.contains('form-control') ||
          clickedElement.classList.contains('js-example-basic-single') ||
          clickedElement.classList.contains('example-custom-input')) {
          clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
          this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
          this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
      };

    getFillList(value, ref, ref2) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == clientNonIndividualActionId) {
                        this.setState({ clientNonIndividualList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);

                    }
                    if (value == clientIndividualActionId) {
                        this.setState({ clientIndividualList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);

                    }
                    if (value == clientGroupActionId) {
                        this.setState({ clientGroupList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);
                    }
                    if (value == mainEngagementActionId) {
                        this.setState({ auditList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);

                    }
                    if (value == companyNameActionId) {
                        this.setState({ companyNameList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);

                    }

                    if (value == locationCityActionId) {
                        this.setState({ locationCityList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);
                    }

                    if (value == locationStateActionId) {
                        this.setState({ locationStateList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);

                    }

                    if (value == LocationTypeActionId) {
                        this.setState({ locationTypeList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);

                    }
                    if (value == PrefixActionId) {
                        console.log("PrefixActionId", data.data )
                        this.setState({ prefixList: data.data , prefixList2 : data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);

                    }

                    

                    if (value == clientTypeActionId) {
                        this.setState({ clientTypeList: data.data })
                        data.data.map(data => {
                            if (data.ID == this.props.location.state.clientTypeId) {
                                this.setState({ clientTypeId: data.ID })
                            }
                        })

                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);
                    }
                }
            })
    }

    //MAIN FORM
    getClientDetails() {
        const { prefixId, clientName, fileNo, clientGroupId, clientCategoryId } = this.state
        setTimeout(() => {
            var id = this.state.ClientID
            this.props
                .clientDetail_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            prefixId: data.data[0]['PrefixID'],
                            clientName: data.data[0]['ClientFName'],
                            fileNo: data.data[0]['FileNo'],
                            clientGroupId: data.data[0]['ClientGroupID'],
                            clientCategoryId: data.data[0]['ClientCategoryID'],
                            isActive: data.data[0]['IsActive'],
                            clientTypeId: data.data[0]['ClientTypeID']
                        })
                         this.getClientCompanyList()
                         this.getClientLocationList()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 500);
    }

    handleUpdate(e) {
        e.preventDefault()
        const { prefixId, clientTypeId, clientName, fileNo, clientGroupId, clientCategoryId, ClientID } = this.state
        const id = ClientID

        if (id != "" && prefixId != "" &&  clientName != "" && clientCategoryId != "" && fileNo != "") {
            this.setState({ isLoading: true })
            this.props
                .updateClient_action(id, prefixId, clientTypeId, clientName.trim(), fileNo, clientGroupId, clientCategoryId)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    handleAdd(e) {
        e.preventDefault()
        const { prefixId, clientTypeId, clientName, fileNo, clientGroupId, clientCategoryId, ClientID } = this.state
        if (prefixId != "" &&  clientTypeId != "" && clientName != "" && fileNo != "" && clientCategoryId != "") {
            this.setState({ isLoading: true })

            this.props
                .addClient_action(prefixId, clientTypeId, clientName.trim(), fileNo, clientGroupId, clientCategoryId, ClientID)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        sessionStorage.setItem("ClientMaster", data.data.clientID)
                        this.props.masterRemount()
                        // this.state.is_update && isActive === true 
                        this.setState({ ClientID: data.data.clientID, isLoading: false, is_update : true, isActive  : true, is_add : false, })
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
        setTimeout(() => {
            console.log("MAIN id", this.state.ClientID)
            console.log("MAIN session", sessionStorage.getItem("ClientMaster"))
        }, 500);
    }

    clearValue() {
        this.setState({
            prefixId: '',
            clientTypeId: '',
            clientName: '',
            fileNo: '',
            clientGroupId: '',
            clientCategoryId: '',
        })
    }

    clearValue2() {
        this.setState({
            // prefixId: '',
            // clientTypeId: '',
            // clientName: '',
            // fileNo: '',
            // clientGroupId: '',
            // clientCategoryId: '',
            loactionType : '',
            locationName : '',
            contactPerson : ''
        })
        this.getClientLocationList()
    }
    
    handleCancel() {
        this.props.navigate('/clientList')
        sessionStorage.setItem("ClientMaster", '')
    }

    handleReset() {
        this.setState({
            clientName: '',
            fileNo: '',
            prefixId: '',

            clientCategoryId: '',
            clientGroupId: '',


        })
    }


    //////////// COMPANY AUDIT
    getClientCompanyList() {
        const { ClientID } = this.state
        this.setState({ isLoading: true })
        this.props
            .clientCompanyList_action(ClientID)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        companyList: data.data,
                        isLoading: false,
                    })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
                setTimeout(() => {
                }, 500);

            })
            .catch((e) => {
                console.log(e)
            })
    }

    getClientCompanyDetails() {
        const { companyNameId, auditTypeId } = this.state
        this.setState({ isLoading: true })
        setTimeout(() => {
            var id = this.state.ClientCompanyID
            this.props
                .clientCompanyDetail_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            companyNameId: data.data[0]['CompanyID'],
                            auditTypeId: data.data[0]['AuditType'],
                        })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            this.setState({ isLoading: false })

        }, 500);
    }
    //add company
    toggleAddCompany() {
        const { is_addCompany } = this.state
        this.setState({ is_addCompany: !is_addCompany })

        this.handleCompanyReset()
        this.toggleModalCompany()
    }

    handleAddCompany(e) {
        e.preventDefault()
        const { companyNameId, auditTypeId, ClientID, is_addCompany } = this.state
        if (companyNameId != "" && auditTypeId != "" && ClientID != "") {
            this.setState({ isLoading: true })
            this.props
                .addClientCompany_action(companyNameId, auditTypeId, ClientID)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false, is_addCompany: !is_addCompany })
                        this.toggleModalCompany()
                        this.getClientCompanyList()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }
    //update company
    toggleUpdateCompany(company) {
        const { is_updateCompany } = this.state
        this.setState({ is_updateCompany: !is_updateCompany, ClientCompanyID: company })
        this.getClientCompanyDetails()
        this.toggleModalCompany()
    }

    handleUpdateCompany(e) {
        e.preventDefault()
        const { companyNameId, auditTypeId, ClientCompanyID, is_updateCompany, ClientID } = this.state
        const id = ClientCompanyID
        if (companyNameId != "" && auditTypeId != "" && ClientCompanyID != "") {
            this.setState({ isLoading: true })
            this.props
                .updateClientCompany_action(id, ClientID, companyNameId, auditTypeId)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false, is_updateCompany: !is_updateCompany })
                        this.toggleModalCompany()
                        this.getClientCompanyList()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    //toggle/reset
    toggleModalCompany(company) {

        const { isOpenCompany } = this.state
        this.setState({ isOpenCompany: !isOpenCompany })
    }

    handleCompanyReset() {
        this.setState({
            companyNameId: '',
            auditTypeId: '',
        })
    }


    /////////// LOCATION
    getClientLocationList() {
        const { ClientID, locationName, loactionType, contactPerson } = this.state
        this.setState({ isLoading: true })
        this.props
            .clientlocationList_action(ClientID, locationName, loactionType, contactPerson)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        locationList: data.data,
                        isLoading: false,
                    })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
                setTimeout(() => {
                }, 500);

            })
            .catch((e) => {
                console.log(e)
            })
    }

    getClientLocationDetails() {
        this.setState({ isLoading: true })
        setTimeout(() => {
            var id = this.state.locationId
            this.props
                .clientLocationDetail_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            locationName: data.data[0]['LocationName'],
                            locationTypeId: data.data[0]['LocationTypeID'],
                            contactPerson: data.data[0]['LocationContactPerson'],
                            toBeAuditedManadatory: data.data[0]['ToBeAuditedManadatory'],
                            address1: data.data[0]['Address1'],
                            address2: data.data[0]['Address2'],
                            address3: data.data[0]['Address3'],
                            stateId: data.data[0]['StateID'],
                            cityId: data.data[0]['CityID'],
                            pinCode: data.data[0]['PinCode'],
                        })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            this.setState({ isLoading: false })

        }, 500);
    }

    //SEARCH HANDLES
    handleSearchLocation() {
        const { ClientID, locationNameSearch, loactionType, contactPerson } = this.state
        // clearLocalStorage("client")
        this.setState({ isLoading: true })
        this.props
            .clientlocationList_action(ClientID, locationNameSearch.trim(), loactionType.trim(), contactPerson.trim())
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ locationList: data.data, isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
        // localStorage
    }

    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.handleSearchLocation()
        }
    }

    //ADD LOCATION
    toggleAddLocation() {
        const { is_addLocation , isOpenLocation} = this.state
        this.setState({ is_addLocation: !is_addLocation  , isOpenLocation :!isOpenLocation })

        // this.handleCompanyReset()
        // this.toggleModalLocation()
    }

    handleAddLocation(e) {
        e.preventDefault()
        const { ClientID, is_addLocation, locationTypeId, locationName, toBeAuditedManadatory, contactPerson, address1, address2, address3, stateId, cityId, pinCode } = this.state
        if (ClientID != "" && locationName != "" && contactPerson != "" && address1 != "" && locationTypeId != "") {
            this.setState({ isLoading: true })
            this.props
                .addClientLocation_action(ClientID, locationTypeId, locationName, toBeAuditedManadatory, contactPerson, address1, address2, address3, stateId, cityId, pinCode)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 500,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false, is_addLocation: !is_addLocation })
                        this.toggleModalLocation()
                        this.handleLocationReset()
                        setTimeout(() => {
                            this.getClientLocationList()
                        }, 500);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //UPDATE LOCATION
    toggleUpdateLocation(location) {
        const { is_updateLocation , isOpenLocation } = this.state
        this.setState({ is_updateLocation: !is_updateLocation, locationId: location , isOpenLocation :!isOpenLocation })
        this.getClientLocationDetails()
        // this.toggleModalLocation()
    }

    handleUpdateLocation(e) {
        e.preventDefault()
        const { ClientID, is_updateLocation, locationId, locationTypeId, locationName, toBeAuditedManadatory, contactPerson, address1, address2, address3, stateId, cityId, pinCode } = this.state
        const id = locationId
        if (locationId != "" && locationName != "" && contactPerson != "" && address1 != "" && locationTypeId != "") {
            this.setState({ isLoading: true })
            this.props
                .updateClientLocation_action(ClientID ,id, locationTypeId, locationName, toBeAuditedManadatory, contactPerson, address1, address2, address3, stateId, cityId, pinCode)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false, is_updateLocation: !is_updateLocation })
                        this.toggleModalLocation()
                        this.handleLocationReset()
                        setTimeout(() => {
                            this.getClientLocationList()
                        }, 500);

                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //RESET/TOGGLE/CLEAR
    handleLocationReset() {
        this.setState({
            loactionType: '',
            locationName: '',
            locationNameSearch  :'',
            contactPerson: '',
            locationTypeId: '',
            toBeAuditedManadatory: false,
            address1: '',
            address2: '',
            address3: '',
            stateId: '',
            cityId: '',
            pinCode: '',
        })
        setTimeout(() => {
            this.getClientLocationList()
        }, 500);
    }

    toggleModalLocation(location, type) {

        const { isOpenLocation, is_addLocation , is_updateLocation} = this.state
        this.setState({ isOpenLocation: !isOpenLocation, })
        if(type == 'add'){
            this.setState({ is_addLocation : !is_addLocation, })  
        }else{
            if(type == 'update'){
                this.setState({ is_addLocation : false, 
                    is_updateLocation : !is_updateLocation,
                    locationId :location })  
            }
            this.getClientLocationDetails()
        }
        this.handleLocationReset()
    }




    // RECORDS
    companyAuditRecord(companyList) {
        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ textAlign: 'left' }} >Company Name</th>
                        <th style={{ width: '180px' }}  >Audit Type</th>
                    </tr>
                </thead>
                <tbody>
                    {companyList.length > 0 ? (
                        companyList.map((data, i) => (
                            <tr key={data.ID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.toggleUpdateCompany(data.ID)} /></a>
                                    </div>
                                </td>
                                <td style={{ textAlign: 'left' }}>{data.cmpsCompanyName}</td>
                                <td>{data.MainEngagementType}</td>
                            </tr>
                        ))
                    ) : (<tr><td colSpan={5} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }

    locationRecord(locationList) {
        return (
            <>
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ textAlign: 'center' }} >Location Type</th>
                        <th style={{}} >Location Name</th>
                        <th style={{}} >Audited</th>
                        <th style={{}} >Contact Person</th>
                        <th style={{}} >Address 1</th>
                        <th style={{}} >Address 2</th>
                        <th style={{}} >Address 3</th>
                        <th style={{}} >State</th>
                        <th style={{}} >City</th>
                        <th style={{}} >Pin</th>

                    </tr>
                </thead>
                <tbody>
                    {locationList.length > 0 ? (
                        locationList.map((data, i) => (
                            <tr key={data.ID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.toggleModalLocation(data.ID,"update")} /></a>
                                    </div>
                                </td>
                                <td style={{ textAlign: 'center' }} >{data.lctsLocationType}</td>
                                <td>{data.LocationName}</td>
                                <td>{data.ToBeAuditedManadatory == 1 ? 'true' : 'false'}</td>
                                <td>{data.LocationContactPerson}</td>
                                <td>{data.Address1}</td>
                                <td>{data.Address2}</td>
                                <td>{data.Address3}</td>
                                <td>{data.State}</td>
                                <td>{data.City}</td>
                                <td>{data.PinCode}</td>
                            </tr>
                        ))
                    ) : (<tr><td colSpan={12} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
            <div style={{ height: '200px' }}></div>
            </>
        )
    }
    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
      }

    render() {
        const { isLoading, clientCategoryId, clientName, prefixList, isSearchTab,isAccessToView,
             clientIndividualList, clientNonIndividualList, clientGroupId, clientGroupList, clientTypeId, 
             clientTypeList, isActive, is_update, is_add, ClientID, isOpenCompany, companyList, auditTypeId, 
             auditList, companyNameList, companyNameId, is_updateCompany, is_addCompany, locationList, isOpenLocation, 
             locationTypeList, loactionType, locationName, contactPerson, locationTypeId, stateId,locationNameSearch,
             locationStateList, cityId, locationCityList, toBeAuditedManadatory, is_addLocation, prefixId, prefixList2 } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                {/* <div className="main-panel main-box"> */}
                    {
                        clientTypeId == 1 ?
                              
                                <div className="row">
                                  
                                    <div className="col-md-12 pg-body ">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row user-status" style={{position:'absolute', top:'5px'}}>
                                                    <div className="">
                                                        { clientName != '' ?
                                                            is_update ? <p>
                                                                <strong className={isActive === true ? 'greena' : 'redi'}> {isActive === true ? 'Active' : 'Inactive'}</strong>
                                                            </p> : <></> : <></>
                                                        }

                                                    </div>
                                                </div>
                                                <div class="d-flex flex-wrap row mb-3">
                                                    <div className="form-group col-md-3">
                                                        <label>Prefix<span class="required1">*</span></label>
                                                        {/* {console.log("234324",prefixList)} */}
                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                            value={prefixId}
                                                            onChange={(e) => this.setState({ prefixId: e.target.value, })}>
                                                            <option value = {""}>Select</option>
                                                            {prefixList.length > 0 && prefixList.map(data => (
                                                                <option value={data.PrefixID} key={data.PrefixID}>{data.Prefix}</option>
                                                            ))
                                                            }
                                                        </select>

                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <label>Name<span class="required1">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='Name'
                                                            value={this.state.clientName}
                                                            onChange={(e) => this.setState({ clientName: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <label>File No<span class="required1">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='File No'
                                                            value={this.state.fileNo}
                                                            onChange={(e) => this.setState({ fileNo: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-wrap row ">
                                                    <div className="form-group col-md-3">
                                                        <label>	Client Category<span class="required1">*</span></label>
                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                            value={clientCategoryId}
                                                            onChange={(e) => this.setState({ clientCategoryId: e.target.value, })}>
                                                            <option  value={''}>Select</option>
                                                            {clientIndividualList.length > 0 && clientIndividualList.map(data => (
                                                                <option value={data.ID} key={data.ID}>{data.ClientCategory}</option>
                                                            ))
                                                            }
                                                        </select>

                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label>	Client Group</label>
                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                            value={clientGroupId}
                                                            onChange={(e) => this.setState({ clientGroupId: e.target.value })}>
                                                            <option  value={''}>Select</option>
                                                            {clientGroupList.length > 0 && clientGroupList.map(data => (
                                                                <option value={data.ClientGroupID} key={data.ClientGroupID}>{data.ClientGroupName}</option>
                                                            ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label>	Client Type<span class="required1">*</span></label>
                                                        <select className='js-example-basic-single bg-secondary' style={{ width: '100%' }}
                                                            value={clientTypeId}
                                                            onChange={(e) => this.setState({ clientTypeId: e.target.value })}
                                                            disabled={true}>

                                                            <option  value={''}>Select</option>
                                                            {clientTypeList.length > 0 && clientTypeList.map(data => (
                                                                <option value={data.ID} key={data.ID}>{data.Name}</option>
                                                            ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="row my-3">
                                                    <div className="col-md-3">
                                                        <div
                                                            className="btn-group"
                                                            role="group"
                                                            aria-label="Basic example"
                                                        >
                                                            {
                                                                this.state.is_add && <button
                                                                    type="button"
                                                                    className="btn btn-save footer-btn-inner mr-1"
                                                                    onClick={this.handleAdd}
                                                                >
                                                                    Save
                                                                </button>
                                                            }
                                                            {
                                                                this.state.is_add && <button
                                                                    type="button"
                                                                    className="btn btn-update footer-btn-inner mr-1"
                                                                    onClick={this.handleReset}
                                                                >
                                                                    Reset
                                                                </button>
                                                            }

                                                            {
                                                                this.state.is_update && isActive === true ?
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-update footer-btn-inner mr-1"
                                                                        onClick={this.handleUpdate}
                                                                    >
                                                                        Update
                                                                    </button> : <></>
                                                            }


                                                            <button
                                                                type="button"
                                                                className="btn btn-cancel footer-btn-inner"
                                                                onClick={this.handleCancel}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* // individual code end */}

                                                {/* individual company Audit start*/}
                                                <div class="row mt-4">
                                                    <div class="col-12">
                                                        <h5 style={{ padding: '7px', float: 'left' }}><b>COMPANY AUDIT</b></h5>
                                                        <button type="button" className="btn btn-Add rounded-1 mb-1 px-3 float-right" disabled={ClientID == null || ClientID == ''}
                                                            
                                                            onClick={this.toggleAddCompany}> Add</button>
                                                        <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                            {this.companyAuditRecord(companyList)}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* individual company audit end */}
                                                {/* individual location start */}

                                                <div class="row mt-4" >
                                                    <div class="col-12">
                                                        <h5 style={{ padding: '7px', float: 'left' }}><b>LOCATION</b></h5>

                                                        <div className="accordion accordion-flush " id="accordionFlushExample">
                                                            <div>
                                                                <h2 className="accordion-header" id="flush-headingOne">
                                                                <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                                                    <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                </button>
                                                                </h2>
                                                                { isSearchTab ?    <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"}  aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                                    <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                        <div className="d-flex flex-wrap row">
                                                                            <div className="form-group col-md-2">
                                                                                <label>Location Type</label>
                                                                                <input type="text"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    disabled={ClientID == null || ClientID == ''}
                                                                                    value={loactionType}
                                                                                    onChange={(e) => this.setState({ loactionType: e.target.value })}
                                                                                    onKeyDown={e => this.handleKeyDown(e)}
                                                                                    required />
                                                                            </div>

                                                                            <div className="form-group col-md-2">
                                                                                <label>Location Name</label>
                                                                                <input type="text"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    disabled={ClientID == null || ClientID == ''}
                                                                                    value={locationName}
                                                                                    onChange={(e) => this.setState({ locationName: e.target.value })}
                                                                                    onKeyDown={e => this.handleKeyDown(e)}
                                                                                    required />
                                                                            </div>

                                                                            <div className="form-group col-md-2">
                                                                                <label>Contact Person</label>
                                                                                <input type="text"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    disabled={ClientID == null || ClientID == ''}
                                                                                    value={contactPerson}
                                                                                    onChange={(e) => this.setState({ contactPerson: e.target.value })}
                                                                                    onKeyDown={e => this.handleKeyDown(e)}
                                                                                    required />
                                                                            </div>

                                                                            <div className="form-group col-md-2 ">
                                                                                <button type="button" className="btn btn-Add rounded-0" disabled={ClientID == null || ClientID == ''} style={{ marginTop: '18px' }} onClick={e => this.handleSearchLocation()}> Search</button>
                                                                                <button type="button" className="btn btn-clear rounded-0 mx-1" disabled={ClientID == null || ClientID == ''} style={{ marginTop: '18px' }} onClick={e => this.handleLocationReset()}> Clear</button>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : <></> }
                                                            </div>
                                                        </div>


                                                        <button type="button" className="btn btn-Add rounded-1 mt-1 mb-1 px-3 float-right"
                                                             onClick={this.toggleModalLocation('','add')} disabled={ClientID == null || ClientID == ''}> Add</button>
                                                        <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                            {this.locationRecord(locationList)}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* individual location end */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                         :
                          
                                <div className="row ">
                                    
                                    <div className=" col-md-12 pg-body">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row user-status" style={{position:'absolute', top:'0px'}}>
                                                    <div className="">
                                                        {  clientName != '' ?
                                                            is_update ? <p>
                                                                <strong className={isActive === true ? 'greena' : 'redi'}> {isActive === true ? 'Active' : 'Inactive'}</strong>
                                                            </p> : <></> : <></>
                                                        }

                                                    </div>
                                                </div>
                                                <div class="d-flex flex-wrap row ">
                                                    <div className="form-group col-md-3">
                                                        <label>Prefix<span class="required1">*</span></label>
                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                            value={prefixId}
                                                            onChange={(e) => this.setState({ prefixId: e.target.value, })}>
                                                            <option value = {""}>Select</option>
                                                            {prefixList.length > 0 && prefixList.map(data => (
                                                                <option value={data.PrefixID} key={data.PrefixID}>{data.Prefix}</option>
                                                            ))
                                                            }
                                                        </select>

                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <label>Company Name<span class="required1">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='Company Name'
                                                            value={this.state.clientName}
                                                            onChange={(e) => this.setState({ clientName: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <label>File No<span class="required1">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='File No'
                                                            value={this.state.fileNo}
                                                            onChange={(e) => this.setState({ fileNo: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-wrap row ">

                                                    <div className="form-group col-md-3">
                                                        <label>	Client Category<span class="required1">*</span></label>
                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                            value={clientCategoryId}
                                                            onChange={(e) => this.setState({ clientCategoryId: e.target.value })}>
                                                            <option  value={''}>Select</option>
                                                            {clientNonIndividualList.length > 0 && clientNonIndividualList.map(data => (
                                                                <option value={data.ID} key={data.ID}>{data.ClientCategory}</option>
                                                            ))
                                                            }
                                                        </select>

                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label>	Client Group</label>
                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                            value={clientGroupId}
                                                            onChange={(e) => this.setState({ clientGroupId: e.target.value })}>
                                                            <option vs>Select</option>
                                                            {clientGroupList.length > 0 && clientGroupList.map(data => (
                                                                <option value={data.ClientGroupID} key={data.ClientGroupID}>{data.ClientGroupName}</option>
                                                            ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label>	Client Type<span class="required1">*</span></label>
                                                        <select className='js-example-basic-single bg-secondary' style={{ width: '100%' }}
                                                            value={clientTypeId}
                                                            onChange={(e) => this.setState({ clientTypeId: e.target.value })}
                                                            disabled={true}>

                                                            <option  value={''}>Select</option>
                                                            {clientTypeList.length > 0 && clientTypeList.map(data => (
                                                                <option value={data.ID} key={data.ID}>{data.Name}</option>
                                                            ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row my-3">
                                                    <div className="col-md-3" style={{ width: 'auto' }}>
                                                        <div
                                                            className="btn-group"
                                                            role="group"
                                                            aria-label="Basic example"
                                                        >
                                                            {
                                                                this.state.is_add && <button
                                                                    type="button"
                                                                    className="btn btn-save footer-btn-inner mr-1"
                                                                    onClick={this.handleAdd}
                                                                >
                                                                    Save
                                                                </button>
                                                            }
                                                            {
                                                                this.state.is_add && <button
                                                                    type="button"
                                                                    className="btn btn-update footer-btn-inner mr-1"
                                                                    onClick={this.handleReset}
                                                                >
                                                                    Reset
                                                                </button>
                                                            }

                                                            {
                                                                this.state.is_update && isActive === true ?
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-update footer-btn-inner mr-1"
                                                                        onClick={this.handleUpdate}
                                                                    >
                                                                        Update
                                                                    </button> : <></>
                                                            }


                                                            <button
                                                                type="button"
                                                                className="btn btn-cancel footer-btn-inner"
                                                                onClick={this.handleCancel}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* non individual code end */}
                                                {/* non individual company audit start */}
                                                <div class="row mt-4">
                                                    <div class="col-12">
                                                        <h5 style={{ padding: '7px', float: 'left' }}><b>COMPANY AUDIT</b></h5>
                                                        <button type="button" className="btn btn-Add rounded-1 mb-1 float-right px-3" disabled={ClientID == null || ClientID == ''}
                                                            onClick={this.toggleAddCompany}> Add</button>
                                                        <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                            {this.companyAuditRecord(companyList)}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* non individual company audit end */}
                                                {/* non individual location start */}
                                                <div class="row mt-4" >
                                                    <div class="col-12">
                                                        <h5 style={{ padding: '7px', float: 'left' }}><b>LOCATION</b></h5>

                                                        <div className="accordion accordion-flush " id="accordionFlushExample">
                                                            <div>
                                                                <h2 className="accordion-header" id="flush-headingOne">
                                                                <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                                                    <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                </button>
                                                                </h2>
                                                                { isSearchTab ? <div id="flush-collapseOne"  className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                                    <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                        <div className="d-flex flex-wrap row">
                                                                            <div className="form-group col-md-3">
                                                                                <label>Location Type</label>
                                                                                <input type="text"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    disabled={ClientID == null || ClientID == ''}
                                                                                    value={loactionType}
                                                                                    onChange={(e) => this.setState({ loactionType: e.target.value })}
                                                                                    onKeyDown={e => this.handleKeyDown(e)}
                                                                                    required />
                                                                            </div>

                                                                            <div className="form-group col-md-3">
                                                                                <label>Location Name</label>
                                                                                <input type="text"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    disabled={ClientID == null || ClientID == ''}
                                                                                    value={locationNameSearch}
                                                                                    onChange={(e) => this.setState({ locationNameSearch: e.target.value })}
                                                                                    onKeyDown={e => this.handleKeyDown(e)}
                                                                                    required />
                                                                            </div>

                                                                            <div className="form-group col-md-3">
                                                                                <label>Contact Person</label>
                                                                                <input type="text"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    disabled={ClientID == null || ClientID == ''}
                                                                                    value={contactPerson}
                                                                                    onChange={(e) => this.setState({ contactPerson: e.target.value })}
                                                                                    onKeyDown={e => this.handleKeyDown(e)}
                                                                                    required />
                                                                            </div>

                                                                            <div className="form-group col-md-3 filter-btn-col">
                                      <label></label>
                                      <button type="button" className="btn btn-Add rounded-1 fil-btn py-2"    disabled={ClientID == null || ClientID == ''} onClick={e => this.handleSearchLocation()}> Search</button>
                                      <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3"    disabled={ClientID == null || ClientID == ''} onClick={e => this.handleLocationReset()}> Clear</button>
                                    </div>

                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div> : <></> }
                                                            </div>
                                                        </div>


                                                       {getModuleWriteAccess(Master) && <button type="button" className="btn btn-Add rounded-1 mt-1 mb-1 px-3 float-right"
                                                             onClick={this.toggleAddLocation} disabled={ClientID == null || ClientID == ''}> Add</button>}
                                                        <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                            {this.locationRecord(locationList)}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* nonindividual location end */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                         
                    }

                    {/* end
   */}
                {/* </div> */}
                {/* end page */}
                {/* <ToastContainer /> */}
                {/* // modal for company audit */}
                <Modal isOpen={!isOpenCompany} onRequestClose={this.toggleModalCompany}>
                    <div className="modal-dialog custom_modal_dialog">
                    <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                {is_addCompany == true ? <h4 class="modal-title modal_title_text">Add Company Audit</h4> : <h4 class="modal-title modal_title_text">Update Company Audit</h4>}
                                {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModalCompany}>&times;</button> */}
                                {is_addCompany == true ? <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleAddCompany} ><span aria-hidden="true" class="pop-cancel">&times;</span></button> : <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.toggleUpdateCompany()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>}


                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div className="form-group row">
                                        <label><h6>Company<span class="required1">*</span></h6></label>
                                        <select className="js-example-basic-single mt-n2 ms-2"
                                            value={companyNameId}
                                            onChange={(e) => this.setState({ companyNameId: e.target.value })}>
                                            <option  value={''}>Select</option>
                                            {companyNameList.length > 0 && companyNameList.map(data => (
                                                <option value={data.cmpnID} key={data.cmpnID}>{data.cmpsCompanyName}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group row mt-3">
                                        <label><h6>Audit Type<span class="required1">*</span></h6></label>
                                        <select className="js-example-basic-single mt-n2 ms-2"
                                            value={auditTypeId}
                                            onChange={(e) => this.setState({ auditTypeId: e.target.value })}
                                        >
                                            <option  value={''}>Select</option>
                                            {auditList.length > 0 && auditList.map(data => (
                                                <option value={data.MainEngagementID} key={data.MainEngagementID}>{data.MainEngagementType}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="modal-footer p-2">
                                    <div class="form-group text-right mod-fot">
                                        {is_addCompany == true ? <a class="btn btn-outline-info px-3 px-3 p-2" onClick={this.handleAddCompany}> Save</a> : <a class="btn btn-outline-info px-3 px-3 p-2" onClick={this.handleUpdateCompany}> Update</a>}
                                        {is_addCompany == true  ? <a class="btn btn-outline-danger p-2 mx-2 px-3" data-dismiss="modal" onClick={this.handleCompanyReset}>Reset</a> : <></> }
                                        {is_addCompany == true ? <a class="btn btn-outline-secondary p-2 px-3" data-dismiss="modal" onClick={this.toggleAddCompany}>Cancel</a> : <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.toggleUpdateCompany()}>Cancel</a>}
                                    </div>
                            </div>

                        </div>
                    </div>
                </Modal>

                {/* model for company location */}
                <Modal isOpen={!isOpenLocation} onRequestClose={is_addLocation == true ? this.toggleModalLocation : () => this.toggleModalLocation() }>
                    <div className="modal-dialog custom_modal_dialog">
                    <div className="modal-content" style={{ borderRadius: '0px', width: '500px', marginTop: '150px' }}>
                            <div class="modal-header">
                                {is_addLocation == true ? <h4 class="modal-title modal_title_text">Add Location</h4> : <h4 class="modal-title modal_title_text">Update Location</h4>
                                }

                                {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModalLocation}>&times;</button> */}
                                {is_addLocation == true ? <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModalLocation} ><span aria-hidden="true" class="pop-cancel">&times;</span></button> :
                                 <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.toggleModalLocation()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                                }


                            </div>
                            <div class="modal-body pt-2">

                                <div class="d-flex flex-wrap row ">
                                    <div className="form-group col">
                                        <label>Location Name<span class="required1">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='Location Name'
                                            value={this.state.locationName}
                                            onChange={(e) => this.setState({ locationName: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div class="form-group col">
                                        <label>Location Type<span class="required1">*</span></label>
                                        <select className="js-example-basic-single mt-n2" style={{ width: '100%' }}
                                            value={locationTypeId}
                                            onChange={(e) => this.setState({ locationTypeId: e.target.value })}
                                        >
                                            <option  value={''}>Select</option>
                                            {locationTypeList.length > 0 && locationTypeList.map(data => (
                                                <option value={data.ID} key={data.ID}>{data.LocationType}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap row mt-2">
                                    <div className="form-group col">
                                        <label>Contact Person<span class="required1">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='Contact person'
                                            value={this.state.contactPerson}
                                            onChange={(e) => this.setState({ contactPerson: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div class="form-group col mt-4 d-flex">
                                        <input type="checkbox" checked={toBeAuditedManadatory} value={toBeAuditedManadatory} onClick={(e) => this.setState({ toBeAuditedManadatory: !toBeAuditedManadatory })} />
                                        <label className='mx-2 mt-2'> To be Audited</label>
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap row mt-2">
                                    <div className="form-group colmd-12">
                                        <label>Address<span class="required1">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control mb-1"
                                            placeholder='Line 1'
                                            value={this.state.address1}
                                            onChange={(e) => this.setState({ address1: e.target.value })}
                                            required
                                        />
                                    </div>
                                        <div className="form-group col-12">
                                            <input
                                                type="text"
                                                className="form-control mb-1"
                                                placeholder='Line 2'
                                                value={this.state.address2}
                                                onChange={(e) => this.setState({ address2: e.target.value })}
                                            />
                                        </div>
                                    <div className="form-group col-12">
                                        <input
                                            type="text"
                                            className="form-control mb-1"
                                            placeholder='Line 3'
                                            value={this.state.address3}
                                            onChange={(e) => this.setState({ address3: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div class="d-flex flex-wrap row mt-2">
                                    <div class="form-group col">
                                        <label>State</label>
                                        <select className="js-example-basic-single mt-n2" 
                                            value={stateId}
                                            onChange={(e) => this.setState({ stateId: e.target.value })}
                                        >
                                            <option  value={''}>Select</option>
                                            {locationStateList.length > 0 && locationStateList.map(data => (
                                                <option value={data.StateID} key={data.StateID}>{data.State}</option>
                                            ))
                                            }
                                        </select>
                                    </div>

                                    <div class="form-group col">
                                        <label>City</label>
                                        <select className={stateId == null || stateId == '' ? 'js-example-basic-single bg-secondary' : 'js-example-basic-single'} style={{ width: '100%' }}
                                            value={cityId}
                                            onChange={(e) => this.setState({ cityId: e.target.value })}
                                            disabled={stateId == null || stateId == ''}
                                        >
                                            <option  value={''}>Select</option>
                                            {locationCityList.length > 0 && locationCityList.map(data => data.ctynStateID == stateId ? (
                                                <option value={data.ctynID} key={data.ctynID}>{data.ctysCity}</option>
                                            ) : null)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap row mt-2 form-group ">
                                    <label>Pin</label>
                                    <div className="form-group col-md-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='pincode'
                                            value={this.state.pinCode}
                                            onChange={(e) => {
                                                const userInput = e.target.value;
                                                // Check if userInput contains only numerical characters
                                                if (/^\d*$/.test(userInput)) {
                                                    this.setState({ pinCode: userInput });
                                                }
                                            }} 
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="modal-footer p-0">
                                    <div class="form-group text-right mod-fot">
                                        {is_addLocation == true ? <a class="btn save_btn del-pop-save px-3 py-2" onClick={this.handleAddLocation}> Save</a> : <a class="btn save_btn del-pop-save" onClick={this.handleUpdateLocation}>Update</a>
                                        }
                                        {is_addLocation == true ? <a class="btn btn btn-outline-danger px-3 py-2" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.handleLocationReset}>Reset</a> : <></> }
                                        {is_addLocation == true ? <a class="btn cancel_btn del-pop-cancel px-3 py-2" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModalLocation}>Cancel</a>
                                         : <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.toggleModalLocation()}>Cancel</a>
                                        }
                                    </div>
                            </div>

                        </div>
                    </div>
                </Modal>

            </>
        )
    }
}

export default withRouter(connect(mapStateToProps, { fill_action, addClient_action, updateClient_action, clientDetail_action, addClientCompany_action, clientCompanyList_action, updateClientCompany_action, clientCompanyDetail_action, clientlocationList_action, addClientLocation_action, updateClientLocation_action, clientLocationDetail_action })(ClientMain))