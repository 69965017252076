import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import Drawer from '../../common_components/Drawer';
import { fill_action } from '../../actions/master_action/checklist_action'
import {
    trainingApprovalRejectList_action,
    addtraining_action, trainingList_action, approveRejectTraining_action,
} from '../../actions/training_action/training_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import Modal from "react-modal";
import {
    courseTypeActionId, clearLocalStorage,
    trainingStatusActionId, trainingApprovedByActionId, trainingEmployeeActionId,
    Training_Approval
} from '../../utils/constants'
import Pagination from '../../utils/pagination'
import Loader from '../../utils/Loader'
import moment from 'moment';
import Workbook from 'react-excel-workbook'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getModuleReadAccess, getModuleUpdateAccess } from '../../utils/RoleAccess';
/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
    // loginAction: () => dispatch(loginAction())
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));


class TrainingApprovalReject extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            status: ' ',
            isOpen: false,
            TrainingListDeleteId: '',
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage: 10,
            pageSizeInput: "10",

            employeeId: '',
            employeeList: [],
            courseDetails: '',
            approvalDate: '',
            trainingFromDate: '',
            trainingEndDate: '',
            courseTypeId: '',
            courseTypeList: [],
            approvedById: '',
            approvedByList: [],
            statusId: '',
            statusList: [],
            TrainingList: [],
            allSelected: false,
            _isReject: false,
            _isApprove: false,
            rejectReason: '',
            checkBoxFlag: false,
            rejectId: '',
            checkedlistId: '',
            approvalRejectCheckboxType: '',
            // local storage
            clientsNameIdTSLS: '',
            financialYearIdTSLS: '',
            engagementIdTSLS: '',
            toDateTSLS: '',
            fromDateTSLS: '',
            statusIdTSLS: '',

            singleLasttrainingFromDate: '',
            isSearchTab: false,
            checkedCurrentRecords: []
        }
        this.getTrainingApprovalRejectList = this.getTrainingApprovalRejectList.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);
        this.record = this.record.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.allSelectedCheckbox = this.allSelectedCheckbox.bind(this)
        this.approvalRejectCheckbox = this.approvalRejectCheckbox.bind(this)
        this.approvalRejectTraining = this.approvalRejectTraining.bind(this)
        this.cancleApprovalStatusModal = this.cancleApprovalStatusModal.bind(this)
        this.cancleRejectStatusModal = this.cancleRejectStatusModal.bind(this)

    }

    componentDidMount = () => {


        this.getFillList(courseTypeActionId, '')
        this.getFillList(trainingStatusActionId, '')
        this.getFillList(trainingApprovedByActionId, '')
        this.getFillList(trainingEmployeeActionId, '')
        this.setState({
            // isAccessToView : ['1', '4'].includes(localStorage.getItem('RoleId'))
            isAccessToView: getModuleReadAccess(Training_Approval),

        })
        this.setState({

            // clientsNameId: localStorage.getItem('clientsNameIdTSLS') != '' ? localStorage.getItem('clientsNameIdTSLS') : '',
            // financialYearId: localStorage.getItem('financialYearIdTSLS') != '' ? localStorage.getItem('financialYearIdTSLS') : '',
            // engagementId: localStorage.getItem('engagementIdTSLS') != '' ? localStorage.getItem('engagementIdTSLS') : '',
            // // toDate: localStorage.getItem('toDateTSLS') != '' ? localStorage.getItem('toDateTSLS') : '',
            // // fromDate: localStorage.getItem('fromDateTSLS') != '' ? localStorage.getItem('fromDateTSLS') : '',
            // statusId: localStorage.getItem('statusIdTSLS') != '' ? localStorage.getItem('statusIdTSLS') : '',

        })

        console.log("localStorage.getItem('fromDateTSLS')", localStorage.getItem('fromDateTSLS'))
        this.getTrainingApprovalRejectList()
        // document.addEventListener('click', this.handleInputClick);
        // document.addEventListener('keydown', this.handleTabKeyPress);

        setTimeout(() => {
            clearLocalStorage("Training")
        }, 100);

    }
    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }

    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };


    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.getTrainingApprovalRejectList()
        }
    }

    clearValue() {
        this.setState({
            employeeId: '',
            courseDetails: '',
            approvalDate: '',
            trainingFromDate: '',
            trainingEndDate: '',
            courseTypeId: '',
            approvedById: '',
            statusId: '',
            currentPage: 1,
            // local storage
            clientsNameIdTSLS: '',
            financialYearIdTSLS: '',
            engagementIdTSLS: '',
            toDateTSLS: '',
            fromDateTSLS: '',
            statusIdTSLS: '',

        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getTrainingApprovalRejectList()
        }, 300);

    }


    // get fill list 
    getFillList(value, ref) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value, ref)
            .then((data) => {
                if (data.error != 1) {
                    if (value == courseTypeActionId) {
                        this.setState({ courseTypeList: data.data, isLoading: false })
                    }
                    if (value == trainingApprovedByActionId) {
                        this.setState({ approvedByList: data.data, isLoading: false })
                    }
                    if (value == trainingStatusActionId) {
                        this.setState({ statusList: data.data, isLoading: false })
                    }
                    if (value == trainingEmployeeActionId) {
                        this.setState({ employeeList: data.data, isLoading: false })
                    }
                }
                else {
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    // get engagement allocation list
    getTrainingApprovalRejectList() {
        const { employeeId, courseDetails, approvalDate, trainingFromDate, trainingEndDate, courseTypeId, approvedById, statusId, checkedCurrentRecords, currentPage } = this.state
        this.setState({ isLoading: true })
        this.props
            //     EmployeeID, FromtrainingFromDate, TotrainingFromDate,
            // CourseTypeID, CourseDetails, SearchEmployeeID,StatusID,
            // ApprovedByID,ApprovedDate
            .trainingApprovalRejectList_action(
                // .trainingList_action(
                trainingFromDate, trainingEndDate,
                courseTypeId != 'Select' && courseTypeId != '' ? courseTypeId : null,
                courseDetails,
                employeeId != 'Select' && employeeId != '' ? employeeId : null,
                statusId != 'Select' && statusId != '' ? statusId : null,
                approvedById != 'Select' && approvedById != '' ? approvedById : null,
                approvalDate,
            )
            .then((data) => {
                if (data.error != 1) {
                    data.data.map((item) => {
                        item.is_cheked = 0
                    })
                    if (data.data == '') {
                        this.setState({ checkBoxFlag: false, epmtyList: true })
                    }
                    this.setState({ TrainingList: data.data })
                    setTimeout(() => {
                        this.setState({ isLoading: false })
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

        // sessionStorage.setItem("clientsNameIdTSLS", clientsNameId)
        // sessionStorage.setItem("financialYearIdTSLS", financialYearId)
        // sessionStorage.setItem("engagementIdTSLS", engagementId)
        // sessionStorage.setItem("toDateTSLS", toDate)
        // sessionStorage.setItem("fromDateTSLS", fromDate)
        sessionStorage.setItem("statusIdTSLS", statusId === 'Select' ? 2 : statusId)
    }

    updateItem(item) {
        this.setState({ currentPage: item });

        console.log('current page : ', item);

        var prevPage = localStorage.getItem("prevSelectedPage")
        console.log('prev page : ', prevPage);
        if (prevPage != null && prevPage != item) {
            const { TrainingList } = this.state;
            const pList = [...TrainingList];

            pList.forEach((data, i) => {
                data.is_cheked = 0
            });
            this.setState({
                TrainingList: pList,
                allSelected: false
            });
        }

    }


    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
            this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
    };

    handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
            this.setState({ pageSizeInput: input });
        }
    };

    approvalRejectTraining() {
        // e.preventDefault()
        const { rejectReason, TrainingList, isOpen, checkedlistId, approvalRejectCheckboxType, currentPage, checkedCurrentRecords } = this.state
        // status(2 for approve and 3 for reject)
        const trainingApproveRejectList = []

        var isShowMsgAllReject = false;
        var isSave = false;
        TrainingList.map((data) => {
            // for single row reject 
            if (approvalRejectCheckboxType == 'singleReject') {

                if (data.TrainingID == checkedlistId) {
                    if (rejectReason != '') {
                        trainingApproveRejectList.push(
                            {
                                'TrainingID': parseInt(data.TrainingID),
                                'Status': 3,
                                "RejectReason": rejectReason
                            })
                        isSave = true;
                    } else {
                        console.log('singleReject')
                        toast.error('Please enter Reason', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        isSave = false;
                        return;
                    }
                }


            }
            // for single row approval 
            if (approvalRejectCheckboxType == 'singleApproval') {
                if (data.TrainingID == checkedlistId) {
                    trainingApproveRejectList.push(
                        {
                            'TrainingID': parseInt(data.TrainingID),
                            'Status': 2,
                            "RejectReason": ""
                        })
                    isSave = true;
                }
            }

            // for all row approval 
            if (approvalRejectCheckboxType == 'allApproval') {
                if (data.is_cheked == 1) {
                    trainingApproveRejectList.push(
                        {
                            'TrainingID': parseInt(data.TrainingID),
                            'Status': 2,
                            "RejectReason": ""
                        })
                    isSave = true;
                }
            }
            // for all row reject 
            if (approvalRejectCheckboxType == 'allReject') {
                if (rejectReason != '') {
                    if (data.is_cheked == 1) {
                        trainingApproveRejectList.push(
                            {
                                'TrainingID': parseInt(data.TrainingID),
                                'Status': 3,
                                "RejectReason": rejectReason
                            })
                        isSave = true;
                    }
                } else {
                    console.log('allReject')
                    isSave = false;
                    isShowMsgAllReject = true;
                    // toast.error('Please enter Reason', {
                    //     theme: 'colored',
                    //     autoClose: 3000,
                    //     hideProgressBar: true,
                    // })
                    // return;
                }
            }
        })
        if (isShowMsgAllReject) {
            toast.error('Please enter Reason', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
            isSave = false;
            return;
        }
        console.log(trainingApproveRejectList);
        if (isSave) {
            this.setState({ isLoading: true })
            this.props
                .approveRejectTraining_action(trainingApproveRejectList)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success(rejectReason == '' ? 'Training Approved Successfully' : 'Training Reject Successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        console.log("trainingApproveRejectList--->", trainingApproveRejectList)
                        console.log("checkedCurrentRecords--->", checkedCurrentRecords)
                        if (currentPage != 1) {
                            if (checkedCurrentRecords.length == trainingApproveRejectList.length) {
                                this.setState({ currentPage: currentPage - 1 })
                                console.log("test---------->1",)
                            } else {
                                console.log("test---------->2",)
                                this.setState({ currentPage: currentPage })
                            }
                        }

                        this.setState({ isLoading: false, isOpen: !isOpen, rejectReason: '', trainingApproveRejectList: [], allSelected: false })
                        rejectReason == '' ? this.setState({ _isApprove: false }) : this.setState({ _isReject: false })
                        this.getTrainingApprovalRejectList()
                        // this.handleCancel()
                        // this.clearValue()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }


    cancleApprovalStatusModal() {
        const { isOpen, _isApprove } = this.state
        this.setState({ isOpen: false, _isApprove: !_isApprove, })
        this.setState({ StatusId: '' })
    }

    cancleRejectStatusModal() {
        const { isOpen, _isReject } = this.state
        this.setState({ isOpen: false, _isReject: !_isReject, })
        this.setState({ StatusId: '' })
    }

    searchApproveToggleModal(type, id, currentRecords) {
        const { isOpen, _isApprove } = this.state
        this.setState({ isOpen: !isOpen, _isApprove: !_isApprove, checkedlistId: id, approvalRejectCheckboxType: type, checkedCurrentRecords: currentRecords })
    }

    searchRejectToggleModal(type, id, currentRecords) {
        const { isOpen, _isReject, rejectReason } = this.state
        this.setState({ isOpen: !isOpen, _isReject: !_isReject, checkedlistId: id, approvalRejectCheckboxType: type, rejectReason: "", checkedCurrentRecords: currentRecords })
    }


    record(TrainingList, indexOfFirstRecord, indexOfLastRecord) {
        const { allSelected, epmtyList, statusId, approvedById } = this.state

        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>

                        {(statusId == 1 || statusId == '') && <>
                            {!epmtyList ?
                                <th style={{ width: '20px' }} >
                                    <input
                                        type='checkbox'
                                        onClick={(e) => this.allSelectedCheckbox(e, indexOfFirstRecord, indexOfLastRecord)}
                                        checked={allSelected}
                                    /> </th>

                                : <th style={{ width: '20px' }} ></th>}
                        </>}
                        {(statusId == 1 || statusId == '') && <th style={{ width: '10px' }}>Action</th>}
                        <th style={{ textAlign: 'left' }} >Training Date</th>
                        <th style={{ width: '150px' }} >Employee </th>
                        <th style={{ width: '250px' }} >Hours</th>
                        <th style={{ width: '150px' }} >Structured/Un-structured</th>
                        <th style={{ width: '250px' }} >Course Details</th>
                        <th style={{ width: '250px' }} > Status </th>
                        {statusId == 2 && <th style={{ width: '100px' }} >Approved By</th>}
                        {statusId == 2 && <th style={{ width: '250px' }}>Approved Date</th>}
                        {statusId == 3 && <th style={{ width: '100px' }} >Rejected By</th>}
                        {statusId == 3 && <th style={{ width: '250px' }}>Rejected Date</th>}

                    </tr>
                </thead>
                <tbody>


                    {
                        TrainingList.length > 0 ?
                            TrainingList.map((data, index) => (
                                <>
                                    <tr key={Math.random()}>

                                        {/* <td className="text-center" style={{ padding: '5px!important' }}> */}
                                        {/* <div className="table__button-group">
                                                {
                                                    data.TrainingStatus == 'Approved' ?
                                                        <a> <span ><i class="fa fa-eye" style={{ color: '#3498db' }}
                                                            onClick={() => this.handleUpdate(data.TrainingID)} /></span></a>
                                                        :
                                                        <>
                                                            <a> <i className="fa fa-pencil" style={{ color: '#aac722' }}
                                                                onClick={() => this.handleUpdate(data.TrainingID)} /></a>
                                                            <a> <span className="text-danger"><i className="fa fa-trash-o"
                                                                onClick={() => this.toggleModal(data.TrainingID)} /></span></a>
                                                        </>
                                                }
                                            </div>
                                        </td> */}
                                        {statusId == 1 || statusId == '' ?
                                            <td>
                                                <input
                                                    onClick={(e) => this.approvalRejectCheckbox(data, data.is_cheked, e)}
                                                    type="checkbox"
                                                    checked={data.is_cheked}
                                                />
                                            </td> : <></>
                                        }
                                        {statusId == 1 || statusId == '' && getModuleUpdateAccess(Training_Approval) ?
                                            <td className='d-flex '>

                                                <button
                                                    disabled={data.StatusName != 'Pending for Approval'}
                                                    type="button"
                                                    className="btn btn-outline-info rounded-1 mr-1 py-1 mx-1 px-3 m-1 h-auto"
                                                    onClick={e => this.searchApproveToggleModal("singleApproval", data.TrainingID, TrainingList)}
                                                > Approval </button>
                                                <button
                                                    disabled={data.StatusName != 'Pending for Approval'}
                                                    type="button"
                                                    className="btn btn-outline-danger rounded-1 mr-1 py-1 mx-1 px-3 m-1 h-auto"
                                                    onClick={e => this.searchRejectToggleModal("singleReject", data.TrainingID, TrainingList)}
                                                > Rejection </button>
                                            </td> : <></>}
                                        <td style={{ textAlign: 'left' }}>{data.TrainingDate != '' ? moment(data.TrainingDate).format('DD-MM-YYYY') : '-'}</td>
                                        <td>{data.EmployeeName != '' ? data.EmployeeName : '-'}</td>
                                        <td>{data.TotalHour != '' ? data.TotalHour : '-'}</td>
                                        <td>{data.CourseTypeName != '' ? data.CourseTypeName : '-'}</td>
                                        <td>{data.CourseDetails != '' ? data.CourseDetails : '-'}</td>
                                        <td>{data.StatusName != '' ? data.StatusName : '-'}</td>
                                        {statusId == 2 && <td>{data.ApprovalByName != '' && data.ApprovalByName != null ? data.ApprovalByName : '-'}</td>}
                                        {statusId == 2 && <td>{data.ApprovedDate != null && moment(data.ApprovedDate).format('DD-MM-YYYY')}</td>}
                                        {statusId == 3 && <td>{data.RejectedByName != '' && data.RejectedByName != null ? data.RejectedByName : '-'}</td>}
                                        {statusId == 3 && <td>{data.RejectedDate != null && moment(data.RejectedDate).format('DD-MM-YYYY')}</td>}


                                    </tr>
                                </>
                            )) : <tr><td colSpan={13} className='text-center'>Record not found</td></tr>
                    }

                </tbody>
            </table>
        )
    }


    approvalRejectCheckbox(data2, is_cheked, e) {

        const { TrainingList } = this.state;
        const pList = [...TrainingList];
        const event = e.target.checked

        if (event == true) {
            pList.forEach((data, i) => {
                if (data2.TrainingID == data.TrainingID) {
                    data.is_cheked = 1;
                }
            });
            this.setState({
                TrainingList: pList,
            })

        } else {
            pList.forEach((data, i) => {
                if (data2.TrainingID === data.TrainingID) {
                    data.is_cheked = 0;
                }
            });
        }
        this.setState({
            TrainingList: pList,
        });

        const checkedAllSelected = pList.every(item => item.is_cheked == 1)

        if (checkedAllSelected) {
            this.setState({ allSelected: true })
        } else {
            this.setState({ allSelected: false })
        }

        const checkboxSelected = pList.some(item => item.is_cheked == 1)

        // console.log("************ checkboxSelected", checkboxSelected)

        if (checkboxSelected) {
            this.setState({ checkBoxFlag: true })
        } else {
            this.setState({ checkBoxFlag: false })
        }
    }


    allSelectedCheckbox(e, indexOfFirstRecord, indexOfLastRecord) {
        const { allSelected, TrainingList } = this.state
        this.setState({ allSelected: e.target.checked })

        if (e.target.checked == true) {
            // TrainingList.map((data) => {
            //     data.is_cheked = 1
            // })
            TrainingList.map((data, index) => {
                if (index >= indexOfFirstRecord && index <= indexOfLastRecord - 1) {
                    data.is_cheked = 1;
                }
                return data;
            });
            this.setState({ TrainingList: TrainingList, checkBoxFlag: true })
        } else {
            TrainingList.map((data) => {
                data.is_cheked = 0
            })
            this.setState({ TrainingList: TrainingList, checkBoxFlag: false })
        }

    }


    render() {
        const { isSearchTab, isAccessToView, isLoading, TrainingList,
            TrainingListDeleteId, isOpen, trainingEndDate,
            statusList, courseTypeList, approvedByList, employeeList,
            trainingFromDate, employeeId, courseDetails, approvalDate, rejectReason,
            courseTypeId, approvedById, statusId, epmtyList, checkBoxFlag, _isReject,
            _isApprove,

        } = this.state

        const { currentPage, recordsPerPage, pageSizeInput } = this.state;


        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = TrainingList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(TrainingList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">

                                            <li><a href="#">Training Approval Reject</a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className='page-space-from-bottom'>
                                        <div className="row">
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body pr-2">
                                                        <div className="accordion accordion-flush " id="accordionFlushExample">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingOne">
                                                                    <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">                                                                <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                    </button>
                                                                </h2>
                                                                {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                            <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                    <div className="d-flex flex-wrap row">
                                                                        <div className='form-group col-md-3'>
                                                                            <label>
                                                                                Training Start Date
                                                                            </label>
                                                                            <div className="form-group d-flex">
                                                                                <DatePicker className="form-control"
                                                                                    dateFormat="dd-MM-yyyy"
                                                                                    selected={this.state.trainingFromDate}
                                                                                    // minDate={this.state.finTodateLstRcd != '' ? this.state.finTodateLstRcd : ''}
                                                                                    // disabled={is_add ? (this.state.finTodateLstRcd != '' ? true : false) : is_update}
                                                                                    onChange={(date) => {
                                                                                        this.setState({ trainingFromDate: date });

                                                                                    }}
                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    placeholderText="From Date"
                                                                                    customInput={<ExampleCustomInput />}
                                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                                    popperClassName="example-datepicker-class"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='form-group col-md-3'>
                                                                            <label>
                                                                                Training End Date
                                                                            </label>
                                                                            <div className="form-group d-flex">
                                                                                <DatePicker className="form-control"
                                                                                    dateFormat="dd-MM-yyyy"
                                                                                    selected={this.state.trainingEndDate}
                                                                                    // minDate={this.state.finTodateLstRcd != '' ? this.state.finTodateLstRcd : ''}
                                                                                    // disabled={is_add ? (this.state.finTodateLstRcd != '' ? true : false) : is_update}
                                                                                    onChange={(date) => {
                                                                                        this.setState({ trainingEndDate: date });

                                                                                    }}
                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    placeholderText="From Date"
                                                                                    customInput={<ExampleCustomInput />}
                                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                                    popperClassName="example-datepicker-class"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Course Type</label>
                                                                            <select className="js-example-basic-single"
                                                                                style={{ width: '100%' }} value={courseTypeId}
                                                                                onChange={e => this.setState({ courseTypeId: e.target.value })}>
                                                                                <option value={""}>Select</option>
                                                                                {courseTypeList.length > 0 && courseTypeList.map(item => (
                                                                                    <option
                                                                                        key={item.Code}
                                                                                        value={item.Code}>
                                                                                        {item.Description}
                                                                                    </option>
                                                                                ))}

                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Course Details</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder=""
                                                                                value={courseDetails}
                                                                                onChange={(e) => this.setState({ courseDetails: e.target.value })}
                                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                                required />
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Employee </label>
                                                                            <select className="js-example-basic-single"
                                                                                style={{ width: '100%' }} value={employeeId}
                                                                                onChange={e => this.setState({ employeeId: e.target.value })}>
                                                                                <option value={""}>Select</option>
                                                                                {employeeList.length > 0 && employeeList.map(item => (
                                                                                    <option
                                                                                        key={item.EmployeeID}
                                                                                        value={item.EmployeeID}>
                                                                                        {item.Employee}
                                                                                    </option>
                                                                                ))}

                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Status</label>
                                                                            <select className="js-example-basic-single"
                                                                                style={{ width: '100%' }}
                                                                                value={this.state.statusId}
                                                                                onChange={(e) => {
                                                                                    this.setState({ statusId: e.target.value }, () => this.getTrainingApprovalRejectList())
                                                                                }}>
                                                                                <option value={""}>Select</option>
                                                                                {statusList.length > 0 && statusList.map(item => (
                                                                                    <option
                                                                                        key={item.Code}
                                                                                        value={item.Code}>
                                                                                        {item.Description}
                                                                                    </option>
                                                                                ))}

                                                                            </select>
                                                                        </div>

                                                                        {/* 
                                                                <div className="form-group col-md-3">
                                                                    <label>Approved by</label>
                                                                    <select className="js-example-basic-single"
                                                                        style={{ width: '100%' }}
                                                                        value={this.state.approvedById}
                                                                        onChange={(e) => this.setState({ approvedById: e.target.value })}>
                                                                        <option value={""}>Select</option>
                                                                        {approvedByList.length > 0 && approvedByList.map(item => (
                                                                            <option
                                                                                key={item.EngpartnerID}
                                                                                value={item.EngpartnerID}>
                                                                                {item.EngPartnerName}
                                                                            </option>
                                                                        ))}

                                                                    </select>
                                                                </div> */}
                                                                        <div className='form-group col-md-3'>
                                                                            <label>
                                                                                Approved  Date
                                                                            </label>
                                                                            <div className="input-group">
                                                                                <div className='daterangecss'>
                                                                                    <div className="form-group d-flex">
                                                                                        <DatePicker className="form-control"
                                                                                            dateFormat="dd-MM-yyyy"
                                                                                            selected={this.state.approvalDate}
                                                                                            // minDate={this.state.fromDate}
                                                                                            peekNextMonth
                                                                                            showMonthDropdown
                                                                                            showYearDropdown
                                                                                            dropdownMode="select"
                                                                                            // disabled={is_add ? false : finYearId == lastRecord ? false : true}
                                                                                            onChange={(date) => this.setState({ approvalDate: date })}
                                                                                            placeholderText="To Date"
                                                                                            customInput={<ExampleCustomInput />}
                                                                                            dayClassName={() => "example-datepicker-day-class"}
                                                                                            popperClassName="example-datepicker-class"
                                                                                        />


                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group col-md-3 mt-4 text-left">
                                                                            <button type="button" className="btn btn-Add rounded-1" onClick={() => this.getTrainingApprovalRejectList()}> Search</button>
                                                                            <button type="button" className="btn btn-clear rounded-1 mx-1 px-3" onClick={this.clearValue}> Clear</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div> : <></>}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 mt-2">
                                                                <div className='d-flex w-100' style={{ justifyContent: 'flex-end' }}>

                                                                    {
                                                                        TrainingList && checkBoxFlag == true && getModuleUpdateAccess(Training_Approval) ?
                                                                            <>

                                                                                <button type="button" className="btn btn-Add rounded-1 m-1" style={{ lineHeight: '14px', padding: '7px', float: 'right' }}
                                                                                    onClick={e => this.searchApproveToggleModal("allApproval", '', currentRecords)}> Approval </button>
                                                                                <button type="button" class="btn btn-outline-danger rounded-1 m-1" style={{ lineHeight: '14px', padding: '7px', float: 'right' }} onClick={e => this.searchRejectToggleModal('allReject', '', currentRecords)}> Rejection </button>

                                                                            </> : <></>
                                                                    }
                                                                </div>



                                                                <div className="table-responsive tbs4 common-table trail-tb " style={{ width: '100%' }}>
                                                                    {this.record(currentRecords, indexOfFirstRecord, indexOfLastRecord)}
                                                                    {TrainingList.length > 0 ? <Pagination
                                                                        nPages={nPages}
                                                                        currentPage={currentPage}
                                                                        setCurrentPage={this.updateItem}
                                                                        recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                        setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                        totalRecords={TrainingList.length}
                                                                    /> : <></>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>
                <ToastContainer />


                {/* // modal for Approve */}
                <Modal isOpen={_isApprove} onRequestClose={this.cancleApprovalStatusModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Approved</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.cancleApprovalStatusModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to Approved this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.approvalRejectTraining()}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel"
                                            data-dismiss="modal" style={{ marginLeft: '5px' }}
                                            onClick={() => this.setState({ _isApprove: !_isApprove })}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>


                {/* // modal for reject */}
                <Modal isOpen={_isReject} onRequestClose={this.cancleRejectStatusModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div className="modal-header">
                                <h4 className="modal-title modal_title_text">Reject</h4>
                                <button type="button " className="close pop-close" data-dismiss="modal" onClick={this.cancleRejectStatusModal} ><span aria-hidden="true" className="pop-cancel">&times;</span></button>

                            </div>
                            <div className="d-flex flex-wrap row mt-2 p-2">
                                <div className="form-group col-12">
                                    <label>Reason<span class="required1">*</span></label>
                                    <textarea
                                        className="form-group"
                                        name="rejectReason"
                                        rows={5}
                                        cols={50}
                                        style={{ border: "1px solid #dee2e6" }}
                                        maxLength={100}
                                        value={rejectReason}
                                        onChange={e => this.setState({ rejectReason: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-left mod-fot">
                                        <a href="javascript:void(0);" class="btn btn-update footer-btn-inner" onClick={(e) => this.approvalRejectTraining()} style={{ marginLeft: '5px' }}>Rejection</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ _isReject: !_isReject })}>Cancel</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>



            </>
        );
    }
}

export default withRouter(connect(null, {
    trainingApprovalRejectList_action,
    addtraining_action, approveRejectTraining_action,
    fill_action, trainingList_action
})(TrainingApprovalReject));