import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import { leaveApprovalList_action, leaveApprovalStatus_action, leaveApprovalReject_action } from '../../../actions/time_sheet_expense_action/leaveApproval_action'
import { fill_action } from '../../../actions/master_action/checklist_action'
import DatePicker from "react-datepicker";

import Pagination from "../../../utils/pagination";

import { clearLocalStorage, employeeActionId, Leave_Approval, leaveApprovalListUrl, timesheetStatusActionId } from '../../../utils/constants'
import { getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));

class LeaveApproval extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            isOpen: false,
            isLoading: false,
            _isReject: false,
            _isApprove: false,
            IsActive: 0,
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage: 10,
            pageSizeInput: "10",

            LeaveApprovalId: '',
            LeaveApprovalList: [],
            statusId: '',
            employeeId: '',
            userEmployeeId: (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            employeeList: [],
            timesheetStatusId: '',
            timesheetStatusList: [],
            fromDate: '',
            toDate: '',

            // local storage
            employeeIdLALS: '',
            fromDateLALS: '',
            toDateLALS: '',
            statusIdLALS: '',

            rejectReason: '',
            leaveStatusId: '',
            checkBoxFlag: false,
            rejectId: '',
            allSelected: '',
            epmtyList: false,
            isSearchTab: false,

            checkedCurrentRecords: []
        }
        this.clearValue = this.clearValue.bind(this)
        this.getleaveApprovalList = this.getleaveApprovalList.bind(this)
        this.handleDetails = this.handleDetails.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);

        this.leaveApprovalStatusModal = this.leaveApprovalStatusModal.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.cancleApprovalStatusModal = this.cancleApprovalStatusModal.bind(this)
        this.cancleToggleModal = this.cancleToggleModal.bind(this)
    }

    // Employee details 
    componentDidMount = () => {

        this.getFillList(employeeActionId, '')
        this.getFillList(timesheetStatusActionId, '')
        localStorage.setItem("prevSelectedPage", this.currentPage)
        console.log("sagar1314 3423", localStorage.getItem('fromDateLALS'))
        this.setState({
            // isAccessToView: localStorage.getItem('UserId') == '1' ? true : false,
            employeeIdLALS: localStorage.getItem('employeeIdLALS'),
            fromDateLALS: localStorage.getItem('fromDateLALS') != '' ? localStorage.getItem('fromDateLALS') : '',
            toDateLALS: localStorage.getItem('toDateLALS') != '' ? localStorage.getItem('toDateLALS') : '',
            statusIdLALS: localStorage.getItem('statusIdLALS'),

            employeeId: localStorage.getItem('employeeIdLALS') != '' ? localStorage.getItem('employeeIdLALS') : '',
            fromDate: localStorage.getItem('fromDateLALS') != '' ? moment(localStorage.getItem('fromDateLALS')).toDate() : '',
            toDate: localStorage.getItem('toDateLALS') != '' ? moment(localStorage.getItem('toDateLALS')).toDate() : '',
            statusId: localStorage.getItem('statusIdLALS') != '' ? localStorage.getItem('statusIdLALS') : '',
        })

        clearLocalStorage("leaveApproval")
        // this.clearValue()
        setTimeout(() => {
            this.getleaveApprovalList()
        }, 300);
    }

    onEmployeeIdSet(e) {
        this.setState({ employeeId: e.target.value })
        setTimeout(() => {
            this.getleaveApprovalList()
        }, 100);
    }

    onStatusIdSet(e) {
        this.setState({ statusId: e.target.value })
        setTimeout(() => {
            this.getleaveApprovalList()
        }, 100);
    }

    getleaveApprovalList() {
        const { employeeId, statusId, userEmployeeId, fromDate, toDate,
            checkedCurrentRecords, currentPage } = this.state
        this.setState({ isLoading: true })
        clearLocalStorage('leaveApproval')
        this.props
            .leaveApprovalList_action(employeeId != '' ? employeeId : null,
                statusId != 1 && statusId != 3 && statusId != 4 && statusId != 5 ? 2 : statusId,
                fromDate,
                toDate)
            .then((data) => {
                if (data.error != 1) {
                    data.data.map((item) => {
                        item.is_cheked = 0
                    })
                    if (data.data == '') {

                        this.setState({ checkBoxFlag: false, epmtyList: true })
                    }

                    this.setState({
                        LeaveApprovalList: data.data, isLoading: false
                    })

                    console.log("sagar1314", data.data)
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isLoading: false })
                }
            })
            .catch((e) => {
                console.log(e);
            });

        localStorage.setItem("employeeIdLALS", employeeId)
        localStorage.setItem("fromDateLALS", fromDate)
        localStorage.setItem("toDateLALS", toDate)
        localStorage.setItem("statusIdLALS", statusId)
    }


    updateItem(item) {
        this.setState({ currentPage: item, });

        console.log('current page : ', item);

        var prevPage = localStorage.getItem("prevSelectedPage")
        if (prevPage != null && prevPage != item) {
            const { LeaveApprovalList } = this.state;
            const pList = [...LeaveApprovalList];
            
            pList.forEach((data, i) => {
                data.is_cheked = 0
            });
            this.setState({
                LeaveApprovalList: pList,
                allSelected: false
            });
        }
    }

    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
            this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
    };

    handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
            this.setState({ pageSizeInput: input });
        }
    };

    handleDetails(leaveApprovalId, Previous_reject) {
        this.props.navigate('/leaveApprovalDetails', { state: { is_Details: true, leaveApprovalId: leaveApprovalId, Previous_reject: Previous_reject } })
    }

    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    // get Quality Reviewer Name List dropdown list 
    getFillList(value, ref, ref2) {

        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == employeeActionId) {
                        this.setState({ employeeList: data.data, isLoading: false })
                    }

                    if (value == timesheetStatusActionId) {
                        console.log("timesheetStatusActionId", data.data)
                        const array = []
                        data.data != '' ?
                            data.data.map((item) => {
                                if (item.TimeSheetStatusID != 1 && item.TimeSheetStatusID != 5) {
                                    array.push(item)
                                }
                            }) : <></>
                        this.setState({ timesheetStatusList: array, isLoading: false })
                    }

                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    leaveApprovalStatus(e) {
        e.preventDefault()
        const { LeaveApprovalList, currentPage, checkedCurrentRecords } = this.state

        const id = this.state.leaveStatusId;

        const listId = []
        LeaveApprovalList.map((data) => {
            if (data.is_cheked == 1) {
                listId.push(data.LeaveID)
            }
        })

        listId.concat()

        console.log('id', id)
        console.log('listId', listId)

        this.setState({ isLoading: true })
        this.props
            .leaveApprovalStatus_action(id != '' ? id : listId.toString())
            .then((data) => {
                if (data.error != 1) {

                    if (currentPage != 1) {
                        if (id == '') {
                            if (checkedCurrentRecords.length == listId.length) {
                                this.setState({ currentPage: currentPage - 1 })
                                console.log("test---------->1",)
                            } else {
                                console.log("test---------->2", checkedCurrentRecords)
                                this.setState({ currentPage: currentPage })
                            }
                        } else {
                            if (checkedCurrentRecords.length == 1) {
                                console.log("test---------->3",)
                                this.setState({ currentPage: currentPage - 1 })
                            } else {
                                if (currentPage != 1) {
                                    console.log("test---------->4",)
                                    this.setState({ currentPage: currentPage })
                                } else {
                                    console.log("test---------->5",)
                                    this.setState({ currentPage: 1 })
                                }
                            }
                        }
                    }
                    toast.success('Leave Approve Successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isLoading: false, isOpen: false, _isApprove: false, allSelected: false, leaveStatusId: '', listId: [] })
                    this.getleaveApprovalList()
                }
                else {
                    this.setState({ isLoading: false })

                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    leaveApprovalStatusModal(leaveStatusId, LeaveApprovalList) {
        const { isOpen, _ } = this.state
        this.setState({ isOpen: !isOpen })
        this.state._isApprove = true
        this.state._isReject = false
        this.setState({ leaveStatusId: leaveStatusId, checkedCurrentRecords: LeaveApprovalList })
    }

    cancleApprovalStatusModal() {
        this.setState({ isOpen: false })
        this.setState({ leaveStatusId: '' })
    }

    //toggle model for Reject.................................
    toggleModal(rejectId, LeaveApprovalList) {
        const { isOpen } = this.state
        this.state._isApprove = false
        this.state._isReject = true
        this.setState({
            isOpen: !isOpen
        })
        this.setState({ rejectId: rejectId, checkedCurrentRecords: LeaveApprovalList })
    }

    cancleToggleModal() {
        this.setState({
            isOpen: false
        })
        this.setState({ rejectId: '', rejectReason: '' })
    }

    searchApproveToggleModal(LeaveApprovalList) {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen, _isApprove: true, _isReject: false, checkedCurrentRecords: LeaveApprovalList })
    }

    searchRejectToggleModal(LeaveApprovalList) {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen, _isReject: true, _isApprove: false, checkedCurrentRecords: LeaveApprovalList })
    }

    handleCancel() {
        this.state.isOpen = false
        this.clearValue()
    }

    leaveApprovalReject(e) {
        e.preventDefault()
        const { rejectReason, LeaveApprovalList, isOpen, currentPage, checkedCurrentRecords } = this.state
        const id = this.state.rejectId;


        const listId = []
        LeaveApprovalList.map((data) => {
            if (data.is_cheked == 1) {
                listId.push(data.LeaveID)
            }
        })

        listId.concat()

        console.log('id', id)
        console.log('listId', listId)

        this.setState({ isLoading: true })
        if (rejectReason != "") {
            this.props
                .leaveApprovalReject_action(id != '' ? id : listId, rejectReason)
                .then((data) => {
                    if (data.error != 1) {

                        if (currentPage != 1) {
                            if (id != '') {
                                if (checkedCurrentRecords.length == listId.length) {
                                    this.setState({ currentPage: currentPage - 1 })
                                    console.log("test---------->1",)
                                } else {
                                    console.log("test---------->2", checkedCurrentRecords)
                                    this.setState({ currentPage: currentPage })
                                }
                            } else {
                                if (checkedCurrentRecords.length == 1) {
                                    console.log("test---------->3",)
                                    this.setState({ currentPage: currentPage - 1 })
                                } else {
                                    if (currentPage != 1) {
                                        console.log("test---------->4",)
                                        this.setState({ currentPage: currentPage })
                                    } else {
                                        console.log("test---------->5",)
                                        this.setState({ currentPage: 1 })
                                    }
                                }
                            }
                        }

                        toast.success('Leave Reject Successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false, isOpen: false, _isReject: false, allSelected: false, rejectId: '', listId: [], rejectReason: '' })
                        this.getleaveApprovalList()

                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter reason', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }

    clearValue() {
        this.setState({
            rejectReason: '',
            statusId: '',
            employeeId: '',
            fromDate: '',
            toDate: ''
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getleaveApprovalList()
        }, 100);

    }

    leaveCheckbox(data2, is_cheked, e) {

        const { LeaveApprovalList } = this.state;
        const pList = [...LeaveApprovalList];
        const event = e.target.checked

        if (event == true) {
            pList.forEach((data, i) => {
                if (data2.LeaveID === data.LeaveID) {
                    data.is_cheked = 1;
                }
            });
            this.setState({
                LeaveApprovalList: pList,
            })

        } else {
            pList.forEach((data, i) => {
                if (data2.LeaveID === data.LeaveID) {
                    data.is_cheked = 0;
                }
            });
        }
        this.setState({
            LeaveApprovalList: pList,
        });

        const checkedAllSelected = pList.every(item => item.is_cheked == 1)

        if (checkedAllSelected) {
            this.setState({ allSelected: true })
        } else {
            this.setState({ allSelected: false })
        }

        const checkboxSelected = pList.some(item => item.is_cheked == 1)

        // console.log("************ checkboxSelected", checkboxSelected)

        if (checkboxSelected) {
            this.setState({ checkBoxFlag: true })
        } else {
            this.setState({ checkBoxFlag: false })
        }
    }


    allSelectedCheckbox(e, indexOfFirstRecord, indexOfLastRecord) {
        const { statusId, allSelected, LeaveApprovalList } = this.state
        this.setState({ allSelected: e.target.checked })

        if (e.target.checked == true) {
            // LeaveApprovalList.map((data) => {
            //     data.is_cheked = 1
            // })
            LeaveApprovalList.map((data, index) => {
                if (index >= indexOfFirstRecord && index <= indexOfLastRecord -1) {
                    data.is_cheked = 1;
                }
                return data;
            });
            this.setState({ LeaveApprovalList: LeaveApprovalList, checkBoxFlag: true })
        } else {
            LeaveApprovalList.map((data) => {
                data.is_cheked = 0
            })

            this.setState({ LeaveApprovalList: LeaveApprovalList, checkBoxFlag: false })
        }

    }

    record(LeaveApprovalList, indexOfFirstRecord, indexOfLastRecord) {
        const { statusId, allSelected, currentPage } = this.state
        console.log("statusId", statusId)
        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        {/* {!epmtyList ? */}
                        <>
                            {statusId != 1 && statusId != 3 && statusId != 4 && statusId != 5 && getModuleUpdateAccess(Leave_Approval) ?
                                <th className='text-center' style={{ width: '30px' }} >
                                    <input
                                        type='checkbox'
                                        onClick={(e) => this.allSelectedCheckbox(e, indexOfFirstRecord, indexOfLastRecord)}
                                        checked={allSelected}
                                    /> </th>
                                : <></>
                            }
                        </>
                        {/* // : <></>} */}
                        <th style={{ width: '30px' }} ></th>
                        {
                            statusId != 1 && statusId != 3 && statusId != 4 && statusId != 5 && getModuleUpdateAccess(Leave_Approval) ?
                                <>
                                    <th style={{ textAlign: 'left', width: '30px' }} ></th>
                                    {/* <th style={{ textAlign: 'left' }} ></th> */}
                                </>
                                : <></>
                        }

                        <th style={{ textAlign: 'left' }} >EmployeeName</th>
                        <th style={{ textAlign: 'left' }} >From Date</th>
                        <th style={{ textAlign: 'left' }} >To Date</th>
                        <th style={{ textAlign: 'left' }} >Leave Type</th>
                        <th style={{ textAlign: 'left' }} >Remark</th>
                        <th style={{ textAlign: 'left' }} >Previous Reject</th>
                        <th style={{ textAlign: 'left' }} >Status</th>
                    </tr>
                </thead>
                <tbody>
                    {LeaveApprovalList.length > 0 ? (
                        LeaveApprovalList.map((data, i) => (
                            <tr key={data.LeaveID}>

                                {statusId != 1 && statusId != 3 && statusId != 4 && statusId != 5 && getModuleUpdateAccess(Leave_Approval) ?
                                    <td>
                                        <input
                                            onClick={(e) => this.leaveCheckbox(data, data.is_cheked, e)}
                                            type="checkbox"
                                            checked={data.is_cheked}
                                        />
                                    </td> : <></>
                                }
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        <a><i class="fa fa-eye" style={{ color: '#3498db' }} onClick={() => this.handleDetails(data.LeaveID, data.Previous_reject)} /></a>
                                    </div>
                                </td>
                                {/* {data.StatusName == 'Pending for Approval' ? <td style={{ paddingLeft: '1.4rem' }} className='d-flex '>
                                            <button
                                                disabled={data.StatusName != 'Pending for Approval'}
                                                type="button"
                                                className="btn btn-outline-info  mr-1 py-1 mx-1 px-3 m-1 h-auto"
                                                onClick={e => this.searchApproveToggleModal("singleApproval", data.TrainingID)}
                                            > Approval </button>
                                            <button
                                                disabled={data.StatusName != 'Pending for Approval'}
                                                type="button"
                                                className="btn btn-outline-danger  mr-1 py-1 mx-1 px-4 m-1 h-auto"
                                                onClick={e => this.searchRejectToggleModal("singleReject", data.TrainingID)}
                                            > Rejection </button>
                                        </td> : <></>} */}
                                {statusId != 1 && statusId != 3 && statusId != 4 && statusId != 5 && getModuleUpdateAccess(Leave_Approval) ?
                                    <>
                                        <td>
                                            <button
                                                // disabled={data.StatusName != 'Pending for Approval'}
                                                type="button"
                                                className="btn btn-outline-info rounded-1 mr-1 py-1 mx-1 px-3 m-1 h-auto"
                                                onClick={e => this.leaveApprovalStatusModal(data.LeaveID, LeaveApprovalList)}
                                            > Approval </button>
                                            <button
                                                // disabled={data.StatusName != 'Pending for Approval'}
                                                type="button"
                                                className="btn btn-outline-danger rounded-1 mr-1 py-1 mx-1 px-3 m-1 h-auto"
                                                onClick={e => this.toggleModal(data.LeaveID, LeaveApprovalList)}
                                            > Rejection </button>
                                        </td>
                                        {/* <td style={{ textAlign: 'left', width: '70px' }}>
                                            <a onClick={(e) => this.leaveApprovalStatusModal(data.LeaveID)} >Approval</a></td>
                                        <td style={{ textAlign: 'left', width: '70px' }} ><a onClick={(e) => this.toggleModal(data.LeaveID)}>Rejection</a></td> */}
                                    </>
                                    : <></>
                                }


                                <td style={{ textAlign: 'left' }} >{data.EmployeeName}</td>
                                <td style={{ textAlign: 'left' }}>{data.LeaveFromDate != '' && data.LeaveFromDate != null && moment(data.LeaveFromDate).format('DD-MM-YYYY')}</td>
                                <td style={{ textAlign: 'left' }}>{data.LeaveToDate != '' && data.LeaveToDate != null && moment(data.LeaveToDate).format('DD-MM-YYYY')}</td>
                                <td style={{ textAlign: 'left' }} >{data.LeaveType}</td>
                                <td style={{ textAlign: 'left', width: '500px', whiteSpace: 'normal' }} >{data.Remark}</td>
                                <td style={{ textAlign: 'left' }} >{data.Previous_reject}</td>
                                <td style={{ textAlign: 'left' }}>{data.Name}</td>
                            </tr>
                        ))

                    ) : (<tr><td colSpan={9} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }

    render() {
        const { isSearchTab, isAccessToView, isOpen, isLoading, LeaveApprovalList, employeeId, employeeList, statusId, timesheetStatusList, fromDate, toDate, rejectReason, _isReject, _isApprove, checkBoxFlag, } = this.state
        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = LeaveApprovalList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(LeaveApprovalList.length / recordsPerPage)
        console.log('currentRecords : ', currentRecords);
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />


                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>Leave Approval</a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className='page-space-from-bottom'>
                                        <div className="row">
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n.accordion-button::after {\nbackground-size: 13px;\ndisplay: none;\n} \n" }} />
                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="accordion accordion-flush " id="accordionFlushExample">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingOne">
                                                                    <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                                                        <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                    </button>
                                                                </h2>
                                                                {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                                <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                    <div className="d-flex flex-wrap row">

                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Employee
                                                                            </label>
                                                                            <select
                                                                                className="js-example-basic-single"
                                                                                style={{ width: "100%" }}

                                                                                value={employeeId}
                                                                                onChange={e => this.onEmployeeIdSet(e)}
                                                                            >
                                                                                <option value={''}>Select</option>
                                                                                {employeeList.length > 0 ? employeeList.map(item => (
                                                                                    <option
                                                                                        key={item.emmnID}
                                                                                        value={item.emmnID}>
                                                                                        {item.empsFirstName}
                                                                                    </option>
                                                                                )) : <></>}
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Status
                                                                            </label>
                                                                            <select
                                                                                className="js-example-basic-single"
                                                                                style={{ width: "100%" }}

                                                                                value={statusId}
                                                                                onChange={e => this.onStatusIdSet(e)}
                                                                            >
                                                                                <option value={''}>Select</option>
                                                                                {timesheetStatusList.length > 0 ? timesheetStatusList.map(item => (
                                                                                    <option
                                                                                        key={item.TimeSheetStatusID}
                                                                                        value={item.TimeSheetStatusID}>
                                                                                        {item.Name}
                                                                                    </option>
                                                                                )) : <></>}
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group col-md-2">
                                                                            <label>
                                                                                FromDate
                                                                            </label>
                                                                            <div className="form-group d-flex">

                                                                                <DatePicker className="form-control"

                                                                                    dateFormat="dd-MM-yyyy"
                                                                                    selected={fromDate}
                                                                                    value={fromDate}
                                                                                    maxDate={toDate}
                                                                                    onChange={(date) => { this.setState({ fromDate: date }) }}
                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    customInput={<ExampleCustomInput />}
                                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                                    popperClassName="example-datepicker-class"
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-2">
                                                                            <label>
                                                                                ToDate
                                                                            </label>
                                                                            <div className="form-group d-flex">
                                                                                <DatePicker className="form-control"
                                                                                    dateFormat="dd-MM-yyyy"
                                                                                    selected={toDate}
                                                                                    value={toDate}
                                                                                    minDate={fromDate}
                                                                                    onChange={(date) => { this.setState({ toDate: date }) }}
                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    customInput={<ExampleCustomInput />}
                                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                                    popperClassName="example-datepicker-class"
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-2 mt-4 p-0">
                                                                            <button type="button" className="btn btn-Add rounded-1" onClick={e => this.getleaveApprovalList()}> Search</button>
                                                                            <button type="button" className="btn btn-clear rounded-1 mx-1 px-3" onClick={e => this.clearValue()}> Clear</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div> : <></>}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 mt-2">

                                                                {
                                                                    LeaveApprovalList && checkBoxFlag == true && getModuleUpdateAccess(Leave_Approval) ?
                                                                        <>
                                                                            <button type="button" class="btn btn-outline-danger rounded-1 m-1" style={{ lineHeight: '14px', padding: '7px', float: 'right' }} onClick={e => this.searchRejectToggleModal(currentRecords)}> Rejection</button>

                                                                            <button type="button" className="btn btn-Add rounded-1 m-1" style={{ lineHeight: '14px', padding: '7px', float: 'right' }}
                                                                                onClick={e => this.searchApproveToggleModal(currentRecords)}> Approval</button>
                                                                        </> : <></>
                                                                }

                                                                <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                                    {this.record(currentRecords, indexOfFirstRecord, indexOfLastRecord)}
                                                                    {LeaveApprovalList.length > 0 ? <Pagination
                                                                        nPages={nPages}
                                                                        currentPage={currentPage}
                                                                        setCurrentPage={this.updateItem}
                                                                        recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                        setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                        totalRecords={LeaveApprovalList.length}
                                                                    /> : <></>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>


                <ToastContainer />
                {/* // modal for Approve */}
                <Modal isOpen={isOpen && _isApprove} onRequestClose={(e) => this.setState({ isOpen: !isOpen })}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Approved</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.cancleApprovalStatusModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to Approved this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.leaveApprovalStatus(e)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.cancleApprovalStatusModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>


                {/* // modal for reject */}
                <Modal isOpen={isOpen && _isReject} onRequestClose={() => this.setState({ isOpen: !isOpen })}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div className="modal-header">
                                <h4 className="modal-title modal_title_text">Reject</h4>
                                <button type="button " className="close pop-close" data-dismiss="modal" onClick={this.cancleToggleModal} ><span aria-hidden="true" className="pop-cancel">&times;</span></button>

                            </div>
                            <div className="d-flex flex-wrap row mt-2 p-2">
                                <div className="form-group col-12">
                                    <label>Reason<span class="required1">*</span></label>
                                    <textarea
                                        className="form-group"
                                        name="rejectReason"
                                        rows={5}
                                        cols={50}
                                        style={{ border: "1px solid #dee2e6" }}
                                        // defaultValue={this.state.remark}
                                        value={rejectReason}
                                        onChange={e => this.setState({ rejectReason: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-left mod-fot">
                                        <a href="javascript:void(0);" class="btn btn-update footer-btn-inner" onClick={(e) => this.leaveApprovalReject(e)} style={{ marginLeft: '5px' }}>Reject</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.cancleToggleModal}>Cancel</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>

            </>
        );
    }
}


export default withRouter(connect(null, { leaveApprovalList_action, fill_action, leaveApprovalStatus_action, leaveApprovalReject_action })(LeaveApproval))