import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../../utils/withRouter';
import Loader from '../../../../utils/Loader'
import { fill_action } from '../../../../actions/master_action/checklist_action';
import { clientAddressList_action, addClientAddress_action, updateClientAddress_action, clientAddressDetail_action } from '../../../../actions/master_action/client_action'
import Modal from "react-modal";
import { locationCityActionId, locationStateActionId, locationTypeActionId, Master, } from '../../../../utils/constants'
import { getModuleUpdateAccess, getModuleWriteAccess } from '../../../../utils/RoleAccess';

const mapStateToProps = state => ({
    ...state
})
const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));
class ClientAddress extends Component {
    constructor(props) {
        super(props)
        this.state = {
            is_add: false,
            is_update: false,
            ClientID: '',
            clientTypeId: '',
            isLoading: false,
            isAccessToView: true,
            addressList: [],
            addressCityList: [],
            addressStateList: [],
            addressTypeList: [],

            is_addAddress: false,
            is_updateAddress: false,
            isOpenAddress: true,
            addressTypeId: '',
            address1: '',
            address2: '',
            address3: '',
            stateId: '',
            cityId: '',
            pinCode: '',
            ClientAddressID: '',
            addressToastMsgID: '',
        }
        this.getFillList = this.getFillList.bind(this)
        this.addressRecord = this.addressRecord.bind(this)
        this.getClientAddressList = this.getClientAddressList.bind(this)
        this.toggleUpdateAddress = this.toggleUpdateAddress.bind(this)
        this.toggleAddAddress = this.toggleAddAddress.bind(this)
        this.toggleModalAddress = this.toggleModalAddress.bind(this)
        this.handleAddAddress = this.handleAddAddress.bind(this)
        this.handleUpdateAddress = this.handleUpdateAddress.bind(this)
        this.getClientAddressDetails = this.getClientAddressDetails.bind(this)
        this.handleAddressReset = this.handleAddressReset.bind(this)
    }

    componentDidMount = () => {
        toast.dismiss();
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            ClientID: this.props.location.state != null && this.props.location.state.ClientID != null ? this.props.location.state.ClientID : '',
            clientTypeId: this.props.location.state != null && this.props.location.state.clientTypeId != null ? this.props.location.state.clientTypeId : '',
            isAccessToView: true,
        })
        this.getFillList(locationTypeActionId, '')
        this.getFillList(locationStateActionId, '')
        this.getFillList(locationCityActionId, '')
        setTimeout(() => {
            this.getClientAddressList()
        }, 500);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
          const focusedElement = document.activeElement;
          const { previouslyFocusedElement } = this.state;
    
          if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
          }
    
          if (focusedElement.classList.contains('form-control') ||
            focusedElement.classList.contains('js-example-basic-single') ||
            focusedElement.classList.contains('example-custom-input')) {
            focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
            this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
          }
        }
      };
    
    
      handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;
    
        if (previouslyFocusedElement) {
          previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }
    
        if (clickedElement.classList.contains('form-control') ||
          clickedElement.classList.contains('js-example-basic-single') ||
          clickedElement.classList.contains('example-custom-input')) {
          clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
          this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
          this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
      };
    getFillList(value, ref, ref2) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == locationCityActionId) {
                        this.setState({ addressCityList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);
                    }

                    if (value == locationStateActionId) {
                        this.setState({ addressStateList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);

                    }

                    if (value == locationTypeActionId) {
                        this.setState({ addressTypeList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);

                    }
                }
            })
    }

    // ////////ADDRESS
    getClientAddressList() {
        this.setState({ isLoading: true })
        var ClientId = this.state.ClientID != null && this.state.ClientID != '' ? this.state.ClientID : sessionStorage.getItem('ClientMaster')
        this.props
            .clientAddressList_action(ClientId)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        addressList: data.data,
                        isLoading: false,
                    })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
                setTimeout(() => {
                }, 500);

            })
            .catch((e) => {
                console.log(e)
            })
    }

    getClientAddressDetails() {
        this.setState({ isLoading: true })
        setTimeout(() => {
            var id = this.state.ClientAddressID
            this.props
                .clientAddressDetail_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            addressTypeId: data.data[0]['AddressTypeID'],
                            address1: data.data[0]['Address1'],
                            address2: data.data[0]['Address2'],
                            address3: data.data[0]['Address3'],
                            stateId: data.data[0]['StateID'],
                            cityId: data.data[0]['CityID'],
                            pinCode: data.data[0]['PinCode'],
                        })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            this.setState({ isLoading: false })

        }, 500);
    }

    toggleAddAddress() {
        const { is_addAddress } = this.state
        this.setState({ is_addAddress: !is_addAddress })
        var clientId = this.state.ClientID != null && this.state.ClientID != '' ? this.state.ClientID : sessionStorage.getItem('ClientMaster')
        console.log(clientId)
        this.handleAddressReset()
        this.toggleModalAddress()
    }

    handleAddAddress(e) {
        e.preventDefault()
        const { is_addAddress, addressToastMsgID, addressTypeId, address1, address2, address3, stateId, cityId, pinCode } = this.state
        var clientId = this.state.ClientID != null && this.state.ClientID != '' ? this.state.ClientID : sessionStorage.getItem('ClientMaster')
        if (clientId != "" && address1 != "" && addressTypeId != "") {
            this.setState({ isLoading: true })
            // console.log("weqe",addressTypeId,stateId,cityId)
            this.props
                .addClientAddress_action(clientId, addressTypeId, address1, address2, address3, stateId, cityId, pinCode)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 1000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false, is_addAddress: !is_addAddress })
                        this.toggleModalAddress()
                        this.handleAddressReset()
                        setTimeout(() => {
                            this.getClientAddressList()
                        }, 500);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    toggleUpdateAddress(address) {
        const { is_updateAddress } = this.state
        this.setState({ is_updateAddress: !is_updateAddress, is_addAddress:false, ClientAddressID: address,  })
        this.getClientAddressDetails()
        this.toggleModalAddress()
    }

    handleUpdateAddress(e) {
        e.preventDefault()
        const { ClientAddressID, ClientID, is_updateAddress, addressTypeId, address1, address2, address3, stateId, cityId, pinCode } = this.state
        const id = ClientAddressID
        if (ClientID != "" && address1 != "" && addressTypeId != "" && ClientAddressID != '') {
            this.setState({ isLoading: true })
            this.props
                .updateClientAddress_action(id, ClientID, addressTypeId, address1, address2, address3, stateId, cityId, pinCode)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false, is_updateAddress: !is_updateAddress })
                        this.toggleModalAddress()
                        this.getClientAddressList()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    toggleModalAddress(address) {
        const { isOpenAddress } = this.state
        this.setState({ isOpenAddress: !isOpenAddress })
    }

    handleAddressReset() {
        this.setState({
            addressTypeId: '',
            address1: '',
            address2: '',
            address3: '',
            stateId: '',
            cityId: '',
            pinCode: '',
        })
    }

    addressRecord(addressList) {
        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{}} >Type</th>
                        <th style={{}} >Address 1</th>
                        <th style={{}} >Address 2</th>
                        <th style={{}} >Address 3</th>
                        <th style={{}} >State</th>
                        <th style={{}} >City</th>
                        <th style={{}} >Pin</th>

                    </tr>
                </thead>
                <tbody>
                    {addressList.length > 0 ? (
                        addressList.map((data, i) => (
                            <tr key={data.ID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        {getModuleUpdateAccess(Master) ?
                                            <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.toggleUpdateAddress(data.ID)} /></a> :
                                            <a><i className="fa fa-eye" onClick={() => this.toggleUpdateAddress(data.ID)} /></a>
                                        }
                                    </div>
                                </td>
                                <td >{data.AddressType}</td>
                                <td>{data.Address1}</td>
                                <td>{data.Address2}</td>
                                <td>{data.Address3}</td>
                                <td>{data.State}</td>
                                <td>{data.City}</td>
                                <td>{data.PinCode}</td>
                            </tr>
                        ))
                    ) : (<tr><td colSpan={12} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }
    render() {
        const { isLoading, addressList, ClientID, is_addAddress, isOpenAddress, addressTypeId, stateId, cityId, addressCityList, addressStateList, addressTypeList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <div className="">
                    <div className="content-wrapper h-auto" style={{height: 'calc(100vh - 200px)'
}}>
                        <div className="row">
                            <style
                                type="text/css"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                }}
                            />
                            <div className='page-scroll-from-bot'>
                                <div className="col-md-12 pg-body grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">

                                            {/* // address code start */}
                                            <div class="row mt-4">
                                                <div class="col-12">
                                                    <h5 style={{ padding: '7px', float: 'left' }}><b>Address</b></h5>
                                                    {getModuleWriteAccess(Master) &&
                                                        <button type="button" className="btn btn-Add rounded-0 mb-2"
                                                            style={{ lineHeight: '14px', padding: '7px', float: 'right' }} onClick={this.toggleAddAddress}> Add</button>}
                                                    <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                        {this.addressRecord(addressList)}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* address code end */}
                                            {/* individual location start */}

                                            {/* <div class="row mt-4" >
                                                    <div class="col-12">
                                                        <h5 style={{ padding: '7px', float: 'left' }}><b>LOCATION</b></h5>

                                                        <div className="accordion accordion-flush " id="accordionFlushExample">
                                                            <div>
                                                                <h2 className="accordion-header" id="flush-headingOne">
                                                                    <button className="accordion-button collapsed list-search" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                                        <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                                    <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                        <div className="d-flex flex-wrap row">
                                                                            <div className="form-group col-md-2">
                                                                                <label>Location Type</label>
                                                                                <input type="text"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    value={loactionType}
                                                                                    onChange={(e) => this.setState({ loactionType: e.target.value })}
                                                                                    onKeyDown={e => this.handleKeyDown(e)}
                                                                                    required />
                                                                            </div>

                                                                            <div className="form-group col-md-2">
                                                                                <label>Location Name</label>
                                                                                <input type="text"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    value={locationName}
                                                                                    onChange={(e) => this.setState({ locationName: e.target.value })}
                                                                                    onKeyDown={e => this.handleKeyDown(e)}
                                                                                    required />
                                                                            </div>

                                                                            <div className="form-group col-md-2">
                                                                                <label>Contact Person</label>
                                                                                <input type="text"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    value={contactPerson}
                                                                                    onChange={(e) => this.setState({ contactPerson: e.target.value })}
                                                                                    onKeyDown={e => this.handleKeyDown(e)}
                                                                                    required />
                                                                            </div>

                                                                            <div className="form-group col-md-2 ">
                                                                                <button type="button" className="btn btn-Add rounded-0" style={{ marginTop: '18px' }} onClick={e => this.handleSearchLocation()}> Search</button>
                                                                                <button type="button" className="btn btn-clear rounded-0 mx-1" style={{ marginTop: '18px' }} onClick={e => this.handleLocationReset()}> Clear</button>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <button type="button" className="btn btn-Add rounded-0 m-2"
                                                            style={{ lineHeight: '14px', padding: '7px', float: 'right' }} onClick={this.toggleAddLocation} disabled={ClientID == null || ClientID == ''}> Add</button>
                                                        <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                            {this.locationRecord(locationList)}
                                                        </div>
                                                    </div>
                                                </div> */}
                                            {/* individual location end */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end
   */}
                </div>
                {/* end page */}
                {/* <ToastContainer /> */}
                <Modal isOpen={!isOpenAddress} onRequestClose={ is_addAddress == true  ? this.toggleModalAddress :() => this.toggleUpdateAddress()}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content mt-4" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                            {is_addAddress == true ?
                              <h4 class="modal-title modal_title_text">Add Company Address</h4> : 
                              <h4 class="modal-title modal_title_text">Update Company Address</h4>}
                                {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModalAddress}>&times;</button> */}
                                {is_addAddress == true ? <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleAddAddress} ><span aria-hidden="true" class="pop-cancel">&times;</span></button> : <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.toggleUpdateAddress()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>}


                            </div>
                            <div class="modal-body pt-1">

                                <div class="d-flex flex-wrap row ">
                                    <div class="form-group col">
                                        <label>Type<span class="required1">*</span></label>
                                        <select className="js-example-basic-single mt-n2" style={{ width: '100%' }}
                                            value={addressTypeId}
                                            onChange={(e) => this.setState({ addressTypeId: e.target.value })}
                                        >
                                            <option value={''} >Select</option>
                                            {addressTypeList.length > 0 && addressTypeList.map(data => (
                                                <option value={data.adtnID} key={data.adtnID}>{data.adtsAddressType}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                    <div class="form-group col mt-1">
                                        <label>State</label>
                                        <select className="js-example-basic-single mt-n2" style={{ width: '100%' }}
                                            value={stateId}
                                            onChange={(e) => this.setState({ stateId: e.target.value })}
                                        >
                                            <option value={''}>Select</option>
                                            {addressStateList.length > 0 && addressStateList.map(data => (
                                                <option value={data.StateID} key={data.StateID}>{data.State}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                </div>




                                <div class="d-flex flex-wrap row mt-1">
                                    <div class="form-group col-md-12">
                                        <label>City</label>
                                        <select className={stateId == null || stateId == '' ? 'js-example-basic-single bg-secondary' : 'js-example-basic-single'} style={{ width: '100%' }}
                                            value={cityId}
                                            onChange={(e) => this.setState({ cityId: e.target.value })}
                                            disabled={stateId == null || stateId == ''}
                                        >
                                            <option value={''}>Select</option>
                                            {addressCityList.length > 0 && addressCityList.map(data => data.ctynStateID == stateId ? (
                                                <option value={data.ctynID} key={data.ctynID}>{data.ctysCity}</option>
                                            ) : null)
                                            }
                                        </select>
                                    </div>
                                </div>



                                <div class="d-flex flex-wrap row mt-1">
                                    <div className="form-group col-12">
                                        <label>Address<span class="required1">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='Line 1'
                                            value={this.state.address1}
                                            onChange={(e) => this.setState({ address1: e.target.value })}
                                            required
                                        />
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap row mt-1">
                                    <div className="form-group col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='Line 2'
                                            value={this.state.address2}
                                            onChange={(e) => this.setState({ address2: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap row mt-1">
                                    <div className="form-group col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='Line 3'
                                            value={this.state.address3}
                                            onChange={(e) => this.setState({ address3: e.target.value })}
                                        />
                                    </div>
                                    <div className="form-group col-md-6 mt-1">
                                        <label>Pin</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='pincode'
                                            value={this.state.pinCode}
                                            onChange={(e) => {
                                                const userInput = e.target.value;
                                                // Check if userInput contains only numerical characters
                                                if (/^\d*$/.test(userInput)) {
                                                    this.setState({ pinCode: userInput });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-0 px-1">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        {is_addAddress == true && getModuleWriteAccess(Master) ? <a class="btn save_btn del-pop-save" onClick={this.handleAddAddress}> Save</a> : getModuleUpdateAccess(Master) && <a class="btn save_btn del-pop-save" onClick={this.handleUpdateAddress}> Update</a>}
                                        {is_addAddress == true && getModuleWriteAccess(Master)? <a class="btn reset_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.handleAddressReset}>Reset</a> : <></>}
                                        {is_addAddress == true ? <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleAddAddress}>Cancel</a> : <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.toggleUpdateAddress()}>Cancel</a>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withRouter(connect(mapStateToProps, { fill_action, clientAddressList_action, addClientAddress_action, updateClientAddress_action, clientAddressDetail_action })(ClientAddress))