import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    platfarm, headers,
    TEST_LIST_ACTION_TYPE,
    TEST_DETAILS_ACTION_TYPE,
    TEST_UPDATE_ACTION_TYPE,
    TEST_ADD_ACTION_TYPE,
    TEST_DELETE_ACTION_TYPE,
    TestListUrl,
    addTestUrl,deleteTestUrl,
    getTestDetailsUrl,
    submitTestUrl,
    TrainingStatus,fetchAuthReport
} from "../../utils/constants"
import moment from 'moment';



export const TestList_action = () => async (dispatch) => {
    try {

        const param = {
            userId: localStorage.getItem('UserId'),
            platform: platfarm,
            EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
           }
        console.log(param);
       
        const res = await axiosGet(TestListUrl, param);
        dispatch({
            type: TEST_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const TestDetails_action = (levelId, ChapterID, TypeOfTestID) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            level: levelId != "" ? parseInt(levelId) : null, 
            ChapterID :  ChapterID != "" ? parseInt(ChapterID) : null, 
            TypeOfTestID :  TypeOfTestID != "" ? parseInt(TypeOfTestID) : null, 

        }        
        const res = await axiosGet(getTestDetailsUrl, param);
        console.log("getTestDetailsUrl", param)
        dispatch({
            type: TEST_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

// export const addTest_action = (trainigAddList) => async (dispatch) => {
//         try {
//             // new Date(moment(auditPeriodStartDate ).format('MM-DD-YYYY')+'Z').toISOString(),
//             // moment(ToTrainingDate).format('MM-DD-YYYY')
//             const mapdata = {
//                 UserID: localStorage.getItem('UserId'),
//                 Platform: platfarm,
//                 EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
//                 trainigAddList: trainigAddList,
//             }
//             const res = await axiosPost(addTestUrl, mapdata, headers);

//             dispatch({
//                 type: TEST_ADD_ACTION_TYPE,
//                 payload: res,
//             });

//             return Promise.resolve(res);
//         } catch (err) {
//             return Promise.reject(err);
//         }
//     };

    export const submitTest_action = (isFinalSubmit, testId, extractData) => async (dispatch) => {
        try {
            // new Date(moment(auditPeriodStartDate ).format('MM-DD-YYYY')+'Z').toISOString(),
            // moment(ToTrainingDate).format('MM-DD-YYYY')
            const mapdata = {
                userId: localStorage.getItem('UserId'),
                platform: platfarm,
                EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
                TestID: testId,
                isSubmit : isFinalSubmit,
                extractData : extractData
            }
//             @userId int,
// @platform Varchar (100),
// @EmployeeID INT,
// @TestID INT,
// @isSubmit bit,
            const res = await axiosPost(submitTestUrl, mapdata, headers);

            dispatch({
                type: TEST_UPDATE_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };


export const deleteTest_action = (id) => async (dispatch) => {
    try {
        const param = {
            userID: localStorage.getItem('UserId'),
            employeeID: null,
            Platform: platfarm,
            trainingID: id,

        }
        const res = await axiosGet(deleteTestUrl, param);
        dispatch({
            type: TEST_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

