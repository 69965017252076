import { axiosPost, axiosGet } from "../../react_axios/Axios"
import { employeeListUrl, employeeDetailsUrl, addEmployeeListUrl, deleteEmployeeUrl, updateEmployeeUrl, platfarm, EMPLOYEE_LIST_ACTION_TYPE, EMPLOYEE_LIST_DETAILS_ACTION_TYPE, EMPLOYEE_LIST_ADD_ACTION_TYPE, EMPLOYEE_LIST_UPDATE_ACTION_TYPE, EMPLOYEE_LIST_DELETE_ACTION_TYPE, headers, employeeContactListUrl,addEmployeeContactUrl, updateEmployeeContactUrl, employeeContactDetailsUrl, EMPLOYEE_CONTACT_LIST_ACTION_TYPE, EMPLOYEE_CONTACT_ADD_ACTION_TYPE, EMPLOYEE_CONTACT_DETAILS_ACTION_TYPE, EMPLOYEE_CONTACT_UPDATE_ACTION_TYPE, deleteEmployeeEngRoleUrl, ROLEPLAYED_EMPLOYEE_DELETE_ACTION_TYPE, EMPLOYEE_CONTACT_DELETE_ACTION_TYPE, deleteEmployeeContactUrl } from "../../utils/constants"
import moment from 'moment'

export const employeeList_action = (code, employeeName, gender, designation, department, manager, partner, email, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Code: code,
            EmployeeName: employeeName,
            Gender: gender,
            Designation: designation,
            Department: department,
            Manager: manager,
            Partner: partner,
            Email: email,
            Status: status,
        }
        const res = await axiosGet(employeeListUrl, param);
        dispatch({
            type: EMPLOYEE_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const employeeDetail_action = (id) => async (dispatch) => {
    try {
        const param = {
            ID: id,
            UserID: localStorage.getItem('userId'),
            Platform: platfarm
        }

        const res = await axiosGet(employeeDetailsUrl, param);
        dispatch({
            type: EMPLOYEE_LIST_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const addEmployeeList_action = (prefixId, firstName, lastName, code, designationId, departmentId, managerId,
    partnerId, dateofBirth, email, genderId, bloodGroupId, maritalStatusId, anniversaryDate, joiningDate,
    resignDate, ratePerHour, contactNo, accountNo, membershipID, isTimesheetMandatory, leaveBal, creditLeaveBal,
     isApprover, leaveTaken, creditLeaveTaken, addedRolePlayedList, totalAnualHours,UserEngRole,UserName,Password) => async (dispatch) => {

        try {
            const mapdata = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                PrefixId: prefixId,
                FirstName: firstName,
                LastName: lastName != '' ? lastName : null,
                EmpCode: code,
                DesignationID: designationId,
                DepartmentID: departmentId != '' ? departmentId : null,
                ReportManagerID: managerId != '' ? managerId : null,
                PartnerID: partnerId != '' ? partnerId : null,
                DateofBirth: dateofBirth != null && dateofBirth != '' && 
                moment(dateofBirth).format('MM-DD-YYYY'),
                EmailID: email != '' ? email : null,
                GenderID: genderId,
                BloodGroupID: bloodGroupId != '' ? bloodGroupId : null,
                MaritalStatus: maritalStatusId != '' ? maritalStatusId : null,
                AniversaryDate: anniversaryDate != null && anniversaryDate != '' && moment(anniversaryDate).format('MM-DD-YYYY') ? anniversaryDate : null,
                FromDate: joiningDate != null && joiningDate != '' && moment(joiningDate).format('MM-DD-YYYY'),
                ToDate: resignDate != null && resignDate != '' && moment(resignDate).format('MM-DD-YYYY') ? resignDate : null,
                RatePerHour: ratePerHour != '' ? ratePerHour : null,
                ContactNo: contactNo != '' ? contactNo : null,
                AccountNo: accountNo != '' ? accountNo : null,
                MembershipID: membershipID != '' ? membershipID : null,
                MandatoryTimeSheet: isTimesheetMandatory != '' ? isTimesheetMandatory : null,
                LeaveBal: leaveBal != '' ? leaveBal : null,
                CreditLeaveBal: creditLeaveBal != '' ? creditLeaveBal : null,
                IsApprover: isApprover != '' ? isApprover : null,
                LeaveTaken: leaveTaken != '' ? leaveTaken : null,
                CreditLeaveTaken: creditLeaveTaken != '' ? creditLeaveTaken : null,
                addedRolePlayedList: addedRolePlayedList,
                TotalAnualHours :totalAnualHours,
                UserEngRole :UserEngRole,
                UserName :UserName,
                Password :Password,
            }
            console.log('addEmployeeList_action', mapdata)
            const res = await axiosPost(addEmployeeListUrl, mapdata, headers);
            
            dispatch({
                type: EMPLOYEE_LIST_ADD_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const updateEmployeeList_action = (id, prefixId, firstName, lastName, code, designationId, departmentId, managerId,
    partnerId, dateofBirth, email, genderId, bloodGroupId, maritalStatusId, anniversaryDate, joiningDate,
    resignDate, ratePerHour, contactNo, accountNo, membershipID, isTimesheetMandatory, leaveBal,
     creditLeaveBal, isApprover, leaveTaken, creditLeaveTaken, addedRolePlayedList, totalAnualHours,UserEngRole) => async (dispatch) => {
        console.log(new Date(moment(dateofBirth).format('MM-DD-YYYY')+'Z').toISOString())
        console.log(moment(dateofBirth).format('MM-DD-YYYY'))
        console.log(dateofBirth)
        console.log("updateEmployeeList_action test")
        try {
        const mapdata = {
            ID: id,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            FirstName: firstName,
            LastName: lastName,
            PrefixId: prefixId,
            EmpCode: code,
            DesignationID: designationId,
            DepartmentID: departmentId,
            ReportManagerID: managerId,
            PartnerID: partnerId,
            DateofBirth: dateofBirth != null && dateofBirth != '' && moment(dateofBirth).format('MM-DD-YYYY'),
            EmailID: email,
            GenderID: genderId,
            BloodGroupID: bloodGroupId,
            MaritalStatus: maritalStatusId,
            AniversaryDate: anniversaryDate != null && anniversaryDate != '' ? moment(anniversaryDate).format('MM-DD-YYYY') :null,
            FromDate: joiningDate != null && joiningDate != '' ? moment(joiningDate).format('MM-DD-YYYY') : null,
            ToDate: resignDate != null && resignDate != '' ? moment(resignDate).format('MM-DD-YYYY') : null,
            RatePerHour: ratePerHour,
            ContactNo: contactNo,
            AccountNo: accountNo,
            MembershipID: membershipID,
            MandatoryTimeSheet: isTimesheetMandatory,
            LeaveBal: leaveBal,
            CreditLeaveBal: creditLeaveBal,
            IsApprover: isApprover != '' ? isApprover : null,
            LeaveTaken: leaveTaken,
            CreditLeaveTaken: creditLeaveTaken,
            addedRolePlayedList: addedRolePlayedList,
            TotalAnualHours :totalAnualHours,
            UserEngRole :UserEngRole,
        }
        console.log("updateEmployeeList_action", mapdata)
        const res = await axiosPost(updateEmployeeUrl, mapdata, headers);

        dispatch({
            type: EMPLOYEE_LIST_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const deleteEmployeeList_action = (id) => async (dispatch) => {
    try {
        const param = {
            ID: id,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,

        }
        const res = await axiosGet(deleteEmployeeUrl, param);
        dispatch({
            type: EMPLOYEE_LIST_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const employeeRolePlayedDelete_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }
        console.log("employeeRolePlayedDelete_action ID", param)
        const res = await axiosGet(deleteEmployeeEngRoleUrl, param);
        dispatch({
            type: ROLEPLAYED_EMPLOYEE_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch (err) {
        return Promise.reject(err);
    }
};


// Other Employee Contact Details

export const employeeListContact_action = (employeeId,id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            EmployeeID: employeeId,
            ID: id
        }
        const res = await axiosGet(employeeContactListUrl, param);
        dispatch({
            type: EMPLOYEE_CONTACT_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const addEmployeeContact_action = (employeeId,contactRelationID,contactPerson,Tel1, Tel2, contEmail, contDateofBirth, contBloodGroupID) => async (dispatch) => {
    try {
        const mapData = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            EmployeeID: employeeId,
            ContactRelationID: contactRelationID ,
            ContactPerson: contactPerson ,
            Tel1: Tel1 ,
            Tel2: Tel2  ,
            Email: contEmail ,
            DateOfBirth: contDateofBirth != null && contDateofBirth != '' && moment(contDateofBirth).format('MM-DD-YYYY') ? contDateofBirth : null,
            BloodGroupID: contBloodGroupID 
        }
        console.log('addEmployeeContactUrl', mapData)
        const res = await axiosPost(addEmployeeContactUrl, mapData, headers);
        dispatch({
            type: EMPLOYEE_CONTACT_ADD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const employeeContactDetail_action = (id, employeeId) => async (dispatch) => {
    try {
        const param = {
            ID: id,
            EmployeeID: employeeId,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }

        const res = await axiosGet(employeeContactDetailsUrl, param);
        dispatch({
            type: EMPLOYEE_CONTACT_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateEmployeeContact_action = (contactId,employeeId,contactRelationID,contactPerson,Tel1, Tel2, contEmail, contDateofBirth, contBloodGroupID) => async (dispatch) => {
    try {
        const mapData = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID:contactId,
            EmployeeID: employeeId,
            ContactRelationID: contactRelationID ,
            ContactPerson: contactPerson ,
            Tel1: Tel1 ,
            Tel2: Tel2 ,
            Email: contEmail ,
            DateOfBirth: contDateofBirth != null && contDateofBirth != '' && moment(contDateofBirth).format('MM-DD-YYYY') ? contDateofBirth: null,
            BloodGroupID: contBloodGroupID 
        }
        console.log("updateEmployeeContact",mapData)
        const res = await axiosPost(updateEmployeeContactUrl, mapData, headers);
        dispatch({
            type: EMPLOYEE_CONTACT_UPDATE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const deleteEmployeeContact_action = (employeeId, id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            EmployeeID:employeeId,
            ID: id,
        }
        const res = await axiosGet(deleteEmployeeContactUrl, param);
        dispatch({
            type: EMPLOYEE_CONTACT_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch (err) {
        return Promise.reject(err);
    }
}