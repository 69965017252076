
import {
    UPDATE_CHECKLIST_ACTION_TYPE,
    UPDATE_CHECKLIST_DETAILS_ACTION_TYPE,
    UPDATE_CHECKLIST_UPDATE_ACTION_TYPE,
    UPDATE_CHECKLIST_CLOSE_ACTION_TYPE,
 } from "../../utils/constants"


const initialState = [];

function updateChecklistReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_CHECKLIST_ACTION_TYPE:
      return [...state, payload];
      case UPDATE_CHECKLIST_DETAILS_ACTION_TYPE:
      return [...state, payload];
    case UPDATE_CHECKLIST_UPDATE_ACTION_TYPE:
      return [...state, payload];
    case UPDATE_CHECKLIST_CLOSE_ACTION_TYPE:
      return [...state, payload];
    
    default:
      return state;
  }
};

export default updateChecklistReducer;


// export default (state = {}, action) => {
//     switch (action.type) {
//      case 'SIMPLE_ACTION':
//       return {
//        result: action.payload
//       }
//      default:
//       return state
//     }
//    }