import React, { Component } from 'react';
import { connect, forwardRef } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Loader from '../../../utils/Loader'
import { engagementRoleAdd_action, engagementRoleUpdate_action, engagementRoleDetails_action } from '../../../actions/master_action/engagementRole_action'
import { designationList_action, designationAdd_action, designationDetails_action, designationUpdate_action, designationDelete_action, rolePlayedDelete_action } from '../../../actions/master_action/designation_action'
import { fill_action } from '../../../actions/master_action/checklist_action'

import { moduleAccessListActionId, Master, rolePlayedId, parentId } from '../../../utils/constants'
import { getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

const mapStateToProps = state => ({
    ...state
})


class DesignationAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            isLoading: false,
            is_add: false,
            is_delete: false,
            is_update: false,
            IsActive: false,
            parentId: '',
            parentList: [],
            engagementRoleId: '',
            engagementRoleName: '',
            maxAllowed: '',
            displayOrder: '',
            is_Disable: false,
            moduleList: [],
            reportList: [],
            rolePlayedList: [],
            designation: '',
            addedRolePlayedList: [],
            DesignationId: ''
        }
        this.handleAdd = this.handleAdd.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.clearValue = this.clearValue.bind(this)
    }

    componentDidMount = () => {
        this.getFillList(rolePlayedId, '')
        this.getFillList(moduleAccessListActionId, '')
        this.getFillList(parentId, '')

        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_Disable: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            DesignationId: this.props.location.state != null && this.props.location.state.DesignationId != null ? this.props.location.state.DesignationId : '',
            isAccessToView: true,
        })

    }
    // Designation Details
    getDesignationDetails(designationId) {
        const { rolePlayedList } = this.state
        console.log(' rolePlayedList ++++ ', rolePlayedList)
        var id = designationId
        this.setState({ isLoading: true })
        this.props
            .designationDetails_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log('addedRolePlayedList 55', data.data['roleList'])

                    var list = []
                    data.data['roleList'].length > 0 && data.data['roleList'].map(data => {
                        rolePlayedList.length > 0 && rolePlayedList.map(item => {
                            if (item.egrnID == data.ID) {
                                item.id = data.ID
                                item.actionId = 1
                                list.push(item)
                            }
                        })
                    })
                    setTimeout(() => {
                        this.setState({
                            designation: data.data['detailsList'][0]['Designation'],
                            parentId: data.data['detailsList'][0]['ParentDesID'],
                            addedRolePlayedList: list,
                            isActive: data.data['detailsList'][0]['IsActive'],
                            moduleList: data.data.moduleList,
                            reportList: data.data.reportList,
                            isLoading: false
                        })
                    }, 100);
                    console.log('getDesignationDetails', data.data)
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }

    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    getFillList(value, ref, ref2) {

        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != '' ? ref : '',
                ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == rolePlayedId) {
                        this.setState({ rolePlayedList: data.data, isLoading: false })
                        setTimeout(() => {
                            this.props.location.state != null && this.props.location.state.is_update && this.getDesignationDetails(this.props.location.state.DesignationId)
                        }, 200);
                    }
                    if (value == moduleAccessListActionId) {
                        data.moduleList.map((item) => {
                            item.ReadAccess = false
                            item.WriteAccess = false
                            item.UpdateAccess = false
                            item.DeleteAccess = false
                            item.FullAccess = false
                            item.Is_SpecialAccess = false
                        })
                        data.reportList.map((item) => {
                            item.FullAccess = false
                            item.ViewAccess = false
                            item.DownloadAccess = false
                        })
                        this.setState({
                            moduleList: data.moduleList,
                            reportList: data.reportList,
                            isLoading: false,
                        }, () => {
                            this.oncheckedModule()
                            this.oncheckedReport()
                        })
                    }
                    if (value == parentId) {
                        this.setState({ parentList: data.data, isLoading: false })
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    handleCancel() {
        this.props.navigate('/designation')
    }

    clearValue() {
        this.setState({
            engagementRoleName: '',
            parentId: '',
            maxAllowed: '',
            displayOrder: '',
        })
    }

    // Role Played
    onSelectRolePlayed(e) {
        const { designation, rolePlayedList, addedRolePlayedList } = this.state
        if (designation != '' && designation != null) {
            this.setState({ egrnID: e.target.value, rolePlayedId: e.target.value != "" ? e.target.value : "" })
            var isDataAvailable = false
            rolePlayedList.map((item) => {

                if (item.egrnID == e.target.value) {
                    console.log("addedRolePlayedList item", item)

                    addedRolePlayedList.map(data => {

                        if (data.egrnID == e.target.value) {
                            isDataAvailable = true
                        }
                        // else {
                        //     item.actionId = 1
                        // }
                    })

                    if (isDataAvailable) {
                        toast.error('Already added role', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                    else {
                        item.actionId = 1
                        addedRolePlayedList.push(item)
                        this.setState({ addedRolePlayedList: addedRolePlayedList })
                    }
                    console.log(" addedRolePlayedList", addedRolePlayedList)

                }
            })

        } else {
            toast.error('Please select mendatory fields', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true
            })
        }
    }

    // Role Played Delete
    deleteRoleplayed(e, roleId, egrnID) {
        e.preventDefault()
        const { addedRolePlayedList, } = this.state
        var id = roleId
        this.setState({ isLoading: true })
        console.log("323232", addedRolePlayedList)
        const filterList = addedRolePlayedList.filter(item => item.egrnID != egrnID);
        console.log("filterList", filterList)
        this.setState({ addedRolePlayedList: filterList })
        this.props
            .rolePlayedDelete_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    console.log()
                    this.setState({ isLoading: false })

                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    handleAdd(e) {
        e.preventDefault()
        const { designation, parentId, addedRolePlayedList, moduleList, reportList } = this.state
        if (designation != "") {
            this.setState({ isLoading: true })

            this.props
                .designationAdd_action(designation.trim(), parentId, addedRolePlayedList, moduleList, reportList)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false })
                        this.handleCancel()
                        this.clearValue()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }



    }
    handleUpdate(e) {
        e.preventDefault()
        const { designation, parentId, addedRolePlayedList, moduleList, reportList } = this.state
        const id = this.state.DesignationId;
        console.log("223344", addedRolePlayedList)


        if (id != "" && designation != "") {
            var filteredList = []
            filteredList = addedRolePlayedList.filter((data) => { return data.actionId == 1 })
            console.log("filteredList", filteredList)

            this.setState({ isLoading: true })
            this.props
                .designationUpdate_action(designation.trim(), parentId, id, filteredList, moduleList, reportList)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        console.log('designationUpdate_action4455662288', data.data)
                        this.setState({ isLoading: false })
                        this.handleCancel()

                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }

    onModuleChange(e, index, value) {
        const { moduleList, reportList } = this.state
        const event = e.target.checked
        moduleList.map((data, i) => {
            if (i === index) {
                if (event) {
                    if (value == 'FullAccess') {
                        data.FullAccess = true
                        data.ReadAccess = true
                        data.WriteAccess = true
                        data.UpdateAccess = true
                        data.DeleteAccess = true
                        data.Is_SpecialAccess = true
                    } else if (value == 'ReadAccess') {
                        data.ReadAccess = true
                    } else if (value == 'WriteAccess') {
                        data.WriteAccess = true
                        data.ReadAccess = true
                    } else if (value == 'UpdateAccess') {
                        data.UpdateAccess = true
                        data.ReadAccess = true
                    } else if (value == 'DeleteAccess') {
                        data.DeleteAccess = true
                        data.ReadAccess = true
                    } else {
                        data.Is_SpecialAccess = true
                    }
                } else {
                    if (value == 'FullAccess') {
                        data.FullAccess = false
                        data.ReadAccess = false
                        data.WriteAccess = false
                        data.UpdateAccess = false
                        data.DeleteAccess = false
                        data.Is_SpecialAccess = false
                    } else if (value == 'ReadAccess') {
                        data.ReadAccess = false
                    } else if (value == 'WriteAccess') {
                        data.WriteAccess = false
                    } else if (value == 'UpdateAccess') {
                        data.UpdateAccess = false
                    } else if (value == 'DeleteAccess') {
                        data.DeleteAccess = false
                    } else {
                        data.Is_SpecialAccess = false
                    }
                }

                if (data.ReadAccess || data.WriteAccess || data.UpdateAccess || data.DeleteAccess) {
                    data.ReadAccess = true
                }

                if (data.ReadAccess && data.WriteAccess && data.UpdateAccess && data.DeleteAccess && data.Is_SpecialAccess) {
                    data.FullAccess = true
                } else {
                    data.FullAccess = false
                }
            }
        })
        this.setState({ moduleList: moduleList }, () => {
            this.oncheckedModule()
        })
    }

    oncheckedModule() {
        const { moduleList, reportList } = this.state
        moduleList.map((data, i) => {
            if (data.ModuleName == 'Timesheet' || data.ModuleName == 'Expense' || data.ModuleName == 'Leave' || data.ModuleName == 'Timesheet & Expense Approval' || data.ModuleName == 'Leave Approval') {
                if (data.ReadAccess || data.WriteAccess || data.UpdateAccess || data.DeleteAccess || data.Is_SpecialAccess) {
                    for (let i = 0; i < moduleList.length; i++) {
                        if (moduleList[i]['ModuleName'] == 'Timesheet & Expense') {
                            moduleList[i]['FullAccess'] = true
                            moduleList[i]['ReadAccess'] = true
                            moduleList[i]['WriteAccess'] = true
                            moduleList[i]['UpdateAccess'] = true
                            moduleList[i]['DeleteAccess'] = true
                            moduleList[i]['Is_SpecialAccess'] = true
                        }
                    }
                }
            }
            if (data.ModuleName == 'Engagement Allocation' || data.ModuleName == 'Client Engagement' || data.ModuleName == 'Update Checklist' || data.ModuleName == 'Review Checklist' || data.ModuleName == 'Close Engagement') {
                if (data.ReadAccess || data.WriteAccess || data.UpdateAccess || data.DeleteAccess || data.Is_SpecialAccess) {
                    for (let i = 0; i < moduleList.length; i++) {
                        if (moduleList[i]['ModuleName'] == 'Engagement') {
                            moduleList[i]['FullAccess'] = true
                            moduleList[i]['ReadAccess'] = true
                            moduleList[i]['WriteAccess'] = true
                            moduleList[i]['UpdateAccess'] = true
                            moduleList[i]['DeleteAccess'] = true
                            moduleList[i]['Is_SpecialAccess'] = true
                        }
                    }
                }
            }
            if (data.ModuleName == 'Traning Entry' || data.ModuleName == 'Training Approval' || data.ModuleName == 'Training report') {
                if (data.ReadAccess || data.WriteAccess || data.UpdateAccess || data.DeleteAccess || data.Is_SpecialAccess) {
                    for (let i = 0; i < moduleList.length; i++) {
                        if (moduleList[i]['ModuleName'] == 'Training') {
                            moduleList[i]['FullAccess'] = true
                            moduleList[i]['ReadAccess'] = true
                            moduleList[i]['WriteAccess'] = true
                            moduleList[i]['UpdateAccess'] = true
                            moduleList[i]['DeleteAccess'] = true
                            moduleList[i]['Is_SpecialAccess'] = true
                        }
                    }
                }
            }
            if (data.ModuleName == 'MCQ Chapter' || data.ModuleName == 'MCQ Setup' || data.ModuleName == 'MCQ Setting' || data.ModuleName == 'MCQ Take Test') {
                if (data.ReadAccess || data.WriteAccess || data.UpdateAccess || data.DeleteAccess || data.Is_SpecialAccess) {
                    for (let i = 0; i < moduleList.length; i++) {
                        if (moduleList[i]['ModuleName'] == 'MCQ') {
                            moduleList[i]['FullAccess'] = true
                            moduleList[i]['ReadAccess'] = true
                            moduleList[i]['WriteAccess'] = true
                            moduleList[i]['UpdateAccess'] = true
                            moduleList[i]['DeleteAccess'] = true
                            moduleList[i]['Is_SpecialAccess'] = true
                        }
                    }
                }
            }
            const reportModuleChecked = reportList.some(item => item.FullAccess || item.ViewAccess || item.DownloadAccess)
            console.log("reportChecked--", reportModuleChecked)
            if (reportModuleChecked) {
                for (let i = 0; i < moduleList.length; i++) {
                    if (moduleList[i]['ModuleName'] == 'Reports') {
                        moduleList[i]['FullAccess'] = true
                        moduleList[i]['ReadAccess'] = true
                        moduleList[i]['WriteAccess'] = true
                        moduleList[i]['UpdateAccess'] = true
                        moduleList[i]['DeleteAccess'] = true
                        moduleList[i]['Is_SpecialAccess'] = true
                    }
                }
            }
            for (let j = 0; j < reportList.length; j++) {
                if (reportList[j]['ReportName'] == 'Firm wise training report' || reportList[j]['ReportName'] == 'Employee training report') {
                    if (reportList[j]['FullAccess'] || reportList[j]['ViewAccess'] || reportList[j]['DownloadAccess']) {
                        for (let i = 0; i < moduleList.length; i++) {
                            if (moduleList[i]['ModuleName'] == 'Training report') {
                                moduleList[i]['FullAccess'] = true
                                moduleList[i]['ReadAccess'] = true
                                moduleList[i]['WriteAccess'] = true
                                moduleList[i]['UpdateAccess'] = true
                                moduleList[i]['DeleteAccess'] = true
                                moduleList[i]['Is_SpecialAccess'] = true
                            }
                        }
                    }
                }
            }
        })
        this.setState({ moduleList: moduleList })
    }

    onReportChange(e, index, value) {
        const { reportList, moduleList } = this.state
        const event = e.target.checked
        reportList.map((data, i) => {
            if (i === index) {
                if (event) {
                    if (value == 'FullAccess') {
                        data.FullAccess = true
                        data.ViewAccess = true
                        data.DownloadAccess = true
                    } else if (value == 'ViewAccess') {
                        data.ViewAccess = true
                    } else {
                        data.DownloadAccess = true
                        data.FullAccess = true
                        data.ViewAccess = true
                    }
                } else {
                    if (value == 'FullAccess') {
                        data.FullAccess = false
                        data.ViewAccess = false
                        data.DownloadAccess = false
                    } else if (value == 'ViewAccess') {
                        data.ViewAccess = false
                    } else {
                        data.DownloadAccess = false
                    }
                }

                if (data.DownloadAccess) {
                    data.ViewAccess = true
                }
                if (data.ViewAccess && data.DownloadAccess) {
                    data.FullAccess = true
                } else {
                    data.FullAccess = false
                }
            }
        })
        this.setState({ reportList: reportList }, () => {
            this.oncheckedReport()
        })
    }

    oncheckedReport() {
        const { reportList, moduleList } = this.state
        reportList.map((data, i) => {
            if (data.ReportName == 'Firm wise training report' || data.ReportName == 'Employee wise training report') {
                if (data.FullAccess || data.ViewAccess || data.DownloadAccess) {
                    for (let i = 0; i < moduleList.length; i++) {
                        if (moduleList[i]['ModuleName'] == 'Training report') {
                            moduleList[i]['FullAccess'] = true
                            moduleList[i]['ReadAccess'] = true
                            moduleList[i]['WriteAccess'] = true
                            moduleList[i]['UpdateAccess'] = true
                            moduleList[i]['DeleteAccess'] = true
                            moduleList[i]['Is_SpecialAccess'] = true
                        }
                    }
                }
            }

            const reportModuleChecked = reportList.some(item => item.FullAccess || item.ViewAccess || item.DownloadAccess)
            console.log("reportChecked--", reportModuleChecked)
            if (reportModuleChecked) {
                for (let i = 0; i < moduleList.length; i++) {
                    if (moduleList[i]['ModuleName'] == 'Reports') {
                        moduleList[i]['FullAccess'] = true
                        moduleList[i]['ReadAccess'] = true
                        moduleList[i]['WriteAccess'] = true
                        moduleList[i]['UpdateAccess'] = true
                        moduleList[i]['DeleteAccess'] = true
                        moduleList[i]['Is_SpecialAccess'] = true
                    }
                }
            }


        })
        this.setState({ reportList: reportList, moduleList: moduleList })
    }


    render() {
        const { isLoading, IsActive, is_update,
            is_add, engagementRoleName, parentId,
            parentList, isAccessToView, maxAllowed,
            displayOrder, is_Disable, moduleList, reportList, rolePlayedList, designation, addedRolePlayedList } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li>
                                                <a onClick={this.handleCancel} >Designation</a>
                                            </li>
                                            <li>
                                                <a >{this.state.is_add ? 'New' : 'Update'}</a>
                                            </li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className="user-status">
                                        {
                                            is_update && engagementRoleName != '' ? <p>
                                                <strong className={IsActive == true ? 'greena' : 'redi'}> {IsActive == true ? 'Active' : 'Inactive'}</strong>
                                            </p> : <></>
                                        }

                                    </div>
                                    <div className='page-space-from-bottom'>
                                        <div className="row">
                                            <style
                                                type="text/css"
                                                dangerouslySetInnerHTML={{
                                                    __html: "\n.accordion-button::after {\n background-size: 13px;\n display: none; \n} \n"
                                                }}
                                            />
                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row">

                                                            <div class="form-group col-3">
                                                                <label>Designation<span class="required1">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={designation}
                                                                    onChange={(e) => this.setState({ designation: e.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group col-3 ">
                                                                <label>
                                                                    Reporting
                                                                </label>
                                                                <select
                                                                    className="js-example-basic-single"
                                                                    style={{ width: "100%" }}
                                                                    value={parentId}
                                                                    onChange={e => this.setState({ parentId: e.target.value != "Select" ? e.target.value : "" })}
                                                                >
                                                                    <option>Select</option>
                                                                    {parentList.length > 0 ? parentList.map(item => (
                                                                        <option
                                                                            key={item.DesignationID}
                                                                            value={item.DesignationID}>
                                                                            {item.Reporting}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='row'>

                                                            <div className="form-group col-3 mt-2">
                                                                <label>
                                                                    Role Played
                                                                </label>
                                                                <select
                                                                    className="js-example-basic-single"
                                                                    style={{ width: "100%" }}

                                                                    value={rolePlayedId}
                                                                    onChange={(e) => this.onSelectRolePlayed(e)}
                                                                // onChange={e => this.setState({ rolePlayedId: e.target.value != "Select" ? e.target.value : "" })}
                                                                >
                                                                    <option>Select</option>
                                                                    {rolePlayedList.length > 0 ? rolePlayedList.map(item => (
                                                                        <option
                                                                            key={item.egrnID}
                                                                            value={item.egrnID}>
                                                                            {item.egrsRole}
                                                                        </option>
                                                                    )) : <></>}
                                                                </select>

                                                            </div>
                                                            <div className="table-responsive tbs4 common-table trail-tb mt-2 mb-2" >
                                                                {/* {this.recordEngRole(employeeEngRoleList)} */}
                                                                <table className="table table-bordered tb-action du-table w-50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th style={{ textAlign: 'left' }} >Engagement Role</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {addedRolePlayedList.length > 0 ? (
                                                                            addedRolePlayedList.map((data, i) => (
                                                                                <tr key={data.egrnID}>
                                                                                    <td className="text-center" style={{ padding: '5px!important' }}>
                                                                                        <div>
                                                                                            <a><span className="text-danger"><i className="fa fa-trash-o" onClick={(e) => this.deleteRoleplayed(e, data.id, data.egrnID)} /></span></a>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style={{ textAlign: 'left' }}>{data.egrsRole}</td>
                                                                                </tr>
                                                                            ))

                                                                        ) : (<tr><td colSpan={5} className='text-center'>No records to display.</td></tr>)}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="table-responsive tbs4 common-table trail-tb" >
                                                                <table className="table table-bordered tb-action du-table -50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Module Name</th>
                                                                            <th>Full Accss</th>
                                                                            <th>Read</th>
                                                                            <th>Write</th>
                                                                            <th>Update</th>
                                                                            <th>Delete</th>
                                                                            <th>Special Permissions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {moduleList.length > 0 ? (
                                                                            moduleList.map((data, i) => (
                                                                                <>
                                                                                    <tr >
                                                                                        <td>{data.ModuleName}</td>
                                                                                        <td>
                                                                                            <input type='checkbox'
                                                                                                checked={data.FullAccess}
                                                                                                onChange={(e) => this.onModuleChange(e, i, 'FullAccess')}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <input type='checkbox'
                                                                                                checked={data.ReadAccess}
                                                                                                onChange={(e) => this.onModuleChange(e, i, 'ReadAccess')}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <input type='checkbox'
                                                                                                checked={data.WriteAccess}
                                                                                                onChange={(e) => this.onModuleChange(e, i, 'WriteAccess')}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <input type='checkbox'
                                                                                                checked={data.UpdateAccess}
                                                                                                onChange={(e) => this.onModuleChange(e, i, 'UpdateAccess')}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <input type='checkbox'
                                                                                                checked={data.DeleteAccess}
                                                                                                onChange={(e) => this.onModuleChange(e, i, 'DeleteAccess')}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <input type='checkbox'
                                                                                                checked={data.Is_SpecialAccess}
                                                                                                onChange={(e) => this.onModuleChange(e, i, 'Is_SpecialAccess')}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            ))

                                                                        ) : (<tr><td colSpan={8} className='text-center'>Record not found</td></tr>)}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="table-responsive tbs4 common-table trail-tb" >
                                                                <table className="table table-bordered tb-action du-table -50">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Report Name</th>
                                                                            <th>Full Access</th>
                                                                            <th>View</th>
                                                                            <th>Download</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {reportList.length > 0 ? (
                                                                            reportList.map((data, i) => (
                                                                                <tr >
                                                                                    <td>{data.ReportName}</td>
                                                                                    <td>
                                                                                        <input type='checkbox'
                                                                                            checked={data.FullAccess}
                                                                                            onChange={(e) => this.onReportChange(e, i, 'FullAccess')}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type='checkbox'
                                                                                            checked={data.ViewAccess}
                                                                                            onChange={(e) => this.onReportChange(e, i, 'ViewAccess')}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <input type='checkbox'
                                                                                            checked={data.DownloadAccess}
                                                                                            onChange={(e) => this.onReportChange(e, i, 'DownloadAccess')}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))

                                                                        ) : (<tr><td colSpan={8} className='text-center'>Record not found</td></tr>)}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="footer-btn">
                                                                <div className="col-md-3">
                                                                    <div
                                                                        className="btn-group"
                                                                        role="group"
                                                                        aria-label="Basic example"
                                                                    >
                                                                        {
                                                                            this.state.is_add && getModuleWriteAccess(Master) && <button
                                                                                type="button"
                                                                                className="btn btn-save footer-btn-inner"
                                                                                onClick={this.handleAdd}
                                                                            >
                                                                                Save
                                                                            </button>
                                                                        }

                                                                        {
                                                                            getModuleUpdateAccess(Master) && this.state.is_update ?
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-update footer-btn-inner mx-1"
                                                                                    onClick={this.handleUpdate}
                                                                                >
                                                                                    Update
                                                                                </button> : <></>
                                                                        }
                                                                        {
                                                                            getModuleWriteAccess(Master) && this.state.is_add ?
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-cancel footer-btn-inner mx-1"
                                                                                    onClick={this.clearValue}
                                                                                >
                                                                                    Reset
                                                                                </button> : <></>
                                                                        }

                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-cancel footer-btn-inner"
                                                                            onClick={this.handleCancel}
                                                                        >
                                                                            Cancel
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end
   */}
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>
                <ToastContainer />
            </>
        );
    }
}


export default withRouter(connect(null, { fill_action, mapStateToProps, engagementRoleAdd_action, engagementRoleUpdate_action, engagementRoleDetails_action, designationList_action, designationAdd_action, designationDetails_action, designationUpdate_action, designationDelete_action, rolePlayedDelete_action })(DesignationAU))