import React, { Component, forwardRef } from "react";
import Loader from "../../../utils/Loader";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../../../utils/pagination'
import { martialStatusList_action, deleteMartialStatus_action, martialStatusDetail_action, addMartialStatus_action, updateMartialStatus_action } from '../../../actions/master_action/martial_status_action'
import { clearLocalStorage, Master, } from '../../../utils/constants'
import Modal from "react-modal";
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from "../../../utils/RoleAccess";

class MartialStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAccessToView: true,

            martialStatusList: [],
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage : 10,
            pageSizeInput: "10", 
            martialStatusname: '',
            martialStatusnameSearch: '',
            status: '',
            statusMSLS: '',
            is_add: false,
            isOpenAU: true,
            is_update: false,
            martialStatusID: '',
            isOpen: false,
            martialStatusDeleteId: '',
            isSearchTab: false,

        }

        this.getMartialStatusList = this.getMartialStatusList.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);  
		
        this.record = this.record.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.getMartialStatusDetails = this.getMartialStatusDetails.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.toggleAdd = this.toggleAdd.bind(this)
        this.toggleUpdate = this.toggleUpdate.bind(this)
        this.toggleModalAU = this.toggleModalAU.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleToggle = this.handleToggle.bind(this)


    }

    componentDidMount = () => {
        this.setState({
            isAccessToView: true,
            statusMSLS: localStorage.getItem('statusMSLS'),
            status: localStorage.getItem('statusMSLS') != '' ? localStorage.getItem('statusMSLS') : '',

        })
        clearLocalStorage("martialStatus")
        this.getMartialStatusList();
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }



    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    //get list
    getMartialStatusList() {
        const { status, martialStatusnameSearch } = this.state
        this.setState({ isLoading: true })
        this.props
        .martialStatusList_action(status === '' ? 1 : parseInt(status), martialStatusnameSearch.trim())
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        martialStatusList: data.data,
                        isLoading: false,
                        currentPage: 1
                    })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    //get details
    getMartialStatusDetails() {
        setTimeout(() => {
            var id = this.state.martialStatusID
            this.props
                .martialStatusDetail_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            martialStatusname: data.data[0]['MaritalStatus'],
                            status: data.data[0]['IsActive']
                        })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 200);
    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }
    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
          this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
      };
      
      handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
          this.setState({ pageSizeInput: input });
        }
      };
    //search
    handleSearch() {
        const { martialStatusnameSearch, status } = this.state
        clearLocalStorage("martialstatus")
        this.setState({ isLoading: true })
        this.props
            .martialStatusList_action(status === '' ? 1 : parseInt(status), martialStatusnameSearch.trim())
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ martialStatusList: data.data, isLoading: false, currentPage: 1 })
                    console.log(data)
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
        localStorage.setItem("statusMSLS", status === '' ? 1 : status)
    }

    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.handleSearch()
        }
    }

    //clear value
    clearValue() {
        this.setState({
            currentPage: 1,
            martialStatusname: '',
            status: '',
            statusMSLS: '',
            martialStatusnameSearch: '',
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getMartialStatusList()
        }, 300);

    }

    //reset values
    handleReset() {
        this.setState({
            martialStatusname: '',
        })
    }

    toggleAdd() {
        const { is_add } = this.state
        this.setState({ is_add: true, martialStatus: "", martialStatusname: '' })
        this.handleReset()
        this.toggleModalAU()
    }

    toggleUpdate(martialStatus) {
        const { is_update } = this.state
        this.handleReset()
        this.setState({ is_update: true, martialStatusID: martialStatus })
        this.toggleModalAU()
    }

    //toggle/reset
    toggleModalAU(martialStatus) {
        const { isOpenAU } = this.state
        this.getMartialStatusDetails()
        this.setState({ isOpenAU: !isOpenAU, martialStatusname: "" })
    }

    handleToggle(id, type) {
        const { isOpenAU, martialStatus } = this.state
        if (type == 'add') {
            this.setState({ is_add: true, is_update: false, isOpenAU: !isOpenAU, martialStatus: "", martialStatusname: '' })
            this.handleReset()
        } else {
            this.handleReset()
            this.setState({ is_update: true, is_add: false, isOpenAU: !isOpenAU, martialStatusID: id })
            this.getMartialStatusDetails()
        }
    }

    //add martial status
    handleAdd(e) {
        e.preventDefault()
        const { martialStatusname } = this.state
        if (martialStatusname != "") {
            this.setState({ isLoading: true })
            console.log(martialStatusname)
            this.props
                .addMartialStatus_action(martialStatusname.trim())
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false })
                        this.toggleModalAU()
                        this.clearValue()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //update martial status
    handleUpdate(e) {
        e.preventDefault()
        const { martialStatusID, martialStatusname } = this.state
        const id = martialStatusID

        if (id != "" && martialStatusname != "") {
            this.setState({ isLoading: true })
            this.props
                .updateMartialStatus_action(id, martialStatusname.trim())
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        this.toggleModalAU()
                        this.clearValue()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //toggal for delete
    toggleModal(martialStatusID) {
        console.log(martialStatusID)
        const { isOpen, martialStatusDeleteId } = this.state
        this.setState({ isOpen: !isOpen })
        if (martialStatusID == '') {
            return
        }
        else {
            this.setState({ martialStatusDeleteId: martialStatusID })
        }
    }

    //delete handle
    handleDelete(e, martialStatusID) {
        e.preventDefault()
        var id = martialStatusID
        this.setState({ isLoading: true })
        this.props
            .deleteMartialStatus_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isOpen: !this.state.isOpen, isLoading: false })
                    this.getMartialStatusList()
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    //record
    record(martialStatusList) {

        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ textAlign: 'left' }}>Martial Status</th>
                        <th style={{ width: '180px', textAlign: 'center' }}>Active</th>
                    </tr>
                </thead>
                <tbody>
                    {martialStatusList.length > 0 ? (
                        martialStatusList.map((data, i) => (

                            <tr key={data.ID}>

                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        {getModuleUpdateAccess(Master) ?
                                            <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={(e) => this.handleToggle(data.ID, 'update')} /></a> :
                                            <a><i className="fa fa-eye" onClick={(e) => this.handleToggle(data.ID, 'update')} /></a>
                                        }
                                        {data.IsActive === true && getModuleDeleteAccess(Master)? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.ID)} /></span></a>
                                            : <></>}

                                    </div>
                                </td>
                                <td style={{ textAlign: 'left' }}>{data.MaritalStatus}</td>
                                <td>{data.IsActive == true ? "Active" : "Inactive"}</td>
                            </tr>
                        ))
                    ) : (<tr><td colSpan={5} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }


    render() {
        const { isSearchTab, isLoading, isAccessToView, martialStatusList,
             martialStatusname, martialStatusnameSearch, is_add,
              isOpenAU, status,  is_update, isOpen, martialStatusDeleteId } = this.state
        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // // // Records to be displayed on the current page
        const currentRecords = martialStatusList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(martialStatusList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />


                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>Martial Status </a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className='page-space-from-bottom'>
                                        <div className="row">
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body pr-2">


                                                        <div className="accordion accordion-flush " id="accordionFlushExample">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingOne">
                                                                    <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                                                        <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                    </button>
                                                                </h2>
                                                                {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                            <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                    <div className="d-flex flex-wrap row">
                                                                        <div className="form-group col-md-3">
                                                                            <label> Martial Status<span class="required1">*</span></label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder=' Martial Status'
                                                                                value={martialStatusnameSearch}
                                                                                onChange={(e) => this.setState({ martialStatusnameSearch: e.target.value })}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Status</label>
                                                                            <select className="js-example-basic-single" style={{ width: '100%' }}
                                                                                value={status}
                                                                                onChange={(e) => this.setState({ status: e.target.value })}>
                                                                                <option value={''}>Select</option>
                                                                                <option value={1}>Active</option>
                                                                                <option value={0}>Inactive</option>
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group col-md-3 filter-btn-col p-0">
                                                                            <label></label>
                                                                            <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={e => this.handleSearch()}> Search</button>
                                                                            <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3" onClick={e => this.clearValue()}> Clear</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div> : <></>}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 mt-1">
                                                                {getModuleWriteAccess(Master) && <button type="button" className="btn btn-Add rounded-1 mb-1 px-3 float-right" onClick={(e) => this.handleToggle('', 'add')}>Add</button> }
                                                                {/* button */}
                                                                <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                                    {this.record(currentRecords)}
                                                                    {martialStatusList.length > 0 ? <Pagination
                                                                        nPages={nPages}
                                                                        currentPage={currentPage}
                                                                        setCurrentPage={this.updateItem}
                                                                        recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                         setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                           totalRecords={martialStatusList.length}
                                                                         /> : <></>}                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>
                <ToastContainer />
                {/* // modal for delete */}
                {/* <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, martialStatusDeleteId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}

                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right">
                                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, martialStatusDeleteId)}> Yes</a>
                                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* modal for AU */}
                <Modal isOpen={!isOpenAU} onRequestClose={this.toggleModalAU}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                {is_add == true ? <h4 class="modal-title modal_title_text">Add Martial Status</h4> : <h4 class="modal-title modal_title_text">Update Martial Status</h4>}
                                {is_add == true ? <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleAdd} ><span aria-hidden="true" class="pop-cancel">&times;</span></button> : <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.toggleUpdate()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>}
                            </div>

                            <div class="modal-body pt-1">
                                    <div class="d-flex flex-wrap row mt-1">
                                        <div className="form-group ">
                                            <label> Martial Status<span class="required1">*</span></label>
                                            <input
                                                type="text"
                                                // disabled={!status}
                                                className="form-control"
                                                placeholder=' Martial Status'
                                                value={martialStatusname}
                                                onChange={(e) => this.setState({ martialStatusname: e.target.value })}
                                                required
                                            />
                                        </div>
                                    </div>
                            </div>

                            <div class="modal-footer p-0 px-1">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        {is_add == true ? getModuleWriteAccess(Master) && <a class="btn save_btn del-pop-save" onClick={this.handleAdd}> Save</a> : status && getModuleUpdateAccess(Master) && <a class="btn save_btn del-pop-save" onClick={this.handleUpdate}> Update</a>}
                                        {is_add == true && getModuleWriteAccess(Master) ? <a class="btn reset_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.handleReset}>Reset</a> : <></>}
                                        {is_add == true ? <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleAdd}>Cancel</a> : <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.toggleUpdate()}>Cancel</a>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(connect(null, { martialStatusList_action, deleteMartialStatus_action, martialStatusDetail_action, addMartialStatus_action, updateMartialStatus_action })(MartialStatus))