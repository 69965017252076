import { axiosGet, axiosPost } from "../../react_axios/Axios"
import { headers, platfarm, workingDaysDetailsUrl,addWorkingDaysUrl, workingDaysListUrl, updateWorkingDaysUrl,WORKING_DAYS_ADD_ACTION_TYPE, WORKING_DAYS_DETAILS_ACTION_TYPE, WORKING_DAYS_LIST_ACTION_TYPE, WORKING_DAYS_UPDATE_ACTION_TYPE } from "../../utils/constants"

export const workingDaysList_action = () => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }
        console.log(param)
        const res = await axiosGet(workingDaysListUrl, param)
        console.log(res)
        dispatch({
            type: WORKING_DAYS_LIST_ACTION_TYPE,
            payload: res
        })

        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const workingDaysDetail_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id
        }
        const res = await axiosGet(workingDaysDetailsUrl, param)
        dispatch({
            type: WORKING_DAYS_DETAILS_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const updateWorkingDays_action = (id, week1Status, week2Status, week3Status, week4Status, week5Status,) => async (dispatch) => {
    try {
        const mapdata = {
            UserId: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
            Week1Status:week1Status,
            Week2Status:week2Status,
            Week3Status:week3Status,
            Week4Status:week4Status,
            Week5Status:week5Status,
        }
        console.log(mapdata)
        const res = await axiosPost(updateWorkingDaysUrl, mapdata, headers)
        dispatch({
            type: WORKING_DAYS_UPDATE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const addWorkingDays_action = (Day, week1Status, week2Status, week3Status, week4Status, week5Status,) => async (dispatch) => {
    try {
        const mapdata = {
            UserId: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: null,
            Day : Day,
            Week1Status:week1Status,
            Week2Status:week2Status,
            Week3Status:week3Status,
            Week4Status:week4Status,
            Week5Status:week5Status,
        }
        console.log(mapdata)
        const res = await axiosPost(addWorkingDaysUrl, mapdata, headers)
        dispatch({
            type: WORKING_DAYS_ADD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}


export default (state = {}, action) => {
    switch (action.type) {
        case 'SIMPLE_ACTION':
            return {
                result: action.payload
            }
        default:
            return state
    }
}