import { axiosPost, axiosGet } from "../../react_axios/Axios"
import { clientGroupListUrl, clientGroupDetailsUrl, clientGroupAddUrl, clientGroupUpdateUrl, clientGroupDeleteUrl,  CLIENTGROUP_LIST_ACTION_TYPE, CLIENTGROUP_DETAILS_ACTION_TYPE, CLIENTGROUP_ADD_ACTION_TYPE,CLIENTGROUP_UPDATE_ACTION_TYPE,CLIENTGROUP_DELETE_ACTION_TYPE, platfarm, headers } from '../../utils/constants'

const user_id = localStorage.getItem('UserId')

export const clientGroupList_action = (clientGroupName, status)=> async(dispatch) => {
    try{
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: clientGroupName,
            Status:status
        }
        // console.log("clientGroupList_action",param)
        const res = await axiosGet(clientGroupListUrl, param);
        dispatch({
            type: CLIENTGROUP_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    }
    catch(err){
        return Promise.reject(err);
    }
}

export const clientGroupAdd_action = (clientGroupName) => async(dispatch) => {
    try {
        const param = {
            UserID : localStorage.getItem('UserId'),
            Platform : platfarm,
            Name : clientGroupName
        }
        const res = await axiosPost(clientGroupAddUrl, param, headers);
        dispatch({
            type: CLIENTGROUP_ADD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);    
    } 
    catch(err){
        return Promise.reject(err);
    }
}

export const clientGroupDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }        
        const res = await axiosGet(clientGroupDetailsUrl, param);
        dispatch({
            type: CLIENTGROUP_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const clientGroupUpdate_action = (clientGroupName, id) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: clientGroupName,
            ID : id,
        }
        const res = await axiosPost(clientGroupUpdateUrl, mapdata, headers);
        console.log("clientGroupUpdate_action", mapdata)
        dispatch({
            type: CLIENTGROUP_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const clientGroupDelete_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }
        const res = await axiosGet(clientGroupDeleteUrl, param);
        dispatch({
            type: CLIENTGROUP_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch (err) {
        return Promise.reject(err);
    }
};