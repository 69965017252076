
import { FINANCIAL_YEAR_LIST_ACTION_TYPE, FINANCIAL_YEAR_LIST_ADD_ACTION_TYPE, FINANCIAL_YEAR_LIST_UPDATE_ACTION_TYPE, FINANCIAL_YEAR_LIST_DELETE_ACTION_TYPE } from "../../utils/constants"


const initialState = [];

function financialYearReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FINANCIAL_YEAR_LIST_ACTION_TYPE:
      return [...state, payload];
    case FINANCIAL_YEAR_LIST_ADD_ACTION_TYPE:
      return [...state, payload];
    case FINANCIAL_YEAR_LIST_UPDATE_ACTION_TYPE:
      return [...state, payload];
    case FINANCIAL_YEAR_LIST_DELETE_ACTION_TYPE:
      return [...state, payload];
    default:
      return state;
  }
};

export default financialYearReducer;


// export default (state = {}, action) => {
//     switch (action.type) {
//      case 'SIMPLE_ACTION':
//       return {
//        result: action.payload
//       }
//      default:
//       return state
//     }
//    }