import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Loader from '../../../utils/Loader'
import { fill_action } from '../../../actions/master_action/checklist_action';
import { chapterListActionId, mcqStatusActionId, levelActionId, MCQ_Setting } from '../../../utils/constants'
import { settingDetails_action, addSetting_action, updateSetting_action } from '../../../actions/mcq_action/settings_action';
import { getModuleWriteAccess } from '../../../utils/RoleAccess';

class Setting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAccessToView: true,
            is_add: false,
            is_delete: false,
            is_update: false,
            mcqNo: '',
            durationShort: '',
            duration: '',
            durationLong: '',
            minNoTest: '',
            noOfMcqLong: '',
            noOfMcqShort: '',
            detailsData: [],
            rows: [{ ID: null, Text: '', Value: '', Datatype: 'varchar' }],
        }
        this.getFillList = this.getFillList.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.getSettingDaysDetails = this.getSettingDaysDetails.bind(this)

        this.handleInputChange = this.handleInputChange.bind(this)
        this.deleteRow = this.deleteRow.bind(this)
        this.addRow = this.addRow.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount = () => {
        this.setState({
            // is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            // is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            // is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
            // settingID: this.props.location.state != null && this.props.location.state.settingID != null ? this.props.location.state.settingID : '',
            // isAccessToView: localStorage.getItem('UserId') == '1' ? true : false,
        })
        this.getSettingDaysDetails()
        // document.addEventListener('click', this.handleInputClick);
        // document.addEventListener('keydown', this.handleTabKeyPress);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };

    // handleInputClick = (event) => {
    //     const { previouslyFocusedElement } = this.state;
    //     if (previouslyFocusedElement) {
    //         previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    //         this.setState({ previouslyFocusedElement: null }); // Update previously focused element to null
    //     }
    // }

    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    handleInputChange = (index, field, event) => {
        const newRows = [...this.state.rows];
        newRows[index][field] = event.target.value;
        this.setState({ rows: newRows });
    };

    addRow = () => {
        const { rows } = this.state;
        const lastRow = rows[rows.length - 1];

        // Check if the last row's Text and Value are filled
        if (lastRow.Text === '' || lastRow.Value === '') {
            toast.error('Please fill in both Text and Value fields before adding a new row.', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            });
            return;
        }

        // Check for duplicates
        for (let i = 0; i < rows.length - 1; i++) {
            if (rows[i].Text === lastRow.Text && rows[i].Value === lastRow.Value) {
                toast.error('Duplicate entry!', {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                });
                return;
            }
        }

        const newID = rows.length + 1;

        this.setState((prevState) => ({
            rows: [...prevState.rows, { ID: null, Text: '', Value: '', Datatype: 'varchar' }],
        }));
    };

    deleteRow = (index) => {
        const { rows } = this.state;
        // if (index === 0) return; // Do not delete the first blank row

        this.setState({
            rows: rows.filter((_, i) => i !== index),
        });
    };


    //get details
    getSettingDaysDetails() {
        setTimeout(() => {
            // var id = this.state.settingID
            this.props
                .settingDetails_action()
                .then((data) => {
                    if (data.error != 1) {
                        // Transform the data
                        if (data.data.length > 0) {

                        } else {
                            // var filteredRows = [
                            //     {
                            //         ID: 1, Text: 'Min no of test to be given by each member in a financial year',
                            //         Value: minNoTest, Datatype: 'varchar'
                            //     },
                            //     { ID: 2, Text: 'No of MCQ per Short Test', Value: noOfMcqShort, Datatype: 'varchar' },
                            //     { ID: 3, Text: 'Time duration of Short Test', Value: durationShort, Datatype: 'varchar' },
                            //     { ID: 4, Text: 'No of MCQ per Long Test', Value: noOfMcqLong, Datatype: 'varchar' },
                            //     { ID: 5, Text: 'Time duration of Long Test', Value: durationLong, Datatype: 'varchar' },
                            // ]
                        }
                        let rows = data.data.map(item => ({
                            ID: item.ID || null,
                            Text: item.Text || '',
                            Value: item.Value || '',
                            Datatype: item.Datatype || 'varchar'
                        }));
                        this.setState({
                            rows: rows,
                            minNoTest: data.data[0]['Value'],
                            durationShort: data.data[2]['Value'],
                            durationLong: data.data[4]['Value'],
                            noOfMcqLong: data.data[3]['Value'],
                            noOfMcqShort: data.data[1]['Value'],
                            detailsData: data.data
                        });

                        // if (data.data[0]['Text'] == 'Min no of test to be given by each member in a financial year') {
                        //     this.setState({ minNoTest: data.data[0]['Value'] });
                        // }
                        // if (data.data[0]['Text'] == 'Time duration of Short Test') {
                        //     this.setState({ durationShort: data.data[0]['Value'] });
                        // }
                        // if (data.data[0]['Text'] == 'Time duration of Long Test') {
                        //     this.setState({ durationLong: data.data[0]['Value'] });
                        // }
                        // if (data.data[0]['Text'] == 'No of MCQ per Long Test') {
                        //     this.setState({ noOfMcqLong: data.data[0]['Value'] });
                        // }
                        // if (data.data[0]['Text'] == 'No of MCQ per Short Test') {
                        //     this.setState({ noOfMcqShort: data.data[0]['Value'] });
                        // }
                        // console.log('rows update', rows)

                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 500);
    }

    //Fill list
    getFillList(value, ref, ref2) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {

                }
            })
    }

    //cancel
    handleCancel() {
        this.props.navigate('/settingList')
    }

    //add setting 
    handleAdd(e) {
        e.preventDefault()
        const { mcqNo, duration, rows,
            durationShort, durationLong, minNoTest,
            noOfMcqLong, noOfMcqShort, detailsData } = this.state
        var isFollow = false
        if (durationShort == '' &&
            durationLong == '' &&
            minNoTest == '' &&
            noOfMcqLong == '' &&
            noOfMcqShort == ''
        ) {
            this.setState({ isLoading: false })
            toast.error('Please enter all records.', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
        else {
            isFollow = true
        }


        if (isFollow) {
            var filteredRows = [
                {
                    ID: detailsData.length == 0 ? null : 1, Text: 'Min no of test to be given by each member in a financial year',
                    Value: minNoTest, Datatype: 'varchar'
                },
                { ID: detailsData.length == 0 ? null : 2, Text: 'No of MCQ per Short Test', Value: noOfMcqShort, Datatype: 'varchar' },
                { ID: detailsData.length == 0 ? null : 3, Text: 'Time duration of Short Test', Value: durationShort, Datatype: 'varchar' },
                { ID: detailsData.length == 0 ? null : 4, Text: 'No of MCQ per Long Test', Value: noOfMcqLong, Datatype: 'varchar' },
                { ID: detailsData.length == 0 ? null : 5, Text: 'Time duration of Long Test', Value: durationLong, Datatype: 'varchar' },
            ]
            this.setState({ isLoading: true })
            this.props
                .addSetting_action(filteredRows)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data save successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        // setTimeout(() => {
                        //     this.handleCancel()
                        // }, 500);
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }

    }

    handleAdd1(e) {
        e.preventDefault()
        const { mcqNo, duration, minNoTest, rows } = this.state
        // Filter out rows with blank Text or Value
        const filteredRows = rows.filter(row => row.Text !== '' && row.Value !== '');
        console.log(filteredRows);
        if (filteredRows.length > 0) {
            this.setState({ isLoading: true })
            this.props
                .addSetting_action(filteredRows)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.handleCancel()
                        }, 500);
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter atleast one record.', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //update 
    handleUpdate(e) {
        e.preventDefault()
        const { mcqNo, duration, rows,
            durationShort, durationLong, minNoTest,
            noOfMcqLong, noOfMcqShort, } = this.state

        console.log('update row ', rows);

        var isFollow = false
        var filteredRows = []
        if (rows[0]['Text'] == 'Min no of test to be given by each member in a financial year') {
            if (minNoTest == '') {
                this.setState({ isLoading: false })
                toast.error('Please enter record.', {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                return;
            }
            else {
                isFollow = true
                var filteredRows = [
                    {
                        ID: rows[0]['ID'], Text: 'Min no of test to be given by each member in a financial year',
                        Value: minNoTest, Datatype: 'varchar'
                    },
                ]
            }
        }

        if (rows[0]['Text'] == 'Time duration of Short Test') {
            if (durationShort == '') {
                this.setState({ isLoading: false })
                toast.error('Please enter record.', {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                return;
            }
            else {
                isFollow = true
                var filteredRows = [
                    {
                        ID: rows[0]['ID'], Text: 'Time duration of Short Test',
                        Value: durationShort, Datatype: 'varchar'
                    },
                ]
            }
        }

        if (rows[0]['Text'] == 'Time duration of Long Test') {
            if (durationLong == '') {
                this.setState({ isLoading: false })
                toast.error('Please enter record.', {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                return;
            }
            else {
                isFollow = true
                var filteredRows = [
                    {
                        ID: rows[0]['ID'], Text: 'Time duration of Long Test',
                        Value: durationLong, Datatype: 'varchar'
                    },
                ]
            }
        }



        if (rows[0]['Text'] == 'No of MCQ per Long Test') {
            if (noOfMcqLong == '') {
                this.setState({ isLoading: false })
                toast.error('Please enter record.', {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                return;
            }
            else {
                isFollow = true
                var filteredRows = [
                    {
                        ID: rows[0]['ID'], Text: 'No of MCQ per Long Test',
                        Value: noOfMcqLong, Datatype: 'varchar'
                    },
                ]
            }
        }

        if (rows[0]['Text'] == 'No of MCQ per Short Test') {
            if (noOfMcqShort == '') {
                this.setState({ isLoading: false })
                toast.error('Please enter record.', {
                    theme: 'colored',
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                return;
            }
            else {
                isFollow = true
                var filteredRows = [
                    {
                        ID: rows[0]['ID'], Text: 'No of MCQ per Short Test',
                        Value: noOfMcqShort, Datatype: 'varchar'
                    },
                ]
            }
        }



        if (isFollow) {
            // var filteredRows = [
            // {
            //     ID: null, Text: 'Min no of test to be given by each member in a financial year',
            //     Value: minNoTest, Datatype: 'varchar'
            // }, 
            // { ID: null, Text: 'No of MCQ per Short Test', Value: noOfMcqShort, Datatype: 'varchar' },
            // { ID: null, Text: 'Time duration of Short Test', Value: durationShort, Datatype: 'varchar' },
            // { ID: null, Text: 'No of MCQ per Long Test', Value: noOfMcqLong, Datatype: 'varchar' },
            // { ID: null, Text: 'Time duration of Long Test', Value: durationLong, Datatype: 'varchar' },
            // ]
            this.setState({ isLoading: true })
            this.props
                .addSetting_action(filteredRows)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.handleCancel()
                        }, 500);
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    render() {
        const { is_update, isAccessToView, isLoading,
            mcqNo, duration, is_add, rows,
            durationShort, durationLong, minNoTest,
            noOfMcqLong, noOfMcqShort,
        } = this.state
        return (<>
            {isLoading ? (
                <Loader></Loader>
            ) : (
                null
            )}
            <Header />
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                    {/* Left side menu */}
                    <Drawer />
                    {/* end left menu */}
                    {/* start page */}
                    {isAccessToView ? (
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul class="breadcrumb d-flex">
                                        <li><a onClick={this.handleCancel}>MCQ Setting</a></li>
                                        <li></li>
                                    </ul>
                                   { is_update ? <div class="status-top mr-2 text-dark" style={{ position: 'absolute', right: '0px', top: '62' }}>
                                        <p>Status : <strong class="text-success">Active</strong></p>
                                    </div> : ''}
                                </div>
                                <div className="row">
                                    <style
                                        type="text/css"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                        }}
                                    />
                                        <div className="">
                                            <div class="card-body mb-4 p-2">
                                                {/* <div className='table-responsive tbs4 common-table trail-tb'>
                                                <table className="table table-bordered tb-action du-table">
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center' style={{width:'50px'}}>SR.NO</th>
                                                            <th>Text</th>
                                                            <th className='text-center' style={{width:'150px'}}>Value</th>
                                                            {is_add && <th>Action</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {rows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td className='text-center p-0'>{index + 1}</td>
                                                                <td className='p-0'>
                                                                    <input type="text"
                                                                        class="p-1"
                                                                        value={row.Text}
                                                                        onChange={(e) => this.handleInputChange(index, 'Text', e)}
                                                                    />
                                                                </td>
                                                                <td className='p-0 text-center'>
                                                                    <input type="text"
                                                                        class="p-1 text-center"
                                                                        value={row.Value}
                                                                        onChange={(e) => this.handleInputChange(index, 'Value', e)}
                                                                    />
                                                                </td>
                                                                {is_add && <td>
                                                                    {rows.length > 1 && (
                                                                        <a> <span className="text-danger">
                                                                            <i className="fa fa-trash-o" onClick={() => this.deleteRow(index)} /></span></a>
                                                                    )}
                                                                </td>}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                </div>
                                                 {is_add ? <button type="button"
                                                    className="btn btn-save footer-btn-inner mr-1 mt-2" onClick={this.addRow}>
                                                    Add
                                                </button> : <></>} */}
                                                <div class="form-group col-md-4 mt-1">
                                                    <label>Min no of test to be given by each member in a financial year
                                                        (nos)<span class="required1">*</span></label>
                                                    <input type="text" class="form-control"
                                                        value={minNoTest}
                                                        onChange={(e) => {
                                                            // this.setState({ minNoTest: e.target.value });
                                                            const input = e.target.value;
                                                            const regex = /^[0-9\b]+$/; // Regex to allow only numbers
                                                            if (input === '' || regex.test(input)) {
                                                                this.setState({ minNoTest: input });
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div class="form-group col-md-4 mt-1">
                                                    <label>	No of MCQ per Short Test
                                                        (nos)<span class="required1">*</span></label>
                                                    <input type="text" class="form-control"
                                                        value={noOfMcqShort}
                                                        onChange={(e) => {
                                                            // this.setState({ noOfMcqShort: e.target.value });
                                                            const input = e.target.value;
                                                            const regex = /^[0-9\b]+$/; // Regex to allow only numbers
                                                            if (input === '' || regex.test(input)) {
                                                                this.setState({ noOfMcqShort: input });
                                                            }
                                                        }}
                                                    />
                                                </div>


                                                <div class="form-group col-md-4 mt-1">
                                                    <label>Time duration of Short Test
                                                        (min)<span class="required1">*</span></label>
                                                    <input type="text" class="form-control"
                                                        value={durationShort}
                                                        onChange={(e) => {
                                                            // this.setState({ durationShort: e.target.value });
                                                            const input = e.target.value;
                                                            const regex = /^[0-9\b]+$/; // Regex to allow only numbers
                                                            if (input === '' || regex.test(input)) {
                                                                this.setState({ durationShort: input });
                                                            }
                                                        }}
                                                    />
                                                </div>


                                                <div class="form-group col-md-4 mt-1">
                                                    <label>	No of MCQ per Long Test
                                                        (nos)<span class="required1">*</span></label>
                                                    <input type="text" class="form-control"
                                                        value={noOfMcqLong}
                                                        onChange={(e) => {
                                                            // this.setState({ noOfMcqLong: e.target.value });
                                                            const input = e.target.value;
                                                            const regex = /^[0-9\b]+$/; // Regex to allow only numbers
                                                            if (input === '' || regex.test(input)) {
                                                                this.setState({ noOfMcqLong: input });
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div class="form-group col-md-4 mt-1">
                                                    <label>	Time duration of Long Test
                                                        (min)<span class="required1">*</span></label>
                                                    <input type="text" class="form-control"
                                                        value={durationLong}
                                                        onChange={(e) => {
                                                            const input = e.target.value;
                                                            const regex = /^[0-9\b]+$/; // Regex to allow only numbers
                                                            if (input === '' || regex.test(input)) {
                                                                this.setState({ durationLong: input });
                                                            }
                                                            // this.setState({ durationLong: e.target.value });
                                                        }}
                                                    />
                                                </div>

                                                <div className="row">
                                                    <div className="footer-btn">
                                                        <div className="col-md-3">
                                                            <div
                                                                className="btn-group"
                                                                role="group"
                                                                aria-label="Basic example"
                                                            >
                                                              { getModuleWriteAccess(MCQ_Setting) &&<button
                                                                    type="button"
                                                                    className="btn btn-save footer-btn-inner mr-1"
                                                                    onClick={this.handleAdd}
                                                                >
                                                                    Save
                                                                </button>}
                                                                {/* {is_update ? <button
                                                                    type="button"
                                                                    className="btn btn-update footer-btn-inner mr-1"
                                                                    onClick={this.handleUpdate}
                                                                >
                                                                    Update
                                                                </button> : <button
                                                                    type="button"
                                                                    className="btn btn-save footer-btn-inner mr-1"
                                                                    onClick={this.handleAdd}
                                                                >
                                                                    Save
                                                                </button>} */}
                                                                {/* <button
                                                                    type="button"
                                                                    className="btn btn-cancel footer-btn-inner"
                                                                    onClick={this.handleCancel}
                                                                >
                                                                    Cancel
                                                                </button> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    ) : (<h5 style={{ color: 'red', alignSelf: 'center', margin: 'auto' }}>You do not have access to view this form!</h5>)}
                    {/* end page */}
                </div>
                {/* page-body-wrapper ends */}
            </div>
            <ToastContainer />
        </>)
    }
}

export default withRouter(connect(null, {
    fill_action, settingDetails_action,
    addSetting_action,
})(Setting))