
import { EXPENSE_HEAD_LIST_ACTION_TYPE, EXPENSE_HEAD_ADD_ACTION_TYPE, EXPENSE_HEAD_UPDATE_ACTION_TYPE, EXPENSE_HEAD_DETAILS_ACTION_TYPE, EXPENSE_HEAD_DETETE_ACTION_TYPE, } from "../../utils/constants";

const initialState = []

function expenseHeadReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case EXPENSE_HEAD_LIST_ACTION_TYPE:
            return [...state, payload];
        case EXPENSE_HEAD_ADD_ACTION_TYPE:
            return [...state, payload];
        case EXPENSE_HEAD_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case EXPENSE_HEAD_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case EXPENSE_HEAD_DETETE_ACTION_TYPE:
            return [...state, payload]
        default:
            return state;
    }
}

export default expenseHeadReducer