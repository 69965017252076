import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Loader from '../../../utils/Loader'
import { fill_action } from '../../../actions/master_action/checklist_action';
import { chapterListActionId, levelActionId, answerkeyActionId, MCQ_Setup } from '../../../utils/constants'
import { mcqSetupDetails_action, updatemcqSetup_action, addmcqSetup_action } from '../../../actions/mcq_action/mcq_setup_action';
import { getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

class MCQSetupAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAccessToView: true,
            QuestionID: '',
            workingDayTypeList: [],
            is_add: false,
            is_delete: false,
            is_update: false,
            mcqNo: '',
            chapterId: "",
            chapterList: [],
            levelId: "",
            levelList: [],
            answerKeyId: '',
            answerKeyList: [],
            question: '',
            option1: '',
            option2: '',
            option3: '',
            option4: '',
        }
        this.getFillList = this.getFillList.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.getSetupDetails = this.getSetupDetails.bind(this)
        this.handleReset = this.handleReset.bind(this)


    }

    componentDidMount = () => {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
            QuestionID: this.props.location.state != null && this.props.location.state.QuestionID != null ? this.props.location.state.QuestionID : '',
            dayOfTheWeek: this.props.location.state != null && this.props.location.state.dayOfTheWeek != null ? this.props.location.state.dayOfTheWeek : '',
            // isAccessToView: localStorage.getItem('UserId') == '1' ? true : false,
        })
        this.props.location.state != null && this.props.location.state.is_update && this.getSetupDetails()
        // document.addEventListener('click', this.handleInputClick);

        // document.addEventListener('keydown', this.handleTabKeyPress);
        this.getFillList(chapterListActionId, '', '');
        this.getFillList(levelActionId, '', '');
        this.getFillList(answerkeyActionId, '', '');
    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    handleReset() {
        this.setState({
            chapterId: "",
            levelId: "",
            answerKeyId: '',
            question: '',
            option1: '',
            option2: '',
            option3: '',
            option4: '',
        })
        // this.getClientsList()

    }

    //get details
    getSetupDetails() {
        setTimeout(() => {
            var id = this.state.QuestionID
            this.props
                .mcqSetupDetails_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        console.log("mcqSetupDetails_action, ", data.data[0])
                        this.setState({
                            chapterId: data.data[0]['ChapterID'],
                            question: data.data[0]['Question'],
                            option1: data.data[0]['Option1'],
                            option2: data.data[0]['Option2'],
                            option3: data.data[0]['Option3'],
                            option4: data.data[0]['Option4'],
                            answerKeyId: data.data[0]['AnswerKey'],
                            levelId: data.data[0]['Level'],

                        })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 500);
    }

    //Fill list
    getFillList(value, ref, ref2) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == chapterListActionId) {
                        this.setState({ chapterList: data.data, isLoading: false })

                    }
                    if (value == levelActionId) {
                        this.setState({ levelList: data.data, isLoading: false })

                    }
                    if (value == answerkeyActionId) {
                        this.setState({ answerKeyList: data.data, isLoading: false })

                    }

                }
            })
    }


    //cancel
    handleCancel() {
        this.props.navigate('/mcqsetupList')
    }

    //add mcq setup 
    handleAdd(e) {
        e.preventDefault()
        const { chapterId, QuestionID, levelId, option1, option2, option3, option4, answerKeyId, question, is_add, is_update } = this.state
        const id = QuestionID

        if (chapterId != "" && levelId != "" && question != "" && option1 != "" && option2 != "" && option3 != "" && option4 != "" && answerKeyId != "") {
            this.setState({ isLoading: true })
            this.props
                .addmcqSetup_action(is_add ? null : id, chapterId, levelId, option1, option2, option3, option4, answerKeyId, question)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.handleCancel()
                        }, 500);
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //update working day
    handleUpdate(e) {
        e.preventDefault()
        const { QuestionID, week1Status, week2Status, week3Status, week4Status, week5Status } = this.state
        const id = QuestionID

        if (id != "" && week1Status != "" && week2Status != "" && week3Status != "" && week4Status != "" && week5Status != "") {
            this.setState({ isLoading: true })
            this.props
                .updatemcqSetup_action(id, week1Status, week2Status, week3Status, week4Status, week5Status)
                .then((data) => {
                    if (data.error == 0) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.handleCancel()
                        }, 500);

                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    render() {
        const { is_update, isAccessToView, isLoading, question, option1, option2, option3, option4,
            mcqNo, chapterId, chapterList, levelId, levelList, answerKeyId, answerKeyList } = this.state
        return (<>
            {isLoading ? (
                <Loader></Loader>
            ) : (
                null
            )}
            <Header />
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                    {/* Left side menu */}
                    <Drawer />
                    {/* end left menu */}
                    {/* start page */}
                    {isAccessToView ? (
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul class="breadcrumb d-flex">
                                        <li><a onClick={this.handleCancel}>MCQ Questions Setup</a></li>
                                        <li></li>
                                    </ul>
                                    {is_update ? <div class="status-top mr-2 text-dark" style={{ position: 'absolute', right: '0px', top: '62' }}>
                                        <p>Status : <strong class="text-success">Active</strong></p>
                                    </div> : ''}
                                </div>
                                <div className='page-space-from-bottom'>
                                    <div className="row">
                                        <style
                                            type="text/css"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                            }}
                                        />
                                        <div className="col-md-12 pg-body grid-margin stretch-card">
                                            <div className="card">
                                                <div class="card-body mb-4">
                                                    {/* height: "calc(100vh - 125px)", overflow: 'auto',   overflowX: 'hidden' */}
                                                    <div class="row user-status">

                                                    </div>
                                                    <div class="d-flex flex-wrap row ">
                                                        {/* <div class="form-group col-md-3 mt-1">
                                                        <label>MCQ No</label>
                                                        <input type="text"
                                                            className="form-control"
                                                            placeholder="MCQ 1"
                                                            value={mcqNo}
                                                            onKeyDown={e => this.handleKeyDown(e)}
                                                            onChange={(e) => this.setState({ mcqNo: e.target.value })}
                                                            disabled />
                                                    </div> */}
                                                        <div class="form-group col-md-3">
                                                            <label>Chapter<span class="required1">*</span></label>
                                                            <select className="js-example-basic-single" style={{ width: '100%' }} value={chapterId}
                                                                onChange={e => this.setState({ chapterId: e.target.value })}>
                                                                <option value="">Select</option>
                                                                {chapterList.length > 0 && chapterList.map(item => (
                                                                    <option
                                                                        key={item.Code}
                                                                        value={item.Code}>
                                                                        {item.Description}
                                                                    </option>
                                                                ))}

                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <label>Level<span class="required1">*</span></label>
                                                            <select className="js-example-basic-single" style={{ width: '100%' }} value={levelId}
                                                                onChange={e => this.setState({ levelId: e.target.value })}>
                                                                <option value="">Select</option>
                                                                {levelList.length > 0 && levelList.map(item => (
                                                                    <option
                                                                        key={item.Code}
                                                                        value={item.Code}>
                                                                        {item.Description}
                                                                    </option>
                                                                ))}

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex flex-wrap row ">
                                                        <div class="form-group col-12">
                                                            <label>Question<span class="required1">*</span></label>
                                                            <textarea class="form-control h-auto" rows="5" cols="50"
                                                                value={question}
                                                                onChange={(e) => this.setState({ question: e.target.value })}>
                                                            </textarea>
                                                        </div>
                                                    </div>

                                                    {/* <h6 className='text-center text-secondary'>(you choose not to set an option, enter "-".)</h6> */}
                                                    <div class="d-flex flex-wrap row ">
                                                        <div class="form-group col-12">
                                                            <label>Option 1<span class="required1">*</span>  &nbsp;  (you choose not to set an option, enter "-".)</label>
                                                            <textarea class="form-control h-auto" rows="3" cols="50"
                                                                value={option1}
                                                                onChange={(e) => this.setState({ option1: e.target.value })}>
                                                            </textarea>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex flex-wrap row ">
                                                        <div class="form-group col-12">
                                                            <label>Option 2<span class="required1">*</span> &nbsp;  (you choose not to set an option, enter "-".)</label>
                                                            <textarea class="form-control h-auto" rows="3" cols="50"
                                                                value={option2}
                                                                onChange={(e) => this.setState({ option2: e.target.value })}>
                                                            </textarea>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex flex-wrap row ">
                                                        <div class="form-group col-12">
                                                            <label>Option 3<span class="required1">*</span>  &nbsp;  (you choose not to set an option, enter "-".)</label>
                                                            <textarea class="form-control h-auto" rows="3" cols="50"
                                                                value={option3}
                                                                onChange={(e) => this.setState({ option3: e.target.value })}>
                                                            </textarea>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex flex-wrap row ">
                                                        <div class="form-group col-12">
                                                            <label>Option 4<span class="required1">*</span>  &nbsp;  (you choose not to set an option, enter "-".)</label>
                                                            <textarea class="form-control h-auto" rows="3" cols="50"
                                                                value={option4}
                                                                onChange={(e) => this.setState({ option4: e.target.value })}>
                                                            </textarea>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex flex-wrap row ">
                                                        <div class="form-group col-md-3 mt-1">
                                                            <label>Answer Key<span class="required1">*</span></label>
                                                            <select className="js-example-basic-single" style={{ width: '100%' }} value={answerKeyId}
                                                                onChange={e => this.setState({ answerKeyId: e.target.value })}>
                                                                <option value="">Select</option>
                                                                {answerKeyList.length > 0 && answerKeyList.map(item => (
                                                                    <option
                                                                        key={item.Code}
                                                                        value={item.Code}>
                                                                        {item.Description}
                                                                    </option>
                                                                ))}

                                                            </select>
                                                        </div>
                                                    </div>


                                                    {/* 
                                                <div class="d-flex flex-wrap row mt-2 m-0 border pt-1 pb-2 rounded-1" style={{ background: '#f4f4f4', textAlign:'left' }}>
                                                    <h5 class="mt-1">System Info</h5>
                                                    <div class="form-group col-md-4 ">
                                                        <span>Created By: Admin</span>
                                                    </div>
                                                    <div class="form-group col-md-4 ">
                                                        <span>Modified By: Admin</span>
                                                    </div>
                                                    <div class="form-group col-md-4 ">
                                                        <span>Deleted Date: 10/03/2024</span>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <span>Created Date Time: 13/04/2024</span>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <span>Modified Date Time: 13/04/2024</span>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <span>Deleted Date Time: 13/04/2024</span>
                                                    </div>
                                                </div> */}




                                                    <div style={{ height: '75px' }}></div>

                                                    <div className="row">
                                                        <div className="footer-btn">
                                                            <div className="col-md-3">
                                                                <div
                                                                    className="btn-group"
                                                                    role="group"
                                                                    aria-label="Basic example"
                                                                >
                                                                    {is_update ? getModuleUpdateAccess(MCQ_Setup) && <button
                                                                        type="button"
                                                                        className="btn btn-update footer-btn-inner mr-1"
                                                                        onClick={this.handleAdd}
                                                                    >
                                                                        Update
                                                                    </button> : <>
                                                                        {getModuleWriteAccess(MCQ_Setup) ?
                                                                            <>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-save footer-btn-inner mr-1"
                                                                                    onClick={this.handleAdd}
                                                                                >
                                                                                    Add
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-update footer-btn-inner mr-1"
                                                                                    onClick={this.handleReset}
                                                                                >
                                                                                    Reset
                                                                                </button>
                                                                            </>
                                                                            : <></>}
                                                                    </>
                                                                    }
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-cancel footer-btn-inner"
                                                                        onClick={this.handleCancel}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                    </div>)}
                    {/* end page */}
                </div>
                {/* page-body-wrapper ends */}
            </div>
            <ToastContainer />
        </>)
    }
}

export default withRouter(connect(null, {
    fill_action, mcqSetupDetails_action,
    updatemcqSetup_action, addmcqSetup_action
})(MCQSetupAU))