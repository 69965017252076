import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    platfarm, headers,
    APPROVAL_LIST_ACTION_TYPE,
    APPROVAL_REJECT_ACTION_TYPE,
    APPROVAL_DETAILS_ACTION_TYPE,
    approvalUrl, approvalDetailsUrl,
    approvalRejectUrl
} from "../../utils/constants"
import moment from "moment";



export const approval_action = (clientName, finYearId, engId, employeeId, transactionForId, status) => async (dispatch) => {
    console.log('employeeId 1', typeof (employeeId));
    console.log('employeeId 2', employeeId != '');
    console.log('employeeId 3', employeeId != 'null');
    console.log('employeeId 4', employeeId != null);
    console.log('employeeId 5', employeeId != '' || employeeId != 'null' ? employeeId : null);
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientID: clientName != '' ? clientName : null,
            FinyearID: finYearId != '' ? finYearId : null,
            EmployeeID: employeeId != '' && employeeId != 'null' ? employeeId : null,
            EngagementID: engId != '' ? engId : null,
            TransactionForID: transactionForId != '' ? transactionForId : null,
            Status: status && status != 'null' ? status : null,
        }
        console.log(param);
        const res = await axiosGet(approvalUrl, param);
        console.log(res);
        dispatch({
            type: APPROVAL_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const approvalDetails_action = (expenseId, TimesheetId) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ExpenseId: expenseId != '' ? expenseId : null,
            TimesheetId: TimesheetId != '' ? TimesheetId : null,
        }

        const res = await axiosGet(approvalDetailsUrl, param);
        dispatch({
            type: APPROVAL_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const approvalReject_action = (timeSheetID, ExpenseID, flag, rejectionReason) => async (dispatch) => {
    try {
        const mapData = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            TimesheetID: timeSheetID,
            ExpenseID: ExpenseID,
            Flag: flag,
            RejecReason: rejectionReason,
        }

        const res = await axiosPost(approvalRejectUrl, mapData, headers);
        console.log("approvalRejectUrl 12", mapData)
        dispatch({
            type: APPROVAL_REJECT_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


