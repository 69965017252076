import { combineReducers } from 'redux';
import simpleReducer from './simpleReducer';
import loginReducer from './user_auth_reducer/login_reducer';
import enagementAllocationReducer from './engagement_reducer/engagement_allocation_reducer';
import clientEnagementReducer from './engagement_reducer/client_engagement_reducer';
import reviewChecklistReducer from './engagement_reducer/review_checklist_reducer';
import updateChecklistReducer from './engagement_reducer/update_checklist_reducer';
import checkListReducer from './master_reducer/checklist_reducer';
import checkListTemplateReducer from './master_reducer/checklist_template_reducer';
import financialYearReducer from './master_reducer/financial_year_reducer';
import employeeReducer from './master_reducer/employee-reducer';
import timesheetReducer from './timesheet_expense_reducer/timesheet_reducer';
import expenseReducer from './timesheet_expense_reducer/expense_reducer';
import clientReducer from './master_reducer/client_reducer'
import documentReducer from './timesheet_expense_reducer/document_reducer';
import approvalReducer from './timesheet_expense_reducer/approval_reducer';
import departmentReducer from './master_reducer/department_reducer'
import designationReducer from './master_reducer/designation_reducer'
import bloodGroupReducer from './master_reducer/bloodGroup_reducer';
import clientGroupReducer from './master_reducer/clientGroup_reducer';
import taskTypeReducer from './master_reducer/taskType_reducer';
import leaveApprovalReducer from './timesheet_expense_reducer/leaveApproval_reducer';
import userCompanyReducer from './master_reducer/locationType_reducer';
import holidayReducer from './master_reducer/holiday_reducer'
import workingDaysReducer from './master_reducer/working_days_reducer';
import addressTypeReducer from './master_reducer/address_type_reducer';
import clientCategoryReducer from './master_reducer/client_category_reducer';
import expenseHeadReducer from './master_reducer/expense_head_reducer';
import locationTypeReducer from './master_reducer/locationType_reducer';
import managementTypeReducer from './master_reducer/managementType_reducer';
import engagementRoleReducer from './master_reducer/engagementRole_reducer';
import leaveReducer from './timesheet_expense_reducer/leave_reducer';
import martialStatusReducer from './master_reducer/martial_status_reducer';

export default combineReducers({
    simpleReducer,
    loginReducer,
    enagementAllocationReducer,
    clientEnagementReducer,
    reviewChecklistReducer,
    updateChecklistReducer,
    checkListReducer,
    checkListTemplateReducer,
    financialYearReducer,
    employeeReducer,
    timesheetReducer,
    expenseReducer,
    documentReducer,
    clientReducer,
    approvalReducer,
    departmentReducer,
    designationReducer,
    bloodGroupReducer,
    clientGroupReducer,
    taskTypeReducer,
    leaveApprovalReducer,
    userCompanyReducer,
    holidayReducer,
    workingDaysReducer,
    addressTypeReducer,
    clientCategoryReducer,
    expenseHeadReducer,
    locationTypeReducer,
    managementTypeReducer,
    engagementRoleReducer,
    leaveReducer,
    martialStatusReducer,
});

