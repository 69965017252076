
import { HOLIDAY_LIST_ACTION_TYPE, HOLIDAY_ADD_ACTION_TYPE, HOLIDAY_UPDATE_ACTION_TYPE, HOLIDAY_DETAILS_ACTION_TYPE, HOLIDAY_DETETE_ACTION_TYPE } from "../../utils/constants";

const initialState = []

function holidayReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case HOLIDAY_LIST_ACTION_TYPE:
            return [...state, payload];
        case HOLIDAY_ADD_ACTION_TYPE:
            return [...state, payload];
        case HOLIDAY_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case HOLIDAY_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case HOLIDAY_DETETE_ACTION_TYPE:
            return [...state, payload]
        default:
            return state;
    }
}

export default holidayReducer