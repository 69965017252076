import { axiosPost, axiosGet } from "../../react_axios/Axios"
import { BLOODGROUP_LIST_ACTION_TYPE, BLOODGROUP_DETAILS_ACTION_TYPE, BLOODGROUP_ADD_ACTION_TYPE, BLOODGROUP_UPDATE_ACTION_TYPE, BLOODGROUP_DELETE_ACTION_TYPE, bloodGroupListUrl, bloodGroupDetailsUrl, bloodGroupAddUrl, bloodGroupUpdateUrl, bloodGroupDeleteUrl, platfarm, headers } from '../../utils/constants'

const user_id = localStorage.getItem('UserId')

export const bloodGroupList_action = (bloodGroup, status)=> async(dispatch) => {
    try{
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: bloodGroup,
            Status:status
        }
        // console.log("bloodGrouptList_action",param)
        const res = await axiosGet(bloodGroupListUrl, param);
        dispatch({
            type: BLOODGROUP_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    }
    catch(err){
        return Promise.reject(err);
    }
}

export const bloodGroupAdd_action = (bloodGroup) => async(dispatch) => {
    try {
        const param = {
            UserID : localStorage.getItem('UserId'),
            Platform : platfarm,
            Name : bloodGroup
        }
        const res = await axiosPost(bloodGroupAddUrl, param, headers);
        dispatch({
            type: BLOODGROUP_ADD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);    
    } 
    catch(err){
        return Promise.reject(err);
    }
}

export const bloodGroupDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }        
        const res = await axiosGet(bloodGroupDetailsUrl, param);
        console.log("bloodGroupDetails_action", param)
        dispatch({
            type: BLOODGROUP_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const bloodGroupUpdate_action = (bloodGroup, id) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: bloodGroup,
            ID : id,
        }
        const res = await axiosPost(bloodGroupUpdateUrl, mapdata, headers);
        console.log("bloodGroupUpdate_action", mapdata)
        dispatch({
            type: BLOODGROUP_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const bloodGrouptDelete_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }
        const res = await axiosGet(bloodGroupDeleteUrl, param);
        dispatch({
            type: BLOODGROUP_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch (err) {
        return Promise.reject(err);
    }
};