import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { financialYearList_action, deleteFinancialYearList_action } from '../../../actions/master_action/financial_year_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import Pagination from '../../../utils/pagination'

import { clearLocalStorage, Master } from '../../../utils/constants'
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//   FinancialYearAction: () => dispatch(FinancialYearAction())
// })

/* 
 * mapStateToProps
*/
// const mapStateToProps = state => {
//   return {
//     loginData: state,
//   };
// }

class FinancialYearList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isAccessToView: true,
      financialYearName: '',
      shortName: '',
      status: ' ',
      financialYearList: [],
      isOpen: false,
      finYearDeleteId: '',
      isLoading: false,
      currentPage: 1,
      tablePerPage: 10,
      recordsPerPage : 10,
      pageSizeInput: "10", 
      finYearNameFLS: '',
      shortNameFLS: '',
      statusFLS: '',
      isFinancialYearNameSort: false,
      isPeriodSort: false,
      isShortNameSort: false,
      isStatusSort: false,
      // setCurrentPage: function(),
      isSearchTab: false,
    }
    this.handleSearch = this.handleSearch.bind(this)
    this.clearValue = this.clearValue.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.updateItem = this.updateItem.bind(this);
    this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
    this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);  

    this.record = this.record.bind(this)
    this.getFinYearList = this.getFinYearList.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.financialYearNameSort = this.financialYearNameSort.bind(this)
    this.periodSort = this.periodSort.bind(this)
    this.shortNameSort = this.shortNameSort.bind(this)
    this.statusSort = this.statusSort.bind(this)
  }

  statusSort() {
    const { financialYearList, isStatusSort } = this.state
    this.setState({ isStatusSort: !isStatusSort })
    if (!isStatusSort) {
      // sorting
      var statusSort = financialYearList.sort((a, b) => {
        const nameA = a.isActive; // ignore upper and lowercase
        const nameB = b.isActive; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ financialYearList: statusSort })
    }
    else {
      // reverse
      var statusSort = financialYearList.sort((a, b) => {
        const nameA = a.isActive; // ignore upper and lowercase
        const nameB = b.isActive; // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ financialYearList: statusSort })
    }
    this.setState({ isStatusSort: !isStatusSort })
  }

  shortNameSort() {
    const { financialYearList, isShortNameSort } = this.state
    this.setState({ isShortNameSort: !isShortNameSort })
    if (!isShortNameSort) {
      // sorting
      var shortNameSort = financialYearList.sort((a, b) => {
        const nameA = a.Code.toUpperCase(); // ignore upper and lowercase
        const nameB = b.Code.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ financialYearList: shortNameSort })
    }
    else {
      // reverse
      var shortNameSort = financialYearList.sort((a, b) => {
        const nameA = a.Code.toUpperCase(); // ignore upper and lowercase
        const nameB = b.Code.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ financialYearList: shortNameSort })
    }
    this.setState({ isShortNameSort: !isShortNameSort })
  }


  periodSort() {
    const { financialYearList, isPeriodSort } = this.state
    this.setState({ isPeriodSort: !isPeriodSort })
    if (!isPeriodSort) {
      // sorting
      var periodSort = financialYearList.sort((a, b) => {
        const nameA = a.FromDate.toUpperCase(); // ignore upper and lowercase
        const nameB = b.FromDate.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ financialYearList: periodSort })
    }
    else {
      // reverse
      var periodSort = financialYearList.sort((a, b) => {
        const nameA = a.FromDate.toUpperCase(); // ignore upper and lowercase
        const nameB = b.FromDate.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ financialYearList: periodSort })
    }
    this.setState({ isPeriodSort: !isPeriodSort })
  }

  financialYearNameSort() {
    const { financialYearList, isFinancialYearNameSort } = this.state
    this.setState({ isFinancialYearNameSort: !isFinancialYearNameSort })
    if (!isFinancialYearNameSort) {
      // sorting
      var financialYearNameSort = financialYearList.sort((a, b) => {
        const nameA = a.FinancialYear.toUpperCase(); // ignore upper and lowercase
        const nameB = b.FinancialYear.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ financialYearList: financialYearNameSort })
    }
    else {
      // reverse
      var financialYearNameSort = financialYearList.sort((a, b) => {
        const nameA = a.FinancialYear.toUpperCase(); // ignore upper and lowercase
        const nameB = b.FinancialYear.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ financialYearList: financialYearNameSort })
    }
    this.setState({ isFinancialYearNameSort: !isFinancialYearNameSort })
  }

  handleKeyDown(e) {
    if (e.code === 'Enter') {
      this.handleSearch()
    }
  }

  handleClick(event) {
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid
    });
  }
  getFinYearList() {
    const { financialYearName, status, shortName } = this.state
    this.setState({ isLoading: true })
    clearLocalStorage("financialYear")
    this.props
      .financialYearList_action(financialYearName.trimStart(), shortName.trimStart(), status === '' ? 1 : status)
      .then((data) => {
        if (data.error != 1) {
          this.setState({ financialYearList: data.data, isLoading: false, currentPage: 1 })

        }
        else {
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  // financial list default
  componentDidMount = () => {
    this.setState({
      isAccessToView: true,
      finYearNameFLS: localStorage.getItem('finYearNameFLS'),
      shortNameFLS: localStorage.getItem('shortNameFLS'),
      statusFLS: localStorage.getItem('statusFLS'),

      financialYearName: localStorage.getItem('finYearNameFLS') != '' ? localStorage.getItem('finYearNameFLS') : '',
      shortName: localStorage.getItem('shortNameFLS') != '' ? localStorage.getItem('shortNameFLS') : '',
      status: localStorage.getItem('statusFLS') != '' ? localStorage.getItem('statusFLS') : '',
    })
    clearLocalStorage("financialYear")
    setTimeout(() => {
      this.getFinYearList()

    }, 100);
    // document.addEventListener('keydown', this.handleTabKeyPress);
    // document.addEventListener('click', this.handleInputClick);

  }

  componentWillUnmount() {
      // document.removeEventListener('keydown', this.handleTabKeyPress);
      // document.removeEventListener('click', this.handleInputClick);
    }


  handleTabKeyPress = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
      const focusedElement = document.activeElement;
      const { previouslyFocusedElement } = this.state;

      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }

      if (focusedElement.classList.contains('form-control') ||
        focusedElement.classList.contains('js-example-basic-single') ||
        focusedElement.classList.contains('example-custom-input')) {
        focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
        this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
      }
    }
  };


  handleInputClick = (event) => {
    const { previouslyFocusedElement } = this.state;
    const clickedElement = event.target;

    if (previouslyFocusedElement) {
      previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    }

    if (clickedElement.classList.contains('form-control') ||
      clickedElement.classList.contains('js-example-basic-single') ||
      clickedElement.classList.contains('example-custom-input')) {
      clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
      this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
    } else {
      this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
    }
  };

  // search list 
  handleSearch() {
    const { financialYearName, status, shortName } = this.state

    clearLocalStorage("financialYear")

    this.setState({ isLoading: true })
    this.props
      .financialYearList_action(financialYearName.trimStart(), shortName.trimStart(), status === '' ? 1 : status)
      .then((data) => {
        if (data.error != 1) {
          this.setState({ financialYearList: data.data, isLoading: false, currentPage: 1 })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

    localStorage.setItem("finYearNameFLS", financialYearName)
    localStorage.setItem("shortNameFLS", shortName)
    localStorage.setItem("statusFLS", status)
  }
  clearValue() {
    this.setState({
      financialYearName: '',
      shortName: '',
      status: '',
      financialYearList: [],
      currentPage: 1,
      finYearNameFLS: '',
      shortNameFLS: '',
      statusFLS: '',
    })
    clearLocalStorage('')
    setTimeout(() => {
      this.getFinYearList()
    }, 300);

  }

  // add list 
  handleAdd() {
    this.props.navigate('/finYearAU', { state: { is_add: true } })
  }

  // update list 
  handleUpdate(finYearId) {
    this.props.navigate('/finYearAU', { state: { is_update: true, finYearId: finYearId } })
  }

  // delete list 
  handleDelete(e, finYearId) {
    // this.props.navigate('/finYearAU', { state: { is_delete: true , finYearId : finYearId } })
    e.preventDefault()
    var id = finYearId
    this.setState({ isLoading: true })
    this.props
      .deleteFinancialYearList_action(id)
      .then((data) => {
        if (data.error != 1) {
          toast.success('Data deleted successfully', {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
          this.setState({ isOpen: !this.state.isOpen, isLoading: false })
          this.getFinYearList()
        }
        else {
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  //toggle model for delete.................................
  toggleModal(finYearId) {
    const { isOpen, finYearDeleteId } = this.state
    this.setState({ isOpen: !isOpen })
    if (finYearId == '') {
      return;
    } else {
      this.setState({ finYearDeleteId: finYearId })
    }

  }

  updateItem(item) {
    this.setState({ currentPage: item });
  }
  updateRecordsPerPage = (newSize) => {
    const parsedSize = parseInt(newSize, 10);
    if (!isNaN(parsedSize) && parsedSize > 0) {
      this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
    }
    this.setState({ pageSizeInput: newSize }); // Keep the input value updated
  };
  
  handlePageSizeInputChange = (event) => {
    const input = event.target.value;
    if (/^\d*$/.test(input)) { // Only allow digits
      this.setState({ pageSizeInput: input });
    }
  };
  record(financialYearList) {
    return (
      <table className="table table-bordered tb-action du-table">
        <thead>
          <tr>
            <th style={{ width: '60px' }} />
            <th style={{ textAlign: 'left' }} onClick={this.financialYearNameSort}>Financial Year Name</th>
            <th style={{ width: '150px' }} onClick={this.shortNameSort}>Shortname</th>
            <th style={{ width: '180px' }} onClick={this.periodSort}>Period</th>
            <th style={{ width: '100px' }} onClick={this.statusSort}>Status</th>
          </tr>
        </thead>
        <tbody>
          {financialYearList.length > 0 ? (
            financialYearList.map((data, i) => (
              <tr key={data.FinYearID}>
                <td className="text-center" style={{ padding: '5px!important' }}>
                  <div className="table__button-group">
                    {getModuleUpdateAccess(Master)?
                    <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleUpdate(data.FinYearID)} /></a>:
                    <a><i className="fa fa-eye" onClick={() => this.handleUpdate(data.FinYearID)} /></a>
          }
                    {data.isActive === 1 && getModuleDeleteAccess(Master) ? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.FinYearID)} /></span></a>
                      : <></>}

                  </div>
                </td>
                <td style={{ textAlign: 'left' }}>{data.FinancialYear}</td>
                <td>{data.Code}</td>
                <td style={{ textAlign: 'center' }} className="period-date">
                  <ul>
                    <li style={{ position: 'relative' }}>{moment(data.FromDate).format('DD-MM-YYYY')} <div className="sep" /></li>
                    <li style={{ marginLeft: '20px' }}>{moment(data.ToDate).format('DD-MM-YYYY')} </li>
                  </ul>
                </td>
                <td style={{ textAlign: 'center' }}>{data.isActive === 1 ? "Active" : "Inactive"}</td>
              </tr>
            ))

          ) : (<tr><td colSpan={5} className='text-center'>Record not found</td></tr>)}
        </tbody>
      </table>
    )
  }

  onSearchTabClick() {
    const { isSearchTab, } = this.state;
    console.log("onSearchTabClick", isSearchTab)
    this.setState({ isSearchTab: !isSearchTab })
  }

  render() {

    const { isSearchTab, isAccessToView, financialYearList,
       tablePerPage,  isOpen, finYearDeleteId, isLoading, financialYearName, status, shortName } = this.state
    // const recordsPerPage = 10
    const { currentPage, recordsPerPage, pageSizeInput } = this.state;

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    // Records to be displayed on the current page
    const currentRecords = financialYearList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(financialYearList.length / recordsPerPage)


    return (
      <>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          null
        )}
        <Header />


        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper">
            {/* Left side menu */}
            <Drawer />
            {/* end left menu */}
            {/* start page */}
            {isAccessToView ? (
              <div className="main-panel main-box">
                <div className="content-wrapper">
                  <div className="main-breadcum">
                    <ul className="breadcrumb">
                      <li><a>Financial Year </a></li>
                      <li />
                    </ul>
                  </div>

            <div className='page-space-from-bottom'>
                  <div className="row">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                    <div className="col-md-12 pg-body grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body pr-2">

                          <div className="accordion accordion-flush " id="accordionFlushExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                  <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                </button>
                              </h2>
                             { isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                  <div className="d-flex flex-wrap row">
                                    <div className="form-group col-md-3">
                                      <label>Financial Year Name</label>
                                      <input type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={financialYearName}
                                        onChange={(e) => this.setState({ financialYearName: e.target.value })}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        required />
                                    </div>
                                    <div className="form-group col-md-3">
                                      <label>Shortname</label>
                                      <input type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={shortName}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ shortName: e.target.value })}
                                        required />
                                    </div>
                                    <div className="form-group col-md-3">
                                      <label>Status</label>
                                      <select className="js-example-basic-single" style={{ width: '100%' }}
                                        value={status}
                                        onChange={(e) => this.setState({ status: e.target.value })}>
                                        <option value={''}>Select</option>
                                        <option value={1}>Active</option>
                                        <option value={0}>Inactive</option>
                                      </select>
                                    </div>
                                    <div className="form-group col-md-3 filter-btn-col p-0">
                                      <label></label>
                                      <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={e => this.handleSearch()}> Search</button>
                                      <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3" onClick={e => this.clearValue()}> Clear</button>
                                    </div>
                                  </div>
                                </div>
                              </div> : <></>}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 mt-1">
                             {getModuleWriteAccess(Master)&& <button type="button" className="btn btn-Add rounded-1 mb-1 py-2 px-3 float-right"
                                 onClick={this.handleAdd}> Add</button>}
                              <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                {this.record(currentRecords)}
                                {financialYearList.length > 0 ? <Pagination
                                  nPages={nPages}
                                  currentPage={currentPage}
                                  setCurrentPage={this.updateItem}
                                  recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                   setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                     totalRecords={financialYearList.length}
                                   /> : <></>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>

                </div>
              </div>
            ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)}
            {/* end page */}
          </div>
          {/* page-body-wrapper ends */}
        </div>


        <ToastContainer />
        {/* // modal for delete */}
        {/* <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
          <div className="modal-dialog custom_modal_dialog">
            <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
              <div class="modal-header pt-1 bg-danger">
                <h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3>
                  </div>
                </div>
              </div>

              <div class="modal-footer p-1">
                  <div class="form-group text-right">
                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, finYearDeleteId)}> Yes</a>
                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                </div>
              </div>

            </div>
          </div>
        </Modal> */}

<Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
          <div className="modal-dialog custom_modal_dialog">
            <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
              <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                </div>
              </div>
              <div class="modal-footer p-1">
                  <div class="form-group text-right">
                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, finYearDeleteId)}> Yes</a>
                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(connect(null, { financialYearList_action, deleteFinancialYearList_action })(FinancialYearList));