import { axiosGet, axiosPost } from "../../react_axios/Axios"
import moment from "moment/moment"
import { headers, holidayListUrl, HOLIDAY_LIST_ACTION_TYPE, addHolidayUrl, HOLIDAY_ADD_ACTION_TYPE, updateHolidayUrl, HOLIDAY_UPDATE_ACTION_TYPE, holidayDetailsUrl, HOLIDAY_DETAILS_ACTION_TYPE, deleteHolidayUrl, HOLIDAY_DETETE_ACTION_TYPE, platfarm } from "../../utils/constants"

export const holidayList_action = (holidayDate, holidayName, holidayType, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            HolidayDate: holidayDate != null && holidayDate != '' ? moment(holidayDate).format('YYYY-MM-DD') : '',
            Name: holidayName,
            HolidayType: holidayType,
            Status: status
        }
        console.log(param)
        const res = await axiosGet(holidayListUrl, param)
        console.log(res)
        dispatch({
            type: HOLIDAY_LIST_ACTION_TYPE,
            payload: res
        })

        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const holidayDetail_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id
        }
        const res = await axiosGet(holidayDetailsUrl, param)
        dispatch({
            type: HOLIDAY_DETAILS_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const addHoliday_action = (holidayDate, holidayName, holidayType) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            HolidayDate: new Date(moment(holidayDate).format('YYYY-MM-DD')),
            Holidaydesc: holidayName,
            HolidayType: holidayType
        }
        const res = await axiosPost
            (addHolidayUrl, mapdata, headers)
        dispatch({
            type: HOLIDAY_ADD_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const updateHoliday_action = (id, holidayDate, holidayName, holidayType) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
            HolidayDate: new Date(moment(holidayDate).format('YYYY-MM-DD')),
            Name: holidayName,
            HolidayType: holidayType,
        }
        const res = await axiosPost(updateHolidayUrl, mapdata, headers)
        dispatch({
            type: HOLIDAY_UPDATE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const deleteHoliday_action = (id) =>
    async (dispatch) => {
        try {
            const param = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                ID: id,
            }
            console.log(param)
            const res = await axiosGet
                (deleteHolidayUrl, param)
            dispatch({
                type: HOLIDAY_DETETE_ACTION_TYPE,
                payload: res
            })
            return Promise.resolve(res)
        } catch (err) {
            return Promise.reject(err)
        }
    }

export default (state = {}, action) => {
    switch (action.type) {
        case 'SIMPLE_ACTION':
            return {
                result: action.payload
            }
        default:
            return state
    }
}