import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import {
    prefixList_action, prefixAdd_action, prefixDelete_action,
    prefixDetails_action, prefixUpdate_action
} from '../../../actions/master_action/prefix_action'
import { fill_action } from '../../../actions/master_action/checklist_action'

import Pagination from "../../../utils/pagination";

import { clearLocalStorage, Master, prefixForActionId } from '../../../utils/constants'
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

class Prefix extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            isOpen: false,
            status: ' ',
            isLoading: false,
            IsActive: 0,
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage : 10,
            pageSizeInput: "10", 
            prefixName: '',
            prefixNameDLS: '',
            prefixList: [],
            prefixForList: [],
            prefixId: '',
            _isAdd: false,
            _isUpdate: false,
            _isDelete: false,
            search_prefixName: '',
            isSearchTab: false,
            IsActiveAdd: false,
        }
        this.getprefixDetails = this.getprefixDetails.bind(this)
        this.getprefixList = this.getprefixList.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);  
		
        this.toggleModal = this.toggleModal.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.toggleModalAdd = this.toggleModalAdd.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.toggleModalUpdate = this.toggleModalUpdate.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.getFillList = this.getFillList.bind(this)
    }


    componentDidMount = () => {
        this.setState({
            isAccessToView: true,
            prefixNameDLS: localStorage.getItem('prefixNameDLS'),
            statusDLS: localStorage.getItem('statusDLS'),

            prefixName: localStorage.getItem('prefixNameDLS') != '' ? localStorage.getItem('prefixNameDLS') : '',
            status: localStorage.getItem('statusDLS') != 'Select' ? localStorage.getItem('statusDLS') : 'Select',
        })
        this.getFillList(prefixForActionId, '')
        this.clearValue()
        clearLocalStorage("prefix")
        setTimeout(() => {
            localStorage.getItem('statusDLS') == 'Select' ? this.getprefixList() : <></>
        }, 100);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }

    //Fill list
    getFillList(value, ref, ref2) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == prefixForActionId) {
                        this.setState({ prefixForList: data.data })
                        setTimeout(() => {
                            console.log(this.state.prefixForList)
                            this.setState({ isLoading: false })
                        }, 500);

                    }
                }
            })
    }
    
    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
          const focusedElement = document.activeElement;
          const { previouslyFocusedElement } = this.state;
    
          if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
          }
    
          if (focusedElement.classList.contains('form-control') ||
            focusedElement.classList.contains('js-example-basic-single') ||
            focusedElement.classList.contains('example-custom-input')) {
            focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
            this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
          }
        }
      };
    
    
      handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;
    
        if (previouslyFocusedElement) {
          previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }
    
        if (clickedElement.classList.contains('form-control') ||
          clickedElement.classList.contains('js-example-basic-single') ||
          clickedElement.classList.contains('example-custom-input')) {
          clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
          this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
          this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
      };
    

    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    getprefixList() {
        const { search_prefixName, status } = this.state
        clearLocalStorage("prefix")
        this.setState({ isLoading: true })
        this.props
            .prefixList_action(search_prefixName, status === 'Select' ? 1 : status)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ prefixList: data.data, isLoading: false, currentPage: 1 })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

        localStorage.setItem("prefixNameDLS", search_prefixName)
        localStorage.setItem("statusDLS", status === 'Select' ? 1 : status)
    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }
    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
          this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
      };
      
      handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
          this.setState({ pageSizeInput: input });
        }
      };

    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.getprefixList()
        }
    }
    clearValue() {
        this.setState({
            search_prefixName: '',
            prefixName: '',
            prefixFor: '',
            status: ' ',
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getprefixList()
        }, 300);
    }

    // toggleModal for delete
    toggleModal(prefixId) {
        const { isOpen, prefixDeleteId } = this.state
        this.setState({ isOpen: !isOpen })
        this.state._isDelete = true
        this.state._isAdd = false
        this.state._isUpdate = false
        if (prefixId == '') {
            return;
        } else {
            this.setState({ prefixId: prefixId })
        }

    }
    // delete prefix
    handleDelete(e) {
        e.preventDefault()
        const { isOpen, prefixName } = this.state
        var id = this.state.prefixId
        this.setState({ isLoading: true })
        this.props
            .prefixDelete_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isLoading: false, isOpen: !isOpen })
                    this.getprefixList()
                    this.handleCancel()
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    handleCancel() {
        this.state.isOpen = false
        this.clearValue()
    }

    getprefixDetails(prefixId) {
        const { prefixName } = this.state
        var id = prefixId
        this.setState({ isLoading: false })
        this.props
            .prefixDetails_action(id)
            .then((data) => {
                if (data.error != 1) {

                    this.setState({
                        prefixName: data.data[0]['Prefix'],
                        prefixFor: data.data[0]['PrefixFor'],
                        prefixId: data.data[0]['PrefixID'],

                        isActive: data.data[0]['IsActive'],
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // prefix add
    handleAdd(e) {
        e.preventDefault()
        const { prefixName, prefixFor } = this.state
        if (prefixName != "" && prefixFor != "") {
            this.setState({ isLoading: true })

            this.props
                .prefixAdd_action(prefixName.trim(), prefixFor)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.clearValue()
                        this.setState({ isLoading: false })
                        this.handleCancel()
                        this.getprefixList()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }



    }



    // modal for add
    toggleModalAdd(prefixId) {
        const { isOpen, prefixName } = this.state
        this.setState({ isOpen: !isOpen, prefixName: "", prefixFor: "", _isAdd: true })
        this.state._isAdd = true
        this.state._isUpdate = false
        this.state._isDelete = false
    }

    // Modal for Update
    toggleModalUpdate(prefixId) {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen, _isUpdate: true })
        this.state._isUpdate = true
        this.state._isAdd = false
        this.state._isDelete = false
        this.getprefixDetails(prefixId)
        if (prefixId == '') {
            return;
        } else {
            this.setState({ prefixId: prefixId })
        }
    }

    // update prefix  
    handleUpdate(e) {
        e.preventDefault()
        const { prefixName, prefixFor } = this.state
        const id = this.state.prefixId;

        if (id != "" && prefixName != "" && prefixFor != "") {
            this.setState({ isLoading: true })
            this.props
                .prefixUpdate_action(id, prefixName.trim(), prefixFor)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false })
                        this.handleCancel()
                        this.getprefixList()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }
    record(prefixList) {
        return (
            <table className="table table-bordered tb-action du-table -50">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ textAlign: 'left' }} onClick={() => this.prefixSort()}>Prefix</th>
                        <th style={{ textAlign: 'left' }} onClick={() => this.prefixSort()}>Prefix For</th>
                        {/* <th style={{ width: '100px' }} onClick={() => this.statusSort()}>Status</th> */}
                    </tr>
                </thead>
                <tbody>
                    {prefixList.length > 0 ? (
                        prefixList.map((data, i) => (
                            <tr key={data.prefixID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        {getModuleUpdateAccess(Master) ?
                                            <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.toggleModalUpdate(data.PrefixID)} /></a> :
                                            <a><i className="fa fa-eye" onClick={() => this.toggleModalUpdate(data.PrefixID)} /></a>}
                                        {getModuleDeleteAccess(Master) && data.IsActive == 1 ? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.PrefixID)} /></span></a>
                                            : <></>}
                                    </div>
                                </td>
                                <td style={{ textAlign: 'left' }} onClick={() => this.prefixSort()} >{data.Prefix}</td>
                                <td style={{ textAlign: 'left' }} onClick={() => this.prefixSort()} >{data.PrefixFor}</td>

                                {/* <td style={{ textAlign: 'center' }}>{data.IsActive ? "Active" : "Inactive"}</td> */}
                            </tr>
                        ))

                    ) : (<tr><td colSpan={5} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }

    render() {
        const { isSearchTab, isAccessToView, isOpen, isLoading, status, prefixName, prefixList,
            _isAdd, _isUpdate, _isDelete,  search_prefixName, prefixFor, prefixForList, IsActiveAdd } = this.state
            const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = prefixList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(prefixList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />


                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>Prefix</a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className='row m-0'>
                                        <div className='col-md-12 p-2 pb-0 pt-0'>
                                            {getModuleWriteAccess(Master) && <button type="button" className="btn btn-Add rounded-1 px-3 mb-1 w-auto float-right" onClick={(e) => this.toggleModalAdd(e)}> Add</button>}
                                        </div>
                                    </div>
                                    <div className='page-space-from-bottom'>
                                        <div className="row">
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n.accordion-button::after {\nbackground-size: 13px;\ndisplay: none;\n} \n" }} />
                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body pr-2 pt-0">
                                                        <div className="row">

                                                            <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                                {this.record(currentRecords)}
                                                                {prefixList.length > 0 ? <Pagination
                                                                    nPages={nPages}
                                                                    currentPage={currentPage}
                                                                    setCurrentPage={this.updateItem}
                                                                    recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                     setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                       totalRecords={prefixList.length}
                                                                     /> : <></>}                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>


                <ToastContainer />
                {/* // modal for delete */}
                {/* <Modal isOpen={isOpen && _isDelete} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}

                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right">
                                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e)}> Yes</a>
                                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* Modal for add and update */}
                <Modal isOpen={(isOpen && _isAdd) || (isOpen && _isUpdate)} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Prefix</h4>
                                {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div className='row'>
                                    <div class="form-group col-md-6">
                                        <label>Prefix<span class="required1">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={prefixName}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                // Limit the input to 100 characters
                                                if (inputValue.length <= 100) {
                                                    this.setState({ prefixName: inputValue });
                                                }
                                            }}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Prefix For<span class="required1">*</span></label>
                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                            value={prefixFor}
                                            onChange={(e) => this.setState({ prefixFor: e.target.value, })}>
                                            <option value={''}>Select</option>
                                            {prefixForList.length > 0 && prefixForList.map(data => (
                                                <option value={data.Code
                                                } key={data.Codes
                                                }>{data.Description}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/* <div class="form-group col-md-2 mt-3  d-flex">
                                                        <input type="checkbox" checked={IsActiveAdd}  onClick={(e) => this.setState({ IsActiveAdd: !IsActiveAdd })} />
                                                        <label className='mx-2 mt-2'> Is Active <span class="required1">*</span></label>

                                                    </div> */}
                            </div>

                            <div class="modal-footer p-2 pt-0 pb-0">
                                <div class="form-group text-left mod-fot m-2">
                                    <div
                                        className="btn-group"
                                        role="group"
                                        aria-label="Basic example"
                                    >
                                        {
                                            this.state._isAdd && <button
                                                type="button"
                                                className="btn btn-save footer-btn-inner mr-1"
                                                onClick={(e) => this.handleAdd(e)}
                                            >
                                                Save
                                            </button>
                                        }

                                        {
                                            this.state._isUpdate &&
                                            <button
                                                type="button"
                                                className="btn btn-update footer-btn-inner mr-1"
                                                onClick={(e) => this.handleUpdate(e)}
                                            >
                                                Update
                                            </button>
                                        }
                                        {
                                            this.state._isAdd &&
                                            <button
                                                type="button"
                                                className="btn btn-update footer-btn-inner mr-1"
                                                onClick={() => this.clearValue()}
                                            >
                                                Reset
                                            </button>
                                        }
                                        <button
                                            type="button"
                                            className="btn btn-cancel footer-btn-inner "
                                            onClick={this.handleCancel}
                                        >
                                            Cancel
                                        </button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(connect(null, { fill_action, prefixList_action, prefixAdd_action, prefixDelete_action, prefixDetails_action, prefixUpdate_action })(Prefix));