import { axiosPost, axiosGet } from "../../react_axios/Axios"
import { managementTypeListUrl, managementTypeDetailsUrl, managementTypeAddUrl, managementTypeUpdateUrl, managementTypeDeleteUrl, MANAGEMENTTYPE_LIST_ACTION_TYPE, MANAGEMENTTYPE_DETAILS_ACTION_TYPE, MANAGEMENTTYPE_ADD_ACTION_TYPE, MANAGEMENTTYPE_UPDATE_ACTION_TYPE, MANAGEMENTTYPE_DELETE_ACTION_TYPE, platfarm, headers } from '../../utils/constants'

const user_id = localStorage.getItem('UserId')

export const managementTypeList_action = (memberType, clientCategoryID, status)=> async(dispatch) => {
    try{
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            MemberType: memberType != '' ? memberType : null,
            ClientCategoryID: clientCategoryID != '' ? clientCategoryID : null,
            Status:status
        }
        console.log("managementTypeList_action", param)
        const res = await axiosGet(managementTypeListUrl, param);
        dispatch({
            type: MANAGEMENTTYPE_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    }
    catch(err){
        return Promise.reject(err);
    }
}

export const managementTypeDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }        
        const res = await axiosGet(managementTypeDetailsUrl, param);
        dispatch({
            type: MANAGEMENTTYPE_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const managementTypeAdd_action = (clientCategoryID, memberType) => async(dispatch) => {
    try {
        const param = {
            UserID : localStorage.getItem('UserId'),
            Platform : platfarm,
            ClientCategoryID : clientCategoryID,
            MemberType : memberType,
        }
        const res = await axiosPost(managementTypeAddUrl, param, headers);
        dispatch({
            type: MANAGEMENTTYPE_ADD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);    
    } 
    catch(err){
        return Promise.reject(err);
    }
}

export const managementTypeUpdate_action = (id, clientCategoryID, memberType ) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID : id,
            ClientCategoryID: clientCategoryID,
            MemberType: memberType,
        }
        const res = await axiosPost(managementTypeUpdateUrl, mapdata, headers);
        dispatch({
            type: MANAGEMENTTYPE_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const managementTypeDelete_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }
        const res = await axiosGet(managementTypeDeleteUrl, param);
        dispatch({
            type: MANAGEMENTTYPE_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch (err) {
        return Promise.reject(err);
    }
};