import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { mcqChapterList_action, deletemcqChapter_action } from '../../../actions/mcq_action/mcq_chapter_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Modal from "react-modal";
import $ from 'jquery'
import Loader from '../../../utils/Loader'
import Pagination from '../../../utils/pagination'
import { clearLocalStorage, MCQ_Chapter } from '../../../utils/constants'
import { fill_action } from '../../../actions/master_action/checklist_action';
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';


// import ReactDOM from "https://cdn.skypack.dev/react-dom@17.0.1";
// import Pagination  from "https://cdn.skypack.dev/rc-pagination@3.1.15";
/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
// const mapStateToProps = state => ({
//   ...state
// })


class MCQChapterList extends Component {


  constructor(props) {
    super(props)
    this.state = {
      isAccessToView: true,
      mcqChapterListName: '',
      shortName: '',
      mcqChapterList: [],
      chapterList: [],
      chapter: '',
      status: '',
      isOpen: false,
      mcqChapterListDeleteId: '',
      currentPage: 1,
      tablePerPage: 10,
      recordsPerPage: 10,
      pageSizeInput: "10",
      isLoading: false,
      chapterMLS: '',
      statusIdMLS: '',

      ismcqChapterListNameSort: false,
      isEqSort: false,
      isShortNameSort: false,
      isStatusSort: false,
      isSearchTab: false,
      statusList: [],
      statusId: '',
    }
    this.handleAdd = this.handleAdd.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.handleClick = this.handleClick.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
    this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);

    this.record = this.record.bind(this)
    this.clearValue = this.clearValue.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)


  }


  handleKeyDown(e) {
    if (e.code === 'Enter') {
      this.getmcqChapterList()
    }
  }

  onSearchTabClick() {
    const { isSearchTab } = this.state;
    console.log("onSearchTabClick", isSearchTab)
    this.setState({ isSearchTab: !isSearchTab })
  }

  clearValue() {
    this.setState({
      chapter: '',
      statusId: '',
      status: '',

    })
    clearLocalStorage('')
    setTimeout(() => {
      this.getmcqChapterList()
    }, 300);

  }

  handleClick(event) {
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid
    });

  }

  componentDidMount = () => {
    console.log("1", localStorage.getItem('statusIdMLS'))

    this.setState({
      // isAccessToView: localStorage.getItem('UserId') == '1' ? true : false,
      chapterMLS: localStorage.getItem('chapterMLS'),
      statusIdMLS: localStorage.getItem('statusIdMLS'),


      chapter: localStorage.getItem('chapterMLS') != '' ? localStorage.getItem('chapterMLS') : '',
      status: localStorage.getItem('statusIdMLS') != '' ? localStorage.getItem('statusIdMLS') : '',

    })
    clearLocalStorage("chapter")

    setTimeout(() => {
      this.getmcqChapterList()
    }, 500);
    console.log("1", localStorage.getItem('statusIdMLS'))
    // document.addEventListener('keydown', this.handleTabKeyPress);
    // this.getFillList(chapterListActionId, '')
    // this.getFillList(mcqStatusActionId, '')
    // document.addEventListener('click', this.handleInputClick);

  }

  componentWillUnmount() {
    // document.removeEventListener('keydown', this.handleTabKeyPress);
    // document.removeEventListener('click', this.handleInputClick);
  }

  // get fill list 
  getFillList(value, ref) {
    this.setState({ isLoading: true })
    this.props
      .fill_action(value, ref)
      .then((data) => {
        if (data.error != 1) {

          //   if (value == mcqStatusActionId) {
          //     this.setState({ statusList : data.data, isLoading: false })
          // }

        }
        else {
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }

  handleTabKeyPress = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
      const focusedElement = document.activeElement;
      const { previouslyFocusedElement } = this.state;

      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }

      if (focusedElement.classList.contains('form-control') ||
        focusedElement.classList.contains('js-example-basic-single') ||
        focusedElement.classList.contains('example-custom-input')) {
        focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
        this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
      }
    }
  };


  handleInputClick = (event) => {
    const { previouslyFocusedElement } = this.state;
    const clickedElement = event.target;

    if (previouslyFocusedElement) {
      previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    }

    if (clickedElement.classList.contains('form-control') ||
      clickedElement.classList.contains('js-example-basic-single') ||
      clickedElement.classList.contains('example-custom-input')) {
      clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
      this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
    } else {
      this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
    }
  };

  // get check list default
  getmcqChapterList() {
    const { status, chapter } = this.state
    clearLocalStorage("chapter")
    this.setState({ isLoading: true })
    this.props
      .mcqChapterList_action(status, chapter.trim())
      .then((data) => {
        console.log(data);
        if (data.error != 1) {
          this.setState({
            mcqChapterList: data.data,
            isLoading: false
            , currentPage: 1,
          })

        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

    localStorage.setItem("chapterMLS", chapter)
    localStorage.setItem("statusIdMLS", status)

  }

  // add list 
  handleAdd() {
    this.props.navigate('/mcqchapterAU', { state: { is_add: true } })
  }

  // update list
  handleUpdate(mcqChapterListId) {
    this.props.navigate('/mcqchapterAU', { state: { is_update: true, chapterID: mcqChapterListId } })
  }

  // delete list
  handleDelete(e, deleteId) {
    e.preventDefault()
    const { mcqChapterList } = this.state
    var id = deleteId
    this.setState({ isLoading: true })
    this.props
      .deletemcqChapter_action(id)
      .then((data) => {
        if (data.error != 1) {
          toast.success('Data deleted successfully', {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
          this.setState({ isOpen: !this.state.isOpen, isLoading: false })
          this.getmcqChapterList()
          this.record(mcqChapterList)
        }
        else {
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  //toggle model for delete.................................
  toggleModal(mcqChapterListId) {
    const { isOpen, mcqChapterListDeleteId } = this.state
    this.setState({ isOpen: !isOpen })
    if (mcqChapterListId == '') {
      return;
    } else {
      this.setState({ mcqChapterListDeleteId: mcqChapterListId })
    }

  }
  updateItem(item) {
    this.setState({ currentPage: item });
  }

  updateRecordsPerPage = (newSize) => {
    const parsedSize = parseInt(newSize, 10);
    if (!isNaN(parsedSize) && parsedSize > 0) {
      this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
    }
    this.setState({ pageSizeInput: newSize }); // Keep the input value updated
  };

  handlePageSizeInputChange = (event) => {
    const input = event.target.value;
    if (/^\d*$/.test(input)) { // Only allow digits
      this.setState({ pageSizeInput: input });
    }
  };

  record(mcqChapterList) {
    return (
      <table className="table table-bordered tb-action du-table">
        <thead>
          <tr>
            <th style={{ width: '60px' }} />
            <th style={{ textAlign: 'left' }}>Chapter </th>
            <th style={{ width: '100px' }}>Status</th>
          </tr>
        </thead>
        <tbody>
          {mcqChapterList.length > 0 ?
            mcqChapterList.map((data, index) => (

              <tr>
                <td className="text-center" style={{ padding: '5px!important' }}>
                  <div className="table__button-group">
                    {getModuleUpdateAccess(MCQ_Chapter) ?
                      <a> <i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleUpdate(data.ChapterID)} /></a> :
                      <a> <i className="fa fa-eye" onClick={() => this.handleUpdate(data.ChapterID)} /></a>}
                    {data.IsActive && data.isUsed != 1 && getModuleDeleteAccess(MCQ_Chapter) ? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.ChapterID)} /></span></a>
                      : <></>}
                  </div>
                </td>
                <td style={{ textAlign: 'left' }}>{data.ChapterName}</td>
                <td>{data.IsActive ? "Active" : "Inactive"}</td>
              </tr>
            )) : <> <tr><td colSpan={5} className='text-center'>Record not found</td></tr></>
          }
        </tbody>
      </table>
    )
  }



  render() {
    const { isSearchTab, isAccessToView, mcqChapterList, tablePerPage, isLoading,
      upperPageBound, mcqChapterListName, shortName, status, chapter,
      chapterList, eq, mcqChapterListDeleteId, isOpen } = this.state
    const { currentPage, recordsPerPage, pageSizeInput } = this.state;

    // const recordsPerPage = 10
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    // Records to be displayed on the current page
    const currentRecords = mcqChapterList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(mcqChapterList.length / recordsPerPage)



    return (
      <>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          null
        )}
        <Header />
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper">
            {/* Left side menu */}
            <Drawer />
            {/* end left menu */}
            {/* start page */}
            {isAccessToView ? (
              <div className="main-panel main-box">
                <div className="content-wrapper">
                  <div className="main-breadcum">
                    <ul className="breadcrumb">
                      <li><a href="#">MCQ Chapter Master</a></li>
                      <li />
                    </ul>
                  </div>
                  <div className='page-space-from-bottom'>
                    <div className="row">
                      <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                      <div className="col-md-12 pg-body grid-margin stretch-card">
                        <div className="card">
                          <div className="card-body pr-2">



                            <div className="accordion accordion-flush " id="accordionFlushExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                  <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                    <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                  </button>
                                </h2>
                                {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                              <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                  <div className="d-flex flex-wrap row">

                                    <div className="form-group col-md-3">
                                      <label>Chapter</label>
                                      <input type="text" class="form-control"
                                        value={chapter} onChange={(e) => this.setState({ chapter: e.target.value })} />
                                    </div>
                                    <div className="form-group col-md-3">
                                      <label>Status</label>
                                      <select className="js-example-basic-single"
                                        style={{ width: '100%' }}
                                        value={this.state.status}
                                        onChange={(e) => this.setState({ status: e.target.value })}>
                                        <option value={''}>Select</option>
                                        <option value={1}>Active</option>
                                        <option value={0}>Inactive</option>
                                      </select>
                                    </div>

                                    <div className="form-group col-md-3 filter-btn-col">
                                      <label></label>
                                      <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={e => this.getmcqChapterList()}> Search</button>
                                      <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2" onClick={e => this.clearValue()}> Clear</button>
                                    </div>
                                  </div>
                                </div>
                                </div> : <></>}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12">
                                {getModuleWriteAccess(MCQ_Chapter) && <button type="button" className="btn btn-Add rounded-1 m-1 mb-1 px-3 float-right" onClick={this.handleAdd}> Add</button>}
                                <div className="table-responsive tbs4 common-table trail-tb " style={{ width: '100%' }}>
                                  {this.record(currentRecords)}
                                  {mcqChapterList.length > 0 ? <Pagination
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={this.updateItem}
                                    recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                    setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                    totalRecords={mcqChapterList.length}
                                  /> : <></>}


                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (<div className='access-msg h-auto'>
              <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
            </div>)}
            {/* end page */}
          </div>
          {/* page-body-wrapper ends */}
        </div >

        <ToastContainer />
        {/* // modal for delete */}
        {/* <Modal
      isOpen={isOpen}
      onRequestClose={this.toggleModal}>
      <div className="modal-dialog custom_modal_dialog">
        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
          <div class="modal-header">
            <h4 class="modal-title modal_title_text">Confirm Delete</h4>
            <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <h3 class="pop_label">Do you really want to delete this record?</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group text-right mod-fot">
                <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, mcqChapterListDeleteId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </Modal> */}
        <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
          <div className="modal-dialog custom_modal_dialog">
            <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
              <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                </div>
              </div>
              <div class="modal-footer p-1">
                <div class="form-group text-right">
                  <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, mcqChapterListDeleteId)}> Yes</a>
                  <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                </div>
              </div>
            </div>
          </div>
        </Modal>

      </>
    );
  }
}



export default withRouter(connect(null, { mcqChapterList_action, fill_action, deletemcqChapter_action })(MCQChapterList));