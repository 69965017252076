
import {
    APPROVAL_LIST_ACTION_TYPE,
    APPROVAL_DETAILS_ACTION_TYPE,
    APPROVAL_REJECT_ACTION_TYPE,
} from "../../utils/constants"


const initialState = [];

function approvalReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case APPROVAL_LIST_ACTION_TYPE:
            return [...state, payload];
        case APPROVAL_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case APPROVAL_REJECT_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
};

export default approvalReducer;

