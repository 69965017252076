
import { MANAGEMENTTYPE_ADD_ACTION_TYPE, MANAGEMENTTYPE_DELETE_ACTION_TYPE, MANAGEMENTTYPE_DETAILS_ACTION_TYPE, MANAGEMENTTYPE_LIST_ACTION_TYPE, MANAGEMENTTYPE_UPDATE_ACTION_TYPE } from "../../utils/constants";

const initialState = []

function managementTypeReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case MANAGEMENTTYPE_LIST_ACTION_TYPE:
            return [...state, payload];
        case MANAGEMENTTYPE_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case MANAGEMENTTYPE_ADD_ACTION_TYPE:
            return [...state, payload];
        case MANAGEMENTTYPE_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case MANAGEMENTTYPE_DELETE_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}

export default managementTypeReducer