
import { LEAVEAPPROVAL_LIST_ACTION_TYPE, LEAVEAPPROVAL_DETAILS_ACTION_TYPE, LEAVEAPPROVAL_STATUS_ACTION_TYPE, LEAVEAPPROVAL_REJECT_ACTION_TYPE } from "../../utils/constants";

const initialState = []

function leaveApprovalReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case LEAVEAPPROVAL_LIST_ACTION_TYPE:
            return [...state, payload];
        case LEAVEAPPROVAL_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case LEAVEAPPROVAL_STATUS_ACTION_TYPE:
            return [...state, payload];
        case LEAVEAPPROVAL_REJECT_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}

export default leaveApprovalReducer