import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../../common_components/Header';
import Drawer from '../../../../common_components/Drawer';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../../utils/withRouter';
import Loader from '../../../../utils/Loader'
import ClientMainAU from './client_main'
import ClientAddressAU from './client_address'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ClientMasterAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAccessToView: true,
            is_add: false,
            is_update: false,
            ClientID: '',
            clientTypeId: '',
        }
        this.handleCancel = this.handleCancel.bind(this)
        this.getSessionData = this.getSessionData.bind(this)

    }
    componentDidMount = () => {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            ClientID: this.props.location.state != null && this.props.location.state.ClientID != null ? this.props.location.state.ClientID : '',
            isAccessToView: true,
            clientTypeId: this.props.location.state != null && this.props.location.state.clientTypeId != null ? this.props.location.state.clientTypeId : '',

        })
        toast.dismiss();
        setTimeout(() => {
            console.log("MOUNT - id type", this.state.ClientID, this.state.clientTypeId)
            console.log("MOUNT- SEESSION", sessionStorage.getItem("ClientMaster"))

        }, 500);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };


    handleCancel() {
        this.props.navigate('/clientList')
    }
    getSessionData() {
        this.setState({
            ClientID: sessionStorage.getItem("ClientMaster")
        })
        setTimeout(() => {
            console.log("GETDATA ID", this.state.ClientID)
        }, 500);
    }


    render() {
        const { isLoading, isAccessToView, ClientID, is_update } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        <Drawer />
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                {
                                    <div className="content-wrapper">
                                        <div className="main-breadcum">
                                            <ul className="breadcrumb">
                                                <li>
                                                    <a onClick={this.handleCancel} >Client Master</a>
                                                </li>
                                                <li>
                                                    <a >{this.state.is_add ? 'New' : 'Update'}</a>
                                                </li>
                                                <li />
                                            </ul>
                                        </div>

                                        <div className="mob-tab">
                                            <div className="mob-wd1">
                                                <ul
                                                    className="nav nav-tabs master-tab"
                                                    id="myTab"
                                                    role="tablist"
                                                >
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className="nav-link active"
                                                            id="client-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#client"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="client"
                                                            aria-selected="true"
                                                        >
                                                            Client
                                                        </button>
                                                    </li>
                                                    {
                                                        is_update ? <>
                                                            <li className="nav-item" role="presentation">
                                                                <button
                                                                    className="nav-link"
                                                                    id="address-tab"
                                                                    data-bs-toggle="tab"
                                                                    data-bs-target="#address"
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls="address"
                                                                    aria-selected="false"
                                                                    disabled={ClientID == '' || ClientID == null ? true : false}
                                                                >
                                                                    Address/Communication
                                                                </button>
                                                            </li>
                                                            {/*   <li className="nav-item" role="presentation">
                                                        <button
                                                            className="nav-link"
                                                            id="other-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#other"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="other"
                                                            aria-selected="false"
                                                        >
                                                            Other Details
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className="nav-link"
                                                            id="location-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#location"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="location"
                                                            aria-selected="false"
                                                        >
                                                            Location Details
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className="nav-link"
                                                            id="management-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#management"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="management"
                                                            aria-selected="false"
                                                        >
                                                            Management Details
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className="nav-link"
                                                            id="documents-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#documents"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="documents"
                                                            aria-selected="false"
                                                        >
                                                            Documents
                                                        </button>
                                                    </li> */}
                                                        </> : <></>
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                        {/*  tab body */}
                                        <div className=' page-scroll-from-bot'>
                                            <div className="col-md-12 mb-3 stretch-card">
                                                <div
                                                    className=""
                                                    style={{ background: "rtransparent", width: "100%" }}
                                                >
                                                    <div
                                                        id="div-height1"
                                                        className=""
                                                        style={{ background: "rgb(255, 255, 255)", height: '435px' }}
                                                    >

                                                        {/* // tab body start*/}
                                                        <div
                                                            className="tab-content"
                                                            id="myTabContent"
                                                            style={{ background: "#fff", padding: "5px 4px !important" }}
                                                        >
                                                            <div
                                                                className="tab-pane fade active show"
                                                                id="client"
                                                                role="tabpanel"
                                                                aria-labelledby="docking-details-tab"
                                                            >
                                                                <div className="d-flex flex-wrap row">
                                                                    <div className="form-group col-12 ">
                                                                        <ClientMainAU masterRemount={this.getSessionData} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*start unloading details 2 tab*/}
                                                            <div
                                                                className="tab-pane fade div-height12"
                                                                id="address"
                                                                role="tabpanel"
                                                                aria-labelledby="unloading-details-tab"
                                                            >
                                                                <div className="d-flex flex-wrap row">
                                                                    <div className="form-group col-12 ">
                                                                        <ClientAddressAU />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*end unloading details 2 tab*/}
                                                        </div>
                                                        {/* // tab body end*/}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/* end
   */}
                            </div>)
                            : (<div className='access-msg h-auto'>
                                <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                            </div>)}
                    </div>
                </div>
                <ToastContainer />
            </>
        )
    }
}

export default withRouter(connect()(ClientMasterAU))