import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import {
  checkListTemplate_action,
  checkListTemplateDetails_action,
  addcheckListTemplate_action,
  updatecheckListTemplate_action,
  downloadTemplate_action,
  deletecheckListTemplate_action
} from '../../../actions/master_action/checklist_template_action'
import { fill_action } from '../../../actions/master_action/checklist_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from "react-router-dom";
import { withRouter } from '../../../utils/withRouter';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from '../../../utils/Loader'
import { checklistnameActionId, typeOfEngagementActionId, clientEngagementNewChecklistActionId, downloadReport, checklistReportName, Master } from '../../../utils/constants'
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
// const mapStateToProps = state => ({
//   ...state
// })

class ChecklistTemplateAU extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkListTemplateName: '',
      is_add: false,
      is_delete: false,
      is_update: false,
      templateId: '',
      checkListData: [],
      tempCheckListData: [],
      typeOfEngagementId: 'Select',
      typeOfEngagementList: [],
      is_critical: false,
      isLoading: false,
      isActive: 0,
      isAccessToView: true,
      newChecklistNameId: '',
      newChecklistNameList: [],
      tempNewChecklistNameList: [],
      isAnyCompleted: false,
      newChecklistNameEQ: '',
    }
    this.handleAdd = this.handleAdd.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleIscritical = this.handleIscritical.bind(this)
    this.handleActionId = this.handleActionId.bind(this)
    this.getCheckList = this.getCheckList.bind(this)
    this.getTemplateDetails = this.getTemplateDetails.bind(this)
    this.getTypeOfEngagement = this.getTypeOfEngagement.bind(this)
    this.onSelectTypeOfEngagement = this.onSelectTypeOfEngagement.bind(this)
    this.onSelectNewChecklistName = this.onSelectNewChecklistName.bind(this)
    this.getNewChecklist = this.getNewChecklist.bind(this)
    this.filterAddMoreList = this.filterAddMoreList.bind(this)

  }

  componentDidMount = () => {
    this.initAsyncOperations();
    // this.setState({
    //   is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
    //   is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
    //   is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
    //   templateId: this.props.location.state != null && this.props.location.state.templateId != null ? this.props.location.state.templateId : '',
    //   isAccessToView: true,
    // })
    //  this.props.location.state != null && this.props.location.state.is_add && this.getCheckList()
    //  this.getTypeOfEngagement()

    // await this.props.location.state != null && this.props.location.state.is_update && this.getTemplateDetails()
    //  this.getNewChecklist()
    // document.addEventListener('keydown', this.handleTabKeyPress);

  }

  async initAsyncOperations() {
    this.setState({
      is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
      is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
      is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
      templateId: this.props.location.state != null && this.props.location.state.templateId != null ? this.props.location.state.templateId : '',
      isAccessToView: true,
    })
    await this.props.location.state != null && this.props.location.state.is_add && this.getCheckList()
    await this.getTypeOfEngagement()
    await this.getNewChecklist()
    await this.props.location.state != null && this.props.location.state.is_update && this.getTemplateDetails()
    // document.addEventListener('click', this.handleInputClick);

    // document.addEventListener('keydown', this.handleTabKeyPress);
  }

  componentWillUnmount() {
    // document.removeEventListener('keydown', this.handleTabKeyPress);
    // document.removeEventListener('click', this.handleInputClick);
  }



 
  handleTabKeyPress = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
      const focusedElement = document.activeElement;
      const { previouslyFocusedElement } = this.state;

      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }

      if (focusedElement.classList.contains('form-control') ||
        focusedElement.classList.contains('js-example-basic-single') ||
        focusedElement.classList.contains('example-custom-input')) {
        focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
        this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
      }
    }
  };


  handleInputClick = (event) => {
    const { previouslyFocusedElement } = this.state;
    const clickedElement = event.target;

    if (previouslyFocusedElement) {
      previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    }

    if (clickedElement.classList.contains('form-control') ||
      clickedElement.classList.contains('js-example-basic-single') ||
      clickedElement.classList.contains('example-custom-input')) {
      clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
      this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
    } else {
      this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
    }
  };

  // get template details
  async getTemplateDetails() {
    const { templateId, checkListData, checkListTemplateName, newChecklistNameList } = this.state
    var id = this.props.location.state.templateId
    this.setState({ isLoading: true })
    await this.props
      .checkListTemplateDetails_action(id)
      .then((data) => {
        if (data.error != 1) {
          var list = [];
          var arr = data.data;

          for (var i = 0; i < arr.length; i++) {
            var data = {
              'action_id': 0,
              'is_critical': arr[i]['IsCritical'],
              'ChecklistID': arr[i]['ChecklistID'],
              'ChecklistName': arr[i]['ChecklistName'],
              'EQID': arr[i]['EQID'],
              'EQName': arr[i]['EQName'],
              'ShortName': arr[i]['Shortname'],
              'TypeOfEngagement': arr[i]['TypeOfEngagement'],
              'ChecklistTemplateDtlID': arr[i]['ChecklistTemplateDtlID'],
              'isActive': arr[i]['Status'],
              'Selected': arr[i]['Selected']
            }

            list.push(data)
          }

          this.setState({
            checkListData: list,
            tempCheckListData: list,
            checkListTemplateName: arr[0]['ChecklistTemplateName'],
            isActive: arr[0]['Status'],
            isLoading: false
          })
          this.filterAddMoreList(list)
        }
        else {
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // get check list
  getCheckList() {
    this.setState({ isLoading: true })
    this.props
      .fill_action(checklistnameActionId, '')
      .then((data) => {
        if (data.error != 1) {
          var list = [];
          var arr = data.data;
          for (var i = 0; i < arr.length; i++) {
            var data = {
              'action_id': 0,
              'is_critical': false,
              'ChecklistID': arr[i]['ChecklistID'],
              'ChecklistName': arr[i]['ChecklistName'],
              'EQID': arr[i]['EQID'],
              'EQName': arr[i]['Name'],
              'ShortName': arr[i]['ShortName'],
              'TypeOfEngagement': arr[i]['TypeOfEngagement'],
              'ChecklistTemplateDtlID': 0
            }
            list.push(data)
          }
          this.setState({
            checkListData: list,
            tempCheckListData: list,
            isLoading: false
          })
        }
        else {
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // new Checklist Name List
  async getNewChecklist() {
    this.setState({ isLoading: true })
    await this.props
      .fill_action(clientEngagementNewChecklistActionId, '')
      .then((data) => {
        if (data.error != 1) {

          this.setState({
            newChecklistNameList: data.data,
            tempNewChecklistNameList: data.data,
            isLoading: false
          })
        }
        else {
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }




  // Type of engagement
  async getTypeOfEngagement() {
    this.setState({ isLoading: true })
    await this.props
      .fill_action(typeOfEngagementActionId, '')
      .then((data) => {
        if (data.error != 1) {

          this.setState({
            typeOfEngagementList: data.data,
            isLoading: false
          })
        }
        else {
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // filter to more check list which is not selected 
  async filterAddMoreList(checkList) {
    const { newChecklistNameList, } = this.state
    // Filter out selected items from newChecklistNameList
    const filteredList = newChecklistNameList.filter(item => !checkList.some(check => check.Selected && check.ChecklistID === item.ChecklistID));
    this.setState({ newChecklistNameList: filteredList })
  }

  // on select new check list 
  onSelectNewChecklistName(e, id) {
    const { newChecklistNameEQ, tempCheckListData, newChecklistNameList, checkListData, is_update } = this.state
    this.setState({ newChecklistNameId: e.target.value })
    var isAvailable = false

    checkListData.map((data, index) => {
      if ((data.ChecklistID == e.target.value) && data.Selected) {
        isAvailable = true
      }
    })

    if (isAvailable) {
      this.setState({ newChecklistNameEQ: '', newChecklistNameId: '' })
      toast.error('Checklist already added.', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: true,
      })
    }
    else {
      var list = [];


      console.log("checkListData ***", checkListData)
      newChecklistNameList.map(item => {
        if (item.ChecklistID == e.target.value) {
          if (item.action_id === 3) {
            item.action_id = 0
            item.Selected = 1
          } else {
            item.action_id = 1
            item.Selected = 1
          }
          var list = {
            'action_id': item.action_id,
            'Selected': item.Selected,
            'EQName': item.Name,
            'TypeOfEngagement': item.TypeOfEngagement,
            'ShortName': item.ShortName,
            'is_critical': item.IsCritical,
            'EQID': item.EQID,
            'ChecklistID': item.ChecklistID,
            'ChecklistName': item.ChecklistName,
            'ChecklistTemplateHdrID': item.ChecklistTemplateHdrID,

          }

          var isChecklistIDExists = checkListData.some(existingItem =>
            existingItem.ChecklistID === e.target.value);


          console.log("existingIndex ", isChecklistIDExists)
          console.log("tempCheckListData  ", tempCheckListData)

          // If not found, push the new item
          if (!isChecklistIDExists) {
            checkListData.push(list);
          }
          // Iterate through tempCheckListData
          const updatedList = tempCheckListData.map(item => {
            // Check if the ChecklistID of the item matches the ChecklistID of the list object
            if (item.ChecklistID === list.ChecklistID) {
              return list;
            } else {
              return item;
            }
          });
        
          this.setState({ tempCheckListData: updatedList })
      // checkListData.push(list);
      console.log(newChecklistNameList)
      console.log(checkListData)
    }
  })
}
this.setState({ checkListData: checkListData, newChecklistNameEQ: '', newChecklistNameId: '' })

  }

// add  new template
handleAdd(e) {
  e.preventDefault()
  const { checkListTemplateName, checkListData, tempCheckListData } = this.state
  if (checkListTemplateName != "") {

    console.log(tempCheckListData)
    console.log(tempCheckListData.filter((item) => (item['action_id'] === 1)))

    var list = tempCheckListData.filter((item) => (item['action_id'] === 1))


    if (list.length == 0) {
      this.setState({ isLoading: false })
      toast.error('Atleast one checklist required to create template.', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: true,
      })
    } else {
      this.setState({ isLoading: true })
      this.props
        .addcheckListTemplate_action(checkListTemplateName.trim(), list)
        .then((data) => {
          if (data.error != 1) {
            toast.success('Data added successfully', {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
            this.setState({ isLoading: false })
            setTimeout(() => {
              this.handleCancel()
             }, 1000);
          }
          else {
            this.setState({ isLoading: false })

            toast.error(data.msg, {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
  else {
    this.setState({ isLoading: false })
    toast.error('Please enter mandatory field', {
      theme: 'colored',
      autoClose: 3000,
      hideProgressBar: true,
    })
  }
}

// update template
handleUpdate(e) {
  e.preventDefault()
  const { templateId, checkListTemplateName, checkListData ,tempCheckListData} = this.state
  if (templateId != "" && checkListTemplateName != "") {

    var list = tempCheckListData.filter((item) => (item['action_id'] === 2 || item['action_id'] === 3 || item['action_id'] === 1))
    const uniqueList = list.filter((item, index, array) => {
      return array.indexOf(item) === index;
    });
    
    var isAnyRecord = false;
    for (var i = 0; i < tempCheckListData.length; i++) {
      if (tempCheckListData[i]['Selected']) {
        isAnyRecord = true;
      }
    }

    if (!isAnyRecord) {
      this.setState({ isLoading: false })
      toast.error('Atleast one checklist required for template.', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: true,
      })
    } else {
      this.setState({ isLoading: true })
      this.props
        .updatecheckListTemplate_action(templateId, checkListTemplateName.trim(), uniqueList)
        .then((data) => {
          if (data.error != 1) {
            toast.success('Data updated successfully', {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
            this.setState({ isLoading: false })
         setTimeout(() => {
          this.handleCancel()
         }, 1000);
          } else {
            this.setState({ isLoading: false })
            toast.error(data.msg, {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
  else {
    this.setState({ isLoading: false })
    toast.error('Please enter mandatory field', {
      theme: 'colored',
      autoClose: 3000,
      hideProgressBar: true,
    })
  }

}

// download template
handleDownload(e) {
  e.preventDefault()

  const { templateId } = this.state
  if (templateId != "") {
    this.setState({ isLoading: true })
    this.props
      .downloadTemplate_action(templateId)
      .then((data) => {
        if (data != null) {
          this.setState({ isLoading: false })
          var url = `${downloadReport}?SessionIdentifier=${data}&Export=Yes`;
          const win = window.location.replace(url, '_blank');
          if (win != null) {
            win.focus();
          }
        } else {
          this.setState({ isLoading: false })
          toast.error('There is some problem to download report. Please try again later!', {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isLoading: false })
      });
  }
  else {
    this.setState({ isLoading: false })
    toast.error('Please enter mandatory field', {
      theme: 'colored',
      autoClose: 3000,
      hideProgressBar: true,
    })
  }

}
// delete template
handleDelete(e) {
  e.preventDefault()
  var id = 1;
  this.setState({ isLoading: true })
  this.props
    .deletecheckListTemplate_action(id)
    .then((data) => {
      if (data.error != 1) {
        toast.success('Data deleted successfully', {
          theme: 'colored',
          autoClose: 3000,
          hideProgressBar: true,
        })
        this.setState({ isLoading: false })
      }
      else {
        toast.error(data.msg, {
          theme: 'colored',
          autoClose: 3000,
          hideProgressBar: true,
        })
      }
    })
    .catch((e) => {
      console.log(e);
    });

}

// clear field ,list
handleCancel() {
  this.setState({
    checkListTemplateName: '',
    checkListData: []
  })
  this.props.navigate('/checkListTemplate')
  // this.props.location.state.is_add && this.getCheckList()
  // this.props.location.state.is_update && this.getTemplateDetails()
}

// on checked critical
handleIscritical(e, data, index) {
  const { checkListData, is_add, is_update } = this.state
  if (is_add) {
    if (e.target.checked) {
      data['is_critical'] = e.target.checked
      data['action_id'] = 1
      data['Selected'] = 1
    }
    else {
      data['is_critical'] = e.target.checked

    }
    this.setState({ checkListData: checkListData })
  }
  else if (is_update) {
    data['is_critical'] = !data['is_critical']
    if (e.target.checked && data['Selected'] !== 1) {

      data['action_id'] = 1
      data['Selected'] = 1
    }
    else {
      if (data['action_id'] != 1) {
        data['action_id'] = 2
      }
    }
    this.setState({ checkListData: checkListData })
  }

}

// on checked action id
handleActionId(e, data) {
  const { checkListData, is_add, is_update } = this.state
  if (is_add) {
    if (e.target.checked) {
      data['action_id'] = 1
      data['Selected'] = 1
    } else {
      data['action_id'] = 0
      data['Selected'] = 0
      data['is_critical'] = false
    }
    this.setState({ checkListData: checkListData })
  }
  else if (is_update) {
    if (e.target.checked) {

      if (data['action_id'] === 3) {
        data['action_id'] = 0
        data['Selected'] = 1
      } else {
        data['action_id'] = 1
        data['Selected'] = 1
      }
    } else {
      data['Selected'] = 0
      data['action_id'] = 3
      if (data['is_critical']) {
        data['is_critical'] = e.target.checked
      }
    }
    this.setState({ checkListData: checkListData })
  }
}

onSelectTypeOfEngagement(e) {
  const { tempCheckListData, checkListData, tempNewChecklistNameList, newChecklistNameList } = this.state
  this.setState({ typeOfEngagementId: e.target.value })
  var list = checkListData;
  if (e.target.value != 'Select') {
    const searchList = tempCheckListData.filter(data => {
      return (data.TypeOfEngagement != null && data.TypeOfEngagement.includes(e.target.value)
      );
    });
    this.setState({ checkListData: [...searchList] })
    // to filter add more check list
    const filteredList = tempNewChecklistNameList.filter(item =>
      !list.some(check => check.Selected && check.ChecklistID === item.ChecklistID) &&
      item.TypeOfEngagement === e.target.value
    );
    this.setState({ newChecklistNameList: filteredList })
  } else {
    console.log("list", tempCheckListData)
    const filteredList = tempCheckListData.filter(item => !item.Selected);
    console.log("select filteredList", filteredList)
    this.setState({ checkListData: [...tempCheckListData], newChecklistNameList: filteredList })
  }

}

render() {
  const { checkListData, isLoading, isActive, is_update, isAccessToView,
    typeOfEngagementId, typeOfEngagementList, is_add, newChecklistNameId, newChecklistNameList, isAnyCompleted, newChecklistNameEQ } = this.state
    let sequenceCounter = 0;
    return (
    <>
      {isLoading ? (
        <Loader></Loader>
      ) : (
        null
      )}
      <Header />
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          {/* Left side menu */}
          <Drawer />
          {/* end left menu */}
          {/* start page */}
          {isAccessToView ? (
            <div className="main-panel main-box">
              <div className="content-wrapper">
                <div className="main-breadcum">
                  <ul className="breadcrumb">
                    <li>
                      <a onClick={this.handleCancel} >Checklist Template</a>
                    </li>
                    <li>
                      <a>{this.state.is_add ? 'New' : 'Update'}</a>
                    </li>
                    <li />
                  </ul>
                </div>
                <div className="user-status">
                            {
                              is_update ? <p>
                                <strong className={isActive === 1 ? 'greena' : 'redi'}> {isActive === 1 ? 'Active' : 'Inactive'}</strong>
                              </p> : <></>
                            }

                        </div>
                        <div className='page-space-from-bottom'>
                <div className="row">
                  <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                      __html:
                        "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                    }}
                  />
                  <div className="col-md-12 pg-body grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        
                        <div className="d-flex flex-wrap row ">
                          <div className="form-group col-md-3">
                            <label>
                              Checklist Template Name<span className="required1">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder='Checklist Template Name'
                              value={this.state.checkListTemplateName}
                              onChange={(e) => {
                                if (e.target.value.length <= 50) {
                                  this.setState({ checkListTemplateName: e.target.value })
                                } else {
                                  // Truncate the input value to 50 characters
                                  this.setState({ checkListTemplateName: e.target.value.slice(0, 50) });
                                }
                              }}
                              required
                            />
                          </div>
                          {
                            // is_add && 
                            <div className="form-group col-md-3 mt-1">
                              <label>Type of engagement</label>

                              <select className="js-example-basic-single"
                                style={{ width: '100%' }}
                                value={typeOfEngagementId}
                                placeholder='Select'
                                onChange={e => this.onSelectTypeOfEngagement(e)}>
                                 <option value={''}>Select</option>
                                {typeOfEngagementList.length > 0 && typeOfEngagementList.map(item => (
                                  <option
                                    key={item.TypeOfEngagement}
                                    value={item.TypeOfEngagement}>
                                    {item.TypeOfEngagement}
                                  </option>
                                ))}

                              </select>

                            </div>
                          }

                        </div>
                        {/* /// */}
                        <div className="row mt-2">
                          <div className="table-responsive tbs4 common-table trail-tb mb-4"
                            style={{Height: '', overflow: 'auto', overflowX: 'hidden'
                            }}
                          >
                            <table className="table table-bordered tb-action du-table">
                              <thead>
                                <tr>
                                  <th className="text-center" style={{ width: 60 }}>
                                    Sr No
                                  </th>
                                  <th>Checklist</th>
                                  <th style={{ width: 100 }}>Short Name </th>
                                  <th style={{ width: 100 }}>Type of engagement</th>
                                  <th style={{ width: 60 }}>Applicable</th>
                                  <th style={{ width: 150 }}>E/Q</th>
                                  <th style={{ width: 100 }}>Critical</th>

                                </tr>
                              </thead>
                              <tbody>
                                {
                                  checkListData.length != null ?
                                    (checkListData.map((data, index) => (
                                      <>
                                        {
                                          is_add ?
                                            <tr key={Math.random()}>
                                              <td className="text-center">{index + 1}</td>
                                              <td style={{ textAlign: 'left' }}>{data.ChecklistName}</td>
                                              <td style={{ textAlign: 'center' }}>{data.ShortName}</td>
                                              <td style={{ textAlign: 'center' }}>{data.TypeOfEngagement}</td>
                                              <td><input type="checkbox" checked={data.Selected === 1 ? true : false} onChange={(e) => this.handleActionId(e, data)} /> </td>

                                              <td style={{ textAlign: 'center' }}>{data.EQName}</td>

                                              <td className='cri'> <input type="checkbox" checked={data.is_critical}
                                                onChange={(e) => this.handleIscritical(e, data, index)} /> </td>
                                            </tr> :
                                            is_update && data.Selected === 1 &&
                                            <tr key={Math.random()}>
                                              <td className="text-center">{ ++sequenceCounter}</td>
                                              <td style={{ textAlign: 'left' }}>{data.ChecklistName}</td>
                                              <td style={{ textAlign: 'center' }}>{data.ShortName}</td>
                                              <td style={{ textAlign: 'center' }}>{data.TypeOfEngagement}</td>
                                              <td><input type="checkbox" checked={data.Selected === 1 ? true : false} onChange={(e) => this.handleActionId(e, data)} /> </td>

                                              <td style={{ textAlign: 'center' }}>{data.EQName || data.Name}</td>

                                              <td className='cri'> <input type="checkbox" checked={data.is_critical}
                                                onChange={(e) => this.handleIscritical(e, data, index)} /> </td>
                                            </tr>
                                        }
                                      </>
                                    ))) : (<>Record not found</>)
                                }
                                {
                                  is_update &&
                                  <tr>
                                    <td />
                                    <td style={{ padding: 0 }}>
                                      <select
                                        className="js-example-basic-single"
                                        style={{
                                          width: "100%",
                                          height: 38,
                                          border: "none",
                                          background: "#eef7f9"
                                        }}
                                        value={newChecklistNameId}
                                        // disabled={isAnyCompleted}
                                        onChange={e => this.onSelectNewChecklistName(e, newChecklistNameId)}
                                      >
                                        <option>Add more checklist</option>
                                        {newChecklistNameList.map(item => (
                                          <option
                                            key={item.ChecklistID}
                                            value={item.ChecklistID}>
                                            {item.ChecklistName}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td style={{ padding: 0 }}>
                                      {/* <input
                                          type="text"
                                          style={{
                                            padding: 7,
                                            background: "#eef7f9"
                                          }}
                                          disabled={true}
                                          value={newChecklistNameEQ}
                                        /> */}
                                    </td>
                                    <td className="cri">
                                      {/* <input type="checkbox" /> */}
                                    </td>
                                    <td>
                                      {/* <input type="checkbox" /> */}
                                    </td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                }


                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* //// */}
                        {/* <div className="row">
                          <div className="col-12 mt-2">

                            <div className="table-responsive tbs4 common-table trail-tb" style={{
                              width: '100%',
                              maxHeight: '68vh', overflow: 'auto', overflowX: 'hidden'
                            }}>
                              <table className="table table-bordered tb-action du-table overflow-auto">
                                <thead>
                                  <tr>
                                    <th style={{ width: '60px' }} />
                                    <th style={{ textAlign: 'left' }}>Check List</th>
                                    <th style={{ width: '250px' }}>E/Q</th>
                                    <th style={{ width: '100px' }}>Critical</th>
                                  </tr>
                                </thead>
                                <tbody className='overflow-auto' style={{}}>

                                  {
                                    checkListData.length != null ?
                                      (checkListData.map((data, index) => (
                                        <>
                                          <tr key={Math.random()}>
                                            <td><input type="checkbox" checked={data.action_id === 1 || data.action_id === 2 ? true : false} onChange={(e) => this.handleActionId(e, data)} /> </td>
                                            <td style={{ textAlign: 'left' }}>{data.ChecklistName}</td>
                                            <td style={{ textAlign: 'center' }}>{data.EQName}</td>
                                            <td className='cri'> <input type="checkbox" checked={data.is_critical}
                                              onChange={(e) => this.handleIscritical(e, data, index)} /> </td>
                                          </tr></>
                                      ))) : (<>Record not found</>)
                                  }

                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="footer-btn">
                            <div className="col-md-3">
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Basic example"
                              >
                                {
                                  this.state.is_add && getModuleWriteAccess(Master) && <button
                                    type="button"
                                    className="btn btn-save footer-btn-inner mr-1"
                                    onClick={this.handleAdd}
                                  >
                                    Save
                                  </button>
                                }
                                {
                                  this.state.is_update && isActive === 1 && getModuleUpdateAccess(Master)?
                                    <button
                                      type="button"
                                      className="btn btn-update footer-btn-inner mr-1"
                                      onClick={this.handleUpdate}
                                    >
                                      Update
                                    </button> : <></>
                                }

                                {
                                  isActive === 1 ?
                                    <button
                                      type="button"
                                      className="btn btn-download footer-btn-inner mr-1"
                                      onClick={this.handleDownload}
                                    >
                                      Download
                                    </button> : <></>
                                }

                                {
                                  this.state.is_delete && getModuleDeleteAccess(Master) &&
                                  <button
                                    type="button"
                                    className="btn btn-delete footer-btn-inner mr-1"
                                    onClick={this.handleDelete}
                                  >
                                    Delete
                                  </button>
                                }

                                <button
                                  type="button"
                                  className="btn btn-cancel footer-btn-inner"
                                  onClick={this.handleCancel}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
</div>
              </div>
            </div>
          ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)}
          {/* end page */}
        </div>
        {/* page-body-wrapper ends */}
      </div>
      <ToastContainer />
    </>
  );
}
}

export default withRouter(connect(null, {
  checkListTemplate_action,
  checkListTemplateDetails_action,
  addcheckListTemplate_action,
  updatecheckListTemplate_action,
  downloadTemplate_action,
  deletecheckListTemplate_action,
  fill_action
})(ChecklistTemplateAU));