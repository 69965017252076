import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    getPrefixListUrl,
    addPrefixUrl,
    updatePrefixUrl,
    getPrefixDetailsUrl,
    deletePrefixUrl,
    platfarm,
    PREFIX_LIST_ACTION_TYPE,
    PREFIX_DETAILS_ACTION_TYPE,
    PREFIX_ADD_ACTION_TYPE,
    PREFIX_UPDATE_ACTION_TYPE,
    PREFIX_DELETE_ACTION_TYPE,
    headers
} from "../../utils/constants"
import moment from 'moment';



export const prefixList_action = () => async (dispatch) => {
    try {

        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }
        const res = await axiosGet(getPrefixListUrl, param);
        dispatch({
            type: PREFIX_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const prefixDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            prefixID : id,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }

        const res = await axiosGet(getPrefixDetailsUrl, param);
        dispatch({
            type: PREFIX_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const prefixAdd_action = (prefixName, prefixfor) => async (dispatch) => {
    try {
        const mapdata = {
            Prefix: prefixName,
            prefixfor: prefixfor,
             UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }
        const res = await axiosPost(addPrefixUrl, mapdata, headers);

        dispatch({
            type: PREFIX_ADD_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const prefixUpdate_action = (id, prefixName, prefixfor) => async (dispatch) => {
    try {
        const mapdata = {
            Id: id,
            Prefix: prefixName,
            prefixfor: prefixfor,
             UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }
        const res = await axiosPost(updatePrefixUrl, mapdata, headers);

        dispatch({
            type: PREFIX_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const prefixDelete_action = (id) => async (dispatch) => {
    try {
        const param = {
            ID: id,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,

        }
        const res = await axiosGet(deletePrefixUrl, param);
        dispatch({
            type: PREFIX_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export default (state = {}, action) => {
    switch (action.type) {
        case 'SIMPLE_ACTION':
            return {
                result: action.payload
            }
        default:
            return state
    }
}