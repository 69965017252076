
import {
    REVIEW_CHECKLIST_ACTION_TYPE,
    REVIEW_CHECKLIST_DETAILS_ACTION_TYPE,
    REVIEW_CHECKLIST_UPDATE_ACTION_TYPE,
    REVIEW_CHECKLIST_CLOSE_ACTION_TYPE,
    REVIEW_CHECKLIST_FILE_UPLOAD_ACTION_TYPE,
    REVIEW_CHECKLIST_FILE_DELETE_ACTION_TYPE,
    REVIEW_CHECKLIST_FILE_GET_ACTION_TYPE
 } from "../../utils/constants"


const initialState = [];

function reviewChecklistReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REVIEW_CHECKLIST_ACTION_TYPE:
      return [...state, payload];
      case REVIEW_CHECKLIST_DETAILS_ACTION_TYPE:
      return [...state, payload];
    case REVIEW_CHECKLIST_UPDATE_ACTION_TYPE:
      return [...state, payload];
    case REVIEW_CHECKLIST_CLOSE_ACTION_TYPE:
      return [...state, payload];
      case REVIEW_CHECKLIST_FILE_UPLOAD_ACTION_TYPE:
        return [...state, payload];
        case REVIEW_CHECKLIST_FILE_DELETE_ACTION_TYPE:
      return [...state, payload];
      case REVIEW_CHECKLIST_FILE_GET_ACTION_TYPE:
      return [...state, payload];
    default:
      return state;
  }
};

export default reviewChecklistReducer;


// export default (state = {}, action) => {
//     switch (action.type) {
//      case 'SIMPLE_ACTION':
//       return {
//        result: action.payload
//       }
//      default:
//       return state
//     }
//    }