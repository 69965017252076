import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import Drawer from '../../common_components/Drawer';
import { dashboard_action } from '../../actions/dashboard_action/dashboard_action'
import { clearLocalStorage, allFinancialYearId, downloadReport, MCQ_Chapter } from '../../utils/constants'
import { withRouter } from '../../utils/withRouter';
import Loader from '../../utils/Loader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { fill_action } from '../../actions/master_action/checklist_action'
import { downloadStatus_action } from '../../actions/engagement_action/client_engagement_action'
import {
  singleLastTimesheetDate_action
} from '../../actions/time_sheet_expense_action/time_sheet_action'
import { getModuleReadAccess } from '../../utils/RoleAccess';
/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//   login_action: () => dispatch(login_action())
// })

/* 
 * mapStateToProps
*/
// const mapStateToProps = state => ({
//   ...state
// })

class Dashboard extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isOpen: false,
      form: 'My',
      myListCount: '',
      firmListCount: '',
      listDetails: [],
      finYearList: [],
      finYearId: '',
      activeInactive: 'Active',
      singleLastTimesheetDate: '',
      singleLastTestDate: ''

    }
    this.getList = this.getList.bind(this)
    this.onClickMyEng = this.onClickMyEng.bind(this)
    this.getFillList = this.getFillList.bind(this)
    this.onSelectFinYear = this.onSelectFinYear.bind(this)
    this.onSelectActiveInactive = this.onSelectActiveInactive.bind(this)
    this.onClickEngagementTab = this.onClickEngagementTab.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
    this.singleLastTimesheetDate = this.singleLastTimesheetDate.bind(this)
    this.takeTest = this.takeTest.bind(this)




  }

  componentDidMount = () => {
    clearLocalStorage('')
    this.getFillList(allFinancialYearId, '')

    setTimeout(() => {
      this.singleLastTimesheetDate()
    }, 100);

  }

  // get fill List dropdown  
  getFillList(id, reference) {
    this.setState({ isLoading: true })
    this.props
      .fill_action(id, reference)
      .then((data) => {
        if (data.error != 1) {
          if (id === allFinancialYearId) {
            this.setState({
              finYearList: data.data,
              isLoading: false
            })
          }

          this.setState({
            isLoading: false
          })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }


  takeTest() {
    const { singleLastTestDate } = this.state
    singleLastTestDate != "Take Test" ?
      this.props.navigate('/homeTest')
      : this.props.navigate('/takeTest')
  }

  onSelectFinYear(e) {
    const { form } = this.state
    this.setState({ finYearId: e.target.value })
    setTimeout(() => {
      this.getList(form)
    }, 300);
  }
  onSelectActiveInactive(status) {
    const { form } = this.state
    this.setState({ activeInactive: status })
    setTimeout(() => {
      this.getList(form)
    }, 300);
  }

  onClickEngagementTab(name) {
    this.setState({ form: name })
    setTimeout(() => {
      this.getList(name)
    }, 200);
  }

  getList(name) {
    const { finYearId, activeInactive } = this.state
    this.setState({ firmListCount: '', myListCount: '', listDetails: [] })

    if (finYearId != 'Select' && finYearId != '' && activeInactive != '') {
      this.setState({ isLoading: true, form: name })
      this.props
        .dashboard_action(name, activeInactive, finYearId)
        .then((data) => {
          if (data.error != 1) {

            for (var i = 0; i < data.data.length; i++) {
              if (data.data[i]['ClosedByDate'] != null && data.data[i]['ClosedByDate'] != '') {
                var date = data.data[i]['ClosedByDate'].split('T');
                var newDate = date[0] + 'T00:00:00.000Z'
                data.data[i]['ClosedByDate'] = newDate
              }
            }

            name == 'Firm' ?
              this.setState({
                firmListCount: data.data.length
              }) :
              this.setState({
                myListCount: data.data.length
              })
            this.setState({
              listDetails: data.data,
              isLoading: false,
              currentPage: 1
            })

          }
          else {
            this.setState({ isLoading: false })
            toast.error(data.msg, {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.setState({ isLoading: false })
      toast.error('Please select financial year and status', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: true,
      })
    }

  }

  singleLastTimesheetDate() {
    this.setState({ isLoading: true })
    this.props
      .singleLastTimesheetDate_action()
      .then((data) => {
        if (data.error != 1) {
          // console.log("singleLastTimesheetDate_action", data.data)
          // console.log("TimeSheetDate", data.data[0][0]['TimeSheetDate'])

          console.log("lastTestTaken", data.data[1][0]['lastTestTaken'])
          console.log("lastTestTaken", moment("2024-07-29T20:38:14.210Z").format("DD-MM-YYYY"))
var datetest =  moment(data.data[1][0]['lastTestTaken']).format('DD-MM-YYYY')
          this.setState({
            singleLastTimesheetDate: data.data != '' && data.data.length > 0 && data.data[0].length > 0 && (data.data[0][0]['TimeSheetDate'] != '' && data.data[0][0]['TimeSheetDate'] != null) ?
              moment(data.data[0][0]['TimeSheetDate']).format('DD-MM-YYYY') : '',
            singleLastTestDate: data.data != '' && data.data.length > 0 && data.data[1].length > 0 && (data.data[1][0]['lastTestTaken'] != "" && data.data[1][0]['lastTestTaken'] != null) ?
              moment.utc(data.data[1][0]['lastTestTaken']).format('DD-MM-YYYY') 
              // datetest
              : 'Take Test',
            isLoading: false
          })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onClickMyEng(id, data) {
    var empId = localStorage.getItem('EmployeeId')
    if (data.engPartnerEmployeeId == empId) {
      this.props.navigate('/clientEngagementAU', { state: { is_update: true, clientEngagementId: id } })
    }
  }



  handleDownload(e, id, statusid) {
    e.preventDefault()
    this.setState({ isLoading: true })
    this.props
      .downloadStatus_action(id, statusid)
      .then((data) => {
        if (data != null) {
          this.setState({ isLoading: false })
          var url = `${downloadReport}?SessionIdentifier=${data}&Export=Yes`;
          const win = window.location.replace(url, '_blank');
          if (win != null) {
            win.focus();
          }
        } else {
          this.setState({ isLoading: false })
          toast.error('There is some problem to download report. Please try again later!', {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isLoading: false })
      });
  }

  render() {
    const { isLoading, listDetails, form, firmListCount, myListCount, activeInactive, finYearList, finYearId, singleLastTimesheetDate, singleLastTestDate } = this.state

    return (
      <div>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          null
        )}
        <ToastContainer />
        <div className='HeaderBtn'
        // style={{


        //   position: 'absolute',
        //   display: 'flex',
        //   justifyContent: 'center',
        //   margin: 'auto',
        //   zIndex : '999',
        //   right: '-5%',
        //   width:' 50%',

        // }}
        >
          <div className="text-center mx-3">
            <label className='text-white'>Last timesheet fill date<br></br><strong className='text-white' style={{fontSize:'14px'}}>{singleLastTimesheetDate}</strong></label>
            {/* <input
                            type="text"
                            className="form-control"
                            disabled={true}
                            value={singleLastTimesheetDate}
                          /> */}
          </div>

          <div className="text-center mx-4">
            <label className='text-white'>Last test <br></br><strong className='text-white' style={{fontSize:'14px', cursor:'pointer'}}  onClick={() => this.takeTest()} > <i class="fa fa-link"></i> {singleLastTestDate} </strong></label>
            {/* <button
                            type="btn btn-secondary"
                            className="form-control text-left p-1 py-2 h-auto"
                            value={singleLastTestDate}
                            onClick={() => this.takeTest()}
                          > {singleLastTestDate} </button> */}
          </div>
        </div>
        <Header />


        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper">
            {/* Left side menu */}
            <Drawer />
            {/* end left menu */}
            <>
              {/* start page */}
              <div className="main-panel main-box">
                <div className="content-wrapper">
                  <form
                    autoComplete="off"
                    className="forms-sample needs-validation wm-forms1"
                    noValidate=""
                  >
                    <div className="row">
                      <div className="col-md-12" style={{ borderBottom: "1px solid #ddd" }}>
                        <div className="main-tab-name">
                          <p className="text-white" style={{ fontSize: '13px' }}>
                            Engagement
                          </p>
                        </div>
                      </div>
                      {/*  */}

                      <div className="form-group col-md-3 ml-4">
                        <div className="form-group row">
                          <div className="col-sm-4">
                            <div className="form-check" style={{ marginTop: '0px', marginBottom: '0px' }}>
                              <label className="d-flex" style={{ lineHeight: '33px' }}>
                                <input
                                  type="radio"
                                  className="w-auto mr-1"
                                  name="activeInactive"
                                  id="active"
                                  checked={activeInactive == 'Active' && true}
                                  value={activeInactive}

                                  onClick={e => this.onSelectActiveInactive('Active')} />

                                Active
                                <i className="input-helper" />
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-check" style={{ marginTop: '0px', marginBottom: '0px' }}>
                              <label className="d-flex" style={{ lineHeight: '33px' }}>
                                <input
                                  type="radio"
                                  className="w-auto mr-1"
                                  name="activeInactive"
                                  id="closed"
                                  checked={activeInactive == 'closed' && true}
                                  value={activeInactive}
                                  onClick={e => this.onSelectActiveInactive('closed')} />

                                Closed
                                <i className="input-helper" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*  */}
                      <div className="form-group row">
                        <div className="col-md-3 form-group ml-4">
                          <label>Select Financial Year</label>
                          <select className style={{ width: '100%' }}
                            value={finYearId} onChange={e => this.onSelectFinYear(e)}
                          >
                             <option value={''}>Select</option>
                            {
                              finYearList.length > 0 && finYearList.map((item) => (
                                <option key={item.FinYearID} value={item.FinYearID}>{item.FinancialYear}</option>
                              ))
                            }
                          </select>
                        </div>


                      </div>
                      {/*  */}
                      <div className="col-md-12 mb-3 stretch-card">
                        <div
                          className=""
                          style={{ background: "rtransparent", width: "100%" }}
                        >

                          <div
                            id="div-height1"
                            className=""
                            style={{ background: "rgb(255, 255, 255)", height: '435px' }}
                          >
                            <div className="mob-tab" style={{ marginLeft: '5px' }}>
                              <div className="mob-wd1">
                                <ul
                                  className="nav nav-tabs mt-2"
                                  id="myTab"
                                  role="tablist"
                                  style={{ backgroundColor: "#fcfcfc" }}
                                >
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className="nav-link active"
                                      id="my-engagement-tab"
                                      data-bs-toggle="tab"
                                      data-bs-target="#my-engagement"
                                      type="button"
                                      role="tab"
                                      aria-controls="my-engagement"
                                      aria-selected="true"
                                    >
                                      <label
                                        className="w-auto pb-2"
                                        style={{ lineHeight: '12px' }}
                                      >
                                        {" "}
                                        <input
                                          type="radio"
                                          className="mr-1"
                                          style={{ width: "auto", float: "left" }}
                                          name="membershipRadios"
                                          id="my"
                                          defaultValue=""
                                          defaultChecked="my"
                                          onClick={e => this.onClickEngagementTab('My')}
                                        />{" "}
                                        My Engagement {form == 'My' && listDetails.length > 0 && <span className="rec-qty">{myListCount}</span>}
                                      </label>
                                    </button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className="nav-link"
                                      id="Firm-Engagement-tab"
                                      data-bs-toggle="tab"
                                      data-bs-target="#Firm-Engagement"
                                      type="button"
                                      role="tab"
                                      aria-controls="profile"
                                      aria-selected="false"
                                    >
                                      <label
                                        className="w-auto pb-2"
                                        style={{ lineHeight: '12px' }}
                                      >
                                        <input
                                          type="radio"
                                          className="mr-1"
                                          style={{ width: "auto", float: "left" }}
                                          name="membershipRadios"
                                          id="firm"
                                          defaultValue="option2"
                                          onClick={e => this.onClickEngagementTab('Firm')}
                                        />{" "}
                                        Firm Engagement {form == 'Firm' && listDetails.length > 0 && <span className="rec-qty">{firmListCount}</span>}
                                      </label>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="tab-content"
                              id="myTabContent"
                              style={{ background: "#fff", padding: "5px 4px !important" }}
                            >
                              <div
                                className="tab-pane fade active show"
                                id="my-engagement"
                                role="tabpanel"
                                aria-labelledby="docking-details-tab"
                              >
                                <div className="d-flex flex-wrap row m-0">
                                  <div className="form-group col-12 p-2">
                                    <div className="table-responsive tbs4 common-table mt-1 tableFixHead custom-height-grid">
                                      <table className="table table-bordered tb-actacion du-table ">
                                        <thead>
                                          <tr>
                                            <th style={{ width: '300px' }}>Client</th>
                                            <th style={{ width: '200px' }}>Engagement Partner</th>
                                            <th style={{ width: '100px' }}>Quality Reviewer</th>
                                            <th style={{ width: '100px' }}>Engagement  Head</th>
                                            <th style={{ width: '100px' }}>Engagement Type</th>
                                            <th
                                              className="text-center"
                                              style={{ width: '100px' }}
                                            >
                                              financial Year
                                            </th>
                                            <th
                                              className="text-center"
                                              style={{ width: '140px' }}
                                            >
                                              Planned Closure date
                                            </th>
                                            {activeInactive == 'closed' ? (
                                              <th
                                                className="text-center"
                                                style={{ width: '140px' }}>
                                                Actual Closure date
                                              </th>
                                            ) : (null)}

                                            <th className="text-center" colSpan={2}>
                                              checklist Status
                                              {/* <table className='text-center m-auto' style={{borderCollapse: 'inherit',outline : '0px !important'}}>
                                                <tr>
                                                  <th className="text-center border-0">Critical</th>
                                                  <th className="text-center  border-0">Non Critical</th>
                                                </tr>
                                              </table> */}
                                            </th>
                                            {activeInactive != 'closed' ? (
                                              <th></th>
                                            ) : (null)}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            {activeInactive == 'closed' ? (
                                              <td />
                                            ) : (null)}

                                            <td className="text-center">Critical</td>
                                            <td className="text-center">Non Critical</td>
                                            {activeInactive != 'closed' ? (
                                              <td />
                                            ) : (null)}
                                          </tr>

                                          {
                                            listDetails.length > 0 ? listDetails.map((data) => (
                                              <tr onClick={e => form == 'My' && this.onClickMyEng(data.EngagementID, data)}>
                                                <td style={{ width: '300px' }}>{data.ClientName}</td>
                                                <td style={{ width: '200px' }}>{form == "My" ? data.EngPartnerName : data.ResponsiblePerson}</td>
                                                <td>{data.QualityReviewer}</td>
                                                <td>{form == "My" ? data.ResponsiblePerson : data.EngagementHead}</td>
                                                <td>{data.EngagementType}</td>
                                                <td className="text-center">{data.FinancialYear}</td>
                                                <td className="text-center">{data.ExpectedClosureDate != null && moment(data.ExpectedClosureDate).format('DD-MM-YYYY')}</td>
                                                {/* <td className="text-center">{new Date(moment(data.ClosedByDate).format('MM-DD-YYYY')+'Z').toISOString()}</td> */}
                                                {activeInactive == 'closed' ? (
                                                  <td className="text-center">{data.ClosedByDate != null && moment(data.ClosedByDate).format('DD-MM-YYYY')}</td>
                                                ) : (null)}
                                                <td className="text-center">{data.Critical}</td>
                                                <td className="text-center">{data.NonCritical}</td>
                                                {activeInactive != 'closed' ? (
                                                  <td class="text-center" style={{ padding: '5px!important' }}>
                                                    <a href="" class="no-sac"><i class="fa fa-spinner" title="status of audit report" style={{ color: '#242424' }} onClick={e => this.handleDownload(e, data.EngagementID, data.EngagementStatusID)}></i></a>
                                                    {/* <a href=""><i class="fa fa-check-circle-o" title="summary report of audit closure" ></i></a> */}
                                                  </td>
                                                ) : (null)}


                                              </tr>
                                            )) : <tr><td className="text-center" colSpan={10}>No record found</td></tr>
                                          }

                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*start unloading details 2 tab*/}
                              <div
                                className="tab-pane fade div-height12"
                                id="my-engagement"
                                role="tabpanel"
                                aria-labelledby="unloading-details-tab"
                              >
                                <div className="d-flex flex-wrap row m-0">
                                  <div className="form-group col-12 p-2">
                                    <div className="table-responsive tbs4 common-table">
                                      <table className="table table-bordered tb-action du-table ">
                                        <thead>
                                          <tr>
                                            <th>Client</th>
                                            <th>Person Responsible</th>
                                            <th>Engagement Type</th>
                                            <th>financial Year</th>
                                            <th>Planned closing date</th>
                                            <th colSpan={2}>checklist Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td>Critical </td>
                                            <td>Non Critical</td>
                                          </tr>
                                          <tr>
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                          </tr>
                                          <tr>
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*end unloading details 2 tab*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* end page */}
            </>

          </div >
          {/* page-body-wrapper ends */}
        </div >

      </div >
    );
  }
}

export default withRouter(connect(null, { dashboard_action, fill_action, downloadStatus_action, singleLastTimesheetDate_action })(Dashboard));