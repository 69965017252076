import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    engagementRoleListUrl, engagementRoleDetailsUrl, deleteEngagementRoleUrl, engagementRoleUpdateUrl,
    engagementRoleAddUrl, ENGAGEMENTROLE_LIST_ACTION_TYPE,
    ENGAGEMENTROLE_DETAILS_ACTION_TYPE, ENGAGEMENTROLE_ADD_ACTION_TYPE,
    ENGAGEMENTROLE_UPDATE_ACTION_TYPE, ENGAGEMENTROLE_DELETE_ACTION_TYPE, platfarm, headers
} from '../../utils/constants'

const user_id = localStorage.getItem('UserId')

export const engagementRoleList_action = (engagementRole, parentId, maxCount, displayOrder, status) => async (dispatch) => {
    try {
        const param = {
            userId: localStorage.getItem('UserId'),
            platform: platfarm,
            EngagementRole: engagementRole,
            Reporting: parentId != '' ? parentId : "",
            MaxCount: maxCount != '' ? maxCount : "",
            Order: displayOrder != '' ? displayOrder : "",
            Status: status
        }
        console.log("engagementRoleList_action", param)
        const res = await axiosGet(engagementRoleListUrl, param);
        dispatch({
            type: ENGAGEMENTROLE_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const engagementRoleAdd_action = (engagementRole, parentId, maxCount, displayOrder) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Role: engagementRole,
            ParentDesID: parentId != '' ? parentId : null,
            MaxCount: maxCount != '' ? maxCount : null,
            DisplayOrder: displayOrder != '' ? displayOrder : null,
        }
        console.log('engagementRoleAdd_action', param)
        const res = await axiosPost(engagementRoleAddUrl, param, headers);
        dispatch({
            type: ENGAGEMENTROLE_ADD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const engagementRoleDetails_action = (id) => async (dispatch) => {

    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }
        const res = await axiosGet(engagementRoleDetailsUrl, param);
        console.log('engagementRoleDetails_action', param)
        dispatch({
            type: ENGAGEMENTROLE_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
};

export const engagementRoleUpdate_action = (id, engagementRole, parentId, ModuleList, ReportList) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
            Role: engagementRole,
            ParentDesID: parentId,
            ModuleList: ModuleList,
            ReportList: ReportList,
        }
        console.log("engagementRoleUpdate_action", mapdata)
        const res = await axiosPost(engagementRoleUpdateUrl, mapdata, headers);
        dispatch({
            type: ENGAGEMENTROLE_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
};

export const deleteEngagementRole_action = (id) =>
    async (dispatch) => {
        console.log("deleteEngagementRole_action", id)
        try {
            const param = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                EngagementID: id,
            }
            const res = await axiosGet
                (deleteEngagementRoleUrl, param)
            dispatch({
                type: ENGAGEMENTROLE_DELETE_ACTION_TYPE,
                payload: res
            })
            return Promise.resolve(res)
        } catch (err) {
            return Promise.reject(err)
        }
    }