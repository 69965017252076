import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import Drawer from '../../common_components/Drawer';
import { getReportUserProfile_action, getReportUserProfileView_action } from '../../actions/reports_action/user_profile_report_action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../utils/Loader'
import Pagination from '../../utils/pagination'
import { fill_action } from '../../actions/master_action/checklist_action'
import Select from 'react-select';

import { clearLocalStorage, trainingEmployeeActionId, financialYearId, downloadReport, downloadReportTrainingFirmWise, UserProfile } from '../../utils/constants'
import * as XLSX from 'xlsx';
import { getReportDownloadAccess, getReportViewAccess } from '../../utils/RoleAccess';

const customStyles = {
    // control: (provided) => ({
    //     ...provided,
    //     height: '10px'
    // }),
    control: (provided) => ({
        ...provided,
        // height: '10px',
        borderColor: "#c9c9c9", // Blue border color
        boxShadow: "none",
        "&:hover": {
            borderColor: "#80bdff", // Blue border color on hover
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        borderRadius: "14px",
        padding: "4px",
        backgroundColor: "#00BCD4", // Blue background color for selected value
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: "white", // Text color for selected value
        fontSize: "12px",
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: "white",
        "&:hover": {
            backgroundColor: "#00BCD4",
            color: "#fff", // Blue border color on hover
        }, // Remove icon color for selected value
    }),
};

function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
}

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//   FinancialYearAction: () => dispatch(FinancialYearAction())
// })

/* 
 * mapStateToProps
*/
// const mapStateToProps = state => {
//   return {
//     loginData: state,
//   };
// }

class UserProfileReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            finYear: '',
            finYearList: [],
            isOpen: false,
            isLoading: false,
            isSearchTab: false,
            listData: [],
            columnArray: [],
            rowData: [],
            trainingList: [],
            currentPage: 1,
            tablePerPage: 10,
            employeeId: '',
            employeeList: [],
            selectedEmployeeIdList: [],
            isShowIframe: false,
            iframeLink: '',
        }
        this.handleClick = this.handleClick.bind(this)
        this.getReport = this.getReport.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.getReportView = this.getReportView.bind(this)
        this.getReportViewList = this.getReportViewList.bind(this)
        this.handleDownloadView = this.handleDownloadView.bind(this)
    }

    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.handleSearch()
        }
    }

    handleClick(event) {
        let listid = Number(event.target.id);
        this.setState({
            currentPage: listid
        });
    }
    getReport() {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .getReportUserProfile_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false, currentPage: 1 })

                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }
    // financial list default
    componentDidMount = () => {
        this.setState({
            isAccessToView: getReportViewAccess(UserProfile),
        })
        clearLocalStorage("financialYear")
        // document.addEventListener('click', this.handleInputClick);

        // document.addEventListener('keydown', this.handleTabKeyPress);
        this.getFillList(financialYearId)
        this.getFillList(trainingEmployeeActionId)
    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }



    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    // get fill List dropdown  
    async getFillList(id, reference) {
        const { is_add } = this.state
        this.setState({ isLoading: true })
        await this.props
            .fill_action(id, reference)
            .then((data) => {
                if (data.error != 1) {
                    if (id === financialYearId) {
                        this.setState({
                            finYearList: data.data,
                        })

                        this.setState({
                            isLoading: false
                        })
                    }
                    if (id == trainingEmployeeActionId) {
                        console.log("trainingEmployeeActionId", data.data)
                        var list = []
                        data.data.map((obj) => {
                            list.push({
                                'value': obj.EmployeeID,
                                'label': obj.Employee
                            });
                        })
                        console.log(list)
                        var list1 = []
                        list.map((obj) => {
                            var name = localStorage.getItem("UserName");
                            if (name.trim() === obj.label.trim()) {
                                console.log("Condition met");
                                list1.push({
                                    'value': obj.value,
                                    'label': obj.label
                                });
                            }
                        })
                        console.log("90099", list1);
                        // , selectedEmployeeIdList: list1
                        this.setState({ employeeList: list, isLoading: false, selectedEmployeeIdList: list1 })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }


    formatData = (data) => {
        return data.map(item => ({
            ...item,
            Date: item.Date ? moment(item.Date).format('DD/MM/YYYY HH:mm:ss') : '',
            TrainingDate: item.TrainingDate ? moment(item.TrainingDate).format('DD/MM/YYYY HH:mm:ss') : '',
            // LeaveToDate: item.LeaveToDate ? moment(item.LeaveToDate).format('DD/MM/YYYY HH:mm:ss') : '',
            ApprovedDate: item.ApprovedDate ? moment(item.ApprovedDate).format('DD/MM/YYYY HH:mm:ss') : ''
        }));
    }

    generateExcel = () => {
        const { listData } = this.state;
        console.log('data generateExcel: ', listData);

        // Prepare data for Excel (xlsx) file
        const worksheet = XLSX.utils.json_to_sheet(listData);

        // Calculate the maximum width for each column
        const columnWidths = listData.reduce((acc, row) => {
            Object.keys(row).forEach((key, idx) => {
                const value = row[key] ? row[key].toString() : '';
                acc[idx] = Math.max(acc[idx] || 10, value.length);
            });
            return acc;
        }, []).map(width => ({ wch: width }));

        worksheet['!cols'] = columnWidths;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate a download link
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'TrainingReport.xlsx';
        a.click();
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 100);
    };

    getReportViewList() {
        const { finYear, selectedEmployeeIdList, rowData, listData, columnArray, trainingList } = this.state


        if (finYear == '') {
            toast.error('Please select Financial year', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        } else {
            console.log()
            this.setState({ isLoading: true })
            const employeedata = selectedEmployeeIdList.map(employee => employee.value).join(',');
            console.log(employeedata)
            this.props
                .getReportUserProfileView_action(finYear, employeedata, '', 1)
                .then((data) => {
                    if (data != null) {
                        this.setState({ isLoading: false, trainingList: data.data })
                        console.log('Report getReportViewList trainingList: ', data.data);
                        if (data.data.length == 0) {
                            toast.error('Record not found', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        }


                    } else {
                        this.setState({ isLoading: false })
                        toast.error('Something went wrong...!', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ isLoading: false })
                });
        }
    }

    getReportView() {
        const { finYear, rowData, selectedEmployeeIdList, listData, columnArray, trainingList } = this.state


        if (finYear == '') {
            toast.error('Please select Financial year', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        } else {
            console.log()
            this.setState({ isLoading: true })
            const employeedata = selectedEmployeeIdList.map(employee => employee.value).join(',');

            this.props
                .getReportUserProfileView_action(finYear, employeedata, '', 1)
                .then((data) => {
                    if (data != null) {
                        this.setState({ isLoading: false })
                        console.log('Report data : ', data.data);
                        if (data.data.length == 0) {
                            toast.error('Record not found', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                        } else {
                            data.data.map((data, index) => {
                                if (index == 0) {
                                    Object.keys(data).map((key, index) => {
                                        columnArray.push(key)
                                    })
                                }
                            })
                            // for row data
                            data.data.map((data, index) => {
                                rowData.push(data)
                            })
                            console.log('rowData data : ', rowData);
                            console.log('columnArray data : ', columnArray);
                            const formattedData = this.formatData(data.data);
                            this.setState({ listData: data.data, rowData: rowData, columnArray: columnArray, dataFetched: true })

                            setTimeout(() => {
                                this.generateExcel();
                            }, 200);
                        }
                        //
                    } else {
                        this.setState({ isLoading: false })
                        toast.error('There is some problem to download excel. Please try again later!', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ isLoading: false })
                });
        }

    }

    record(trainingList) {
        console.log('trainingList: ', trainingList);
        return (
            <div class="table-responsive tbs4 common-table ">
                <table className="table table-bordered tb-action du-table">
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'left' }}>SR No </th>
                            <th style={{ textAlign: 'left' }} >Employee Name </th>
                            <th className='text-center' style={{ width: '100px' }} >Structured Training Hours</th>
                            <th className='text-center' style={{ width: '100px' }} >UnStructured Training Hours</th>
                            <th className='text-center' style={{ width: '150px' }}>Total TrainingHours</th>
                            <th className='text-center' style={{ width: '150px' }}>Required Hours</th>
                            <th className='text-center' style={{ width: '100px' }} >Shortfall Excess</th>

                        </tr>
                    </thead>
                    <tbody>
                        {trainingList.length > 0 ?
                            trainingList.map((data, index) => (
                                <tr>
                                    <td style={{ textAlign: 'left' }}>{data.SRNo}</td>
                                    <td style={{ textAlign: 'left' }}>{data.EmployeeName}</td>
                                    <td className='text-center' style={{ textAlign: 'left' }}>{data.StructuredTrainingHours}</td>
                                    <td className='text-center' style={{ textAlign: 'left' }}>{data.UnStructuredTrainingHours}</td>
                                    <td className='text-center' style={{ textAlign: 'left' }}>{data.TotalTrainingHours}</td>
                                    <td className='text-center' style={{ textAlign: 'left' }}>{data.RequiredHours}</td>
                                    <td className='text-center' style={{ textAlign: 'left' }}>{data.Shortfall_Excess}</td>


                                </tr>
                            )) : <> <tr><td colSpan={7} className='text-center'>Record not found</td></tr></>
                        }
                    </tbody>
                </table>
            </div>
        )
    }
    handleDownloadView(e) {
        e.preventDefault()
        const { finYear, selectedEmployeeIdList } = this.state
        const employeedata = selectedEmployeeIdList.map(employee => employee.value).join(',');

        if (finYear == '') {
            toast.error('Please select Financial year', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        } else {
            console.log()
            this.setState({ isLoading: true })
            this.props
                .getReportUserProfile_action(finYear, employeedata, '', 1)
                .then((data) => {
                    if (data != null) {
                        this.setState({ isLoading: false })
                        console.log('Report data : ', data);
                        var url = `${downloadReport}?SessionIdentifier=${data}`;
                        console.log('Report download url : ', url);
                        // const win = window.location.replace(url, '_blank');
                        // if (win != null) {
                        //     win.focus();
                        // }
                        this.setState({ isShowIframe: true, iframeLink: url.replace('&Export=yes', '') })

                    } else {
                        this.setState({ isLoading: false })
                        toast.error('There is some problem to download report. Please try again later!', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ isLoading: false })
                });
        }
    }

    // download audit plane...............
    handleDownload(e) {
        e.preventDefault()
        const { finYear, selectedEmployeeIdList } = this.state
        const employeedata = selectedEmployeeIdList.map(employee => employee.value).join(',');

        if (finYear == '') {
            toast.error('Please select Financial year', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        } else {
            console.log()
            this.setState({ isLoading: true })
            this.props
                .getReportUserProfile_action(finYear, employeedata, '', 1)
                .then((data) => {
                    if (data != null) {
                        this.setState({ isLoading: false })
                        console.log('Report data : ', data);
                        var url = `${downloadReport}?SessionIdentifier=${data}&Export=Yes`;
                        console.log('Report download url : ', url);
                        const win = window.location.replace(url, '_blank');
                        if (win != null) {
                            win.focus();
                        }
                    } else {
                        this.setState({ isLoading: false })
                        toast.error('There is some problem to download report. Please try again later!', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ isLoading: false })
                });
        }

    }


    //on select fin year
    onSelectFinYearDropdown(e) {
        this.setState({ finYear: e.target.value })
    }



    render() {

        const { isShowIframe, iframeLink, isSearchTab, finYearList, employeeList, selectedEmployeeIdList, finYear, isAccessToView, tablePerPage, currentPage, trainingList,
            isLoading, } = this.state
        const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = trainingList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(trainingList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />


                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>User Profile Report</a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className="page-space-from-bottom">
                                        <div className="row m-0">
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                            {/* <div className="col-md-12 pg-body grid-margin stretch-card d-flex p-2 pt-0"> */}
                                            <div className="form-group col-md-3">
                                                <label>
                                                    Financial Year<span className="required1">*</span>
                                                </label>
                                                <select
                                                    className="js-example-basic-single"
                                                    style={{ width: "100%" }}
                                                    value={finYear}
                                                    onChange={(e) => this.onSelectFinYearDropdown(e)}
                                                >
                                                    <option >Select</option>
                                                    {finYearList.map(item => (
                                                        <option
                                                            key={item.FinYearID}
                                                            value={item.FinYearID}>
                                                            {item.FinancialYear}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-6 pr-0 form-group mx-3">
                                                <label>Employee
                                                    {/* <span class="mendatory_class">*</span> */}
                                                </label>
                                                <Select style={{ Height: '10px !important' }}
                                                    isMulti
                                                    isDisabled={localStorage.getItem('RoleId') != 1 && localStorage.getItem('RoleId') != 4}
                                                    // isClearable={statusId !== '0'}
                                                    // styles={statusId == '0' ? deletedStyles : customStyles}
                                                    styles={customStyles}
                                                    value={selectedEmployeeIdList}
                                                    onChange={e => this.setState({ selectedEmployeeIdList: e })}
                                                    options={employeeList} />
                                            </div>

                                            <div className="form-group col-md-3  filter-btn-col">
                                                <label></label>
                                                <div
                                                    className="mx-2"
                                                    role="group"
                                                    aria-label="Basic example">
                                                    {getReportDownloadAccess(UserProfile) && <button
                                                        type="button"
                                                        className="btn btn-print-audit footer-btn-inner mt-2" style={{ float: 'left' }}
                                                        onClick={e => this.handleDownload(e)}
                                                    >
                                                        Download
                                                    </button>}
                                                    <button
                                                        type="button"
                                                        className="btn btn-print-audit footer-btn-inner mx-2 mt-2" style={{ float: 'left' }}
                                                        onClick={e => this.handleDownloadView(e)}
                                                    >
                                                        View
                                                    </button>
                                                    {/* <button type="button"
                                                    className="btn btn-outline-primary fil-btn p-2 w-auto mr-2 mt-2" style={{ float: 'left' }}
                                                    onClick={this.getReportView}
                                                >
                                                    Export To Excel
                                                </button> */}
                                                </div>
                                            </div>
                                            {isShowIframe ?
                                                <div className='p-0 report-border' style={{ borderTop: '2px solid #686767;' }}>
                                                    <iframe
                                                        style={{ padding: '10px 20px' }}
                                                        title="User profile report"
                                                        width="100%"
                                                        height="500"
                                                        src={`${iframeLink}#toolbar=0&navpanes=0`}
                                                        // src="http://192.168.3.11:8081/doc/WebForm/AasanReportViewer.aspx?SessionIdentifier=aasan_report_1a1032d6-9937-4c52-aa0f-542255bf2843"
                                                        frameBorder="10"
                                                    ></iframe>
                                                </div>
                                                : <></>}

                                            {/* </div> */}
                                            {/* {trainingList.length > 0 ? this.record(currentRecords) : <></>}
                                        {trainingList.length > 0 ? <Pagination
                                            nPages={nPages}
                                            currentPage={currentPage}
                                            setCurrentPage={this.updateItem} /> : <></>} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>


                <ToastContainer />

            </>
        );
    }
}

export default withRouter(connect(null, {
    getReportUserProfile_action, fill_action, getReportUserProfileView_action
})(UserProfileReport));