import { axiosPost, axiosGet } from "../../react_axios/Axios"
import { checkListUrl,checkListDetailsUrl, addcheckListUrl, deletecheckListUrl, updateCheckListUrl, platfarm,fillListUrl, 
    CHECK_LIST_ACTION_TYPE, headers, CHECK_LIST_ADD_ACTION_TYPE, CHECK_LIST_UPDATE_ACTION_TYPE, CHECK_LIST_DELETE_ACTION_TYPE,
    CHECK_LIST_DETAILS_ACTION_TYPE,FILL_LIST_ACTION_TYPE } from "../../utils/constants"


const user_id = localStorage.getItem('UserId')

export const checkList_action = (name, shortName,status,eq) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: name,
            shortName: shortName,
            Status: status,
            EQID : eq != null && eq != 'null' ? eq : 0,
        }
        const res = await axiosGet(checkListUrl, param);
        dispatch({
            type: CHECK_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const checkListDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            Id: id,
            userId: localStorage.getItem('UserId'),
            Platform: platfarm,
        }        

        const res = await axiosGet(checkListDetailsUrl, param);
        dispatch({
            type: CHECK_LIST_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const addCheckList_action = (TypeOfEng,checkListName, shortName, status) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            TypeOfEng: TypeOfEng,
            Name: checkListName,
            Code: shortName,
            EQID: status
        }
        const res = await axiosPost(addcheckListUrl, mapdata, headers);

        dispatch({
            type: CHECK_LIST_ADD_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateCheckList_action = (id, TypeOfEng, checkListName, shortName, status) => async (dispatch) => {
    try {
        const mapdata = {
            id: id,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            TypeOfEng: TypeOfEng,
            ChecklistName: checkListName,
            shortName: shortName,
            EQID: status
        }
        const res = await axiosPost(updateCheckListUrl, mapdata, headers);

        dispatch({
            type: CHECK_LIST_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const deleteCheckList_action = (id) => async (dispatch) => {
    try {
        const param = {
            Id: id,
            userId: localStorage.getItem('UserId'),
            Platform: platfarm,

        }
        const res = await axiosGet(deletecheckListUrl, param);
        dispatch({
            type: CHECK_LIST_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const fill_action = (actionId,reference, reference2) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform:platfarm,
            ActionID : actionId,
            Reference : reference,
            Reference2 : reference2 != "" || reference2 != null ? reference2 : null
        }
        const res = await axiosGet(fillListUrl, param);
        dispatch({
            type: FILL_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};





export const simpleAction = () => dispatch => {
    dispatch({
     type: 'SIMPLE_ACTION',
     payload: 'result_of_simple_action'
    })
   }