import { axiosPost, axiosGet } from "../../react_axios/Axios"
import { designationListUrl, designationAddUrl, designationDetailsUrl, designationUpdateUrl, designationDeleteUrl, DESIGNATION_LIST_ACTION_TYPE, DESIGNATION_ADD_ACTION_TYPE, DESIGNATION_UPDATE_ACTION_TYPE, DESIGNATION_DELETE_ACTION_TYPE, DESIGNATION_DETAILS_ACTION_TYPE, headers, platfarm, ROLEPLAYED_DELETE_ACTION_TYPE, rolePlayedDeleteUrl } from '../../utils/constants'

const user_id = localStorage.getItem('UserId')

export const designationList_action = (designation, reporting, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Designation: designation,
            Reporting: reporting,
            Status: status
        }
        console.log("designationList_action", param)
        const res = await axiosGet(designationListUrl, param);
        dispatch({
            type: DESIGNATION_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const designationAdd_action = (designation, parentId, addedRolePlayedList, ModuleList, ReportList) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Designation: designation,
            ParentDesID: parentId != '' ? parentId : null,
            addedRolePlayedList: addedRolePlayedList,
            ModuleList: ModuleList,
            ReportList: ReportList,
        }
        console.log('designationAdd_action', param)
        const res = await axiosPost(designationAddUrl, param, headers);
        dispatch({
            type: DESIGNATION_ADD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const designationDetails_action = (id) => async (dispatch) => {

    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }
        const res = await axiosGet(designationDetailsUrl, param);
        console.log('designationDetails_action', param)
        dispatch({
            type: DESIGNATION_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
};

export const designationUpdate_action = (designation, parentId, id, addedRolePlayedList, ModuleList, ReportList) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Designation: designation,
            ParentDesID: parentId != '' ? parentId : null,
            ID: id,
            addedRolePlayedList: addedRolePlayedList,
            ModuleList: ModuleList,
            ReportList: ReportList,
        }
        console.log("designationUpdate_action", mapdata)
        const res = await axiosPost(designationUpdateUrl, mapdata, headers);
        dispatch({
            type: DESIGNATION_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
};

export const designationDelete_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }
        console.log("designationDelete_action", param)
        const res = await axiosGet(designationDeleteUrl, param);
        dispatch({
            type: DESIGNATION_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
};


export const rolePlayedDelete_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }
        console.log("SP_Delete_DesignationEngRole", param)
        const res = await axiosGet(rolePlayedDeleteUrl, param);
        dispatch({
            type: ROLEPLAYED_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
};
