import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Loader from '../../../utils/Loader'
import { fill_action } from '../../../actions/master_action/checklist_action';
import { chapterListActionId, mcqStatusActionId, levelActionId } from '../../../utils/constants'
import { TestDetails_action, submitTest_action, } from '../../../actions/mcq_action/test_action';
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';
import Modal from "react-modal";
import { useHistory } from 'react-router-dom';
import usePreventNavigation from '../../../utils/usePreventNavigation'; // Adjust the path as needed
import { event } from 'jquery';
// import { useNavigate, Link, useLocation } from 'react-router-dom';

class StartTest extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAccessToView: true,
            levelID: '',
            allMainTestDataList: [],
            tempAllMainTestDataList: [],
            testTimeDuration: '',
            testTimeDuration1: '',

            currentActiveQuestion: 1,
            currentQuestionID: '',
            testId: '',
            finalResponseList: [],
            score: '',
            isTestAvailable: false,
            isFinalSubmit: false,
            analyseResponse: false,
            is_add: false,
            is_delete: false,
            is_update: false,
            is_finalSubmitModel: false,
            maxUnansweredCount: 0,
            isQuite_testModel: false,
            typeOfTestId: '',
            typeOfTestIdName: '',
            chapterIdName: '',
            chapterId: '',
            level_name: '',

            isBack: false,
            isQuiteBack: false,
            isQuiteTestSkippedModal: false,
            quiteTestSkippedSubmit: '',
            pendingSkipped: '',

        }
        this.getFillList = this.getFillList.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.getTestDetails = this.getTestDetails.bind(this)
        this.onClickLevelBox = this.onClickLevelBox.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.renderer = this.renderer.bind(this);
        this.toggleModalSubmitYes = this.toggleModalSubmitYes.bind(this)
        this.onPopstate = this.onPopstate.bind(this)
        this.toggleQuiteTestSkippedModal = this.toggleQuiteTestSkippedModal.bind(this)

    }


    handlePopState(event) {
        const currentUrl = window.location.href;

        if (currentUrl.includes("disable-page")) {
            this.disableBrowserAction();
        } else {
            this.enableBrowserAction();
        }
    }

    disableBrowserAction() {
        window.browser.browserAction.setIcon({
            path: {
                16: "icons/disabled-icon-16.png",
                48: "icons/disabled-icon-48.png",
                128: "icons/disabled-icon-128.png"
            }
        });

        // Remove click event listener to prevent interaction
        window.browser.browserAction.onClicked.removeListener(this.handleClick);
    }

    enableBrowserAction() {
        window.browser.browserAction.setIcon({
            path: {
                16: "icons/enabled-icon-16.png",
                48: "icons/enabled-icon-48.png",
                128: "icons/enabled-icon-128.png"
            }
        });

        // Reattach the click event listener
        window.browser.browserAction.onClicked.addListener(this.handleClick);
    }

    handleClick(tab) {
        // Your click logic
        console.log('Browser action clicked');
    }

    componentDidMount = () => {

        window.addEventListener('popstate', function (event) {
            this.handlePopState(event);
        });

        // browser.browserAction.onClicked.addListener((tab) => {
        //     browser.browserAction.disable(tab.id);
        //   });

        ///
        // window.addEventListener("popstate", this.onPopstate);
        window.addEventListener("beforeunload", (event) => this.beforeUnloadHandler(event, '1'))

        const previousPage = sessionStorage.getItem('previousPage');
        console.log("Previous Page:", previousPage);

        // Store the current page in session storage for the next navigation
        sessionStorage.setItem('previousPage', window.location.href);
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
            levelID: this.props.location.state != null && this.props.location.state.levelID != null ? this.props.location.state.levelID : '',
            chapterId: this.props.location.state != null && this.props.location.state.chapterId != null ? this.props.location.state.chapterId : '',
            chapterIdName: this.props.location.state != null && this.props.location.state.chapterIdName != null ? this.props.location.state.chapterIdName : '',
            typeOfTestId: this.props.location.state != null && this.props.location.state.typeOfTestId != null ? this.props.location.state.typeOfTestId : '',
            typeOfTestIdName: this.props.location.state != null && this.props.location.state.typeOfTestIdName != null ? this.props.location.state.typeOfTestIdName : '',
            level_name: this.props.location.state != null && this.props.location.state.levelName != null ? this.props.location.state.levelName : '',

        })

        if (previousPage && previousPage.includes('takeTest')) {
            // Perform specific actions based on this condition
            this.getTestDetails()
        } else {
            // console.log("The previous page does not contain 'takeTest'.");
            // Perform alternative actions
            setTimeout(() => {
                this.handleCancel()
            }, 1000);
        }


        this.getFillList(levelActionId, '', '');
        // window.addEventListener("popstate", this.onPopstate, true);


    }



    onPopstate(event) {
        const { allMainTestDataList, tempAllMainTestDataList, testTimeDuration, finalResponseList, } = this.state
        const { pathname } = this.props.location; // Get current pathname from props


        // console.log("called  onPopstate")


        // Included for legacy support, e.g. Chrome/Edge < 119
        // event.returnValue = true;
        // return 'Are you sure you want to leave this page?';

        // event.preventDefault();
        // window.history.pushState(null, null, window.location.href);
        // console.log("pathname 2222221", pathname)
        // if (pathname == '/startTest') {
        //     console.log(`Get current pathname from props: ${pathname}`);
        //     const confirmLeave = window.confirm("Do you really want to quit this test?");
        //     console.log(`confirmLeave : ${confirmLeave}`);
        //     if (confirmLeave) {
        //         console.log("You pressed OK!", pathname);
        //         // Optionally, navigate away or perform other actions
        //         // this.props.navigate(sessionStorage.getItem('previousPage'))
        //     } else {
        //         console.log("You canceled!", pathname);
        //         //  window.history.pushState(null, null, pathname);

        //         //   sessionStorage.setItem('previousPsage', pathname);
        //     }
        // }
        // else {
        //     console.log("else part", pathname);
        //     this.props.navigate(pathname)
        // }

    }

    componentWillUnmount() {
        //  window.removeEventListener("beforeunload", this.beforeUnloadHandler);
        //  window.removeEventListener("popstate", this.onPopstate);

    }

    beforeUnloadHandler(event, flag) {
        console.log("called  beforeUnloadHandler ---------1")
        // Recommended
        // Included for legacy support, e.g. Chrome/Edge < 119
        if (flag == 1) {
            event.preventDefault(); // For legacy support, but not needed for modern browsers
            event.returnValue = true // Shows the confirmation popup
        } else {
            event.preventDefault(); 
            event.returnValue = false
        }
    };


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };


    numberToWords = (num) => {
        const words = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen"];
        return words[num] || num.toString();
    };

    //get details
    getTestDetails() {
        setTimeout(() => {
            var id = this.state.levelID
            var ChapterID = this.state.chapterId
            var TypeOfTestID = this.state.typeOfTestId
            this.props
                .TestDetails_action(id, ChapterID, TypeOfTestID)
                .then((data) => {
                    if (data.error != 1) {
                        if (data.data.length == 0) {
                            this.setState({ isLoading: false, isTestAvailable: false })
                            toast.error('Test not available.', {
                                theme: 'colored',
                                autoClose: 3000,
                                hideProgressBar: true,
                            })
                            setTimeout(() => {
                                this.handleCancel()
                            }, 1000);
                        } else {
                            const questionsWithSerial = data.data.map((question, index) => ({
                                ...question,
                                sr_no: index + 1,
                                isAnswered: false,
                                isUnanswered: false,
                                isFinalSubmit: false,
                                SelectedOption: 0,
                            }));
                            // console.log('Time 456')
                            // console.log(data.data[0]['VALUE'])
                            // console.log((parseInt(data.data[0]['VALUE']) * 60 * 1000))
                            // data.data[0]['VALUE'] = '90'
                            // const minutes = parseInt(data.data[0]['VALUE']);
                            // const durationInMilliseconds = minutes * 60 * 1000;
                            // const testTimeDuration1 = durationInMilliseconds + Date.now();
                            // console.log("8098", testTimeDuration1)

                            // const minutes = Math.floor(parseInt(data.data[0]['VALUE']) / 60);
                            // const seconds = parseInt(data.data[0]['VALUE']) - minutes * 60;
                            this.setState({
                                allMainTestDataList: questionsWithSerial,
                                tempAllMainTestDataList: questionsWithSerial,
                                testId: parseInt(data.data[0]['TestID']),
                                testTimeDuration: Date.now() + (parseInt(data.data[0]['VALUE']) * 60 * 1000),
                                // testTimeDuration: (parseInt(data.data[0]['VALUE']) * 60 * 1000) + Date.now(),
                                testTimeDuration1: parseInt(data.data[0]['VALUE']),
                                isLoading: false,
                                isTestAvailable: true
                            })
                            this.setState({ isLoading: false })
                        }


                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        setTimeout(() => {
                            this.handleCancel()
                        }, 1000);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 500);
    }

    // onClickLevelBox(clickedNo) {
    //     const { is_update, isFinalSubmit } = this.state
    //     if (isFinalSubmit == false) {
    //         this.setState({
    //             currentActiveQuestion: parseInt(clickedNo)
    //         })
    //     }
    // }


    onClickLevelBox(clickedNo) {
        const { is_update, isFinalSubmit, currentActiveQuestion, tempAllMainTestDataList, maxUnansweredCount } = this.state
        if (isFinalSubmit == false) {

            this.setState(prevState => ({
                maxUnansweredCount: prevState.maxUnansweredCount <= clickedNo ? clickedNo : prevState.maxUnansweredCount
            }), () => {
                // // Log the updated state value in the callback
                console.log("Max sr_no of unanswered questions:", this.state.maxUnansweredCount);

                // // Use setTimeout if needed to log after a delay
                // setTimeout(() => {
                //     console.log("Max sr_no of unanswered questions after timeout:", this.state.maxUnansweredCount);
                // }, 200);
            });

            // to submit previous 
            const answeredList = tempAllMainTestDataList.map((question, index) => question.sr_no == currentActiveQuestion ? ({
                ...question,
                isAnswered: question.SelectedOption != 0 ? true : false,
                isUnanswered: question.SelectedOption == 0 ? true : false,
                // isFinalSubmit: false,
                // clickedNo != 1 &&
            }) : {
                ...question,
                isUnanswered: question.SelectedOption == 0 && index <= (question.sr_no <= maxUnansweredCount ? maxUnansweredCount : clickedNo - 1) && index != (clickedNo - 1)
                    ? true : false,
            });
            //&&      question.sr_no <= maxUnansweredCount
            // index <= (clickedNo - 1)  && index != (clickedNo - 1) 
            // cono
            this.setState({
                tempAllMainTestDataList: answeredList,
            })
            this.handleUpdate(false)
            //
            this.setState({
                currentActiveQuestion: parseInt(clickedNo)
            })
        }
    }

    // previous
    onClickPrevious() {
        const { is_update, currentActiveQuestion, tempAllMainTestDataList, maxUnansweredCount } = this.state
        if (currentActiveQuestion != 1) {
            this.setState(prevState =>
                ({ currentActiveQuestion: currentActiveQuestion - 1 }), () => { });
            console.log("Max sr_no of unanswered questions 1:", this.state.maxUnansweredCount);

            console.log("99999", currentActiveQuestion - 1);
            console.log(currentActiveQuestion);
            const answeredList = tempAllMainTestDataList.map((question, index) => question.sr_no == currentActiveQuestion ? ({
                ...question,
                isAnswered: question.SelectedOption != 0 ? true : false,
                isUnanswered: currentActiveQuestion != 1 && question.SelectedOption == 0 ? true : false,

                // isUnanswered: question.SelectedOption == 0  ? true : false,
                // isFinalSubmit: false,

            }) :
                {
                    ...question,
                    isUnanswered: question.SelectedOption == 0 && question.sr_no != currentActiveQuestion
                        && index <= maxUnansweredCount - 1
                        ? true : false,
                });
            console.log("Max sr_no of unanswered questions 2:", this.state.maxUnansweredCount);

            this.setState({
                tempAllMainTestDataList: answeredList
            })
            this.handleUpdate(false)
        }

    }

    // skip
    onClickSkip() {
        const { is_update, currentActiveQuestion, tempAllMainTestDataList } = this.state
        if (tempAllMainTestDataList.length != currentActiveQuestion) {
            this.setState({
                currentActiveQuestion: currentActiveQuestion + 1,
            })
        }

        const answeredList = tempAllMainTestDataList.map((question, index) => question.sr_no == currentActiveQuestion ? ({
            ...question,
            // isAnswered : question.SelectedOption != 0  ? true : false,
            isUnanswered: question.SelectedOption == 0 ? true : false,
            // isFinalSubmit: false,

        }) : { ...question });
        if (tempAllMainTestDataList.length != currentActiveQuestion) {
            this.setState({
                tempAllMainTestDataList: answeredList
            })
            this.setState(prevState => ({
                maxUnansweredCount: prevState.maxUnansweredCount <= currentActiveQuestion ? currentActiveQuestion + 1 : prevState.maxUnansweredCount
            }), () => {
                console.log("Max sr_no of unanswered questions onClickSkip:", currentActiveQuestion);
                console.log("Max sr_no of unanswered questions onClickSkip:", this.state.maxUnansweredCount);
            });
        }
        else {
            // this.setState({
            //     isFinalSubmit: true
            // })
        }

    }


    // next
    onClickNext() {
        const { is_update, currentActiveQuestion, tempAllMainTestDataList, maxUnansweredCount } = this.state
        const answeredList = tempAllMainTestDataList.map((question, index) => question.sr_no == currentActiveQuestion ? ({
            ...question,
            isAnswered: question.SelectedOption != 0 ? true : false,
            isUnanswered: question.SelectedOption == 0 ? true : false,

            // isUnanswered: question.SelectedOption == 0  ? true : false,
            // isFinalSubmit: false,

        }) :
            {
                ...question,
                isUnanswered: question.SelectedOption == 0 && question.sr_no != currentActiveQuestion
                    && index <= (question.sr_no <= maxUnansweredCount ? maxUnansweredCount : currentActiveQuestion - 1) && index != (currentActiveQuestion - 1)
                    ? true : false,
            });
        this.setState({
            currentActiveQuestion: currentActiveQuestion + 1,
            tempAllMainTestDataList: answeredList
        })


        // tempAllMainTestDataList.length - 1 ? currentActiveQuestion : 
        console.log('currentActiveQuestion', currentActiveQuestion)
        console.log('tempAllMainTestDataList.length', tempAllMainTestDataList.length)

        // this.handleUpdate(false)
        // Check if the current question is the last one
        const isLastQuestion = currentActiveQuestion === tempAllMainTestDataList.length;
        const isLastQuestionAnswered = tempAllMainTestDataList[tempAllMainTestDataList.length - 1].isAnswered;

        if (isLastQuestion) {
            console.log('123')
            this.setState({
                // is_finalSubmitModel: true,
                currentActiveQuestion: currentActiveQuestion,
            })
        } else {
            console.log('12435453')
            this.handleUpdate(false)
        }

    }

    // on select option
    onSelectOption(srNo, optionNumber, element) {
        const { is_update, currentActiveQuestion, tempAllMainTestDataList, currentQuestionID } = this.state

        const answeredList = tempAllMainTestDataList.map((question, index) => question.sr_no == srNo ? ({
            ...question,
            SelectedOption: optionNumber,
        }) : { ...question });

        console.log(answeredList)
        this.setState({
            tempAllMainTestDataList: answeredList,
            currentQuestionID: element.QuestionID
        })
    }

    toggleModalSubmitYes() {
        // this.handleUpdate(false)
        this.handleUpdate(true)
        this.props.navigate('/startTest/Submitted')
    }

    toggleModalSubmitNo() {
        const { currentActiveQuestion, tempAllMainTestDataList, isFinalSubmit, levelID, testId, currentQuestionID } = this.state

        this.handleUpdate(false)
        // this.toggleQuiteTestSkippedModal()
        // if([tempAllMainTestDataList.length - 1] == currentQuestionID ){
        //     this.setState({
        //         // currentActiveQuestion:  currentActiveQuestion - 2,
        //         is_finalSubmitModel: true ,
        //         // isFinalSubmit : true
        //     })
        // }else{
        //     this.handleUpdate(false)
        // }
    }

    toggleQuiteTestSkippedModal() {
        const { isQuiteTestSkippedModal, quiteTestSkippedSubmit } = this.state

        this.setState({ isQuiteTestSkippedModal: isQuiteTestSkippedModal })
    }

    //update working day
    handleUpdate(isFinalSubmitClick) {
        const { currentActiveQuestion, isQuiteTestSkippedModal, tempAllMainTestDataList, isFinalSubmit, levelID, testId, currentQuestionID } = this.state
        const id = levelID
        // isQuiteTestSkippedModal  : false,
        // quiteTestSkippedSubmit: '',
        // prepare udt test dtlID, question Id, SelectedOption 
        console.log("tempAllMainTestDataList 190090", tempAllMainTestDataList)
        // console.log("tempAllMainTestDataList 1",  item.sr_no == currentActiveQuestion)
        // console.log("tempAllMainTestDataList 1",  item.sr_no)
        console.log("currentActiveQuestion 1", currentActiveQuestion)

        // if (isFinalSubmitClick) {
        //     this.setState({ isQuiteTestSkippedModal: !isQuiteTestSkippedModal })
        //     // Filter questions where isUnanswered is true
        //    var unansweredQuestions = tempAllMainTestDataList.map((q) => {
        //     q.isUnanswered == true
        //    });
        //     console.log("unansweredQuestions 1", unansweredQuestions)
        // }

        // Filter the items where SelectedOption is not 0, then map the desired fields
        const extractData = tempAllMainTestDataList
            .filter(item => item.QuestionID == currentQuestionID)
            .map(item => ({
                TestDtlID: parseInt(item.TestDtlID),
                QuestionID: parseInt(item.QuestionID),
                SelectedOption: parseInt(item.SelectedOption)
            }));
        console.log("extractData 1", extractData)

        this.setState({ isLoading: true })
        this.props
            .submitTest_action(isFinalSubmitClick, testId, extractData)
            .then((data) => {
                if (data.error == 0) {
                    // isFinalSubmitClick && toast.success('Test submitted successfully', {
                    //     theme: 'colored',
                    //     autoClose: 3000,
                    //     hideProgressBar: true,
                    // })
                    console.log('submitTest_action', data.data.length > 0 ? data.data[0][0]['score'] : "",)
                    if (isFinalSubmitClick) {
                        console.log("submit--------------------------->")
                        window.addEventListener("beforeunload", (event) => this.beforeUnloadHandler(event, '0'))
                    }
                    this.setState({
                        isLoading: false, is_finalSubmitModel: false,
                        isFinalSubmit: isFinalSubmitClick,
                        score: data.data.length > 0 ? data.data[0][0]['score'] : "",
                        finalResponseList: data.data.length > 0 ? data.data[1] : [],
                    })
                    return
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    //Fill list
    getFillList(value, ref, ref2) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {

                }
            })
    }

    //cancel
    handleCancel() {
        this.props.navigate('/takeTest')
    }

    //add working day
    handleAdd(e) {
        e.preventDefault()
        const { dayOfTheWeek, levelID, week1Status, week2Status, week3Status, week4Status, week5Status } = this.state
        const id = levelID

        if (dayOfTheWeek != "" && week1Status != "" && week2Status != "" && week3Status != "" && week4Status != "" && week5Status != "") {
            this.setState({ isLoading: true })
            this.props
                .submitTest_action(dayOfTheWeek.trim(), week1Status, week2Status, week3Status, week4Status, week5Status)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.handleCancel()
                        }, 500);
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }



    renderer({ hours, minutes, seconds, completed }) {
        if (!this.state) return null; // Ensure this.state is defined

        const { isOpen, isFinalSubmit, is_finalSubmitModel, countdownCompleted } = this.state;
        // completed && !countdownCompleted && isFinalSubmit == false
        if (completed) {
            console.log('isFinalSubmit 22', completed, countdownCompleted, isFinalSubmit)
            this.setState({
                isFinalSubmit: true,
            });
            this.handleUpdate(true);
            // Render a completed state
            return <></>;
        } else {
            // Render a countdown
            return <span style={{ fontSize: '17px' }}><b>{isFinalSubmit ? '' : `${hours} : ${minutes} : ${seconds} Min`}</b></span>;
        }
    }

    toggleModal(id) {
        const { tempAllMainTestDataList, isOpen, isFinalSubmit, is_finalSubmitModel, isQuiteTestSkippedModal } = this.state

        // this.setState({ is_finalSubmitModel: !is_finalSubmitModel })
        console.log("7687", tempAllMainTestDataList)
        var count = 0
        // Filter questions where isUnanswered is true
        tempAllMainTestDataList.map((q) => {
            if (q.isUnanswered || q.SelectedOption == 0) {
                count = count + 1
            }
        });
        console.log("7687", count)
        // if (count > 0) {
        //     this.setState({ pendingSkipped: count, isQuiteTestSkippedModal: !isQuiteTestSkippedModal })
        // } else {
        //     this.setState({ is_finalSubmitModel: !is_finalSubmitModel })
        // }

        if (count > 0) {
            this.setState({ pendingSkipped: count, is_finalSubmitModel: !is_finalSubmitModel })
        }
        else {
            this.setState({ is_finalSubmitModel: !is_finalSubmitModel })
        }

    }

    afterSkippedSubmit() {
        const { tempAllMainTestDataList, isOpen, isFinalSubmit, is_finalSubmitModel, isQuiteTestSkippedModal } = this.state
        this.setState({ isQuiteTestSkippedModal: false, })
        setTimeout(() => {
            this.setState({ is_finalSubmitModel: !is_finalSubmitModel })
        }, 500);

    }

    finalSubmitModal() {
        const { isOpen, isFinalSubmit, is_finalSubmitModel } = this.state
        this.setState({ is_finalSubmitModel: !is_finalSubmitModel })
    }


    render() {
        const { pendingSkipped, isQuiteBack, isQuiteTestSkippedModal, testTimeDuration, isTestAvailable, isAccessToView, isLoading, isFinalSubmit, levelID, chapterId, typeOfTestId, level_name, typeOfTestIdName, chapterIdName,
            allMainTestDataList, tempAllMainTestDataList, analyseResponse, currentQuestionID, instructionId,
            currentActiveQuestion, isQuite_testModel, is_finalSubmitModel, score, finalResponseList, testTimeDuration1,
        } = this.state
        return (<>
            {isLoading ? (
                <Loader></Loader>
            ) : (
                null
            )}
            <Header />
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                    {/* Left side menu */}
                    <Drawer />
                    {/* end left menu */}
                    {/* start page */}
                    {isAccessToView ? (
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum" style={{ borderBottom: 'none' }}>
                                    <ul class="breadcrumb d-flex" style={{ border: '1px solid #c8c8c8' }}>
                                        <li><a onClick={this.handleCancel}>Start a Test</a></li>
                                        <li></li>
                                    </ul>
                                    {/* <div class="status-top mr-2 text-dark" style={{ position: 'absolute', right: '0px', top: '62' }}>
                                        <p>Status : <strong class="text-success">Active</strong></p>
                                    </div> */}

                                    {/*  // <Countdown date={testTimeDuration} style={{ cursor: 'none' }}>
                                                    <Countdown date={Date.now() + (testTimeDuration1 * 60 * 1000)}>
                                                    renderer={this.renderer}
                                                 </Countdown> */}


                                </div>
                                <div className='page-space-from-bottom'>
                                    <div className="row">
                                        <style
                                            type="text/css"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                            }}
                                        />
                                        <div className="card">
                                            <div className="card-body mcq-test-bg p-1">
                                                <div className="d-flex flex-wrap row m-0">
                                                    <div className="border rounded-2 p-0 mt-3" style={{ position: 'relative' }}>
                                                        <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                                                            {isFinalSubmit == false ?
                                                                <div className="d-flex border-bottom mt-1">
                                                                    <p className="p-2 pb-1 mb-1 pt-1 rounded-top text-primary"> Chapter: <b> {(chapterIdName)}</b></p>
                                                                    <p className="p-2 pb-1 mb-1 pt-1 rounded-top text-primary"> Level: <b>{level_name}</b></p>
                                                                    <p className="p-2 pb-1 mb-1 pt-1 rounded-top text-primary"> Test Type: <b>{(typeOfTestIdName)}</b></p>

                                                                </div> :
                                                                <div></div>}
                                                            <div className='test-timer p-0 text-danger m-2' >
                                                                <b> {isFinalSubmit ?
                                                                    <button className="btn btn-outline-info p-2 w-auto m-auto d-block"
                                                                        onClick={this.handleCancel}>Take New Test</button> : `Remaining Time:`} </b>
                                                                <b> {isFinalSubmit ? "" :
                                                                    testTimeDuration != "" ?

                                                                        <Countdown
                                                                            date={testTimeDuration}
                                                                            renderer={this.renderer}
                                                                        />
                                                                        : <></>

                                                                }  </b>
                                                            </div>
                                                        </div>
                                                        {/* {isTestAvailable ?
                                                            <div className='test-timer'>
                                                                <h6 className="p-2 rounded-top text-danger rem-time mt-2"><b> {isFinalSubmit ? <button className="btn btn-outline-info p-2 w-auto m-auto d-block"
                                                                    onClick={this.handleCancel}>Take New Test</button> : `Remaining Time:`} </b>
                                                                    <b> {isFinalSubmit ? "" :
                                                                        testTimeDuration != "" ?

                                                                            <Countdown
                                                                                date={testTimeDuration}
                                                                                renderer={this.renderer}
                                                                            />
                                                                            : <></>

                                                                    }  </b>
                                                                </h6>
                                                            </div> : <></>} */}
                                                        <div className="row m-0">
                                                            {isFinalSubmit == false ?
                                                                <div className="col-md-2 p-0 pl-2" style={{ borderRight: '1px solid #c0c0c0' }}>
                                                                    <h5 className="p-2 mb-1 border-bottom px-3"> {isFinalSubmit ? allMainTestDataList.length : currentActiveQuestion} / {allMainTestDataList.length}</h5>
                                                                    <div className="qus-count d-flex mt-2">
                                                                        <ul >
                                                                            {
                                                                                tempAllMainTestDataList.length > 0 ? tempAllMainTestDataList.map((element, index) => {
                                                                                    return <li className={element.isAnswered && element.sr_no != currentActiveQuestion ? 'bg-success text-white' :
                                                                                        element.isUnanswered && element.sr_no != currentActiveQuestion ? 'bg-danger text-white' : element.sr_no == currentActiveQuestion ? 'bg-primary text-white' : 'text-primary'}
                                                                                        // style={{ border: '1px solid #34b1aa' }}
                                                                                        style={{
                                                                                            border: element.isAnswered && element.sr_no != currentActiveQuestion ? '1px solid  var(--bs-success)' :
                                                                                                element.isUnanswered && element.sr_no != currentActiveQuestion ? '1px solid var(--bs-danger)' : '1px solid var(--bs-primary)'
                                                                                        }}
                                                                                        onClick={() => this.onClickLevelBox(element.sr_no)}>{element.sr_no}</li>
                                                                                }) : <></>
                                                                            }

                                                                        </ul><br />
                                                                    </div>
                                                                    <div className="p-2 test-leg pt-0">

                                                                        <p className="text-success"><i className="fa fa-square-o bg-success" style={{
                                                                            borderRadius: "2px"
                                                                        }} /> Answered</p>
                                                                        <p className="text-danger"><i className="fa fa-square-o bg-danger" style={{
                                                                            borderRadius: "2px"
                                                                        }} /> Skipped </p>
                                                                        <p className="text-primary"><i className="fa fa-square-o" /> Unanswered</p>
                                                                        {/* <p className="text-primary"><i className="fa fa-square-o" /> Skipped</p> */}
                                                                    </div>
                                                                </div>

                                                                : <></>}
                                                            {/* // question */}
                                                            {

                                                                tempAllMainTestDataList.length > 0 ? tempAllMainTestDataList.map((element, index) => {
                                                                    return currentActiveQuestion == element.sr_no && isFinalSubmit == false ?
                                                                        <div className="col-md-10" style={{ borderTop: '1px solid #dee2e6' }}>
                                                                            <h6 className="mt-3"> {element.Question}</h6>
                                                                            <hr className=" mt-3 mb-3" style={{ width: '100%', border: '1px solid #898989' }} />
                                                                            {element.Option1 !== '-' && element.Option1 !== '' ? <div className="rounded-1 opt-bg p-2 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="d-flex"><input name="abceef" defaultValue="Option-five" id={14} type="radio"
                                                                                        className="h-auto mr-1" checked={element.SelectedOption == '1'}
                                                                                        onChange={() => this.onSelectOption(element.sr_no, 1, element)} />{element.Option1}</label>
                                                                                </div>
                                                                            </div> : <></>}
                                                                            {element.Option2 !== '-' && element.Option2 !== '' ? <div className="rounded-1 opt-bg p-2 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="d-flex"><input name="abceef" defaultValue="Option-five" id={14} checked={element.SelectedOption == '2'}
                                                                                        type="radio" className="h-auto mr-1" onChange={() => this.onSelectOption(element.sr_no, 2, element)} />{element.Option2}</label>
                                                                                </div>
                                                                            </div> : <></>}
                                                                            {element.Option3 !== '-' && element.Option3 !== '' ? <div className="rounded-1 opt-bg p-2 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="d-flex"><input name="abceef" defaultValue="Option-five" checked={element.SelectedOption == '3'}
                                                                                        id={14} type="radio" className="h-auto mr-1" onChange={() => this.onSelectOption(element.sr_no, 3, element)} />{element.Option3}</label>
                                                                                </div>
                                                                            </div> : <></>}
                                                                            {element.Option4 !== '-' && element.Option4 !== '' ? <div className="rounded-1 opt-bg p-2 mb-2">
                                                                                <div className="form-group">
                                                                                    <label className="d-flex"><input name="abceef" defaultValue="Option-five" checked={element.SelectedOption == '4'}
                                                                                        id={14} type="radio" className="h-auto mr-1" onChange={() => this.onSelectOption(element.sr_no, 4, element)} />{element.Option4}</label>
                                                                                </div>
                                                                            </div> : <></>}
                                                                        </div> :
                                                                        <>      {isFinalSubmit && index == 0 && <div className="col-md-12 p-5">
                                                                            <h5 className="mt-4"> You have Successfully Submitted the Test.</h5>
                                                                            <h3 className=" text-success pt-2">Your {score} </h3>
                                                                            {analyseResponse == false ? <button className="btn btn-primary p-2 mt-4 w-auto m-auto d-block"
                                                                                onClick={() => this.setState({ analyseResponse: true })}>Analyse your Response</button> : <></>}
                                                                            {/* <button className="btn btn-warning p-2 mt-4 w-auto m-auto d-block"
                                                                                onClick={this.handleCancel}>Take New Test</button> */}

                                                                        </div>} </>

                                                                }) : <>
                                                                </>

                                                            }


                                                        </div>
                                                    </div>
                                                </div>

                                                {analyseResponse && <div className="d-flex flex-wrap row m-0 mt-2">
                                                    <div className="p-2 mt-2 pb-0 test-leg pt-0 float-right text-end">
                                                        <p className="text-danger float-right"><i className="fa fa-square-o" /> Wrong answer</p>
                                                        <p className="text-success float-right mx-2"><i className="fa fa-square-o" /> Correct answer</p>
                                                    </div>
                                                    {
                                                        finalResponseList.length > 0 ?
                                                            finalResponseList.map((element, index) => {
                                                                return <><div className="col-md-12">
                                                                    <h6 className="mb-3 mt-3">{++index}.{element.Question}</h6>
                                                                    {element.Option1 !== '-' && element.Option1 !== '' ?
                                                                        <div className="rounded-1 mb-2"
                                                                            style={{
                                                                                background: element.AnswerKey == 1 ? '#b6e9d6' :
                                                                                    element.SelectedOption == 1 ? '#ffe2e2' : '#fefefe',
                                                                                border: element.AnswerKey == 1 ? '1px solid #0eb477' :
                                                                                    element.SelectedOption == 1 ? '1px solid #f78686' : '', padding: '8px'
                                                                            }}>
                                                                            <div className="form-group">
                                                                                <label className="d-flex">
                                                                                    <input name={`question_${index}`} defaultValue="Option-four" id={13}
                                                                                        type="radio" className="h-auto mr-1" value={1} checked={element.SelectedOption === 1} />{element.Option1}</label>
                                                                            </div>
                                                                        </div> : <></>}
                                                                    {element.Option2 !== '-' && element.Option2 !== '' ?
                                                                        <div className="rounded-1 mb-2"
                                                                            style={{
                                                                                background: element.AnswerKey == 2 ? '#b6e9d6' :
                                                                                    element.SelectedOption == 2 ? '#ffe2e2' : '#fefefe',
                                                                                border: element.AnswerKey == 2 ? '1px solid #0eb477' :
                                                                                    element.SelectedOption == 2 ? '1px solid #f78686' : '', padding: '8px'
                                                                            }}>
                                                                            <div className="form-group">
                                                                                <label className="d-flex"><input name={`question_${index}`} defaultValue="Option-four" id={13}
                                                                                    type="radio" className="h-auto mr-1" value={2} checked={element.SelectedOption === 2} /> {element.Option2}</label>
                                                                            </div>
                                                                        </div> : <></>}
                                                                    {element.Option3 !== '-' && element.Option3 !== '' ?
                                                                        <div className="rounded-1 mb-2"
                                                                            style={{
                                                                                background: element.AnswerKey == 3 ? '#b6e9d6' :
                                                                                    element.SelectedOption == 3 ? '#ffe2e2' : '#fefefe',
                                                                                border: element.AnswerKey == 3 ? '1px solid #0eb477' :
                                                                                    element.SelectedOption == 3 ? '1px solid #f78686' : '', padding: '8px'
                                                                            }}>
                                                                            <div className="form-group">
                                                                                <label className="d-flex"><input name={`question_${index}`} defaultValue="Option-four" id={13}
                                                                                    type="radio" className="h-auto mr-1" value={3} checked={element.SelectedOption === 3} /> {element.Option3}</label>
                                                                            </div>
                                                                        </div>
                                                                        : <></>}
                                                                    {element.Option4 !== '-' && element.Option4 !== '' ?
                                                                        <div className="rounded-1 mb-2"
                                                                            style={{
                                                                                background: element.AnswerKey == 4 ? '#b6e9d6' :
                                                                                    element.SelectedOption == 4 ? '#ffe2e2' : '#fefefe',
                                                                                border: element.AnswerKey == 4 ? '1px solid #0eb477' :
                                                                                    element.SelectedOption == 4 ? '1px solid #f78686' : '', padding: '8px'
                                                                            }}>
                                                                            <div className="form-group">
                                                                                <label className="d-flex"><input name={`question_${index}`} defaultValue="Option-four" id={13}
                                                                                    type="radio" className="h-auto mr-1" value={4} checked={element.SelectedOption === 4} />  {element.Option4}</label>
                                                                            </div>
                                                                        </div>
                                                                        : <></>}
                                                                    <hr className='mb-0' />
                                                                </div>

                                                                </>
                                                            }) : <></>}
                                                    <hr className="m-2 mt-3 mb-1" style={{ width: '100%', border: '1px solid #a5a5a5' }} />
                                                    <div className="col-md-12">
                                                        <button type="button" className="btn btn-cancel footer-btn-inner mr-1 mt-3 w-auto"
                                                            style={{ float: 'right' }}
                                                            onClick={() => this.handleCancel()} >Close</button>
                                                    </div>
                                                </div>}
                                                <div style={{ height: '200px' }} />
                                                {isFinalSubmit == false && <div className="row">
                                                    <div className="footer-btn">
                                                        <div className="col-md-3">
                                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                                <button type="button" className="btn btn-cancel footer-btn-inner mr-1"
                                                                    disabled={currentQuestionID == 1}
                                                                    onClick={currentQuestionID == 1 ? " " : () => this.onClickPrevious()}>Previous</button>

                                                                <button type="button" className="btn btn-save footer-btn-inner mr-1"
                                                                    disabled={[tempAllMainTestDataList.length - 1] == currentQuestionID}
                                                                    onClick={[tempAllMainTestDataList.length - 1] == currentQuestionID ? "" : () => this.onClickSkip()}>Skip</button>
                                                                {/* // this.setState({ is_finalSubmitModel: true}) */}
                                                                <button type="button" className={[tempAllMainTestDataList.length - 1] == currentQuestionID ? 'btn btn-next footer-btn-inner mr-1' :
                                                                    'btn btn-next footer-btn-inner mr-1'
                                                                }
                                                                    disabled={[tempAllMainTestDataList.length - 1] == currentQuestionID}
                                                                    onClick={[tempAllMainTestDataList.length - 1] == currentQuestionID ? '' : () => this.onClickNext()} >Next</button>
                                                                <button type="button" className="btn btn-update footer-btn-inner mr-1" onClick={() => this.toggleModal()} >Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                                {/* Button trigger modal */}
                                            </div>

                                            {/* // */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                    </div>)}
                    {/* end page */}
                </div>
                {/* page-body-wrapper ends */}
            </div>
            <ToastContainer />
            <Modal isOpen={is_finalSubmitModel} onRequestClose={this.toggleModal}>
                <div className="modal-dialog custom_modal_dialog">
                    <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                        <div class="modal-header pt-1 bg-success">
                            <h4 class="modal-title modal_title_text">Submit Test</h4>
                            {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
                            <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-12">
                                    {pendingSkipped == 0 ? <h3 class="pop_label pt-3 pb-1">Are you sure do you want to submit?</h3> :
                                        <h3 class="pop_label pt-3 pb-1">You have skipped {pendingSkipped} question are you sure want to submit this test?</h3>}
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer p-1">
                            <div class="form-group text-right">
                                <button class="btn btn-outline-success del-pop-btn" onClick={(e) => this.toggleModalSubmitYes()}>Yes</button>
                                <button class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={(e) => this.toggleModalSubmitNo()}> No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={isQuite_testModel} onRequestClose={this.finalSubmitModal}>
                <div className="modal-dialog custom_modal_dialog">
                    <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                        <div class="modal-header pt-1 bg-success">
                            <h4 class="modal-title modal_title_text">Quit Test</h4>
                            {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
                            <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.finalSubmitModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <h3 class="pop_label pt-3 pb-1">Are you sure do you want to quite?</h3>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer p-1">
                            <div class="form-group text-right">
                                <button class="btn btn-outline-success del-pop-btn" onClick={(e) => this.finalSubmitModal()}>Yes</button>
                                <button class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={(e) => this.toggleModalSubmitNo()}> No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={isQuiteTestSkippedModal} onRequestClose={() => this.setState({ isQuiteTestSkippedModal: false })}>
                <div className="modal-dialog custom_modal_dialog">
                    <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                        <div class="modal-header pt-1 bg-success">
                            <h4 class="modal-title modal_title_text">Submit Test</h4>
                            {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
                            <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isQuiteTestSkippedModal: false, is_finalSubmitModel: false })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <h3 class="pop_label pt-3 pb-1">You have skipped {pendingSkipped} question are you sure want to submit this test?</h3>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer p-1">
                            <div class="form-group text-right">
                                <button class="btn btn-outline-success del-pop-btn" onClick={(e) => this.afterSkippedSubmit()}>Yes</button>
                                <button class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={(e) => this.setState({ isQuiteTestSkippedModal: false, is_finalSubmitModel: false })}> No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={isQuiteBack} onRequestClose={() => this.setState({ isQuiteBack: !isQuiteBack })}>
                <div className="modal-dialog custom_modal_dialog">
                    <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                        <div class="modal-header pt-1 bg-success">
                            <h4 class="modal-title modal_title_text">Quite Test</h4>
                            {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
                            <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.finalSubmitModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <h3 class="pop_label pt-3 pb-1">Are you sure do you want to quite?</h3>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer p-1">
                            <div class="form-group text-right">
                                <button class="btn btn-outline-success del-pop-btn" onClick={(e) => this.finalSubmitModal()}>Yes</button>
                                <button class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={() => this.setState({ isQuiteBack: !isQuiteBack })}> No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </>)
    }
}

export default withRouter(connect(null, {
    fill_action, TestDetails_action,
    submitTest_action,
})(StartTest))