import { CLIENT_CATEGORY_LIST_ACTION_TYPE, CLIENT_CATEGORY_ADD_ACTION_TYPE, CLIENT_CATEGORY_UPDATE_ACTION_TYPE, CLIENT_CATEGORY_DETAILS_ACTION_TYPE, CLIENT_CATEGORY_DETETE_ACTION_TYPE } from "../../utils/constants";

const initialState = []

function clientCategoryReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case CLIENT_CATEGORY_LIST_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_CATEGORY_ADD_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_CATEGORY_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_CATEGORY_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_CATEGORY_DETETE_ACTION_TYPE:
            return [...state, payload]
        default:
            return state;
    }
}

export default clientCategoryReducer