import { axiosPost, axiosGet } from "../../react_axios/Axios"
import { taskTypeListUrl, taskTypeDetailsUrl, taskTypeAddUrl, taskTypeUpdateUrl, taskTypeDeleteUrl, TASKTYPE_LIST_ACTION_TYPE, TASKTYPE_DETAILS_ACTION_TYPE, TASKTYPE_ADD_ACTION_TYPE, TASKTYPE_UPDATE_ACTION_TYPE, TASKTYPE_DELETE_ACTION_TYPE,  platfarm, headers } from '../../utils/constants'

const user_id = localStorage.getItem('UserId')

export const taskTypeList_action = (taskTypeName, status)=> async(dispatch) => {
    try{
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: taskTypeName,
            Status:status
        }
        console.log("taskTypeList_action",param)
        const res = await axiosGet(taskTypeListUrl, param);
        dispatch({
            type: TASKTYPE_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    }
    catch(err){
        return Promise.reject(err);
    }
}

export const taskTypeAdd_action = (taskTypeName) => async(dispatch) => {
    try {
        const param = {
            UserID : localStorage.getItem('UserId'),
            Platform : platfarm,
            Name : taskTypeName
        }
        const res = await axiosPost(taskTypeAddUrl, param, headers);
        dispatch({
            type: TASKTYPE_ADD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);    
    } 
    catch(err){
        return Promise.reject(err);
    }
}

export const taskTypeDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }        
        const res = await axiosGet(taskTypeDetailsUrl, param);
        dispatch({
            type: TASKTYPE_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const taskTypeUpdate_action = (taskTypeName, id) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: taskTypeName,
            ID : id,
        }
        const res = await axiosPost(taskTypeUpdateUrl, mapdata, headers);
        console.log("taskTypeUpdate_action", mapdata)
        dispatch({
            type: TASKTYPE_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const taskTypeDelete_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }
        const res = await axiosGet(taskTypeDeleteUrl, param);
        dispatch({
            type: TASKTYPE_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch (err) {
        return Promise.reject(err);
    }
};