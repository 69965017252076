import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    platfarm, headers,
    TRAINING_REPORT_ACTION_TYPE,EmployeeHasEnteredTimesheetReportViewUrl,
    EmployeeHasEnteredTimesheetReport,fetchAuthReport,EMP_ENTERED_TIMESHEET_VIEW_ACTION_TYPE
} from "../../utils/constants"
import moment from 'moment';


export const getEmployeeHasEnteredTimesheetReport_action = (employeedata) => async (dispatch) => {
    try {
        console.log(employeedata)
        var list = [];
        const mapdata1 = {
            "ParameterName":"@UserID",
            "ParameterValue": parseInt(localStorage.getItem('UserId')),
            "ParameterDataType":"int"
        }
        list.push(mapdata1);
        const mapdata2 = {
            "ParameterName":"@EmployeeID",
            "ParameterValue": parseInt( (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID']),
            "ParameterDataType":"int"
        }
        list.push(mapdata2);
        const mapdata3 = {
            "ParameterName":"@Platform",
            "ParameterValue":platfarm,
            "ParameterDataType":"varchar"
        }
        list.push(mapdata3);
        const mapdata4 = {
            "ParameterName":"@EmployeeIDs",
            "ParameterValue" : employeedata,
            "ParameterDataType":"varchar"
        }
        list.push(mapdata4);
      
        const mapData = {
            "user_id": localStorage.getItem('UserId'),
            "report_name": EmployeeHasEnteredTimesheetReport,
            "parameter_details": list
        }
     

        const res = await axiosPost(fetchAuthReport, mapData);
        dispatch({
            type: TRAINING_REPORT_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const  getEmployeeHasEnteredTimesheetReportView_action = (employeedata) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            EmployeeID: parseInt( (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID']),
            Platform: platfarm,
            EmployeeIDs: employeedata,
        }
        console.log(param)
        const res = await axiosGet(EmployeeHasEnteredTimesheetReportViewUrl, param)
        console.log(res)
        dispatch({
            type: EMP_ENTERED_TIMESHEET_VIEW_ACTION_TYPE,
            payload: res
        })

        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}