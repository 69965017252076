import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Loader from '../../../utils/Loader'
import { mainEngagementActionId, Master, subEngagementActionId } from '../../../utils/constants'
import { fill_action } from '../../../actions/master_action/checklist_action';
import { addEngagementMaster_action, updateEngagementMaster_action, engagementMasterDetail_action } from '../../../actions/master_action/engagement_master_action'
import { data } from 'jquery';
import { getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

const mapStateToProps = state => ({
    ...state
})

class EngagementMasterAU extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isActive: 0,
            isAccessToView: true,
            isLoading: false,
            is_add: false,
            is_update: false,


            engagementMasterId: '',
            engagementMasterName: '',
            mainEngagmentId: '',
            subEngagementId: '',
            hasChild: false,
            mainEngagmentList: [],
            subEngagementList: [],
        }

        this.handleCancel = this.handleCancel.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.getEngagementMasterDetails = this.getEngagementMasterDetails.bind(this)

    }
    componentDidMount = () => {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            engagementMasterId: this.props.location.state != null && this.props.location.state.engagementMasterId != null ? this.props.location.state.engagementMasterId : '',
            isAccessToView: true,
        })
        this.getFillList(mainEngagementActionId, '')
        this.getFillList(subEngagementActionId, '')
        this.props.location.state != null && this.props.location.state.is_update && this.getEngagementMasterDetails()
        setTimeout(() => {
            console.log(this.state.engagementMasterId)

        }, 100);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


  
    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
          const focusedElement = document.activeElement;
          const { previouslyFocusedElement } = this.state;
    
          if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
          }
    
          if (focusedElement.classList.contains('form-control') ||
            focusedElement.classList.contains('js-example-basic-single') ||
            focusedElement.classList.contains('example-custom-input')) {
            focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
            this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
          }
        }
      };
    
    
      handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;
    
        if (previouslyFocusedElement) {
          previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }
    
        if (clickedElement.classList.contains('form-control') ||
          clickedElement.classList.contains('js-example-basic-single') ||
          clickedElement.classList.contains('example-custom-input')) {
          clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
          this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
          this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
      };
    
    getFillList(value, ref, ref2) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == mainEngagementActionId) {
                        console.log('check main', data.data)
                        this.setState({ mainEngagmentList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);

                    }
                    if (value == subEngagementActionId) {
                        console.log('check sub', data.data)
                        this.setState({ subEngagementList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);

                    }
                }
            })
    }

    handleCancel() {
        this.props.navigate('/engagementMaster')
    }

    handleReset() {
        this.setState({
            engagementMasterId: '',
            engagementMasterName: '',
            mainEngagmentId: '',
            subEngagementId: '',
            hasChild: true

        })
    }

    handleAdd(e) {
        e.preventDefault()
        const { engagementMasterName, mainEngagmentId, subEngagementId, hasChild } = this.state
        if (engagementMasterName != "" && mainEngagmentId != "") {
            this.setState({ isLoading: true })
            this.props.addEngagementMaster_action(engagementMasterName.trim(), mainEngagmentId, subEngagementId, hasChild)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.clearValue()
                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    clearValue() {
        this.setState({
            engagementMasterId: '',
            engagementMasterName: '',
            mainEngagmentId: '',
            subEngagementId: '',
            hasChild: true
        })
    }


    getEngagementMasterDetails() {
        const { engagementMasterName, mainEngagmentId, subEngagementId, hasChild, isActive } = this.state
        var id = this.props.location.state.engagementMasterId
        this.setState({ isLoading: true })
        this.props
            .engagementMasterDetail_action(id)
            .then((data) => {
                console.log("get", data.data[0])
                if (data.error != 1) {
                    this.setState({
                        engagementMasterName: data.data[0]['EngagementDesc'],
                        mainEngagmentId: data.data[0]['MainType'],
                        subEngagementId: data.data[0]['ParentEngagementID'],
                        hasChild: data.data[0]['HasChild'],
                        isActive: data.data[0]['IsActive'],
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }


    handleUpdate(e) {
        e.preventDefault()
        const { engagementMasterName, mainEngagmentId, subEngagementId, hasChild, engagementMasterId } = this.state
        const id = engagementMasterId

        if (id != "" && engagementMasterName != "" && mainEngagmentId != "") {
            this.setState({ isLoading: true })
            this.props
                .updateEngagementMaster_action(id, engagementMasterName.trim(), mainEngagmentId, subEngagementId, hasChild)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }
    render() {
        const { isAccessToView, isLoading, engagementMasterName, mainEngagmentId, subEngagementId, hasChild, mainEngagmentList, mainSubId, subEngagementList, isActive, is_update, engagementMasterId } = this.state
        return (<>
            {isLoading ? (
                <Loader></Loader>
            ) : (
                null
            )}
            <Header />
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                    {/* Left side menu */}
                    <Drawer />
                    {/* end left menu */}
                    {/* start page */}
                    {isAccessToView ? (
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul className="breadcrumb">
                                        <li>
                                            <a onClick={this.handleCancel} >Engagement Master</a>
                                        </li>
                                        <li>
                                            <a >{this.state.is_add ? 'New' : 'Update'}</a>
                                        </li>
                                        <li />
                                    </ul>
                                </div>
                                                    <div className="status-top">
                                                        {
                                                            is_update ? <p>
                                                                <strong className={isActive === true ? 'greena' : 'redi'}> {isActive === true ? 'Active' : 'Inactive'}</strong>
                                                            </p> : <></>
                                                        }

                                                    </div>
                                <div className="row">
                                    <style
                                        type="text/css"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                        }}
                                    />
                                    <div className="col-md-12 pg-body grid-margin stretch-card">
                                        <div className="card">
                                            <div className="card-body">
                                               

                                                <div class="d-flex flex-wrap row mb-2">
                                                    <div class="form-group col-md-3">
                                                        <label>Engagement Master Name<span class="required1">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='Name'
                                                            value={this.state.engagementMasterName}
                                                            onChange={(e) => this.setState({ engagementMasterName: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label>Main Engagement<span class="required1">*</span></label>
                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                            value={mainEngagmentId}
                                                            onChange={(e) => this.setState({ mainEngagmentId: e.target.value, })}>
                                                            <option value={''}>Select</option>
                                                            {mainEngagmentList.length > 0 && mainEngagmentList.map(data => (
                                                                <option value={data.MainEngagementID
                                                                } key={data.MainEngagementID
                                                                }>{data.MainEngagementType}</option>
                                                            ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-wrap row mb-3">
                                                    <div className="form-group col-md-3">
                                                        <label>Sub Engagement</label>
                                                        <select className={!mainEngagmentId || mainEngagmentId == 'Select' ? 'js-example-basic-single bg-secondary' : 'js-example-basic-single'} style={{ width: '100%' }}
                                                            disabled={!mainEngagmentId || mainEngagmentId == 'Select'}
                                                            value={subEngagementId}
                                                            onChange={(e) => this.setState({ subEngagementId: e.target.value })}>
                                                             <option value={''}>Select</option>
                                                            {subEngagementList.length > 0 && subEngagementList.map(data => data.MainType == mainEngagmentId && engagementMasterId != data.EngagementMasterID ? (
                                                                <option value={data.EngagementMasterID} key={data.EngagementMasterID}>{data.EngagementDesc}</option>) : null)
                                                            }
                                                        </select>
                                                    </div>
                                                    <div class="form-group col-md-2 mt-4  d-flex">
                                                        <label className='d-flex'><input type="checkbox" className='mr-1' style={{height:'16px'}} checked={hasChild} value={hasChild} onClick={(e) => this.setState({ hasChild: !hasChild })} /> Has Child </label>

                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="footer-btn">
                                                        <div className="col-md-3">
                                                            <div
                                                                className="btn-group"
                                                                role="group"
                                                                aria-label="Basic example"
                                                            >
                                                                {
                                                                    this.state.is_add && getModuleWriteAccess(Master) && <button
                                                                        type="button"
                                                                        className="btn btn-save footer-btn-inner mr-1"
                                                                        onClick={this.handleAdd}
                                                                    >
                                                                        Save
                                                                    </button>
                                                                }
                                                                {
                                                                    this.state.is_add && getModuleWriteAccess(Master)&& <button
                                                                        type="button"
                                                                        className="btn btn-update footer-btn-inner mr-1"
                                                                        onClick={this.handleReset}
                                                                    >
                                                                        Reset
                                                                    </button>
                                                                }

                                                                {
                                                                    this.state.is_update && getModuleUpdateAccess(Master)&& isActive === true ?
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-update footer-btn-inner mr-1"
                                                                            onClick={this.handleUpdate}
                                                                        >
                                                                            Update
                                                                        </button> : <></>
                                                                }


                                                                <button
                                                                    type="button"
                                                                    className="btn btn-cancel footer-btn-inner"
                                                                    onClick={this.handleCancel}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)}
                    {/* end page */}
                </div>
                {/* page-body-wrapper ends */}
            </div>
            <ToastContainer />
        </>)
    }
}


export default withRouter(connect(mapStateToProps, { fill_action, addEngagementMaster_action, engagementMasterDetail_action, updateEngagementMaster_action })(EngagementMasterAU))
