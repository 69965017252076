import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import Drawer from '../../common_components/Drawer';
import {
    changePassword_action,
} from '../../actions/user_auth_action/login_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import moment, { relativeTimeRounding } from 'moment';
import Modal from "react-modal";
import Loader from '../../utils/Loader'
import { clearLocalStorage } from '../../utils/constants'
import Pagination from '../../utils/pagination'
/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            old_pass: '',
            new_pass: '',
            confirm_pass: '',
            isLoading: false,
            is_showNewpass: false,
            is_showOldpass: false,
            is_showConfirmpass: false,
            is_forgotPassword: false,
        }
        this.handleChangePassword = this.handleChangePassword.bind(this)
        this.handleObscure = this.handleObscure.bind(this)

    }

    componentDidMount() {
        clearLocalStorage("")
        this.setState({
            is_forgotPassword: this.props.location.state != null && this.props.location.state.is_forgotPassword != null ? this.props.location.state.is_forgotPassword : false
        })
    }

    handleObscure(pass) {
        const { is_showOldpass, is_showConfirmpass, is_showNewpass } = this.state
        if (pass == 'new_pass') {
            this.setState({ is_showNewpass: !is_showNewpass })
        }
        else if (pass == 'old_pass') {
            this.setState({ is_showOldpass: !is_showOldpass })
        }
        else if (pass == 'confirm_pass') {
            this.setState({ is_showConfirmpass: !is_showConfirmpass })
        }

    }

    handleChangePassword = () => {
        const { old_pass, new_pass, confirm_pass } = this.state
        clearLocalStorage("")
        if (new_pass != '' && confirm_pass != '' && old_pass != '' && new_pass === confirm_pass) {
            this.setState({ isLoading: true })
            this.props
                .changePassword_action(old_pass, new_pass)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toast.success('Password changed successfully.Please login again.', {
                            theme: "colored",
                            autoClose: 3000,
                            hideProgressBar: true
                        });
                        setTimeout(() => {
                            localStorage.clear()
                            this.props.navigate('/login')
                        }, 300);

                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please check your password', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }

    render() {
        const { old_pass, new_pass, confirm_pass, is_showOldpass, is_showConfirmpass, is_showNewpass, is_forgotPassword } = this.state


        return (
            <>
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul className="breadcrumb">
                                        <li><a href="#">Change Password</a></li>
                                        <li />
                                    </ul>
                                </div>
                                <div className="row">
                                    <style
                                        type="text/css"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                        }}
                                    />
                                    <div className="col-md-12 pg-body grid-margin stretch-card">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row mt-4">
                                                    <div className="col-md-4 mt-4 m-auto border-pass rounded-2">
                                                        <div className="p-3">
                                                            <h5 className='pb-2 pt-1 text-info-pass'>Create new password</h5>
                                                            <div className="row">
                                                                <div className="form-group col-12 mt-1 d-flex p-0" style={{position: 'relative'}}>
                                                                    <input  type={is_showOldpass ? "type" : "password"}
                                                                        className="form-control rounded-1 change-pas"
                                                                        placeholder={is_forgotPassword ? "Enter password which got in an email" : "Old Password"}
                                                                        required
                                                                        value={old_pass}
                                                                        onChange={e => this.setState({ old_pass: e.target.value })}

                                                                    />
                                                                    <i className={is_showOldpass ? "fa fa-eye password-view-ico" : "fa fa-eye-slash password-view-ico"} 
                                                                        onClick={e => this.handleObscure('old_pass')} />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group col-12 mt-2 d-flex p-0" style={{position: 'relative'}}>
                                                                    <input type={is_showNewpass ? "type" : "password"}
                                                                        className="form-control rounded-1 change-pas"
                                                                        placeholder="New Password"
                                                                        required
                                                                        value={new_pass}
                                                                        onChange={e => this.setState({ new_pass: e.target.value })}

                                                                    />
                                                                    <i className={is_showNewpass ? "fa fa-eye password-view-ico" : "fa fa-eye-slash password-view-ico"} 
                                                                        onClick={e => this.handleObscure('new_pass')} />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group col-12 mt-2 d-flex p-0" style={{position: 'relative'}}>
                                                                    <input type={is_showConfirmpass ? "type" : "password"}
                                                                        className="form-control rounded-1 change-pas"
                                                                        placeholder="Confirm Password"
                                                                        required
                                                                        value={confirm_pass}
                                                                        onChange={e => this.setState({ confirm_pass: e.target.value })}
                                                                    />
                                                                    <i className={is_showConfirmpass ? "fa fa-eye password-view-ico" : "fa fa-eye-slash password-view-ico"} 
                                                                        onClick={e => this.handleObscure('confirm_pass')}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 mb-1">
                                                            <div className="text-center mt-2 p-0">
                                                                <button type="button" className="btn  btn-info p-2" style={{width:'100%'}}
                                                                    onClick={e => this.handleChangePassword()}>
                                                                    Change Password
                                                                </button>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* page-body-wrapper ends */}
                        </div>
                        <ToastContainer />
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(connect(null, {
    changePassword_action
})(ChangePassword));