import React, { Component, forwardRef } from "react";
import Loader from "../../../utils/Loader";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../../../utils/pagination'
import { expenseHeadList_action, deleteExpenseHead_action, expenseHeadDetail_action, addExpenseHead_action, updateExpenseHead_action } from '../../../actions/master_action/expense_head_action'
import { clearLocalStorage, Master, } from '../../../utils/constants'
import { fill_action } from '../../../actions/master_action/checklist_action';
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import moment from 'moment/moment';
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from "../../../utils/RoleAccess";

class ExpenseHead extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAccessToView: true,
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage: 10,
            pageSizeInput: "10",
            is_add: false,
            is_update: false,
            isOpen: false,
            isOpenAU: true,

            expenseHeadList: [],
            expenseHeadNameEHLS: '',
            statusEHLS: '',
            expenseHeadName: '',
            expenseHeadNameSearch: '',
            status: ' ',
            expenseHeadID: '',
            expenseHeadDeleteId: '',
            isSearchTab: false,
        }
        this.getExpenseHeadList = this.getExpenseHeadList.bind(this)
        this.record = this.record.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);

        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.toggleModalAU = this.toggleModalAU.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.toggleAdd = this.toggleAdd.bind(this)
        this.toggleUpdate = this.toggleUpdate.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.getExpenseHeadDetails = this.getExpenseHeadDetails.bind(this)
        this.handleToggle = this.handleToggle.bind(this)


    }

    //expense head list default
    componentDidMount = () => {
        this.setState({
            isAccessToView: true,
            expenseHeadNameEHLS: localStorage.getItem('expenseHeadNameEHLS'),
            statusEHLS: localStorage.getItem('statusEHLS'),

            expenseHeadNameSearch: localStorage.getItem('expenseHeadNameEHLS') != '' ? localStorage.getItem('expenseHeadNameEHLS') : '',
            status: localStorage.getItem('statusEHLS') != '' ? localStorage.getItem('statusEHLS') : '',

        })
        clearLocalStorage("expenseHead")
        setTimeout(() => {
            this.getExpenseHeadList()
        }, 100);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };


    //toggal for delete
    toggleModal(expenseHeadID) {
        console.log(expenseHeadID)
        const { isOpen, expenseHeadDeleteId } = this.state
        this.setState({ isOpen: !isOpen })
        if (expenseHeadID == '') {
            return
        }
        else {
            this.setState({ expenseHeadDeleteId: expenseHeadID })
        }
    }

    //delete handle
    handleDelete(e, expenseHeadID) {
        e.preventDefault()
        var id = expenseHeadID
        this.setState({ isLoading: true })
        this.props
            .deleteExpenseHead_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isOpen: !this.state.isOpen, isLoading: false })
                    this.getExpenseHeadList()
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    //get list
    getExpenseHeadList() {
        const { expenseHeadNameSearch, status } = this.state
        this.setState({ isLoading: true })
        this.props
            .expenseHeadList_action(expenseHeadNameSearch, status === '' ? 1 : parseInt(status))
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        expenseHeadList: data.data,
                        isLoading: false,
                        currentPage: 1
                    })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    //get details
    getExpenseHeadDetails() {
        setTimeout(() => {
            var id = this.state.expenseHeadID
            this.props
                .expenseHeadDetail_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            expenseHeadName: data.data[0]['ExpenseType'],
                            status: data.data[0]['IsActive'],
                        })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 200);
    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }
    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
            this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
    };

    handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
            this.setState({ pageSizeInput: input });
        }
    };
    //search
    handleSearch() {
        const { expenseHeadNameSearch, status } = this.state
        clearLocalStorage("expenseHead")
        this.setState({ isLoading: true })
        this.props
            .expenseHeadList_action(expenseHeadNameSearch.trim(), status === '' ? 1 : parseInt(status))
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ expenseHeadList: data.data, isLoading: false, currentPage: 1 })
                    console.log(data)
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
        localStorage.setItem("expenseHeadNameEHLS", expenseHeadNameSearch)
        localStorage.setItem("statusEHLS", status)
    }


    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.handleSearch()
        }
    }

    //clear value
    clearValue() {
        this.setState({
            expenseHeadList: [],
            expenseHeadNameEHLS: '',
            statusEHLS: 'Select',
            expenseHeadName: '',
            expenseHeadNameSearch: "",
            status: ' ',
            currentPage: 1,
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getExpenseHeadList()
        }, 300);
    }

    //record
    record(expenseHeadList) {

        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ textAlign: 'left' }}>Expense Head</th>
                        <th style={{ width: '180px', textAlign: 'center' }}>Active</th>
                    </tr>
                </thead>
                <tbody>
                    {expenseHeadList.length > 0 ? (
                        expenseHeadList.map((data, i) => (
                            <tr key={data.ExpenseHeadID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        {getModuleUpdateAccess(Master) ?
                                            <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleToggle(data.ExpenseHeadID, 'update')} /></a> :
                                            <a><i className="fa fa-eye" onClick={() => this.handleToggle(data.ExpenseHeadID, 'update')} /></a>
                                        }
                                        {data.IsActive === true && getModuleDeleteAccess(Master) ? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.ExpenseHeadID)} /></span></a>
                                            : <></>}

                                    </div>
                                </td>
                                <td style={{ textAlign: 'left' }}>{data.ExpenseType}</td>
                                <td>{data.IsActive == true ? "Active" : "Inactive"}</td>
                            </tr>
                        ))
                    ) : (<tr><td colSpan={5} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }

    //reset values
    handleReset() {
        this.setState({
            expenseHeadID: '',
            expenseHeadName: '',
            expenseHeadNameSearch: ''
        })
    }

    toggleAdd() {
        const { is_add } = this.state
        this.setState({ is_add: true })
        this.handleReset()
        this.toggleModalAU()
    }

    toggleUpdate(expenseHead) {
        const { is_update } = this.state
        this.handleReset()
        this.setState({ is_update: true, expenseHeadID: expenseHead })
        this.toggleModalAU()
    }

    //toggle/reset
    toggleModalAU(expenseHead) {
        const { isOpenAU } = this.state
        this.getExpenseHeadDetails()
        this.setState({ isOpenAU: !isOpenAU })
    }

    handleToggle(id, type) {
        const { isOpenAU, martialStatus } = this.state
        if (type == 'add') {
            this.setState({ is_add: true, is_update: false, isOpenAU: !isOpenAU, expenseHeadName: "", })
            this.handleReset()
        } else {
            this.handleReset()
            this.setState({ is_update: true, is_add: false, isOpenAU: !isOpenAU, expenseHeadID: id })
            this.getExpenseHeadDetails()
        }
    }

    //add expense head
    handleAdd(e) {
        e.preventDefault()
        const { expenseHeadName } = this.state
        if (expenseHeadName != "") {
            this.setState({ isLoading: true })
            console.log(expenseHeadName)
            this.props
                .addExpenseHead_action(expenseHeadName.trim())
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false })
                        this.toggleModalAU()
                        this.clearValue()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //update expense head
    handleUpdate(e) {
        e.preventDefault()
        const { expenseHeadID, expenseHeadName, isOpenAU } = this.state
        const id = expenseHeadID

        if (id != "" && expenseHeadName != "") {
            this.setState({ isLoading: true })
            this.props
                .updateExpenseHead_action(id, expenseHeadName.trim())
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false, expenseHeadName: "", isOpenAU: !isOpenAU })
                        // this.toggleModalAU()
                        this.clearValue()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    render() {
        const { isSearchTab, isLoading, isAccessToView, is_add, isOpenAU,
            expenseHeadNameSearch, status, expenseHeadList, expenseHeadName,
            is_update, isOpen, expenseHeadDeleteId } = this.state
        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // // Records to be displayed on the current page
        const currentRecords = expenseHeadList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(expenseHeadList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />


                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>Expense Head </a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className='page-space-from-bottom'>
                                        <div className="row">
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body pr-2">


                                                        <div className="accordion accordion-flush " id="accordionFlushExample">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingOne">
                                                                    <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                                                        <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                    </button>
                                                                </h2>
                                                                {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                                <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                    <div className="d-flex flex-wrap row">
                                                                        <div className="form-group col-md-3">
                                                                            <label>Expense Head</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="Expense Head"
                                                                                value={expenseHeadNameSearch}
                                                                                onChange={(e) => this.setState({ expenseHeadNameSearch: e.target.value })}
                                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                                required />
                                                                        </div>

                                                                        <div className="form-group col-md-3">
                                                                            <label>Status</label>
                                                                            <select className="js-example-basic-single" style={{ width: '100%' }}
                                                                                value={status}
                                                                                onChange={(e) => this.setState({ status: e.target.value })}>
                                                                                <option value={''}>Select</option>
                                                                                <option value={1}>Active</option>
                                                                                <option value={0}>Inactive</option>
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group col-md-3 filter-btn-col">
                                                                            <label></label>
                                                                            <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={e => this.handleSearch()}> Search</button>
                                                                            <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3" onClick={e => this.clearValue()}> Clear</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div> : <></>}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 mt-1">
                                                                {getModuleWriteAccess(Master) && <button type="button" className="btn btn-Add rounded-1 mb-1 float-right px-3" onClick={() => this.handleToggle('', 'add')}>Add</button>}
                                                                {/* button */}
                                                                <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                                    {this.record(currentRecords)}
                                                                    {expenseHeadList.length > 0 ? <Pagination
                                                                        nPages={nPages}
                                                                        currentPage={currentPage}
                                                                        setCurrentPage={this.updateItem}
                                                                        recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                        setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                        totalRecords={expenseHeadList.length}
                                                                    /> : <></>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>
                <ToastContainer />
                {/* // modal for delete */}
                {/* <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, expenseHeadDeleteId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}

                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right">
                                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, expenseHeadDeleteId)}> Yes</a>
                                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* modal for AU */}
                <Modal isOpen={!isOpenAU} onRequestClose={this.toggleModalAU}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                {is_add == true ? <h4 class="modal-title modal_title_text">Add Expense Head</h4> : <h4 class="modal-title modal_title_text">Update Expense Head</h4>}
                                {is_add == true ? <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleAdd} ><span aria-hidden="true" class="pop-cancel">&times;</span></button> : <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.toggleUpdate()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>}
                            </div>

                            <div class="modal-body">
                                <div class="d-flex flex-wrap row mt-2">
                                    <div class="d-flex flex-wrap row ">
                                        <div className="form-group col-md-3 pr-0">
                                            <label>Expense Head<span class="required1">*</span></label>
                                        </div>
                                        <div className="form-group col-9 pr-0">
                                            <input
                                                type="text"
                                                // disabled={!status}
                                                className="form-control"
                                                placeholder='Expense Head'
                                                value={this.state.expenseHeadName}
                                                onChange={(e) => this.setState({ expenseHeadName: e.target.value })}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    {is_add == true ? getModuleWriteAccess(Master) && <a class="btn save_btn del-pop-save" onClick={this.handleAdd}> Save</a> :
                                     status && getModuleUpdateAccess(Master) && <a class="btn save_btn del-pop-save" onClick={this.handleUpdate}> Update</a>}
                                    {is_add == true && getModuleWriteAccess(Master) ? <a class="btn btn-outline-danger" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.handleReset}>Reset</a> : <></>}
                                    {is_add == true ? <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleAdd}>Cancel</a> : <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.toggleUpdate()}>Cancel</a>}
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(connect(null, { expenseHeadList_action, deleteExpenseHead_action, expenseHeadDetail_action, addExpenseHead_action, updateExpenseHead_action })(ExpenseHead))