import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { fill_action } from '../../../actions/master_action/checklist_action'
import { leaveApprovalDetails_action } from '../../../actions/time_sheet_expense_action/leaveApproval_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from "react-router-dom";
import { withRouter } from '../../../utils/withRouter';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Loader from '../../../utils/Loader'
import { FaCalendarAlt } from 'react-icons/fa';
import "react-datepicker/dist/react-datepicker.css";
import { employeeActionId, timesheetStatusActionId } from '../../../utils/constants'
import Modal from "react-modal";

const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));

class LeaveApprovalDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            is_Details: false,
            leaveApprovalId: '',
            employeeId: '',
            employeeList: [],
            timesheetStatusId: '',
            timesheetStatusList: [],
            disabledFlag: false,
            name: '',
            rejectionReason: '',
            Previous_reject: ''
        }
        this.geteLeaveApprovalDetails = this.geteLeaveApprovalDetails.bind(this)
    }

    componentDidMount = () => {


        this.getFillList(employeeActionId, '')
        this.getFillList(timesheetStatusActionId, '')

        this.setState({
            // isAccessToView: localStorage.getItem('UserId') == '1' ? true : false,
            is_Details: this.props.location.state != null && this.props.location.state.is_Details != null ? this.props.location.state.is_Details : false,
            leaveApprovalId: this.props.location.state != null && this.props.location.state.leaveApprovalId != null ? this.props.location.state.leaveApprovalId : '',
            Previous_reject: this.props.location.state != null && this.props.location.state.Previous_reject != null ? this.props.location.state.Previous_reject : '',
        })
        this.props.location.state != null && this.props.location.state.is_Details && this.geteLeaveApprovalDetails()
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }
  


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
          const focusedElement = document.activeElement;
          const { previouslyFocusedElement } = this.state;
    
          if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
          }
    
          if (focusedElement.classList.contains('form-control') ||
            focusedElement.classList.contains('js-example-basic-single') ||
            focusedElement.classList.contains('example-custom-input')) {
            focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
            this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
          }
        }
      };
    
    
      handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;
    
        if (previouslyFocusedElement) {
          previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }
    
        if (clickedElement.classList.contains('form-control') ||
          clickedElement.classList.contains('js-example-basic-single') ||
          clickedElement.classList.contains('example-custom-input')) {
          clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
          this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
          this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
      };
    getFillList(value, ref, ref2) {

        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == employeeActionId) {
                        this.setState({ employeeList: data.data, isLoading: false })
                    }

                    if (value == timesheetStatusActionId) {
                        this.setState({ timesheetStatusList: data.data, isLoading: false })
                    }

                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    //  clear field  
    handleCancel() {
        this.props.navigate('/leaveApproval')
    }

    // Details employee contact
    geteLeaveApprovalDetails() {
        var id = this.props.location.state.leaveApprovalId
        this.setState({ isLoading: true })
        this.props
            .leaveApprovalDetails_action(parseInt(id))
            .then((data) => {
                if (data.error != 1) {

                    this.setState({
                        employeeId: data.data[0]['EmployeeID'],
                        timesheetStatusId: data.data[0]['LeaveStatus'],
                        fromDate: data.data[0]['LeaveFromDate'] != null && data.data[0]['LeaveFromDate'] != '' ? moment(data.data[0]['LeaveFromDate'], 'YYYY-MM-DD').toDate() : '',
                        toDate: data.data[0]['LeaveToDate'] != null && data.data[0]['LeaveToDate'] != '' ? moment(data.data[0]['LeaveToDate'], 'YYYY-MM-DD').toDate() : '',
                        leaveType: data.data[0]['LeaveType'],
                        remark: data.data[0]['Remark'],
                        name: data.data[0]['Name'],
                        rejectionReason: data.data[0]['RejectReason'],
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    render() {
        const { isLoading, isActive, isAccessToView, is_Details, employeeId, employeeList, timesheetStatusId, timesheetStatusList, fromDate, toDate, leaveType, remark, name, rejectionReason, Previous_reject } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />

                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {/* style={{ overflow : 'scroll !important', height : '200rem', backgroundColor : 'pink'}} */}
                        {isAccessToView ? (
                            <div className="main-panel main-box " >

                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li>
                                                <a onClick={() => this.handleCancel()} >Leave Approval</a>
                                            </li>
                                            {/* <li>
                                                <a >Details</a>
                                            </li> */}
                                            <li />
                                        </ul>
                                    </div>
                                    <div className="user-status">
                                                            {
                                                                is_Details && name != '' ? <p>
                                                                    <strong className={name
                                                                        === 'Entered' || 'Sent for Approval' || 'Approved' || 'Rejected' || 'Deleted' ? 'greena' : 'redi'}> {name
                                                                        }</strong>
                                                                </p> : <></>
                                                            }

                                                    </div>
                                    <div className="row m-0">
                                        <style
                                            type="text/css"
                                            dangerouslySetInnerHTML={{
                                                __html: "\n.accordion-button::after {\n background-size: 13px;\n display: none; \n} \n"
                                            }}
                                        />
                                        <div className="col-md-12 pg-body grid-margin stretch-card"  >
                                            <div className="card">
                                                <div className="card-body p-0">
                                                    
{/* 
                                                    <div className="mob-tab mb-2">
                                                        <div className="mob-wd1">
                                                            <ul
                                                                className="nav nav-tabs master-tab"
                                                                id="myTab"
                                                                role="tablist"
                                                            >
                                                                <li className="nav-item" role="presentation">
                                                                    <button
                                                                        className="nav-link active"
                                                                        id="home-tab"
                                                                        data-bs-toggle="tab"
                                                                        data-bs-target="#home"
                                                                        type="button"
                                                                        role="tab"
                                                                        aria-controls="home"
                                                                        aria-selected="true"
                                                                    >
                                                                        Leave Approval
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div> */}
                                                    <div className='page-space-from-bottom'>
                                                    <div className="d-flex flex-wrap row mb-2">

                                                        <div className="form-group col-md-3">
                                                            <label>
                                                                Employee
                                                            </label>
                                                            <select
                                                                disabled={true}
                                                                className="js-example-basic-single"
                                                                style={{ width: "100%" }}

                                                                value={employeeId}
                                                                onChange={e => this.setState({ employeeId: e.target.value != "Select" ? e.target.value : "" })}
                                                            >
                                                                 <option value={''}>Select</option>
                                                                {employeeList.length > 0 ? employeeList.map(item => (
                                                                    <option
                                                                        key={item.emmnID}
                                                                        value={item.emmnID}>
                                                                        {item.empsFirstName} 
                                                                    </option>
                                                                )) : <></>}
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-md-3">
                                                            <div>
                                                                <label>Leave Type</label>
                                                                <input
                                                                    disabled={true}
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={leaveType}
                                                                    onChange={(e) => this.setState({ leaveType: e.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                   
                                                 
                                                      

                                                    </div>

                                                        {/* <div className="form-group col-md-3">
                                                            <label>
                                                                Status
                                                            </label>
                                                            <select
                                                                disabled={true}
                                                                className="js-example-basic-single"
                                                                style={{ width: "100%" }}

                                                                value={timesheetStatusId}
                                                                onChange={e => this.setState({ timesheetStatusId: e.target.value != "Select" ? e.target.value : "" })}
                                                            >
                                                                 <option value={''}>Select</option>
                                                                {timesheetStatusList.length > 0 ? timesheetStatusList.map(item => (
                                                                    <option
                                                                        key={item.TimeSheetStatusID}
                                                                        value={item.TimeSheetStatusID}>
                                                                        {item.Name}
                                                                    </option>
                                                                )) : <></>}
                                                            </select>
                                                        </div> */}
                                                    </div>

                                                    <div className="d-flex flex-wrap row">
                                                        <div className="form-group col-md-3">
                                                            <label>
                                                                From Date
                                                            </label>
                                                            <div className="form-group d-flex">
                                                                <DatePicker className="form-control"
                                                                    disabled={true}
                                                                    dateFormat="dd-MM-yyyy"
                                                                    selected={fromDate}
                                                                    value={fromDate}
                                                                    onChange={(date) => { this.setState({ fromDate: date }) }}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    customInput={<ExampleCustomInput />}
                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                    popperClassName="example-datepicker-class"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group col-md-3">
                                                            <label>
                                                                To Date
                                                            </label>
                                                            <div className="form-group d-flex">
                                                                <DatePicker className="form-control"
                                                                    disabled={true}
                                                                    dateFormat="dd-MM-yyyy"
                                                                    selected={toDate}
                                                                    value={toDate}
                                                                    minDate={fromDate}
                                                                    onChange={(date) => { this.setState({ toDate: date }) }}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    customInput={<ExampleCustomInput />}
                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                    popperClassName="example-datepicker-class"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div className="d-flex flex-wrap row mt-2">
                                                        <div className="form-group col-md-6">
                                                            <label>Remark</label>
                                                            <textarea
                                                                disabled={true}
                                                                className="form-group h-auto"
                                                                name="remark"
                                                                maxLength={500}
                                                                // defaultValue={this.state.remark}
                                                                value={remark}
                                                                onChange={e => this.setState({ remark: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                    </div>

                                                    {name == "Rejected" || Previous_reject === 'Yes' ?
                                                        <div className="d-flex flex-wrap row mt-2">
                                                            <div className="form-group col-md-6">
                                                                <label>Rejection Reason</label>
                                                                <textarea
                                                                    maxLength={500}
                                                                    disabled={true}
                                                                    className="form-group"
                                                                    name="Rejection Reason"
                                                                    style={{ border: "1px solid #dee2e6" }}
                                                                    defaultValue={rejectionReason}
                                                                    value={rejectionReason}
                                                                />
                                                            </div>
                                                        </div>
                                                        : <></>}
                                                    <div className="row">
                                                        <div className="footer-btn">
                                                            <div className="col-md-3">
                                                                <div
                                                                    className="btn-group"
                                                                    role="group"
                                                                    aria-label="Basic example"
                                                                >
                                                                    <button style={{ marginRight: '5px' }}
                                                                        type="button"
                                                                        className="btn btn-cancel footer-btn-inner"
                                                                        onClick={() => this.handleCancel()}
                                                                    >
                                                                        Cancel
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* end*/}
                            </div>
                        ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div >
                <ToastContainer />


            </>
        );
    }
}

export default withRouter(connect(mapStateToProps, { fill_action, leaveApprovalDetails_action })(LeaveApprovalDetails));