import React, { Component, forwardRef } from "react";
import Loader from "../../../utils/Loader";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../../../utils/pagination'
import { holidayList_action, deleteHoliday_action, holidayDetail_action, addHoliday_action, updateHoliday_action } from '../../../actions/master_action/holiday_action'
import { clearLocalStorage, holidayTypeActionId, Master, } from '../../../utils/constants'
import { fill_action } from '../../../actions/master_action/checklist_action';
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import moment from 'moment/moment';
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from "../../../utils/RoleAccess";

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));

class Holiday extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            isAccessToView: true,
            holidayList: [],
            HolidayID: '',
            holidayDate: '',
            holidayName: '',
            holidayType: '',
            holidayDateSearch: '',
            holidayNameSearch: '',
            holidayTypeSearch: '',
            holidayDateHLS: '',
            holidayNameHLS: '',
            holidayTypeHLS: '',
            statusHLS: '',
            status: '',
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage: 10,
            pageSizeInput: "10",
            holidayDeleteId: '',
            holidayTypeList: [],
            holidayTypeId: '',

            isOpenAU: true,
            is_add: false,
            is_update: false,
            isSearchTab: false,
        }
        this.record = this.record.bind(this)
        this.getHolidayList = this.getHolidayList.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);

        this.handleSearch = this.handleSearch.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.toggleAdd = this.toggleAdd.bind(this)
        this.toggleUpdate = this.toggleUpdate.bind(this)
        this.toggleModalAU = this.toggleModalAU.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.getHolidayDetails = this.getHolidayDetails.bind(this)
    }

    //holiday list default
    componentDidMount = () => {
        this.setState({
            isAccessToView: true,
            holidayDateHLS: localStorage.getItem('holidayDateHLS'),
            holidayNameHLS: localStorage.getItem('holidayNameHLS'),
            holidayTypeHLS: localStorage.getItem('holidayTypeHLS'),
            statusHLS: localStorage.getItem('statusHLS'),

            holidayDateSearch: localStorage.getItem('holidayDateHLS') != '' ? localStorage.getItem('holidayDateHLS') : '',
            holidayNameSearch: localStorage.getItem('holidayNameHLS') != '' ? localStorage.getItem('holidayNameHLS') : '',
            holidayTypeSearch: localStorage.getItem('holidayTypeHLS') != '' ? localStorage.getItem('holidayTypeHLS') : '',
            status: localStorage.getItem('statusHLS') != '' ? localStorage.getItem('statusHLS') : '',

        })
        clearLocalStorage("holiday")
        setTimeout(() => {
            this.getFillList(holidayTypeActionId, '')
            this.getHolidayList()
        }, 100);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    //Fill list
    getFillList(value, ref, ref2) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == holidayTypeActionId) {
                        this.setState({ holidayTypeList: data.data })
                        setTimeout(() => {
                            console.log(this.state.holidayTypeList)
                            this.setState({ isLoading: false })
                        }, 200);

                    }
                }
            })
    }

    //get list
    getHolidayList() {
        const { holidayDateSearch, holidayNameSearch, holidayTypeSearch, status } = this.state
        this.setState({ isLoading: true })
        this.props
            .holidayList_action(holidayDateSearch, holidayNameSearch, holidayTypeSearch, status === '' ? 1 : parseInt(status))
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        holidayList: data.data,
                        isLoading: false,
                        currentPage: 1
                    })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    //get details
    getHolidayDetails() {
        setTimeout(() => {
            var id = this.state.HolidayID
            this.props
                .holidayDetail_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            holidayName: data.data[0]['Description'],
                            holidayDate: moment(data.data[0]['HolidayDate']).toDate(),
                            holidayTypeId: data.data[0]['Type'],
                            status: data.data[0]['Status'],
                        })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 200);
    }

    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    //search
    handleSearch() {
        const { holidayDateSearch, holidayNameSearch, holidayTypeSearch, status } = this.state
        clearLocalStorage("holiday")
        this.setState({ isLoading: true })
        this.props
            .holidayList_action(
                holidayDateSearch,
                holidayNameSearch.trim(), holidayTypeSearch.trim(), status === '' ? 1 : parseInt(status))
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ holidayList: data.data, isLoading: false, currentPage: 1 })
                    console.log(data)
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
        // localStorage.setItem("holidayDateHLS", holidayDate)
        localStorage.setItem("holidayNameHLS", holidayNameSearch)
        localStorage.setItem("holidayTypeHLS", holidayTypeSearch)
        localStorage.setItem("statusHLS", status)
    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }
    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
            this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
    };

    handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
            this.setState({ pageSizeInput: input });
        }
    };
    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.handleSearch()
        }
    }

    //reset values
    handleReset() {
        this.setState({
            HolidayID: '',
            holidayName: '',
            holidayDate: '',
            holidayTypeId: '',
            holidayTypeSearch: '',
            holidayNameSearch: '',
            holidayDateSearch: '',
            holidayTypeIdSearch: '',
        })
    }

    toggleAdd() {
        const { is_add } = this.state
        this.setState({ is_add: true })
        this.handleReset()
        this.toggleModalAU()
    }

    toggleUpdate(holiday) {
        const { is_update } = this.state
        this.handleReset()
        this.setState({ is_update: true, HolidayID: holiday })
        this.toggleModalAU()
    }

    //clear value
    clearValue() {
        this.setState({
            holidayList: [],
            holidayDate: '',
            holidayName: '',
            holidayType: '',
            holidayTypeSearch: '',
            holidayDateHLS: '',
            holidayNameHLS: '',
            holidayTypeHLS: '',
            currentPage: 1,
            statusHLS: '',
            status: '',
            holidayNameSearch: '',
            holidayDateSearch: '',
            holidayTypeIdSearch: '',
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getHolidayList()
        }, 300);
    }

    //delete handle
    handleDelete(e, HolidayID) {
        e.preventDefault()
        var id = HolidayID
        this.setState({ isLoading: true })
        this.props
            .deleteHoliday_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isOpen: !this.state.isOpen, isLoading: false })
                    this.getHolidayList()
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    //toggal for delete
    toggleModal(HolidayID) {
        const { isOpen, holidayDeleteId } = this.state
        this.setState({ isOpen: !isOpen })
        if (HolidayID == '') {
            return
        }
        else {
            this.setState({ holidayDeleteId: HolidayID })
        }
    }

    //toggle/reset
    toggleModalAU(holiday) {
        const { isOpenAU } = this.state
        this.getHolidayDetails()
        this.setState({ isOpenAU: !isOpenAU })
    }
    handleToggle(id, type) {
        const { isOpenAU, } = this.state
        if (type == 'add') {
            this.setState({
                is_add: true, is_update: false, isOpenAU: !isOpenAU,
                holidayName: "", holidayDate: "", holidayTypeId: ""
            })
            this.handleReset()
        } else {
            this.handleReset()
            this.setState({ is_update: true, is_add: false, isOpenAU: !isOpenAU, HolidayID: id })
            this.getHolidayDetails()
        }
    }
    //add holiday
    handleAdd(e) {
        e.preventDefault()
        const { holidayDate, holidayName, holidayTypeId, isOpenAU } = this.state
        if (holidayName != "" && (holidayDate != "" && holidayDate != null && holidayDate != "Invalid Date")
            && holidayTypeId != "") {
            this.setState({ isLoading: true })
            console.log(holidayDate, holidayName, holidayTypeId)
            this.props
                .addHoliday_action(holidayDate, holidayName.trim(), holidayTypeId)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false, isOpenAU: !isOpenAU })
                        // this.toggleModalAU()
                        this.clearValue()
                        // setTimeout(() => {
                        // this.getHolidayList()
                        // }, 500);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //update holiday
    handleUpdate(e) {
        e.preventDefault()
        const { HolidayID, holidayDate, holidayName, holidayTypeId, isOpenAU } = this.state
        const id = HolidayID

        if (id != "" && (holidayDate != "" && holidayDate != null && holidayDate != "Invalid Date") && holidayName != "" && holidayTypeId != "") {
            this.setState({ isLoading: true })
            this.props
                .updateHoliday_action(id, holidayDate, holidayName.trim(), holidayTypeId)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false, isOpenAU: !isOpenAU })
                        // this.toggleModalAU()
                        this.clearValue()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //record
    record(holidayList) {

        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ width: '180px', textAlign: 'center' }} >Holiday Date</th>
                        <th style={{ textAlign: 'left' }} >Holiday Description</th>
                        <th style={{ width: '100px' }}>Holiday Type</th>
                        <th style={{ width: '100px' }}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {holidayList.length > 0 ? (
                        holidayList.map((data, i) => (
                            <tr key={data.HolidayID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        {getModuleUpdateAccess(Master) ?
                                            <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleToggle(data.HolidayID, 'update')} /></a> :
                                            <a><i className="fa fa-eye" onClick={() => this.handleToggle(data.HolidayID, 'update')} /></a>
                                        }
                                        {data.Status === true && getModuleDeleteAccess(Master) ? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.HolidayID)} /></span></a>
                                            : <></>}

                                    </div>
                                </td>
                                <td style={{ textAlign: 'center' }}>{data.HolidayDate}</td>
                                <td style={{ textAlign: 'left' }}>{data.Description}</td>
                                <td>{data.Typedesc}</td>
                                <td>{data.Status == true ? "Active" : "Inactive"}</td>
                            </tr>
                        ))
                    ) : (<tr><td colSpan={5} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }


    render() {
        const { isSearchTab, isLoading, isAccessToView, holidayTypeId,
            holidayTypeList, holidayList, holidayDateSearch, holidayNameSearch, holidayTypeSearch, status, holidayDeleteId, isOpenAU, is_add, is_update, isOpen } = this.state
        // const recordsPerPage = 10
        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = holidayList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(holidayList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />


                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>Holiday </a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className='page-space-from-bottom'>
                                        <div className="row">
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body pr-2">


                                                        <div className="accordion accordion-flush " id="accordionFlushExample">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingOne">
                                                                    <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                                                        <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                    </button>
                                                                </h2>
                                                                {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                                <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                    <div className="d-flex flex-wrap row">
                                                                        <div className="form-group col-md-2">
                                                                            <label>Holiday Date</label>
                                                                            <div className="form-group d-flex">
                                                                                <DatePicker className="form-control"
                                                                                    dateFormat="dd-MM-yyyy"
                                                                                    selected={this.state.holidayDateSearch}
                                                                                    onChange={(date) => {
                                                                                        this.setState({ holidayDateSearch: date });
                                                                                    }}
                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    placeholderText="Holiday Date"
                                                                                    customInput={<ExampleCustomInput />}
                                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                                    popperClassName="example-datepicker-class"
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group col-md-2">
                                                                            <label>Holiday Name</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder=""
                                                                                value={holidayNameSearch}
                                                                                onChange={(e) => this.setState({ holidayNameSearch: e.target.value })}
                                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                                required />
                                                                        </div>

                                                                        <div className="form-group col-md-3">
                                                                            <label>Holiday Type</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder=""
                                                                                value={holidayTypeSearch}
                                                                                onChange={(e) => this.setState({ holidayTypeSearch: e.target.value })}
                                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                                required />
                                                                        </div>

                                                                        <div className="form-group col-md-3">
                                                                            <label>Status</label>
                                                                            <select className="js-example-basic-single" style={{ width: '100%' }}
                                                                                value={status}
                                                                                onChange={(e) => this.setState({ status: e.target.value })}>
                                                                                <option value={''}>Select</option>
                                                                                <option value={1}>Active</option>
                                                                                <option value={0}>Inactive</option>
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group col-md-2 filter-btn-col p-0">
                                                                            <label></label>
                                                                            <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={e => this.handleSearch()}> Search</button>
                                                                            <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3" onClick={e => this.clearValue()}> Clear</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div> : <></>}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 mt-1">
                                                                {getModuleWriteAccess(Master) && <button type="button" className="btn btn-Add rounded-1 mb-1 float-right px-3" onClick={(() => this.handleToggle('', 'add'))}>Add</button>}
                                                                {/* button */}
                                                                <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                                    {this.record(currentRecords)}
                                                                    {holidayList.length > 0 ? <Pagination
                                                                        nPages={nPages}
                                                                        currentPage={currentPage}
                                                                        setCurrentPage={this.updateItem}
                                                                        recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                        setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                        totalRecords={holidayList.length}
                                                                    /> : <></>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>
                <ToastContainer />
                {/* // modal for delete */}
                {/* <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, holidayDeleteId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}
                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right">
                                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, holidayDeleteId)}> Yes</a>
                                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* modal for AU */}
                <Modal isOpen={!isOpenAU} onRequestClose={this.toggleModalAU}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                {is_add == true ? <h4 class="modal-title modal_title_text">Add Holiday</h4> : <h4 class="modal-title modal_title_text">Update Holiday</h4>}
                                {is_add == true ? <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleAdd} ><span aria-hidden="true" class="pop-cancel">&times;</span></button> : <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.toggleUpdate()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>}
                            </div>
                            <div class="modal-body">
                                <div class="d-flex flex-wrap row mb-3">
                                    <div class="form-group col-md-6">
                                        <label>Holiday Date<span class="required1">*</span></label>
                                        <div className="form-group d-flex">
                                            <DatePicker className="form-control"
                                                dateFormat="dd-MM-yyyy"
                                                // disabled={!status}
                                                selected={this.state.holidayDate}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                onChange={(date) => this.setState({ holidayDate: date })}
                                                placeholderText="Holiday Date"
                                                customInput={<ExampleCustomInput />}
                                                dayClassName={() => "example-datepicker-day-class"}
                                                popperClassName="example-datepicker-class"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap row mb-3">
                                    <div className="form-group col-md-6">
                                        <label>Holiday Name<span class="required1">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='Name'
                                            // disabled={!status}
                                            value={this.state.holidayName}
                                            onChange={(e) => this.setState({ holidayName: e.target.value })}
                                            required
                                        />
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap row mb-3">
                                    <div className="form-group col-md-6">
                                        <label>Holiday Type<span class="required1">*</span></label>
                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                            value={holidayTypeId}
                                            // disabled={!status}
                                            onChange={(e) => this.setState({ holidayTypeId: e.target.value, })}>
                                            <option value={''}>Select</option>
                                            {holidayTypeList.length > 0 && holidayTypeList.map(data => (
                                                <option value={data.TypeID
                                                } key={data.TypeID
                                                }>{data.Typedesc}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        {is_add == true ? getModuleWriteAccess(Master) && <a class="btn save_btn del-pop-save" onClick={this.handleAdd}> Save</a> : status && getModuleUpdateAccess(Master) && <a class="btn save_btn del-pop-save" onClick={this.handleUpdate}> Update</a>}
                                        {is_add == true && getModuleWriteAccess(Master) ? <a class="btn reset_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.handleReset}>Reset</a> : <></>}
                                        {is_add == true ? <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleAdd}>Cancel</a> : <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.toggleUpdate()}>Cancel</a>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>

            </>
        );
    }
}

export default withRouter(connect(null, { holidayList_action, fill_action, holidayDetail_action, addHoliday_action, updateHoliday_action, deleteHoliday_action })(Holiday))