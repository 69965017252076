
import {
    EXPENSE_ACTION_TYPE,
    EXPENSE_DETAILS_ACTION_TYPE,
    EXPENSE_ADD_ACTION_TYPE,
    EXPENSE_UPDATE_ACTION_TYPE,
    EXPENSE_DELETE_ACTION_TYPE,
    EXPENSE_GET_TIMESHEETID_ACTION_TYPE,
    EXPENSE_APPROVAL_ACTION_TYPE,
    EXPENSE_DELETE_DOCUMENT_ACTION_TYPE,
    EXPENSE_LASTTIMESHEET_FILL_ACTION_TYPE
 } from "../../utils/constants"


const initialState = [];

function expenseReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EXPENSE_ACTION_TYPE:
      return [...state, payload];
      case EXPENSE_DETAILS_ACTION_TYPE:
      return [...state, payload];
    case EXPENSE_ADD_ACTION_TYPE:
      return [...state, payload];
      case EXPENSE_UPDATE_ACTION_TYPE:
        return [...state, payload];
        case EXPENSE_DELETE_ACTION_TYPE:
      return [...state, payload];
      case EXPENSE_UPDATE_ACTION_TYPE:
      return [...state, payload];
      case EXPENSE_APPROVAL_ACTION_TYPE:
      return [...state, payload];
      case EXPENSE_GET_TIMESHEETID_ACTION_TYPE:
      return [...state, payload];
      case EXPENSE_DELETE_DOCUMENT_ACTION_TYPE:
      return [...state, payload];
      case EXPENSE_LASTTIMESHEET_FILL_ACTION_TYPE:
      return [...state, payload];
    default:
      return state;
  }
};

export default expenseReducer;


// export default (state = {}, action) => {
//     switch (action.type) {
//      case 'SIMPLE_ACTION':
//       return {
//        result: action.payload
//       }
//      default:
//       return state
//     }
//    }