import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    platfarm, headers,
    MCQ_SETUP_LIST_ACTION_TYPE,
    MCQ_SETUP_DETAILS_ACTION_TYPE,
    MCQ_SETUP_UPDATE_ACTION_TYPE,
    MCQ_SETUP_ADD_ACTION_TYPE,
    MCQ_SETUP_DELETE_ACTION_TYPE,
    mcqSetupListUrl,
    addMCQSetupUrl,deleteMCQSetupUrl,
    getMCQSetupDetailsUrl,
    updateMCQSetupUrl,
    TrainingStatus,fetchAuthReport
} from "../../utils/constants"
import moment from 'moment';



export const mcqSetupList_action = (chapterId, levelId, status, question) => async (dispatch) => {
    try {

        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            Chapterid: chapterId != "" ? parseInt(chapterId) : null,
            Isactive: status != "" ? parseInt(status) : 1, 
            Question: question,
            level: levelId != "" ? parseInt(levelId) : null, 

        }
        console.log(param);
        // req.input('Userid', request.query.UserID);
        //     req.input('Employeeid', request.query.Platform);
        //     req.input('Platform', request.query.Platform);
        //     req.input('Chapterid', request.query.Chapterid);
        //     req.input('Isactive', request.query.Isactive);
        //     req.input('Question', request.query.Question);
        //     req.input('level', request.query.level);
        const res = await axiosGet(mcqSetupListUrl, param);
        dispatch({
            type: MCQ_SETUP_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const mcqSetupDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            Userid: localStorage.getItem('UserId'),
                Employeeid:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
                Platform: platfarm,
                Isactive : 1,
                QuestionID: id,
        }        
        const res = await axiosGet(getMCQSetupDetailsUrl, param);
        console.log("bloodGroupDetails_action", param)
        dispatch({
            type: MCQ_SETUP_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } 
    catch(err){
        return Promise.reject(err);
    }
};

export const addmcqSetup_action = (Questionid, chapterId, levelId, option1, option2, option3, option4, answerKeyId, question) => async (dispatch) => {
        try {
            // new Date(moment(auditPeriodStartDate ).format('MM-DD-YYYY')+'Z').toISOString(),
            // moment(ToTrainingDate).format('MM-DD-YYYY')
            const mapdata = {
                Userid: localStorage.getItem('UserId'),
                Employeeid:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
                Platform: platfarm,
                Questionid: Questionid,
                Chapterid: chapterId,
                Question: question,
                option1: option1,
                option2: option2,
                option3: option3,
                option4 : option4,
                AnswerKey : answerKeyId,
                Level : levelId,
                Isactive : 1,

            }
            const res = await axiosPost(addMCQSetupUrl, mapdata, headers);

            dispatch({
                type: MCQ_SETUP_ADD_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    export const updatemcqSetup_action = (trainigAddList) => async (dispatch) => {
        try {
            // new Date(moment(auditPeriodStartDate ).format('MM-DD-YYYY')+'Z').toISOString(),
            // moment(ToTrainingDate).format('MM-DD-YYYY')
            const mapdata = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
                trainigAddList: trainigAddList,
            }
            const res = await axiosPost(updateMCQSetupUrl, mapdata, headers);

            dispatch({
                type: MCQ_SETUP_UPDATE_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };


export const deletemcqSetup_action = (id) => async (dispatch) => {
    try {
        const param = {
            Userid: localStorage.getItem('UserId'),
            Employeeid:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            Platform: platfarm,
            Questionid: id,

        }
        const res = await axiosGet(deleteMCQSetupUrl, param);
        dispatch({
            type: MCQ_SETUP_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

 