import React, { Component } from "react";
import Loader from "../../../utils/Loader";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Modal from "react-modal";
import Pagination from '../../../utils/pagination'
import { clientList_action, deleteClient_action } from '../../../actions/master_action/client_action'
import { clearLocalStorage, Master } from '../../../utils/constants'
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from "../../../utils/RoleAccess";

class ClientList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clientList: [],
            isAccessToView: true,
            clientNameCLS: '',
            fileNoCLS: '',
            clientGroupCLS: '',
            clientCategoryCLS: '',
            statusCLLS: '',
            clientName: '',
            fileNo: '',
            clientGroup: '',
            clientCategory: '',
            status: ' ',
            isLoading: false,
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage: 10,
            pageSizeInput: "10",
            isOpen: false,
            clientDeleteId: '',
            isStatusSort: false,
            isClientCategorySort: false,
            isClientGroupSort: false,
            isFileNoSort: false,
            isClientNameSort: false,
            isClientTypeSort: false,
            isSearchTab: false,
        }
        this.record = this.record.bind(this)
        this.getClientList = this.getClientList.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);

        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.statusSort = this.statusSort.bind(this)
        this.clientCategorySort = this.clientCategorySort.bind(this)
        this.clientGroupSort = this.clientGroupSort.bind(this)
        this.fileNoSort = this.fileNoSort.bind(this)
        this.clientNameSort = this.clientNameSort.bind(this)
        this.clientTypeSort = this.clientTypeSort.bind(this)
        this.handleIndividualAdd = this.handleIndividualAdd.bind(this)
        this.handleNonIndividualAdd = this.handleNonIndividualAdd.bind(this)
    }

    //SORTING
    statusSort() {
        const { clientList, isStatusSort } = this.state
        this.setState({ isStatusSort: !isStatusSort })
        if (!isStatusSort) {
            //sorting
            var statusSort = clientList.sort((a, b) => {
                const nameA = a.IsActive
                const nameB = b.IsActive
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })
            this.setState({ clientList: statusSort })
        }
        else {
            //reverse
            var statusSort = clientList.sort((a, b) => {
                const nameA = a.IsActive
                const nameB = b.IsActive
                if (nameA < nameB) {
                    return 1
                }
                if (nameA > nameB) {
                    return -1
                }
                return 0
            })
            this.setState({ clientList: statusSort })
        }
        this.setState({ isStatusSort: !statusSort })
    }

    clientCategorySort() {
        const { clientList, isClientCategorySort } = this.state
        this.setState({ isClientCategorySort: !isClientCategorySort })
        if (!isClientCategorySort) {
            var clientCategorySort = clientList.sort((a, b) => {
                const nameA = a.ClientCategory != null && a.ClientCategory.toUpperCase()
                const nameB = b.ClientCategory != null && b.ClientCategory.toUpperCase()
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })
            this.setState({ clientList: clientCategorySort })
        }
        else {
            //reverse
            var clientCategorySort = clientList.sort((a, b) => {
                const nameA = a.ClientCategory != null && a.ClientCategory.toUpperCase()
                const nameB = b.ClientCategory != null && b.ClientCategory.toUpperCase()
                if (nameA < nameB) {
                    return 1
                }
                if (nameA > nameB) {
                    return -1
                }
                return 0
            })
            this.setState({ clientList: clientCategorySort })
        }
        this.setState({ isClientCategorySort: !isClientCategorySort })
    }

    clientGroupSort() {
        const { clientList, isClientGroupSort } = this.state
        this.setState({ isClientGroupSort: !isClientGroupSort })
        if (!isClientGroupSort) {
            var clientGroupSort = clientList.sort((a, b) => {
                const nameA = a.ClientGroupName != null && a.ClientGroupName.toUpperCase()
                const nameB = b.ClientGroupName != null && b.ClientGroupName.toUpperCase()
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })
            this.setState({ clientList: clientGroupSort })
        }
        else {
            //reverse
            var clientGroupSort = clientList.sort((a, b) => {
                const nameA = a.ClientGroupName != null && a.ClientGroupName.toUpperCase()
                const nameB = b.ClientGroupName != null && b.ClientGroupName.toUpperCase()
                if (nameA < nameB) {
                    return 1
                }
                if (nameA > nameB) {
                    return -1
                }
                return 0
            })
            this.setState({ clientList: clientGroupSort })
        }
        this.setState({ isClientGroupSort: !isClientGroupSort })
    }

    fileNoSort() {
        const { clientList, isFileNoSort } = this.state
        this.setState({ isFileNoSort: !isFileNoSort })
        if (!isFileNoSort) {
            var fileNoSort = clientList.sort((a, b) => {
                const nameA = a.FileNo != null && a.FileNo.toUpperCase()
                const nameB = b.FileNo != null && b.FileNo.toUpperCase()
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })
            this.setState({ clientList: fileNoSort })
        }
        else {
            //reverse
            var fileNoSort = clientList.sort((a, b) => {
                const nameA = a.FileNo != null && a.FileNo.toUpperCase()
                const nameB = b.FileNo != null && b.FileNo.toUpperCase()
                if (nameA < nameB) {
                    return 1
                }
                if (nameA > nameB) {
                    return -1
                }
                return 0
            })
            this.setState({ clientList: fileNoSort })
        }
        this.setState({ isFileNoSort: !isFileNoSort })
    }

    clientNameSort() {
        const { clientList, isClientNameSort } = this.state
        this.setState({ isClientNameSort: !isClientNameSort })
        if (!isClientNameSort) {
            var clientNameSort = clientList.sort((a, b) => {
                const nameA = a.ClientFName != null && a.ClientFName.toUpperCase()
                const nameB = b.ClientFName != null && b.ClientFName.toUpperCase()
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })
            this.setState({ clientList: clientNameSort })
        }
        else {
            //reverse
            var clientNameSort = clientList.sort((a, b) => {
                const nameA = a.ClientFName != null && a.ClientFName.toUpperCase()
                const nameB = b.ClientFName != null && b.ClientFName.toUpperCase()
                if (nameA < nameB) {
                    return 1
                }
                if (nameA > nameB) {
                    return -1
                }
                return 0
            })
            this.setState({ clientList: clientNameSort })
        }
        this.setState({ isClientNameSort: !isClientNameSort })
    }

    clientTypeSort() {
        const { clientList, isClientTypeSort } = this.state
        this.setState({ isClientTypeSort: !isClientTypeSort })
        if (!isClientTypeSort) {
            //sorting
            var clientTypeSort = clientList.sort((a, b) => {
                const nameA = a.ClientTypeID
                const nameB = b.ClientTypeID
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })
            this.setState({ clientList: clientTypeSort })
        }
        else {
            //reverse
            var clientTypeSort = clientList.sort((a, b) => {
                const nameA = a.ClientTypeID
                const nameB = b.ClientTypeID
                if (nameA < nameB) {
                    return 1
                }
                if (nameA > nameB) {
                    return -1
                }
                return 0
            })
            this.setState({ clientList: clientTypeSort })
        }
        this.setState({ isClientTypeSort: !isClientTypeSort })
    }
    //get client list

    getClientList() {
        const { clientName, fileNo, clientGroup, clientCategory, status } = this.state
        this.setState({ isLoading: true })
        this.props
            .clientList_action(clientName.trim(), fileNo.trim(), clientGroup.trim(), clientCategory.trim(), status == '' ? 1 : status)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        clientList: data.data,
                        isLoading: false,
                        currentPage: 1
                    })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    //client list default
    componentDidMount = () => {
        this.setState({
            isAccessToView: true,
            clientNameCLS: localStorage.getItem('clientNameCLS'),
            fileNoCLS: localStorage.getItem('fileNoCLS'),
            clientGroupCLS: localStorage.getItem('clientGroupCLS'),
            clientCategoryCLS: localStorage.getItem('clientCategoryCLS'),
            statusCLLS: localStorage.getItem('statusCLLS'),

            clientName: localStorage.getItem('clientNameCLS') != '' ? localStorage.getItem('clientNameCLS') : '',
            fileNo: localStorage.getItem('fileNoCLS') != '' ? localStorage.getItem('fileNoCLS') : '',
            clientGroup: localStorage.getItem('clientGroupCLS') != '' ? localStorage.getItem('clientGroupCLS') : '',
            clientCategory: localStorage.getItem('clientCategoryCLS') != '' ? localStorage.getItem('clientCategoryCLS') : '',
            status: localStorage.getItem('statusCLLS') != '' ? localStorage.getItem('statusCLLS') : '',
        })

        console.log("clientGroupCLS 1", localStorage.getItem('clientGroupCLS'))
        console.log("clientGroupCLS 2", this.state.clientGroup)
        clearLocalStorage("client")
        setTimeout(() => {
            this.getClientList()
        }, 100);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }

    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };


    handleIndividualAdd() {
        this.props.navigate('/ClientMasterAU', { state: { is_add: true, clientTypeId: 1 } })
    }

    handleNonIndividualAdd() {
        this.props.navigate('/ClientMasterAU', { state: { is_add: true, clientTypeId: 2 } })
    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }
    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
            this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
    };

    handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
            this.setState({ pageSizeInput: input });
        }
    };
    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.handleSearch()
        }
    }

    //search
    handleSearch() {
        const { clientName, fileNo, clientGroup, clientCategory, status } = this.state
        clearLocalStorage("client")
        console.log("statusCLLS  34222", status.valueOf())
        console.log("statusCLLS  34222", status.trim() == '' ? '1' : status)
        this.setState({ isLoading: true })
        this.props
            .clientList_action(clientName.trim(), fileNo.trim(), clientGroup.trim(),
                clientCategory.trim(), status.trim() == '' ? '1' : status)
            .then((data) => {
                if (data.error != 1) {

                    this.setState({ clientList: data.data, isLoading: false, currentPage: 1 })
                    console.log(data)
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })

        console.log("clientNameCLS", clientName)
        console.log("fileNoCLS", fileNo)
        console.log("clientGroupCLS", clientGroup)
        console.log("clientCategoryCLS", clientCategory)
        console.log("statusCLLS", status)
        localStorage.setItem("clientNameCLS", clientName)
        localStorage.setItem("fileNoCLS", fileNo)
        localStorage.setItem("clientGroupCLS", clientGroup)
        localStorage.setItem("clientCategoryCLS", clientCategory)
        localStorage.setItem("statusCLLS", status)
    }

    clearValue() {
        this.setState({
            clientList: [],
            clientNameCLS: '',
            fileNoCLS: '',
            clientGroupCLS: '',
            clientCategoryCLS: '',
            statusCLLS: '',
            clientName: '',
            fileNo: '',
            clientGroup: '',
            clientCategory: '',
            status: '',
            currentPage: 1,
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getClientList()
        }, 300);
    }

    //update client
    handleUpdate(ClientID, ClientTypeID) {
        this.props.navigate('/ClientMasterAU', { state: { is_update: true, ClientID: ClientID, clientTypeId: ClientTypeID } })
    }

    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    //delete client
    handleDelete(e, ClientID) {
        e.preventDefault()
        var id = ClientID
        this.setState({ isLoading: true })
        this.props
            .deleteClient_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isOpen: !this.state.isOpen, isLoading: false })
                    this.getClientList()
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    toggleModal(ClientID) {
        const { isOpen, clientDeleteId } = this.state
        this.setState({ isOpen: !isOpen })
        if (ClientID == '') {
            return
        }
        else {
            this.setState({ clientDeleteId: ClientID })
        }
    }

    record(clientList) {

        return (
            <table className="table table-bordered tb-action du-table" style={{ marginBottom: '80px' }}>
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ width: '180px', textAlign: 'center' }} onClick={this.clientTypeSort}>Client Type</th>
                        <th style={{ textAlign: 'left' }} onClick={this.clientNameSort}>Client Name</th>
                        <th style={{ width: '100px' }} onClick={this.fileNoSort}>File No</th>
                        <th style={{ width: '120px' }} onClick={this.clientGroupSort}>Client Group</th>
                        <th style={{ width: '120px' }} onClick={this.clientCategorySort}>Client Category</th>
                        <th style={{ width: '100px' }} onClick={this.statusSort}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {clientList.length > 0 ? (
                        clientList.map((data, i) => (
                            <tr key={data.ClientID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        {getModuleUpdateAccess(Master) ?
                                            <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleUpdate(data.ClientID, data.ClientTypeID)} /></a> :
                                            <a><i className="fa fa-eye" style={{ color: '#3498db' }} onClick={() => this.handleUpdate(data.ClientID, data.ClientTypeID)} /></a>
                                        }
                                        {data.IsActive === true && getModuleDeleteAccess(Master) ? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.ClientID)} /></span></a>
                                            : <></>}

                                    </div>
                                </td>
                                <td style={{ textAlign: 'center' }}>{data.ClientTypeID === 1 ? "Individual" : "Non-Individual"}</td>
                                <td style={{ textAlign: 'left' }}>{data.ClientFName}</td>
                                <td>{data.FileNo}</td>
                                <td>{data.ClientGroupName}</td>
                                <td>{data.ClientCategory}</td>
                                <td>{data.IsActive == true ? "Active" : "Inactive"}</td>
                            </tr>
                        ))
                    ) : (<tr><td colSpan={7} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }

    render() {
        const { isSearchTab, isAccessToView, fileNo, clientList, tablePerPage, isLoading, isOpen, clientDeleteId, clientName, clientGroup, clientCategory, status } = this.state
        // const recordsPerPage = 
        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = clientList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(clientList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />


                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>Client </a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className=' page-scroll-from-bot'>
                                        <div className="row">
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body pr-2">


                                                        <div className="accordion accordion-flush " id="accordionFlushExample">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingOne">
                                                                    <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                                                        <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                    </button>
                                                                </h2>
                                                                {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                                <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                    <div className="d-flex flex-wrap row">
                                                                        <div className="form-group col-md-2">
                                                                            <label>Client Name</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder=""
                                                                                value={clientName}
                                                                                onChange={(e) => this.setState({ clientName: e.target.value })}
                                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                                required />
                                                                        </div>

                                                                        <div className="form-group col-md-2">
                                                                            <label>File No</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder=""
                                                                                value={fileNo}
                                                                                onChange={(e) => this.setState({ fileNo: e.target.value })}
                                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                                required />
                                                                        </div>

                                                                        <div className="form-group col-md-2">
                                                                            <label>Client Group</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder=""
                                                                                value={clientGroup}
                                                                                onChange={(e) => this.setState({ clientGroup: e.target.value })}
                                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                                required />
                                                                        </div>

                                                                        <div className="form-group col-md-2">
                                                                            <label>Client Category</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder=""
                                                                                value={clientCategory}
                                                                                onChange={(e) => this.setState({ clientCategory: e.target.value })}
                                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                                required />
                                                                        </div>

                                                                        <div className="form-group col-md-2">
                                                                            <label>Status</label>
                                                                            <select className="js-example-basic-single" style={{ width: '100%' }}
                                                                                value={status}
                                                                                onChange={(e) => this.setState({ status: e.target.value })}>
                                                                                <option value={''}>Select</option>
                                                                                <option value={1}>Active</option>
                                                                                <option value={0}>Inactive</option>
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group col-md-2 filter-btn-col p-0">
                                                                            <label></label>
                                                                            <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={e => this.handleSearch()}> Search</button>
                                                                            <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3" onClick={e => this.clearValue()}> Clear</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div> : <></>}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 mt-2">
                                                                {getModuleWriteAccess(Master) &&
                                                                    <>
                                                                        <button type="button" className="btn btn-Add rounded-0 mb-2"
                                                                            style={{ lineHeight: '14px', padding: '7px', float: 'right' }} onClick={this.handleIndividualAdd}>individual Add</button>
                                                                        <button type="button" className="btn btn-Add rounded-0 mb-2 mr-2"
                                                                            style={{ lineHeight: '14px', padding: '7px', float: 'right' }} onClick={this.handleNonIndividualAdd}>non individual Add</button>
                                                                    </>
                                                                }
                                                                {/* button */}
                                                                <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                                    {this.record(currentRecords)}
                                                                    {clientList.length > 0 ? <Pagination
                                                                        nPages={nPages}
                                                                        currentPage={currentPage}
                                                                        setCurrentPage={this.updateItem}
                                                                        recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                        setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                        totalRecords={clientList.length}
                                                                    /> : <></>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>
                <ToastContainer />
                {/* // modal for delete */}
                {/* <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, clientDeleteId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}

                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right">
                                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, clientDeleteId)}> Yes</a>
                                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(connect(null, { clientList_action, deleteClient_action })(ClientList))