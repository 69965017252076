import { ENGAGEMENTROLE_ADD_ACTION_TYPE, ENGAGEMENTROLE_DETAILS_ACTION_TYPE, ENGAGEMENTROLE_LIST_ACTION_TYPE, ENGAGEMENTROLE_UPDATE_ACTION_TYPE } from '../../utils/constants'

const initialState = []

function engagementRoleReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case ENGAGEMENTROLE_LIST_ACTION_TYPE:
            return [...state, payload];
        case ENGAGEMENTROLE_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case ENGAGEMENTROLE_ADD_ACTION_TYPE:
            return [...state, payload];
        case ENGAGEMENTROLE_UPDATE_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}

export default engagementRoleReducer