import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { addLeave_action, leaveDetail_action, updateLeave_action, minLeaveDate_action } from '../../../actions/time_sheet_expense_action/leave_action'
import { fill_action } from '../../../actions/master_action/checklist_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Loader from '../../../utils/Loader'
import { employeeActionId, Leave, leaveTypeActionId, timesheetStatusActionId } from '../../../utils/constants'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));


class LeaveAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            isOpen: false,
            is_add: false,
            is_update: false,
            isLoading: false,

            employeeId: (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            leaveTypeId: '',
            fromDate: '',
            toDate: '',
            remark: '',
            rejectionReason: '',
            statusId: '',
            leaveID: '',
            date: new Date(),

            leaveTypeList: [],
            statusIdList: [],
            employeeList: [],
            statusName: '',
            minDate: '',
            timesheetDisable: false,
        }

        this.handleCancel = this.handleCancel.bind(this)
        this.onSave = this.onSave.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.getMinLeaveDate = this.getMinLeaveDate.bind(this)
        this.getLeaveDetails = this.getLeaveDetails.bind(this)
    }

    componentDidMount = () => {


        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            leaveID: this.props.location.state != null && this.props.location.state.leaveID != null ? this.props.location.state.leaveID : '',
            isAccessToView: localStorage.getItem('UserId') == '1' ? true : false,
        })
        this.getFillList(employeeActionId, '')
        this.getFillList(leaveTypeActionId, '')
        this.getFillList(timesheetStatusActionId, '')
        setTimeout(() => {
            this.getMinLeaveDate()
            console.log(this.state.leaveID)
        }, 100);
        this.props.location.state != null && this.props.location.state.is_update && this.getLeaveDetails()
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    // get fill list 
    getFillList(value, ref) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value, ref)
            .then((data) => {
                if (data.error != 1) {
                    if (value == employeeActionId) {
                        console.log(data)
                        this.setState({ employeeList: data.data, isLoading: false })
                    }
                    if (value == leaveTypeActionId) {
                        console.log(data)
                        this.setState({ leaveTypeList: data.data, isLoading: false })
                    }
                    if (value == timesheetStatusActionId) {
                        console.log(data)
                        this.setState({ statusIdList: data.data, isLoading: false })
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    //get details
    getLeaveDetails() {
        setTimeout(() => {
            var id = this.state.leaveID
            this.props
                .leaveDetail_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            leaveTypeId: data.data[0]['LeaveTypeID'],
                            fromDate: moment(data.data[0]['LeaveFromDate']).toDate(),
                            toDate: moment(data.data[0]['LeaveToDate']).toDate(),
                            remark: data.data[0]['Remark'],
                            rejectionReason: data.data[0]['RejectReason'],
                            statusId: data.data[0]['LeaveStatus'],
                            statusName: data.data[0]['Name'],
                        })

                        if (data.data[0]['Name'] == "Approved") {
                            this.setState({ timesheetDisable: true })
                        }

                        console.log(data)
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 50);
    }

    getMinLeaveDate() {
        setTimeout(() => {
            this.props
                .minLeaveDate_action()
                .then((data) => {
                    if (data.error != 1) {

                        this.setState({ minDate: moment(data.data[0]['TimeSheetDate']).toDate() })
                        setTimeout(() => {
                            console.log(this.state.minDate)
                        }, 200);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 500);
    }

    handleCancel() {
        this.props.navigate('/leaveList')
    }

    //add leave
    onSave() {
        const { leaveTypeId, employeeId, fromDate, toDate, remark } = this.state
        if ((fromDate != "" && fromDate != null && fromDate != "Invalid Date")
            && (toDate != "" && toDate != null && toDate != "Invalid Date")
            && remark != "") {
            this.setState({ isLoading: true })
            this.props
                .addLeave_action(1, employeeId, fromDate, toDate, remark)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.handleCancel()
                        }, 1000);

                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });


        } else {
            this.setState({ isLoading: false })
            toast.error('Please enter mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //update leave
    handleUpdate(e) {
        e.preventDefault()
        const { leaveID, leaveTypeId, employeeId, fromDate, toDate, remark } = this.state
        const id = leaveID
        if (leaveID != "" && leaveTypeId != "" && employeeId != "" &&
            (fromDate != "" && fromDate != null && fromDate != "Invalid Date")
            && (toDate != "" && toDate != null && toDate != "Invalid Date")
            && remark != "") {
            this.setState({ isLoading: true })
            this.props
                .updateLeave_action(id, leaveTypeId, employeeId, fromDate, toDate, remark)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.handleCancel()
                        }, 1000);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    handleReset() {
        this.setState({
            leaveTypeId: '',
            fromDate: '',
            toDate: '',
            remark: '',
        })

    }

    render() {
        const { isAccessToView, statusIdList, statusId, employeeId, leaveTypeList, employeeList, fromDate, rejectionReason, remark, toDate, is_add, leaveTypeId, is_update, isLoading, statusName, timesheetDisable } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        <Drawer />
                        {/* start page */}
                        {/* {isAccessToView ? ( */}
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">

                                    <ul className="breadcrumb">

                                        <li>
                                            <a onClick={this.handleCancel}>Leave </a>
                                        </li>
                                        {/* <li>
                                            <a>{this.state.is_add ? 'New' : 'Update'}</a>
                                        </li> */}
                                        <li />
                                    </ul>
                                </div>
                                <div className=" user-status">

                                    {
                                        is_update && statusName != '' ? <p>
                                            <strong className={statusName === 'Deleted' || statusName === 'Rejected' ? 'redi' : 'greena'}> {statusName}</strong>
                                        </p> : <></>
                                    }
                                </div>
                                <div
                                    className="tab-content"
                                    id="myTabContent">
                                    <div className="row">
                                        <style
                                            type="text/css"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                            }}
                                        />
                                        <div className="col-md-12 pg-body grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row user-status">
                                                        <div className="status-top mr-2">

                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-wrap row ">
                                                        <div className="form-group col-md-3">
                                                            <label>
                                                                Leave Type <span className="required1">*</span>
                                                            </label>
                                                            <select
                                                                //  className={statusId != 2 && is_update && "js-example-basic-single bg-secondary"} style={{ width: "100%" }}
                                                                className={"js-example-basic-single bg-secondary"} style={{ width: "100%" }}
                                                                value={leaveTypeId}
                                                                // disabled={statusId != 2 && is_update == true ? true : false}
                                                                disabled={true}
                                                                onChange={(e) => this.setState({ leaveTypeId: e.target.value })}
                                                            >
                                                                {/*  <option value={''}>Select</option> */}
                                                                {leaveTypeList.length > 0 ? leaveTypeList.map(data => (
                                                                    <option
                                                                        key={data.ID}
                                                                        value={data.ID}>
                                                                        {data.LeaveType}
                                                                    </option>
                                                                )) : <></>}
                                                            </select>
                                                        </div>

                                                        <div className="form-group col-md-3">
                                                            <label>
                                                                Employee
                                                                <span className="required1">*</span>
                                                            </label>
                                                            <select
                                                                className={"js-example-basic-single bg-secondary"}
                                                                style={{ width: "100%" }}
                                                                disabled={true}
                                                                value={employeeId}
                                                                onChange={e => this.setState({ employeeId: e.target.value })}
                                                            >
                                                                <option value={''}>Select</option>
                                                                {employeeList.length > 0 ? employeeList.map(data => (
                                                                    <option
                                                                        key={data.emmnID}
                                                                        value={data.emmnID}>
                                                                        {data.empsFirstName}
                                                                    </option>
                                                                )) : <></>}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-wrap row mt-2">
                                                        <div className="form-group col-md-3">
                                                            <label>
                                                                From Date<span className="required1">*</span>
                                                            </label>
                                                            <div className="form-group d-flex">
                                                                <DatePicker className="form-control js-example-basic-single w-100"
                                                                    dateFormat="dd-MM-yyyy"
                                                                    disabled={statusId != 2 && statusId != 4 && is_update == true ? true : false}
                                                                    selected={this.state.fromDate}
                                                                    minDate={this.state.minDate}
                                                                    maxDate={this.state.toDate}
                                                                    onChange={(date) => {
                                                                        this.setState({ fromDate: date, toDate: date })
                                                                    }}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    placeholderText="From Date"
                                                                    customInput={<ExampleCustomInput />}
                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                    popperClassName="example-datepicker-class"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-3">
                                                            <label>
                                                                To Date<span className="required1">*</span>
                                                            </label>
                                                            <div className="form-group d-flex">
                                                                <DatePicker className="form-control"
                                                                    dateFormat="dd-MM-yyyy"
                                                                    // disabled={this.state.fromDate == ''  || this.state.fromDate == null || this.state.fromDate == "Invalid Date" ? true : false}

                                                                    disabled={this.state.fromDate != '' || this.state.fromDate == null || this.state.fromDate != 'Invalid Date' ?
                                                                        (statusId != 2 && statusId != 4 && is_update == true
                                                                            ? true : false)
                                                                        : true ? true : false}
                                                                    selected={this.state.toDate}
                                                                    minDate={this.state.fromDate == '' || this.state.fromDate == null ? this.state.minDate : this.state.fromDate}
                                                                    onChange={(date) => {
                                                                        this.setState({ toDate: date });

                                                                    }}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    placeholderText="From Date"
                                                                    customInput={<ExampleCustomInput />}
                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                    popperClassName="example-datepicker-class"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-wrap row ">
                                                        <div className="form-group col-md-6">
                                                            <label>Remark<span className="required1">*</span></label>
                                                            <div className="form-group mb-3">
                                                                <textarea
                                                                    className="form-group"
                                                                    name="remark"
                                                                    disabled={statusId != 2 && statusId != 4 && is_update == true ? true : false}

                                                                    maxLength={500}
                                                                    style={{ border: "1px solid #dee2e6" }}
                                                                    defaultValue={remark}
                                                                    value={remark}
                                                                    onChange={e => this.setState({ remark: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        statusName === 'Rejected' ?
                                                            <div className="form-group col-9">
                                                                <label>Rejection Reason</label>
                                                                <div className="form-group mb-3">
                                                                    <textarea
                                                                        maxLength={500}
                                                                        disabled={true}
                                                                        className="form-group"
                                                                        name="Rejection Reason"
                                                                        style={{ border: "1px solid #dee2e6" }}
                                                                        defaultValue={rejectionReason}
                                                                        value={rejectionReason}
                                                                        onChange={e => this.setState({ rejectionReason: e.target.value })}
                                                                    />
                                                                </div>
                                                            </div> : <></>
                                                    }
                                                    {/* <div className="form-group col-md-3">
                                                        <label>
                                                            status
                                                            <span className="required1">*</span>
                                                        </label>
                                                        <select
                                                            className={"js-example-basic-single bg-secondary"}
                                                            style={{ width: "100%" }}
                                                            disabled={true}
                                                            value={statusId} onChange={e => this.setState({ statusId: e.target.value })}>
                                                             <option value={''}>Select</option>
                                                            {statusIdList.length > 0 ? statusIdList.map(data => (
                                                                <option
                                                                    key={data.TimeSheetStatusID}
                                                                    value={data.TimeSheetStatusID}>
                                                                    {data.Name}
                                                                </option>
                                                            )) : <></>}
                                                        </select>
                                                    </div> */}

                                                    <div style={{ height: 50 }} />
                                                    <div className="row">
                                                        <div className="footer-btn">
                                                            <div className="col-md-3">
                                                                <div
                                                                    className="btn-group"
                                                                    role="group"
                                                                    aria-label="Basic example"
                                                                >
                                                                    {!timesheetDisable ?
                                                                        <>
                                                                            {
                                                                                is_add && getModuleWriteAccess(Leave) ?
                                                                                    <>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-save footer-btn-inner mr-1"
                                                                                            onClick={e => this.onSave()}
                                                                                        > Save </button>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-update footer-btn-inner mr-1"
                                                                                            onClick={this.handleReset}
                                                                                        >
                                                                                            Reset
                                                                                        </button>

                                                                                    </>
                                                                                    :
                                                                                    (
                                                                                        <>
                                                                                            {getModuleUpdateAccess(Leave) && <button
                                                                                                type="button"
                                                                                                className="btn btn-save footer-btn-inner mr-1"
                                                                                                onClick={e => this.handleUpdate(e)}
                                                                                            >{statusName === 'Rejected' ? 'Resubmit' : 'Update'}  </button>}
                                                                                        </>

                                                                                    )
                                                                            }
                                                                        </> : <></>
                                                                    }

                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-cancel footer-btn-inner"
                                                                        onClick={this.handleCancel}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end page */}
                            </div>
                            {/* page-body-wrapper ends */}
                        </div>
                        {/* ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)} */}
                    </div>
                </div>
                <ToastContainer />
            </>
        );
    }
}

export default withRouter(connect(null, { addLeave_action, fill_action, minLeaveDate_action, updateLeave_action, leaveDetail_action })(LeaveAU));