import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import Drawer from '../../common_components/Drawer';
import {
    addtraining_action,
    deletetraining_action,
    approveRejectTraining_action
} from '../../actions/training_action/training_action'
import { fill_action } from '../../actions/master_action/checklist_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../utils/Loader'
import {
    courseTypeActionId,
    Traning_Entry
} from '../../utils/constants'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from 'react-icons/fa';
// import 'react-time-picker/dist/TimePicker.css';
// import 'react-clock/dist/Clock.css';
import { TimePicker } from '@hilla/react-components/TimePicker.js';
import { getModuleReadAccess, getModuleUpdateAccess, getModuleWriteAccess } from '../../utils/RoleAccess';

/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));

class TrainingAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            isOpen: false,
            is_add: false,
            is_delete: false,
            is_update: false,
            isLoading: false,
            courseTypeList: [],
            // courseTypeId: '',
            trainingFromDate: '',
            courseDetails: '',
            hours: '',
            tableRows: [{
                trainingdate: null,
                totalhours: '',
                courseTypeID: '',
                courseDetails: ''
            }],
            //
            // transactionForId: '',
            // transactionForList: [],
            // finYearId: '',
            // financialYearList: [],
            // clientNameList: [],
            // clientNameId: '',
            // engagementId: '',
            // engagementList: [],
            // transactionTypeId: '',
            // transactionTypeList: [],
            // employeeId:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            // employeeList: [],
            // taskId: '',
            // taskList: [],
            // description: '',
            // date: '',
            // fromTime: '',
            // toTime: '',
            // remark: '',
            // rejectionReason: '',
            // isVerified: '',
            // TrainingStatus: '',
            // TrainingExpenseID: '',
            // ExpenseAmmount: '',
            // ExpenseId: '',
            // isApproved: false,
            // startDate: '',
            // endDate: '',
            // lastDateTrainingFill: '',
            // is_DateFlag: false,
            // isClientDisable: false,
            // TrainingDisable: false,
            // isTrainingSuccsess: false,
            // TrainingId: '',
            // singleDisableLastDateTraining: false,
            // isDatePresent: false,
            // currentDate: new Date
        }
        // this.handleUpdate = this.handleUpdate.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.onSave = this.onSave.bind(this)
        // this.toCamelCase = this.toCamelCase.bind(this)
        // this.onSelectClient = this.onSelectClient.bind(this)
        // this.onSelectFinYear = this.onSelectFinYear.bind(this)
        // this.onSelectEngagementId = this.onSelectEngagementId.bind(this)
        this.getDetailsTraining = this.getDetailsTraining.bind(this)
        // this.onSelectTransactionForId = this.onSelectTransactionForId.bind(this)
        // this.onSelectDate = this.onSelectDate.bind(this)
        // this.toggleModal = this.toggleModal.bind(this)
        // this.handleHolidaySave = this.handleHolidaySave.bind(this)
        this.approvalTraining = this.approvalTraining.bind(this)
        this.addingTraining = this.addingTraining.bind(this)
        // this.getStartEndDateList = this.getStartEndDateList.bind(this)

    }

    componentDidMount() {


        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
            engagementAllocationId: this.props.location.state != null && this.props.location.state.engagementAllocationId != null ? this.props.location.state.engagementAllocationId : '',
            isAccessToView: getModuleReadAccess(Traning_Entry),

        })
        this.getFillList(courseTypeActionId, '')
        this.props.location.state != null && this.props.location.state.is_update && this.getDetailsTraining()
        // document.addEventListener('click', this.handleInputClick);
        // document.addEventListener('keydown', this.handleTabKeyPress);
    


    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
      }


      
  handleTabKeyPress = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
      const focusedElement = document.activeElement;
      const { previouslyFocusedElement } = this.state;

      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }

      if (focusedElement.classList.contains('form-control') ||
        focusedElement.classList.contains('js-example-basic-single') ||
        focusedElement.classList.contains('example-custom-input')) {
        focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
        this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
      }
    }
  };


  handleInputClick = (event) => {
    const { previouslyFocusedElement } = this.state;
    const clickedElement = event.target;

    if (previouslyFocusedElement) {
      previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    }

    if (clickedElement.classList.contains('form-control') ||
      clickedElement.classList.contains('js-example-basic-single') ||
      clickedElement.classList.contains('example-custom-input')) {
      clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
      this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
    } else {
      this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
    }
  };

    // get Quality Reviewer Name List dropdown list 
    getFillList(value, ref, ref2) {
        const { clientNameId, is_add } = this.state
        // this.setState({ isLoading: true })

        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == courseTypeActionId) {
                        console.log('courseTypeActionId')
                        this.setState({ courseTypeList: data.data })
                        setTimeout(() => {
                            this.setState({ isLoading: false })
                        }, 500);
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    // Function to add a new blank row to the table
    // addingTraining = () => {
    //     // Check if the first row contains blank values
    //     const firstRow = this.state.tableRows[0];
    //     if (!firstRow.trainingdate || !firstRow.totalhours || !firstRow.courseTypeID || !firstRow.courseDetails) {
    //         alert("Please fill in all fields in the first row.");
    //         return;
    //     }

    //     const newRow = {
    //         trainingdate: null,
    //         totalhours: '',
    //         courseTypeID: '',
    //         courseDetails: ''
    //     };
    //     this.setState(prevState => ({
    //         tableRows: [...prevState.tableRows, newRow]
    //     }));
    //     console.log(this.state.tableRows);
    // };

    addingTraining = () => {
        const { tableRows } = this.state;
    
        // Check if the first row contains blank values
        const firstRow = tableRows[0];
        if (!firstRow.trainingdate || !firstRow.totalhours || !firstRow.courseTypeID || !firstRow.courseDetails) {
            // alert("Please fill in all fields in the first row.");
            toast.error('Please fill in all fields in the first row.', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
            return;
        }
    
         // If you want to check if all rows are complete before proceeding, you can add a flag
const allRowsComplete = tableRows.every(row => 
    row.trainingdate && row.totalhours && row.courseTypeID && row.courseDetails
);

console.log("allRowsComplete", allRowsComplete)
if (!allRowsComplete) {
    // Display a message indicating not all rows are complete
    toast.error('Please fill in all fields in all rows.', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: true,
    });
    return;
}


        // Create a new row with null values
        const newRow = {
            trainingdate: null,
            totalhours: '',
            courseTypeID: '',
            courseDetails: ''
        };
    
        // Combine the new row with existing rows and filter out duplicates
        const newRows = [...tableRows, newRow];
        if (this.hasDuplicates(newRows)) {
            toast.error('Adding this row will create a duplicate entry. Please remove it before adding a new row.', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
            // alert("Adding this row will create a duplicate entry. Please remove it before adding a new row.");
            return;
        }
    
        // Update state with the new row
        this.setState({ tableRows: newRows });
    };

    
    // Function to remove a row from the table
    removeRow = (index) => {
        this.setState(prevState => ({
            tableRows: prevState.tableRows.filter((row, i) => i !== index)
        }));
    };

    approvalTraining() {
        const { transactionForId, employeeId, clientNameId, finYearId,
            engagementId, } = this.state
        this.setState({ isLoading: true })
        this.props
            .approvalTraining_action(transactionForId, employeeId, clientNameId, finYearId, engagementId)
            .then((data) => {
                if (data.error != 1) {
                    // console.log("getTrainingId_action ", data.data[0]['FinYearID'])

                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    //toggle model for ................................
    // toggleModal() {
    //     const { isOpen, } = this.state
    //     this.setState({ isOpen: !isOpen })
    // }

    // get Training details
    getDetailsTraining() {
        this.setState({ isLoading: true })
        var id = this.props.location.state != ' ' && this.props.location.state.TrainingListId != ' ' ? this.props.location.state.TrainingListId : ''

        this.props
            .TrainingDetails_action(id)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        clientNameId: data.data[0]['ClientID'],
                        transactionForId: data.data[0]['TransactionForID'],
                        finYearId: data.data[0]['FinYearID'],
                        engagementId: data.data[0]['EngagementID'],
                        TrainingStatus: data.data[0]['TrainingStatus'],
                        transactionTypeId: data.data[0]['TransactionTypeID'],
                        employeeId: data.data[0]['EmployeeID'],
                        taskId: data.data[0]['TaskTypeID'],
                        description: data.data[0]['Description'],
                        date: moment(data.data[0]['TrainingDate'], 'YYYY-MM-DD').toDate(),
                        fromTime: data.data[0]['StartTime'],
                        toTime: data.data[0]['Endtime'],
                        remark: data.data[0]['Remark'],
                        rejectionReason: data.data[0]['RejectReason'],
                        TrainingExpenseID: data.data[0]['TrainingID'],
                        ExpenseAmmount: data.data[0]['ExpenseAmount'],
                        ExpenseId: data.data[0]['ExpenseID'],
                        isLoading: false
                    })
                    // sessionStorage.setItem('clientNameIdTimeSS', data.data[0]['ClientID'])
                    // sessionStorage.setItem('transactionForIdTimeSS', data.data[0]['TransactionForID'])
                    // sessionStorage.setItem('finYearIdTimeSS', data.data[0]['FinYearID'])
                    // sessionStorage.setItem('engagementIdTimeSS', data.data[0]['EngagementID'])
                    // sessionStorage.setItem('dateTimeSS', moment(data.data[0]['TrainingDate'], 'YYYY-MM-DD').toDate())

                    if (data.data[0]['TrainingStatus'] == "Approved") {
                        this.setState({ TrainingDisable: true })
                    }

                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })

            .catch((e) => {
                console.log(e);
            });
    }

    // onSelectClient(e) {
    //     const { financialYearList } = this.state
    //     this.setState({ date: '', finYearId: '', financialYearList: [], engagementId: '', engagementList: [], is_DateFlag: true, lastDateTrainingFill: '' })
    //     console.log("e", e.target.value)
    //     e.target.value == '' ? this.setState({ date: '' }) : <></>
    //     if (e.target.value != '') {
    //         this.setState({ clientNameId: e.target.value }, () => { })
    //         setTimeout(() => {

    //         }, 500);
    //     }
    //     else {
    //         this.setState({ finYearId: '', financialYearList: [], engagementId: '', engagementList: [], clientNameId: '' })
    //     }
    //     // this.setState({ clientNameId: e.target.value }, () => { })
    //     // setTimeout(() => {
    //     //     this.getFillList(finYearOnbasicClientActionId, e.target.value)
    //     //     sessionStorage.setItem('clientNameIdTimeSS', e.target.value)
    //     // }, 500);
    // }

    // onSelectFinYear(e) {
    //     const { clientNameId, engagementList } = this.state
    //     console.log("onSelectFinYear", `${clientNameId}-${e.target.value}`)
    //     this.setState({ finYearId: e.target.value })

    //     console.log("***********", e.target.value)

    // }

    toCamelCase(str) {
        return str.replace(/[-_]+/g, ' ')
            .replace(/(?:^\w|[A-Z]|\b\w)/g, function (match, index) {
                return index === 0 ? match.toUpperCase() : match.toLowerCase();
            })
            .replace(/\s+/g, '');
    }

    // handleHolidaySave() {
    //     this.onSave('', 1)
    // }

    // navigateToExpense() {
    //     const { TrainingId, clientNameId, finYearId, engagementId, transactionForId, date } = this.state
    //     console.log("****************Training to expense", TrainingId)
    //     this.props.navigate('/expenseU', {
    //         state: {
    //             is_add: true,
    //             is_navigateTrainingToExpense: true,
    //             TrainingExpenseID: TrainingId,
    //             clientNameId: clientNameId,
    //             transactionForId: transactionForId,
    //             finYearId: finYearId,
    //             engagementId: engagementId,
    //             date: date,
    //         }
    //     })
    // }

    // Function to check for duplicate rows based on trainingdate, courseTypeID, and courseDetails
    hasDuplicates = (rows) => {
        const seen = new Set();
        for (const row of rows) {
            const key = `${row.trainingdate}_${row.courseTypeID}_${row.courseDetails}`;
            if (seen.has(key)) {
                return true; // Duplicate found
            }
            seen.add(key);
        }
        return false; // No duplicates found
    };


    onSave(e) {
        e.preventDefault();
        const { tableRows } = this.state
        // Filter out rows with null or blank values
        const filteredRows = tableRows.filter(row =>
            row.trainingdate !== null &&
            row.totalhours.trim() !== '' &&
            row.courseTypeID.trim() !== '' &&
            row.courseDetails.trim() !== ''
        );

        // // Check if any rows were removed
        // if (filteredRows.length !== tableRows.length) {
        //     this.setState({ tableRows: filteredRows });
        // }

        // Check for duplicates
        if (this.hasDuplicates(filteredRows)) {
            toast.error("Duplicate entries found. Please remove them before saving.", {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            });
            return;
        }

        if (filteredRows.length > 0) {
            this.setState({ isLoading: true });
            this.props.addtraining_action(filteredRows)
                .then((data) => {
                    this.setState({ isLoading: false, isOpen: false })
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toast.success("Training added successfully", {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        setTimeout(() => {
                            this.handleCancel()
                        }, 300);

                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            toast.error("Please atleast one training record.", {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }


    }

    onExpenseUpdate() {
        const { ExpenseId } = this.state
        console.log("ExpenseId!!", ExpenseId)
        if (ExpenseId != null) {
            this.props.navigate('/expenseU', { state: { is_update: true, is_Expenseupdate: true, UpdateTrainingExpenseID: ExpenseId } })
        }
    }

    onExpenseSave() {
        const { ExpenseId, TrainingExpenseID } = this.state
        this.props.navigate('/expenseU', { state: { is_add: true, TrainingExpenseID: TrainingExpenseID } })

    }

    // update template
    // handleUpdate(e) {
    //     e.preventDefault()
    //     const { transactionForId, employeeId, clientNameId, finYearId,
    //         engagementId, taskId, transactionTypeId, description, date, fromTime, toTime,
    //         remark, rejectionReason } = this.state
    //     var id = this.props.location.state != ' ' && this.props.location.state.TrainingListId != '' ? this.props.location.state.TrainingListId : ''

    //     if (date != "" && fromTime != "" && toTime != "" && transactionTypeId != 'Select' && transactionTypeId != "" && taskId != 'Select' && taskId != "" && description != "" && (transactionForId != '2' ? (clientNameId != '' && clientNameId != 'Select' && finYearId != '' && finYearId != 'Select' && engagementId != '' && engagementId != 'Select') : true) && transactionForId != '' && transactionForId != 'Select') {
    //         this.setState({ isLoading: true })
    //         this.props
    //             .updateTraining_action(id, transactionForId, employeeId, clientNameId, finYearId,
    //                 engagementId, taskId, transactionTypeId, description, date, fromTime, toTime,
    //                 remark, rejectionReason)
    //             .then((data) => {
    //                 if (data.error != 1) {
    //                     this.setState({ isLoading: false })
    //                     this.props.navigate('/Training')

    //                 }
    //                 else {
    //                     this.setState({ isLoading: false })
    //                     toast.error(data.msg, {
    //                         theme: 'colored',
    //                         autoClose: 3000,
    //                         hideProgressBar: true,
    //                     })
    //                 }
    //             })
    //             .catch((e) => {
    //                 console.log(e);
    //             });


    //     } else {
    //         this.setState({ isLoading: false })
    //         toast.error('Please enter mandatory field', {
    //             theme: 'colored',
    //             autoClose: 3000,
    //             hideProgressBar: true,
    //         })
    //     }
    // }


    approveRejectTraining() {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .approveRejectTraining_action([])
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        isLoading: false,
                    })
                    toast.success('Save successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // clear field ,list
    handleCancel() {
        this.props.navigate('/trainingList')
    }

    handleReset() {
        this.setState({
            tableRows: [{
                trainingdate: "",
                totalhours: '',
                courseTypeID: '',
                courseDetails: ''
            }],
        })
        // this.getClientsList()

    }


    // onSelectTransactionForId(e) {
    //     const { transactionForId, transactionForList, currentDate } = this.state
    //     this.setState({ transactionForId: e.target.value })
    //     if (e.target.value === '2') {
    //         this.setState({
    //             isClientDisable: true,
    //             clientNameId: '',
    //             finYearId: '',
    //             engagementId: '',
    //             // endDate: '',
    //             // startDate: '',
    //             // lastDateTrainingFill: '',
    //             date: '',
    //             endDate: currentDate
    //         })
    //     } else {
    //         this.setState({ isClientDisable: false, date: '' })
    //     }
    // }

    // onSelectEngagementId(e) {
    //     const { } = this.state
    //     this.setState({ engagementId: e.target.value, is_DateFlag: false })
    //     this.state.engagementId != '' ? this.setState({ date: '' }) : <></>
    //     setTimeout(() => {
    //         this.state.engagementId != '' ? this.getStartEndDateList() : <></>
    //         this.state.engagementId != '' ? this.lastTrainingFill() : <></>
    //     }, 100);
    // }

    // onSelectDate(date) {
    //     const { } = this.state
    //     this.setState({ date: date })

    // }

    //toggle model for delete.................................
    // toggleModal() {
    //     const { isOpen } = this.state
    //     this.setState({ isOpen: !isOpen })
    // }

    addingTraining() {
        const { date, fromTime, toTime, description,
             clientNameId, finYearId, engagementId, 
             transactionForId, transactionTypeId, taskId, tableRows } = this.state

 // Check if the first row contains blank values
 const firstRow = tableRows[0];
 if (!firstRow.trainingdate || !firstRow.totalhours || !firstRow.courseTypeID || !firstRow.courseDetails) {
     // alert("Please fill in all fields in the first row.");
     toast.error('Please fill in all fields in the first row.', {
         theme: 'colored',
         autoClose: 3000,
         hideProgressBar: true,
     })
     return;
 }

 // If you want to check if all rows are complete before proceeding, you can add a flag
const allRowsComplete = tableRows.every(row => 
    row.trainingdate && row.totalhours && row.courseTypeID && row.courseDetails
);

console.log("allRowsComplete", allRowsComplete)
if (!allRowsComplete) {
    // Display a message indicating not all rows are complete
    toast.error('Please fill in all fields in all rows.', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: true,
    });
    return;
}

 // Create a new row with null values
 const newRow = {
     trainingdate: null,
     totalhours: '',
     courseTypeID: '',
     courseDetails: ''
 };

 // Combine the new row with existing rows and filter out duplicates
 const newRows = [...tableRows, newRow];
 if (this.hasDuplicates(newRows)) {
     toast.error('Adding this row will create a duplicate entry. Please remove it before adding a new row.', {
         theme: 'colored',
         autoClose: 3000,
         hideProgressBar: true,
     })
     // alert("Adding this row will create a duplicate entry. Please remove it before adding a new row.");
     return;
 }

 // Update state with the new row
 this.setState({ tableRows: newRows });






             /////////***************** */
        function getSystemTime() {
            var date = new Date();
            var hours = date.getHours();
            var minutes = date.getMinutes();

            hours = (hours < 10 ? '0' : '') + hours;
            minutes = (minutes < 10 ? '0' : '') + minutes;

            return hours + ':' + minutes;
        }

        const systemTime = getSystemTime();
        // console.log('System time:', systemTime);

        const findCurrentDateTime = new Date

        const currentDate = moment(findCurrentDateTime).format('DD-MM-YYYY')
        const checkedDate = moment(date).format('DD-MM-YYYY')

        if (date != "" && fromTime != "" && toTime != "" &&( transactionTypeId != 'Select' 
       && transactionTypeId != "" )&& (taskId != 'Select' && taskId != "") && description != "" && 
        (transactionForId != '2' ?
         (clientNameId != '' && clientNameId != 'Select' && finYearId != '' && finYearId != 'Select' &&
          engagementId != '' && engagementId != 'Select') : true) &&( transactionForId != '' && transactionForId != 'Select')) {
            const { isApproved } = this.state

            if (currentDate == checkedDate) {
                if (fromTime >= systemTime) {
                    this.setState({ isApproved: !isApproved })
                } else {
                    toast.error("You can not fill current date Training before 9PM", {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            } else {
                this.setState({ isApproved: !isApproved })
            }
        } else {
            this.setState({ isLoading: false })
            toast.error('Please enter mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    expenseCancle() {
        this.setState({ isTrainingSuccsess: false })
        this.props.navigate('/Training')
    }

    render() {
        const { isAccessToView, is_add, details_clientId, is_update, isOpen, is_allSelected, is_allDeselected, searchClientsName, qualityReviewerName, qualityReviewerNameList, isLoading,
            TrainingStatus, courseTypeId, trainingFromDate, courseDetails, courseTypeList, hours,
            tableRows,
            transactionForId, transactionForList, finYearId, engagementId, clientNameId, clientNameList,
            engagementList, transactionTypeId, transactionTypeList, employeeId, taskId,
            taskList, description, fromTime, toTime, rejectionReason, employeeList, financialYearList, isApproved, startDate, endDate, is_DateFlag, isClientDisable, TrainingDisable, isTrainingSuccsess, singleDisableLastDateTraining, isDatePresent, currentDate } = this.state
        const minTime = new Date();
        minTime.setHours(9);
        minTime.setMinutes(0);
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    {/* partial:partials/_navbar.html */}
                    {/* partial */}
                    <div className="container-fluid page-body-wrapper">
                        {/* partial:partials/_settings-panel.html */}
                        <Drawer />
                        {/* start page */}
                        {/* {isAccessToView ? ( */}
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul className="breadcrumb">

                                        <li>
                                            <a onClick={this.handleCancel}>Training Entry </a>
                                        </li>
                                        <li>
                                            <a>{this.state.is_add ? 'New' : 'Update'}</a>
                                        </li>
                                        <li />
                                    </ul>
                                </div>
                                <div className="user-status">
                                                            {
                                                                is_update && TrainingStatus != "" ? <p>
                                                                    <strong className={TrainingStatus
                                                                        === 'Entered' || 'Sent for Approval' || 'Approved' || 'Rejected' || 'Deleted' ? 'greena' : 'redi'}> {TrainingStatus
                                                                        }</strong>
                                                                </p> : <></>
                                                            }
                                                    </div>
                                <div
                                    className="tab-content"
                                    id="myTabContent">
                                    <div className="row">
                                        <style
                                            type="text/css"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                            }}
                                        />
                                        <div className="col-md-12 pg-body grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    
                                                    <div className="d-flex flex-wrap row">
                                                        <p>Employee Name : <span><b>{localStorage.getItem("UserName")}</b></span></p>
                                                        <div className="table-responsive tbs4 common-table trail-tb ">
                                                            <table className="table table-bordered tb-action du-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className='text-center' style={{ width: '60px' }}>Sr.No</th>
                                                                        <th style={{ textAlign: 'left', width: '150px' }} >Training Date</th>
                                                                        <th style={{ width: '10px' }} >Hours</th>
                                                                        <th style={{ width: '150px' }} >Structured/Un-structured</th>
                                                                        <th style={{ width: '360px' }}>Course Details</th>
                                                                        <th className='text-center' style={{ width: '50px' }}>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                {/* <tbody>
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>   <div className="form-group d-flex">
                                                                        <DatePicker className="form-control"
                                                                            dateFormat="dd-MM-yyyy"
                                                                            selected={this.state.trainingFromDate}
                                                                            // minDate={this.state.finTodateLstRcd != '' ? this.state.finTodateLstRcd : ''}
                                                                            // disabled={is_add ? (this.state.finTodateLstRcd != '' ? true : false) : is_update}
                                                                            onChange={(date) => {
                                                                                this.setState({ trainingFromDate: date });

                                                                            }}
                                                                            peekNextMonth
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            placeholderText="From Date"
                                                                            customInput={<ExampleCustomInput />}
                                                                            dayClassName={() => "example-datepicker-day-class"}
                                                                            popperClassName="example-datepicker-class"
                                                                        />
                                                                    </div></td>
                                                                    <td> <input type='time'
                                                                    className={ "js-example-basic-single form-control"}
                                                                    onChange={(e, time) => this.setState({ hours: e.target.value })}
                                                                    value={hours} /></td>
                                                                    <td>  <select className="js-example-basic-single"
                                                                        style={{ width: '100%' }} value={courseTypeId} 
                                                                        onChange={e => this.setState({ courseTypeId: e.target.value })}>
                                                                         <option value={''}>Select</option>
                                                                        {courseTypeList.length > 0 && courseTypeList.map(item => (
                                                                            <option
                                                                                key={item.Code}
                                                                                value={item.Code}>
                                                                                {item.Description}
                                                                            </option>
                                                                        ))}

                                                                    </select></td>
                                                                    <td> <input type="text"
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        value={courseDetails}
                                                                        onChange={(e) => this.setState({ courseDetails: e.target.value })}
                                                                        onKeyDown={e => this.handleKeyDown(e)}
                                                                        required /></td>
                                                                    <td></td>
                                                                </tr>

                                                               
                                                            </tbody> */}
                                                                <tbody>
                                                                    {/* Render existing rows */}
                                                                    {tableRows.map((rowData, index) => (
                                                                        <tr key={index}>
                                                                            <td className='text-center p-0'>{index + 1}</td>
                                                                            <td className='p-0'>
                                                                                {/* <div className="form-group d-flex "> */}
                                                                                    {/* Render your DatePicker and other input fields here */}
                                                                                    <input
                                                                                        type="date"
                                                                                        className="p-1 w-100"
                                                                                        max={new Date().toISOString().split('T')[0]} // Set the maximum date to today

                                                                                        value={rowData.trainingdate}
                                                                                        onChange={(e) => {
                                                                                            const newRows = [...tableRows];
                                                                                            newRows[index].trainingdate = e.target.value;
                                                                                            this.setState({ tableRows: newRows });
                                                                                        }}
                                                                                    />
                                                                                {/* </div> */}
                                                                            </td>
                                                                            <td className='p-0'>
                                                                                <TimePicker
                                                                                    style={{ backgroundColor: 'white', width: "100%" }}

                                                                                    className='time-picker time-picker-input bg-white'
                                                                                    value={rowData.totalhours}
                                                                                    onChange={(e) => {
                                                                                        const newRows = [...tableRows];
                                                                                        newRows[index].totalhours = e.target.value;
                                                                                        this.setState({ tableRows: newRows });
                                                                                    }}
                                                                                // step={60 * 5} 
                                                                                />
                                                                                {/* <input
                                                                                    type='time'
                                                                                    aria-label="Time"
                                                                                    className="p-1 time-picker-input"
                                                                                    min='01:00' max= '24:00'
                                                                                    value={rowData.totalhours}
                                                                                    onChange={(e) => {
                                                                                        const newRows = [...tableRows];
                                                                                        newRows[index].totalhours = e.target.value;
                                                                                        this.setState({ tableRows: newRows });
                                                                                    }}
                                                                                /> */}
                                                                            </td>
                                                                            <td className='p-0'>
                                                                                <select
                                                                                    className="form-select"
                                                                                    style={{ width: '100%', borderRadius:'0px !important' }}
                                                                                    value={rowData.courseTypeID}
                                                                                    onChange={(e) => {
                                                                                        const newRows = [...tableRows];
                                                                                        newRows[index].courseTypeID = e.target.value;
                                                                                        this.setState({ tableRows: newRows });
                                                                                    }}
                                                                                >
                                                                                    <option value ={''}>Select</option>
                                                                                    {courseTypeList.length > 0 && courseTypeList.map(item => (
                                                                                        <option
                                                                                            key={item.Code}
                                                                                            value={item.Code}>
                                                                                            {item.Description}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </td>
                                                                            <td className='p-0'>
                                                                                <input style={{ height: '35px', width:'100%' }}
                                                                                    type="text"
                                                                                    className="p-2"
                                                                                    maxLength={500}
                                                                                    value={rowData.courseDetails}
                                                                                    onChange={(e) => {
                                                                                        const newRows = [...tableRows];
                                                                                        newRows[index].courseDetails = e.target.value;
                                                                                        this.setState({ tableRows: newRows });
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td className='text-center p-0'>
                                                                                {tableRows.length > 1 ? <a> <span className="text-danger">
                                                                                    <i className="fa fa-trash-o" onClick={() => this.removeRow(index)} /></span></a> : '-'}
                                                                            </td>
                                                                            {/* Render other columns as needed */}
                                                                        </tr>
                                                                    ))}
                                                                    {/* Add button to add new row */}
                                                                    {/* <tr>
                                                                    <td colSpan="6">
                                                                        <div className='col-2'>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-save footer-btn-inner mr-1"
                                                                                onClick={this.addingTraining}
                                                                            >
                                                                                Add
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr> */}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-save footer-btn-inner mr-1 mt-2"
                                                        onClick={this.addingTraining}
                                                    >
                                                        Add
                                                    </button>

                                                    <div style={{ height: 50 }} />
                                                    <div className="row">
                                                        <div className="footer-btn">
                                                            <div className="col-md-3">
                                                                <div
                                                                    className="btn-group"
                                                                    role="group"
                                                                    aria-label="Basic example"
                                                                >

                                                                    {
                                                                        is_add && getModuleWriteAccess(Traning_Entry)?
                                                                            <>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-save footer-btn-inner mr-1"
                                                                                    onClick={e => this.onSave(e)}
                                                                                > Save </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-update footer-btn-inner mr-1"
                                                                                    onClick={this.handleReset}
                                                                                >
                                                                                    Reset
                                                                                </button>

                                                                            </>
                                                                            :
                                                                            <>
                                                                                {!TrainingDisable && getModuleUpdateAccess(Traning_Entry)?
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-update footer-btn-inner mr-1"
                                                                                        onClick={e => this.handleUpdate(e)}
                                                                                    > Update </button> : <></>
                                                                                }
                                                                            </>
                                                                    }
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-cancel footer-btn-inner"
                                                                        onClick={this.handleCancel}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end page */}
                            </div>
                            {/* page-body-wrapper ends */}
                        </div>
                        {/* ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)} */}
                    </div>
                </div >
                <ToastContainer />


            </>
        );
    }
}

export default withRouter(connect(null, {
    addtraining_action,
    deletetraining_action,
    approveRejectTraining_action,
    fill_action,
})(TrainingAU));