import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {platfarm, headers,
    REVIEW_CHECKLIST_ACTION_TYPE,
    REVIEW_CHECKLIST_DETAILS_ACTION_TYPE,
    REVIEW_CHECKLIST_UPDATE_ACTION_TYPE,
    REVIEW_CHECKLIST_CLOSE_ACTION_TYPE,
    reviewChecklistUrl ,reviewChecklistDetailsUrl ,closeReviewChecklistUrl,
    updateReviewChecklistUrl, fillListUrl, FILL_LIST_ACTION_TYPE,
    uploadFileUrl,deleteFileUrl,REVIEW_CHECKLIST_FILE_UPLOAD_ACTION_TYPE,
    REVIEW_CHECKLIST_FILE_DELETE_ACTION_TYPE, REVIEW_CHECKLIST_FILE_GET_ACTION_TYPE, getFileUrl

} from "../../utils/constants"


export const reviewChecklist_action = (financialYear,clientName,status) => async (dispatch) => {
    try {   
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientName : clientName,
            FinancialYear : financialYear,
            status : status
        }
        const res = await axiosGet(reviewChecklistUrl, param);
        dispatch({
            type: REVIEW_CHECKLIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const reviewChecklistDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Id: id
        }        

        const res = await axiosGet(reviewChecklistDetailsUrl, param);
        dispatch({
            type: REVIEW_CHECKLIST_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const updateReviewChecklist_action = (id ,checklist) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            id : id,
            CheckList : checklist,
           
        }
        const res = await axiosPost(updateReviewChecklistUrl, mapdata, headers);

        dispatch({
            type: REVIEW_CHECKLIST_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const closeReviewChecklist_action = (id,remark) => async (dispatch) => {
    try {
        const param = {
            EngagementID : id,
            Remark : remark,
            userId: localStorage.getItem('UserId'),
            Platform: platfarm,

        }
        const res = await axiosGet(closeReviewChecklistUrl, param);
        dispatch({
            type: REVIEW_CHECKLIST_CLOSE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const uploadFile_action = (id,file) => async (dispatch) => {
    try {   
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            EngChecklistID: id,
            File : file
        }
        const res = await axiosPost(uploadFileUrl, param);
        dispatch({
            type: REVIEW_CHECKLIST_FILE_UPLOAD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const deleteFile_action = (id) => async (dispatch) => {
    try {   
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            EngChecklistID: id
        }
        const res = await axiosGet(deleteFileUrl, param);
        dispatch({
            type: REVIEW_CHECKLIST_FILE_DELETE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const GetFile_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Id: id
        }        
        const res = await axiosGet(getFileUrl, param);
        dispatch({
            type: REVIEW_CHECKLIST_FILE_GET_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};



export const simpleAction = () => dispatch => {
    dispatch({
     type: 'SIMPLE_ACTION',
     payload: 'result_of_simple_action'
    })
   }