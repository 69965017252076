
import { WORKING_DAYS_UPDATE_ACTION_TYPE , WORKING_DAYS_LIST_ACTION_TYPE, WORKING_DAYS_DETAILS_ACTION_TYPE} from "../../utils/constants";

const initialState = []

function workingDaysReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case WORKING_DAYS_LIST_ACTION_TYPE:
            return [...state, payload];
        case WORKING_DAYS_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case WORKING_DAYS_DETAILS_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}

export default workingDaysReducer