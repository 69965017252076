import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Loader from '../../../utils/Loader'
import { fill_action } from '../../../actions/master_action/checklist_action';
import { Master, workingDayTypeActionId, MonthActionId } from '../../../utils/constants'
import { workingDaysDetail_action, updateWorkingDays_action, addWorkingDays_action } from '../../../actions/master_action/working_days_action';
import { getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

class WorkingDaysUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAccessToView: true,
            WorkingDayID: '',
            workingDayTypeList: [],
            is_add: false,
            is_delete: false,
            is_update: false,
            monthID: '',
            monthList: [],

            dayOfTheWeek: '',
            week1Status: "",
            week2Status: "",
            week3Status: "",
            week4Status: "",
            week5Status: "",
        }
        this.getFillList = this.getFillList.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.getWorkingDaysDetails = this.getWorkingDaysDetails.bind(this)
    }

    componentDidMount = () => {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
            WorkingDayID: this.props.location.state != null && this.props.location.state.WorkingDayID != null ? this.props.location.state.WorkingDayID : '',
            dayOfTheWeek: this.props.location.state != null && this.props.location.state.dayOfTheWeek != null ? this.props.location.state.dayOfTheWeek : '',
            isAccessToView: true,
        })
        this.getFillList(workingDayTypeActionId, '')
        this.getFillList(MonthActionId, '')
        
        setTimeout(() => {
        
            this.props.location.state != null && this.props.location.state.is_update && this.getWorkingDaysDetails()
        }, 500);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
          const focusedElement = document.activeElement;
          const { previouslyFocusedElement } = this.state;
    
          if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
          }
    
          if (focusedElement.classList.contains('form-control') ||
            focusedElement.classList.contains('js-example-basic-single') ||
            focusedElement.classList.contains('example-custom-input')) {
            focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
            this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
          }
        }
      };
    
    
      handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;
    
        if (previouslyFocusedElement) {
          previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }
    
        if (clickedElement.classList.contains('form-control') ||
          clickedElement.classList.contains('js-example-basic-single') ||
          clickedElement.classList.contains('example-custom-input')) {
          clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
          this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
          this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
      };

    //get details
    getWorkingDaysDetails() {
        setTimeout(() => {
            var id = this.state.WorkingDayID
            this.props
                .workingDaysDetail_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            week1Status: data.data[0]['Week1Status'],
                            week2Status: data.data[0]['Week2Status'],
                            week3Status: data.data[0]['Week3Status'],
                            week4Status: data.data[0]['Week4Status'],
                            week5Status: data.data[0]['Week5Status'],
                            monthID : data.data[0]['Day'],
                        })
                        console.log('MonthID', data.data[0]['Day'] );

                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 500);
    }

    //Fill list
    getFillList(value, ref, ref2) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == workingDayTypeActionId) {
                        this.setState({ workingDayTypeList: data.data })
                        setTimeout(() => {
                            console.log(this.state.workingDayTypeList)
                            this.setState({ isLoading: false })
                        }, 500);
                    }
                    if (value == MonthActionId) {
                        this.setState({ monthList: data.data,  isLoading: false })
                    }
                }
            })
    }

    //cancel
    handleCancel() {
        this.props.navigate('/workingDaysList')
    }

    //add working day
    handleAdd(e) {
        e.preventDefault()
        const {dayOfTheWeek,monthID, WorkingDayID, week1Status, week2Status, week3Status, week4Status, week5Status } = this.state
        const id = WorkingDayID

        if (monthID != "" && week1Status != "" && week2Status != "" && week3Status != "" && week4Status != "" && week5Status != "") {
            this.setState({ isLoading: true })
            this.props
                .addWorkingDays_action(monthID, week1Status, week2Status, week3Status, week4Status, week5Status)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.handleCancel()
                        }, 500);
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //update working day
    handleUpdate(e) {
        e.preventDefault()
        const {monthID, WorkingDayID, week1Status, week2Status, week3Status, week4Status, week5Status } = this.state
        const id = WorkingDayID

        if (id != "" && week1Status != "" && week2Status != "" && week3Status != "" && week4Status != "" && week5Status != "") {
            this.setState({ isLoading: true })
            this.props
                .updateWorkingDays_action(id, week1Status, week2Status, week3Status, week4Status, week5Status)
                .then((data) => {
                    if (data.error == 0) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false })
                        setTimeout(() => {
                            this.handleCancel()
                        }, 500);

                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    render() {
        const {is_update, isAccessToView,monthID,monthList, isLoading, week1Status, week2Status, dayOfTheWeek, week3Status, workingDayTypeList, week4Status, week5Status } = this.state
        return (<>
            {isLoading ? (
                <Loader></Loader>
            ) : (
                null
            )}
            <Header />
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                    {/* Left side menu */}
                    <Drawer />
                    {/* end left menu */}
                    {/* start page */}
                    {isAccessToView ? (
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul className="breadcrumb">
                                        <li>
                                            <a onClick={this.handleCancel} >Working Days</a>
                                        </li>
                                        <li>
                                            {is_update ? <a >Update</a> : <a >Add</a>}
                                        </li>
                                        <li />
                                    </ul>
                                </div>
                                <div className="row">
                                    <style
                                        type="text/css"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                        }}
                                    />
                                    <div className="col-md-12 pg-body grid-margin stretch-card">
                                        <div className="card">
                                            <div className="card-body">
                                                {/* <div className="row user-status">
                                                    <div className="status-top mr-2">
                                                        {
                                                            is_update ? <p>
                                                                <strong className={isActive === true ? 'greena' : 'redi'}> {isActive === true ? 'Active' : 'Inactive'}</strong>
                                                            </p> : <></>
                                                        }

                                                    </div>
                                                </div> */}
                                                <div class="d-flex flex-wrap row mb-3">
                                                    <div className="form-group col-md-2">
                                                        <label>Month<span class="required1">*</span></label>
                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                            value={monthID}
                                                            disabled = {is_update}
                                                            onChange={(e) => this.setState({ monthID: e.target.value, })}>
                                                            <option value="">Select</option>
                                                            {monthList.length > 0 && monthList.map(data => (
                                                                <option value={data.Name
                                                                } key={data.MonthID
                                                                }>{data.Name}</option>
                                                            ))
                                                            }
                                                        </select>
                                                        {/* <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder='Day of the week'
                                                            value={dayOfTheWeek}
                                                            maxLength={50}
                                                            onChange={(e) => { this.setState({ dayOfTheWeek: e.target.value }) }}
                                                            disabled={is_update}
                                                            required
                                                        /> */}
                                                    </div>
                                                    <div className="form-group col-md-2">
                                                        <label>Week 1<span class="required1">*</span></label>
                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                            value={week1Status}
                                                            onChange={(e) => this.setState({ week1Status: e.target.value, })}>
                                                            <option value="">Select</option>
                                                            {workingDayTypeList.length > 0 && workingDayTypeList.map(data => (
                                                                <option value={data.TypeID
                                                                } key={data.TypeID
                                                                }>{data.Typedesc}</option>
                                                            ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-2">
                                                        <label>Week 2<span class="required1">*</span></label>
                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                            value={week2Status}
                                                            onChange={(e) => this.setState({ week2Status: e.target.value, })}>
                                                            <option value="">Select</option>
                                                            {workingDayTypeList.length > 0 && workingDayTypeList.map(data => (
                                                                <option value={data.TypeID
                                                                } key={data.TypeID
                                                                }>{data.Typedesc}</option>
                                                            ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-2">
                                                        <label>Week 3<span class="required1">*</span></label>
                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                            value={week3Status}
                                                            onChange={(e) => this.setState({ week3Status: e.target.value, })}>
                                                            <option value="">Select</option>
                                                            {workingDayTypeList.length > 0 && workingDayTypeList.map(data => (
                                                                <option value={data.TypeID
                                                                } key={data.TypeID
                                                                }>{data.Typedesc}</option>
                                                            ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-2">
                                                        <label>Week 4<span class="required1">*</span></label>
                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                            value={week4Status}
                                                            onChange={(e) => this.setState({ week4Status: e.target.value, })}>
                                                            <option value="">Select</option>
                                                            {workingDayTypeList.length > 0 && workingDayTypeList.map(data => (
                                                                <option value={data.TypeID
                                                                } key={data.TypeID
                                                                }>{data.Typedesc}</option>
                                                            ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-2">
                                                        <label>Week 5<span class="required1">*</span></label>
                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                            value={week5Status}
                                                            onChange={(e) => this.setState({ week5Status: e.target.value, })}>
                                                            <option value="">Select</option>
                                                            {workingDayTypeList.length > 0 && workingDayTypeList.map(data => (
                                                                <option value={data.TypeID
                                                                } key={data.TypeID
                                                                }>{data.Typedesc}</option>
                                                            ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="footer-btn">
                                                        <div className="col-md-3">
                                                            <div
                                                                className="btn-group"
                                                                role="group"
                                                                aria-label="Basic example"
                                                            >
                                                                {is_update && getModuleUpdateAccess(Master) ? <button
                                                                    type="button"
                                                                    className="btn btn-update footer-btn-inner mr-1"
                                                                    onClick={this.handleUpdate}
                                                                >
                                                                    Update
                                                                </button> : getModuleWriteAccess(Master) && <button
                                                                    type="button"
                                                                    className="btn btn-save footer-btn-inner mr-1"
                                                                    onClick={this.handleAdd}
                                                                >
                                                                    Add
                                                                </button>}
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-cancel footer-btn-inner"
                                                                    onClick={this.handleCancel}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                    </div>)}
                    {/* end page */}
                </div>
                {/* page-body-wrapper ends */}
            </div>
            <ToastContainer />
        </>)
    }
}

export default withRouter(connect(null, { fill_action, workingDaysDetail_action, updateWorkingDays_action, addWorkingDays_action })(WorkingDaysUpdate))