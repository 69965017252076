import React, { Component, forwardRef } from "react";
import Loader from "../../../utils/Loader";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../../../utils/pagination'
import { clientCategoryList_action, deleteClientCategory_action, addClientCategory_action, updateClientCategory_action, clientCategoryDetail_action } from '../../../actions/master_action/client_category_action'
import { clearLocalStorage, clientTypeActionId, Master } from '../../../utils/constants'
import Modal from "react-modal";
import { fill_action } from '../../../actions/master_action/checklist_action';
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from "../../../utils/RoleAccess";

class ClientCategoryList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAccessToView: true,
            isOpen: false,
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage: 10,
            pageSizeInput: "10",

            ClientCategoryID: '',
            clientCategoryTypeList: [],
            clientCategoryList: [],
            clientCategoryName: '',
            clientCategoryTypeId: "",
            managementDesc: '',
            status: ' ',
            clientCategoryNameCGLS: '',
            managementDescCGLS: '',
            statusCGLS: '',
            clientCategoryDeleteId: '',
            managementDescSearch: "",
            clientCategoryNameSearch: "",
            isOpenAU: true,
            is_add: false,
            is_update: false,
            isSearchTab: false,
        }
        this.record = this.record.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.getClientCategoryList = this.getClientCategoryList.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this)
        // this.handleAddClientCategory = this.handleAddClientCategory.bind(this)
        this.clearValue = this.clearValue.bind(this)
        // this.handleUpdateClientCategory = this.handleUpdateClientCategory.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.toggleAdd = this.toggleAdd.bind(this)
        this.toggleUpdate = this.toggleUpdate.bind(this)
        this.toggleModalAU = this.toggleModalAU.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.getClientCategoryDetails = this.getClientCategoryDetails.bind(this)
    }

    //client category list default
    componentDidMount = () => {
        this.setState({
            isAccessToView: true,
            clientCategoryNameCGLS: localStorage.getItem('clientCategoryNameCGLS'),
            managementDescCGLS: localStorage.getItem('managementDescCGLS'),
            statusCGLS: localStorage.getItem('statusCGLS'),

            clientCategoryNameSearch: localStorage.getItem('clientCategoryNameCGLS') != '' ? localStorage.getItem('clientCategoryNameCGLS') : '',
            managementDescSearch: localStorage.getItem('managementDescCGLS') != '' ? localStorage.getItem('managementDescCGLS') : '',
            status: localStorage.getItem('statusCGLS') != '' ? localStorage.getItem('statusCGLS') : '',

        })
        clearLocalStorage("clientCategory")
        setTimeout(() => {
            this.getFillList(clientTypeActionId, '')
            this.getClientCategoryList()
        }, 100);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    //Fill list
    getFillList(value, ref, ref2) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == clientTypeActionId) {
                        this.setState({ clientCategoryTypeList: data.data })
                        setTimeout(() => {
                            console.log(this.state.clientCategoryTypeList)
                            this.setState({ isLoading: false })
                        }, 200);

                    }
                }
            })
    }

    // //add client category
    // handleAddClientCategory() {
    //     this.props.navigate('/clientCategoryAU', { state: { is_add: true } })
    // }

    // //update client category
    // handleUpdateClientCategory(ClientCategoryID) {
    //     console.log(ClientCategoryID)
    //     this.props.navigate('/clientCategoryAU', { state: { is_update: true, ClientCategoryID: ClientCategoryID } })
    // }

    //reset values
    handleReset() {
        this.setState({
            ClientCategoryID: '',
            clientCategoryName: '',
            managementDesc: '',
            clientCategoryNameSearch: '',
            managementDescSearch: '',
            clientCategoryTypeId: "Select",
        })
    }

    toggleAdd() {
        const { is_add } = this.state
        this.setState({ is_add: true })
        this.handleReset()
        this.toggleModalAU()
    }

    toggleUpdate(clientCategory) {
        const { is_update } = this.state
        this.handleReset()
        this.setState({ is_update: true, ClientCategoryID: clientCategory })
        this.toggleModalAU()
    }

    //toggle/reset
    toggleModalAU(holiday) {
        const { isOpenAU } = this.state
        this.getClientCategoryDetails()
        this.setState({ isOpenAU: !isOpenAU })
    }

    handleToggle(id, type) {
        const { isOpenAU, martialStatus } = this.state
        if (type == 'add') {
            this.setState({
                is_add: true, is_update: false, isOpenAU: !isOpenAU, clientCategoryName: '',
                managementDesc: '', clientCategoryTypeId: "Select",
            })
            this.handleReset()
        } else {
            this.handleReset()
            this.setState({ is_update: true, is_add: false, isOpenAU: !isOpenAU, ClientCategoryID: id })
            this.getClientCategoryDetails()
        }
    }

    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    //add client category
    handleAdd(e) {
        e.preventDefault()
        const { clientCategoryName, clientCategoryTypeId, managementDesc } = this.state
        if (clientCategoryName != "" && clientCategoryTypeId != "" && clientCategoryTypeId != "Select" && managementDesc != "") {
            this.setState({ isLoading: true })

            this.props
                .addClientCategory_action(clientCategoryName.trim(), clientCategoryTypeId, managementDesc)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false })
                        this.toggleModalAU()
                        this.clearValue()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg['ErrMsg'], {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }
    //update client category
    handleUpdate(e) {
        e.preventDefault()
        const { ClientCategoryID, clientCategoryName, isOpenAU, clientCategoryTypeId, managementDesc } = this.state
        const id = ClientCategoryID

        if (id != "" && clientCategoryName != "" && clientCategoryTypeId != "" && clientCategoryTypeId != "Select" && managementDesc != "") {
            this.setState({ isLoading: true })
            this.props
                .updateClientCategory_action(id, clientCategoryName.trim(), clientCategoryTypeId, managementDesc)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        this.setState({ isLoading: false, isOpenAU: !isOpenAU })
                        // this.toggleModalAU()
                        this.clearValue()
                    }
                    else {
                        this.setState({ isLoading: false, })

                        toast.error(data.msg['ErrMsg'], {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    //get list
    getClientCategoryList() {
        const { clientCategoryNameSearch, clientCategoryTypeId, managementDescSearch, status } = this.state
        this.setState({ isLoading: true })
        this.props
            .clientCategoryList_action(clientCategoryNameSearch, clientCategoryTypeId == '' ? null : clientCategoryTypeId, managementDescSearch, status === '' ? 1 : parseInt(status))
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        clientCategoryList: data.data,
                        isLoading: false,
                        currentPage: 1
                    })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    //get details
    getClientCategoryDetails() {
        setTimeout(() => {
            var id = this.state.ClientCategoryID
            this.props
                .clientCategoryDetail_action(id)
                .then((data) => {
                    console.log(data)
                    if (data.error != 1) {
                        this.setState({
                            clientCategoryName: data.data[0]['ClientCategory'],
                            managementDesc: data.data[0]['Description'],
                            clientCategoryTypeId: data.data[0]['ClientType'],
                            status: data.data[0]['IsActive']
                        })
                        console.log("1234", data.data[0]['ClientType'])
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 200);
    }

    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.handleSearch()
        }
    }

    //clear value
    clearValue() {
        this.setState({
            clientCategoryList: [],
            clientCategoryName: '',
            clientCategoryTypeId: 'Select',
            managementDesc: '',
            clientCategoryNameSearch: '',
            managementDescSearch: '',
            status: ' ',
            clientCategoryNameCGLS: '',
            managementDescCGLS: '',
            statusCGLS: 'Select',
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getClientCategoryList()
        }, 300);
    }

    //search
    handleSearch() {
        const { clientCategoryNameSearch, clientCategoryTypeId, managementDescSearch, status } = this.state
        clearLocalStorage("clientCategory")
        this.setState({ isLoading: true })
        this.props
            .clientCategoryList_action(clientCategoryNameSearch.trim(), clientCategoryTypeId == '' ? null : clientCategoryTypeId, managementDescSearch.trim(), status === '' ? 1 : parseInt(status))
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ clientCategoryList: data.data, isLoading: false, currentPage: 1 })
                    console.log(data)
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
        localStorage.setItem("clientCategoryNameCGLS", clientCategoryNameSearch)
        localStorage.setItem("managementDescCGLS", managementDescSearch)
        localStorage.setItem("statusCGLS", status)
    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }
    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
            this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
    };

    handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
            this.setState({ pageSizeInput: input });
        }
    };
    //delete handle
    handleDelete(e, ClientCategoryID) {
        e.preventDefault()
        var id = ClientCategoryID
        this.setState({ isLoading: true })
        this.props
            .deleteClientCategory_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isOpen: !this.state.isOpen, isLoading: false })
                    this.getClientCategoryList()
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    //toggal for delete
    toggleModal(ClientCategoryID) {
        const { isOpen, clientCategoryDeleteId } = this.state
        this.setState({ isOpen: !isOpen })
        if (ClientCategoryID == '') {
            return
        }
        else {
            this.setState({ clientCategoryDeleteId: ClientCategoryID })
        }
    }


    //record
    record(clientCategoryList) {

        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th className="text-left">Client Category</th>
                        <th style={{ width: '180px', }} >Client Category Type</th>
                        <th style={{ width: '100px' }}>Management Description</th>
                        <th style={{ width: '100px' }}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {clientCategoryList.length > 0 ? (
                        clientCategoryList.map((data, i) => (
                            <tr key={data.ClientCategoryID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        {getModuleUpdateAccess(Master) ?
                                            <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleToggle(data.ClientCategoryID, 'update')} /></a> :
                                            <a><i className="fa fa-eye" style={{ color: '#3498db' }} onClick={() => this.handleToggle(data.ClientCategoryID, 'update')} /></a>
                                        }
                                        {data.IsActive === true && getModuleDeleteAccess(Master) ? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.ClientCategoryID)} /></span></a>
                                            : <></>}

                                    </div>
                                </td>
                                <td className="text-left">{data.ClientCategory}</td>
                                <td >{data.ClientType}</td>
                                <td>{data.Description}</td>
                                <td>{data.IsActive == true ? "Active" : "Inactive"}</td>
                            </tr>
                        ))
                    ) : (<tr><td colSpan={15} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }

    render() {
        const { isSearchTab, isLoading, isAccessToView, managementDesc, status, isOpenAU, is_add, clientCategoryTypeList,
            managementDescSearch, clientCategoryNameSearch,
            is_update, clientCategoryTypeId, clientCategoryList, clientCategoryName, isOpen, clientCategoryDeleteId } = this.state
        const { currentPage, recordsPerPage, pageSizeInput } = this.state;
        // 
        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = clientCategoryList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(clientCategoryList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>Client Category </a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className='page-space-from-bottom'>
                                        <div className="row">
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body pr-2">


                                                        <div className="accordion accordion-flush " id="accordionFlushExample">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingOne">
                                                                    <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                                                        <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                    </button>
                                                                </h2>
                                                                {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                                <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                    <div className="d-flex flex-wrap row">

                                                                        <div className="form-group col-md-2">
                                                                            <label>Client Category</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="Client Category"
                                                                                value={clientCategoryNameSearch}
                                                                                onChange={(e) => this.setState({ clientCategoryNameSearch: e.target.value })}
                                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                                required />
                                                                        </div>

                                                                        <div className="form-group col-md-3">
                                                                            <label>Management Description</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="Management Description"
                                                                                value={managementDescSearch}
                                                                                onChange={(e) => this.setState({ managementDescSearch: e.target.value })}
                                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                                required />
                                                                        </div>

                                                                        <div className="form-group col-md-3">
                                                                            <label>Client Category Type</label>
                                                                            <select className="js-example-basic-single" style={{ width: '100%' }}
                                                                                value={clientCategoryTypeId}
                                                                                onChange={(e) => this.setState({ clientCategoryTypeId: e.target.value })}>
                                                                                <option value={''}>Select</option>
                                                                                <option value={1}>Individual's</option>
                                                                                <option value={2}>Non-Individual's</option>
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group col-md-2">
                                                                            <label>Status</label>
                                                                            <select className="js-example-basic-single" style={{ width: '100%' }}
                                                                                value={status}
                                                                                onChange={(e) => this.setState({ status: e.target.value })}>
                                                                                <option value={''}>Select</option>
                                                                                <option value={1}>Active</option>
                                                                                <option value={0}>Inactive</option>
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group col-md-2 filter-btn-col p-0">
                                                                            <label></label>
                                                                            <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={e => this.handleSearch()}> Search</button>
                                                                            <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3" onClick={e => this.clearValue()}> Clear</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div> : <></>}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 mt-1">
                                                                {getModuleWriteAccess(Master) && <button type="button" className="btn btn-Add rounded-1 mb-1 px-3 float-right" onClick={() => this.handleToggle('', 'add')}>Add</button>}
                                                                {/* button */}
                                                                <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                                    {this.record(currentRecords)}
                                                                    {clientCategoryList.length > 0 ? <Pagination
                                                                        nPages={nPages}
                                                                        currentPage={currentPage}
                                                                        setCurrentPage={this.updateItem}
                                                                        recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                        setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                        totalRecords={clientCategoryList.length}
                                                                    /> : <></>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div >
                                    </div >
                                </div >
                            </div >
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)
                        }
                        {/* end page */}
                    </div >
                    {/* page-body-wrapper ends */}
                </div >
                <ToastContainer />
                {/* // modal for delete */}
                {/* <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, clientCategoryDeleteId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}

                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right">
                                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, clientCategoryDeleteId)}> Yes</a>
                                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* modal for AU */}
                <Modal isOpen={!isOpenAU} onRequestClose={this.toggleModalAU}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                {is_add == true ? <h4 class="modal-title modal_title_text">Add Client Category</h4> : <h4 class="modal-title modal_title_text">Update Client Category</h4>}
                                {is_add == true ? <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleAdd} ><span aria-hidden="true" class="pop-cancel">&times;</span></button> : <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.toggleUpdate()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>}
                            </div>

                            <div class="modal-body p-2">
                                <div className="form-group col-12 mb-1">
                                    <label>Client Category<span class="required1">*</span></label>
                                    <input
                                        type="text"
                                        // disabled={!status}
                                        className="form-control"
                                        placeholder='Client Category'
                                        value={clientCategoryName}
                                        onChange={(e) => this.setState({ clientCategoryName: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group col-12 mb-1">
                                    <label>Client Category Type<span class="required1">*</span></label>
                                    <select className="js-example-basic-single" style={{ width: '100%' }}
                                        value={clientCategoryTypeId}
                                        // disabled={!status}
                                        onChange={(e) => this.setState({ clientCategoryTypeId: e.target.value, })}>
                                        <option value={''}>Select</option>
                                        {clientCategoryTypeList.length > 0 && clientCategoryTypeList.map(data => (
                                            <option value={data.ID
                                            } key={data.ID
                                            }>{data.Name}</option>
                                        ))
                                        }
                                    </select>
                                </div>
                                <div className="form-group col-12 mb-1">
                                    <label>Management Description<span class="required1">*</span></label>
                                    <input
                                        type="text"
                                        // disabled={!status}
                                        className="form-control"
                                        placeholder='Name'
                                        value={managementDesc}
                                        onChange={(e) => this.setState({ managementDesc: e.target.value })}
                                        required
                                    />
                                </div>
                            </div>

                            <div class="modal-footer p-0">
                                <div class="form-group text-right mod-fot">
                                    {is_add == true ? getModuleWriteAccess(Master) && <a class="btn save_btn del-pop-save" onClick={this.handleAdd}> Save</a> : status && getModuleUpdateAccess(Master) && <a class="btn save_btn del-pop-save" onClick={this.handleUpdate}> Update</a>}
                                    {is_add == true && getModuleWriteAccess(Master) ? <a class="btn btn-outline-danger px-3" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.handleReset}>Reset</a> : <></>}
                                    {is_add == true ? <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleAdd}>Cancel</a> : <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.toggleUpdate()}>Cancel</a>}
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(connect(null, { clientCategoryList_action, fill_action, deleteClientCategory_action, addClientCategory_action, updateClientCategory_action, clientCategoryDetail_action })(ClientCategoryList))