

import { CHECK_LIST_TEMPLATE_ACTION_TYPE,CHECK_LIST_TEMPLATE_DETAILS_ACTION_TYPE, CHECK_LIST_TEMPLATE_ADD_ACTION_TYPE, CHECK_LIST_TEMPLATE_UPDATE_ACTION_TYPE, CHECK_LIST_TEMPLATE_DELETE_ACTION_TYPE,CHECK_LIST_TEMPLATE_DOWNLOAD_ACTION_TYPE } from "../../utils/constants"


const initialState = [];

function checkListTemplateReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHECK_LIST_TEMPLATE_ACTION_TYPE:
      return [...state, payload];
      case CHECK_LIST_TEMPLATE_DETAILS_ACTION_TYPE:
      return [...state, payload];
    case CHECK_LIST_TEMPLATE_ADD_ACTION_TYPE:
      return [...state, payload];
    case CHECK_LIST_TEMPLATE_UPDATE_ACTION_TYPE:
      return [...state, payload];
    case CHECK_LIST_TEMPLATE_DOWNLOAD_ACTION_TYPE:
      return [...state, payload];
    case CHECK_LIST_TEMPLATE_DELETE_ACTION_TYPE:
      return [...state, payload];
    default:
      return state;
  }
};

export default checkListTemplateReducer;


