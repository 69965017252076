import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import {
    legalEntityList_action,
    addlegalEntityList_action,
    deletelegalEntityList_action,
    legalEntityDetail_action,
    updatelegalEntityList_action
} from '../../../actions/master_action/legal_Entity_action'

import Pagination from "../../../utils/pagination";

import { clearLocalStorage } from '../../../utils/constants'

class LegalEntity extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            isOpen: false,
            status: ' ',
            isLoading: false,
            IsActive: 0,
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage : 10,
            pageSizeInput: "10", 
            LegalEntityName: '',
            LegalEntityNameDLS: '',
            LegalEntityList: [],
            LegalEntityId: '',
            _isAdd: false,
            _isUpdate: false,
            _isDelete: false,
            search_LegalEntityName: '',
            isSearchTab: false,
            format: "",
            formatSearch: ""
        }
        this.getLegalEntityDetails = this.getLegalEntityDetails.bind(this)
        this.getLegalEntityList = this.getLegalEntityList.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);  
		
        this.toggleModal = this.toggleModal.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.toggleModalAdd = this.toggleModalAdd.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.toggleModalUpdate = this.toggleModalUpdate.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)

    }


    componentDidMount = () => {
        this.setState({
            isAccessToView: true,
            LegalEntityNameDLS: localStorage.getItem('LegalEntityNameDLS'),
            statusDLS: localStorage.getItem('statusDLS'),

            LegalEntityName: localStorage.getItem('LegalEntityNameDLS') != '' ? localStorage.getItem('LegalEntityNameDLS') : '',
            status: localStorage.getItem('statusDLS') != 'Select' ? localStorage.getItem('statusDLS') : 'Select',
        })
        this.clearValue()
        clearLocalStorage("LegalEntity")

         this.getLegalEntityList()
        //  document.addEventListener('click', this.handleInputClick);

        // document.addEventListener('keydown', this.handleTabKeyPress);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }
  


    
  handleTabKeyPress = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
      const focusedElement = document.activeElement;
      const { previouslyFocusedElement } = this.state;

      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }

      if (focusedElement.classList.contains('form-control') ||
        focusedElement.classList.contains('js-example-basic-single') ||
        focusedElement.classList.contains('example-custom-input')) {
        focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
        this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
      }
    }
  };


  handleInputClick = (event) => {
    const { previouslyFocusedElement } = this.state;
    const clickedElement = event.target;

    if (previouslyFocusedElement) {
      previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    }

    if (clickedElement.classList.contains('form-control') ||
      clickedElement.classList.contains('js-example-basic-single') ||
      clickedElement.classList.contains('example-custom-input')) {
      clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
      this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
    } else {
      this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
    }
  };
    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    getLegalEntityList() {
        const { search_LegalEntityName, formatSearch, status } = this.state
        clearLocalStorage("LegalEntity")
        this.setState({ isLoading: true })
        this.props
            .legalEntityList_action(search_LegalEntityName.trim(), formatSearch.trim(), status === 'Select' ? 1 : status)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ LegalEntityList: data.data, isLoading: false, currentPage: 1 })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

        localStorage.setItem("LegalEntityNameDLS", search_LegalEntityName)
        localStorage.setItem("statusDLS", status === 'Select' ? 1 : status)
    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }
    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
          this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
      };
      
      handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
          this.setState({ pageSizeInput: input });
        }
      };


    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.getLegalEntityList()
        }
    }
    clearValue() {
        this.setState({
            search_LegalEntityName: '',
            format: '',
            formatSearch: '',
            LegalEntityName: '',
            status: ' ',
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getLegalEntityList()
        }, 300);
    }

    // toggleModal for delete
    toggleModal(LegalEntityId) {
        const { isOpen, LegalEntityDeleteId } = this.state
        this.setState({ isOpen: !isOpen })
        this.state._isDelete = true
        this.state._isAdd = false
        this.state._isUpdate = false
        if (LegalEntityId == '') {
            return;
        } else {
            this.setState({ LegalEntityId: LegalEntityId })
        }

    }
    // delete LegalEntity
    handleDelete(e) {
        e.preventDefault()
        var id = this.state.LegalEntityId
        this.setState({ isLoading: true })
        this.props
            .deletelegalEntityList_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isLoading: false })
                    this.handleCancel()
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    handleCancel() {
        this.state.isOpen = false
        this.clearValue()
    }

    getLegalEntityDetails(LegalEntityId) {
        const { LegalEntityName } = this.state
        var id = LegalEntityId
        this.setState({ isLoading: false })
        this.props
            .legalEntityDetail_action(id)
            .then((data) => {
                if (data.error != 1) {

                    this.setState({
                        LegalEntityName: data.data[0]['LegalEntityType'],
                        format: data.data[0]['Format'],
                        isActive: data.data[0]['IsActive'],
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // LegalEntity add
    handleAdd(e) {
        e.preventDefault()
        const { LegalEntityName, format } = this.state
        if (LegalEntityName != "" && format != "" ) {
            this.setState({ isLoading: true })

            this.props
                .addlegalEntityList_action(LegalEntityName.trim(), format)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.clearValue()
                        this.setState({ isLoading: false })
                        this.handleCancel()
                        this.getLegalEntityList()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }



    }



    // modal for add
    toggleModalAdd(LegalEntityId) {
        const { isOpen, LegalEntityName } = this.state
        this.setState({ isOpen: !isOpen, LegalEntityName: "",format : "", _isAdd: true })
        this.state._isAdd = true
        this.state._isUpdate = false
        this.state._isDelete = false
    }

    // Modal for Update
    toggleModalUpdate(LegalEntityId) {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen, _isUpdate: true })
        this.state._isUpdate = true
        this.state._isAdd = false
        this.state._isDelete = false
        this.getLegalEntityDetails(LegalEntityId)
        if (LegalEntityId == '') {
            return;
        } else {
            this.setState({ LegalEntityId: LegalEntityId })
        }
    }

    // update LegalEntity  
    handleUpdate(e) {
        e.preventDefault()
        const { LegalEntityName, format } = this.state
        const id = this.state.LegalEntityId;

        if (id != "" && LegalEntityName != "" && format != "") {
            this.setState({ isLoading: true })
            this.props
                .updatelegalEntityList_action(id, LegalEntityName.trim(), format)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false })
                        this.handleCancel()
                        this.getLegalEntityList()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }
    record(LegalEntityList) {
        return (
            <table className="table table-bordered tb-action du-table -50">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ textAlign: 'left' }} onClick={() => this.LegalEntitySort()}>Legal Entity</th>
                        <th style={{ textAlign: 'left' }} onClick={() => this.LegalEntitySort()}>Format</th>
                        <th style={{ width: '100px' }} onClick={() => this.statusSort()}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {LegalEntityList.length > 0 ? (
                        LegalEntityList.map((data, i) => (
                            <tr key={data.LegalEntityID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.toggleModalUpdate(data.ID
)} /></a>
                                        {/* <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.LegalEntityID)} /></span></a> */}
                                        {data.IsActive == 1 ? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.ID)} /></span></a>
                                            : <></>}
                                    </div>
                                </td>
                                <td style={{ textAlign: 'left' }} onClick={() => this.LegalEntitySort()} >{data.LegalEntityType}</td>
                                <td style={{ textAlign: 'left' }} onClick={() => this.LegalEntitySort()} >{data.Format}</td>
                                <td style={{ textAlign: 'center' }}>{data.IsActive ? "Active" : "Inactive"}</td>
                            </tr>
                        ))

                    ) : (<tr><td colSpan={5} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }

    render() {
        const { isSearchTab, isAccessToView, isOpen, isLoading, format,formatSearch, status, 
            LegalEntityName, LegalEntityList, _isAdd, _isUpdate, _isDelete,  search_LegalEntityName } = this.state
// 
        // const recordsPerPage = 10
        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = LegalEntityList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(LegalEntityList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />


                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>Legal Entity</a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className='page-space-from-bottom'>
                                    <div className="row">
                                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n.accordion-button::after {\nbackground-size: 13px;\ndisplay: none;\n} \n" }} />
                                        <div className="col-md-12 pg-body grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body pr-2">
                                                    <div className="accordion accordion-flush " id="accordionFlushExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="flush-headingOne">
                                                                <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                                                    <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                </button>
                                                            </h2>
                                                            {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                                <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                <div className="d-flex flex-wrap row">
                                                                    <div className="form-group col-md-3">
                                                                        <label>Legal Entity</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            value={search_LegalEntityName}
                                                                            onKeyDown={e => this.handleKeyDown(e)}
                                                                            onChange={(e) => this.setState({ search_LegalEntityName: e.target.value })}
                                                                            required />
                                                                    </div>
                                                                    <div class="form-group col-md-3 ">
                                                                        <label>Format<span class="required1">*</span></label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={formatSearch}
                                                                            onChange={(e) => {
                                                                                const inputValue = e.target.value;
                                                                                // Limit the input to 100 characters
                                                                                if (inputValue.length <= 100) {
                                                                                    this.setState({ formatSearch: inputValue });
                                                                                }
                                                                            }}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label>Status</label>
                                                                        <select className="js-example-basic-single" style={{ width: '100%' }}
                                                                            value={status}
                                                                            onChange={(e) => this.setState({ status: e.target.value })}>
                                                                             <option value={''}>Select</option>
                                                                            <option value={1}>Active</option>
                                                                            <option value={0}>Inactive</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-md-3 filter-btn-col p-0">
                                      <label></label>
                                      <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={e => this.handleSearch()}> Search</button>
                                      <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3" onClick={e => this.clearValue()}> Clear</button>
                                    </div>
                                                                </div>
                                                            </div>
                                                            </div> : <></>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 mt-1">
                                                            <button type="button" className="btn btn-Add rounded-1 px-3 mb-1"
                                                                style={{ lineHeight: '14px', padding: '7px', float: 'right' }} onClick={(e) => this.toggleModalAdd(e)}> Add</button>
                                                        </div>
                                                        <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                            {this.record(currentRecords)}
                                                            {LegalEntityList.length > 0 ? <Pagination
                                                                nPages={nPages}
                                                                currentPage={currentPage}
                                                                setCurrentPage={this.updateItem}
                                                                recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                 setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                   totalRecords={LegalEntityList.length}
                                                                 /> : <></>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>


                <ToastContainer />
                {/* // modal for delete */}
                {/* <Modal isOpen={isOpen && _isDelete} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}

<Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
          <div className="modal-dialog custom_modal_dialog">
            <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
              <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                </div>
              </div>
              <div class="modal-footer p-1">
                  <div class="form-group text-right">
                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1"  onClick={(e) => this.handleDelete(e)}> Yes</a>
                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                </div>
              </div>
            </div>
          </div>
        </Modal>

                {/* Modal for add and update */}
                <Modal isOpen={(isOpen && _isAdd) || (isOpen && _isUpdate)} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Legal Entity</h4>
                                {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="form-group col-12 d-flex">
                                    <label>Legal Entity Type<span class="required1">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={LegalEntityName}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            // Limit the input to 100 characters
                                            if (inputValue.length <= 100) {
                                                this.setState({ LegalEntityName: inputValue });
                                            }
                                        }}
                                        required
                                    />
                                </div>
                                <div class="form-group col-12 d-flex">
                                    <label>Format<span class="required1">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={format}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            // Limit the input to 100 characters
                                            if (inputValue.length <= 100) {
                                                this.setState({ format: inputValue });
                                            }
                                        }}
                                        required
                                    />
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-left mod-fot m-2">
                                        <div
                                            className="btn-group"
                                            role="group"
                                            aria-label="Basic example"
                                        >
                                            {
                                                this.state._isAdd && <button
                                                    type="button"
                                                    className="btn btn-save footer-btn-inner mr-1"
                                                    onClick={(e) => this.handleAdd(e)}
                                                >
                                                    Save
                                                </button>
                                            }

                                            {
                                                this.state._isUpdate &&
                                                <button
                                                    type="button"
                                                    className="btn btn-update footer-btn-inner mr-1"
                                                    onClick={(e) => this.handleUpdate(e)}
                                                >
                                                    Update
                                                </button>
                                            }
                                            <button
                                                type="button"
                                                className="btn btn-cancel footer-btn-inner mr-1"
                                                onClick={this.handleCancel}
                                            >
                                                Cancel
                                            </button>
                                            {
                                                this.state._isAdd &&
                                                <button
                                                    type="button"
                                                    className="btn btn-update footer-btn-inner mr-1"
                                                    onClick={() => this.clearValue()}
                                                >
                                                    Reset
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(connect(null, {
    legalEntityList_action,
    addlegalEntityList_action,
    deletelegalEntityList_action,
    legalEntityDetail_action,
    updatelegalEntityList_action
})(LegalEntity));