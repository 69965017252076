import { axiosPost } from "../../react_axios/Axios"
import { authUrl, platfarm, LOGIN_ACTION_TYPE, headers, changePasswordUrl, CHANGE_PASSWORD_ACTION_TYPE, forgetPasswordUrl, FORGET_PASSWORD_ACTION_TYPE } from "../../utils/constants"

export const login_action = (userName, password) => async (dispatch) => {
  try {
    const mapdata = {
      UserName: userName,
      Password: password,
    }
    const res = await axiosPost(authUrl, mapdata, headers);
    if (res != null) {
      console.log("login_action",res)
      localStorage.clear()
      console.log('login_action', JSON.stringify(res.data))
      localStorage.setItem('UserLoginData', JSON.stringify(res.data)) //get object data using JSON.parse()
      localStorage.setItem('UserId', res.data[0]['UserID'])
      localStorage.setItem('EmployeeId', res.data[0]['EmployeeID'])
      localStorage.setItem('UserName', res.data[0]['Name'])
      localStorage.setItem('CompanyName', res.data[0]['CompanyName'])
      localStorage.setItem('RoleId', res.data[0]['roleId'])
      localStorage.setItem('ModuleAccessList', JSON.stringify(res.moduleAccess))
      localStorage.setItem('ReportAccessList', JSON.stringify(res.reportAccess))

      console.log("*******************************Login Data", res.data)

    }

    dispatch({
      type: LOGIN_ACTION_TYPE,
      payload: res,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};



export const changePassword_action = (oldPassword, newPassword) => async (dispatch) => {
  try {
    const mapdata = {
      UserID: parseInt(localStorage.getItem('UserId')),
      Platform: platfarm,
      oldPassword: oldPassword,
      newPassword: newPassword,
    }
    const res = await axiosPost(changePasswordUrl, mapdata, headers);
    dispatch({
      type: CHANGE_PASSWORD_ACTION_TYPE,
      payload: res,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const forgetPassword_action = (email) => async (dispatch) => {
  try {
    const mapdata = {
      Platform: platfarm,
      email: email,
    }
    const res = await axiosPost(forgetPasswordUrl, mapdata, headers);
    dispatch({
      type: FORGET_PASSWORD_ACTION_TYPE,
      payload: res,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
