import React, { Component } from "react";
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { employeeList_action, deleteEmployeeList_action } from "../../../actions/master_action/employee_action"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import { withRouter } from "../../../utils/withRouter";
import moment from "moment";
import Modal from "react-modal"
import Loader from "../../../utils/Loader";
import Pagination from "../../../utils/pagination";

import { clearLocalStorage, Master } from "../../../utils/constants";
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from "../../../utils/RoleAccess";

class employeeList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isAccessToView: true,
      code: '',
      employeeName: '',
      gender: '',
      designation: '',
      department: '',
      manager: '',
      partner: '',
      from: '',
      to: '',
      email: '',
      status: ' ',
      employeeList: [],
      employeeDeleteId: '',
      isOpen: false,
      employeelistDeleteId: '',
      isLoading: false,
      currentPage: 1,
      tablePerPage: 10,
      recordsPerPage: 10,
      pageSizeInput: "10",
      codeElS: '',
      employeeNameELS: '',
      genderELS: '',
      designationELS: '',
      departmentELS: '',
      managerELS: '',
      partnerELS: '',
      fromELS: '',
      toELS: '',
      emailELS: '',
      statusELS: '',
      isEmployeelistNameSort: false,
      isStatusSort: false,
      isCodeSort: false,
      isEmployeeNameSort: false,
      isGenderSort: false,
      isDepartmentSort: false,
      isSearchTab: false,
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.updateItem = this.updateItem.bind(this);
    this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
    this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);
    this.codeSort = this.codeSort.bind(this)
    this.employeeNameSort = this.employeeNameSort.bind(this)
    this.genderSort = this.genderSort.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.getEmployee_list = this.getEmployee_list.bind(this)
  }


  codeSort() {
    const { employeeList, isCodeSort } = this.state
    this.setState({ isCodeSort: !isCodeSort })
    if (!isCodeSort) {
      // sorting
      var codeSort = employeeList.sort((a, b) => {
        const nameA = a.Code // ignore upper and lowercase
        const nameB = b.Code // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ employeeList: codeSort })
    }
    else {
      // reverse
      var codeSort = employeeList.sort((a, b) => {
        const nameA = a.Code // ignore upper and lowercase
        const nameB = b.Code // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ employeeList: codeSort })
    }
    this.setState({ isCodeSort: !isCodeSort })
  }

  employeeNameSort() {
    const { employeeList, isEmployeeNameSort } = this.state
    this.setState({ isEmployeeNameSort: !isEmployeeNameSort })
    if (!isEmployeeNameSort) {
      // sorting
      var employeeNameSort = employeeList.sort((a, b) => {
        const nameA = a.Code.toUpperCase(); // ignore upper and lowercase
        const nameB = b.Code.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ employeeList: employeeNameSort })
    }
    else {
      // reverse
      var CodeSort = employeeList.sort((a, b) => {
        const nameA = a.Code.toUpperCase(); // ignore upper and lowercase
        const nameB = b.Code.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ employeeList: CodeSort })
    }
    this.setState({ isEmployeeNameSort: !isEmployeeNameSort })
  }

  genderSort() {
    const { employeeList, isGenderSort } = this.state
    this.setState({ isGenderSort: !isGenderSort })
    if (!isGenderSort) {
      // sorting
      var genderSort = employeeList.sort((a, b) => {
        const nameA = a.Code.toUpperCase(); // ignore upper and lowercase
        const nameB = b.Code.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ employeeList: genderSort })
    }
    else {
      // reverse
      var genderSort = employeeList.sort((a, b) => {
        const nameA = a.Code.toUpperCase(); // ignore upper and lowercase
        const nameB = b.Code.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ employeeList: genderSort })
    }
    this.setState({ isGenderSort: !isGenderSort })
  }

  getEmployee_list() {
    const { code, employeeName, gender, designation, department, manager, partner, email, status } = this.state
    this.setState({ isLoading: true })
    clearLocalStorage("employee")
    this.props
      .employeeList_action(code, employeeName.trimStart(), gender,
        designation, department, manager, partner, email,
        status == '' || status != null ? 1 : status)
      .then((data) => {
        if (data.error != 1) {
          this.setState({ employeeList: data.data, isLoading: false, currentPage: 1 })
        }
        else {
          this.setState({ isLoading: false, })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }



  // Employee list defaultxxx
  componentDidMount = () => {
    this.setState({
      isAccessToView: true,
      codeElS: localStorage.getItem('codeElS'),
      employeeNameELS: localStorage.getItem('employeeNameELS'),
      genderELS: localStorage.getItem('genderELS'),
      designationELS: localStorage.getItem('designationELS'),
      departmentELS: localStorage.getItem('departmentELS'),
      managerELS: localStorage.getItem('managerELS'),
      partnerELS: localStorage.getItem('partnerELS'),
      fromELS: localStorage.getItem('fromELS'),
      toELS: localStorage.getItem('toELS'),
      emailELS: localStorage.getItem('emailELS'),
      statusELS: localStorage.getItem('statusELS'),

      code: localStorage.getItem('codeElS') != '' ? localStorage.getItem('codeElS') : '',
      employeeName: localStorage.getItem('employeeNameELS') != '' ? localStorage.getItem('employeeNameELS') : '',
      gender: localStorage.getItem('genderELS') != '' ? localStorage.getItem('genderELS') : '',
      designation: localStorage.getItem('designationELS') != '' ? localStorage.getItem('designationELS') : '',
      department: localStorage.getItem('departmentELS') != '' ? localStorage.getItem('departmentELS') : '',
      manager: localStorage.getItem('managerELS') != '' ? localStorage.getItem('managerELS') : '',
      partner: localStorage.getItem('partnerELS') != '' ? localStorage.getItem('partnerELS') : '',
      from: localStorage.getItem('fromELS') != '' ? localStorage.getItem('fromELS') : '',
      to: localStorage.getItem('toELS') != '' ? localStorage.getItem('toELS') : '',
      email: localStorage.getItem('emailELS') != '' ? localStorage.getItem('emailELS') : '',
      statusELS: localStorage.getItem('statusELS') != '' ? localStorage.getItem('statusELS') : '',

    })
    clearLocalStorage("employee")
    setTimeout(() => {
      this.getEmployee_list()

    }, 300);

    // document.addEventListener('keydown', this.handleTabKeyPress);
    // document.addEventListener('click', this.handleInputClick);

  }

  componentWillUnmount() {
    // document.removeEventListener('keydown', this.handleTabKeyPress);
    // document.removeEventListener('click', this.handleInputClick);
  }



  handleTabKeyPress = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
      const focusedElement = document.activeElement;
      const { previouslyFocusedElement } = this.state;

      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }

      if (focusedElement.classList.contains('form-control') ||
        focusedElement.classList.contains('js-example-basic-single') ||
        focusedElement.classList.contains('example-custom-input')) {
        focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
        this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
      }
    }
  };


  handleInputClick = (event) => {
    const { previouslyFocusedElement } = this.state;
    const clickedElement = event.target;

    if (previouslyFocusedElement) {
      previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    }

    if (clickedElement.classList.contains('form-control') ||
      clickedElement.classList.contains('js-example-basic-single') ||
      clickedElement.classList.contains('example-custom-input')) {
      clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
      this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
    } else {
      this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
    }
  };


  // search list 
  handleSearch() {
    const { code, employeeName, gender, designation, department, manager, partner, email, status } = this.state

    clearLocalStorage("employee")

    this.setState({ isLoading: true })
    this.props
      .employeeList_action(code, employeeName.trim(), gender,
        designation, department, manager, partner, email,
        status == '' || status != null ? 1 : status)
      .then((data) => {
        if (data.error != 1) {
          this.setState({ employeeList: data.data, isLoading: false, currentPage: 1 })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

    localStorage.setItem("codeELS", code)
    localStorage.setItem("employeeNameELS", employeeName)
    localStorage.setItem("genderELS", gender)
    localStorage.setItem("designationELS", designation)
    localStorage.setItem("departmentELS", department)
    localStorage.setItem("managerELS", manager)
    localStorage.setItem("partnerELS", partner)
    localStorage.setItem("emailELS", email)
    localStorage.setItem("statusELS", status)
  }

  onSearchTabClick() {
    const { isSearchTab, } = this.state;
    console.log("onSearchTabClick", isSearchTab)
    this.setState({ isSearchTab: !isSearchTab })
  }

  clearValue() {
    this.setState({
      code: '',
      employeeName: '',
      gender: '',
      designation: '',
      department: '',
      manager: '',
      partner: '',
      from: '',
      to: '',
      email: '',
      status: ' ',
      financialYearList: [],
      currentPage: 1,
      codeElS: '',
      employeeNameELS: '',
      genderELS: '',
      designationELS: '',
      departmentELS: '',
      managerELS: '',
      partnerELS: '',
      fromELS: '',
      toELS: '',
      emailELS: '',
      statusELS: '',
    })
    clearLocalStorage('')
    setTimeout(() => {
      this.getEmployee_list()
    }, 300);

  }

  handleKeyDown(e) {
    if (e.code === 'Enter') {
      this.handleSearch()
    }
  }

  // add list 
  handleAdd() {
    this.props.navigate('/employeeAU', { state: { is_add: true } })
  }

  // update list 
  handleUpdate(employeeListId) {
    this.props.navigate('/employeeAU', { state: { is_update: true, employeeId: employeeListId } })
  }

  // delete list 
  handleDelete(e, employeeId) {
    // this.props.navigate('/finYearAU', { state: { is_delete: true , employeeId : employeeId } })
    e.preventDefault()
    var id = employeeId
    this.setState({ isLoading: true })
    this.props
      .deleteEmployeeList_action(id)
      .then((data) => {
        if (data.error != 1) {
          toast.success('Data deleted successfully', {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
          this.setState({ isOpen: !this.state.isOpen, isLoading: false })
          this.getEmployee_list()
        }
        else {
          this.setState({ isOpen: !this.state.isOpen, isLoading: false })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }


  //toggle model for delete.................................
  toggleModal(employeeId) {
    const { isOpen, employeeDeleteId } = this.state
    this.setState({ isOpen: !isOpen })
    if (employeeId == '') {
      return;
    } else {
      this.setState({ employeeDeleteId: employeeId })
    }

  }

  updateItem(item) {
    this.setState({ currentPage: item });
  }
  updateRecordsPerPage = (newSize) => {
    const parsedSize = parseInt(newSize, 10);
    if (!isNaN(parsedSize) && parsedSize > 0) {
      this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
    }
    this.setState({ pageSizeInput: newSize }); // Keep the input value updated
  };

  handlePageSizeInputChange = (event) => {
    const input = event.target.value;
    if (/^\d*$/.test(input)) { // Only allow digits
      this.setState({ pageSizeInput: input });
    }
  };
  record(employeeList) {
    return (
      <table className="table table-bordered tb-action du-table">
        <thead>
          <tr>
            <th style={{ width: '60px' }} />
            <th style={{ textAlign: 'left' }} >Employee Name</th>
            <th style={{ textAlign: 'left' }} >Gender</th>
            <th style={{ textAlign: 'left' }} >Designation</th>
            <th style={{ textAlign: 'left' }} >Department</th>
            {/* <th style={{ textAlign: 'left' }} >Manager</th> */}
            <th style={{ textAlign: 'left' }} >Report Partner</th>
            <th style={{ textAlign: 'left' }} >From</th>
            <th style={{ textAlign: 'left' }} >To</th>
            <th style={{ textAlign: 'left' }} >Email</th>
            <th style={{ textAlign: 'left' }} >Status</th>
          </tr>
        </thead>
        <tbody>
          {employeeList.length > 0 ? (
            employeeList.map((data, i) => (
              <tr key={data.EmployeeID}>
                <td className="text-center" style={{ padding: '5px!important' }}>
                  <div className="table__button-group">
                    {getModuleUpdateAccess(Master) ?
                      <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleUpdate(data.EmployeeID)} /></a> :
                      <a><i className="fa fa-eye" onClick={() => this.handleUpdate(data.EmployeeID)} /></a>}
                    {getModuleDeleteAccess(Master) && <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.EmployeeID)} /></span></a>}


                  </div>
                </td>
                <td style={{ textAlign: 'left' }}>{data.EmployeeName}</td>
                <td style={{ textAlign: 'left' }}>{data.gensGender}</td>
                <td style={{ textAlign: 'left' }}>{data.Designation}</td>
                <td style={{ textAlign: 'left' }}>{data.Department}</td>
                {/* <td style={{ textAlign: 'left' }}>{data.Manager}</td> */}
                <td style={{ textAlign: 'left' }}>{data.Partner}</td>
                <td style={{ textAlign: 'left' }}>{data.FromDate != '' && data.FromDate != null && moment(data.FromDate).format('DD-MM-YYYY')}</td>
                <td style={{ textAlign: 'left' }}>{data.ToDate != '' && data.ToDate != null && moment(data.ToDate).format('DD-MM-YYYY')}</td>
                <td style={{ textAlign: 'left' }}>{data.EmailID}</td>
                <td style={{ textAlign: 'center' }}>{data.IsActive ? "Active" : "Inactive"}</td>
              </tr>
            ))

          ) : (<tr><td colSpan={5} className='text-center'>Record not found</td></tr>)}
        </tbody>
      </table>
    )
  }

  render() {

    const { isSearchTab, isAccessToView, employeeList,
      tablePerPage, isOpen, employeeDeleteId, isLoading, code, employeeName, gender, designation, department, manager, partner, from, to, email, status } = this.state
    const { currentPage, recordsPerPage, pageSizeInput } = this.state;

    //  const recordsPerPage = 10
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    // Records to be displayed on the current page
    const currentRecords = employeeList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(employeeList.length / recordsPerPage)


    return (
      <>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          null
        )}
        <Header />


        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper">
            {/* Left side menu */}
            <Drawer />
            {/* end left menu */}
            {/* start page */}
            {isAccessToView ? (
              <div className="main-panel main-box">
                <div className="content-wrapper">
                  <div className="main-breadcum">
                    <ul className="breadcrumb">
                      <li><a>Employee</a></li>
                      <li />
                    </ul>
                  </div>
                  <div className='page-space-from-bottom'>
                    <div className="row">
                      <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                      <div className="col-md-12 pg-body grid-margin stretch-card">
                        <div className="card">
                          <div className="card-body pr-2">
                            <div className="accordion accordion-flush " id="accordionFlushExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                  <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                    <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                  </button>
                                </h2>
                                {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                  <div className="d-flex flex-wrap row">
                                    <div className="form-group col-md-3">
                                      <label>Employee Name</label>
                                      <input type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={employeeName}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ employeeName: e.target.value })}
                                        required />
                                    </div>
                                    <div className="form-group col-md-3">
                                      <label>Gender</label>
                                      <input type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={gender}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ gender: e.target.value })}
                                        required />
                                    </div>
                                    <div className="form-group col-md-3">
                                      <label>Designation</label>
                                      <input type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={designation}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ designation: e.target.value })}
                                        required />
                                    </div>
                                    <div className="form-group col-md-3">
                                      <label>Department</label>
                                      <input type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={department}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ department: e.target.value })}
                                        required />
                                    </div>
                                    {/* <div className="form-group col-md-3">
                                      <label>Manager</label>
                                      <input type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={manager}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ manager: e.target.value })}
                                        required />
                                    </div> */}
                                    <div className="form-group col-md-3">
                                      <label>Report Partner</label>
                                      <input type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={partner}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ partner: e.target.value })}
                                        required />
                                    </div>
                                    <div className="form-group col-md-3">
                                      <label>Email</label>
                                      <input type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={email}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ email: e.target.value })}
                                        required />
                                    </div>
                                    <div className="form-group col-md-3">
                                      <label>Status</label>
                                      <select className="js-example-basic-single" style={{ width: '100%' }}
                                        value={status}
                                        onChange={(e) => this.setState({ status: e.target.value })}>
                                        <option value={''}>Select</option>
                                        <option value={1}>Active</option>
                                        <option value={0}>Inactive</option>
                                      </select>
                                    </div>
                                    <div className="form-group col-md-3 filter-btn-col">
                                      <button type="button" className="btn btn-Add rounded-1 fil-btn py-2 mt-2" onClick={e => this.handleSearch()}> Search</button>
                                      <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3 mt-2" onClick={e => this.clearValue()}> Clear</button>
                                    </div>
                                  </div>
                                </div>
                                </div> : <></>}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 mt-1">
                                {getModuleWriteAccess(Master) && <button type="button" className="btn btn-Add rounded-1 mb-1 px-3"
                                  style={{ lineHeight: '14px', padding: '7px', float: 'right' }} onClick={() => this.handleAdd()}> Add</button>}
                                <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                  {this.record(currentRecords)}
                                  {employeeList.length > 0 ? <Pagination
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={this.updateItem}
                                    recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                    setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                    totalRecords={employeeList.length}
                                  /> : <></>}

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (<div className='access-msg h-auto'>
              <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
            </div>)}
            {/* end page */}
          </div>
          {/* page-body-wrapper ends */}
        </div>


        <ToastContainer />
        {/* // modal for delete */}
        {/* <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: !isOpen })}>
          <div className="modal-dialog custom_modal_dialog">
            <div className="modal-content" style={{ borderRadius: '0px', width: '400px' }}>
              <div className="modal-header">
                <h4 className="modal-title modal_title_text">Confirm Delete</h4>
                <button type="button " className="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isOpen: !isOpen })} ><span aria-hidden="true" className="pop-cancel">&times;</span></button>

              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="pop_label">Do you really want to delete this record?</h3>
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-md-12">
                  <div className="form-group text-right mod-fot">
                    <a href="javascript:void(0);" className="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, employeeDeleteId)}><i className="fa fa-check"></i>&nbsp; Yes</a>
                    <a href="javascript:void(0);" className="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ isOpen: !isOpen })}><i className="fa fa-times"></i>&nbsp; No</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Modal> */}

        <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
          <div className="modal-dialog custom_modal_dialog">
            <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
              <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isOpen: !this.state.isOpen })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                </div>
              </div>
              <div class="modal-footer p-1">
                <div class="form-group text-right">
                  <a class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, employeeDeleteId)}> Yes</a>
                  <a class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={() => this.setState({ isOpen: !this.state.isOpen })} >No</a>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }

}


export default withRouter(connect(null, { employeeList_action, deleteEmployeeList_action })(employeeList));