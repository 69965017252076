import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Loader from '../../../utils/Loader'
import { fill_action } from '../../../actions/master_action/checklist_action';
import { chapterListActionId, mcqStatusActionId, levelActionId } from '../../../utils/constants'
import { TestList_action, } from '../../../actions/mcq_action/test_action';
import moment from 'moment';
import Pagination from '../../../utils/pagination';


class HomeTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAccessToView: true,
            WorkingDayID: '',
            workingDayTypeList: [],
            testInfo: [],
            is_add: false,
            is_delete: false,
            is_update: false,
            mcqNo: '',
            chapterId: "",
            chapterList: [],
            levelId: "",
            levelList: [],
            answerKey: '',
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage : 10,
            pageSizeInput: "10", 
        }
        this.getFillList = this.getFillList.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.getHomeTest = this.getHomeTest.bind(this)
        this.record = this.record.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);  
		
    }

    componentDidMount = () => {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
            WorkingDayID: this.props.location.state != null && this.props.location.state.WorkingDayID != null ? this.props.location.state.WorkingDayID : '',
            dayOfTheWeek: this.props.location.state != null && this.props.location.state.dayOfTheWeek != null ? this.props.location.state.dayOfTheWeek : '',
            // isAccessToView: localStorage.getItem('UserId') == '1' ? true : false,
        })
        this.getHomeTest()

        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {

        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }

  

    updateItem(item) {
        this.setState({ currentPage: item });
      }

   
      updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
          this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
      };
      
      handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
          this.setState({ pageSizeInput: input });
        }
      };

  handleTabKeyPress = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
      const focusedElement = document.activeElement;
      const { previouslyFocusedElement } = this.state;

      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }

      if (focusedElement.classList.contains('form-control') ||
        focusedElement.classList.contains('js-example-basic-single') ||
        focusedElement.classList.contains('example-custom-input')) {
        focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
        this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
      }
    }
  };


  handleInputClick = (event) => {
    const { previouslyFocusedElement } = this.state;
    const clickedElement = event.target;

    if (previouslyFocusedElement) {
      previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    }

    if (clickedElement.classList.contains('form-control') ||
      clickedElement.classList.contains('js-example-basic-single') ||
      clickedElement.classList.contains('example-custom-input')) {
      clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
      this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
    } else {
      this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
    }
  };

    //get details
    getHomeTest() {

        var id = this.state.WorkingDayID
        this.props
            .TestList_action()
            .then((data) => {
                if (data.error == 0) {
                    console.log("TestList_action", data.data)
                    this.setState({
                        testInfo: data.data,

                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    //Fill list
    getFillList(value, ref, ref2) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {

                }
            })
    }

    //cancel
    handleCancel() {
        this.props.navigate('/takeTest') 
    }

    //add working day
    handleAdd(e) {
        e.preventDefault()
        const { dayOfTheWeek, WorkingDayID, week1Status, week2Status, week3Status, week4Status, week5Status } = this.state
        const id = WorkingDayID

        // if (dayOfTheWeek != "" && week1Status != "" && week2Status != "" && week3Status != "" && week4Status != "" && week5Status != "") {
        //     this.setState({ isLoading: true })
        //     this.props
        //         .addSetting_action(dayOfTheWeek.trim(), week1Status, week2Status, week3Status, week4Status, week5Status)
        //         .then((data) => {
        //             if (data.error != 1) {
        //                 toast.success('Data added successfully', {
        //                     theme: 'colored',
        //                     autoClose: 3000,
        //                     hideProgressBar: true,
        //                 })

        //                 this.setState({ isLoading: false })
        //                 setTimeout(() => {
        //                     this.handleCancel()
        //                 }, 500);
        //             }
        //             else {
        //                 this.setState({ isLoading: false })

        //                 toast.error(data.msg, {
        //                     theme: 'colored',
        //                     autoClose: 3000,
        //                     hideProgressBar: true,
        //                 })
        //             }
        //         })
        //         .catch((e) => {
        //             console.log(e);
        //         });
        // }
        // else {
        //     this.setState({ isLoading: false })
        //     toast.error('Please enter  mandatory field', {
        //         theme: 'colored',
        //         autoClose: 3000,
        //         hideProgressBar: true,
        //     })
        // }
    }

    //update working day
    handleUpdate(e) {
        e.preventDefault()
        const { WorkingDayID, week1Status, week2Status, week3Status, week4Status, week5Status } = this.state
        const id = WorkingDayID

        // if (id != "" && week1Status != "" && week2Status != "" && week3Status != "" && week4Status != "" && week5Status != "") {
        //     this.setState({ isLoading: true })
        //     this.props
        //         .addSetting_action(id, week1Status, week2Status, week3Status, week4Status, week5Status)
        //         .then((data) => {
        //             if (data.error == 0) {
        //                 toast.success('Data updated successfully', {
        //                     theme: 'colored',
        //                     autoClose: 3000,
        //                     hideProgressBar: true,
        //                 })

        //                 this.setState({ isLoading: false })
        //                 setTimeout(() => {
        //                     this.handleCancel()
        //                 }, 500);

        //             }
        //             else {
        //                 this.setState({ isLoading: false })

        //                 toast.error(data.msg, {
        //                     theme: 'colored',
        //                     autoClose: 3000,
        //                     hideProgressBar: true,
        //                 })
        //             }
        //         })
        //         .catch((e) => {
        //             console.log(e);
        //         });
        // }
        // else {
        //     this.setState({ isLoading: false })
        //     toast.error('Please enter  mandatory field', {
        //         theme: 'colored',
        //         autoClose: 3000,
        //         hideProgressBar: true,
        //     })
        // }
    }

    record(testInfo) {
        return (
            <table className="table table-bordered tb-action du-table mt-2">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th className="text-center">Chapter</th>
                        <th className="text-center">Level</th>
                        <th className="text-center">Type of Test</th>
                        <th className="text-center">Score</th>
                        <th className="text-center">Score %</th>
                        <th className="text-center">Time Taken</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        testInfo.length > 0 ?
                            testInfo.map((element) => {
                                return <tr>
                                    <td>{moment(element.testdatetime).format('DD-MM-YYYY')}</td>
                                    <td className="text-center">{element.ChapterName}</td>
                                    <td className="text-center">{element.level}</td>
                                    <td className="text-center">{element.TypeOfTest}</td>
                                    <td className="text-center">{element.score != '/' ? element.score : ""}</td>
                                    <td className="text-center">{element.ScorePercentage}</td>
                                    <td className="text-center">{element.time_taken}</td>
                                </tr>
                            })
                            : <></>
                    }
                </tbody>
            </table>
        )
    }



    render() {
        const { is_update, isAccessToView,  isLoading, mcqNo, chapterId, chapterList, levelId, levelList, answerKey, testInfo } = this.state
        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = testInfo.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(testInfo.length / recordsPerPage)



        return (<>
            {isLoading ? (
                <Loader></Loader>
            ) : (
                null
            )}
            <Header />
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                    {/* Left side menu */}
                    <Drawer />
                    {/* end left menu */}
                    {/* start page */}
                    {isAccessToView ? (
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul class="breadcrumb d-flex">
                                        <li><a onClick={this.handleCancel}>Test History</a></li>
                                        <li></li>
                                    </ul>
                                    {/* <div class="status-top mr-2 text-dark" style={{ position: 'absolute', right: '0px', top: '62' }}>
                                        <p>Status : <strong class="text-success">Active</strong></p>
                                    </div> */}
                                </div>
                                <div className='page-space-from-bottom'>
                                <div className="row">
                                    <style
                                        type="text/css"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                        }}
                                    />
                                    <div className="card">
                                        <div className="card-body p-2">
                                            <div className="row">
                                                <div className="col-12">
                                                    {testInfo.length < 0 ?
                                                        <>
                                                            <img src="assets/pngtree-test-icon-png-image_6591706.png" className="m-auto d-block" style={{ width: '350px' }} />
                                                            <div className="d-flex flex-wrap row border m-0 p-1 rounded-1 pb-2 text-center">
                                                                <div className="form-group col-12 pb-1">
                                                                    <h5 className="pt-2">You have not taken any test</h5>
                                                                    <button className="btn btn-outline-success p-2 mt-2" style={{ fontSize: '13px', width: '150px', height: '34px' }} onClick={() => this.handleCancel()}>Take a test now</button>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : <>
                                                            
                                                            <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                            <button className="btn btn-outline-success p-2 mb-2" style={{ fontSize: '13px', width: '150px', float: 'right', height: '34px' }}
                                                                onClick={() => this.handleCancel()}>Take a test now</button>
                                                                {/* <a href="training_entry_add.html"><button type="button" class="btn btn-Add text-white rounded-0 mb-2" style="line-height: 14px; padding: 7px; float:right"> Add</button></a> */}
                                                                <p className='pt-1'>List of previous test taken</p>

                                                                {this.record(currentRecords)}
                                                                {testInfo.length > 0 ? <Pagination
                                                                    nPages={nPages}
                                                                    currentPage={currentPage}
                                                                    setCurrentPage={this.updateItem}
                                                                    recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                    setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                      totalRecords={testInfo.length}
                                                                     /> : <></>}
                                                            </div>
                                                            {/* <button className="btn btn-outline-success p-2" style={{ fontSize: '13px', width: '150px', float: 'left', height: '34px' }}
                                                                onClick={() => this.handleCancel()} >Take a test now</button> */}
                                                        </>}


                                                </div>
                                            </div>
                                            <div style={{ height: '75px' }} />
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)}
                    {/* end page */}
                </div>
                {/* page-body-wrapper ends */}
            </div>
            <ToastContainer />
        </>)
    }
}

export default withRouter(connect(null, {
    fill_action, TestList_action,

})(HomeTest))