import { axiosGet, axiosPost } from "../../react_axios/Axios"
import { engagementMasterListURL, engagementMasterDetailsUrl, addEngagementMasterUrl, updateEngagementMasterUrl, deleteEngagementMasterUrl, platfarm, ENGAGEMENT_MASTER_LIST_ACTION_TYPE, ENGAGEMENT_MASTER_DETAILS_ACTION_TYPE, ENGAGEMENT_MASTER_ADD_ACTION_TYPE, ENGAGEMENT_MASTER_UPDATE_ACTION_TYPE, ENGAGEMENT_MASTER_DELETE_ACTION_TYPE, headers } from '../../utils/constants'

export const engagementMasterList_action = (engagementMasterName, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: engagementMasterName,
            status: status
        }
        const res = await axiosGet(engagementMasterListURL, param)

        dispatch({
            type: ENGAGEMENT_MASTER_LIST_ACTION_TYPE,
            payload: res
        })

        return Promise.resolve(res)
    } catch (err) {
        return Promise.reject(err)
    }
}

export const addEngagementMaster_action = (engagementMasterName, mainEngagmentId, subEngagementId, hasChild) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: engagementMasterName,
            MainType: mainEngagmentId,
            ParentEngID: subEngagementId,
            Haschild: hasChild,
        }
        console.log(mapdata)
        const res = await axiosPost
            (addEngagementMasterUrl, mapdata, headers)

        dispatch({
            type: ENGAGEMENT_MASTER_ADD_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const engagementMasterDetail_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id
        }
        console.log("details", param)
        const res = await axiosGet(engagementMasterDetailsUrl, param)
        dispatch({
            type: ENGAGEMENT_MASTER_DETAILS_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}


export const updateEngagementMaster_action = (id, engagementMasterName, mainEngagmentId, subEngagementId, HasChild) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
            Name: engagementMasterName,
            MainType: mainEngagmentId,
            ParentEngID: subEngagementId,
            HasChild: HasChild,
        }
        console.log(mapdata)
        const res = await axiosPost(updateEngagementMasterUrl, mapdata, headers)
        dispatch({
            type: ENGAGEMENT_MASTER_UPDATE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const deleteEngagementMaster_action = (id) =>
    async (dispatch) => {
        try {
            const param = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                ID: id,
            }
            const res = await axiosGet
                (deleteEngagementMasterUrl, param)
            dispatch({
                type: ENGAGEMENT_MASTER_DELETE_ACTION_TYPE,
                payload: res
            })
            return Promise.resolve(res)
        } catch (err) {
            return Promise.reject(err)
        }
    }

export default (state = {}, action) => {
    switch (action.type) {
        case 'SIMPLE_ACTION':
            return {
                result: action.payload
            }
        default:
            return state
    }
}