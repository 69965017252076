import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { checkList_action, fill_action, deleteCheckList_action } from '../../../actions/master_action/checklist_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Modal from "react-modal";
import $ from 'jquery'
import Loader from '../../../utils/Loader'
import Pagination from '../../../utils/pagination'
import { eqListId, clearLocalStorage, Master } from '../../../utils/constants'
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

// import ReactDOM from "https://cdn.skypack.dev/react-dom@17.0.1";
// import Pagination  from "https://cdn.skypack.dev/rc-pagination@3.1.15";
/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
// const mapStateToProps = state => ({
//   ...state
// })


class ChecklistList extends Component {


  constructor(props) {
    super(props)
    this.state = {
      isAccessToView: true,
      checklistName: '',
      shortName: '',
      checkList: [],
      eqList: [],
      eq: '',
      status: ' ',
      isOpen: false,
      checklistDeleteId: '',
      currentPage: 1,
      tablePerPage: 10,
      recordsPerPage : 10,
      pageSizeInput: "10",
      isLoading: false,
      checklistNameCLS: '',
      shortNameCLS: '',
      statusCLS: '',
      eqCLS: '',
      isChecklistNameSort: false,
      isEqSort: false,
      isShortNameSort: false,
      isStatusSort: false,
      isSearchTab: false,
    }
    this.handleSearch = this.handleSearch.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.getEQList = this.getEQList.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.handleClick = this.handleClick.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
    this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);  

    this.record = this.record.bind(this)
    this.clearValue = this.clearValue.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.checklistNameSort = this.checklistNameSort.bind(this)
    this.eqSort = this.eqSort.bind(this)
    this.shortNameSort = this.shortNameSort.bind(this)
    this.statusSort = this.statusSort.bind(this)

  }
  statusSort() {
    const { checkList, isStatusSort } = this.state
    this.setState({ isStatusSort: !isStatusSort })
    if (!isStatusSort) {
      // sorting
      var statusSort = checkList.sort((a, b) => {
        const nameA = a.isActive; // ignore upper and lowercase
        const nameB = b.isActive; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ checkList: statusSort })
    }
    else {
      // reverse
      var statusSort = checkList.sort((a, b) => {
        const nameA = a.isActive; // ignore upper and lowercase
        const nameB = b.isActive; // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ checkList: statusSort })
    }
    this.setState({ isStatusSort: !isStatusSort })
  }
  shortNameSort() {
    const { checkList, isShortNameSort } = this.state
    this.setState({ isShortNameSort: !isShortNameSort })
    if (!isShortNameSort) {
      // sorting
      var shortNameSort = checkList.sort((a, b) => {
        const nameA = a.Code.toUpperCase(); // ignore upper and lowercase
        const nameB = b.Code.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ checkList: shortNameSort })
    }
    else {
      // reverse
      var shortNameSort = checkList.sort((a, b) => {
        const nameA = a.Code.toUpperCase(); // ignore upper and lowercase
        const nameB = b.Code.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ checkList: shortNameSort })
    }
    this.setState({ isShortNameSort: !isShortNameSort })
  }
  eqSort() {
    const { checkList, isEqSort } = this.state
    this.setState({ isEqSort: !isEqSort })
    if (!isEqSort) {
      // sorting
      var eqSort = checkList.sort((a, b) => {
        const nameA = a.EQName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.EQName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ checkList: eqSort })
    }
    else {
      // reverse
      var eqSort = checkList.sort((a, b) => {
        const nameA = a.EQName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.EQName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ checkList: eqSort })
    }
    this.setState({ isEqSort: !isEqSort })
  }

  checklistNameSort() {
    const { checkList, isChecklistNameSort } = this.state
    this.setState({ isChecklistNameSort: !isChecklistNameSort })
    if (!isChecklistNameSort) {
      // sorting
      var checklistNameSort = checkList.sort((a, b) => {
        const nameA = a.ChecklistName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.ChecklistName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ checkList: checklistNameSort })
    }
    else {
      // reverse
      var checklistNameSort = checkList.sort((a, b) => {
        const nameA = a.ChecklistName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.ChecklistName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ checkList: checklistNameSort })
    }
    this.setState({ isChecklistNameSort: !isChecklistNameSort })
  }

  handleKeyDown(e) {
    if (e.code === 'Enter') {
      this.handleSearch()
    }
  }

  onSearchTabClick() {
    const { isSearchTab, } = this.state;
    console.log("onSearchTabClick", isSearchTab)
    this.setState({ isSearchTab: !isSearchTab })
  }

  clearValue() {
    this.setState({
      checklistName: '',
      shortName: '',
      status: ' ',
      eq: '',
      checkList: [],
      currentPage: 1,
      checklistNameCLS: '',
      shortNameCLS: '',
      statusCLS: '',
      eqCLS: ''
    })
    clearLocalStorage('')
    setTimeout(() => {
      this.getCheckList()
    }, 300);

  }

  handleClick(event) {
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid
    });

  }

  componentDidMount = () => {
    this.getEQList();

    this.setState({
      isAccessToView: true,
      checklistNameCLS: localStorage.getItem('checklistNameCLS'),
      shortNameCLS: localStorage.getItem('shortNameCLS'),
      statusCLS: localStorage.getItem('statusCLS'),
      eqCLS: localStorage.getItem('eqCLS'),
      checklistName: localStorage.getItem('checklistNameCLS') != '' ? localStorage.getItem('checklistNameCLS') : '',
      shortName: localStorage.getItem('shortNameCLS') != '' ? localStorage.getItem('shortNameCLS') : '',
      status: localStorage.getItem('statusCLS') != '' ? localStorage.getItem('statusCLS') : '',
      eq: localStorage.getItem('eqCLS') != '' ? localStorage.getItem('eqCLS') : '',

    })
    clearLocalStorage("checklist")
    setTimeout(() => {
     this.getCheckList() 

    }, 100);
    // document.addEventListener('keydown', this.handleTabKeyPress);
    // document.addEventListener('click', this.handleInputClick);

  }

  componentWillUnmount() {
      // document.removeEventListener('keydown', this.handleTabKeyPress);
      // document.removeEventListener('click', this.handleInputClick);
    }

  handleTabKeyPress = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
      const focusedElement = document.activeElement;
      const { previouslyFocusedElement } = this.state;

      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }

      if (focusedElement.classList.contains('form-control') ||
        focusedElement.classList.contains('js-example-basic-single') ||
        focusedElement.classList.contains('example-custom-input')) {
        focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
        this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
      }
    }
  };


  handleInputClick = (event) => {
    const { previouslyFocusedElement } = this.state;
    const clickedElement = event.target;

    if (previouslyFocusedElement) {
      previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    }

    if (clickedElement.classList.contains('form-control') ||
      clickedElement.classList.contains('js-example-basic-single') ||
      clickedElement.classList.contains('example-custom-input')) {
      clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
      this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
    } else {
      this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
    }
  };
  // get check list default
  getCheckList() {
    const { checklistName, status, shortName, eq } = this.state
    this.setState({ isLoading: true })
    clearLocalStorage("checklist")
    this.props
      .checkList_action(checklistName.trimStart(), shortName.trimStart(),  status == '' ? 1 : status, eq == '' ? null : eq)
      .then((data) => {
        if (data.error != 1) {
          this.setState({
            checkList: data.data,
            isLoading: false
          })

        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // get eq dropdown list 
  getEQList() {
    this.setState({ isLoading: true })
    this.props
      .fill_action(eqListId, '')
      .then((data) => {
        if (data.error != 1) {
          this.setState({ eqList: data.data, isLoading: false })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }

  // search list
  handleSearch() {
    const { checklistName, status, shortName, eq } = this.state
    clearLocalStorage("checklist")
    this.setState({ isLoading: true })
    this.props
      .checkList_action(checklistName.trimStart(), shortName.trimStart(), status == '' ? 1 : status, eq == '' ? null : eq)
      .then((data) => {
        if (data.error != 1) {
          this.setState({ checkList: data.data, isLoading: false, currentPage: 1 })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

    localStorage.setItem("checklistNameCLS", checklistName)
    localStorage.setItem("shortNameCLS", shortName)
    localStorage.setItem("statusCLS",  status)
    localStorage.setItem("eqCLS", eq)

  }

  // add list 
  handleAdd() {
    this.props.navigate('/checkListAU', { state: { is_add: true } })
  }

  // update list
  handleUpdate(checklistId) {
    this.props.navigate('/checkListAU', { state: { is_update: true, checklistId: checklistId } })
  }

  // delete list
  handleDelete(e, checklistId) {
    e.preventDefault()
    // this.props.navigate('/checkListAU', { state: { is_delete: true, checklistId: checklistId } })
    const { checkList } = this.state
    var id = checklistId
    this.setState({ isLoading: true })
    this.props
      .deleteCheckList_action(id)
      .then((data) => {
        if (data.error != 1) {
          toast.success('Data deleted successfully', {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
          this.setState({ isOpen: !this.state.isOpen, isLoading: false })
          this.getCheckList()
          this.record(checkList)
        }
        else {
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  //toggle model for delete.................................
  toggleModal(checklistId) {
    const { isOpen, checklistDeleteId } = this.state
    this.setState({ isOpen: !isOpen })
    if (checklistId == '') {
      return;
    } else {
      this.setState({ checklistDeleteId: checklistId })
    }

  }
  updateItem(item) {
    this.setState({ currentPage: item });
  }

  updateRecordsPerPage = (newSize) => {
    const parsedSize = parseInt(newSize, 10);
    if (!isNaN(parsedSize) && parsedSize > 0) {
      this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
    }
    this.setState({ pageSizeInput: newSize }); // Keep the input value updated
  };
  
  handlePageSizeInputChange = (event) => {
    const input = event.target.value;
    if (/^\d*$/.test(input)) { // Only allow digits
      this.setState({ pageSizeInput: input });
    }
  };

  record(checkList) {
    return (
      <table className="table table-bordered tb-action du-table">
        <thead>
          <tr>
            <th style={{ width: '60px' }} />
            <th style={{ textAlign: 'left' }} onClick={this.checklistNameSort}>Check List Name </th>
            <th style={{ width: '150px' }} onClick={this.shortNameSort}>Shortname</th>
            <th style={{ width: '250px' }} onClick={this.eqSort}>E/Q</th>
            <th style={{ width: '100px' }} onClick={this.statusSort}>Status</th>
          </tr>
        </thead>
        <tbody>
          {checkList.length > 0 ?
            checkList.map((data, index) => (

              <tr>
                <td className="text-center" style={{ padding: '5px!important' }}>
                  <div className="table__button-group">
                  {getModuleUpdateAccess(Master) ?
                    <a> <i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleUpdate(data.ChecklistID)} /></a>:
                    <a> <i className="fa fa-eye" style={{ color: '#3498db' }} onClick={() => this.handleUpdate(data.ChecklistID)} /></a>}
                    {data.isActive === 1 && getModuleDeleteAccess(Master)? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.ChecklistID)} /></span></a>
                      : <></>}
                  </div>
                </td>
                <td style={{ textAlign: 'left' }}>{data.ChecklistName}</td>
                <td>{data.Code}</td>
                <td>{data.EQName}</td>
                <td>{data.isActive === 1 ? "Active" : "Inactive"}</td>
              </tr>
            )) : <> <tr><td colSpan={5} className='text-center'>Record not found</td></tr></>
          }
        </tbody>
      </table>
    )
  }



  render() {
    const { isSearchTab, isAccessToView, checkList, tablePerPage,  isLoading, upperPageBound, checklistName, shortName, status,
      eqList, eq, checklistDeleteId, isOpen } = this.state
      const { currentPage, recordsPerPage, pageSizeInput } = this.state;

    // const recordsPerPage = 10
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    // Records to be displayed on the current page
    const currentRecords = checkList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(checkList.length / recordsPerPage)



    return (
      <>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          null
        )}
        <Header />
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper">
            {/* Left side menu */}
            <Drawer />
            {/* end left menu */}
            {/* start page */}
            {isAccessToView ? (
              <div className="main-panel main-box">
                <div className="content-wrapper">
                  <div className="main-breadcum">
                    <ul className="breadcrumb">
                      <li><a>Check List</a></li>
                    </ul>
                  </div>
                  <div className='page-space-from-bottom'>
                  <div className="row">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                    <div className="col-md-12 pg-body grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body pr-2">
                          

                        <div className="accordion accordion-flush " id="accordionFlushExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                              </button>
                            </h2>
                            { isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                              <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                <div className="d-flex flex-wrap row">
                                  <div className="form-group col-md-3">
                                    <label>Check List Name</label>
                                    <input type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={checklistName}
                                      onKeyDown={e => this.handleKeyDown(e)}
                                      onChange={(e) => this.setState({ checklistName: e.target.value })}
                                      required />
                                  </div>
                                  <div className="form-group col-md-3">
                                    <label>Shortname</label>
                                    <input type="text"
                                      className="form-control"
                                      placeholder=''
                                      value={shortName}
                                      onKeyDown={e => this.handleKeyDown(e)}
                                      onChange={(e) => this.setState({ shortName: e.target.value })}

                                      required />
                                  </div>
                                  <div className="form-group col-md-2">
                                    <label>E/Q</label>
                                    <select className="js-example-basic-single" style={{ width: '100%' }} value={eq} onChange={e => this.setState({ eq: e.target.value })}>
                                       <option value={''}>Select</option>
                                      {eqList.map(item => (
                                        <option
                                          key={item.EQID}
                                          value={item.EQID}>
                                          {item.Name}
                                        </option>
                                      ))}

                                    </select>
                                  </div>
                                  <div className="form-group col-md-2">
                                    <label>Status</label>
                                    <select className="js-example-basic-single"
                                      style={{ width: '100%' }}
                                      value={this.state.status}
                                      onChange={(e) => this.setState({ status: e.target.value })}>
                                       <option value={''}>Select</option>
                                      <option value={1}>Active</option>
                                      <option value={0}>Inactive</option>
                                    </select>
                                  </div>

                                  <div className="form-group col-md-2 filter-btn-col p-0">
                                      <label></label>
                                      <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={e => this.handleSearch()}> Search</button>
                                      <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3" onClick={e => this.clearValue()}> Clear</button>
                                    </div>
                                </div>
                              </div>
                            </div>  : <></>}
                          </div>
                        </div>

                 
                        <div className="row">
                          <div className="col-12">
                            {/* <button type="button" class="btn btn-Add rounded-0 m-1 mb-2"
                                style={{ lineHeight: '14px', padding: '7px', float: 'right' }}>Download</button> */}
                            {/* <button type="button" class="btn btn-print-audit rounded-0 m-1 mb-2"
                                style={{ lineHeight: '14px', padding: '7px', float: 'right' }}>Print</button> */}
                           {getModuleWriteAccess(Master)&& <button type="button" className="btn btn-Add rounded-1 m-1 mb-1 px-3"
                              style={{ lineHeight: '14px', padding: '7px', float: 'right' }} onClick={this.handleAdd}> Add</button>}
                            <div className="table-responsive tbs4 common-table trail-tb " style={{ width: '100%' }}>
                              {/* <table className="table table-bordered tb-action du-table overflow-auto" >
                                <thead>
                                  <tr>
                                    <th style={{ width: '60px' }} />
                                    <th>Check List Name</th>
                                    <th style={{ width: '150px' }}>Shortname</th>
                                    <th style={{ width: '250px' }}>E/Q</th>
                                    <th style={{ width: '100px' }}>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {renderTable} */}
                              {/* 
                                  {
                                    checkList.length != null ?
                                      checkList.map((data, index) => (
                                        <>
                                          <tr>
                                            <td className="text-center" style={{ padding: '5px!important' }}>
                                              <div className="table__button-group">
                                                <i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleUpdate(data.ChecklistID)} />
                                                <span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.ChecklistID)} /></span>
                                              </div>
                                            </td>
                                            <td>{data.ChecklistName}</td>
                                            <td>{data.Code}</td>
                                            <td>{data.EQName}</td>
                                            <td>{data.isActive === 1 ? "Active" : "Inactive"}</td>
                                          </tr></>
                                      )) : <>Record not found</>
                                  } */}

                              {/* </tbody>
                              </table> */}
                              {this.record(currentRecords)}
                              {checkList.length > 0 ? <Pagination
                                nPages={nPages}
                                currentPage={currentPage}
                                setCurrentPage={this.updateItem}
                                recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                 setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                   totalRecords={checkList.length}
                                 /> : <></>}

                            </div>

                          </div>
                        </div>
                  </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
          ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)}
          {/* end page */}
        </div>
        {/* page-body-wrapper ends */}
      </div >

        <ToastContainer />
    {/* // modal for delete */ }
    {/* <Modal
      isOpen={isOpen}
      onRequestClose={this.toggleModal}>
      <div className="modal-dialog custom_modal_dialog">
        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
          <div class="modal-header">
            <h4 class="modal-title modal_title_text">Confirm Delete</h4>
            <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <h3 class="pop_label">Do you really want to delete this record?</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group text-right mod-fot">
                <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, checklistDeleteId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </Modal> */}

<Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
          <div className="modal-dialog custom_modal_dialog">
            <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
              <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                </div>
              </div>
              <div class="modal-footer p-1">
                  <div class="form-group text-right">
                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, checklistDeleteId)}> Yes</a>
                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                </div>
              </div>
            </div>
          </div>
        </Modal>

    


      </>
    );
  }
}



export default withRouter(connect(null, { checkList_action, fill_action, deleteCheckList_action })(ChecklistList));