
import {
    ENGAGEMENT_ALLOCATION_ACTION_TYPE,
    ENGAGEMENT_ALLOCATION_DETAILS_ACTION_TYPE,
    ENGAGEMENT_ALLOCATION_ADD_ACTION_TYPE,
    ENGAGEMENT_ALLOCATION_UPDATE_ACTION_TYPE,
    ENGAGEMENT_ALLOCATION_DELETE_ACTION_TYPE
 } from "../../utils/constants"


const initialState = [];

function enagementAllocationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ENGAGEMENT_ALLOCATION_ACTION_TYPE:
      return [...state, payload];
      case ENGAGEMENT_ALLOCATION_DETAILS_ACTION_TYPE:
      return [...state, payload];
    case ENGAGEMENT_ALLOCATION_ADD_ACTION_TYPE:
      return [...state, payload];
    case ENGAGEMENT_ALLOCATION_UPDATE_ACTION_TYPE:
      return [...state, payload];
    case ENGAGEMENT_ALLOCATION_DELETE_ACTION_TYPE:
      return [...state, payload];
    default:
      return state;
  }
};

export default enagementAllocationReducer;


// export default (state = {}, action) => {
//     switch (action.type) {
//      case 'SIMPLE_ACTION':
//       return {
//        result: action.payload
//       }
//      default:
//       return state
//     }
//    }