import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    platfarm, headers,
    CLIENT_ENGAGEMENT_ACTION_TYPE,
    CLIENT_ENGAGEMENT_DETAILS_ACTION_TYPE,
    CLIENT_ENGAGEMENT_ADD_ACTION_TYPE,
    CLIENT_ENGAGEMENT_UPDATE_ACTION_TYPE,
    CLIENT_ENGAGEMENT_DELETE_ACTION_TYPE,
    CLIENT_ENGAGEMENT_CHECKLIST_ACTION_TYPE,
    clientEngagementUrl, clientEngagementDetailsUrl,
    addClientEngagementUrl, deleteClientEngagementUrl,
    updateClientEngagementUrl, fillListUrl, FILL_LIST_ACTION_TYPE, 
    clientCheckListUrl,fetchAuthReport,EngStatusReportName,
     EngClosureReportName, auditPlaneReportName,
     getDesignationDropdownlistClientEngagementUrl,
     CLIENT_ENGAGEMENT_DESIGNATION_LIST_ACTION_TYPE,

} from "../../utils/constants"
import moment from 'moment';


export const clientEngagement_action = (finYear, clientName, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientName: clientName,
            FinancialYear: finYear,
            status: status
        }   
       
        const res = await axiosGet(clientEngagementUrl, param);
        dispatch({
            type: CLIENT_ENGAGEMENT_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const clientEngagementDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            Id: id,
            userId: localStorage.getItem('UserId'),
            Platform: platfarm,
        }

        const res = await axiosGet(clientEngagementDetailsUrl, param);
        dispatch({
            type: CLIENT_ENGAGEMENT_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const clientCheckList_action = (name, shortName, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: name,
            shortName: shortName,
            Status: status
        }
        const res = await axiosGet(clientCheckListUrl, param);
        dispatch({
            type: CLIENT_ENGAGEMENT_CHECKLIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const addClientEngagement_action = (clientName, engagementTypeName,finYear,
    location,is_LocationBased,is_TimeBased,auditPeriodStartDate,auditPeriodToDate,
    startDate,endDate,expectedClosureDate,remark,teamList,checkListData,checklistTemplateID,EngagementAllocationID,comment,email) => async (dispatch) => {
        try {
            const mapdata = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                ClientID: parseInt(clientName),
                EngagementAllocationID : parseInt(EngagementAllocationID),
                LocationID: parseInt(location),
                IsLocationBased: is_LocationBased,
                IsTimeBased: is_TimeBased,
                AuditFromDate:  auditPeriodStartDate != 'Invalid date'  && auditPeriodStartDate != null &&  auditPeriodStartDate != '' &&
                  auditPeriodStartDate != 'Select'  ? moment(auditPeriodStartDate).format('MM-DD-YYYY') : '',
                   AuditToDate :  auditPeriodToDate != 'Invalid date'  && auditPeriodToDate != null &&  auditPeriodToDate != '' &&
                  auditPeriodToDate != 'Select'  ? moment(auditPeriodToDate).format('MM-DD-YYYY') : '',
                // AuditFromDate:  new Date(moment(auditPeriodStartDate ).format('MM-DD-YYYY')+'Z').toISOString(),
                // AuditToDate:  new Date(moment(auditPeriodToDate ).format('MM-DD-YYYY')+'Z').toISOString(),
                // StartDate:  new Date(moment(startDate ).format('MM-DD-YYYY')+'Z').toISOString(),
                // EndDate:  new Date(moment(endDate).format('MM-DD-YYYY')+'Z').toISOString(),

                StartDate:  startDate != 'Invalid date'  && startDate != null &&  startDate != '' &&
                startDate != 'Select'  ? moment(startDate).format('MM-DD-YYYY') : '',
                EndDate :  endDate != 'Invalid date'  && endDate != null &&  endDate != '' &&
                endDate != 'Select'  ? moment(endDate).format('MM-DD-YYYY') : '',

                // ExpectedClosureDate: new Date(moment(expectedClosureDate ).format('MM-DD-YYYY')+'Z').toISOString(),
                ExpectedClosureDate:  expectedClosureDate != 'Invalid date'  && expectedClosureDate != null &&  expectedClosureDate != '' &&
                expectedClosureDate != 'Select'  ? moment(expectedClosureDate).format('MM-DD-YYYY') : '',

                ChecklistTemplateID: parseInt(checklistTemplateID) ,
                Remark: remark,

                teamMemberList: teamList,
                engagementCheckList: checkListData,
                comment : comment,
                isEmail: email
            }
            const res = await axiosPost(addClientEngagementUrl, mapdata, headers);

            dispatch({
                type: CLIENT_ENGAGEMENT_ADD_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const updateClientEngagement_action = (id,clientName, engagementTypeName,finYear,
    location,is_LocationBased,is_TimeBased,auditPeriodStartDate,auditPeriodToDate,
    startDate,endDate,expectedClosureDate,remark,teamList,checkListData,checklistTemplateID,EngagementAllocationID,comment,email) => async (dispatch) => {
    try {
        console.log('updateClientEngagement_action  location', location )
        console.log('updateClientEngagement_action  location', parseInt(location) )

        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID  : id,
            ClientID: parseInt(clientName),
            EngagementAllocationID : parseInt(EngagementAllocationID),
            LocationID: parseInt(location),
            IsLocationBased: is_LocationBased,
            IsTimeBased: is_TimeBased,

            AuditFromDate:  auditPeriodStartDate != 'Invalid date'  && auditPeriodStartDate != null &&  auditPeriodStartDate != '' &&
            auditPeriodStartDate != 'Select'  ? moment(auditPeriodStartDate).format('MM-DD-YYYY') : '',
             AuditToDate :  auditPeriodToDate != 'Invalid date'  && auditPeriodToDate != null &&  auditPeriodToDate != '' &&
            auditPeriodToDate != 'Select'  ? moment(auditPeriodToDate).format('MM-DD-YYYY') : '',

            // AuditFromDate:  new Date(moment(auditPeriodStartDate ).format('MM-DD-YYYY')+'Z').toISOString(),
            // AuditToDate:  new Date(moment(auditPeriodToDate ).format('MM-DD-YYYY')+'Z').toISOString(),
            // StartDate:  new Date(moment(startDate ).format('MM-DD-YYYY')+'Z').toISOString(),
            // EndDate:  new Date(moment(endDate).format('MM-DD-YYYY')+'Z').toISOString(),
            StartDate:  startDate != 'Invalid date'  && startDate != null &&  startDate != '' &&
            startDate != 'Select'  ? moment(startDate).format('MM-DD-YYYY') : '',
            EndDate :  endDate != 'Invalid date'  && endDate != null &&  endDate != '' &&
            endDate != 'Select'  ? moment(endDate).format('MM-DD-YYYY') : '',

            // ExpectedClosureDate: new Date(moment(expectedClosureDate ).format('MM-DD-YYYY')+'Z').toISOString(),
            ExpectedClosureDate:  expectedClosureDate != 'Invalid date'  && expectedClosureDate != null &&  expectedClosureDate != '' &&
            expectedClosureDate != 'Select'  ? moment(expectedClosureDate).format('MM-DD-YYYY') : '',

            
            ChecklistTemplateID: parseInt(checklistTemplateID) ,
            Remark: remark,

            teamMemberList: teamList,
            engagementCheckList: checkListData,
            comment : comment,
            isEmail: email
        }
        const res = await axiosPost(updateClientEngagementUrl, mapdata, headers);

        dispatch({
            type: CLIENT_ENGAGEMENT_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const deleteClientEngagement_action = (id) => async (dispatch) => {
    try {
        const param = {
            Id: id,
            userId: localStorage.getItem('UserId'),
            Platform: platfarm,

        }
        const res = await axiosGet(deleteClientEngagementUrl, param);
        dispatch({
            type: CLIENT_ENGAGEMENT_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const designationDropdownList_action = () => async (dispatch) => {
    try {
        const res = await axiosGet(getDesignationDropdownlistClientEngagementUrl);
        dispatch({
            type: CLIENT_ENGAGEMENT_DESIGNATION_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const downloadPlane_action = (id) => async (dispatch) => {
    try {
        var list = [];
        const mapdata1 = {
            "ParameterName":"@id",
            "ParameterValue":id,
            "ParameterDataType":"int"
        }
        
        list.push(mapdata1);

        const mapData = {
            "user_id": localStorage.getItem('UserId'),
            "report_name": auditPlaneReportName,
            "parameter_details": list
        }

        const res = await axiosPost(fetchAuthReport, mapData);

        dispatch({
            type: CLIENT_ENGAGEMENT_CHECKLIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const downloadStatus_action = (id,statusid) => async (dispatch) => {
    try {
        var list = [];
        const mapdata1 = {
            "ParameterName":"@id",
            "ParameterValue":id,
            "ParameterDataType":"int"
        }
        
        list.push(mapdata1);

        const mapData = {
            "user_id": localStorage.getItem('UserId'),
            "report_name": statusid != '2' ? EngStatusReportName : EngClosureReportName,
            "parameter_details": list
        }

        const res = await axiosPost(fetchAuthReport, mapData);

        dispatch({
            type: CLIENT_ENGAGEMENT_CHECKLIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const fill_action = () => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
        }
        const res = await axiosGet(fillListUrl, param);
        dispatch({
            type: FILL_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


