import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashRouter as Switch, Route, Router, Routes } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Login from "./screens/user_auth/login";
import Dashboard from "./screens/dashboard/dashboard";
import FinancialYearList from "./screens/master/financial_year/financial_year_list";
import FinancialYearAU from "./screens/master/financial_year/financial_year_au";
import ChecklistList from './screens/master/checklist/checklist_list'
import ChecklistAU from './screens/master/checklist/checklist_au'
import ChecklistListTemplate from './screens/master/checklist_template/checklist_template_list'
import ChecklistTemplateAU from './screens/master/checklist_template/checklist_template_au'
import EngagementAllocationList from './screens/engagement/engagement_allocation/engagement_allocation_list'
import EngagementAllocationAU from './screens/engagement/engagement_allocation/engagement_allocation_au'
import ClientEngagement from './screens/engagement/client_engagement/client_engagement_list'
import ClientEngagementAU from './screens/engagement/client_engagement/client_engagement_au'
import UpdateChecklistU from './screens/engagement/update_checklist/update_checklist_u'
import UpdateChecklist from './screens/engagement/update_checklist/update_checklist_list'
import ReviewChecklist from './screens/engagement/review_checklist/review_checklist_list'
import ReviewChecklistU from './screens/engagement/review_checklist/review_checklist_u'
import CloserChecklist from './screens/engagement/closer_checklist/closer_checklist_list'
import CloserChecklistU from './screens/engagement/closer_checklist/closer_checklist_u'
import ChangePassword from './screens/user_auth/changePassword'
import Employee_list from './screens/master/employee/employee_list';
import EmployeeAU from './screens/master/employee/employee_au';
import TimeSheetList from './screens/time_expense/time_sheet/time_sheet_list'
import TimeSheetU from './screens/time_expense/time_sheet/time_sheet_au'
import ExpenseList from './screens/time_expense/expense/expense_list'
import ExpenseAU from './screens/time_expense/expense/expense_au';
import ClientList from './screens/master/client/client_list'
import ClientMainAU from './screens/master/client/client_master_au/client_main'
import ClientMasterAU from './screens/master/client/client_master_au/client_master'
import ClientAddressAU from './screens/master/client/client_master_au/client_address'
import EngagementMaster from './screens/master/engagement_master/engagement_master_list'
import EngagementMasterAU from './screens/master/engagement_master/engagement_master_au'
import Department from './screens/master/department/department'
import Designation from './screens/master/designation/designation'
import BloodGroup from './screens/master/bloodGroup/bloodGroup'
import ClientGroup from './screens/master/clientGroup/clientGroup'
import TaskType from './screens/master/taskType/taskType';

import HolidayListAU from './screens/master/holiday/holiday_list_and_au'
import Approval_list from './screens/time_expense/approval/approval_list';
import Approval_u from './screens/time_expense/approval/approval_u';
import WorkingDaysList from './screens/master/working_days/working_days_list'
import WorkingDaysU from './screens/master/working_days/working_days_u'
import AddressTypeListAU from './screens/master/address_type/address_type_list_and_au'
import ClientCategoryListAU from './screens/master/client_category/client_category_list_and_au'
import ExpenseHeadListAU from './screens/master/expense_head/expense_head_list_and_au';
import LeaveList from './screens/time_expense/leave/leave_list'
import LeaveAU from './screens/time_expense/leave/leave_au'
import LeaveApproval_List from './screens/time_expense/leaveApproval/leaveApproval_List';
import LeaveApproval_Details from './screens/time_expense/leaveApproval/leaveApproval_Details';
import LocationType from './screens/master/location_type/locationType';
import ManagementType from './screens/master/management_type/managementType';
import EngagementRoleList from './screens/master/engagementRole/engagementRole_list';
import EngagementRoleAU from './screens/master/engagementRole/engagementRole_au';
import MartialStatusListAU from './screens/master/martial_status/martial_status_list_and_au';
import TrainingList from './screens/training/training_list';
import TrainingAU from './screens/training/training_au';
import TrainingApprovalReject from './screens/training/training_approval_reject_list';
import TrainingReportFirmWise from './screens/training/report/firm_wise_training';
import TrainingReportEmployee from './screens/training/report/employee_training';
import PrefixListAU from './screens/master/prefix/prefix';
import GenderListAU from './screens/master/gender/gender';
import LegalEntityListAU from './screens/master/legal_entity/legal_entity';

import TimesheetEmployeeSummaryReport from './screens/reports/timesheet_emp_summary_report';
import TimesheetClientSummaryReport from './screens/reports/timesheet_client_summary_report';
import EmpClientSummaryReport from './screens/reports/emp_client_summary_report';
import ExpenseEmpSummaryReport from './screens/reports/expense_emp_summary_report';
import ExpenseClientSummaryReport from './screens/reports/expense_client_summary_report';
import TimesheetDetailsReport from './screens/reports/timesheet_details_report';
import ClientDetailsReport from './screens/reports/client_details_report';
import ClientEngDetailsReport from './screens/reports/client_eng_details_report';
import LeaveReport from './screens/reports/leave_report';
import EmpLeaveReport from './screens/reports/emp_leave_report';
import EmployeeHasEnteredTimesheetReport from './screens/reports/emp_has_entered_timesheet_report';
import UserProfileReport from './screens/reports/user_profile_report';

import MCQChapterList from './screens/mcq/mcq_chapter_master/mcq_chapter_list';
import MCQChapterAU from './screens/mcq/mcq_chapter_master/mcq_chapter_add';
import MCQSetupList from './screens/mcq/mcq_setup/mcq_setup_list';
import MCQSetupAU from './screens/mcq/mcq_setup/mcq_setup_AU';
import SettingAU from './screens/mcq/setting/settingAU';
import SettingList from './screens/mcq/setting/setting_list';

import TakeTest from './screens/mcq/test/take_test';
import StartTest from './screens/mcq/test/start_test';
import HomeTest from './screens/mcq/test/home_test';
import DesignationAu from './screens/master/designation/designationAu';
// import PreventBackNavigation from './utils/usePreventNavigation';

function App() {

  return (
    <div className="App">
      <div className="auth-inner">
    
        <Switch>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/changePassword" element={<ChangePassword />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/finYearList" element={<FinancialYearList />} />
            <Route exact path="/finYearAU" element={<FinancialYearAU />} />
            <Route exact path="/checkList" element={<ChecklistList />} />
            <Route exact path="/checkListAU" element={<ChecklistAU />} />
            <Route exact path="/checkListTemplate" element={<ChecklistListTemplate />} />
            <Route exact path="/checkListTemplateAU" element={<ChecklistTemplateAU />} />
            <Route exact path="/engagementAllocation" element={<EngagementAllocationList />} />
            <Route exact path="/engagementAllocationAU" element={<EngagementAllocationAU />} />
            <Route exact path="/clientEngagement" element={<ClientEngagement />} />
            <Route exact path="/clientEngagementAU" element={<ClientEngagementAU />} />
            <Route exact path="/updateChecklistU" element={<UpdateChecklistU />} />
            <Route exact path="/updateChecklist" element={<UpdateChecklist />} />
            <Route exact path="/reviewChecklist" element={<ReviewChecklist />} />
            <Route exact path="/reviewChecklistU" element={<ReviewChecklistU />} />
            <Route exact path="/closerChecklist" element={<CloserChecklist />} />
            <Route exact path="/closerChecklistU" element={<CloserChecklistU />} />
            <Route exact path="/employeeList" element={<Employee_list />} />
            <Route exact path="/employeeAU" element={<EmployeeAU />} />
            <Route exact path='/department' element={< Department />} />
            <Route exact path='/designation' element={< Designation />} />
            <Route exact path='/bloodGroup' element={< BloodGroup />} />
            <Route exact path='/clientGroup' element={< ClientGroup />} />
            <Route exact path='/taskType' element={< TaskType />} />
            <Route exact path='/locationType' element={< LocationType />} />
            <Route exact path='/managementType' element={< ManagementType />} />
            <Route exact path='/EngagementRoleList' element={< EngagementRoleList />} />
            <Route exact path='/EngagementRoleAU' element={< EngagementRoleAU />} />


            {/* // client master */}
            <Route exact path="/clientList" element={<ClientList />} />
            <Route exact path="/clientMainAU" element={<ClientMainAU />} />
            <Route exact path="/ClientMasterAU" element={<ClientMasterAU />} />
            <Route exact path="/ClientAddressAU" element={<ClientAddressAU />} />
            {/* engagement master */}
            <Route exact path="/engagementMaster" element={<EngagementMaster />} />
            <Route exact path="/engagementMasterAU" element={<EngagementMasterAU />} />
            {/* Holiday */}
            <Route exact path="/holidayListAU" element={<HolidayListAU />} />
            {/* Working Days */}
            <Route exact path="/workingDaysList" element={<WorkingDaysList />} />
            <Route exact path="/workingDaysU" element={<WorkingDaysU />} />
            {/* Address Type */}
            <Route exact path="/addressTypeListAU" element={<AddressTypeListAU />} />
            {/* client Category */}
            <Route exact path="/clientCategoryListAU" element={<ClientCategoryListAU />} />
            {/* expense head list */}
            <Route exact path="/expenseHeadListAU" element={<ExpenseHeadListAU />} />
            {/* martial status */}
            <Route exact path="/martialStatusListAU" element={<MartialStatusListAU />} />
            {/* // time sheet and expense */}
            <Route exact path="/timeSheet" element={<TimeSheetList />} />
            <Route exact path="/timeSheetU" element={<TimeSheetU />} />
            <Route exact path="/expense" element={<ExpenseList />} />
            <Route exact path="/expenseU" element={<ExpenseAU />} />
            <Route exact path="/approval" element={<Approval_list />} />
            <Route exact path="/approvalU" element={<Approval_u />} />
            <Route exact path="/leaveList" element={<LeaveList />} />
            <Route exact path="/leaveAU" element={<LeaveAU />} />
            <Route exact path="/leaveApproval" element={<LeaveApproval_List />} />
            <Route exact path="/leaveApprovalDetails" element={<LeaveApproval_Details />} />
            {/* <Route exact path="/approval_U" element={<Approval_u/>} /> */}
            <Route exact path="/trainingList" element={<TrainingList />} />
            <Route exact path="/trainingAU" element={<TrainingAU />} />
            <Route exact path="/trainingApprovalReject" element={<TrainingApprovalReject />} />
            <Route exact path="/trainingReportFirmWise" element={<TrainingReportFirmWise />} />
            <Route exact path="/trainingReportEmployee" element={<TrainingReportEmployee />} />
            <Route exact path="/prefixListAU" element={<PrefixListAU />} />
            <Route exact path="/genderListAU" element={<GenderListAU />} />
            <Route exact path="/legalEntityListAU" element={<LegalEntityListAU />} />

            <Route exact path="/timesheetEmployeeSummaryReport" element={<TimesheetEmployeeSummaryReport />} />
            <Route exact path="/timesheetClientSummaryReport" element={<TimesheetClientSummaryReport />} />
            <Route exact path="/empClientSummaryReport" element={<EmpClientSummaryReport />} />
            <Route exact path="/expenseEmpSummaryReport" element={<ExpenseEmpSummaryReport />} />
            <Route exact path="/leaveReport" element={<LeaveReport />} />
            <Route exact path="/empLeaveReport" element={<EmpLeaveReport />} />

            <Route exact path="/expenseClientSummaryReport" element={<ExpenseClientSummaryReport />} />
            <Route exact path="/timesheetDetailsReport" element={<TimesheetDetailsReport />} />
            <Route exact path="/clientDetailsReport" element={<ClientDetailsReport />} />
            <Route exact path="/clientEngDetailsReport" element={<ClientEngDetailsReport />} />
            <Route exact path="/employeeHasEnteredTimesheetReport" element={<EmployeeHasEnteredTimesheetReport />} />
            <Route exact path="/userProfileReport" element={<UserProfileReport />} />

            <Route exact path="/mcqchapterlist" element={<MCQChapterList />} />
            <Route exact path="/mcqchapterAU" element={<MCQChapterAU />} />
            <Route exact path="/mcqsetupList" element={<MCQSetupList />} />
            <Route exact path="/mcqsetupAU" element={<MCQSetupAU />} />
            <Route exact path="/SettingAU" element={<SettingAU />} />
            <Route exact path="/SettingList" element={<SettingList />} />
            <Route exact path="/takeTest" element={<TakeTest />} />
            <Route exact path="/startTest" element={<StartTest />} />
            <Route exact path="/startTest/:id" element={<StartTest />} />
            <Route exact path="/homeTest" element={<HomeTest />} />
            <Route exact path="/designationAU" element={<DesignationAu />} />


          </Routes>
        </Switch>
      </div>
    </div>

  );
}

export default App;