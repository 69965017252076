
import { LOCATIONTYPE_ADD_ACTION_TYPE, LOCATIONTYPE_DELETE_ACTION_TYPE, LOCATIONTYPE_DETAILS_ACTION_TYPE, LOCATIONTYPE_LIST_ACTION_TYPE, LOCATIONTYPE_UPDATE_ACTION_TYPE } from "../../utils/constants";

const initialState = []

function locationTypeReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case LOCATIONTYPE_LIST_ACTION_TYPE:
            return [...state, payload];
        case LOCATIONTYPE_ADD_ACTION_TYPE:
            return [...state, payload];
        case LOCATIONTYPE_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case LOCATIONTYPE_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case LOCATIONTYPE_DELETE_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}

export default locationTypeReducer