import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {platfarm, headers,
    ENGAGEMENT_ALLOCATION_ACTION_TYPE ,
    ENGAGEMENT_ALLOCATION_DETAILS_ACTION_TYPE ,
    ENGAGEMENT_ALLOCATION_ADD_ACTION_TYPE ,
    ENGAGEMENT_ALLOCATION_UPDATE_ACTION_TYPE ,
    ENGAGEMENT_ALLOCATION_DELETE_ACTION_TYPE ,
    engagementAllocationUrl ,engagementAllocationDetailsUrl ,
    addEngagementAllocationUrl,  deleteEngagementAllocationUrl,
    updateEngagementAllocationUrl,fillListUrl, FILL_LIST_ACTION_TYPE,fetchAuthReport,engAllocationReportName

} from "../../utils/constants"
import moment from 'moment';




export const engagementAllocation_action = (clientName,engPartnerName,qualityReviewerName,engagementTypeName,status) => async (dispatch) => {
    try {   
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientName: clientName,
            EngPartnerName: engPartnerName,
            QualityreviewerName: qualityReviewerName,
            EngagementTypeName: engagementTypeName,
            Status: status
        }
        const res = await axiosGet(engagementAllocationUrl, param);
        dispatch({
            type: ENGAGEMENT_ALLOCATION_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const engagementAllocationDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            Id: id,
            userId: localStorage.getItem('UserId'),
            Platform: platfarm,
        }        

        const res = await axiosGet(engagementAllocationDetailsUrl, param);
        dispatch({
            type: ENGAGEMENT_ALLOCATION_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const addEngagementAllocation_action = (finYear, startDate,endDate,engPartnerName,
    engagementTypeName,qualityReviewerName,remark,
    clientNameList,email) => async (dispatch) => {
        console.log("clientNameList", clientNameList)
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            FinYearID: finYear,
            // StartDate: startDate,
            // EndDate: endDate,
            StartDate:  startDate != 'Invalid date'  && startDate != null &&  startDate != '' &&
            startDate != 'Select'  ? moment(startDate).format('MM-DD-YYYY') : '',
            EndDate :  endDate != 'Invalid date'  && endDate != null &&  endDate != '' &&
            endDate != 'Select'  ? moment(endDate).format('MM-DD-YYYY') : '',

            EngagementPartnerID: engPartnerName,
            QualityReviewerID : qualityReviewerName,
            EngagementTypeID: engagementTypeName,
            clientNameList : clientNameList,
            Remark: remark,
            isEmail: email
        }
        const res = await axiosPost(addEngagementAllocationUrl, mapdata, headers);

        dispatch({
            type: ENGAGEMENT_ALLOCATION_ADD_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateEngagementAllocation_action = (id ,finYear, startDate,endDate,engPartnerName,
    engagementTypeName,qualityReviewerName,remark,clientNameList,email) => async (dispatch) => {
    console.log("clientNameList", clientNameList)
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            id : id,
            FinYearID: finYear,
            // StartDate: startDate,
            // EndDate: endDate,
            StartDate:  startDate != 'Invalid date'  && startDate != null &&  startDate != '' &&
            startDate != 'Select'  ? moment(startDate).format('MM-DD-YYYY') : '',
            EndDate :  endDate != 'Invalid date'  && endDate != null &&  endDate != '' &&
            endDate != 'Select'  ? moment(endDate).format('MM-DD-YYYY') : '',

            EngagementPartnerID: engPartnerName,
            QualityReviewerID : qualityReviewerName,
            EngagementTypeID: engagementTypeName,
            clientNameList : clientNameList,
            Remark: remark,
            isEmail: email
        }
        const res = await axiosPost(updateEngagementAllocationUrl, mapdata, headers);

        dispatch({
            type: ENGAGEMENT_ALLOCATION_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const deleteEngagementAllocation_action = (id) => async (dispatch) => {
    try {
        const param = {
            Id: id,
            userId: localStorage.getItem('UserId'),
            Platform: platfarm,

        }
        const res = await axiosGet(deleteEngagementAllocationUrl, param);
        dispatch({
            type: ENGAGEMENT_ALLOCATION_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const engagementAllocationDownload_action = (clientName,engPartnerName,qualityReviewerName,engagementTypeName,status) => async (dispatch) => {
    try {   
        var header = {
            "Access-Control-Allow-Origin": "*"
        };
        var list = [];
        const mapdata1 = {
            "ParameterName":"@ClientName",
            "ParameterValue":clientName,
            "ParameterDataType":"varchar"
        }
        const mapdata2 = {
            "ParameterName":"@EngagementPartner",
            "ParameterValue":engPartnerName,
            "ParameterDataType":"varchar"
        }
        const mapdata3 = {
            "ParameterName":"@QualityReviewer",
            "ParameterValue":qualityReviewerName,
            "ParameterDataType":"varchar"
        }
        const mapdata4 = {
            "ParameterName":"@EngagementTypeName",
            "ParameterValue":engagementTypeName,
            "ParameterDataType":"varchar"
        }
        const mapdata5 = {
            "ParameterName":"@Statusid",
            "ParameterValue":status,
            "ParameterDataType":"int"
        }
        
        
        list.push(mapdata1);
        list.push(mapdata2);
        list.push(mapdata3);
        list.push(mapdata4);
        list.push(mapdata5);
        

        const mapData = {
            "user_id": localStorage.getItem('UserId'),
            "report_name": engAllocationReportName,
            "parameter_details": list
        }

        const res = await axiosPost(fetchAuthReport, mapData,header);

        dispatch({
            type: ENGAGEMENT_ALLOCATION_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const fill_action = () => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
        }
        const res = await axiosGet(fillListUrl, param);
        dispatch({
            type: FILL_LIST_ACTION_TYPE,
            payload: res,
        });
    
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};



export const simpleAction = () => dispatch => {
    dispatch({
     type: 'SIMPLE_ACTION',
     payload: 'result_of_simple_action'
    })
   }