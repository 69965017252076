import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import {
  addCheckList_action, updateCheckList_action,
  deleteCheckList_action, checkListDetails_action, fill_action
} from '../../../actions/master_action/checklist_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from "react-router-dom";
import { withRouter } from '../../../utils/withRouter';
import Loader from '../../../utils/Loader'
import { eqListId, Master } from '../../../utils/constants'
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
// const mapStateToProps = state => ({
//   ...state
// })

class ChecklistAU extends Component {
  constructor(props) {
    super(props)
    this.state = {
      TypeOfEng: '',
      checkListName: '',
      shortName: '',
      is_add: false,
      is_delete: false,
      is_update: false,
      eqStatus: 0,
      checklistId: '',
      eqList: [],
      eq: '',
      isLoading: false,
      isActive: 0,
      isAccessToView: true,
    }
    this.handleAdd = this.handleAdd.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.getCheckListDetails = this.getCheckListDetails.bind(this)
    this.getEQList = this.getEQList.bind(this)


  }

  // checklist details 
  componentDidMount = () => {
    this.setState({
      is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
      is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
      is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
      checklistId: this.props.location.state != null && this.props.location.state.checklistId != null ? this.props.location.state.checklistId : '',
      isAccessToView: true,
    })

    this.getEQList();
    this.props.location.state != null && this.props.location.state.is_update && this.getCheckListDetails()
    // document.addEventListener('keydown', this.handleTabKeyPress);
    // document.addEventListener('click', this.handleInputClick);

  }

  componentWillUnmount() {
      // document.removeEventListener('keydown', this.handleTabKeyPress);
      // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
      if (event.key === 'Tab' || event.key === 'Backspace') {
        const focusedElement = document.activeElement;
        const { previouslyFocusedElement } = this.state;
  
        if (previouslyFocusedElement) {
          previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }
  
        if (focusedElement.classList.contains('form-control') ||
          focusedElement.classList.contains('js-example-basic-single') ||
          focusedElement.classList.contains('example-custom-input')) {
          focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
          this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
        }
      }
    };
  
  
    handleInputClick = (event) => {
      const { previouslyFocusedElement } = this.state;
      const clickedElement = event.target;
  
      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }
  
      if (clickedElement.classList.contains('form-control') ||
        clickedElement.classList.contains('js-example-basic-single') ||
        clickedElement.classList.contains('example-custom-input')) {
        clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
        this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
      } else {
        this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
      }
    };

  // CheckList
  getCheckListDetails() {
    var id = this.props.location.state.checklistId
    this.setState({ isLoading: true })
    this.props
      .checkListDetails_action(id)
      .then((data) => {
        if (data.error != 1) {
          this.setState({
            TypeOfEng: data.data[0]['TypeOfEngagement'],
            checkListName: data.data[0]['ChecklistName'],
            shortName: data.data[0]['Code'],
            eq: data.data[0]['EQID'],
            isActive: data.data[0]['IsActive'],
            isLoading: false
          })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  // eq list  
  getEQList() {
    this.setState({ isLoading: true })
    this.props
      .fill_action(eqListId, '')
      .then((data) => {
        if (data.error != 1) {
          this.setState({ eqList: data.data, isLoading: false })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }

  // add list 
  handleAdd(e) {
    e.preventDefault()
    const { TypeOfEng, checkListName, shortName, eq } = this.state
    // eqStatus: 1,

    this.setState({ isLoading: true })
    if (TypeOfEng != "" && checkListName != "" && shortName != "" && (eq != "" && eq != "Select")) {
      this.props
        .addCheckList_action(TypeOfEng, checkListName.trim(), shortName.trim(), eq)
        .then((data) => {
          if (data.error != 1) {
            toast.success('Data added successfully', {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
            this.setState({ isLoading: false })
            this.handleCancel()
          }
          else {
            this.setState({ isLoading: false })
            toast.error(data.msg, {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })

          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    else {
      this.setState({ isLoading: false })
      toast.error('Please enter mandatory field', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: true,
      })
    }



  }

  // update list
  handleUpdate(e) {
    e.preventDefault()
    const { TypeOfEng, checkListName, shortName, eq } = this.state
    const id = this.state.checklistId;
    if (id != "" && TypeOfEng != "" && checkListName != "" && shortName != "" && (eq != "" && eq != "Select")) {
      this.setState({ isLoading: true })
      this.props
        .updateCheckList_action(id, TypeOfEng, checkListName.trim(), shortName.trim(), eq)
        .then((data) => {
          if (data.error != 1) {
            toast.success('Data updated successfully', {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
            this.setState({ isLoading: false })
            this.handleCancel()
          }
          else {
            this.setState({ isLoading: false })
            toast.error(data.msg, {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    else {
      this.setState({ isLoading: false })
      toast.error('Please enter mandatory field', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: true,
      })
    }

  }

  // delete list
  handleDelete(e) {
    e.preventDefault()
    var id = this.state.checklistId;
    this.setState({ isLoading: true })
    this.props
      .deleteCheckList_action(id)
      .then((data) => {
        if (data.error != 1) {
          toast.success('Data deleted successfully', {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
          this.setState({ isLoading: false })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }

  // clear field 
  handleCancel() {
    this.setState({
      checkListName: '',
      shortName: '',
      eq: ''
    })
    this.props.navigate('/checkList')
  }


  render() {
    const { eqList, eq, isLoading, isActive, is_update, isAccessToView } = this.state
    return (
      <>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          null
        )}

        <Header />
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper">
            {/* Left side menu */}
            <Drawer />
            {/* end left menu */}
            {/* start page */}
            {isAccessToView ? (
              <div className="main-panel main-box">
                <div className="content-wrapper">
                  <div className="main-breadcum">
                    <ul className="breadcrumb">
                      <li>
                        <a onClick={this.handleCancel}>Checklist</a>
                      </li>
                      <li>
                        <a >{this.state.is_add ? 'New' : 'Update'}</a>
                      </li>
                      <li />
                    </ul>
                  </div>
                  <div className="user-status">
                    {
                      is_update ? <p>
                        <strong className={isActive === 1 ? 'greena' : 'redi'}> {isActive === 1 ? 'Active' : 'Inactive'}</strong>
                      </p> : <></>
                    }

                  </div>
                  <div className="row">
                    <style
                      type="text/css"
                      dangerouslySetInnerHTML={{
                        __html:
                          "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                      }}
                    />
                    <div className="col-md-12 pg-body grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">

                          <div className="d-flex flex-wrap row ">
                            <div className="form-group col-md-3">
                              <label>
                                Type Of Engagement<span className="required1">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder='EA,BA,TA...'
                                maxLength={3}
                                value={this.state.TypeOfEng}
                                onChange={(e) => this.setState({ TypeOfEng: e.target.value.toUpperCase() })}
                                required
                              />
                            </div>
                            <div className="form-group col-md-3">
                              <label>
                                Checklist Name<span className="required1">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder='CheckList Name'
                                value={this.state.checkListName}
                                onChange={(e) => this.setState({ checkListName: e.target.value })}
                                required
                                maxLength={100}
                              // style={{ padding: '3px', margin: '3px', boxSizing: 'border-box' }} // Add CSS directly here

                              />
                            </div>
                            <div className="form-group col-md-3">
                              <label>
                                Shortname<span className="required1">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control1"
                                placeholder='Shortname'
                                value={this.state.shortName}
                                onChange={(e) => this.setState({ shortName: e.target.value })}
                                required
                                maxLength={30}
                              // Add CSS directly here

                              />
                            </div>
                            <div className="form-group col-md-3">
                              <label>E/Q<span className="required1">*</span></label>
                              <select className="js-example-basic-single" style={{ width: '100%' }} value={eq} onChange={e => this.setState({ eq: e.target.value })}>
                                {/* this.setState({ eq: e.target.value }) */}
                                 <option value={''}>Select</option>
                                {eqList.length > 0 ? eqList.map(item => (
                                  <option
                                    key={item.EQID}
                                    value={item.EQID}>
                                    {item.Name}
                                  </option>
                                )) : <></>}

                              </select>
                            </div>
                          </div>

                          {/* <div class="d-flex flex-wrap row">
                          <div class="form-group col-md-3">
                            <label>E/Q <span class="required1">*</span></label>
                            <select class="js-example-basic-single" style="width: 100%;" value={this.state.eqStatus} onChange={() => this.handleStatus}>
                               <option value={''}>Select</option>
                              <option value={0}>Engagement</option>
                              <option value={1}>Quality</option>
                            </select>
                          </div>

                        </div> */}
                          <div className="row">
                            <div className="footer-btn">
                              <div className="col-md-3">
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Basic example"
                                >
                                  {
                                    this.state.is_add && getModuleWriteAccess(Master) && <button
                                      type="button"
                                      className="btn btn-save footer-btn-inner mr-1"
                                      onClick={this.handleAdd}
                                    >
                                      Save
                                    </button>
                                  }
                                  {
                                    this.state.is_update && isActive === 1 && getModuleUpdateAccess(Master) ?
                                      <button
                                        type="button"
                                        className="btn btn-update footer-btn-inner mr-1"
                                        onClick={this.handleUpdate}
                                      >
                                        Update
                                      </button> : <></>
                                  }

                                  {
                                    this.state.is_delete && getModuleDeleteAccess(Master) &&
                                    <button
                                      type="button"
                                      className="btn btn-delete footer-btn-inner mr-1"
                                      onClick={this.handleDelete}
                                    >
                                      Delete
                                    </button>
                                  }

                                  <button
                                    type="button"
                                    className="btn btn-cancel footer-btn-inner"
                                    onClick={this.handleCancel}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end
   */}
              </div>
            ) : (<div className='access-msg h-auto'>
              <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
            </div>)}
            {/* end page */}
          </div>
          {/* page-body-wrapper ends */}
        </div>
        <ToastContainer />
      </>
    );
  }
}


export default withRouter(connect(null, {
  addCheckList_action, checkListDetails_action, updateCheckList_action, deleteCheckList_action
  , fill_action
})(ChecklistAU));