import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import Drawer from '../../common_components/Drawer';
import { fill_action } from '../../actions/master_action/checklist_action'
import {
    trainingList_action,
    addtraining_action,
    deletetraining_action,
} from '../../actions/training_action/training_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import Modal from "react-modal";
import {
    courseTypeActionId, clearLocalStorage,
    trainingStatusActionId, trainingApprovedByActionId, trainingEmployeeActionId,
    Traning_Entry
} from '../../utils/constants'
import Pagination from '../../utils/pagination'
import Loader from '../../utils/Loader'
import moment from 'moment';
import Workbook from 'react-excel-workbook'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getModuleDeleteAccess, getModuleWriteAccess } from '../../utils/RoleAccess';
/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
    // loginAction: () => dispatch(loginAction())
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));


class TrainingList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            status: ' ',
            isOpen: false,
            TrainingListDeleteId: '',
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage: 10,
            pageSizeInput: "10",
            employeeId: '',
            employeeList: [],
            courseDetails: '',
            approvalDate: '',
            trainingFromDate: '',
            trainingEndDate: '',
            courseTypeId: '',
            courseTypeList: [],
            approvedById: '',
            approvedByList: [],
            statusId: '',
            statusList: [],
            TrainingList: [],
            // local storage
            trainingStartDateTLS: '',
            trainingEndDateTLS: '',
            courseTypeTLS: '',
            courseDetailsTLS: '',
            employeeTLS: '',
            statusIdTLS: '',
            approvedByTLS: '',
            approvedDateTLS: '',

            isSearchTab: false,
        }
        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.getTrainingList = this.getTrainingList.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);
        this.record = this.record.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    componentDidMount = () => {
        this.getFillList(courseTypeActionId, '')
        this.getFillList(trainingStatusActionId, '')
        this.getFillList(trainingApprovedByActionId, '')
        this.getFillList(trainingEmployeeActionId, '')
        console.log("0000", localStorage.getItem('approvedDateTLS'))
        this.setState({

            trainingStartDateTLS: localStorage.getItem('trainingStartDateTLS') != '' ? localStorage.getItem('trainingStartDateTLS') : '',
            trainingEndDateTLS: localStorage.getItem('trainingEndDateTLS') != '' ? localStorage.getItem('trainingEndDateTLS') : '',
            courseTypeTLS: localStorage.getItem('courseTypeTLS') != '' ? localStorage.getItem('courseTypeTLS') : '',
            courseDetailsTLS: localStorage.getItem('courseDetailsTLS') != '' ? localStorage.getItem('courseDetailsTLS') : '',
            employeeTLS: localStorage.getItem('employeeTLS') != '' ? localStorage.getItem('employeeTLS') : '',
            statusIdTLS: localStorage.getItem('statusIdTLS ') != '' ? localStorage.getItem('statusIdTLS') : '',
            approvedByTLS: localStorage.getItem('approvedByTLS') != '' ? localStorage.getItem('approvedByTLS') : '',
            approvedDateTLS: localStorage.getItem('approvedDateTLS') != '' ? localStorage.getItem('approvedDateTLS') : '',

            trainingFromDate: localStorage.getItem('trainingStartDateTLS') != '' ?
                moment(localStorage.getItem('trainingStartDateTLS')).toDate() : '',
            trainingEndDate: localStorage.getItem('trainingEndDateTLS') != '' ?
                moment(localStorage.getItem('trainingEndDateTLS')).toDate() : '',
            courseTypeId: localStorage.getItem('courseTypeTLS') != '' ? localStorage.getItem('courseTypeTLS') : '',
            courseDetails: localStorage.getItem('courseDetailsTLS') != '' ? localStorage.getItem('courseDetailsTLS') : '',
            employeeId: localStorage.getItem('employeeTLS') != '' ? localStorage.getItem('employeeTLS') : '',
            statusId: localStorage.getItem('statusIdTLS ') != '' ? localStorage.getItem('statusIdTLS') : '',
            approvedById: localStorage.getItem('approvedByTLS') != '' ? localStorage.getItem('approvedByTLS') : '',
            approvalDate: localStorage.getItem('approvedDateTLS') != '' ?
                moment(localStorage.getItem('approvedDateTLS')).toDate() : '',


        })

        // document.addEventListener('click', this.handleInputClick);
        // document.addEventListener('keydown', this.handleTabKeyPress);

        setTimeout(() => {
            this.getTrainingList()
            clearLocalStorage("training")
        }, 100);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.getTrainingList()
        }
    }

    clearValue() {
        this.setState({
            employeeId: '',
            courseDetails: '',
            approvalDate: '',
            trainingFromDate: '',
            trainingEndDate: '',
            courseTypeId: '',
            approvedById: '',
            statusId: '',
            currentPage: 1,
            // local storage
            trainingStartDateTLS: '',
            trainingEndDateTLS: '',
            courseTypeTLS: '',
            courseDetailsTLS: '',
            employeeTLS: '',
            statusIdTLS: '',
            approvedByTLS: '',
            approvedDateTLS: '',
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getTrainingList()
        }, 300);

    }


    // get fill list 
    getFillList(value, ref) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value, ref)
            .then((data) => {
                if (data.error != 1) {
                    if (value == courseTypeActionId) {
                        this.setState({ courseTypeList: data.data, isLoading: false })
                    }
                    if (value == trainingApprovedByActionId) {
                        this.setState({ approvedByList: data.data, isLoading: false })
                    }
                    if (value == trainingStatusActionId) {
                        this.setState({ statusList: data.data, isLoading: false })
                    }
                    if (value == trainingEmployeeActionId) {
                        this.setState({ employeeList: data.data, isLoading: false })
                    }
                }
                else {
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    // get engagement allocation list
    getTrainingList() {
        const { employeeId, courseDetails, approvalDate, trainingFromDate, trainingEndDate, courseTypeId, approvedById, statusId, } = this.state
        this.setState({ isLoading: true })
        console.log(approvalDate)
        clearLocalStorage("training")
        this.props
            .trainingList_action(
                trainingFromDate, trainingEndDate,
                courseTypeId != 'Select' && courseTypeId != '' ? courseTypeId : null,
                courseDetails.trim(),
                employeeId != 'Select' && employeeId != '' ? employeeId : null,
                statusId != 'Select' && statusId != '' ? statusId : null,
                approvedById != 'Select' && approvedById != '' ? approvedById : null,
                approvalDate,
            )
            .then((data) => {
                if (data.error != 1) {

                    this.setState({ TrainingList: data.data })
                    setTimeout(() => {
                        this.setState({ isLoading: false, currentPage: 1, })
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

        localStorage.setItem("trainingStartDateTLS", trainingFromDate)
        localStorage.setItem("trainingEndDateTLS", trainingEndDate)
        localStorage.setItem("courseTypeTLS", courseTypeId)
        localStorage.setItem("courseDetailsTLS", courseDetails)
        localStorage.setItem("employeeTLS", employeeId)
        localStorage.setItem("approvedByTLS", approvedById)
        localStorage.setItem("approvedDateTLS", approvalDate)
        localStorage.setItem("statusIdTSLS", statusId === '' ? 2 : statusId)
    }


    // add list 
    handleAdd() {
        this.props.navigate('/trainingAU', { state: { is_add: true } })
    }

    handleUpdate(TrainingListId) {
        this.props.navigate('/TrainingU', { state: { is_update: true, TrainingListId: TrainingListId } })
    }

    // delete list
    handleDelete(e, TrainingListId) {
        e.preventDefault()
        // this.props.navigate('/TrainingListAU', { state: { is_delete: true, TrainingListId: TrainingListId } })
        var id = TrainingListId
        this.setState({ isLoading: true })
        this.props
            .deletetraining_action(id)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isOpen: !this.state.isOpen })
                    this.getTrainingList()
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isLoading: false })

                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    //toggle model for delete.................................
    toggleModal(TrainingListId) {
        const { isOpen, TrainingListDeleteId } = this.state
        this.setState({ isOpen: !isOpen })
        if (TrainingListId == '') {
            return;
        } else {
            this.setState({ TrainingListDeleteId: TrainingListId })
        }

    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }

    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
            this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
    };

    handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
            this.setState({ pageSizeInput: input });
        }
    };

    record(TrainingList) {
        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ textAlign: 'left' }} >Training Date</th>
                        <th style={{ width: '150px' }} >Employee </th>
                        <th style={{ width: '250px' }} >Hours</th>
                        <th style={{ width: '150px' }} >Structured/Un-structured</th>
                        <th style={{ width: '250px' }} >Course Details</th>
                        <th style={{ width: '250px' }} >Status </th>
                        <th style={{ width: '100px' }} >Approved By</th>
                        <th style={{ width: '250px' }}>Approved Date</th>
                        <th style={{ width: '100px' }} >Rejected By</th>
                        <th style={{ width: '250px' }}>Rejected Date</th>
                    </tr>
                </thead>
                <tbody>


                    {
                        TrainingList.length > 0 ?
                            TrainingList.map((data, index) => (
                                <>
                                    <tr key={Math.random()}>

                                        <td className="text-center" style={{ padding: '5px!important' }}>
                                            <div className="table__button-group">

                                                {/* <a> <i className="fa fa-pencil" style={{ color: '#aac722' }}
                                                    onClick={() => this.handleUpdate(data.TrainingID)} /></a> */}

                                                {data.StatusName != 'Approved' && data.StatusName != 'Deleted' && getModuleDeleteAccess(Traning_Entry) ?
                                                    <a> <span className="text-danger"><i className="fa fa-trash-o"
                                                        onClick={() => this.toggleModal(data.TrainingID)} /></span></a>
                                                    : <></>
                                                }



                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'left' }}>{data.TrainingDate != '' ? moment(data.TrainingDate).format('DD-MM-YYYY') : '-'}</td>
                                        <td>{data.EmployeeName != '' ? data.EmployeeName : '-'}</td>
                                        <td>{data.TotalHour != '' ? data.TotalHour : '-'}</td>
                                        <td>{data.CourseTypeName != '' ? data.CourseTypeName : '-'}</td>
                                        <td>{data.CourseDetails != '' ? data.CourseDetails : '-'}</td>
                                        <td>{data.StatusName != '' ? data.StatusName : '-'}</td>
                                        <td>{data.ApprovalByName != '' && data.ApprovalByName != null ? data.ApprovalByName : '-'}</td>
                                        <td>{data.ApprovedDate != null && moment(data.ApprovedDate).format('DD-MM-YYYY')}</td>
                                        <td>{data.RejectedByName != '' && data.RejectedByName != null ? data.RejectedByName : '-'}</td>
                                        <td>{data.RejectedDate != null && moment(data.RejectedDate).format('DD-MM-YYYY')}</td>

                                    </tr></>
                            )) : <tr><td colSpan={13} className='text-center'>Record not found</td></tr>
                    }

                </tbody>
            </table>
        )
    }



    render() {
        const { isSearchTab, isAccessToView, isLoading, TrainingList,
            TrainingListDeleteId, isOpen, trainingEndDate,
            statusList, courseTypeList, approvedByList, employeeList,
            trainingFromDate, employeeId, courseDetails, approvalDate,
            courseTypeId, approvedById, statusId,

        } = this.state

        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = TrainingList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(TrainingList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {/* {isAccessToView ? ( */}
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul className="breadcrumb">

                                        <li><a href="#">Training List</a></li>
                                        <li />
                                    </ul>
                                </div>
                                <div className='page-space-from-bottom'>
                                    <div className="row">
                                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                        <div className="col-md-12 pg-body grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body pr-2">
                                                    <div className="accordion accordion-flush " id="accordionFlushExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="flush-headingOne">
                                                                <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">                                                                <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                </button>
                                                            </h2>
                                                            {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                            <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                <div className="row">
                                                                    <div className='form-group col-md-3'>
                                                                        <label>
                                                                            Training Start Date
                                                                        </label>
                                                                        <div className="form-group d-flex">
                                                                            <DatePicker className="form-control"
                                                                                dateFormat="dd-MM-yyyy"
                                                                                selected={this.state.trainingFromDate}
                                                                                // minDate={this.state.finTodateLstRcd != '' ? this.state.finTodateLstRcd : ''}
                                                                                // disabled={is_add ? (this.state.finTodateLstRcd != '' ? true : false) : is_update}
                                                                                onChange={(date) => {
                                                                                    this.setState({ trainingFromDate: date });

                                                                                }}
                                                                                peekNextMonth
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                placeholderText="From Date"
                                                                                customInput={<ExampleCustomInput />}
                                                                                dayClassName={() => "example-datepicker-day-class"}
                                                                                popperClassName="example-datepicker-class"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='form-group col-md-3'>
                                                                        <label>
                                                                            Training End Date
                                                                        </label>
                                                                        <div className="form-group d-flex">
                                                                            <DatePicker className="form-control"
                                                                                dateFormat="dd-MM-yyyy"
                                                                                selected={this.state.trainingEndDate}
                                                                                // minDate={this.state.finTodateLstRcd != '' ? this.state.finTodateLstRcd : ''}
                                                                                // disabled={is_add ? (this.state.finTodateLstRcd != '' ? true : false) : is_update}
                                                                                onChange={(date) => {
                                                                                    this.setState({ trainingEndDate: date });

                                                                                }}
                                                                                peekNextMonth
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                placeholderText="From Date"
                                                                                customInput={<ExampleCustomInput />}
                                                                                dayClassName={() => "example-datepicker-day-class"}
                                                                                popperClassName="example-datepicker-class"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {/* </div> */}
                                                                    <div className="form-group col-md-3">
                                                                        <label>Course Type</label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }} value={courseTypeId}
                                                                            onChange={e => this.setState({ courseTypeId: e.target.value })}>
                                                                            <option value={''}>Select</option>
                                                                            {courseTypeList.length > 0 && courseTypeList.map(item => (
                                                                                <option
                                                                                    key={item.Code}
                                                                                    value={item.Code}>
                                                                                    {item.Description}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label>Course Details</label>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            value={courseDetails}
                                                                            onChange={(e) => this.setState({ courseDetails: e.target.value })}
                                                                            onKeyDown={e => this.handleKeyDown(e)}
                                                                            required />
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label>Employee </label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }} value={employeeId}
                                                                            onChange={e => this.setState({ employeeId: e.target.value })}>
                                                                            <option value={''}>Select</option>
                                                                            {employeeList.length > 0 && employeeList.map(item => (
                                                                                <option
                                                                                    key={item.EmployeeID}
                                                                                    value={item.EmployeeID}>
                                                                                    {item.Employee}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label>Status</label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }}
                                                                            value={this.state.statusId}
                                                                            onChange={(e) => this.setState({ statusId: e.target.value })}>
                                                                            <option value={''}>Select</option>
                                                                            {statusList.length > 0 && statusList.map(item => (
                                                                                <option
                                                                                    key={item.Code}
                                                                                    value={item.Code}>
                                                                                    {item.Description}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                    </div>


                                                                    <div className="form-group col-md-3">
                                                                        <label>Approved by</label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }}
                                                                            value={this.state.approvedById}
                                                                            onChange={(e) => this.setState({ approvedById: e.target.value })}>
                                                                            <option value={''}>Select</option>
                                                                            {approvedByList.length > 0 && approvedByList.map(item => (
                                                                                <option
                                                                                    key={item.EngpartnerID}
                                                                                    value={item.EngpartnerID}>
                                                                                    {item.EngPartnerName}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                    </div>
                                                                    <div className='form-group col-md-3'>
                                                                        <label>
                                                                            Approved  Date
                                                                        </label>
                                                                        <div className="input-group">
                                                                            <div className='daterangecss'>
                                                                                <div className="form-group d-flex">
                                                                                    <DatePicker className="form-control"
                                                                                        dateFormat="dd-MM-yyyy"
                                                                                        selected={this.state.approvalDate}
                                                                                        // minDate={this.state.fromDate}
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        // disabled={is_add ? false : finYearId == lastRecord ? false : true}
                                                                                        onChange={(date) => this.setState({ approvalDate: date != null ? date : '' })}
                                                                                        //   onChange={(date) => console.log(date)}

                                                                                        placeholderText="To Date"
                                                                                        customInput={<ExampleCustomInput />}
                                                                                        dayClassName={() => "example-datepicker-day-class"}
                                                                                        popperClassName="example-datepicker-class"
                                                                                    />


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group col-md-3 mt-2" style={{ textAlign: 'left' }}>
                                                                    <button type="button" className="btn btn-Add rounded-1" onClick={() => this.getTrainingList()}> Search</button>
                                                                    <button type="button" className="btn btn-clear rounded-1 mx-1 px-3" onClick={this.clearValue}> Clear</button>
                                                                </div>
                                                            </div>
                                                                {/* </div> */}
                                                            </div> : <></>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className='d-flex w-100' style={{ justifyContent: 'flex-end' }}>

                                                                {getModuleWriteAccess(Traning_Entry) && <div>
                                                                    <button type="button" className="btn btn-Add rounded-1 m-1"
                                                                        onClick={this.handleAdd}> Add</button>
                                                                </div>
                                                                }
                                                            </div>



                                                            <div className="table-responsive tbs4 common-table trail-tb " style={{ width: '100%' }}>
                                                                {this.record(currentRecords)}
                                                                {TrainingList.length > 0 ? <Pagination
                                                                    nPages={nPages}
                                                                    currentPage={currentPage}
                                                                    setCurrentPage={this.updateItem}
                                                                    recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                    setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                    totalRecords={TrainingList.length}
                                                                /> : <></>}

                                                            </div>
                                                        </div>
                                                    </div >
                                                </div >
                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >
                        {/* ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)} */}
                        {/* end page */}
                    </div >
                    {/* page-body-wrapper ends */}
                </div >
                <ToastContainer />
                {/* // modal for delete */}
                {/* <Modal
                    isOpen={isOpen}
                    onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot" >
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, TrainingListDeleteId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn  del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}
                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right">
                                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, TrainingListDeleteId)}> Yes</a>
                                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(connect(null, {
    trainingList_action,
    addtraining_action,
    deletetraining_action,
    fill_action,
})(TrainingList));