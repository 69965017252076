
import { TASKTYPE_LIST_ACTION_TYPE, TASKTYPE_DETAILS_ACTION_TYPE, TASKTYPE_ADD_ACTION_TYPE, TASKTYPE_UPDATE_ACTION_TYPE, TASKTYPE_DELETE_ACTION_TYPE } from "../../utils/constants";

const initialState = []

function taskTypeReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case TASKTYPE_LIST_ACTION_TYPE:
            return [...state, payload];
        case TASKTYPE_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case TASKTYPE_ADD_ACTION_TYPE:
            return [...state, payload];
        case TASKTYPE_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case TASKTYPE_DELETE_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}

export default taskTypeReducer