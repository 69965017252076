import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {platfarm, headers,
    UPDATE_CHECKLIST_ACTION_TYPE,
    UPDATE_CHECKLIST_DETAILS_ACTION_TYPE,
    UPDATE_CHECKLIST_UPDATE_ACTION_TYPE,
    UPDATE_CHECKLIST_CLOSE_ACTION_TYPE,
    updateChecklistUrl ,updateChecklistDetailsUrl ,closeUpdateChecklistUrl,
    updateUpdateChecklistUrl,
     fillListUrl, FILL_LIST_ACTION_TYPE

} from "../../utils/constants"




export const UpdateChecklist_action = (financialYear,clientName,status) => async (dispatch) => {
    try {   
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientName : clientName,
            FinancialYear : financialYear,
            status : status,
            from : 'update'

        }
        const res = await axiosGet(updateChecklistUrl, param);
        dispatch({
            type: UPDATE_CHECKLIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateChecklistDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Id: id
        }        

        const res = await axiosGet(updateChecklistDetailsUrl, param);
        dispatch({
            type: UPDATE_CHECKLIST_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const updateUpdateChecklist_action = (id ,checklist) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            id : id,
            CheckList : checklist,
           
        }
        const res = await axiosPost(updateUpdateChecklistUrl, mapdata, headers);

        dispatch({
            type: UPDATE_CHECKLIST_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const closeUpdateChecklist_action = (id,remark) => async (dispatch) => {
    try {
        const param = {
            EngagementID : id,
            Remark : remark,
            userId: localStorage.getItem('UserId'),
            Platform: platfarm,

        }
        const res = await axiosGet(closeUpdateChecklistUrl, param);
        dispatch({
            type: UPDATE_CHECKLIST_CLOSE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};




export const simpleAction = () => dispatch => {
    dispatch({
     type: 'SIMPLE_ACTION',
     payload: 'result_of_simple_action'
    })
   }