
import { MARTIAL_STATUS_LIST_ACTION_TYPE, MARTIAL_STATUS_ADD_ACTION_TYPE, MARTIAL_STATUS_UPDATE_ACTION_TYPE, MARTIAL_STATUS_DETAILS_ACTION_TYPE, MARTIAL_STATUS_DETETE_ACTION_TYPE } from "../../utils/constants";

const initialState = []

function martialStatusReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case MARTIAL_STATUS_LIST_ACTION_TYPE:
            return [...state, payload];
        case MARTIAL_STATUS_ADD_ACTION_TYPE:
            return [...state, payload];
        case MARTIAL_STATUS_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case MARTIAL_STATUS_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case MARTIAL_STATUS_DETETE_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}

export default martialStatusReducer