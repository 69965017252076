
import { CLIENT_LIST_ACTION_TYPE, CLIENT_LIST_DETAILS_ACTION_TYPE, CLIENT_LIST_ADD_ACTION_TYPE, CLIENT_LIST_UPDATE_ACTION_TYPE, CLIENT_LIST_DELETE_ACTION_TYPE, CLIENT_COMPANY_LIST_ACTION_TYPE, CLIENT_COMPANY_LIST_ADD_ACTION_TYPE, CLIENT_COMPANY_LIST_DETAILS_ACTION_TYPE, CLIENT_COMPANY_LIST_UPDATE_ACTION_TYPE, CLIENT_LOCATION_LIST_ACTION_TYPE, CLIENT_LOCATION_LIST_ADD_ACTION_TYPE, CLIENT_LOCATION_LIST_DETAILS_ACTION_TYPE, CLIENT_LOCATION_LIST_UPDATE_ACTION_TYPE, CLIENT_ADDRESS_LIST_ACTION_TYPE, CLIENT_ADDRESS_LIST_ADD_ACTION_TYPE, CLIENT_ADDRESS_LIST_DETAILS_ACTION_TYPE, CLIENT_ADDRESS_LIST_UPDATE_ACTION_TYPE } from "../../utils/constants";

const initialState = []

function clientReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case CLIENT_LIST_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_LIST_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_LIST_ADD_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_LIST_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_LIST_DELETE_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_COMPANY_LIST_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_COMPANY_LIST_ADD_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_COMPANY_LIST_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_COMPANY_LIST_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_LOCATION_LIST_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_LOCATION_LIST_ADD_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_LOCATION_LIST_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_LOCATION_LIST_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_ADDRESS_LIST_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_ADDRESS_LIST_ADD_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_ADDRESS_LIST_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case CLIENT_ADDRESS_LIST_UPDATE_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}

export default clientReducer