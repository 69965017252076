import { axiosPost, axiosGet } from "../../react_axios/Axios"
import { financialYearListUrl,financialYearDetailsUrl, addFinancialYearListUrl, deleteFinancialYearUrl,
     updateFinancialYearUrl, platfarm, 
     FINANCIAL_YEAR_LIST_ACTION_TYPE,FINANCIAL_YEAR_LIST_DETAILS_ACTION_TYPE, headers, FINANCIAL_YEAR_LIST_ADD_ACTION_TYPE, FINANCIAL_YEAR_LIST_UPDATE_ACTION_TYPE, FINANCIAL_YEAR_LIST_DELETE_ACTION_TYPE } from "../../utils/constants"
import moment from 'moment';



export const financialYearList_action = (financialYearName, shortName , status) => async (dispatch) => {
    try {
        
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            FinancialYearName: financialYearName,
            ShortName:shortName,
            status:status
        }
        const res = await axiosGet(financialYearListUrl, param);
        dispatch({
            type: FINANCIAL_YEAR_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const financialYearDetail_action = (id,lastRecord) => async (dispatch) => {
    try {
        const param = {
            ID: id,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            LastRecord : lastRecord
        }

        const res = await axiosGet(financialYearDetailsUrl, param);
        dispatch({
            type: FINANCIAL_YEAR_LIST_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const addFinancialYearList_action = (financialYearName, shortName, fromDate, toDate) => async (dispatch) => {
    try {
        const mapdata = {
            FinancialYearName: financialYearName,
            ShortName: shortName,
            FromDate: new Date(moment(fromDate).format('MM-DD-YYYY')+'Z').toISOString(),
            ToDate: new Date(moment(toDate).format('MM-DD-YYYY')+'Z').toISOString(),
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }
        const res = await axiosPost(addFinancialYearListUrl, mapdata, headers);

        dispatch({
            type: FINANCIAL_YEAR_LIST_ADD_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateFinancialYearList_action = (id,financialYearName, shortName, fromDate, toDate) => async (dispatch) => {
    try {
        const mapdata = {
            Id : id,
            FinancialYearName: financialYearName,
            ShortName: shortName,
            FromDate: new Date(moment(fromDate).format('MM-DD-YYYY')+'Z').toISOString(),
            ToDate: new Date(moment(toDate).format('MM-DD-YYYY')+'Z').toISOString(),
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }
        const res = await axiosPost(updateFinancialYearUrl, mapdata, headers);

        dispatch({
            type: FINANCIAL_YEAR_LIST_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const deleteFinancialYearList_action = (id) => async (dispatch) => {
    try {
        const param = {
            Id: id,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,

        }
        const res = await axiosGet(deleteFinancialYearUrl, param);
        dispatch({
            type: FINANCIAL_YEAR_LIST_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export default (state = {}, action) => {
    switch (action.type) {
        case 'SIMPLE_ACTION':
            return {
                result: action.payload
            }
        default:
            return state
    }
}