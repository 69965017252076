import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Loader from '../../../utils/Loader'
import { fill_action } from '../../../actions/master_action/checklist_action';
import {
    MstMcqInstructionActionId,
    chapterListActionId, typeOfTestActionId, levelActionId
} from '../../../utils/constants'
import { settingDetails_action, addSetting_action, } from '../../../actions/mcq_action/settings_action';

class TakeTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAccessToView: true,
            WorkingDayID: '',
            workingDayTypeList: [],
            is_add: false,
            is_delete: false,
            is_update: false,
            mcqNo: '',
            chapterId: "",
            chapterList: [],
            levelId: "",
            levelList: [],
            typeOfTestList: [],
            typeOfTestId: '',
            instructionList: [],
            instructionId: '',
            answerKey: '',
        }
        this.getFillList = this.getFillList.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.OnClickIcon = this.OnClickIcon.bind(this)
        this.startTest = this.startTest.bind(this)


    }

    componentDidMount = () => {
        this.setState({
            is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
            is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
            is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
            WorkingDayID: this.props.location.state != null && this.props.location.state.WorkingDayID != null ? this.props.location.state.WorkingDayID : '',
            dayOfTheWeek: this.props.location.state != null && this.props.location.state.dayOfTheWeek != null ? this.props.location.state.dayOfTheWeek : '',
            // isAccessToView: localStorage.getItem('UserId') == '1' ? true : false,
        })
        // document.addEventListener('click', this.handleInputClick);

        // document.addEventListener('keydown', this.handleTabKeyPress);
        this.getFillList(levelActionId, '', '');
        this.getFillList(chapterListActionId, '', '');
        this.getFillList(typeOfTestActionId, '', '');
        this.getFillList(MstMcqInstructionActionId, '', '');

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


  
    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
          const focusedElement = document.activeElement;
          const { previouslyFocusedElement } = this.state;
    
          if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
          }
    
          if (focusedElement.classList.contains('form-control') ||
            focusedElement.classList.contains('js-example-basic-single') ||
            focusedElement.classList.contains('example-custom-input')) {
            focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
            this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
          }
        }
      };
    
    
      handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;
    
        if (previouslyFocusedElement) {
          previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }
    
        if (clickedElement.classList.contains('form-control') ||
          clickedElement.classList.contains('js-example-basic-single') ||
          clickedElement.classList.contains('example-custom-input')) {
          clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
          this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
          this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
      };
    

    //Fill list
    getFillList(value, ref, ref2) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == levelActionId) {
                        this.setState({ levelList: data.data, isLoading: false })

                    }
                    if (value == chapterListActionId) {
                        var chapList = [{
                            Code: 0,
                            Description: 'Generic'
                        }]
                        
                        chapList.push(...data.data)
                        console.log(chapList)
                        this.setState({ chapterList: chapList, isLoading: false })

                    }
                    if (value == typeOfTestActionId) {
                        this.setState({ typeOfTestList: data.data, isLoading: false })

                    }
                    if (value == MstMcqInstructionActionId) {
                        const processedData = data.data.map(item => ({
                            ...item,
                            isHide: false // or any default value you want to set
                        }));
                        this.setState({ instructionList: processedData, isLoading: false })

                    }
                }
            })
    }

    //cancel
    handleCancel() {
        this.props.navigate('/workingDaysList')
    }


    startTest() {
        const { levelId, chapterId, typeOfTestId, chapterList, typeOfTestList, levelList } = this.state
       
        var isFollow =  false 
        if (levelId == "") {
            toast.error('Please select level', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
            isFollow =  false 
            return ;
        } else {
            isFollow =  true 
        }

        if (chapterId == "") {
            toast.error('Please select chapter', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
            isFollow =  false 
            return ;
        } else {
            isFollow =  true 
        }

        if (typeOfTestId == "") {
            toast.error('Please select type of test', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
            isFollow =  false 
            return ;
        } else {
            isFollow =  true 
        }


        if(isFollow){
            const chapterName = chapterList.find(item => item.Code == chapterId);
            console.log( chapterList.find(item => item.Code == chapterId))
            const level = levelList.find(item => item.Code == levelId);
            const typeOfTestName = typeOfTestList.find(item => item.Code == typeOfTestId);
            console.log(chapterName)
            console.log(typeOfTestName.Value)
            sessionStorage.setItem('previousPage', window.location.href);
            this.props.navigate('/startTest', {
                // replace: true, 
                state: {
                    is_add: true,
                    levelID: levelId,
                    chapterIdName : chapterName.Description, 
                     chapterId: chapterId, 
                     typeOfTestIdName: typeOfTestName.Value,
                     typeOfTestId: typeOfTestId,
                     levelName : level.Description
                }
            })
        }
    }

    OnClickIcon(id) {
        const { levelId, chapterId, instructionList } = this.state

        const updatedInstructionList = instructionList.map(item =>
            item.McqInstructionID === id ? { ...item, isHide: !item.isHide } : item
        );

        this.setState({ instructionList: updatedInstructionList });
    }



    render() {
        const { is_update, isAccessToView, isLoading,
            mcqNo, chapterId, chapterList, levelId,
            levelList, typeOfTestList, typeOfTestId, instructionId,
            instructionList, } = this.state
        return (<>
            {isLoading ? (
                <Loader></Loader>
            ) : (
                null
            )}
            <Header />
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                    {/* Left side menu */}
                    <Drawer />
                    {/* end left menu */}
                    {/* start page */}
                    {isAccessToView ? (
                        <div className="main-panel main-box">
                            <div className="content-wrapper">
                                <div className="main-breadcum">
                                    <ul class="breadcrumb d-flex">
                                        <li><a onClick={this.handleCancel}>Take a Test</a></li>
                                        <li></li>
                                    </ul>
                                    {/* <div class="status-top mr-2 text-dark" style={{ position: 'absolute', right: '0px', top: '62' }}>
                                        <p>Status : <strong class="text-success">Active</strong></p>
                                    </div> */}
                                </div>
                                <div className="row">
                                    <style
                                        type="text/css"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                                        }}
                                    />
                                    <div className="card">
                                        <div class="card-body mb-4 p-2">
                                            <div class="d-flex flex-wrap row m-0">
                                                <div class="border  rounded-2 p-0 pb-3">
                                                    <h5 class="p-2 rounded-top text-danger text-left text-dark" style={{ background: '#e4e4e4' }}> Instructions</h5>
                                                    <div class="p-2 pb-0">
                                                        {instructionList.length > 0 && instructionList.map(item => (
                                                            <React.Fragment key={item.McqInstructionID}>
                                                                <p>
                                                                    <i className={!item.isHide ? "fa fa-angle-right mx-2" : "fa fa-angle-down mx-2"} onClick={() => this.OnClickIcon(item.McqInstructionID)}></i>
                                                                    {item.ParentName}
                                                                </p>
                                                                {item.isHide ? <p className={'mx-5'}> {item.Description}</p> : null}
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                    <div class="d-flex flex-wrap row m-0">
                                                        <div className='colsm-12 p-0'>
                                                            <hr class="m-2 mb-1 mt-1" style={{ border: '1px solid #898989' }} />
                                                        </div>
                                                        
                                                        <div class="form-group col-md-3 p-2 pt-0">
                                                            <label>Choose Chapter<span class="required1">*</span></label>
                                                            <select className="js-example-basic-single" style={{ width: '100%' }} value={chapterId}
                                                                onChange={e => this.setState({ chapterId: e.target.value })}>
                                                                <option value="">Select</option>
                                                                {chapterList.length > 0 && chapterList.map(item => (
                                                                    <option
                                                                        key={item.Code}
                                                                        value={item.Code}>
                                                                        {item.Description}
                                                                    </option>
                                                                ))}

                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-3 p-2 pt-0">
                                                            <label>Choose Level<span class="required1">*</span></label>
                                                            <select className="js-example-basic-single" style={{ width: '100%' }} value={levelId}
                                                                onChange={e => this.setState({ levelId: e.target.value })}>
                                                                <option value="">Select</option>
                                                                {levelList.length > 0 && levelList.map(item => (
                                                                    <option
                                                                        key={item.Code}
                                                                        value={item.Code}>
                                                                        {item.Description}
                                                                    </option>
                                                                ))}

                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-3 p-2 pt-0">
                                                            <label>Type of Test<span class="required1">*</span></label>
                                                            <select className="js-example-basic-single" style={{ width: '100%' }} value={typeOfTestId}
                                                                onChange={e => this.setState({ typeOfTestId: e.target.value })}>
                                                                <option value="">Select</option>
                                                                {typeOfTestList.length > 0 && typeOfTestList.map(item => (
                                                                    <option
                                                                        key={item.Code}
                                                                        value={item.Code}>
                                                                        {item.Value}
                                                                    </option>
                                                                ))}

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <button class="btn btn-outline-primary p-2 mt-1 m-2"
                                                        style={{ fontSize: '13px', margin: '0 auto', width: '100px' }} onClick={this.startTest}>Start Test</button>
                                                </div>
                                                {/* <!-- <div class="form-group col-md-3">
                                                        <label>You have not taken any test</label>
                                                        <button class="btn btn-outline-primary p-2 mt-2" style="font-size: 13px; width: 150px;">Take a test now</button>
                                                    </div> --> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                    </div>)}
                    {/* end page */}
                </div>
                {/* page-body-wrapper ends */}
            </div>
            <ToastContainer />
        </>)
    }
}

export default withRouter(connect(null, {
    fill_action, settingDetails_action,
    addSetting_action,
})(TakeTest))