import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import { managementTypeList_action, managementTypeDetails_action, managementTypeAdd_action, managementTypeUpdate_action, managementTypeDelete_action } from '../../../actions/master_action/managementType_action'
import { fill_action } from '../../../actions/master_action/checklist_action'

import Pagination from "../../../utils/pagination";

import { clearLocalStorage, clientCategoryActionId } from '../../../utils/constants'

class ManagementType extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAccessToView: true,
            isOpen: false,
            status: ' ',
            isLoading: false,
            IsActive: 0,
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage : 10,
            pageSizeInput: "10", 
            managementType: '',
            managementTypeList: [],
            clientCategoryId: '',
            clientCategoryList: [],
            search_clientCategory: '',
            isSearchTab: false,
        }

        this.handleCancel = this.handleCancel.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);  
		
        this.toggleModal = this.toggleModal.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.toggleModalAdd = this.toggleModalAdd.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.toggleModalUpdate = this.toggleModalUpdate.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.getManagementTypeDetails = this.getManagementTypeDetails.bind(this)
        this.getManagementTypeList = this.getManagementTypeList.bind(this)

    }

    componentDidMount = () => {
        this.getFillList(clientCategoryActionId, '')

        this.setState({
            isAccessToView: true,
            managementTypeMLS: localStorage.getItem('managementTypeMLS'),
            clientCategoryIdMLS: localStorage.getItem('clientCategoryIdMLS'),
            statusMLS: localStorage.getItem('statusMLS'),

            managementType: localStorage.getItem('managementTypeMLS') != '' ? localStorage.getItem('managementTypeMLS') : '',
            clientCategoryId: localStorage.getItem('clientCategoryIdMLS') != '' ? localStorage.getItem('clientCategoryIdMLS') : '',
            status: localStorage.getItem('statusMLS') != 'Select' ? localStorage.getItem('statusMLS') : 'Select',
        })
        clearLocalStorage("managementType")
        this.clearValue()
        setTimeout(() => {
            this.getManagementTypeList()
        }, 100);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    
  handleTabKeyPress = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
      const focusedElement = document.activeElement;
      const { previouslyFocusedElement } = this.state;

      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }

      if (focusedElement.classList.contains('form-control') ||
        focusedElement.classList.contains('js-example-basic-single') ||
        focusedElement.classList.contains('example-custom-input')) {
        focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
        this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
      }
    }
  };


  handleInputClick = (event) => {
    const { previouslyFocusedElement } = this.state;
    const clickedElement = event.target;

    if (previouslyFocusedElement) {
      previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    }

    if (clickedElement.classList.contains('form-control') ||
      clickedElement.classList.contains('js-example-basic-single') ||
      clickedElement.classList.contains('example-custom-input')) {
      clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
      this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
    } else {
      this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
    }
  };
    getFillList(value, ref, ref2) {

        this.setState({ isLoading: true })
        this.props
            .fill_action(value,
                ref != 'Select' && ref != '' ? ref : '',
                ref2 != 'Select' && ref2 != '' ? ref2 : '')
            .then((data) => {
                if (data.error != 1) {
                    if (value == clientCategoryActionId) {
                        this.setState({ clientCategoryList: data.data, isLoading: false })
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    clearValue() {
        this.setState({
            search_clientCategory: '',
            managementType: '',
            clientCategoryId: '',
            status: ' '
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getManagementTypeList()
        }, 300);

    }

    handleKeyDown(e) {
        if (e.code === 'Enter') {
            this.getManagementTypeList()
        }
    }


    handleCancel() {
        this.state.isOpen = false
        this.clearValue()
    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }
    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
          this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
      };
      
      handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
          this.setState({ pageSizeInput: input });
        }
      };
    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
      }

    // toggleModal for delete
    toggleModal(managementId) {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
        this.state._isDelete = true
        this.state._isAdd = false
        this.state._isUpdate = false
        if (managementId == '') {
            return;
        } else {
            this.setState({ managementId: managementId })
        }

    }
    // delete Department
    handleDelete(e) {
        e.preventDefault()
        var id = this.state.managementId
        console.log('99999', id)
        this.setState({ isLoading: true })
        this.props
            .managementTypeDelete_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isLoading: false })
                    this.handleCancel()
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    getManagementTypeDetails(managementId) {
        var id = managementId
        this.setState({ isLoading: false })
        this.props
            .managementTypeDetails_action(id)
            .then((data) => {
                if (data.error != 1) {

                    this.setState({
                        managementType: data.data[0]['MemberType'],
                        clientCategoryId: data.data[0]['ClientCategoryID'],
                        IsActive: data.data[0]['IsActive'],
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // update Designation
    toggleModalUpdate(managementId) {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen  , _isUpdate : true  })
        this.state._isUpdate = true
        this.state._isAdd = false
        this.state._isDelete = false
        this.getManagementTypeDetails(managementId)
        if (managementId == '') {
            return;
        } else {
            this.setState({ managementId: managementId })
        }
    }

    handleUpdate(e) {
        e.preventDefault()
        const { clientCategoryId, managementType } = this.state
        const id = this.state.managementId;
        if (id != "" && managementType != "") {

            this.setState({ isLoading: true })
            this.props
                .managementTypeUpdate_action(id, clientCategoryId, managementType)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data updated successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false })
                        this.handleCancel()
                        this.getManagementTypeList()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }

    // LocationType add
    toggleModalAdd() {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen  , _isAdd : true })
        this.clearValue()
        this.state._isAdd = true
        this.state._isUpdate = false
        this.state._isDelete = false
    }
    handleAdd(e) {
        e.preventDefault()
        const { clientCategoryId, managementType } = this.state
        if (managementType != "") {
            this.setState({ isLoading: true })

            this.props
                .managementTypeAdd_action(clientCategoryId, managementType.trim() )
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Data added successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false })
                        this.handleCancel()
                        this.clearValue()
                    }
                    else {
                        this.setState({ isLoading: false })

                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  mandatory field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }



    getManagementTypeList() {
        const { search_clientCategory, clientCategoryId, status } = this.state
        this.setState({ isLoading: true })
        clearLocalStorage("managementType")
        this.props
            .managementTypeList_action(search_clientCategory.trim(), clientCategoryId == 'Select' || clientCategoryId == '' ? '' : clientCategoryId, status != 'Select' && status != '' ? status : 1)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ managementTypeList: data.data, isLoading: false, currentPage: 1 })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
        localStorage.setItem("managementTypeMLS", search_clientCategory)
        localStorage.setItem("clientCategoryIdMLS", clientCategoryId)
        localStorage.setItem("statusMLS", status === 'Select' ? 1 : status)
    }

    record(managementTypeList) {
        return (
            <table className="table table-bordered tb-action du-table -50">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ textAlign: 'left' }} >Management type</th>
                        <th style={{ textAlign: 'left' }} >Client Category</th>
                        <th style={{ width: '100px' }}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {managementTypeList.length > 0 ? (
                        managementTypeList.map((data, i) => (
                            <tr key={data.ID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.toggleModalUpdate(data.ID)} /></a>
                                        {data.IsActive == 1 ? <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.ID)} /></span></a>
                                            : <></>}
                                    </div>
                                </td>
                                <td style={{ textAlign: 'left' }}>{data.MemberType}</td>
                                <td style={{ textAlign: 'left' }}>{data.ClientCategory}</td>
                                <td style={{ textAlign: 'center' }}>{data.IsActive ? "Active" : "Inactive"}</td>
                            </tr>
                        ))

                    ) : (<tr><td colSpan={5} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }

    render() {
        const { isSearchTab, isAccessToView, isOpen, isLoading, status, _isAdd, _isUpdate,
             _isDelete,  clientCategoryId, clientCategoryList, managementTypeList, managementType, search_clientCategory } = this.state
             const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = managementTypeList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(managementTypeList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />


                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>Management Type</a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className='page-space-from-bottom'>
                                    <div className="row">
                                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n.accordion-button::after {\nbackground-size: 13px;\ndisplay: none;\n} \n" }} />
                                        <div className="col-md-12 pg-body grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body pr-2">
                                                    <div className="accordion accordion-flush " id="accordionFlushExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="flush-headingOne">
                                                            <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                                                   <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                </button>
                                                            </h2>
                                                            { isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                                <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                    <div className="d-flex flex-wrap row">
                                                                        <div className="form-group col-md-3">
                                                                            <label>Management Type</label>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                value={search_clientCategory}
                                                                                onChange={(e) => this.setState({ search_clientCategory: e.target.value })}
                                                                                required />
                                                                        </div>

                                                                        <div className="form-group col-md-3">
                                                                            <label>
                                                                                Client Category
                                                                            </label>
                                                                            <select
                                                                                className="js-example-basic-single"
                                                                                style={{ width: "100%" }}

                                                                                value={clientCategoryId}
                                                                                onChange={e => this.setState({ clientCategoryId: e.target.value != "Select" ? e.target.value : "" })}

                                                                            >
                                                                                 <option value={''}>Select</option>
                                                                                {clientCategoryList.length > 0 ? clientCategoryList.map(item => (
                                                                                    <option
                                                                                        key={item.ClientCategoryID}
                                                                                        value={item.ClientCategoryID}>
                                                                                        {item.ClientCategory}
                                                                                    </option>
                                                                                )) : <></>}
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group col-md-3">
                                                                            <label>Status</label>
                                                                            <select className="js-example-basic-single" style={{ width: '100%' }}
                                                                                value={status}
                                                                                onChange={(e) => this.setState({ status: e.target.value })}>
                                                                                 <option value={''}>Select</option>
                                                                                <option value={1}>Active</option>
                                                                                <option value={0}>Inactive</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group col-md-3 filter-btn-col p-0">
                                      <label></label>
                                      <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={e => this.handleSearch()}> Search</button>
                                      <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3" onClick={e => this.clearValue()}> Clear</button>
                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>  : <></>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 mt-1">
                                                            <button type="button" className="btn btn-Add rounded-1 float-right px-3 mb-1" onClick={(e) => this.toggleModalAdd(e)}> Add</button>
                                                        </div>
                                                        <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                            {this.record(currentRecords)}
                                                            {managementTypeList.length > 0 ? <Pagination
                                                                nPages={nPages}
                                                                currentPage={currentPage}
                                                                setCurrentPage={this.updateItem}
                                                                recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                 setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                   totalRecords={managementTypeList.length}
                                                                 /> : <></>}
                                                                                                                                     </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>


                <ToastContainer />
                {/* // modal for delete */}
                {/* <Modal isOpen={isOpen && _isDelete} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}


                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
          <div className="modal-dialog custom_modal_dialog">
            <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
              <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                </div>
              </div>
              <div class="modal-footer p-1">
                  <div class="form-group text-right">
                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e)}> Yes</a>
                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                </div>
              </div>
            </div>
          </div>
        </Modal>

                {/* Modal for add and update */}
                <Modal isOpen={(isOpen && _isAdd) || (isOpen && _isUpdate)} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Management Type</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body pt-1">
                                <div className='row'>
                                <div class="form-group col-12">
                                    <label>Management Type<span class="required1">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={managementType}
                                        onChange={(e) => this.setState({ managementType: e.target.value })}
                                        required
                                    />
                                </div>
                                </div>
                                <div className='row'>
                                <div className="form-group col-12">
                                    <label>
                                        Client Category
                                    </label>
                                    <select
                                        className="js-example-basic-single"
                                        style={{ width: "100%" }}

                                        value={clientCategoryId}
                                        onChange={e => this.setState({ clientCategoryId: e.target.value != "Select" ? e.target.value : "" })}

                                    >
                                         <option value={''}>Select</option>
                                        {clientCategoryList.length > 0 ? clientCategoryList.map(item => (
                                            <option
                                                key={item.ClientCategoryID}
                                                value={item.ClientCategoryID}>
                                                {item.ClientCategory}
                                            </option>
                                        )) : <></>}
                                    </select>
                                </div>
                                </div>
                            </div>

                            <div class="modal-footer p-1">
                                    <div class="form-group text-left mod-fot m-2">
                                        <div
                                            className="btn-group"
                                            role="group"
                                            aria-label="Basic example"
                                        >
                                            {
                                                this.state._isAdd && <button
                                                    type="button"
                                                    className="btn btn-save footer-btn-inner mr-1"
                                                    onClick={(e) => this.handleAdd(e)}
                                                >
                                                    Save
                                                </button>
                                            }

                                            {
                                                this.state._isUpdate &&
                                                <button
                                                    type="button"
                                                    className="btn btn-update footer-btn-inner mr-1"
                                                    onClick={(e) => this.handleUpdate(e)}
                                                >
                                                    Update
                                                </button>
                                            }
                                            {
                                                this.state._isAdd &&
                                                <button
                                                    type="button"
                                                    className="btn btn-update footer-btn-inner mr-1"
                                                    onClick={() => this.clearValue()}
                                                >
                                                    Reset
                                                </button>
                                            }
                                            <button
                                                type="button"
                                                className="btn btn-cancel footer-btn-inner mr-1"
                                                onClick={this.handleCancel}
                                            >
                                                Cancel
                                            </button>
                                            
                                        </div>
                                    </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        );
    }


}


export default withRouter(connect(null, { managementTypeList_action, managementTypeDetails_action, managementTypeAdd_action, managementTypeUpdate_action, managementTypeDelete_action, fill_action })(ManagementType));