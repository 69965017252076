import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

const Pagination = ({ nPages, currentPage, setCurrentPage, recordsPerPage, setRecordsPerPage, totalRecords }) => {
  const MAX_VISIBLE_PAGES = 10;
  const [inputValue, setInputValue] = useState(recordsPerPage);

  useEffect(() => {
    setInputValue(recordsPerPage);
  }, [recordsPerPage]);

  // Calculations
  const startRecord = (currentPage - 1) * recordsPerPage + 1;
  const endRecord = Math.min(currentPage * recordsPerPage, totalRecords);

  const handlePageSizeChange = () => {
    const newSize = parseInt(inputValue, 10);
    if (!isNaN(newSize) && newSize > 0) {
      setRecordsPerPage(newSize);
    }
  };

  const nextPage = () => {
    if (currentPage < nPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const firstPage = () => setCurrentPage(1);
  const lastPage = () => setCurrentPage(nPages);

  const renderPageNumbers = () => {
    const pageNumbers = [...Array(nPages).keys()].map(page => page + 1);

    if (nPages <= MAX_VISIBLE_PAGES) {
      return pageNumbers.map(pgNumber => (
        <li
          key={pgNumber}
          className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}
        >
          <a
            onClick={() => setCurrentPage(pgNumber)}
            style={{ cursor: 'pointer' }}
            className="page-link"
          >
            {pgNumber}
          </a>
        </li>
      ));
    } else {
      const startPage = Math.max(currentPage - Math.floor(MAX_VISIBLE_PAGES / 2), 1);
      const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, nPages);

      return (
        <>
          {startPage > 1 && (
            <>
              <li className="page-item">
                <a
                  onClick={() => setCurrentPage(1)}
                  style={{ cursor: 'pointer' }}
                  className="page-link"
                >
                  1
                </a>
              </li>
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            </>
          )}
          {pageNumbers.slice(startPage - 1, endPage).map(pgNumber => (
            <li
              key={pgNumber}
              className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}
            >
              <a
                onClick={() => setCurrentPage(pgNumber)}
                style={{ cursor: 'pointer' }}
                className="page-link"
              >
                {pgNumber}
              </a>
            </li>
          ))}
          {endPage < nPages && (
            <>
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
              <li className="page-item">
                <a
                  onClick={() => setCurrentPage(nPages)}
                  style={{ cursor: 'pointer' }}
                  className="page-link"
                >
                  {nPages}
                </a>
              </li>
            </>
          )}
        </>
      );
    }
  };

  return (
    <div className="row m-0">
      <div className="col-md-6">
        <ul className="pagination" style={{ borderTop: '1px solid #d1d1d1' }}>
          <li className="page-item">
            <a
              className="page-link"
              style={{ cursor: 'pointer' }}
              onClick={firstPage}
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </a>
          </li>
          <li className="page-item">
            <a
              className="page-link"
              style={{ cursor: 'pointer' }}
              onClick={prevPage}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </a>
          </li>
          {renderPageNumbers()}
          <li className="page-item">
            <a
              className="page-link"
              style={{ cursor: 'pointer' }}
              onClick={nextPage}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </a>
          </li>
          <li className="page-item">
            <a
              className="page-link"
              style={{ cursor: 'pointer' }}
              onClick={lastPage}
            >
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </a>
          </li>
        </ul>
      </div>
      <div className="col-md-5 text-center d-flex align-items-center page-list-qty">
        <h6 className='m-0'>Page Size:</h6>
        <input
          type="text"
          className='form-control text-center'
          value={inputValue}
          maxLength={4}
          onChange={(e) => setInputValue(e.target.value)}
          style={{ marginLeft: '10px', width: '60px', padding: '15px' }}
        />
        <button
          className="btn btn-primary mx-2 p-2"
          onClick={handlePageSizeChange}
        >
          Change
        </button>

        <h6 className='mx-4 mt-2'>Item {startRecord} to {endRecord} of {totalRecords}</h6>
      </div>
      {/* <div className="col-md-3 text-center d-flex align-items-center">
        <h6>Item {startRecord} to {endRecord} of {totalRecords}</h6>
      </div> */}
    </div>
  );
};

export default Pagination;
