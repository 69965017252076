import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {templateUrl,
    checkListUrl,
    addcheckListTemplateUrl,
    checkListTemplateDetailsUrl,
    deletecheckTemplateUrl,
    updateCheckTemplateUrl,
    TEMPLATE_ACTION_TYPE,
    fetchAuthReport, 
    checklistReportName,
    CHECK_LIST_TEMPLATE_ACTION_TYPE,CHECK_LIST_TEMPLATE_DETAILS_ACTION_TYPE,
    CHECK_LIST_TEMPLATE_ADD_ACTION_TYPE,CHECK_LIST_TEMPLATE_UPDATE_ACTION_TYPE,
    CHECK_LIST_TEMPLATE_DELETE_ACTION_TYPE,
    CHECK_LIST_TEMPLATE_DOWNLOAD_ACTION_TYPE,
    platfarm, headers} from "../../utils/constants"


const user_id = localStorage.getItem('UserId')

// get template list
export const Template_action = (name, status) => async (dispatch) => { 
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: name,
            status: status
        }
        const res = await axiosGet(templateUrl, param);
        dispatch({
            type: TEMPLATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

// template check list for add/update page
export const checkListTemplate_action = () => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: '',
            shortName: '',
            Status: 1
        }        

        const res = await axiosGet(checkListUrl, param);
        dispatch({
            type: CHECK_LIST_TEMPLATE_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

// get template details for update
export const checkListTemplateDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            Id: id,
            userId: localStorage.getItem('UserId'),
            Platform: platfarm,
        }        
        const res = await axiosGet(checkListTemplateDetailsUrl, param);
        dispatch({
            type: CHECK_LIST_TEMPLATE_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const addcheckListTemplate_action = (checkListTemplateName, checkList) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ChecklistTemplateName: checkListTemplateName,
            checkList : checkList
        }
        const res = await axiosPost(addcheckListTemplateUrl, mapdata, headers);
        dispatch({
            type: CHECK_LIST_TEMPLATE_ADD_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updatecheckListTemplate_action = (id,checkListTemplateName,checkList) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ChecklistTemplateHdrID: id,
            ChecklistTemplateName: checkListTemplateName,
            checkList : checkList
        }

        const res = await axiosPost(updateCheckTemplateUrl, mapdata, headers);

        dispatch({
            type: CHECK_LIST_TEMPLATE_UPDATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const downloadTemplate_action = (id) => async (dispatch) => {
    try {
        var list = [];
        const mapdata1 = {
            "ParameterName":"@Id",
            "ParameterValue":id,
            "ParameterDataType":"int"
        }
        
        list.push(mapdata1);

        const mapData = {
            "user_id": localStorage.getItem('UserId'),
            "report_name": checklistReportName,
            "parameter_details": list
        }

        const res = await axiosPost(fetchAuthReport, mapData);

        dispatch({
            type: CHECK_LIST_TEMPLATE_DOWNLOAD_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const deletecheckListTemplate_action = (id) => async (dispatch) => {
    try {
        const param = {
            Id: id,
            userId: localStorage.getItem('UserId'),
            Platform: platfarm,

        }
        const res = await axiosGet(deletecheckTemplateUrl, param);
        dispatch({
            type: CHECK_LIST_TEMPLATE_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const simpleAction = () => dispatch => {
    dispatch({
     type: 'SIMPLE_ACTION',
     payload: 'result_of_simple_action'
    })
   }