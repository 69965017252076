import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    platfarm, headers,
    EXPENSE_ACTION_TYPE,
    EXPENSE_DETAILS_ACTION_TYPE,
    EXPENSE_ADD_ACTION_TYPE,
    EXPENSE_UPDATE_ACTION_TYPE,
    EXPENSE_DELETE_ACTION_TYPE,
    EXPENSE_GET_TIMESHEETID_ACTION_TYPE,
    expenseUrl, expenseDetailsUrl,
    addExpenseUrl, deleteExpenseUrl,
    updateExpenseUrl, getTimesheetIdUrl,
    approvalExpenseUrl, EXPENSE_APPROVAL_ACTION_TYPE,
    fillListUrl, FILL_LIST_ACTION_TYPE, fetchAuthReport, engAllocationReportName,
    deleteExpenseDocumentUrl, EXPENSE_DELETE_DOCUMENT_ACTION_TYPE,

} from "../../utils/constants"
import moment from "moment";




export const expense_action = (clientName, finYearId, engId, fromDate, toDate, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientID: clientName != 'null' ? clientName : null,
            FinyearID: finYearId != 'null' ? finYearId : null,
            EngagementID: engId != 'null' ? engId : null,
            FromDate: fromDate != null && fromDate != '' ? moment(fromDate).format('YYYY-MM-DD') : null,
            ToDate: toDate != null && toDate != '' ? moment(toDate).format('YYYY-MM-DD') : null,
            status: status != 'null' ? status : null,
        }

        const res = await axiosGet(expenseUrl, param);
        dispatch({
            type: EXPENSE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getTimesheetId_action = (transactionForId, clientNameId, finYearId,
    engagementId, employeeId) => async (dispatch) => {
        try {
            const param = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                TransactionForID: transactionForId != '' ? transactionForId : null,
                ClientID: clientNameId != '' ? clientNameId : null,
                FinancialYearID: finYearId != '' ? finYearId : null,
                EngagementID: engagementId != '' ? engagementId : null,
                EmployeeID: employeeId != '' ? employeeId : null,
            }
            const res = await axiosGet(getTimesheetIdUrl, param);
            dispatch({
                type: EXPENSE_GET_TIMESHEETID_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };


export const expenseDetails_action = (id) => async (dispatch) => {
    try {
        const param = {
            ID: id,
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }

        const res = await axiosGet(expenseDetailsUrl, param);
        dispatch({
            type: EXPENSE_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

// export const addExpense_action = (timeSheetID, transactionForId, employeeId, clientNameId, finYearId,
//     engagementId,  expenseTypeID, date, expenseHeadDescription, description,
//     remark, amount, approvedBy, rejectionReason, isPaidByCreditCard, isNeedSupporting, documentFilesList) => async (dispatch) => {
export const addExpense_action = (timeSheetID, expenseTypeID, description, amount, remark, isNeedSupporting, isPaidByCreditCard, ownerID, transactionForID, clientID, financeYearID, engagementID, transactionDate, documentFilesList) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            TimeSheetID: timeSheetID,
            ExpenseTypeID: expenseTypeID,
            Description: description,
            Amount: amount,
            Remark: remark,
            needsupporting: isNeedSupporting,
            Paidby: isPaidByCreditCard,
            OwnerID: ownerID,
            TransactionForID: transactionForID,
            ClientID: clientID,
            FinanceYearID: financeYearID,
            EngagementID: engagementID,
            TransactionDate: moment(transactionDate).format('YYYY-MM-DD'),
            DocumentFilesList: documentFilesList
        }
        console.log("addExpense_action", mapdata)
        const res = await axiosPost(addExpenseUrl, mapdata, headers);

        dispatch({
            type: EXPENSE_ADD_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateExpense_action = (id, transactionForId, employeeId, clientNameId, finYearId,
    engagementId, timeSheetID, expenseTypeID, date, expenseHeadDescription, description,
    remark, amount, approvedBy, rejectionReason, isPaidByCreditCard, isNeedSupporting, documentFilesList) => async (dispatch) => {

        try {
            const mapdata = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                ID: id,

                TransactionForID: transactionForId,
                EmployeeID: employeeId,
                ClientID: clientNameId,
                FinancialYearID: finYearId,
                EngagementID: engagementId,

                TimeSheetID: timeSheetID,
                ExpenseTypeID: expenseTypeID,
                ExpenseDate: moment(date).format('MM-DD-YYYY'),

                ExpenseHeadDescription: '',
                Description: description,
                Remark: remark,
                Amount: amount,
                ApprovedBy: null,
                RejectReason: rejectionReason,
                needsupporting: isNeedSupporting,
                Paidby: isPaidByCreditCard,
                DocumentFilesList: documentFilesList

            }
            const res = await axiosPost(updateExpenseUrl, mapdata, headers);

            dispatch({
                type: EXPENSE_UPDATE_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const deleteExpense_action = (id) => async (dispatch) => {
    try {
        const param = {
            Id: id,
            userId: localStorage.getItem('UserId'),
            platform: platfarm,

        }
        const res = await axiosGet(deleteExpenseUrl, param);
        dispatch({
            type: EXPENSE_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};



export const fill_action = () => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
        }
        const res = await axiosGet(fillListUrl, param);
        dispatch({
            type: FILL_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const approvalExpense_action = (clientName, finYearId, EmployeeID, engId, TransactionForID, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientID: clientName,
            FinYearID: finYearId,
            EmployeeID: EmployeeID,
            EngagementID: engId,
            TransactionForID: TransactionForID,
            Status: status
        }

        const res = await axiosGet(approvalExpenseUrl, param);
        dispatch({
            type: EXPENSE_APPROVAL_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};



export const simpleAction = () => dispatch => {
    dispatch({
        type: 'SIMPLE_ACTION',
        payload: 'result_of_simple_action'
    })
}


export const deleteExpenseDocument_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserId: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }
        const res = await axiosGet(deleteExpenseDocumentUrl, param);
        dispatch({
            type: EXPENSE_DELETE_DOCUMENT_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


