import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { fill_action } from '../../../actions/master_action/checklist_action'
import { leaveList_action, deleteLeave_action } from '../../../actions/time_sheet_expense_action/leave_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Modal from "react-modal";
import { clearLocalStorage, Leave, timesheetStatusActionId } from '../../../utils/constants'
import Pagination from '../../../utils/pagination'
import Loader from '../../../utils/Loader'
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';
/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
    // loginAction: () =>hidden scroll dispatch(loginAction())
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));

class LeaveList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAccessToView: true,
            LeaveID: '',
            userId: localStorage.getItem('UserId'),
            leaveList: [],
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage: 10,
            pageSizeInput: "10",
            fromDate: '',
            toDate: '',
            statusId: '',
            statusIdList: [],
            date: new Date(),
            leaveDeleteId: '',
            fromDateLLS: '',
            toDateLLS: '',
            statusIdLLS: '',
            isOpen: false,
            isSearchTab: false,
        }
        this.getLeaveList = this.getLeaveList.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);

        this.handleAdd = this.handleAdd.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
    }

    componentDidMount = () => {


        this.setState({
            // isAccessToView: localStorage.getItem('UserId') == '1' ? true : false,
            fromDateLLS: localStorage.getItem('fromDateLLS'),
            toDateLLS: localStorage.getItem('toDateLLS'),
            statusIdLLS: localStorage.getItem('statusIdLLS'),

            fromDate: localStorage.getItem('fromDateLLS') != '' ? moment(localStorage.getItem('fromDateLLS')).toDate() : '',
            toDate: localStorage.getItem('toDateLLS') != '' ? moment(localStorage.getItem('toDateLLS')).toDate() : '',
            statusId: localStorage.getItem('statusIdLLS') != '' ? localStorage.getItem('statusIdLLS') : '',
        })
        this.getFillList(timesheetStatusActionId, '')

        setTimeout(() => {
            clearLocalStorage("leave")
            this.getLeaveList()
        }, 100);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    // get fill list 
    getFillList(value, ref) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(value, ref)
            .then((data) => {
                if (data.error != 1) {
                    if (value == timesheetStatusActionId) {
                        this.setState({ statusIdList: data.data, isLoading: false })
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    // get leave list
    getLeaveList() {
        const { statusId, fromDate, toDate } = this.state
        this.setState({ isLoading: true })
        clearLocalStorage("leave")
        this.props
            .leaveList_action(statusId != 1 && statusId != 3 && statusId != 4 && statusId != 5 ? '0' : statusId, fromDate == '' ? null : fromDate, toDate == '' ? null : toDate)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ leaveList: data.data })
                    setTimeout(() => {
                        this.setState({ isLoading: false, currentPage: 1, })
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })

                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

        // localStorage.setItem("fromDateLLS", fromDate)
        // localStorage.setItem("toDateLLS", toDate)
        // localStorage.setItem("statusIdLLS", statusId)
    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }

    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
            this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
    };

    handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
            this.setState({ pageSizeInput: input });
        }
    };


    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    //search
    handleSearch() {
        const { statusId, fromDate, toDate } = this.state
        clearLocalStorage("leave")
        this.setState({ isLoading: true })
        console.log(statusId, "f", fromDate, "t", toDate)
        if ((this.state.fromDate == '') && (this.state.toDate != '')) {
            this.setState({ isLoading: false })
            toast.error("Please enter from date", {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
        else {
            this.props
                .leaveList_action(statusId == 'Select' || statusId == '' ? null : statusId, fromDate == '' ? null : fromDate, toDate == '' ? null : toDate)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ leaveList: data.data, isLoading: false, currentPage: 1 })
                        console.log(data)
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.data, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        }
        localStorage.setItem("fromDateLLS", fromDate)
        localStorage.setItem("toDateLLS", toDate)
        localStorage.setItem("statusIdLLS", statusId)
    }

    //clear value
    clearValue() {
        this.setState({
            leaveList: [],
            currentPage: 1,
            fromDate: '',
            toDate: '',
            statusId: '',
            date: new Date(),

            fromDateLLS: '',
            toDateLLS: '',
            statusIdLLS: '',
        })
        clearLocalStorage('')
        setTimeout(() => {
            this.getLeaveList()
        }, 300);
    }

    // add leave
    handleAdd() {
        this.props.navigate('/leaveAU', { state: { is_add: true } })
    }

    // update list
    handleUpdate(leaveID) {
        this.props.navigate('/leaveAU', { state: { is_update: true, leaveID: leaveID } })
    }

    //toggal for delete
    toggleModal(leaveID) {
        console.log(leaveID)
        const { isOpen, leaveDeleteId } = this.state
        this.setState({ isOpen: !isOpen })
        if (leaveID == '') {
            return
        }
        else {
            this.setState({ leaveDeleteId: leaveID })
        }
    }

    //delete handle
    handleDelete(e, LeaveID) {
        e.preventDefault()
        var id = LeaveID
        this.setState({ isLoading: true })
        this.props
            .deleteLeave_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toast.success('Data deleted successfully', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                    this.setState({ isOpen: !this.state.isOpen, isLoading: false })
                    this.getLeaveList()
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }


    record(leaveList) {
        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }}></th>
                        <th style={{ textAlign: 'center' }}>Employee</th>
                        <th style={{ width: '150px' }}>From Date</th>
                        <th style={{ width: '150px' }}>To Date</th>
                        <th style={{ width: '150px' }}>Leave Type</th>
                        <th style={{ width: '200px' }}>Status</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        leaveList.length > 0 ?
                            leaveList.map((data, index) => (
                                <>
                                    <tr key={data.LeaveID}>
                                        <td className="text-center" style={{ padding: '5px!important' }}>
                                            <div className="table__button-group">
                                                {
                                                    // data.LeaveStatus == 2 ?
                                                    // <a> <i className="fa fa-pencil" style={{ color: '#aac722' }}
                                                    //     onClick={() => this.handleUpdate(data.LeaveID)} /></a>
                                                    //  : null
                                                }
                                                {data.LeaveStatus == 3 ?
                                                    <a> <i class="fa fa-eye" style={{ color: '#3498db' }}
                                                        onClick={() => this.handleUpdate(data.LeaveID)} /></a>
                                                    :
                                                    <>
                                                        {getModuleUpdateAccess(Leave) ?
                                                            <a> <i className="fa fa-pencil" style={{ color: '#aac722' }}
                                                                onClick={() => this.handleUpdate(data.LeaveID)} /></a> :
                                                            <a> <i className="fa fa-eye" style={{ color: '#3498db' }}
                                                                onClick={() => this.handleUpdate(data.LeaveID)} /></a>
                                                        }
                                                        {data.Name != 'Deleted' && getModuleDeleteAccess(Leave) ? <a> <span className="text-danger"><i className="fa fa-trash-o"
                                                            onClick={() => this.toggleModal(data.LeaveID)} /></span></a>
                                                            : <></>}
                                                    </>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>{data.EmployeeName}</td>
                                        <td>{data.LeaveFromDate != null && moment(data.LeaveFromDate).format('DD-MM-YYYY')}</td>
                                        <td>{data.LeaveToDate != null && moment(data.LeaveToDate).format('DD-MM-YYYY')}</td>
                                        <td>{data.LeaveType}</td>
                                        <td>{data.Name}</td>
                                    </tr></>
                            )) : <tr><td colSpan={13} className='text-center'>Record not found</td></tr>
                    }

                </tbody>
            </table>
        )
    }


    render() {
        const { isSearchTab, isLoading, isAccessToView, statusIdList, isOpen, leaveDeleteId, leaveList } = this.state

        const { currentPage, recordsPerPage, pageSizeInput } = this.state;

        // const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // // Records to be displayed on the current page
        const currentRecords = leaveList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(leaveList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {/* {isAccessToView ? ( */}
                        <div className="main-panel main-box">
                            <div className="content-wrapper">

                                <div className="main-breadcum">
                                    <ul className="breadcrumb">

                                        <li><a href="#">Leave</a></li>
                                        <li />
                                    </ul>
                                </div>
                                <div className='page-space-from-bottom'>
                                    <div className="row">
                                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                        <div className="col-md-12 pg-body grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body pr-2">
                                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="flush-headingOne">
                                                                <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">                                                                <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                                                </button>
                                                            </h2>
                                                            {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                                                            <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                                                <div className="d-flex flex-wrap row">
                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            From Date<span className="required1">*</span>
                                                                        </label>
                                                                        <div className="form-group d-flex">
                                                                            <DatePicker className="form-control"
                                                                                dateFormat="dd-MM-yyyy"
                                                                                selected={this.state.fromDate}
                                                                                maxDate={this.state.toDate}
                                                                                onChange={(date) => {
                                                                                    this.setState({ fromDate: date });

                                                                                }}
                                                                                peekNextMonth
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                placeholderText="From Date"
                                                                                customInput={<ExampleCustomInput />}
                                                                                dayClassName={() => "example-datepicker-day-class"}
                                                                                popperClassName="example-datepicker-class"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label>
                                                                            To Date<span className="required1">*</span>
                                                                        </label>
                                                                        <div className="form-group d-flex">
                                                                            <DatePicker className="form-control"
                                                                                dateFormat="dd-MM-yyyy"
                                                                                selected={this.state.toDate}
                                                                                minDate={this.state.fromDate}
                                                                                onChange={(date) => {
                                                                                    this.setState({ toDate: date });
                                                                                }}
                                                                                peekNextMonth
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                placeholderText="From Date"
                                                                                customInput={<ExampleCustomInput />}
                                                                                dayClassName={() => "example-datepicker-day-class"}
                                                                                popperClassName="example-datepicker-class"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-md-3 mt-1">
                                                                        <label>Status</label>
                                                                        <select className="js-example-basic-single"
                                                                            style={{ width: '100%' }}
                                                                            value={this.state.statusId}
                                                                            onChange={(e) => this.setState({ statusId: e.target.value })}
                                                                        >
                                                                            <option value={''}>Select</option>
                                                                            {statusIdList.length > 0 && statusIdList.slice(1).map(data => (
                                                                                <option
                                                                                    key={data.TimeSheetStatusID}
                                                                                    value={data.TimeSheetStatusID}>
                                                                                    {data.Name}
                                                                                </option>
                                                                            ))}

                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-md-3 mt-4 text-left" style={{ textAlign: 'left' }}>
                                                                        <button type="button" className="btn btn-Add rounded-1" style={{ marginTop: '4px' }}
                                                                            onClick={this.handleSearch}
                                                                        > Search</button>
                                                                        <button type="button" className="btn btn-clear rounded-1 mx-1 px-3" style={{ marginTop: '4px' }}
                                                                            onClick={this.clearValue}
                                                                        > Clear</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                                : <></>}
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-12 mt-2">
                                                            {getModuleWriteAccess(Leave) && <button type="button" className="btn btn-Add rounded-1 mb-2 px-3"
                                                                style={{ float: 'right' }} onClick={this.handleAdd}
                                                            >Add</button>}
                                                            {/* button */}
                                                            <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                                {this.record(currentRecords)}
                                                                {leaveList.length > 0 ? <Pagination
                                                                    nPages={nPages}
                                                                    currentPage={currentPage}
                                                                    setCurrentPage={this.updateItem}
                                                                    recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                    setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                    totalRecords={leaveList.length}
                                                                /> : <></>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ) : (<div className='access-msg h-auto'>
                        <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
 </div>)} */}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>
                <ToastContainer />
                {/* modal for delete */}
                {/* <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3 class="pop_label">Do you really want to delete this record?</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-right mod-fot">
                                        <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, leaveDeleteId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                                        <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}

                <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
                            <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right">
                                    <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, leaveDeleteId)}> Yes</a>
                                    <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}


export default withRouter(connect(null, { leaveList_action, fill_action, deleteLeave_action })(LeaveList));