import { axiosGet, axiosPost } from "../../react_axios/Axios"
import { headers, MARTIAL_STATUS_LIST_ACTION_TYPE, MARTIAL_STATUS_ADD_ACTION_TYPE, MARTIAL_STATUS_UPDATE_ACTION_TYPE, MARTIAL_STATUS_DETAILS_ACTION_TYPE, deleteMartialStatusUrl, MARTIAL_STATUS_DETETE_ACTION_TYPE, martialStatusListUrl, addMartialStatusUrl, updateMartialStatusUrl, martialStatusDetailsUrl, platfarm } from "../../utils/constants"

export const martialStatusList_action = (status, martialStatusnameSearch) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Status: status,
            MartialStatusnameSearch: martialStatusnameSearch != '' ? martialStatusnameSearch : null
        }
        console.log(param)
        const res = await axiosGet(martialStatusListUrl, param)
        console.log(res)
        dispatch({
            type: MARTIAL_STATUS_LIST_ACTION_TYPE,
            payload: res
        })

        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const martialStatusDetail_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id
        }
        const res = await axiosGet(martialStatusDetailsUrl, param)
        console.log(res)
        dispatch({
            type: MARTIAL_STATUS_DETAILS_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const addMartialStatus_action = (martialStatus) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            MaritalStatus: martialStatus,
        }
        const res = await axiosPost
            (addMartialStatusUrl, mapdata, headers)
        dispatch({
            type: MARTIAL_STATUS_ADD_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const updateMartialStatus_action = (id, martialStatus) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
            MaritalStatus: martialStatus,
        }
        const res = await axiosPost(updateMartialStatusUrl, mapdata, headers)
        dispatch({
            type: MARTIAL_STATUS_UPDATE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const deleteMartialStatus_action = (id) =>
    async (dispatch) => {
        try {
            const param = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                ID: id,
            }
            console.log(param)
            const res = await axiosGet
                (deleteMartialStatusUrl, param)
            dispatch({
                type: MARTIAL_STATUS_DETETE_ACTION_TYPE,
                payload: res
            })
            return Promise.resolve(res)
        } catch (err) {
            return Promise.reject(err)
        }
    }

export default (state = {}, action) => {
    switch (action.type) {
        case 'SIMPLE_ACTION':
            return {
                result: action.payload
            }
        default:
            return state
    }
}