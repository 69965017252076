
import { DEPARTMENT_ADD_ACTION_TYPE, DEPARTMENT_DELETE_ACTION_TYPE, DEPARTMENT_DETAILS_ACTION_TYPE, DEPARTMENT_LIST_ACTION_TYPE, DEPARTMENT_UPDATE_ACTION_TYPE } from "../../utils/constants";

const initialState = []

function departmentReducer(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case DEPARTMENT_LIST_ACTION_TYPE:
            return [...state, payload];
        case DEPARTMENT_ADD_ACTION_TYPE:
            return [...state, payload];
        case DEPARTMENT_DETAILS_ACTION_TYPE:
            return [...state, payload];
        case DEPARTMENT_UPDATE_ACTION_TYPE:
            return [...state, payload];
        case DEPARTMENT_DELETE_ACTION_TYPE:
            return [...state, payload];
        default:
            return state;
    }
}

export default departmentReducer