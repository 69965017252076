
import {
    CLIENT_ENGAGEMENT_ACTION_TYPE ,
    CLIENT_ENGAGEMENT_DETAILS_ACTION_TYPE ,
    CLIENT_ENGAGEMENT_ADD_ACTION_TYPE ,
    CLIENT_ENGAGEMENT_UPDATE_ACTION_TYPE ,
    CLIENT_ENGAGEMENT_DELETE_ACTION_TYPE ,
    CLIENT_ENGAGEMENT_DESIGNATION_LIST_ACTION_TYPE
 } from "../../utils/constants"


const initialState = [];

function clientEnagementReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLIENT_ENGAGEMENT_ACTION_TYPE:
      return [...state, payload];
      case CLIENT_ENGAGEMENT_DETAILS_ACTION_TYPE:
      return [...state, payload];
    case CLIENT_ENGAGEMENT_ADD_ACTION_TYPE:
      return [...state, payload];
    case CLIENT_ENGAGEMENT_UPDATE_ACTION_TYPE:
      return [...state, payload];
    case CLIENT_ENGAGEMENT_DELETE_ACTION_TYPE:
      return [...state, payload];
      case CLIENT_ENGAGEMENT_DESIGNATION_LIST_ACTION_TYPE:
      return [...state, payload];
    default:
      return state;
  }
};

export default clientEnagementReducer;


// export default (state = {}, action) => {
//     switch (action.type) {
//      case 'SIMPLE_ACTION':
//       return {
//        result: action.payload
//       }
//      default:
//       return state
//     }
//    }