import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import {
  engagementAllocationDetails_action,
  addEngagementAllocation_action, updateEngagementAllocation_action,
  deleteEngagementAllocation_action
} from '../../../actions/engagement_action/engagement_allocation_action'
import { fill_action } from '../../../actions/master_action/checklist_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import { clientsId, financialYearId, engagementPartnerId, engagementTypeId, qualityReviewerId, Engagement_Allocation } from '../../../utils/constants'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from 'react-icons/fa';
import "react-datepicker/dist/react-datepicker.css";
import { getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';
/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
  ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
  <input
    value={value}
    className="example-custom-input"
    onClick={onClick}
    onChange={onChange}
    disabled={disabled}
    ref={ref}
  ></input>
));


class EngagementAllocationAU extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkListTemplateName: '',
      checkListData: [],
      is_critical: false,

      is_add: false,
      is_delete: false,
      is_update: false,
      isLoading: false,
      engagementAllocationId: '',
      finYear: '',
      finYearList: [],
      startDate: '',
      endDate: '',
      clientNameList: [],
      tempClientNameList: [],
      clientNameId: '',
      engPartnerName: '',
      engPartnerNameList: [],
      qualityReviewerName: '',
      qualityReviewerNameList: [],
      engagementTypeName: '',
      engagementTypeNameList: [],
      remark: '',
      status: 1,
      isOpen: false,
      engagementAllocationDeleteId: '',
      searchClientsName: '',
      is_allSelected: false,
      is_allDeselected: false,
      details_clientId: '',
      isActive: '',
      isAccessToView: true,

    }
    this.handleSaveAndApprove = this.handleSaveAndApprove.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleIscritical = this.handleIscritical.bind(this)
    this.handleActionId = this.handleActionId.bind(this)
    this.getEngagementAllocationDetails = this.getEngagementAllocationDetails.bind(this)
    this.getFinYearList = this.getFinYearList.bind(this)
    this.onSelectFinYearDropdown = this.onSelectFinYearDropdown.bind(this)
    this.getEngPartnerNameList = this.getEngPartnerNameList.bind(this)
    this.getEngagementTypeNameList = this.getEngagementTypeNameList.bind(this)
    this.getQualityReviewerNameList = this.getQualityReviewerNameList.bind(this)
    this.getClientsList = this.getClientsList.bind(this)
    this.onSearchClientsName = this.onSearchClientsName.bind(this)
    this.allSelect = this.allSelect.bind(this)
    this.allDeselect = this.allDeselect.bind(this)
    this.onClientNameChecked = this.onClientNameChecked.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onSaveAndMail = this.onSaveAndMail.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    
  }

  componentDidMount() {
    this.setState({
      is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
      is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
      is_delete: this.props.location.state != null && this.props.location.state.is_delete != null ? this.props.location.state.is_delete : false,
      engagementAllocationId: this.props.location.state != null && this.props.location.state.engagementAllocationId != null ? this.props.location.state.engagementAllocationId : '',
      isAccessToView: true,
    })
    this.getClientsList()

    this.getFinYearList()
    this.getEngPartnerNameList()
    this.getEngagementTypeNameList()
    this.getQualityReviewerNameList()
    this.props.location.state != null && this.props.location.state.is_update && this.getEngagementAllocationDetails()
    // document.addEventListener('keydown', this.handleTabKeyPress);
    // document.addEventListener('click', this.handleInputClick);

  }

  componentWillUnmount() {
    // document.removeEventListener('keydown', this.handleTabKeyPress);
    // document.removeEventListener('click', this.handleInputClick);

  }

  handleTabKeyPress = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
      const focusedElement = document.activeElement;
      const { previouslyFocusedElement } = this.state;

      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }

      if (focusedElement.classList.contains('form-control') ||
        focusedElement.classList.contains('js-example-basic-single') ||
        focusedElement.classList.contains('example-custom-input')) {
        focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
        this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
      }
    }
  };


  handleInputClick = (event) => {
    const { previouslyFocusedElement } = this.state;
    const clickedElement = event.target;

    if (previouslyFocusedElement) {
      previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    }

    if (clickedElement.classList.contains('form-control') ||
      clickedElement.classList.contains('js-example-basic-single') ||
      clickedElement.classList.contains('example-custom-input')) {
      clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
      this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
    } else {
      this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
    }
  };
  // get Quality Reviewer Name List dropdown list 
  getQualityReviewerNameList() {
    this.setState({ isLoading: true })
    this.props
      .fill_action(qualityReviewerId, '')
      .then((data) => {
        if (data.error != 1) {
          this.setState({ qualityReviewerNameList: data.data, isLoading: false })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }

  // get clients List dropdown list 
  getClientsList() {
    this.setState({ isLoading: true })
    this.props
      .fill_action(clientsId, '')
      .then((data) => {
        if (data.error != 1) {
          console.log('clientNameList', data.data)
          this.setState({
            clientNameList: data.data,
            tempClientNameList: data.data,
            // isLoading: false
          })
          setTimeout(() => {
            this.setState({ isLoading: false })
          }, 300);
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }

  // get engagement type dropdown list 
  getEngagementTypeNameList() {
    this.setState({ isLoading: true })
    this.props
      .fill_action(engagementTypeId, '')
      .then((data) => {
        if (data.error != 1) {
          this.setState({ engagementTypeNameList: data.data, isLoading: false })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }


  // Engagement Partner Name list  
  getEngPartnerNameList() {
    this.setState({ isLoading: true })
    this.props
      .fill_action(engagementPartnerId, '')
      .then((data) => {
        if (data.error != 1) {
          this.setState({ engPartnerNameList: data.data, isLoading: false })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }


  // fin year list  
  getFinYearList() {
    this.setState({ isLoading: true })
    this.props
      .fill_action(financialYearId, '')
      .then((data) => {
        if (data.error != 1) {
          this.setState({ finYearList: data.data, isLoading: false })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }

  // get Engagement Allocation details
  getEngagementAllocationDetails() {
    const { clientNameList, clientNameId } = this.state
    var id = this.props.location.state.engagementAllocationId
    this.setState({ isLoading: true })
    // await this.getClientsList()
    this.props
      .engagementAllocationDetails_action(id)
      .then((data) => {
        if (data.error != 1) {

          this.setState({
            finYear: data.data[0]['FinYearID'],
            qualityReviewerName: data.data[0]['QualityReviewerID'],
            engPartnerName: data.data[0]['EngagementPartnerID'],
            startDate: moment(data.data[0]['StartDate'], 'YYYY-MM-DD').toDate(),
            endDate: moment(data.data[0]['EndDate'], 'YYYY-MM-DD').toDate(),
            remark: data.data[0]['Remark'],
            engagementTypeName: data.data[0]['EngagementTypeID'],
            details_clientId: data.data[0]['ClientID'],
            isActive: data.data[0]['Status'],
            isLoading: false,
            clientNameId: data.data[0]['ClientID'],
          })

        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })

  }

  // add  new template
  handleSaveAndApprove(e) {
    e.preventDefault()
    const { is_add } = this.state
    is_add ? this.onSave() : this.handleUpdate(e)
  }

  onSave() {
    const { finYear, startDate, endDate, clientNameList, engPartnerName, qualityReviewerName, engagementTypeName, remark, clientNameId } = this.state
    if (finYear != "" && 
    ( startDate != "" && startDate != null && startDate != "Invalid Date" ) 
     && ( endDate != "" && endDate != null && endDate != "Invalid Date" ) 
      && engPartnerName != "" && engPartnerName != "Select" && engagementTypeName != "" && engagementTypeName != "Select" && qualityReviewerName != "" && qualityReviewerName != "Select" && clientNameId != "" && clientNameId != "Select") {
      this.toggleModal();

    } else {
      this.setState({ isLoading: false })
      toast.error('Please enter mandatory field', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: true,
      })
    }
  }

  // update template
  handleUpdate(e) {
    e.preventDefault()
    const { details_clientId, finYear, startDate, endDate, clientNameList, engPartnerName, qualityReviewerName, engagementTypeName, remark } = this.state
    // && qualityReviewerName != ""
    if (engPartnerName != null && engagementTypeName != null && qualityReviewerName != "") {
      // this.toggleModal();
      this.onSaveAndMail(e, false)
    } else {
      this.setState({ isLoading: false })
      toast.error('Please enter mandatory field', {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: true,
      })
    }

  }

  onSaveAndMail(e, email) {
    e.preventDefault()
    const { is_add } = this.state
    //for adding.......................
    if (is_add) {
      const { finYear, startDate, endDate, clientNameList, isOpen, engPartnerName, qualityReviewerName, engagementTypeName, remark, clientNameId } = this.state
      this.setState({ isLoading: true, isOpen: false })
      this.props
        .addEngagementAllocation_action(finYear, startDate, endDate, engPartnerName,
          engagementTypeName, qualityReviewerName, remark, clientNameId, email)
        .then((data) => {
          if (data.error == 0) {
            toast.success('Data added successfully', {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
            this.setState({ isLoading: false, isOpen: !isOpen })
            this.handleReset()
            setTimeout(() => {
              this.handleCancel()
            },  1000);
           
          }
          else {
            this.setState({ isLoading: false })
            toast.error(data.msg, {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      //for updating...................
      const { details_clientId, finYear, startDate, endDate, clientNameList, engPartnerName, qualityReviewerName, engagementTypeName, remark } = this.state
      this.setState({ isLoading: true, isOpen: false })
      var id = this.props.location.state.engagementAllocationId
      console.log("clientNameList", clientNameList)

      var list = clientNameList.filter((item) => {
        return item.clnnID === details_clientId
      })

      console.log("list", list)

      var clnID = [];
      for (let i = 0; i < list.length; i++) {
        clnID.push(list[i]['clnnID']);

      }
      clnID = clnID.toString();
      console.log("clnID", clnID)
      // qualityReviewerName
      this.props
        .updateEngagementAllocation_action(id, finYear, startDate, endDate, engPartnerName, engagementTypeName, qualityReviewerName, remark, details_clientId, email)
        .then((data) => {
          if (data.error != 1) {
            toast.success('Data updated successfully', {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
            this.setState({ isLoading: false })
            setTimeout(() => {
              this.handleCancel()
            },  1000);
          }
          else {
            this.setState({ isLoading: false })
            toast.error(data.msg, {
              theme: 'colored',
              autoClose: 3000,
              hideProgressBar: true,
            })
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }


  // delete template
  handleDelete(e) {
    e.preventDefault()
    var id = 1;
    this.setState({ isLoading: true })
    this.props
      .deleteEngagementAllocation_action(id)
      .then((data) => {
        if (data.error != 1) {
          toast.success('Data deleted successfully', {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
          this.setState({ isLoading: false })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }

  // clear field ,list
  handleCancel() {
    this.props.navigate('/engagementAllocation')
  }

  handleReset() {
    this.setState({
      finYear: '',
      startDate: '',
      clientNameId: '',
      endDate: '',
      engPartnerName: '',
      qualityReviewerName: '',
      engagementTypeName: '',
      remark: '',
    })
    this.getClientsList()

  }

  // on checked critical
  handleIscritical(e, data, index) {
    const { checkListData, is_add, is_update } = this.state
    if (is_add) {
      if (e.target.checked) {
        data['is_critical'] = !data['is_critical']
        data['action_id'] = 1
      }
      else {
        data['is_critical'] = !data['is_critical']
      }
      this.setState({ checkListData: checkListData })
    }
    else if (is_update) {
      if (e.target.checked) {
        data['is_critical'] = !data['is_critical']
        data['action_id'] = 3
      }
      else {
        data['is_critical'] = !data['is_critical']
        data['action_id'] = 3
      }
      this.setState({ checkListData: checkListData })
    }

  }

  // on checked action id
  handleActionId(e, data) {
    const { checkListData, is_add, is_update } = this.state
    if (is_add) {
      if (e.target.checked) {
        data['action_id'] = 1
      } else {
        data['action_id'] = 0
      }
      this.setState({ checkListData: checkListData })
    }
  }

  //on select fin year
  onSelectFinYearDropdown(e) {
    const { finYearList, startDate, endDate, finYear } = this.state
    if(e.target.value  == ''){
      this.setState({
        startDate:'',
        endDate: '',
        finYear: e.target.value 
      })
    }else{
      this.setState({ finYear: e.target.value })
      finYearList.map((element, index) => {
        if (element.FinYearID == e.target.value) {
          this.setState({
            startDate: moment(finYearList[index]['Fromdate'], 'YYYY-MM-DD').toDate(),
            endDate: moment(finYearList[index]['Todate'], 'YYYY-MM-DD').toDate(),
          })
        }
      })
    }
    
  }

  allSelect() {
    const { clientNameList, tempClientNameList } = this.state
    this.setState({
      is_allSelected: true,
    })
    for (let i = 0; i < clientNameList.length; i++) {
      clientNameList[i]['is_selected'] = true;
      tempClientNameList[i]['is_selected'] = true;
    }
    this.setState({
      clientNameList: clientNameList,
      tempClientNameList: tempClientNameList
    })
  }

  allDeselect() {
    const { clientNameList, tempClientNameList } = this.state
    this.setState({
      is_allDeselected: true
    })
    for (let i = 0; i < clientNameList.length; i++) {
      clientNameList[i]['is_selected'] = false;
      tempClientNameList[i]['is_selected'] = false;
    }
    this.setState({
      clientNameList: clientNameList,
      tempClientNameList: tempClientNameList
    })
  }

  onSearchClientsName(e) {
    const { clientNameList, searchClientsName, tempClientNameList } = this.state
    this.setState({ searchClientsName: e.target.value })
    if (e.target.value.length > 0) {
      const clientList = tempClientNameList.filter(data => {
        return (
          data.clnsClientFName.toLowerCase().includes(searchClientsName.toLowerCase())
        );
      });
      this.setState({ clientNameList: clientList })
    }
    else {
      this.setState({ clientNameList: tempClientNameList })
    }
  }

  onClientNameChecked(e, clnnID) {
    const { clientNameList } = this.state
    clientNameList.map((item, index) => {
      if (clnnID === clientNameList[index]['clnnID']) {
        clientNameList[index]['is_selected'] = !clientNameList[index]['is_selected'];
        this.setState({
          clientNameList: clientNameList
        })
      }
    })
  }

  //toggle model for delete.................................
  toggleModal() {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  render() {
    const { isAccessToView, clientNameId, is_add, details_clientId, is_update, isOpen, is_allSelected, is_allDeselected, searchClientsName, qualityReviewerName, qualityReviewerNameList, isLoading, finYearList,
      isActive, finYear, engPartnerNameList, engPartnerName, engagementTypeName, engagementTypeNameList, clientNameList } = this.state

    return (
      <>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          null
        )}
        <Header />
        <div className="container-scroller">
          {/* partial:partials/_navbar.html */}
          {/* partial */}
          <div className="container-fluid page-body-wrapper">
            {/* partial:partials/_settings-panel.html */}
            <Drawer />
            {/* start page */}
            {isAccessToView ? (
              <div className="main-panel main-box">
                <div className="content-wrapper">
                  <div className="main-breadcum">
                    <ul className="breadcrumb">

                      <li>
                        <a onClick={this.handleCancel}>Engagement Allocation </a>
                      </li>
                      <li>
                        <a>{this.state.is_add ? 'New' : 'Update'}</a>
                      </li>
                      <li />
                    </ul>
                  </div>
                  <div className="user-status">
                                {
                                  is_update  && isActive != '' ? <p>
                                    <strong className={isActive === 'Approved' ? 'greena' 
                                    : isActive != '' ? 'redi' : ''}> {isActive}</strong>
                                  </p> : <></>
                                }
                            </div>
                  <div
                    className="tab-content"
                    id="myTabContent">
                    <div className="row">
                      <style
                        type="text/css"
                        dangerouslySetInnerHTML={{
                          __html:
                            "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            "
                        }}
                      />

                      <div className="col-md-12 pg-body grid-margin stretch-card">
                        <div className="card">
                          <div className="card-body">

                            <div className="d-flex flex-wrap row ">
                              <div className="form-group col-md-3">
                                <label>
                                  Financial Year <span className="required1">*</span>
                                </label>
                                <select className={is_update ? 'bg-secondary' : ''} style={{ width: "100%" }}

                                  disabled={is_update ? true : false}
                                  value={finYear} onChange={e => this.onSelectFinYearDropdown(e)}>
                                  <option  value=''>Select</option>
                                  {finYearList.length > 0 ? finYearList.map(item => (
                                    <option
                                      key={item.FinYearID}
                                      value={item.FinYearID}>
                                      {item.FinancialYear}
                                    </option>
                                  )) : <></>}
                                </select>
                              </div>
                              <div className="form-group col-md-3">
                                <label>
                                  Start Date<span className="required1">*</span>
                                </label>
                                <div className="form-group d-flex">
                                  <DatePicker className="form-control"
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.startDate}
                                    disabled={true}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    // disabled={this.state.is_add ? true : false || is_update ? true : false}
                                    onChange={(date) => {
                                      this.setState({ startDate: date });

                                    }}
                                    placeholderText="Start Date"
                                  // customInput={<ExampleCustomInput />}
                                  // dayClassName={() => "example-datepicker-day-class"}
                                  // popperClassName="example-datepicker-class"
                                  />

                                  <FaCalendarAlt style={{
                                    flex: '0 0 19%',
                                    maxWidth: '19%',
                                    color: 'grey',
                                    marginTop: '7px',
                                    marginLeft: '-3rem',
                                    zIndex: '0'
                                  }} />
                                </div>
                              </div>
                              <div className="form-group col-md-3">
                                <label>
                                  End Date<span className="required1">*</span>
                                </label>
                                <div className="form-group d-flex">
                                  <DatePicker className="form-control"
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.endDate}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    disabled={this.state.is_add ? true : false || is_update ? true : false}
                                    onChange={(date) => {
                                      this.setState({ endDate: date });

                                    }}
                                    placeholderText="End Date"
                                  // customInput={<ExampleCustomInput />}
                                  // dayClassName={() => "example-datepicker-day-class"}
                                  // popperClassName="example-datepicker-class"
                                  />

                                  <FaCalendarAlt style={{
                                    flex: '0 0 19%',
                                    maxWidth: '19%',
                                    color: 'grey',
                                    marginTop: '7px',
                                    marginLeft: '-3rem',
                                    zIndex: '0'
                                  }} />
                                </div>
                              </div>
                            </div>
                            {/* <div className="d-flex flex-wrap row mt-2">
                            <div
                              className="form-group col-9"
                              style={{ position: "relative" }}
                            >

                              {is_add ? (<>
                                <label>
                                  Select Client's From Below List
                                  <span className="required1">*</span>
                                </label>
                                <div
                                  className="selectandunselect mb-2"
                                  style={{ top: 0, position: "absolute", right: 16 }}
                                >
                                  <div
                                    className="btn-group mb-2"
                                    role="group"
                                    aria-label="Basic example"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-outline-info p-1 rounded-0"
                                      style={{ fontSize: 13 }}
                                      onClick={this.allSelect}
                                    >
                                      Select All
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-outline-info p-1 rounded-0"
                                      style={{ fontSize: 13 }}
                                      onClick={this.allDeselect}
                                    >
                                      De-Select All
                                    </button>
                                  </div>
                                </div>
                              </>) : (<></>)}
                             <div className="select-client-box p-2 pt-2">
                                {is_add ? (<>
                                  <div className="d-flex flex-wrap row mb-1">
                                    <div
                                      className="form-group col-md-6"
                                      style={{ position: "relative" }}
                                    >
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Client"
                                        onChange={e => this.onSearchClientsName(e)}
                                      />
                                      <i
                                        className="fa fa-search"
                                        style={{
                                          position: "absolute",
                                          top: 1,
                                          right: 16,
                                          fontSize: 12,
                                          color: "#ddd",
                                          background: "#fff",
                                          padding: "8px 7px"
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>) : (<></>)}

                                {
                                  clientNameList.length > 0 ? (clientNameList.map((item, index) => (
                                    is_update ?
                                      // update
                                      (<>{item.clnnID === details_clientId ? (<label style={{ margin: 0, padding: 0 }} key={item.clnnID}>

                                        <input key={item.clnnID}
                                          type="checkbox"
                                          style={{
                                            float: "left",
                                            marginTop: "-8px",
                                            marginRight: 8,
                                          }}

                                          // disabled={is_update ? true : false}
                                          onClick={e => this.onClientNameChecked(e, item.clnnID)}
                                          checked={item.is_selected || is_update ? true : false}
                                        />
                                        {item.clnsClientFName}
                                      </label>) : (<></>)

                                      }</>)
                                      :
                                      // add
                                      (<label style={{ margin: 0, padding: 0 }} key={item.clnnID}>

                                        <input key={item.clnnID}
                                          type="checkbox"
                                          style={{
                                            float: "left",
                                            marginTop: "-8px",
                                            marginRight: 8,
                                          }}
                                          disabled={is_update ? true : false}
                                          onClick={e => this.onClientNameChecked(e, item.clnnID)}
                                          checked={item.is_selected}
                                        />
                                        {item.clnsClientFName}
                                      </label>
                                      )
                                  ))) : <></>
                                }
                              </div> 
                            </div>
                          </div> */}
                            <div className="d-flex flex-wrap row mt-1">
                              <div className="form-group col-md-3">
                                <label>
                                  Client
                                  <span className="required1">*</span>
                                </label>
                                <select
                                  className={is_update ? "js-example-basic-single bg-secondary" : "js-example-basic-single"}
                                  style={{ width: "100%" }}
                                  disabled={is_update}
                                  value={clientNameId} onChange={e => this.setState({ clientNameId: e.target.value })}
                                >
                                  <option  value=''>Select</option>
                                  {clientNameList.length > 0 ? clientNameList.map(item => (
                                    // <option
                                    //   key={item.clnnID}
                                    //   value={item.clnnID}>
                                    //   {item.clnsClientFName}
                                    // </option>
                                    <option
                                      key={item.ClientID}
                                      value={item.ClientID.toString()}>
                                      {item.ClientFName}
                                    </option>
                                  )) : <></>}
                                </select>                            </div>

                                <div className="form-group col-md-3">
                                <label>
                                  Engagement Partner
                                  <span className="required1">*</span>
                                </label>
                                <select
                                  className="js-example-basic-single"
                                  style={{ width: "100%" }}

                                  value={engPartnerName} onChange={e => this.setState({ engPartnerName: e.target.value })}

                                >
                                  <option  value=''>Select</option>
                                  {engPartnerNameList.length > 0 ? engPartnerNameList.map(item => (
                                    // <option
                                    //   key={item.emmnID}
                                    //   value={item.emmnID}>
                                    //   {item.EngagementPartnerName}
                                    // </option>

                                    <option
                                      key={item.EmployeeID}
                                      value={item.EmployeeID}>
                                      {item.EngagementPartnerName}
                                    </option>
                                  )) : <></>}
                                </select>
                              </div>

                              <div className="form-group col-md-3">
                                <label>
                                  Quality Reviewer<span className="required1">*</span>
                                </label>
                                <select
                                  className="js-example-basic-single"
                                  style={{ width: "100%" }}

                                  value={qualityReviewerName} onChange={e => this.setState({ qualityReviewerName: e.target.value })}

                                >
                                  <option  value=''>Select</option>
                                  {qualityReviewerNameList.length > 0 ? qualityReviewerNameList.map(item => (
                                    <option
                                      key={item.EmployeeID}
                                      value={item.EmployeeID}>
                                      {item.QualityReviewerName}
                                    </option>
                                  )) : <></>}
                                </select>
                              </div>

                              <div className="form-group col-md-3">
                                <label>
                                  Engagement Type<span className="required1">*</span>
                                </label>
                                <select
                                  className="js-example-basic-single"
                                  style={{ width: "100%" }}
                                  value={engagementTypeName} onChange={e => this.setState({ engagementTypeName: e.target.value })}
                                >
                                  <option value=''>Select</option>
                                  {engagementTypeNameList.length > 0 ? engagementTypeNameList.map(item => (
                                    <option
                                      key={item.EngagementMasterID}
                                      value={item.EngagementMasterID}>
                                      {item.EngagementType}
                                    </option>
                                  )) : <></>}
                                </select>
                              </div>
                            </div>




                            <div className="d-flex flex-wrap row mt-2">
                            </div>
                            <div className="d-flex flex-wrap row">
                              <div className="form-group col-12">
                                <label>Remark</label>
                                <div className="form-group mb-3">
                                  <textarea
                                    className="form-group rounded-1"
                                    name="remark"
                                    rows={2}
                                    cols={50}
                                    style={{ border: "1px solid #dee2e6" }}
                                    // defaultValue={this.state.remark}
                                    value={this.state.remark}
                                    onChange={e => this.setState({ remark: e.target.value })}
                                  />
                                </div>
                              </div>
                            </div>
                            <div style={{ height: 50 }} />
                            <div className="row">
                              <div className="footer-btn">
                                <div className="col-md-3">
                                  <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic example"
                                  >
                                    {/* {isActive} */}
                                    {is_add ?
                                      getModuleWriteAccess(Engagement_Allocation) && <button
                                        type="button"
                                        className="btn btn-save footer-btn-inner mr-1"
                                        onClick={e => this.handleSaveAndApprove(e)}
                                      >
                                        Save & Approve
                                      </button> :
                                      getModuleUpdateAccess(Engagement_Allocation) && <button
                                        type="button"
                                        className="btn btn-save footer-btn-inner mr-1"
                                        onClick={e => this.handleSaveAndApprove(e)}
                                      >
                                        Update
                                      </button>
                                    }
                                    {
                                      is_add && getModuleWriteAccess(Engagement_Allocation) && <button
                                        type="button"
                                        className="btn btn-update footer-btn-inner mr-1"
                                        onClick={this.handleReset}
                                      >
                                        Reset
                                      </button>
                                    }

                                    <button
                                      type="button"
                                      className="btn btn-cancel footer-btn-inner"
                                      onClick={this.handleCancel}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end page */}
                </div>
                {/* page-body-wrapper ends */}
              </div>
            ) : (<div className='access-msg h-auto'>
              <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
            </div>)}
          </div>
        </div>
        <ToastContainer />
        <Modal
          isOpen={isOpen}
          onRequestClose={() => this.setState({ isOpen: !isOpen })}>
          <div className="modal-dialog custom_modal_dialog">
            <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
              <div class="modal-header">
                <h4 class="modal-title modal_title_text">Email</h4>
                {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isOpen: !isOpen })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="pop_label">Do you want to send the email?</h3>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group text-right mod-fot" >
                    <a href="javascript:void(0);" class="btn save_btn del-pop-save"
                      onClick={(e) => this.onSaveAndMail(e, true)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                    <a href="javascript:void(0);" class="btn cancel_btn  del-pop-cancel" data-dismiss="modal"
                      style={{ marginLeft: '5px' }} onClick={(e) => this.onSaveAndMail(e, false)}><i class="fa fa-times"></i>&nbsp; No</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(connect(null, {
  engagementAllocationDetails_action,
  addEngagementAllocation_action,
  updateEngagementAllocation_action,
  deleteEngagementAllocation_action,
  fill_action
})(EngagementAllocationAU));