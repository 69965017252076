import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    platfarm, headers,
    TRAINING_REPORT_ACTION_TYPE,TIME_CLIENT_VIEW_ACTION_TYPE,
    TimesheetClientSummaryReport ,fetchAuthReport, timesheetClientSummaryReportViewUrl,
} from "../../utils/constants"
import moment from 'moment';


export const getTimesheetClientSummary_action = (
    trainingFromDate,trainingEndDate,monthId,yearId,employeedata,statusId) => async (dispatch) => {
    try {
        console.log( trainingFromDate,trainingEndDate,monthId,yearId,employeedata,statusId)
        var list = [];
        const mapdata1 = {
            "ParameterName":"@UserID",
            "ParameterValue": parseInt(localStorage.getItem('UserId')),
            "ParameterDataType":"int"
        }
        list.push(mapdata1);
        const mapdata2 = {
            "ParameterName":"@EmployeeID",
            "ParameterValue": parseInt( (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID']),
            "ParameterDataType":"int"
        }
        list.push(mapdata2);
        const mapdata3 = {
            "ParameterName":"@Platform",
            "ParameterValue":platfarm,
            "ParameterDataType":"varchar"
        }
        list.push(mapdata3);
        const mapdata4 = {
            "ParameterName":"@FromDate",
            "ParameterValue" : trainingFromDate,
            "ParameterDataType":"varchar"
        }
        list.push(mapdata4);
        const mapdata5 = {
            "ParameterName":"@ToDate",
            "ParameterValue": trainingEndDate,
            "ParameterDataType":"varchar"
        }
        list.push(mapdata5);
        const mapdata6 = {
            "ParameterName":"@Month",
            "ParameterValue": monthId,
            "ParameterDataType":"varchar"
        }
        list.push(mapdata6);
        const mapdata7 = {
            "ParameterName":"@Year",
            "ParameterValue":yearId,
            "ParameterDataType":"varchar"
        }
        list.push(mapdata7);
        const mapdata8 = {
            "ParameterName":"@EmployeeIDs",
            "ParameterValue":  employeedata,
            "ParameterDataType":"varchar"
        }
        list.push(mapdata8);
        const mapdata9 = {
            "ParameterName":"@StatusID",
            "ParameterValue":statusId,
            "ParameterDataType":"varchar"
        }
        list.push(mapdata9);


        const mapData = {
            "user_id": localStorage.getItem('UserId'),
            "report_name": TimesheetClientSummaryReport,
            "parameter_details": list
        }
        console.log('TimesheetClientSummaryReport mapData', mapData)

        const res = await axiosPost(fetchAuthReport, mapData);
        dispatch({
            type: TRAINING_REPORT_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const  getTimesheetClientSummaryView_action = (trainingFromDate,trainingEndDate,monthId,yearId,employeedata,statusId) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            EmployeeID: parseInt( (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID']),
            Platform: platfarm,
            FromDate: trainingFromDate,

            ToDate: trainingEndDate,
            Month: monthId,
            Year: yearId,

            EmployeeIDs: employeedata,
            StatusID: statusId
        }
        console.log(param)
        const res = await axiosGet(timesheetClientSummaryReportViewUrl, param)
        console.log(res)
        dispatch({
            type: TIME_CLIENT_VIEW_ACTION_TYPE,
            payload: res
        })

        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}