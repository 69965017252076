import { axiosGet, axiosPost } from "../../react_axios/Axios"
import { headers, ClientCategoryDetailsUrl, ClientCategoryListUrl, addClientCategoryUrl, updateClientCategoryUrl, deleteClientCategoryUrl, CLIENT_CATEGORY_DETAILS_ACTION_TYPE, CLIENT_CATEGORY_DETETE_ACTION_TYPE, CLIENT_CATEGORY_LIST_ACTION_TYPE, CLIENT_CATEGORY_ADD_ACTION_TYPE, CLIENT_CATEGORY_UPDATE_ACTION_TYPE, platfarm } from "../../utils/constants"

export const clientCategoryList_action = (clientCategoryName, clientCategoryTypeId, managementDesc, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientCategory: clientCategoryName,
            Desc: managementDesc,
            ClientTypeID: clientCategoryTypeId,
            Status: status
        }
        console.log(param)
        const res = await axiosGet(ClientCategoryListUrl, param)
        console.log(res)
        dispatch({
            type: CLIENT_CATEGORY_LIST_ACTION_TYPE,
            payload: res
        })

        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const clientCategoryDetail_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id
        }
        const res = await axiosGet(ClientCategoryDetailsUrl, param)
        console.log(res)
        dispatch({
            type: CLIENT_CATEGORY_DETAILS_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const addClientCategory_action = (clientCategoryName, clientCategoryTypeID, managementDesc ) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ClientCategory: clientCategoryName,
            ClientType: clientCategoryTypeID,
            Description: managementDesc
        }
        const res = await axiosPost
            (addClientCategoryUrl, mapdata, headers)
        dispatch({
            type: CLIENT_CATEGORY_ADD_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const updateClientCategory_action = (id,clientCategoryName, clientCategoryTypeID, managementDesc) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
            ClientCategory: clientCategoryName,
            ClientType: clientCategoryTypeID,
            Description: managementDesc
        }
        console.log(mapdata)
        const res = await axiosPost(updateClientCategoryUrl, mapdata, headers)
        dispatch({
            type: CLIENT_CATEGORY_UPDATE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const deleteClientCategory_action = (id) =>
    async (dispatch) => {
        try {
            const param = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                ID: id,
            }
            console.log(param)
            const res = await axiosGet
                (deleteClientCategoryUrl, param)
            dispatch({
                type: CLIENT_CATEGORY_DETETE_ACTION_TYPE,
                payload: res
            })
            return Promise.resolve(res)
        } catch (err) {
            return Promise.reject(err)
        }
    }

export default (state = {}, action) => {
    switch (action.type) {
        case 'SIMPLE_ACTION':
            return {
                result: action.payload
            }
        default:
            return state
    }
}