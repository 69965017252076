import { axiosPost, axiosGet } from "../../react_axios/Axios"
import { LEAVEAPPROVAL_LIST_ACTION_TYPE, leaveApprovalDetailsUrl, LEAVEAPPROVAL_DETAILS_ACTION_TYPE, LEAVEAPPROVAL_STATUS_ACTION_TYPE, LEAVEAPPROVAL_REJECT_ACTION_TYPE, leaveApprovalStatusUrl, leaveApprovalRejectUrl, leaveApprovalListUrl, platfarm } from '../../utils/constants'
import moment from "moment";

const user_id = localStorage.getItem('UserId')

export const leaveApprovalList_action = (employeeId, statusId, fromDate, toDate) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: null,
            EmployeeID: employeeId != '' && employeeId != 'null' ? employeeId : null,
            Status: statusId != '' && statusId != 'null' ? statusId : null,
            FromDate: fromDate != '' ? moment(fromDate).format('YYYY-MM-DD') : null,
            ToDate: toDate != '' ? moment(toDate).format('YYYY-MM-DD') : null
        }
        const res = await axiosGet(leaveApprovalListUrl, param);
        dispatch({
            type: LEAVEAPPROVAL_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const leaveApprovalDetails_action = (leaveID) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            LeaveID: parseInt(leaveID)
        }
        const res = await axiosGet(leaveApprovalDetailsUrl, param);
        dispatch({
            type: LEAVEAPPROVAL_DETAILS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const leaveApprovalStatus_action = (leaveStatusId) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: leaveStatusId
        }
        console.log('leaveApprovalDetails_action', param)
        const res = await axiosPost(leaveApprovalStatusUrl, param);
        dispatch({
            type: LEAVEAPPROVAL_STATUS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const leaveApprovalReject_action = (leaveStatusId, rejectReason) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: leaveStatusId,
            RejectReason: rejectReason
        }
        console.log('leaveApprovalDetails_action', param)
        const res = await axiosPost(leaveApprovalRejectUrl, param);
        dispatch({
            type: LEAVEAPPROVAL_REJECT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    }
    catch (err) {
        return Promise.reject(err);
    }
}
