import { axiosPost, axiosGet } from "../../react_axios/Axios"
import { leaveDetailsUrl, leaveListUrl, addLeaveUrl, updateLeaveUrl, deleteLeaveUrl, LEAVE_DETAILS_ACTION_TYPE, LEAVE_DETETE_ACTION_TYPE, LEAVE_LIST_ACTION_TYPE, LEAVE_ADD_ACTION_TYPE, LEAVE_UPDATE_ACTION_TYPE, minLeaveDateUrl, LEAVE_MIN_DATE_ACTION_TYPE, platfarm, headers } from "../../utils/constants"
import moment from "moment";

export const leaveList_action = (status, fromDate, toDate) => async (dispatch) => {
    try {
        const param = {
            UserID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            Platform: null,
            Status: status,
            FromDate: fromDate != null && fromDate != '' ? moment(fromDate).format('YYYY-MM-DD') : null,
            ToDate: toDate != null && toDate != '' ? moment(toDate).format('YYYY-MM-DD') : null,
        }
        console.log(param)
        const res = await axiosGet(leaveListUrl, param);
        console.log(res)
        dispatch({
            type: LEAVE_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const leaveDetail_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            Platform: platfarm,
            ID: id,
        }
        console.log(param)
        const res = await axiosGet(leaveDetailsUrl, param)
        console.log(res)
        dispatch({
            type: LEAVE_DETAILS_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const addLeave_action = (leaveTypeId, employeeId, fromDate, toDate, remark) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: employeeId,
            Platform: platfarm,
            LeaveTypeID: leaveTypeId,
            FromDate: moment(fromDate).format('YYYY-MM-DD'),
            ToDate: moment(toDate).format('YYYY-MM-DD'),
            Remark: remark
        }
        console.log(mapdata)
        const res = await axiosPost
            (addLeaveUrl, mapdata, headers)
        console.log(res)
        dispatch({
            type: LEAVE_ADD_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const updateLeave_action = (id, leaveTypeId, employeeId, fromDate, toDate, remark) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: employeeId,
            Platform: platfarm,
            ID: id,
            LeaveTypeID: leaveTypeId,
            FromDate: new Date(moment(fromDate).format('YYYY-MM-DD')),
            ToDate: new Date(moment(toDate).format('YYYY-MM-DD')),
            Remark: remark
        }
        const res = await axiosPost(updateLeaveUrl, mapdata, headers)
        dispatch({
            type: LEAVE_UPDATE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const deleteLeave_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
        }
        console.log(param)
        const res = await axiosGet
            (deleteLeaveUrl, param)
        dispatch({
            type: LEAVE_DETETE_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    } catch (err) {
        return Promise.reject(err)
    }
}

export const minLeaveDate_action = () => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
        }
        console.log(param)
        const res = await axiosGet(minLeaveDateUrl, param);
        console.log(res)
        dispatch({
            type: LEAVE_MIN_DATE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const simpleAction = () => dispatch => {
    dispatch({
        type: 'SIMPLE_ACTION',
        payload: 'result_of_simple_action'
    })
}