import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../utils/Loader";
import { userManualDownloadUrl } from '../utils/constants';
import Modal from "react-modal";

Modal.setAppElement("#root");


// {head,subHeader}
const Header = () => {

  const history = useNavigate();
  const [isLoading, setLoader] = useState(false);


  const loadScripts = () => {
    // This array contains all the files/CDNs 
    // const dynamicScripts = [
    //   'js/jquery.sticky.js',
    //   'js/metisMenu.min.js',
    //   'js/metisMenu-active.js',
    //   'js/main.js',
    //   'js/azia.js'
    // ];

    // for (let i = 0; i < dynamicScripts.length; i++) {
    //   const node = document.createElement('script');
    //   node.src = dynamicScripts[i];
    //   node.type = 'text/javascript';
    //   node.async = false;
    //   document.getElementsByTagName('head')[0].appendChild(node);
    // }
  }

  useEffect(() => {
    loadScripts();
   
  }, []);

  

  const onLogoutClick = () => {
    localStorage.clear();
    history.push('/login');
  }

  const openPdf = () => {
    var URL = userManualDownloadUrl /* http://0.0.0.0:8000/ or http://127.0.0.1:8000/; */
    window.open(URL, null);
  }

  return (
    <>
      {isLoading ? (
        <Loader></Loader>
      ) : (
        null
      )}
      <nav className="navbar top-menu navbar-expand-lg navbar-light" style={{ zIndex: 33 }}>
        <div className="container-fluid">
          <button className="navbar-toggler mob-menu collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <a className="navbar-brand mob-hide" href="#" style={{ fontWeight: 'bold', fontSize: '38px', color: '#444' }}><img src="assets/MainHeaderLogo.png" style={{ width: '150px', marginTop: '0px' }} /></a>
          <div className="top-links">
            <ul className="header-icon wm-header d-flex" style={{/* flexDirection: 'row', */ marginBottom: '0px' }}>
              {/*
  <li> <span class="quick-new" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Add"><a href="add.html" target="_blank"><i class="mdi mdi-plus"></i></a></span></li>
    */}
            </ul>
            <a className="navbar-brand desk-hide" href="#" style={{ fontWeight: 'bold', fontSize: '38px', color: '#444' }}>Aasan</a>
            <div className="navbar right-profile default-layout p-0 d-flex align-items-top flex-row">
              <div className="navbar-menu-wrapper d-flex align-items-top" style={{ padding: '0px' }}>
                <ul className="navbar-nav ms-auto">
                  {/* <li class="wh-name">
                                    <div class="top-name1">FY- 2021 / 2022</div>
                                    <div class="top-name">PROD</div>
                                </li> */}
                  {/* <li className="nav-item dropdown"> */}
                  {/* <a class="nav-link count-indicator notify" id="countDropdown" href="#" data-toggle="tooltip" title="Notification" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="icon-bell" style="font-size: 19px;"></i>
                                        <span class="count" style="top: -2px;"></span>
                                    </a> */}
                  {/* </li> */}
                  <li className="wh-name">
                    <div className="top-name">
                    {localStorage.getItem('CompanyName')}<br />{" "} 
                      {/* Natvarlal Vepari &amp; Co <br />{" "} */}
                      <span>
                        <b>{localStorage.getItem("UserName")}</b>
                      </span>
                    </div>
                  </li>

                  <li className="nav-item dropdown d-lg-block user-dropdown">
                    {/* <a className="nav-link top-user" id="UserDropdown" data-toggle="tooltip" title="User" href="#" data-bs-toggle="dropdown" aria-expanded="false"> vs</a> */}
                    <div style={{ marginTop: '-22px', height: '165px' }} className="dropdown-menu dropdown-menu-right navbar-dropdown profile-right" aria-labelledby="UserDropdown">
                      <div className="dropdown-header text-center sign-right">
                        <button type="button" className="btns-cl right-close"><i className="mdi mdi-close" /></button>
                        <img className="img-md rounded-circle" src="images/faces/face8.jpg" alt="Profile image" />
                        <p className="mb-1 mt-3 font-weight-semibold">Allen Moreno</p>
                        {/* <p class="fw-light text-muted mb-0">allenmoreno@gmail.com</p>
                                            <span class="tp-vendor"><a href="#" class="tp-name">Benchmark Computer pvt ltd.</a></span> */}
                      </div>
                      {/*  <a href="user_profile.html" class="dropdown-item"><i class="dropdown-item-icon mdi mdi-account-outline text-primary1 me-2"></i> My Profile </a> */}
                      {/* <a class="dropdown-item"><i class="dropdown-item-icon mdi mdi-message-text-outline text-primary1 me-2"></i> Messages</a> */}
                      {/* <a href="change_password.html" class="dropdown-item"><i class="dropdown-item-icon mdi mdi-calendar-check-outline text-primary1 me-2"></i> Change Password</a> */}
                      {/*  <a class="dropdown-item"><i class="dropdown-item-icon mdi mdi-help-circle-outline text-primary1 me-2"></i> FAQ</a> */}
                      <a className="dropdown-item" href="login.html"><i className="dropdown-item-icon mdi mdi-power text-primary1 me-2" />Sign Out</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Header;

