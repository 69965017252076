import { axiosPost, axiosGet } from "../../react_axios/Axios"
import {
    platfarm, headers,
    TRAINING_LIST_ACTION_TYPE,
    TRAINING_APPROVE_Reject_LIST_ACTION_TYPE,
    TRAINING_ADD_ACTION_TYPE,
    TRAINING_DELETE_ACTION_TYPE,
    TRAINING_APPROVE_Reject_ACTION_TYPE,
    TRAINING_REPORT_ACTION_TYPE,
    trainingUrl, 
    addtrainingUrl, deletetrainingUrl,reportTrainingViewUrl,TRAINING_REPORT_VIEW_ACTION_TYPE,
    getTrainingApprovalRejectListUrl,approveRejectTrainingUrl,reportTrainingUrl,
    TrainingStatus,fetchAuthReport,EmployeewiseTrainingStatus, FirmwiseTrainngStatus
} from "../../utils/constants"
import moment from 'moment';


export const trainingList_action = (FromTrainingDate, ToTrainingDate,
    CourseTypeID, CourseDetails, EmployeeID, StatusID,
    ApprovedByID, ApprovedDate
) => async (dispatch) => {
    try {

        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            FromTrainingDate: FromTrainingDate != 'Invalid date'  && FromTrainingDate != null &&  FromTrainingDate != '' &&  FromTrainingDate != 'Select'  ?
                moment(FromTrainingDate).format('MM-DD-YYYY') : '',
            ToTrainingDate: ToTrainingDate != 'Invalid date'  && ToTrainingDate != null &&  ToTrainingDate != '' &&  ToTrainingDate != 'Select'  ? moment(ToTrainingDate).format('MM-DD-YYYY') : '',

            CourseTypeID: CourseTypeID,
            CourseDetails: CourseDetails,
            SearchEmployeeID: EmployeeID,
            StatusID: StatusID,

            ApprovedByID: ApprovedByID,
            ApprovedDate: ApprovedDate != 'Invalid date'  && ApprovedDate != null  &&  ApprovedDate != '' &&  ApprovedDate != 'Select' ? moment(ApprovedDate).format('MM-DD-YYYY') : '',
        }
        console.log(param);
        //         @UserID Int,
        // @EmployeeID Int,
        // @Platform Nvarchar(10),
        // @FromTrainingDate DateTime,
        // @ToTrainingDate DateTime,
        // @CourseTypeID Int,
        // @CourseDetails Nvarchar(100),
        // @SearchEmployeeID Int,
        // @StatusID Int,
        // @ApprovedByID Int,
        // @ApprovedDate DateTime
        const res = await axiosGet(trainingUrl, param);
        dispatch({
            type: TRAINING_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const addtraining_action = (trainigAddList) => async (dispatch) => {
        try {
            // new Date(moment(auditPeriodStartDate ).format('MM-DD-YYYY')+'Z').toISOString(),
            // moment(ToTrainingDate).format('MM-DD-YYYY')
            const mapdata = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
                trainigAddList: trainigAddList,
            }
            const res = await axiosPost(addtrainingUrl, mapdata, headers);

            dispatch({
                type: TRAINING_ADD_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const deletetraining_action = (id) => async (dispatch) => {
    try {
        const param = {
            userID: localStorage.getItem('UserId'),
            employeeID: null,
            Platform: platfarm,
            trainingID: id,

        }
        const res = await axiosGet(deletetrainingUrl, param);
        dispatch({
            type: TRAINING_DELETE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const approveRejectTraining_action = (trainingApproveRejectList) => async (dispatch) => {
    try {
        // new Date(moment(auditPeriodStartDate ).format('MM-DD-YYYY')+'Z').toISOString(),
        // moment(ToTrainingDate).format('MM-DD-YYYY')
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            trainingApproveRejectList: trainingApproveRejectList,
        }
        const res = await axiosPost(approveRejectTrainingUrl, mapdata, headers);

        dispatch({
            type: TRAINING_APPROVE_Reject_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const trainingApprovalRejectList_action = (FromTrainingDate, ToTrainingDate,
    CourseTypeID, CourseDetails, EmployeeID, StatusID,
    ApprovedByID, ApprovedDate
) => async (dispatch) => {
    try {

        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            EmployeeID:   (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID'],
            FromTrainingDate: FromTrainingDate != '' ?
                moment(FromTrainingDate).format('MM-DD-YYYY') : null,
            ToTrainingDate: ToTrainingDate != '' ? moment(ToTrainingDate).format('MM-DD-YYYY') : null,

            CourseTypeID: CourseTypeID,
            CourseDetails: CourseDetails,
            SearchEmployeeID: EmployeeID,
            StatusID: StatusID,

            ApprovedByID: ApprovedByID,
            ApprovedDate: ApprovedDate != '' ? moment(ApprovedDate).format('MM-DD-YYYY') : null,
        }
        console.log(param);
       
        const res = await axiosGet(getTrainingApprovalRejectListUrl, param);
        dispatch({
            type: TRAINING_APPROVE_Reject_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const getReportTraining_action = (FinacialYearID,Training_EmployeeID,StatusID,ReportType) => async (dispatch) => {
    try {
       
        var list = [];
        const mapdata1 = {
            "ParameterName":"@UserID",
            "ParameterValue": parseInt(localStorage.getItem('UserId')),
            "ParameterDataType":"int"
        }
        list.push(mapdata1);
        const mapdata2 = {
            "ParameterName":"@EmployeeID",
            "ParameterValue": parseInt( (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID']),
            "ParameterDataType":"int"
        }
        list.push(mapdata2);
        const mapdata3 = {
            "ParameterName":"@Platform",
            "ParameterValue":platfarm,
            "ParameterDataType":"varchar"
        }
        list.push(mapdata3);
        const mapdata4 = {
            "ParameterName":"@FinacialYearID",
            "ParameterValue":parseInt(FinacialYearID),
            "ParameterDataType":"int"
        }
        list.push(mapdata4);
        const mapdata5 = {
            "ParameterName":"@Training_EmployeeID",
            "ParameterValue": Training_EmployeeID,
            "ParameterDataType":"varchar"
        }
        list.push(mapdata5);
        const mapdata6 = {
            "ParameterName":"@StatusID",
            "ParameterValue": parseInt(StatusID),
            "ParameterDataType":"int"
        }
        list.push(mapdata6);
        const mapdata7 = {
            "ParameterName":"@ReportType",
            "ParameterValue":ReportType,
            "ParameterDataType":"int"
        }
        list.push(mapdata7);

        const mapData = {
            "user_id": localStorage.getItem('UserId'),
            "report_name": ReportType == 1 ?  FirmwiseTrainngStatus : EmployeewiseTrainingStatus,
            "parameter_details": list
        }
     

        const res = await axiosPost(fetchAuthReport, mapData);
        dispatch({
            type: TRAINING_REPORT_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const getReportTrainingView_action = (FinacialYearID,Training_EmployeeID,StatusID,ReportType) => async (dispatch) => {
        try {
            const param = {
                UserID: localStorage.getItem('UserId'),
                EmployeeID: parseInt( (JSON.parse(localStorage.getItem('UserLoginData')))[0]['EmployeeID']),
                Platform: platfarm,
                FinacialYearID : FinacialYearID,

                Training_EmployeeID: Training_EmployeeID,
                StatusID: StatusID,
                ReportType: ReportType,
              
            }
            console.log(param)
            const res = await axiosGet(reportTrainingViewUrl, param)
            console.log(res)
            dispatch({
                type: TRAINING_REPORT_VIEW_ACTION_TYPE,
                payload: res
            })

            return Promise.resolve(res)
        }
        catch (err) {
            return Promise.reject(err)
        }
    }