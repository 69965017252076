import { axiosGet, axiosPost } from "../../react_axios/Axios"
import { headers, expenseHeadDetailsUrl, expenseHeadListUrl, deleteExpenseHeadUrl, addExpenseHeadUrl, updateExpenseHeadUrl, EXPENSE_HEAD_LIST_ACTION_TYPE, EXPENSE_HEAD_DETAILS_ACTION_TYPE, EXPENSE_HEAD_DETETE_ACTION_TYPE, EXPENSE_HEAD_ADD_ACTION_TYPE, EXPENSE_HEAD_UPDATE_ACTION_TYPE, platfarm } from "../../utils/constants"

export const expenseHeadList_action = (expenseHeadName, status) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: expenseHeadName,
            Status: status
        }
        console.log(param)
        const res = await axiosGet(expenseHeadListUrl, param)
        console.log(res)
        dispatch({
            type: EXPENSE_HEAD_LIST_ACTION_TYPE,
            payload: res
        })

        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const expenseHeadDetail_action = (id) => async (dispatch) => {
    try {
        const param = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id
        }
        const res = await axiosGet(expenseHeadDetailsUrl, param)
        console.log(res)
        dispatch({
            type: EXPENSE_HEAD_DETAILS_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const addExpenseHead_action = (expenseHeadName) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            Name: expenseHeadName,
        }
        const res = await axiosPost
            (addExpenseHeadUrl, mapdata, headers)
        dispatch({
            type: EXPENSE_HEAD_ADD_ACTION_TYPE,
            payload: res
        })
        return Promise.resolve(res)
    }
    catch (err) {
        return Promise.reject(err)
    }
}

export const updateExpenseHead_action = (id, expenseHeadName) => async (dispatch) => {
    try {
        const mapdata = {
            UserID: localStorage.getItem('UserId'),
            Platform: platfarm,
            ID: id,
            Name:expenseHeadName,
        }
        const res = await axiosPost(updateExpenseHeadUrl, mapdata, headers)
        dispatch({
            type: EXPENSE_HEAD_UPDATE_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const deleteExpenseHead_action = (id) =>
    async (dispatch) => {
        try {
            const param = {
                UserID: localStorage.getItem('UserId'),
                Platform: platfarm,
                ID: id,
            }
            console.log(param)
            const res = await axiosGet
                (deleteExpenseHeadUrl, param)
            dispatch({
                type: EXPENSE_HEAD_DETETE_ACTION_TYPE,
                payload: res
            })
            return Promise.resolve(res)
        } catch (err) {
            return Promise.reject(err)
        }
}

export default (state = {}, action) => {
    switch (action.type) {
        case 'SIMPLE_ACTION':
            return {
                result: action.payload
            }
        default:
            return state
    }
}