import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { fill_action } from '../../../actions/master_action/checklist_action'
import {
    closeChecklistDetails_action,
    closeCloseEngagementChecklist_action, deleteFile_action, uploadFile_action, GetFile_action
} from '../../../actions/engagement_action/close_engagement_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Modal from "react-modal";
import { updateChecklistReviewedByActionId, clearLocalStorage, updateChecklistPreparedByActionId, Close_Engagement } from '../../../utils/constants'
import Pagination from '../../../utils/pagination'
import Loader from '../../../utils/Loader'
import moment from 'moment';
import Resizer from "react-image-file-resizer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getModuleUpdateAccess } from '../../../utils/RoleAccess';

/* 
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
    // loginAction: () => dispatch(loginAction())
})

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));

class CloserChecklistU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reviewChecklistData: [],
            isLoading: false,
            isOpen: false,
            isSureCloseModal: false,
            engagementAllocationlistDeleteId: '',
            reviewChecklistId: '',
            is_update: false,
            isLoading: false,
            isOpen: false,
            reviewedByList: [],
            preparedByList: [],
            preparedById: '',
            checklistList: [],
            remark: '',
            isAllReviewedDone: false,
            dissableList: [],
            isImageModalOpen: false,
            imageName: '',
            actualClosureDate: '',
            isActualClosureDateModal: false,
            isShowMore: false,
            isDetails: false,
        }
        this.handleCancel = this.handleCancel.bind(this)
        this.onCloseClick = this.onCloseClick.bind(this)
        this.getReviewChecklistDetails = this.getReviewChecklistDetails.bind(this)
        // this.handleRemark = this.handleRemark.bind(this)
        // this.onSelectReviewed = this.onSelectReviewed.bind(this)
        // this.onSelectReviewedBy = this.onSelectReviewedBy.bind(this)

        // this.handleUpdate = this.handleUpdate.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.onPdfSelect = this.onPdfSelect.bind(this)
        this.onImageCancelClick = this.onImageCancelClick.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.handleDeleteFile = this.handleDeleteFile.bind(this)
        this.handleUploadFile = this.handleUploadFile.bind(this)
        this.handleGetFile = this.handleGetFile.bind(this)
        this.toggleModalDisplayImage = this.toggleModalDisplayImage.bind(this)
        this.toggleSureCloseModal = this.toggleSureCloseModal.bind(this)
        this.toggleActualClosureDateModal = this.toggleActualClosureDateModal.bind(this)

    }



    componentDidMount = () => {
        this.setState({
            reviewChecklistId: this.props.location.state.reviewChecklistId != '' ? this.props.location.state.reviewChecklistId : '',
        })
        this.getReviewChecklistDetails()
        this.getFillList(updateChecklistReviewedByActionId, this.props.location.state.reviewChecklistId)
        this.getFillList(updateChecklistPreparedByActionId, this.props.location.state.reviewChecklistId)
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);

    }



    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    // get fill List dropdown  
    getFillList(id, reference) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(id, reference)
            .then((data) => {
                if (data.error != 1) {
                    if (id === updateChecklistReviewedByActionId) {
                        this.setState({
                            reviewedByList: data.data,
                        })
                    }
                    if (id === updateChecklistPreparedByActionId) {
                        this.setState({
                            preparedByList: data.data,
                        })
                    }
                    this.setState({
                        isLoading: false
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    // get review checklist   details
    getReviewChecklistDetails() {
        const { reviewChecklistData, dissableList } = this.state
        var id = this.props.location.state.reviewChecklistId
        this.setState({ isLoading: true })
        // await this.getClientsList()
        this.props
            .closeChecklistDetails_action(id)
            .then((data) => {
                if (data.error != 1) {

                    var checklist = data.data['checkList']
                    for (var i = 0; i < checklist.length; i++) {
                        checklist[i].file = '';
                        checklist[i].fileName = '';
                        checklist[i].ActionID = 0;
                        checklist[i].isDisabled = checklist[i].IsReviewed;
                        dissableList.push(checklist[i].IsReviewed)
                    }

                    this.setState({
                        reviewChecklistData: data.data != null && data.data,
                        checklistList: checklist != null && checklist,
                        isLoading: false,
                        status: data.data['Status'] != null && data.data['Status'],
                        isDetails: true
                    })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toast.error(data.data, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })

    }


    // handleKeyDown(e, index) {
    //     if (e.code === 'Enter') {
    //         this.handleRemark(e, index)
    //     }
    // }

    // handleRemark(e, index) {
    //     const { checklistList } = this.state
    //     checklistList[index]['ReviewedByRemark'] = e.target.value
    //     this.setState({ checklistList: checklistList })
    // }

    // clear field ,list
    handleCancel() {
        this.props.navigate('/closerChecklist')
    }

    onCloseClick() {
        const { checklistList, isAllReviewedDone } = this.state

        var isAllReviewed = true;
        for (var i = 0; i < checklistList.length; i++) {
            if (checklistList[i]['IsReviewed'] == null || !checklistList[i]['IsReviewed']) {
                isAllReviewed = false;
            }
        }
        if (!isAllReviewed) {
            this.toggleModal()
        } else {
            this.toggleActualClosureDateModal(isAllReviewed)
            this.setState({ isAllReviewedDone: isAllReviewed })
        }

    }

    //toggle model for close.................................
    toggleModal() {
        const { isOpen, reviewChecklistId } = this.state
        this.setState({ isOpen: !isOpen, })

    }
    //toggle model for delete.................................
    toggleSureCloseModal() {
        const { isSureCloseModal, reviewChecklistId, remark, actualClosureDate, isActualClosureDateModal } = this.state
        if (actualClosureDate != '' && actualClosureDate != null) {
            this.setState({ isActualClosureDateModal: !isActualClosureDateModal, })
            this.setState({ isSureCloseModal: !isSureCloseModal, })

        } else {
            toast.error('Please select actual Closure Date', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }

    }

    //toggle model for Actual Closure Date.................................
    toggleActualClosureDateModal(isReviwed) {
        const { isActualClosureDateModal, actualClosureDate, remark } = this.state
        if (remark != '' || isReviwed) {
            this.setState({ isActualClosureDateModal: !isActualClosureDateModal, })
        } else {
            toast.error('Please fill remark', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    // on close
    handleClose(e) {
        e.preventDefault()
        const { remark, actualClosureDate, isAllReviewedDone } = this.state
        this.setState({ isLoading: true })
        const id = this.props.location.state.reviewChecklistId;
        if ((remark != '' || isAllReviewedDone) && id != '' && actualClosureDate != '') {
            this.props
                .closeCloseEngagementChecklist_action(id, remark, actualClosureDate)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('Checklist closed successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isOpen: !this.state.isOpen, isLoading: false, remark: " " })
                        this.handleCancel()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Please enter  Remark field', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    handleDeleteFile(checklist) {
        const { file, fileName } = this.state
        this.setState({ isLoading: true })
        var id = checklist.EngagementChecklistID
        if (id != '') {
            this.props
                .deleteFile_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('File deleted successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })

                        checklist['file'] = '';
                        checklist['fileName'] = '';
                        checklist['IsFileAvailable'] = 0;
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Something went wrong', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    handleGetFile(id) {
        const { file, fileName, imageName } = this.state
        this.setState({ isLoading: true })
        if (id != '') {
            this.props
                .GetFile_action(id)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ imageName: data.data[0]['File'].split(',')[1], isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Something went wrong', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    handleUploadFile(id, file) {
        const { fileName } = this.state
        this.setState({ isLoading: true })
        if (file != '' && id != '') {
            this.props
                .uploadFile_action(id, file)
                .then((data) => {
                    if (data.error != 1) {
                        toast.success('File uploaded successfully', {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toast.error(data.msg, {
                            theme: 'colored',
                            autoClose: 3000,
                            hideProgressBar: true,
                        })
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            this.setState({ isLoading: false })
            toast.error('Something went wrong', {
                theme: 'colored',
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    onFilePick = async (e, file, data) => {
        e.preventDefault()
        const { checklistList } = this.state
        var image = '';
        if (!file[0]['name'].toString().toLowerCase().includes('.pdf')) {
            image = await this.resizeFile(file[0]);
        } else {
            image = await this.onPdfSelect(file[0]);

        }
        var checklist = checklistList;
        for (var i = 0; i < checklist.length; i++) {
            if (checklist[i]['EngagementChecklistID'] == data.EngagementChecklistID) {
                if (checklist[i]['IsReviewed']) {
                    checklist[i]['file'] = image;
                    checklist[i]['fileName'] = file[0]['name'];
                    checklist[i]['IsFileAvailable'] = 1;
                    this.handleUploadFile(checklist[i]['EngagementChecklistID'], checklist[i]['file'])
                }
                else {
                    toast.error('Please select reviewed', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }


            }
        }
        this.setState({ checklistList: [...checklist] });

    }

    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    onPdfSelect = async (file) => {
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
            return base64;
        });
    }
    onImageCancelClick = (e, data) => {
        e.preventDefault()
        const { checklistList } = this.state
        var checklist = checklistList;
        for (var i = 0; i < checklist.length; i++) {
            if (checklist[i]['EngagementChecklistID'] == data.EngagementChecklistID) {
                checklist[i]['file'] = '';
                checklist[i]['fileName'] = '';
            }
        }
        this.setState({ checklistList: [...checklist] });
    }

    //toggle model for img file.................................
    toggleModalDisplayImage(data) {
        const { isImageModalOpen } = this.state
        this.handleGetFile(data.EngagementChecklistID)
        this.setState({ isImageModalOpen: !isImageModalOpen, isLoading: false, })
    }

    render() {
        const { isLoading, reviewChecklistData, remark, reviewedByList, preparedByList, isDetails,
            checklistList, isOpen, currentPage, dissableList, isImageModalOpen, imageName, isSureCloseModal,
            isShowMore, clientName, engPartnerName, qualityReviewerName, engagementTypeId, status, reviewChecklistId, actualClosureDate, isActualClosureDateModal,
        } = this.state


        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        <>
                            {/* start page */}
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li>
                                                <a href="#"> Close Engagement</a>
                                            </li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className="user-status">
                                        <div className="">
                                            {isDetails ?
                                                <p>
                                                    <strong className={reviewChecklistData['Status'] === 'In-Process' ? 'greena' :
                                                        reviewChecklistData['Status'] == '' ? null : 'redi'}>
                                                        {reviewChecklistData['Status'] != null ? reviewChecklistData['Status'] : ''}</strong>
                                                </p>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                    <div className='page-space-from-bottom'>
                                        <div className="row">
                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body">
                                                        {/* <div className="row user-status">
                                                        <div className="status-top mr-2">
                                                            <p>
                                                                <strong className={reviewChecklistData['Status'] === 'In-Process' ? 'greena' : 'redi'}>
                                                                    {reviewChecklistData['Status'] != null ? reviewChecklistData['Status'] : ''}</strong>
                                                            </p>
                                                        </div>
                                                    </div> */}
                                                        <div className="col-md-12 mb-3  mt-2">
                                                            <div>
                                                                <div>
                                                                    <div className="d-flex flex-wrap row">
                                                                        {/* <div class="form-group col-md-3">
                                                      <label>Company</label>
                                                       <p>Natvarlal Vepari & Co</p>
                                              </div> */}
                                                                        <div className="form-group col-md-3 ">
                                                                            <label>Client</label>
                                                                            <p>{reviewChecklistData['ClientName'] != null ? reviewChecklistData['ClientName'] : ''}</p>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Engagement Type</label>
                                                                            <p>{reviewChecklistData['EngagementType'] != null ? reviewChecklistData['EngagementType'] : ''}</p>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Financial Year</label>
                                                                            <p>{reviewChecklistData['FinancialYear'] != null ? reviewChecklistData['FinancialYear'] : ''}</p>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Location</label>
                                                                            <p>{reviewChecklistData['LocationName'] != null ? reviewChecklistData['LocationName'] : ''}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-wrap row mt-2">
                                                                        <div className="form-group col-md-3">
                                                                            <label>Audit Period</label>
                                                                            <p>{reviewChecklistData.AuditFromDate != null ? moment(reviewChecklistData.AuditFromDate).format('DD-MM-YYYY') : ''} -

                                                                                {reviewChecklistData.AuditToDate != null ? moment(reviewChecklistData.AuditToDate).format('DD-MM-YYYY') : ""}</p>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Start Date - End Date</label>
                                                                            <p>{reviewChecklistData.StartDate != null ? moment(reviewChecklistData.StartDate).format('DD-MM-YYYY') : ''} -
                                                                                {reviewChecklistData.EndDate != null ? moment(reviewChecklistData.EndDate).format('DD-MM-YYYY') : ''}</p>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label>Expected Closure Date</label>
                                                                            <p>{reviewChecklistData.ExpectedClosureDate != null ? moment(reviewChecklistData.ExpectedClosureDate).format('DD-MM-YYYY') : ''}</p>
                                                                        </div>
                                                                    </div>
                                                                    {/* Show More Start Here */}
                                                                    {!isShowMore ?
                                                                        <></>
                                                                        :
                                                                        <div className="d-flex flex-wrap row mt-1">
                                                                            <div className="form-group col-md-3">
                                                                                <label>Engagement Head</label>
                                                                                <p>{reviewChecklistData['EngagementHead'] != null ? reviewChecklistData['EngagementHead'] : ''}</p>
                                                                            </div>
                                                                            <div className="form-group col-md-3">
                                                                                <label>Engagement Partner</label>
                                                                                <p>{reviewChecklistData['EngPartnerName'] != null ? reviewChecklistData['EngPartnerName'] : ''}</p>
                                                                            </div>
                                                                            <div className="form-group col-md-3">
                                                                                <label>Quality Reviewer</label>
                                                                                <p>{reviewChecklistData['QualityReviewerName'] != null ? reviewChecklistData['QualityReviewerName'] : ''}</p>
                                                                            </div>
                                                                            {/* <div class="form-group col-md-3">
                                                <label>Team Member</label>
                                                <p>Prachi</p>
                                            </div> */}
                                                                        </div>}
                                                                    {isShowMore ?
                                                                        <p style={{ color: "#1683ff" }}>
                                                                            {" "}
                                                                            <button className='btn btn-outline-info p-1 py-1 pt-2 pb-2 px-2 rounded-1' onClick={() => this.setState({ isShowMore: !isShowMore })}>
                                                                                <i className="fa fa-arrow-up" /> Less
                                                                            </button>
                                                                        </p>
                                                                        :
                                                                        <p style={{ color: "#1683ff" }}>
                                                                            {" "}
                                                                            <button className='btn btn-outline-info p-1 py-1 pb-2 px-2 rounded-1' onClick={() => this.setState({ isShowMore: !isShowMore })}>
                                                                                <i className="fa fa-plus" /> Show More
                                                                            </button>

                                                                        </p>}
                                                                    {/* Show More End Here */}
                                                                    <div className="row mt-2">
                                                                        <div className="table-responsive tbs4 common-table trail-tb">
                                                                            <table className="table table-bordered tb-action du-table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="text-center">Sr. No</th>
                                                                                        <th>Checklist</th>
                                                                                        <th style={{ width: 100 }}>E/Q</th>
                                                                                        <th
                                                                                            style={{ width: 100 }}
                                                                                            className="text-center"
                                                                                        >
                                                                                            Critical
                                                                                        </th>
                                                                                        <th>Reviewed</th>
                                                                                        <th>Reviewed By</th>
                                                                                        <th>Prepared By</th>
                                                                                        <th>Remark</th>
                                                                                        {/* <th>Upload File</th> */}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        checklistList.length > 0 ? checklistList.map((item, index) => (
                                                                                            <tr>
                                                                                                <td className="text-center">{index + 1}</td>
                                                                                                <td style={{ textAlign: 'left' }}>{item.ChecklistName}</td>
                                                                                                <td>{item.Name}</td>
                                                                                                <td className="text-center">{item.IsCritical ? 'Critical' : 'Non-Critical'}</td>
                                                                                                <td>{" "}<input type="checkbox"
                                                                                                    className={item.isDisabled && 'bg-secondary'}
                                                                                                    checked={item.IsReviewed}
                                                                                                    value={item.IsReviewed}
                                                                                                    disabled={true}
                                                                                                // onClick={e => this.onSelectReviewed(e, item, index)}
                                                                                                />
                                                                                                </td>
                                                                                                <td style={{ padding: 0 }}>
                                                                                                    <select
                                                                                                        className={item.isDisabled ? 'bg-secondary js-example-basic-single' : 'js-example-basic-single'}
                                                                                                        style={{
                                                                                                            width: "100%",
                                                                                                            height: 38,
                                                                                                            border: "none",
                                                                                                            background: "#eef7f9"
                                                                                                        }}
                                                                                                        value={item.ReviewedBy}
                                                                                                        disabled={true}
                                                                                                    // onChange={e => this.onSelectReviewedBy(e, index)}
                                                                                                    >
                                                                                                        <option value={''}>Select</option>
                                                                                                        {reviewedByList.map(item => (
                                                                                                            <option
                                                                                                                key={item.EngagementPartnerID}
                                                                                                                value={item.EngagementPartnerID}>
                                                                                                                {item.EngagementPartner}
                                                                                                            </option>
                                                                                                        ))}

                                                                                                    </select>
                                                                                                </td>
                                                                                                {/* ////=============== */}
                                                                                                <td style={{ padding: 0 }}>
                                                                                                    <select
                                                                                                        className={item.isDisabled ? 'bg-secondary js-example-basic-single' : 'js-example-basic-single'}
                                                                                                        style={{
                                                                                                            width: "100%",
                                                                                                            height: 38,
                                                                                                            border: "none",
                                                                                                            background: "#eef7f9"
                                                                                                        }}
                                                                                                        value={item.PreparedBy}
                                                                                                        disabled={true}
                                                                                                    // onChange={e => this.onSelectReviewedBy(e, index)}
                                                                                                    >
                                                                                                        <option value={''}>Select</option>
                                                                                                        {preparedByList.map(item => (
                                                                                                            <option
                                                                                                                key={item.TeamMemberID}
                                                                                                                value={item.TeamMemberID}>
                                                                                                                {item.TeamMember}
                                                                                                            </option>
                                                                                                        ))}

                                                                                                    </select>
                                                                                                </td>
                                                                                                {/* ////=============== */}

                                                                                                <td style={{ padding: 0 }}>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className={item.isDisabled && 'bg-secondary'}
                                                                                                        style={{ padding: 7, background: "#eef7f9",width: '100%' }}
                                                                                                        value={item.ReviewedByRemark}
                                                                                                        disabled={true} onChange={e => this.handleRemark(e, index)}
                                                                                                    // onKeyDown={e => this.handleKeyDown(e, index)}
                                                                                                    />
                                                                                                </td>
                                                                                                {/* <td style={{ padding: '0px 4px !important' }}>

                                                                                                {item.IsFileAvailable ? (<>
                                                                                                    <div className="grid-upload-file d-flex">
                                                                                                   <div> <a
                                                                                                        className="btn btn-info btn-sm rounded-0 p-1"
                                                                                                        style={{ lineHeight: "21px!important" }} 
                                                                                                        disabled={true}
                                                                                                    >
                                                                                                        <span className="glyphicon glyphicon-upload" />
                                                                                                        View File 
                                                                                                    </a></div>
                                                                                                    <div classname="ml-5">
                                                                                                  </div>
                                                                                                    </div>
                                                                                                </>)
                                                                                                    : (<>
                                                                                                        <form encType="multipart/form-data">
                                                                                                                <div className="grid-upload-file">
                                                                                                                    <input type="file"
                                                                                                                        disabled={true}
                                                                                                                     onChange={(e) => this.onFilePick(e, e.target.files, item)} accept={".jpg,.jpeg,.png,.pdf"} />
                                                                                                                    <a
                                                                                                                        className="btn btn-info btn-sm remove upload-btn rounded-0 p-1 text-white"
                                                                                                                        style={{ lineHeight: "21px!important" }}
                                                                                                                    >
                                                                                                                        <span className="glyphicon glyphicon-upload" />
                                                                                                                        Upload File
                                                                                                                    </a>

                                                                                                                </div>
                                                                                                          
                                                                                                        </form>
                                                                                                    </>)}

                                                                                            </td> */}
                                                                                            </tr>
                                                                                        )) : (<tr ><td colSpan={8} style={{ textAlign: 'center' }}>Check list is not available</td></tr>)
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="footer-btn">
                                                                        <div className="col-md-3">
                                                                            <div
                                                                                className="btn-group"
                                                                                role="group"
                                                                                aria-label="Basic example"
                                                                            >
                                                                                {isDetails && reviewChecklistData['Status'] == 'In-Process' && getModuleUpdateAccess(Close_Engagement) &&
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-delete footer-btn-inner mr-1"
                                                                                        data-toggle="modal"
                                                                                        data-target="#close"
                                                                                        onClick={this.onCloseClick}
                                                                                    >
                                                                                        Close
                                                                                    </button>}

                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-cancel footer-btn-inner"
                                                                                    onClick={this.handleCancel}
                                                                                >
                                                                                    Cancel
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                            {/* //====  Modal for file image*/}
                            <Modal
                                isOpen={isImageModalOpen}
                                onRequestClose={this.toggleModalDisplayImage}>
                                <div className="modal-dialog custom_modal_dialog">
                                    <div className="modal-content" style={{ borderRadius: '0px' }}>
                                        <div class="modal-header">
                                            <h4 class="modal-title">File</h4>
                                            <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModalDisplayImage} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                                        </div>
                                        <div class="modal-body" style={{ padding: "10px 10px" }}>
                                            <div className="d-flex flex-wrap row">
                                                <img src={`data:image/jpeg;base64,${imageName}`} style={{ width: '100%' }} />

                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-delete footer-btn-inner mr-1"
                                            >
                                                Change
                                            </button>
                                            <button type="button" className="btn btn-cancel  footer-btn-inner"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={this.toggleModalDisplayImage}
                                            >
                                                Cancel
                                            </button>
                                        </div>

                                    </div>
                                </div>

                            </Modal>


                            <Modal
                                isOpen={isOpen}
                                onRequestClose={this.toggleModal}>
                                <div className="modal-dialog custom_modal_dialog">
                                    <div className="modal-content" style={{ borderRadius: '0px', left: '0%', width: '400px' }}>
                                        <div class="modal-header">
                                            <h4 class="modal-title">Forcefully Closure of an Engagement</h4>
                                            <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                                        </div>
                                        <div class="modal-body" style={{ padding: "10px 10px" }}>
                                            <div className="d-flex flex-wrap row">
                                                <div className="form-group col-12">
                                                    <label style={{ fontSize: 14 }}>
                                                        {/* There are {checklistList.length} critical checklist, which has not yet been reviewd. */}
                                                        There are few checklist, which has not yet been reviewed.
                                                        In case you want to forcefully close this engagement, enter your
                                                        remark and click on "Close" else click on "Cancel" to fill the
                                                        missing checklists.
                                                    </label>
                                                    <textarea
                                                        className="mt-2"
                                                        name="remark"
                                                        rows={4}
                                                        cols={50}
                                                        style={{ border: "1px solid #ddd" }}
                                                        value={remark}
                                                        onChange={e => this.setState({ remark: e.target.value })}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer p-1">
                                            <button
                                                type="button"
                                                className="btn btn-delete footer-btn-inner mr-1"
                                                onClick={e => this.toggleActualClosureDateModal()}
                                            >
                                                Close
                                            </button>
                                            <button type="button" className="btn btn-cancel  footer-btn-inner"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={this.toggleModal}
                                            >
                                                Cancel
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </Modal>

                            <Modal
                                isOpen={isSureCloseModal}
                                onRequestClose={this.toggleSureCloseModal}>
                                <div className="modal-dialog custom_modal_dialog">
                                    <div className="modal-content" style={{ borderRadius: '0px', left: '0%', width: '400px' }}>
                                        <div class="modal-header">
                                            <h4 class="modal-title">Closure of an Engagement</h4>
                                            <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleSureCloseModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                                        </div>
                                        <div class="modal-body" style={{ padding: "10px 10px" }}>
                                            <div className="d-flex flex-wrap row">
                                                <div className="form-group col-12">
                                                    <label style={{ fontSize: 14 }}>
                                                        Are you sure?
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer py-1">
                                            <button
                                                type="button"
                                                className="btn btn-delete footer-btn-inner mr-1"
                                                onClick={e => this.handleClose(e)}
                                            >
                                                Ok
                                            </button>
                                            <button type="button" className="btn btn-cancel  footer-btn-inner"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={this.toggleSureCloseModal}
                                            >
                                                Cancel
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </Modal>

                            <Modal
                                isOpen={isActualClosureDateModal}
                                onRequestClose={this.toggleActualClosureDateModal}>
                                <div className="modal-dialog custom_modal_dialog" style={{ width: '30%' }}>
                                    <div className="modal-content" style={{ borderRadius: '0px', left: '0%', width: '90%' }}>
                                        <div class="modal-header">
                                            <h4 class="modal-title">Actual Closure Date<span className="required1">*</span></h4>
                                            <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleActualClosureDateModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                                        </div>
                                        <div class="modal-body" style={{ padding: "10px 10px" }}>
                                            <div className="d-flex flex-wrap row">
                                                <div className="form-group col-12">

                                                    <label>
                                                        Select actual Closure Date<span className="required1">*</span>
                                                    </label>
                                                    <div className="form-group d-flex">
                                                        <DatePicker className="form-control"
                                                            dateFormat="dd-MM-yyyy"
                                                            selected={actualClosureDate}
                                                            minDate={moment(reviewChecklistData.StartDate, 'YYYY-MM-DD').toDate()}
                                                            maxDate={Date.now()}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            onChange={(date) => {
                                                                this.setState({ actualClosureDate: date });

                                                            }}
                                                            placeholderText="Actual Closure Date"
                                                            customInput={<ExampleCustomInput />}
                                                            dayClassName={() => "example-datepicker-day-class"}
                                                            popperClassName="example-datepicker-class"
                                                        />

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-delete footer-btn-inner mr-1"
                                                onClick={e => this.toggleSureCloseModal(e)}
                                            >
                                                Ok
                                            </button>
                                            <button type="button" className="btn btn-cancel  footer-btn-inner"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={this.toggleActualClosureDateModal}
                                            >
                                                Cancel
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </Modal>
                        </>

                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>


            </>
        );
    }
}

export default withRouter(connect(null, {
    closeChecklistDetails_action,
    closeCloseEngagementChecklist_action,
    fill_action, deleteFile_action, uploadFile_action, GetFile_action
})(CloserChecklistU));