import React, { Component, forwardRef } from "react";
import Loader from "../../../utils/Loader";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../../../utils/pagination'
import { workingDaysList_action } from '../../../actions/master_action/working_days_action'
import { getModuleUpdateAccess, getModuleWriteAccess } from "../../../utils/RoleAccess";
import { Master } from "../../../utils/constants";

class WorkingDays extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isAccessToView: true,
            currentPage: 1,
            tablePerPage: 10,
            recordsPerPage: 10,
            pageSizeInput: "10",
            isSearchTab: false,
            workingDaysList: [],
        }

        this.record = this.record.bind(this)
        this.handleUpdateWorkingDay = this.handleUpdateWorkingDay.bind(this)
        this.getWorkingdayList = this.getWorkingdayList.bind(this)
        this.updateItem = this.updateItem.bind(this);
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
        this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);

    }

    //working day list default
    componentDidMount = () => {
        this.setState({
            isAccessToView: true,
        })
        setTimeout(() => {
            this.getWorkingdayList()
        }, 100);
        // document.addEventListener('keydown', this.handleTabKeyPress);
        // document.addEventListener('click', this.handleInputClick);

    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handleTabKeyPress);
        // document.removeEventListener('click', this.handleInputClick);
    }


    handleTabKeyPress = (event) => {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            const focusedElement = document.activeElement;
            const { previouslyFocusedElement } = this.state;

            if (previouslyFocusedElement) {
                previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
            }

            if (focusedElement.classList.contains('form-control') ||
                focusedElement.classList.contains('js-example-basic-single') ||
                focusedElement.classList.contains('example-custom-input')) {
                focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
                this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
            }
        }
    };


    handleInputClick = (event) => {
        const { previouslyFocusedElement } = this.state;
        const clickedElement = event.target;

        if (previouslyFocusedElement) {
            previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
        }

        if (clickedElement.classList.contains('form-control') ||
            clickedElement.classList.contains('js-example-basic-single') ||
            clickedElement.classList.contains('example-custom-input')) {
            clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
            this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
        } else {
            this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
        }
    };

    onSearchTabClick() {
        const { isSearchTab, } = this.state;
        console.log("onSearchTabClick", isSearchTab)
        this.setState({ isSearchTab: !isSearchTab })
    }

    //record
    record(workingDaysList) {
        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ width: '180px', textAlign: 'center' }} >Month</th>
                        <th>Week 1</th>
                        <th>Week 2</th>
                        <th>Week 3</th>
                        <th>Week 4</th>
                        <th>Week 5</th>
                    </tr>
                </thead>
                <tbody>
                    {workingDaysList.length > 0 ? (
                        workingDaysList.map((data, i) => (
                            <tr key={data.OfficeWorkingDaysID}>
                                <td className="text-center" style={{ padding: '5px!important' }}>
                                    <div className="table__button-group">
                                        {getModuleUpdateAccess(Master) ?
                                            <a><i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleUpdateWorkingDay(data.OfficeWorkingDaysID, data.Day)} /></a> :
                                            <a><i className="fa fa-eye" onClick={() => this.handleUpdateWorkingDay(data.OfficeWorkingDaysID, data.Day)} /></a>
                                        }
                                    </div>
                                </td>
                                <td style={{ width: '180px', textAlign: 'center' }}>{data.Day}</td>
                                <td>{data.Week1}</td>
                                <td>{data.Week2}</td>
                                <td>{data.Week3}</td>
                                <td>{data.Week4}</td>
                                <td>{data.Week5}</td>
                            </tr>
                        ))
                    ) : (<tr><td colSpan={5} className='text-center'>Record not found</td></tr>)}
                </tbody>
            </table>
        )
    }

    getWorkingdayList() {
        const { workingDaysList } = this.state
        this.setState({ isLoading: true })
        this.props
            .workingDaysList_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        workingDaysList: data.data,
                        isLoading: false,
                        currentPage: 1
                    })
                }
                else {
                    toast.error(data.msg, {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    handleUpdateWorkingDay(WorkingDayID, dayOfTheWeek) {
        console.log(WorkingDayID, dayOfTheWeek)
        this.props.navigate('/workingDaysU', { state: { WorkingDayID: WorkingDayID, dayOfTheWeek: dayOfTheWeek, is_update: true, } })
    }


    handleAdd() {
        this.props.navigate('/workingDaysU', { state: { is_add: true } })
    }

    // LocationType add
    toggleModalAdd() {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
        this.clearValue()
        // this.state._isAdd = true
        // this.state._isUpdate = false
        // this.state._isDelete = false
    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }

    updateRecordsPerPage = (newSize) => {
        const parsedSize = parseInt(newSize, 10);
        if (!isNaN(parsedSize) && parsedSize > 0) {
            this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
        }
        this.setState({ pageSizeInput: newSize }); // Keep the input value updated
    };

    handlePageSizeInputChange = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) { // Only allow digits
            this.setState({ pageSizeInput: input });
        }
    };

    render() {
        const { isSearchTab, isLoading, isAccessToView, workingDaysList } = this.state
        // const recordsPerPage = 10

        const { currentPage, recordsPerPage, pageSizeInput } = this.state;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = workingDaysList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(workingDaysList.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <Header />


                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper">
                        {/* Left side menu */}
                        <Drawer />
                        {/* end left menu */}
                        {/* start page */}
                        {isAccessToView ? (
                            <div className="main-panel main-box">
                                <div className="content-wrapper">
                                    <div className="main-breadcum">
                                        <ul className="breadcrumb">
                                            <li><a>Working Days</a></li>
                                            <li />
                                        </ul>
                                    </div>
                                    <div className="page-space-from-bottom">
                                        <div className="row">
                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                                            <div className="col-md-12 pg-body grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body page-mid pt-0">

                                                        <div className="row">
                                                            <div className="col-12">
                                                                {getModuleWriteAccess(Master) && <button type="button" className="btn btn-Add rounded-1 mb-1 px-3 px-3 float-right" onClick={(e) => this.handleAdd(e)}> Add</button>}
                                                            </div>
                                                            <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                                                {this.record(workingDaysList)}
                                                                {/* {workingDaysList.length > 0 ? <Pagination
                                                                    nPages={nPages}
                                                                    currentPage={currentPage}
                                                                    setCurrentPage={this.updateItem}
                                                                    recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                                                    setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                                                    totalRecords={workingDaysList.length}
                                                                /> : <></>} */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='access-msg h-auto'>
                            <h5 class="text-danger"><i class="fa fa-exclamation-circle text-danger"></i> You do not have access to view this form!</h5>
                        </div>)}
                        {/* end page */}
                    </div>
                    {/* page-body-wrapper ends */}
                </div>
                <ToastContainer />

                {/* Modal for add and update */}
                {/* <Modal isOpen={(isOpen && _isAdd) || (isOpen && _isUpdate)} onRequestClose={this.toggleModal}>
                    <div className="modal-dialog custom_modal_dialog">
                        <div className="modal-content" style={{ borderRadius: '0px', width: '550px' }}>
                            <div class="modal-header">
                                <h4 class="modal-title modal_title_text">Working Days</h4>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

                            </div>
                            <div class="modal-body d-flex">
                                <div class="form-group col-md-6 p-1">
                                    <label>Management Type<span class="required1">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={managementType}
                                        onChange={(e) => this.setState({ managementType: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6 p-1">
                                    <label>
                                        Client Category
                                    </label>
                                    <select
                                        className="js-example-basic-single"
                                        style={{ width: "100%" }}

                                        value={clientCategoryId}
                                        onChange={e => this.setState({ clientCategoryId: e.target.value != "Select" ? e.target.value : "" })}

                                    >
                                         <option value={''}>Select</option>
                                        {clientCategoryList.length > 0 ? clientCategoryList.map(item => (
                                            <option
                                                key={item.ClientCategoryID}
                                                value={item.ClientCategoryID}>
                                                {item.ClientCategory}
                                            </option>
                                        )) : <></>}
                                    </select>
                                </div>
                            </div>

                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-group text-left mod-fot m-2">
                                        <div
                                            className="btn-group"
                                            role="group"
                                            aria-label="Basic example"
                                        >
                                            {
                                                this.state._isAdd && <button
                                                    type="button"
                                                    className="btn btn-save footer-btn-inner mr-1"
                                                    onClick={(e) => this.handleAdd(e)}
                                                >
                                                    Save
                                                </button>
                                            }

                                            {
                                                this.state._isUpdate &&
                                                <button
                                                    type="button"
                                                    className="btn btn-update footer-btn-inner mr-1"
                                                    onClick={(e) => this.handleUpdate(e)}
                                                >
                                                    Update
                                                </button>
                                            }
                                            <button
                                                type="button"
                                                className="btn btn-cancel footer-btn-inner mr-1"
                                                onClick={this.handleCancel}
                                            >
                                                Cancel
                                            </button>
                                            {
                                                this.state._isAdd &&
                                                <button
                                                    type="button"
                                                    className="btn btn-update footer-btn-inner mr-1"
                                                    onClick={() => this.clearValue()}
                                                >
                                                    Reset
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal> */}

            </>
        );
    }
}

export default withRouter(connect(null, { workingDaysList_action })(WorkingDays))